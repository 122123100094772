import { css } from '@emotion/css';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Checkbox } from '../../../../../../components/Checkbox';
import { Icon } from '../../../../../../components/Icon';
import { Modal } from '../../../../../../components/Modal';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';
import { Defaults } from '../../../../../../constants/Defaults';
import { Color, FontSize } from '../../../../../../constants/Style';
import { gutter, square, toRGBA } from '../../../../../../helpers/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { approximate } from '../../../../../../utils/NumberUtil';
import { SignInMethod } from '../../../../../Accounts/AccountsNew';
import { CancelPolicyContent } from '../../../../components/CancelPolicyContent';
import { LastWarningContent } from '../../../../components/LastWarningContent';
import { ReservationStore } from '../../../stores/ReservationStore';
import { MedicalAppointmentFeeContent } from './MedicalAppointmentFeeContent';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05rem;
`;

type Props = {
  open: boolean;
  handleStateChange: (newState: boolean) => void;
  onConfirm: (mode?: 'LINE' | 'LEGACY') => void;
};

const warningMessage = {
  '01': `咳、喉の痛み、全身倦怠感、関節痛、
下痢、発熱などの症状がある方は、
すべて「発熱・感染症外来」をご予約ください。
`,
  '02': `咳、喉の痛み、全身倦怠感、関節痛、
下痢、発熱などの症状がある方は、
すべて「発熱・感染症外来」をご予約ください。
`,
  '04': `咳、喉の痛み、全身倦怠感、関節痛、
下痢、発熱などの症状がある方は、
すべて「発熱・感染症外来」をご予約ください。
`,
} as { [key: string]: string }; // FIXME 一時的な処置

const warningList = css`
  padding-left: ${gutter(6)};
  list-style-type: disc;
`;

const warningListItemDanger = css`
  font-weight: 600;
  color: ${Color.FunctionalRedNeutral};
`;

const detailedWarningMessage = {
  D053: (
    <div>
      <ul className={warningList}>
        <li className={warningListItemDanger}>授乳中、妊娠中の方はボトックス不可</li>
        <li>ワクチン接種は前後1週間不可</li>
        <li>3ヶ月以内は要避妊</li>
        <li>3ヶ月間は輸血、献血不可</li>
      </ul>
    </div>
  ),
  D052: (
    <div>
      <ul className={warningList}>
        <li className={warningListItemDanger}>授乳中、妊娠中の方はボトックス不可</li>
        <li>ワクチン接種は前後1週間不可</li>
        <li>3ヶ月以内は要避妊</li>
        <li>3ヶ月間は輸血、献血不可</li>
      </ul>
    </div>
  ),
} as { [key: string]: React.ReactElement }; // FIXME 一時的な処置

export const ConfirmDialog = observer(({ open, handleStateChange, onConfirm }: Props) => {
  const store = useContext(ReservationStore.Context);
  const session = useContext(SessionStore.Context);

  const [reserveEnabled, setReserveEnabled] = useState(false); // FIXME 一時的な処置
  const [cancelPolicyConcent, setCancelPolicyConcent] = useState(false);

  const [signInMethod, setSignInMethod] = useState<SignInMethod>('LINE');

  useEffect(() => {
    if (!store.department?.id) {
      return;
    }
    if (!(store.department.id in warningMessage) && !((store.treatmentKind?.id ?? '') in detailedWarningMessage)) {
      setReserveEnabled(true);
    }
  }, [store.department, store.treatmentKind]);

  const { cancelPolicyInfo, visibleLastCaution, isJustBeforeAction } = store.cancelPolicy;
  const isJustBeforeReserve = useMemo(
    () => !!store.date && isJustBeforeAction(store.date),
    [store.date, isJustBeforeAction],
  );

  const handleConfirmClick = (method?: SignInMethod) => {
    method && setSignInMethod(method);
    if (isJustBeforeReserve) {
      store.cancelPolicy.setVisibleLastCaution(true);
      return;
    }
    session.authenticated ? onConfirm() : onConfirm(method);
  };

  const handleConfirmLastWarningClick = () => {
    session.authenticated ? onConfirm() : onConfirm(signInMethod);
  };

  const handleBack = () => {
    handleStateChange(false);
    store.setCheckedReservationFee(false);
  };

  if (!store.date) {
    return (
      <Modal visible={open}>
        <Card width={295}>
          <Caption type="header">日付が選択されていません。</Caption>
          <Button theme="neutral" ghost type="button" onClick={handleBack}>
            戻る
          </Button>
        </Card>
      </Modal>
    );
  }

  if (store.reservationFeeRequired && store.isReservationFeeDay() && !store.checkedReservationFee) {
    return (
      <Modal visible={open}>
        <Card width={375}>
          <MedicalAppointmentFeeContent onConfirm={() => store.setCheckedReservationFee(true)} onCancel={handleBack} />
        </Card>
      </Modal>
    );
  }

  return (
    <>
      <Modal visible={open}>
        <Card width={375}>
          {!visibleLastCaution && (
            <div className={centerStyle}>
              <ConfirmSection hint={!session.authenticated ? <>※15分以内に予約を完了してください</> : <></>} />
              {cancelPolicyInfo && (
                <div className={cancelPolicySectionStyle}>
                  <CancelPolicyContent date={store.date} store={store.cancelPolicy} actionType="reserve" />
                  <>
                    <Checkbox
                      label="上記キャンセルポリシーに同意する"
                      checked={cancelPolicyConcent}
                      onChange={v => setCancelPolicyConcent(v.target.checked)}
                    />
                  </>
                </div>
              )}
              {store.department && store.department.id in warningMessage && (
                <div className={warningSectionStyle}>
                  <div className={warningMsgStyle}>{warningMessage[store.department.id]}</div>
                  <div className={warningCheckBoxStyle}>
                    <Checkbox
                      checked={reserveEnabled}
                      onChange={v => setReserveEnabled(v.target.checked)}
                      label="上記の症状は一つもありません"
                    />
                  </div>
                </div>
              )}
              {store.treatmentKind && store.treatmentKind.id in detailedWarningMessage && (
                <div className={warningSectionStyle}>
                  <Card color="redNeutral">
                    <div className={warningCardIconStyle}>
                      <Icon name="exclamation-triangle" />
                    </div>
                    <div>{detailedWarningMessage[store.treatmentKind.id]}</div>
                  </Card>
                  <div className={warningCheckBoxStyle}>
                    <Checkbox
                      checked={reserveEnabled}
                      onChange={v => setReserveEnabled(v.target.checked)}
                      label="上記内容を確認しました"
                    />
                  </div>
                </div>
              )}
              {session.authenticated ? (
                <AuthenticatedAction
                  onConfirm={handleConfirmClick}
                  handleStateChange={handleBack}
                  disabled={!reserveEnabled || (!!cancelPolicyInfo && !cancelPolicyConcent)}
                  isJustBeforeReserve={isJustBeforeReserve}
                />
              ) : (
                <ButtonGroup>
                  <Button
                    className={
                      isJustBeforeReserve
                        ? ''
                        : `${GtmClasses.gtm.temporallyReservation.def} ${store.department?.id} ${store.treatmentKind?.id}`
                    }
                    block
                    theme="line"
                    type="button"
                    onClick={() => handleConfirmClick('LINE')}
                    disabled={!reserveEnabled || (!!cancelPolicyInfo && !cancelPolicyConcent)}
                  >
                    <Icon name="line" />
                    LINEログインで予約
                  </Button>
                  <Button block theme="neutral" ghost type="button" onClick={handleBack}>
                    戻る
                  </Button>
                  <div className={legacySignUpButtonStyle} onClick={() => handleConfirmClick('LEGACY')}>
                    メールアドレスでログインして予約（過去にメールアドレスで登録済みの方のみ。新規登録はLINEのみとなります）
                  </div>
                </ButtonGroup>
              )}
            </div>
          )}
          {visibleLastCaution && (
            <>
              <LastWarningContent
                type="reserve"
                store={store.cancelPolicy}
                onConfirm={handleConfirmLastWarningClick}
                onCancel={handleBack}
                confirmAnalyticsClassName={`${
                  session.authenticated
                    ? GtmClasses.gtm.conversion.authenticated
                    : GtmClasses.gtm.temporallyReservation.def
                } ${store.department?.id} ${store.treatmentKind?.id}`}
              />
            </>
          )}
        </Card>
      </Modal>
    </>
  );
});

const legacySignUpButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: ${FontSize.Regular};
  font-weight: bold;
  cursor: pointer;
`;

const warningSectionStyle = css`
  margin-bottom: 16px;
  text-align: left;
`;
const warningCheckBoxStyle = css`
  margin-top: 4px;
`;

const warningMsgStyle = css`
  padding: 16px;
  background: ${toRGBA(Color.FunctionalRedNeutral, 0.1)};
`;

const warningCardIconStyle = css`
  text-align: center;

  > svg {
    ${square(32)}

    fill: ${Color.FunctionalRedNeutral};
  }
`;

type ConfirmSectionProps = {
  hint?: ReactNode;
};

type ActionProps = Pick<Props, 'onConfirm' | 'handleStateChange'> & { disabled: boolean; isJustBeforeReserve: boolean };

const AuthenticatedAction = observer(({ onConfirm, handleStateChange, disabled, isJustBeforeReserve }: ActionProps) => {
  const store = useContext(ReservationStore.Context);
  const [concent, setConcent] = useState(false);
  return (
    <ButtonGroup>
      {!store.confirmRequiredLoading && store.confirmRequired ? (
        <div className={termsConfirmedSectionStyle}>
          <Checkbox
            label={`${store.department?.clinicName}への情報提供に同意する`}
            checked={concent}
            onChange={v => setConcent(v.target.checked)}
          />
          <div>
            <a
              style={{ textDecoration: 'underline' }}
              href={store.department?.termsUrl || '/'}
              target="_blank"
              rel="noreferrer"
            >
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Button
        disabled={store.confirmRequiredLoading || (store.confirmRequired && !concent) || disabled}
        type="button"
        onClick={() => onConfirm()}
        block
        className={
          isJustBeforeReserve
            ? ``
            : `${GtmClasses.gtm.conversion.authenticated} ${store.department?.id} ${store.treatmentKind?.id}`
        }
      >
        {isJustBeforeReserve ? '予約する' : '予約を完了する'}
      </Button>
      <Button block theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
        戻る
      </Button>
    </ButtonGroup>
  );
});

const termsConfirmedSectionStyle = css`
  text-align: left;
`;

const ConfirmSection = observer(({ hint }: ConfirmSectionProps) => {
  const store = useContext(ReservationStore.Context);
  if (!store.date) {
    throw new Error('unreachable code');
  }
  return (
    <div className={captionContainerStyle}>
      <div className={confirmHeaderStyle}>以下の内容で予約しますか？</div>
      <hr className={confirmHrStyle} />
      <div className={confirmBodyStyle}>
        <div className={confirmDateStyle}>
          {format(store.date, 'yyyy年M月d日')}({WeekLabels[getDay(store.date)]})
        </div>
        <div className={extraLargeStyle}>
          <div>
            {format(store.date, 'HH:mm')}
            <span className={smallTimeTildeStyle}> ～ </span>
            <span className={smallTimeStyle}>
              [所要{approximate(store.treatmentKind?.displayDuration || store.duration, Defaults.DURATION)}分]
            </span>
          </div>
        </div>
        <div className={departmentStyle}>
          {store.department?.clinicName} / {store.department?.name}
        </div>
        <Caption type="small">{hint}</Caption>
      </div>
    </div>
  );
});

// FIXME COVID only
// const considerCovid = (store: ReservationStore) => {
//   const displayDurations = covidDisplayDuration(store.treatmentKind?.id);
//   return displayDurations.displayDuration || store.duration;
// };

const confirmBodyStyle = css`
  margin: 24px 0;
`;

const confirmHeaderStyle = css`
  margin: 10px 0;
  font-size: ${FontSize.Medium};
  font-weight: bold;
`;

const confirmDateStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
`;

const confirmHrStyle = css`
  border: 0.5px solid ${Color.GrayscaleLight};
  opacity: 0.5;
`;

const extraLargeStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${FontSize.ExtraLarge};
  margin: 16px 0;
  font-size: ${FontSize.ExtraLarge};
  font-weight: 700;
  letter-spacing: -0.05rem;
  white-space: nowrap;
`;

const departmentStyle = css`
  margin: 16px 0;
`;

const captionContainerStyle = css`
  width: 100%;
`;

const smallTimeStyle = css`
  align-self: flex-end;
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  margin-right: 4px;
  margin-left: 4px;
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const cancelPolicySectionStyle = css`
  margin-bottom: 16px;
  font-weight: 300;
  text-align: left;
`;
