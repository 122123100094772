import React, { ComponentProps } from 'react';

import { Routes } from '../../../../constants/Routes';
import { MyPageSectionHeader } from '../MyPageSectionHeader';
import { CheckupResultCardLayout } from './CheckupResultCardLayout';

type Props = ComponentProps<typeof CheckupResultCardLayout>;
export const CheckupResultCards = ({ treatments }: Props) => (
  <section>
    <MyPageSectionHeader href={Routes.Paths.CheckupResultHistories} icon="consultation-status">
      健康診断結果
    </MyPageSectionHeader>
    <CheckupResultCardLayout treatments={treatments?.filter(t => t.checkupAppointment.result)} />
  </section>
);
