export const SUMMARY = {
  A: '異常なし',
  B: '要注意',
  C: '要受診',
  D: '要治療',
};

export const JUDGED_RESULT = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};

export const TWITTER_URL = {
  A: 'https://twitter.com/clinic_ten/status/1532563576074178561/photo/1',
  B: 'https://twitter.com/clinic_ten/status/1532569183426588673/photo/1',
  C: 'https://twitter.com/clinic_ten/status/1532569231543668736/photo/1',
  D: 'https://twitter.com/clinic_ten/status/1532569273088225282/photo/1',
};

export const OGP_URL = {
  A: 'https://pbs.twimg.com/media/FUTCN36aUAAeh8T?format=jpg&name=small',
  B: 'https://pbs.twimg.com/media/FUTHiJeaMAArkjK?format=jpg&name=small',
  C: 'https://pbs.twimg.com/media/FUTHluNakAE9tJ8?format=jpg&name=small',
  D: 'https://pbs.twimg.com/media/FUTHoIQacAATZwr?format=jpg&name=small',
};
