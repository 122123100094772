import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';

type DocumentNode = ReturnType<typeof gql>;

export const GQL = {
  query: async <V, R>(query: DocumentNode, variables: V) => {
    datadogLogs.logger.debug(
      `GQL.query ${
        query.definitions
          .map(d => d.kind === 'OperationDefinition' && d.name?.value)
          .filter(x => !!x)
          .join(',') || ''
      }`,
      {
        query: query.loc,
        variables,
      },
    );
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `executed query ${query.definitions
        .map(d => d.kind === 'OperationDefinition' && d.name?.value)
        .filter(x => !!x)
        .join(',')}`,
      level: Sentry.Severity.Info,
    });
    try {
      const res = await (API.graphql({
        query,
        variables: variables as any,
        authMode: 'AMAZON_COGNITO_USER_POOLS' as any,
      }) as Promise<{ data?: R; errors?: Error[] }>);

      if (!res.data || res.errors) {
        throw res.errors;
      }

      return res.data;
    } catch (e) {
      Sentry.addBreadcrumb({
        category: 'graphql',
        message: `failed execute query ${query.definitions
          .map(d => d.kind === 'OperationDefinition' && d.name?.value)
          .filter(x => !!x)
          .join(',')}`,
        level: Sentry.Severity.Error,
        data: {
          variables,
          // @ts-ignore
          errors: e?.errors?.map?.((x: any) => ({ ...x, path: x?.path?.join(',') })),
        },
      });
      throw e;
    }
  },
  queryAsGuest: async <V, R>(query: DocumentNode, variables: V) => {
    datadogLogs.logger.debug(
      `GQL.queryAsGuest ${
        query.definitions
          .map(d => d.kind === 'OperationDefinition' && d.name?.value)
          .filter(x => !!x)
          .join(',') || ''
      }`,
      {
        query: query.loc,
        variables,
      },
    );
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `executed query as guest ${query.definitions
        .map(d => d.kind === 'OperationDefinition' && d.name?.value)
        .filter(x => !!x)
        .join(',')}`,
      level: Sentry.Severity.Info,
    });
    const res = await (API.graphql({
      query,
      variables: variables as any,
      authMode: 'AWS_IAM' as any,
    }) as Promise<{ data?: R; errors?: Error[] }>);

    if (!res.data || res.errors) {
      throw res.errors;
    }

    return res.data;
  },
  queryMaybeGuest: async <V, R>(query: DocumentNode, variables: V) => {
    const isAuthenticated = await (async () => {
      try {
        const session = await Auth.currentSession();
        if (session.isValid()) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    })();

    datadogLogs.logger.debug(
      `GQL.queryMaybeGuest ${
        query.definitions
          .map(d => d.kind === 'OperationDefinition' && d.name?.value)
          .filter(x => !!x)
          .join(',') || ''
      }`,
      {
        query: query.loc,
        variables,
      },
    );

    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `executed query ${isAuthenticated ? '' : 'as guest'} ${query.definitions
        .map(d => d.kind === 'OperationDefinition' && d.name?.value)
        .filter(x => !!x)
        .join(',')}`,
      level: Sentry.Severity.Info,
    });

    const res = await (API.graphql({
      query,
      variables: variables as any,
      authMode: (isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM') as any,
    }) as Promise<{ data?: R; errors?: Error[] }>);

    if (!res.data || res.errors) {
      throw res.errors;
    }

    return res.data;
  },
};
