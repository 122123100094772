import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Breakpoint, Dimension } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = {
  children: ReactNode;
};

export const Wrapper = ({ children }: Props) => <div className={baseStyle}>{children}</div>;

const baseStyle = css`
  margin: auto ${gutter(5)};

  @media (min-width: ${Breakpoint.ScreenXs}px) {
    max-width: 82%;
    margin: auto;
  }

  @media (min-width: ${Breakpoint.ScreenSm}px) {
    max-width: ${Dimension.ContentWidthMax}px;
  }
`;
