import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { AttachmentType } from '../../../../../../gql/gql-types';
import { Accordion } from '../../../../../components/Accordion';
import { Card } from '../../../../../components/Card';
import { Treatment } from '../../../../../interfaces/Treatment';

type Props = Pick<Treatment, 'attachments'> & {
  onClick?: (key: string) => Promise<void>;
};

export const AttachmentList = ({ attachments, onClick }: Props) => {
  const attachmentsGroup = useMemo(
    () => [
      { title: '領収書ダウンロード', items: attachments.filter(item => item.type === AttachmentType.Receipt) || [] },
      { title: '検査結果ダウンロード', items: attachments.filter(item => item.type !== AttachmentType.Receipt) || [] },
    ],
    [attachments],
  );

  return (
    <>
      {attachmentsGroup.map(attachments => (
        <React.Fragment key={attachments.title}>
          {attachments.items.length > 0 && (
            <Accordion header={<div>{attachments.title}</div>} textBold>
              {attachments.items.map((attachment, index) => (
                <Card key={index} outlineLight menuItem>
                  <button className={downloadTextStyle} onClick={() => onClick?.(attachment.id)}>
                    {attachment.name}
                  </button>
                </Card>
              ))}
            </Accordion>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const downloadTextStyle = css`
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;
