import { css } from '@emotion/css';
import React from 'react';
import { createPortal } from 'react-dom';

import { Icon } from '../../../../components/Icon';
import { BorderRadius, Color, FontSize, IconSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';

export const AccountRestrictedToast = ({ accountRestricted }: { accountRestricted: boolean }) => {
  if (!accountRestricted) {
    return <></>;
  }
  return createPortal(
    <div className={baseStyle}>
      <div className={itemStyle}>
        <Icon name="exclamation-triangle" />
        <span>患者様のシステムのご利用は制限されています</span>
      </div>
    </div>,
    document.body,
  );
};

const baseStyle = css`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: ${gutter(4)};
  transform: translate3d(-50%, 0, 0);
`;

const itemStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 256px;
  padding: ${gutter(4)} ${gutter(8)};
  font-size: ${FontSize.Medium};
  font-weight: 700;
  color: ${Color.FunctionalRedNeutral};
  background: ${Color.FunctionalRedLight};
  border-radius: ${BorderRadius.Regular};
  box-shadow: 0 12px 32px rgb(0 0 0 / 12%);

  > svg {
    margin-right: ${gutter(3)};
    fill: ${Color.FunctionalRedNeutral};
    ${square(IconSize.Large)}
  }
`;
