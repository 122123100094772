import React, { ComponentProps } from 'react';

import { MyPageSectionHeader } from '../MyPageSectionHeader';
import { NewsArticle } from './NewsArticle';

type Props = {
  articles: ComponentProps<typeof NewsArticle>[];
};
export const News = ({ articles }: Props) => (
  <section>
    <MyPageSectionHeader href="/" icon="bell">
      お知らせ
    </MyPageSectionHeader>
    <div>
      {articles.map(article => (
        <NewsArticle key={article.id} title={article.title} date={article.date} id={article.id} />
      ))}
    </div>
  </section>
);
