import { Auth } from 'aws-amplify';
import { action, makeAutoObservable, observable } from 'mobx';

export class ForgotPasswordStore {
  constructor() {
    makeAutoObservable(this, {
      email: observable,
      code: observable,
      newPassword: observable,
      confirmForgotPassword: action,
      forgotPassword: action,
      setCode: action,
      setEmail: action,
      setNewPassword: action,
    });
  }

  public email?: string = undefined;
  public code?: string = undefined;
  public newPassword?: string = undefined;

  public setEmail(email: string) {
    this.email = email;
  }

  public setCode(code: string) {
    this.code = code;
  }

  public setNewPassword(newPassword: string) {
    this.newPassword = newPassword;
  }

  public async forgotPassword() {
    if (!this.email) {
      return false;
    }
    await Auth.forgotPassword(this.email);
    return true;
  }

  public async confirmForgotPassword() {
    if (!this.email || !this.code || !this.newPassword) {
      return false;
    }
    await Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword);
    return true;
  }
}
