import { css } from 'emotion';
import { AnswerInput, AnswerValueInput, Question } from 'gql/gql-types';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { FormItem, FormLayout } from '../../../../components/Form';
import { InterviewReactiveFormItem } from '../../../../components/InterviewForm';
import { Modal } from '../../../../components/Modal';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { GtmClasses } from '../../../../constants/AnalyticsTags';
import { Routes } from '../../../../constants/Routes';
import { Breakpoint, Dimension } from '../../../../constants/Style';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ReservationDetailStore } from '../../../Reservations/ReservationDetail/stores/ReservationDetailStore';
import { Interviews } from '../stores/Interviews';
import { InterviewDiscardPrompt } from './components/InterviewDiscardPrompt';
import { InterviewProgress } from './components/InterviewProgress';
import { InterviewWarningCard } from './components/InterviewWarningCard';
import { FeverDomain } from './fever';
import { transformer } from './transformer';

type AnswerAction = {
  type: 'ANSWER';
  answer: AnswerInput;
  isConditional?: boolean;
};
type action =
  | AnswerAction
  | {
      type: 'OVERWRITE';
      initialState: AnswerInput[];
    };

const reducer = (previewState: AnswerInput[], action: action) => {
  switch (action.type) {
    case 'ANSWER':
    default:
      return [
        ...previewState.slice(
          0,
          previewState.findIndex(s => s.id === action.answer.id),
        ),
        action.answer,
        ...previewState.slice(previewState.findIndex(s => s.id === action.answer.id) + 1),
      ];
    case 'OVERWRITE':
      return [...action.initialState];
  }
};

const getDefaultValue = ({ answer }: Pick<Question, 'answer'>): AnswerValueInput[] => {
  if (answer && answer.length > 0) {
    return answer.map(a => ({
      id: String(a?.option?.id || 0),
      label: a?.option?.label || '',
      value: a?.answer || '',
    }));
  }
  return [];
};

type Prop = {
  onNext?: () => void;
  loading?: boolean;
};

const filterAnswerBefore = (answers: AnswerInput[], action: AnswerAction) => {
  const reduced = reducer(answers, action);
  const index = reduced.findIndex(x => x.id === action.answer.id);
  return reduced.slice(0, index + 1);
};
const filterQuestionBefore = (interviews: Interviews, action: AnswerAction) => {
  const index = interviews.interview?.questions?.findIndex(x => x?.id === action.answer.id);
  return index ? interviews.interview?.questions?.slice(0, index + 1) : [];
};

const validate = (answers: AnswerInput[], questions: NonNullable<Interviews['interview']>['questions']) =>
  answers.length > 0 &&
  !answers.some(a => {
    if (questions?.some(q => q?.id && String(q.id) === String(a.id) && transformer(answers, q).required)) {
      return a.answer.length === 0 || a.answer.some(item => item.value === '');
    }
    return false;
  });

const shouldReAnswer = (action: AnswerAction, interviews: Interviews, answers: AnswerInput[]) => {
  if (!action.isConditional) {
    return false;
  }
  return (
    validate(reducer(answers, action), filterQuestionBefore(interviews, action)) &&
    (answers.find(a => String(a.id) === String(action.answer.id))?.answer.length || 0) > 0
  );
};

export const InterviewsInput = observer(({ onNext, loading }: Prop) => {
  const interviews = useContext(Interviews.Context);
  const reservation = useContext(ReservationDetailStore.Context);
  const { reservationId } = useReservationId();
  const { push: nativePush } = useHistory();
  const [doAnswerInterview, answerInterviewStatus] = useTransaction((answers: AnswerInput[]) =>
    interviews.answerInterview({
      treatmentId: interviews.treatmentId,
      answers,
      isSubmitted: false,
    }),
  );
  const [doReAnswerInterview, reAnswerInterviewStatus] = useTransaction((answers: AnswerInput[]) =>
    interviews.reAnswerInterview({
      treatmentId: interviews.treatmentId,
      answers,
    }),
  );
  const [doFetchReservation, fetchReservationStatus] = useTransaction(() =>
    reservation.fetchReservation(reservationId),
  );
  const [answers, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({
      type: 'OVERWRITE',
      initialState: (interviews.interview?.questions || []).map(q => ({
        id: String(q?.id || 0),
        answer: q ? getDefaultValue(q) : [],
      })),
    });
  }, [interviews.interview]);

  useEffect(() => {
    validate(answers, interviews.interview?.questions || []) &&
      !answerInterviewStatus.running &&
      !interviews.interview?.finished &&
      doAnswerInterview(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const onChange = async (answer: AnswerInput, isConditional?: boolean) => {
    const action: AnswerAction = { type: 'ANSWER', answer, isConditional };
    // 症状が変更された場合のみ、バリデーションを無視して送信して表示を洗い替える。
    // 問診API側の対応ができていないためいったん塞ぐ。
    if (shouldReAnswer(action, interviews, answers)) {
      doReAnswerInterview(filterAnswerBefore(answers, action));
    }
    dispatch(action);
  };

  const [discardPrompt, setDiscardPrompt] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    doFetchReservation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [doDiscard, discardStatus] = useTransaction(async (department?: string) => {
    await reservation.fetchReservation(reservationId).then(() => reservation.setToCanceled());
    addToast({ message: '予約を取り消しました。', icon: 'check-circle' });
    nativePush({ pathname: Routes.Paths.ReservationNew, search: department ? `?department=${department}` : undefined });
  });

  const displayQuestions = useMemo(
    () =>
      FeverDomain.filterQuestions(
        interviews.interview?.questions || [],
        answers,
        reservation.reservation?.department.id || '',
      ),
    [answers, interviews.interview?.questions, reservation.reservation?.department.id],
  );

  const visibleFeverWarning = useMemo(
    () => FeverDomain.feverWarning(displayQuestions, answers, reservation.reservation?.department.id || ''),
    [answers, displayQuestions, reservation.reservation?.department.id],
  );

  return (
    <>
      <DocumentTitle title={`事前問診 - ${reservation.reservation?.department.name}`} />
      <Modal visible={!!reAnswerInterviewStatus.running}>
        <Card>問診を読み込み中です。</Card>
      </Modal>
      <div className={interviewProgressStyle}>
        <InterviewProgress
          total={interviews.estimatedQuestions}
          answered={answers.filter(a => a.answer.length > 0).length}
        />
      </div>
      <Wrapper>
        <Caption type="header" underline align="center">
          事前問診
        </Caption>
        <FormLayout>
          <FormItem>
            <Button block ghost onClick={() => setDiscardPrompt(true)}>
              診療科目を選びなおす
            </Button>
            <InterviewDiscardPrompt
              open={discardPrompt}
              onClose={() => setDiscardPrompt(false)}
              onCommit={() => doDiscard()}
              disabled={discardStatus.running}
            />
          </FormItem>
          {displayQuestions.map(
            (question, inx) =>
              question && (
                <InterviewReactiveFormItem
                  def={transformer(answers, question)}
                  onChange={value =>
                    onChange({ id: String(question.id || 0), answer: value }, question.isConditional ?? false)
                  }
                  value={answers[inx] ? answers[inx].answer : []}
                  key={inx}
                  analyticsClassName={`${GtmClasses.gtm.registration.preInterview.interviewForm}  ${
                    reservation.reservation?.department.id
                  }_${inx + 1}`}
                />
              ),
          )}
          {answerInterviewStatus.running && <Card>次の問診を読み込み中です。</Card>}
          {(loading || fetchReservationStatus.running) && <Card>問診を読み込み中です。</Card>}
          {visibleFeverWarning && (
            <FormItem>
              <InterviewWarningCard onClickCancel={() => doDiscard()} disabled={discardStatus.running} />
            </FormItem>
          )}
          <FormItem>
            {!visibleFeverWarning &&
              interviews.interview?.finished &&
              validate(answers, interviews.interview.questions) && (
                <>
                  <ButtonGroup gutter={10}>
                    <Button
                      block
                      onClick={async () => {
                        await doAnswerInterview(answers);
                        if (interviews.interview?.finished) {
                          onNext?.();
                        }
                      }}
                      disabled={loading || answerInterviewStatus.running}
                    >
                      確認画面に進む
                    </Button>
                  </ButtonGroup>
                </>
              )}
          </FormItem>
        </FormLayout>
      </Wrapper>
    </>
  );
});

const interviewProgressStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: ${Dimension.GlobalNavigationWidth}px;
  }
`;
