import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { AppleCalendarButton } from '../../../components/CalendarButton/AppleCalendarButton';
import { GoogleCalendarButton } from '../../../components/CalendarButton/GoogleCalendarButton';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { useReservationId } from '../../../hooks/useReservationId';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { AnnouncementStore } from '../../../stores/AnnouncementStore';
import { ReservationDetailStore } from '../../Reservations/ReservationDetail/stores/ReservationDetailStore';

const imageStyle = css`
  display: flex;
  justify-content: center;
`;

export const InterviewsComplete = observer(() => {
  useScrollToTop();
  const { reservationId, push } = useReservationId();
  const store = useMemo(() => new ReservationDetailStore(), []);
  const announcementStore = useMemo(() => new AnnouncementStore(), []);

  useEffect(() => {
    store.fetchReservation(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  useEffect(() => {
    announcementStore.setDepartment(store.reservation?.department);
    announcementStore.setTreatmentKind(store.reservation?.treatmentKind);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.reservation]);

  const handleGoogleCalendar = () => {
    push(Routes.Paths.GoogleCalendarCoopSetting, reservationId);
  };
  return (
    <Wrapper>
      <DocumentTitle title="問診のご協力ありがとうございます" />
      <div className={imageStyle}>
        <img src="/images/doctors_bowing.png" alt="医師がお辞儀をしている画像" />
      </div>
      <CaptionGroup>
        <Caption type="header">問診のご協力ありがとうございます</Caption>
        <Caption type="body">{announcementStore.visitingInformation}</Caption>
      </CaptionGroup>
      {announcementStore.requirement && (
        <div className={requirementStyle}>
          <Card>
            <Caption type="header">ご協力いただきたい事項</Caption>
            <Caption type="danger" break>
              {announcementStore.requirement}
            </Caption>
          </Card>
        </div>
      )}
      <ButtonGroup>
        <Button block onClick={() => push(Routes.Paths.ReservationShow, reservationId)}>
          予約内容を確認する
        </Button>
        {store.reservation?.date && (
          <AppleCalendarButton date={store.reservation.date} displayDuration={store.reservation.displayDuration} />
        )}
        <GoogleCalendarButton onClick={() => handleGoogleCalendar()} />
      </ButtonGroup>
    </Wrapper>
  );
});

const requirementStyle = css`
  margin: 16px 0;
`;
