import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { FormItem, FormLabel } from '../../../../../../components/Form';
import { Modal } from '../../../../../../components/Modal';
import { TextField } from '../../../../../../components/TextField';
import { Wrapper } from '../../../../../../components/Wrapper';
import { useContext } from '../../../../../../hooks/useContext';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { IndividualCheckupReservationStore } from '../stores/IndividualCheckupReservationStore';
import { CheckupPlanSelect } from './components/CheckupPlanSelect';
import { GroupedCheckupOptionSelect } from './components/GroupedCheckupOptionSelect';

type Props = {
  onNext?: () => void;
};

export const IndividualCheckupPlanSelect = observer(({ onNext }: Props) => {
  const store = useContext(IndividualCheckupReservationStore.Context);
  const [doDiscount, discountStatus] = useTransaction(() => store.fetchMasters());

  return (
    <Wrapper>
      <Modal visible={!!discountStatus.running}>
        <Card>読み込み中です・・・</Card>
      </Modal>
      <CaptionGroup>
        <Caption type="header" underline align="center">
          プラン選択
        </Caption>
      </CaptionGroup>
      <FormItem gutter={4}>
        <FormLabel>紹介コード</FormLabel>
        <TextField
          value={store.discountCode || ''}
          onChange={v => store.setDiscountCode(v)}
          onFocusout={() => doDiscount()}
          color="white"
        />
      </FormItem>
      <FormItem gutter={4}>
        <FormLabel>プラン</FormLabel>
        <Card>
          <CheckupPlanSelect
            options={store.selectableCourses.map(c => ({
              label: c.name,
              value: c.id,
              price: c.regularPrice,
              discountPrice: c.price !== c.regularPrice ? c.price : undefined,
              description: c.description,
            }))}
            onChange={v => {
              const selected = store.selectableCourses.find(c => c.id === v);
              selected && store.setCourse(selected);
              selected?.cancelPolicies && store.cancelPolicies.setCancelPolicies(selected.cancelPolicies);
            }}
            value={store.course?.id}
          />
        </Card>
      </FormItem>
      <FormItem gutter={4}>
        <FormLabel>希望するオプションを選択ください。 受診可能な予約枠をご提案します。</FormLabel>
        <Card>
          <GroupedCheckupOptionSelect
            checkupOptions={store.beforeReservingOptions.map(s => ({
              billingTarget: s.billType,
              description: s.description,
              discountAmount: s.price,
              estimatedDuration: s.requiredMin,
              id: s.id,
              label: s.name,
              regularAmount: s.regularPrice,
              subTitle: s.subTitle || '',
              warning: s.warning || undefined,
            }))}
            checkedOptionIds={store.options.map(o => o.id)}
            onToggle={id => store.toggleOption(id)}
          />
        </Card>
      </FormItem>
      <ButtonGroup gutter={10}>
        <Button block onClick={onNext} disabled={!store.course}>
          次へ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
