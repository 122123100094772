import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { DocumentTitle } from '../../components/DocumentTitle';
import { Routes } from '../../constants/Routes';
import { useContext } from '../../hooks/useContext';
import { SessionStore } from '../../stores/SessionStore';

export const NotFoundPage = observer(() => {
  const session = useContext(SessionStore.Context);
  const authSuggestion = useMemo(
    () => session.authStateKnown && !session.authenticated && Routes.AuthRoutes.some(r => Routes.matches(r)),
    [session],
  );

  return (
    <>
      <DocumentTitle title="お探しのページは見つかりませんでした" />
      <h1>404</h1>
      <p>お探しのページは見つかりませんでした </p>
      <p>{new URL(window.location.href).pathname}</p>
      {authSuggestion ? (
        <p>
          <a className={linkStyle} href={Routes.Paths.AccountNew} target="_blank" rel="noopener noreferrer">
            ログイン
          </a>
          後にこのページを再読み込みすれば見つかる可能性があります。
        </p>
      ) : null}
    </>
  );
});

const linkStyle = css`
  text-decoration: underline;
`;
