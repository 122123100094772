import { css } from 'emotion';
import React, { ReactNode, useMemo } from 'react';

import { Caption } from '../../../../../../../../components/Caption';
import { Checkbox } from '../../../../../../../../components/Checkbox';
import { Icon } from '../../../../../../../../components/Icon';
import { Tip } from '../../../../../../../../components/Tip';
import { Color, FontSize } from '../../../../../../../../constants/Style';
import { square } from '../../../../../../../../helpers/Style';

type Props = {
  checkupOptions: CheckupOption[];
} & CheckboxIOProps;

type CheckboxIOProps = {
  checkedOptionIds?: string[];
  onToggle?: (id: string) => void;
};

type CheckupOption = {
  id: string;
  subTitle: string;
  warning?: string;
  label: string;
  discountAmount: number;
  regularAmount: number;
  description?: string;
  billingTarget: '1_organization' | '2_own';
  estimatedDuration: number;
};

type GroupedOption = {
  subTitle: string;
  warning?: string;
  options: CheckupOption[];
};

const reorderEmptyArray = (input: GroupedOption[]) => [
  ...input.filter(x => x.subTitle),
  ...input
    .filter(x => !x.subTitle)
    .map(x => ({
      ...x,
      subTitle: 'その他',
    })),
];

export const GroupedCheckupOptionSelect = ({ checkupOptions, checkedOptionIds, onToggle }: Props) => {
  const groups = useMemo(
    () =>
      reorderEmptyArray(
        checkupOptions.reduce((prev, elem) => {
          const existentInx = prev.findIndex(x => x.subTitle === elem.subTitle);
          if (existentInx <= -1) {
            return [
              ...prev,
              {
                subTitle: elem.subTitle,
                warning: elem.warning,
                options: [elem],
              },
            ];
          }
          return [
            ...prev.slice(0, existentInx),
            {
              ...prev[existentInx],
              options: [...prev[existentInx].options, elem],
            },
            ...prev.slice(existentInx + 1),
          ];
        }, new Array<GroupedOption>()),
      ),
    [checkupOptions],
  );

  return (
    <>
      {groups.map((group, key) => (
        <CheckupOptionGroup group={group} key={key} onToggle={onToggle} checkedOptionIds={checkedOptionIds} />
      ))}
    </>
  );
};

type GroupProps = {
  group: GroupedOption;
} & CheckboxIOProps;

const CheckupOptionGroup = ({ group, onToggle, checkedOptionIds }: GroupProps) => (
  <div>
    <div className={subHeaderStyle}>
      <Caption type="subheader">{group.subTitle}</Caption>
      <div className={warningStyle}>{group.warning}</div>
    </div>
    <hr />
    {group.options.map((o, innerKey) => (
      <div className={checkupOptionCheckboxStyle} key={innerKey}>
        <CheckupOptionCheckbox
          option={o}
          key={innerKey}
          checked={checkedOptionIds?.includes(o.id)}
          onToggle={() => onToggle?.(o.id)}
        />
      </div>
    ))}
  </div>
);

const subHeaderStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const warningStyle = css`
  font-size: ${FontSize.Small};
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;

type OptionProps = {
  option: CheckupOption;
  checked?: boolean;
  onToggle?: () => void;
};

const labelContainerStyle = css`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const CheckupOptionCheckbox = ({ option: o, checked, onToggle }: OptionProps) => (
  <div>
    <div className={optionContainerStyle}>
      <Checkbox checked={checked} onChange={() => onToggle?.()} />
      <div className={labelContainerStyle}>
        <div>{o.label}</div>
        <CheckupOptionHelp />
      </div>
      <CheckupOptionSuffix>
        <CheckupOptionAmount regularAmount={o.regularAmount} discountAmount={o.discountAmount} />
      </CheckupOptionSuffix>
    </div>
    <CheckupOptionCaption>{o.description}</CheckupOptionCaption>
    <CheckupOptionFooter billingTarget={o.billingTarget} estimatedDuration={o.estimatedDuration} />
  </div>
);

const checkupOptionCheckboxStyle = css`
  margin: 24px 0;
`;

type BaseProps = {
  children: ReactNode;
};
const CheckupOptionCaption = ({ children }: BaseProps) => (
  <div className={optionCaptionStyle}>
    <div className={captionStyle}>{children}</div>
  </div>
);

const captionStyle = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;

const optionCaptionStyle = css`
  margin: 8px 0;
`;

const CheckupOptionSuffix = ({ children }: BaseProps) => <div className={suffixStyle}>{children}</div>;

const suffixStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const CheckupOptionHelp = () => (
  <div className={checkupOptionHelpStyle}>
    <Icon name="question-circle" />
  </div>
);

const checkupOptionHelpStyle = css`
  display: flex;
  align-items: center;

  > svg {
    ${square(FontSize.Medium)}

    color: ${Color.GrayscaleNeutral};
    fill: ${Color.GrayscaleNeutral};
  }
`;

const optionContainerStyle = css`
  display: grid;
  grid-template-columns: 20px 1fr max-content;
  align-items: center;
  justify-content: space-between;
`;

type AmountProps = Pick<CheckupOption, 'regularAmount' | 'discountAmount'>;

const CheckupOptionAmount = ({ regularAmount, discountAmount }: AmountProps) => (
  <div>
    {regularAmount === discountAmount ? (
      <div className={discountAmountStyle}>¥{regularAmount.toLocaleString()}</div>
    ) : (
      <div>
        <div className={discountAmountStyle}>¥{discountAmount.toLocaleString()}</div>
        <s className={regularAmountStyle}>¥{regularAmount.toLocaleString()}</s>
      </div>
    )}
  </div>
);

const discountAmountStyle = css`
  font-size: ${FontSize.Medium};
  line-height: 0.12;
  color: ${Color.GrayscaleDarker};
`;

const regularAmountStyle = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;

type CheckupOptionFooterProps = Pick<CheckupOption, 'estimatedDuration' | 'billingTarget'>;
const CheckupOptionFooter = ({ estimatedDuration, billingTarget }: CheckupOptionFooterProps) => (
  <div className={checkupOptionFooterStyle}>
    <div>{billingTarget === '1_organization' ? <Tip className={tipStyle}>会社負担</Tip> : <></>}</div>
    <div className={checkupOptionFooterDurationStyle}>所要時間：約{estimatedDuration}分</div>
  </div>
);

const tipStyle = css`
  padding: 0 3px;
  color: ${Color.GrayscaleNeutral};
  border-color: ${Color.GrayscaleDarker};
`;

const checkupOptionFooterStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const checkupOptionFooterDurationStyle = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;
