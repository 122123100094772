import { urlSafeEncode } from '@aws-amplify/core';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { DepartmentType } from '../../../../../../gql/gql-types';
import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { AppleCalendarButton } from '../../../../../components/CalendarButton/AppleCalendarButton';
import { GoogleCalendarButton } from '../../../../../components/CalendarButton/GoogleCalendarButton';
import { Icon } from '../../../../../components/Icon';
import { GtmClasses } from '../../../../../constants/AnalyticsTags';
import { Routes } from '../../../../../constants/Routes';
import { Color, FontSize } from '../../../../../constants/Style';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { ReservationDetailStore } from '../../stores/ReservationDetailStore';

type Props = {
  reservation: Reservation;
};

/**
 * 予約詳細において、可能なアクションを表現するコンポネント。<br/>
 * アクション自体を定義するプロバイダロジックと、それをUIに反映するコンポネントの組になっている。
 */
export const ReservationDetailActions = ({ reservation }: Props) => {
  const actions = useReservationDetailActions(reservation);
  return <ButtonGroup gutter={20}>{actions}</ButtonGroup>;
};

type Reservation = ReservationDetailStore['reservation'];

/**
 * ベースとなるプロバイダロジック
 * @param reservation
 * @returns
 */
const useReservationDetailActions = (reservation: Reservation): ReactNode[] => [
  ...useIndividualCheckupInterview(reservation),
  ...useCalendar(reservation),
  ...useEditAction(reservation),
  ...usePreInterviewShow(reservation),
];

const useIndividualCheckupInterview = (reservation: Reservation): ReactNode[] => {
  const { push } = useReservationId();
  if (!reservation) {
    return [];
  }

  if (reservation.department.type !== DepartmentType.Checkup) {
    return [];
  }

  if (reservation.interview?.submitted) {
    return [];
  }

  if (!reservation.isIndividualCheckup) {
    return [];
  }

  return [
    <div>
      <div className={interviewWarningStyle}>事前問診にご回答ください</div>
      <Button
        block
        onClick={() => push(Routes.Paths.CheckupReservationFixIndividual, reservation.id)}
        className={dangerStyle}
      >
        <Icon name="medical-checkup" />
        問診の回答・オプション追加
      </Button>
    </div>,
  ];
};

const dangerStyle = css`
  background: ${Color.FunctionalRedNeutral};
`;

const interviewWarningStyle = css`
  margin-bottom: 10px;
  font-size: ${FontSize.Regular};
  color: ${Color.FunctionalRedNeutral};
`;

const useEditAction = (reservation: Reservation): ReactNode[] => {
  const { push } = useReservationId();
  if (!reservation) {
    return [];
  }
  if (reservation.date <= new Date()) {
    return [];
  }

  if (reservation.department.type === DepartmentType.Checkup && reservation.interview?.submitted) {
    return [
      <Button ghost onClick={() => push(Routes.Paths.ReservationActions, reservation.id)}>
        <Icon name="clock-three" />
        予約時間・オプションの変更・取消
      </Button>,
    ];
  }

  return [
    <Button ghost onClick={() => push(Routes.Paths.ReservationActions, reservation.id)}>
      <Icon name="clock-three" />
      予約時間の変更・取消
    </Button>,
  ];
};

const useCalendar = (reservation: Reservation): ReactNode[] => {
  if (!reservation) {
    return [];
  }

  return [
    <AppleCalendarButton date={reservation.date} displayDuration={reservation.displayDuration} />,
    <GoogleCalendarButton
      onClick={() => {
        window.location.replace(Routes.Paths.GoogleCalendarCoopSetting.replace(':uuid', urlSafeEncode(reservation.id)));
      }}
    />,
  ];
};

const usePreInterviewShow = (reservation: Reservation): ReactNode[] => {
  const { push } = useReservationId();
  if (!reservation || reservation.noInterview) {
    return [];
  }

  const props = {
    ghost: true,
    className: GtmClasses.gtm.startInterview.fromMyPage,
    children: (
      <>
        <Icon name="medical-checkup" />
        事前問診の確認・編集
      </>
    ),
  };

  const isCheckup = reservation.department.type && reservation.department.type === DepartmentType.Checkup;
  if (isCheckup) {
    // 健診の場合

    if (reservation.interview?.submitted) {
      return [<Button {...props} onClick={() => push(Routes.Paths.CheckupPreInterviewShow, reservation.id)} />];
    }

    if (!reservation.isIndividualCheckup) {
      return [
        <Button {...props} onClick={() => push(Routes.Paths.CheckupPreInterviewPostReserveEdit, reservation.id)} />,
      ];
    }

    // 個人健診は別途ボタン配置
    return [];
  }

  // 健診以外の場合
  if (!reservation.interview?.submitted) {
    return [<Button {...props} onClick={() => push(Routes.Paths.ReservationPreInterview, reservation.id)} />];
  }

  return [<Button {...props} onClick={() => push(Routes.Paths.ReservationPreInterviewShow, reservation.id)} />];
};
