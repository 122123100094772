import { color } from 'csx';
import { injectGlobal } from 'emotion';

import { Color, FontFamily } from '../constants/Style';

/**
 * Margin や Padding など余白の値を算出して返す。
 *
 * 余白は 4 の倍数として定義されている。
 * @param value
 */
export function gutter(value: number): string {
  return `${4 * value}px`;
}

/**
 * 矩形サイズを返す。
 *
 * @param value 一辺の長さ
 */
export function square(value: string | number) {
  return {
    width: value,
    height: value,
  };
}

/**
 * rgba カラーモデルの値（文字列）を生成して返す。
 *
 * @param hex HEX 値のカラーコード
 * @param opacity 不透明度
 *
 * @example
 * toRGBA('#0ff', 0.5) //=> rgba(0, 255, 255, 0.5)
 */
export function toRGBA(hex: Color, opacity: number) {
  const rgb = color(hex);
  return `rgba(${rgb.red()}, ${rgb.green()}, ${rgb.blue()}, ${opacity})`;
}

export function applyGlobalStyle() {
  injectGlobal`
    /* Reset */
    /* 以下を参考 */
    /* https://github.com/hankchizljaw/modern-css-reset/blob/master/dist/reset.min.css */
    *, *:before, *:after {
      box-sizing: border-box;
    }
    html {
      font-family: sans-serif;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
    body {
      min-height: 100vh;
      text-rendering: optimizeSpeed;
      line-height: 1.5;
    }
    a:not([class]) {
      text-decoration-skip-ink: auto;
    }
    img,
    picture {
      max-width: 100%;
      display: block;
    }
    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    /* Scaffold */
    html,
    body {
      margin: 0;
      padding: 0;
      color: ${Color.GrayscaleDarker};
      font-family: ${FontFamily.Regular};
      font-feature-settings: "palt" 1;
      background: ${Color.TextureBody};
    }
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul,
    ol,
    figure,
    blockquote,
    dl,
    dd {
      margin: 0;
    }
    ul,
    ol {
      padding: 0;
      list-style: none;
    }
    a {
      color: ${Color.GrayscaleDarker};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    svg {
      fill: ${Color.GrayscaleDarker};
    }
    /* CU-5vub2k */
    body {
      letter-spacing: 0.01rem;
    }
  `;
}
