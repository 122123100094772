import { css } from 'emotion';
import React from 'react';

import { FontSize } from '../../../../../../constants/Style';
import { gutter } from '../../../../../../helpers/Style';

type Props = {
  href: string;
  alt: string;
  header: string;
  body: string;
  info: string[];
};
export const CheckupReservationStep = ({ alt, href, header, body, info }: Props) => (
  <div className={checkupStepStyle}>
    <img src={href} alt={alt} />
    <div>
      <h3 className={checkupStepHeaderStyle}>{header}</h3>
      <p className={checkupStepDescriptionStyle}>{body}</p>
      {info.map((s, i) => (
        <p key={i} className={checkupStepDescriptionInfoStyle}>
          {s}
        </p>
      ))}
    </div>
  </div>
);

const checkupStepStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: ${gutter(7)} 0;
  text-align: center;
`;

const checkupStepHeaderStyle = css`
  margin: ${gutter(3)} 0;
`;

const checkupStepDescriptionStyle = css`
  font-size: ${FontSize.Regular};
  text-align: left;
`;

const checkupStepDescriptionInfoStyle = css`
  font-size: ${FontSize.Regular};
  font-weight: bold;
  text-align: left;
`;
