import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../../gql/client';
import {
  GetCheckupTreatmentWithInterviewQuery,
  GetCheckupTreatmentWithInterviewQueryVariables,
  InterviewTypeFragment,
} from '../../../../../../gql/gql-types';
import { getCheckupTreatmentWithInterview } from '../../../../../../gql/operations/getCheckupTreatmentWithInterview';

export class CheckupPreInterviewStore {
  constructor() {
    makeAutoObservable(this, {
      interview: observable,
      preInterview: observable,
      id: observable,
      fetchInterviews: action,
    });
  }

  public interview?: InterviewTypeFragment = undefined;
  public preInterview?: InterviewTypeFragment = undefined;
  public id?: string = undefined;

  public async fetchInterviews(id: string) {
    this.id = id;

    const res = await GQL.query<GetCheckupTreatmentWithInterviewQueryVariables, GetCheckupTreatmentWithInterviewQuery>(
      getCheckupTreatmentWithInterview,
      {
        id,
      },
    );

    if (!res.getTreatment) {
      return;
    }

    this.interview = res.getTreatment.interview || undefined;
    this.preInterview = res.getTreatment.preInterview || undefined;
  }
}
