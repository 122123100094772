import { action, makeAutoObservable, observable } from 'mobx';

export class PolicyStore {
  constructor() {
    makeAutoObservable(this, {
      body: observable,
      updatedAt: observable,
      fetchPrivacyPolicy: action,
      fetchTerms: action,
    });
  }

  public body?: string = undefined;
  public updatedAt?: string = undefined;

  public async fetchTerms() {
    const res = await (
      await fetch('https://clinicten.microcms.io/api/v1/terms', {
        headers: new Headers({
          'X-API-KEY': '5c25d338-65d0-4cce-a044-892730fc24ab',
        }),
      })
    ).json();
    this.body = res.body;
    this.updatedAt = res.updatedAt;
  }

  public async fetchPrivacyPolicy() {
    const res = await (
      await fetch('https://clinicten.microcms.io/api/v1/privacy', {
        headers: new Headers({
          'X-API-KEY': '5c25d338-65d0-4cce-a044-892730fc24ab',
        }),
      })
    ).json();
    this.body = res.body;
    this.updatedAt = res.updatedAt;
  }
}
