import { createContext, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

export const useScrollTo = () => {
  const refMap = useMemo(() => new Map<string, HTMLDivElement>(), []);
  const scrollToWithKey = useCallback(
    (key: string) => {
      const elem = refMap.get(key);
      if (!elem) {
        return;
      }
      elem.scrollIntoView();
    },
    [refMap],
  );

  return { scrollTo: scrollToWithKey, scrollMap: refMap };
};

export const ScrollToElementContext = createContext(new Map<string, HTMLDivElement>());

type NullRef<X> = MutableRefObject<X | null>;

const useNullableRef = <X>() => {
  const ref = useRef<X>();
  return ref as NullRef<X>;
};

export const useScrollAnchor = (key: string) => {
  const ref = useNullableRef<HTMLDivElement>();
  const context = useContext(ScrollToElementContext);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    context.set(key, ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
  return ref;
};
