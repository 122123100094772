import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../../../components/Button';
import { Caption } from '../../../../components/Caption';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';

export const AccountsNewPharmacyPage = observer(() => {
  const { push } = useHistory();
  return (
    <Wrapper>
      <Caption type="header">アカウントの登録にご協力お願いします</Caption>
      <Caption type="body">
        当薬局では、LINEを活用した服薬指導を行っております。当薬局内のシステムとお客様の情報を関連付けするため、アカウントの登録とお名前の入力へのご協力をお願いします。
      </Caption>
      <Button
        block
        theme="line"
        onClick={() =>
          push({
            pathname: Routes.Paths.AccountNew,
            search: new URLSearchParams({
              type: 'LINE',
              action: 'pharmacyRegister',
            }).toString(),
          })
        }
      >
        LINEでアカウント登録する
      </Button>
    </Wrapper>
  );
});
