import { format } from 'date-fns/esm';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';

type Props = {
  title: string;
  updatedAt: Date;
};
export const FaqTitle = ({ title, updatedAt }: Props) => (
  <Card>
    <div>{title}</div>
    <Caption type="small">{format(updatedAt, 'yyyy年M月d日 HH:mm')}</Caption>
  </Card>
);
