import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { CheckupReservationStep } from '../../../../Reservations/CheckupReservationsNew/CheckupReservationStart/components/CheckupReservationStep';
import { IndividualCheckupReservationStore } from '../stores/IndividualCheckupReservationStore';

type Props = {
  onNext?: () => void;
};

export const IndividualCheckupReservationConfirmPage = ({ onNext }: Props) => {
  const { push } = useHistory();
  const store = useContext(IndividualCheckupReservationStore.Context);
  useEffect(() => {
    store.fetchMasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const pushToDock = () => {
  //   push({ pathname: Routes.Paths.DockReservationNewIndividual });
  // };

  return (
    <Wrapper>
      <CaptionGroup>
        <Caption type="header" align="center" underline>
          健康診断ご予約の流れ
        </Caption>
      </CaptionGroup>

      {Steps.map((Step, key) => (
        <CheckupReservationStep
          key={key}
          href={Step.href}
          header={Step.header}
          body={Step.body}
          info={Step.info}
          alt={Step.alt}
        />
      ))}

      <ButtonGroup gutter={10}>
        <Button block onClick={onNext}>
          次へ
        </Button>
        <Button block ghost onClick={() => push(Routes.Paths.ReservationNew)}>
          戻る
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};

const Steps = [
  {
    href: '/images/checkup-3.svg',
    header: '1.予約日時の選択',
    body: '氏名、生年月日、受診されるコース、予約日時を選択していただきます。',
    info: [],
    alt: 'カレンダーのイラスト',
  },
  {
    href: '/images/checkup-1.svg',
    body: '事前問診にお答えください。',
    info: [],
    header: '2.事前問診(1-2分)に答える',
    alt: '事前問診のイラスト',
  },
  {
    href: '/images/checkup-2.svg',
    header: '3.追加検査オプションの選択',
    body: '事前問診から、あなたに合った追加検査オプションを選択することができます。',
    info: ['※検査結果は1ヶ月程度でお知らせします。余裕をもってご予約ください。'],
    alt: '検査のイラスト',
  },
];
