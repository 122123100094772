import { action, computed, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../../../gql/client';
import {
  FixTreatmentMutation,
  FixTreatmentMutationVariables,
  GetTemporallyTreatmentQuery,
  GetTemporallyTreatmentQueryVariables,
  Maybe,
  ShouldConfirmQuery,
  ShouldConfirmQueryVariables,
} from '../../../../../../../gql/gql-types';
import fixTreatment from '../../../../../../../gql/operations/fixTreatment';
import { getTemporallyTreatment } from '../../../../../../../gql/operations/getTemporallyTreatment';
import { shouldConfirm } from '../../../../../../../gql/operations/shouldConfirm';
import { HandledError } from '../../../../../../components/ErrorDialog';
import { Address } from '../../../../../../domains/user/Address';
import { InsuranceCardStore } from '../../../../AccountDetail/AccountEdit/components/InsuranceCardInput/stores/InsuranceCardStore';
import { CreditCardStore } from '../../../../store/CreditCardStore';
import { Name } from '../../../stores/ProfileStore';

type Clinic = {
  id: string;
  name: string;
  termsUrl?: Maybe<string>;
};

export class ReservationTemporallyStore {
  constructor() {
    makeAutoObservable(this, {
      clinic: observable,
      fetch: action,
      concentRequired: observable,
      name: observable,
      setName: action,
      valid: computed,
      newComer: observable,
      setNewComer: action,
      department: observable,
      fever: computed,
      phone: observable,
      address: observable,
      fix: action,
      id: observable,
      insuranceCards: observable,
      setAddress: action,
      setPhone: action,
      profession: observable,
      setProfession: action,
      errors: computed,
      mail: observable,
      setMail: action,
      card: observable,
      setCard: action,
    });
  }

  public clinic?: Clinic = undefined;
  public concentRequired?: boolean | null = undefined;
  public newComer?: boolean = undefined;
  public name?: Name = undefined;
  public id?: string = undefined;
  public department?: string = undefined;
  public treatmentKindId?: string = undefined;

  public get fixable() {
    return this.valid && !this.concentRequired;
  }

  public async fetch(id: string) {
    const treatment = await GQL.queryAsGuest<GetTemporallyTreatmentQueryVariables, GetTemporallyTreatmentQuery>(
      getTemporallyTreatment,
      { id },
    );

    if (!treatment.getTemporallyTreatment) {
      throw new HandledError(
        '原因は15分以内に予約が完了されなかったためと考えられます。お手数ですが、再度はじめから予約をお願い致します。',
      );
    }

    this.concentRequired = (
      await GQL.query<ShouldConfirmQueryVariables, ShouldConfirmQuery>(shouldConfirm, {
        input: {
          clinic: treatment.getTemporallyTreatment.clinic?.id || '00',
        },
      })
    ).shouldConfirm?.confirmRequired;

    this.clinic = treatment.getTemporallyTreatment.clinic || undefined;

    this.id = id;

    this.department = treatment.getTemporallyTreatment.department?.id;
    this.treatmentKindId = treatment.getTemporallyTreatment.treatmentKind?.id;

    this.insuranceCards.setUserId(id);
  }

  public async fix() {
    if (!this.id) {
      return false;
    }
    const res = await GQL.query<FixTreatmentMutationVariables, FixTreatmentMutation>(fixTreatment, {
      input: {
        id: this.id,
        // eslint-disable-next-line no-irregular-whitespace
        name: this.name ? `${this.name.sei}　${this.name.mei}` : '',
        sei: this.name?.sei,
        mei: this.name?.mei,
        mail: this.mail,
        addressLine1: this.address.addressLine1,
        addressLine2: this.address.addressLine2,
        profession: this.profession,
        phone: this.phone,
        zipCode: this.address.zipCode,
        insuranceCards: this.insuranceCards.toInput(),
        pref: this.address.pref,
        cardToken: this.card?.cardToken,
        brand: this.card?.brand,
        expAt: this.card?.expAt,
        fourDigit: this.card?.fourDigit,
      },
    });

    return res.fixTreatment?.id;
  }

  public setName(name: Name) {
    this.name = name;
  }

  public setNewComer(required: boolean) {
    this.newComer = required;
  }

  public get errors() {
    let errors: { field: string; error: string }[] = [];
    if (this.newComer) {
      if (!this.name) {
        return [{ field: 'sei', error: '氏名を入力してください。' }];
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.sei)) {
        errors = [...errors, { field: 'sei', error: '氏名は全角カタカナで入力してください。' }];
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.mei)) {
        errors = [...errors, { field: 'mei', error: '氏名は全角カタカナで入力してください。' }];
      }
    }

    if (this.fever && this.newComer && this.insuranceCards.urls.length <= 0) {
      errors = [...errors, { field: 'insuranceCard', error: '保険証画像をアップロードしてください。' }];
    }

    if (this.fever && !this.phone) {
      errors = [...errors, { field: 'phone', error: '電話番号を入力してください。' }];
    }

    return errors;
  }

  public get valid() {
    return this.errors.length === 0;
  }

  public get fever() {
    return this.department === '09'; // TODO 09ではなく設定を持つ
  }

  public mail?: string = undefined;

  public setMail(mail: string) {
    this.mail = mail;
  }

  // fever
  public phone?: string = undefined;

  public setPhone(phone: string) {
    this.phone = phone;
  }

  public address: Address = {
    addressLine1: '',
    addressLine2: '',
    pref: '',
    zipCode: '',
  };

  public setAddress(address: Address) {
    this.address = address;
  }

  public insuranceCards: InsuranceCardStore = new InsuranceCardStore();

  public profession?: string = undefined;

  public setProfession(profession: string) {
    this.profession = profession;
  }

  public card?: Card = undefined;

  public setCard(card: Card) {
    this.card = card;
  }

  public cardStatus: CreditCardStore = new CreditCardStore();
}

type Card = {
  cardToken: string;
  brand: string;
  fourDigit: string;
  expAt: string;
};
