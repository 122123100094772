import { ComponentProps } from 'react';

import { AnswerInput } from '../../../../../gql/gql-types';
import { InterviewReactiveFormItem } from '../../../../components/InterviewForm';

type QuestionProp = ComponentProps<typeof InterviewReactiveFormItem>['def'];

/**
 * APIから返却された質問を、表示用に加工する
 * @param answers 今までの回答
 * @param question 処理対象の質問
 */
export const transformer = (answers: AnswerInput[], question: QuestionProp): QuestionProp => {
  if (question.isNarrowDown) {
    const narrowDownIndex = answers.findIndex(ans => ans.id === question.id);
    if (narrowDownIndex < 1) {
      return question;
    }
    // 一問前が絞り込みの対象となることは暗黙的に決まっている
    const selectedAnswer = answers[narrowDownIndex - 1].answer;
    return {
      ...question,
      options: question.options
        ?.filter(opt => selectedAnswer.some(o => o.value === opt?.value || o.label === opt?.label))
        .map(opt => ({
          ...opt,
          label: opt?.type === 'input' ? selectedAnswer.find(a => a.label === opt.label)?.value || '' : opt?.label,
          value: opt?.type === 'input' ? selectedAnswer.find(a => a.label === opt.label)?.value || '' : opt?.value,
          type: 'radio',
        })),
    };
  }

  return question;
};
