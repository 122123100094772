import gql from 'graphql-tag';

import { CancelPoliciesType } from '../fragments/cancelPolicies';

export const listIndividualCheckupMasters = gql`
  query listIndividualCheckupMasters($discountCode: String, $union: String, $sex: String, $birthDate: String) {
    getDiscountCheckupIndividualMasters(
      input: { code: $discountCode, union: $union, sex: $sex, birthDate: $birthDate }
    ) {
      plans {
        planId
        regularPrice
        price
      }
      options {
        optionId
        price
        regularPrice
      }
    }
    listCheckupPlanIndividuals {
      items {
        order
        plan {
          id
          label
          description
          options
          treatmentKindId
          treatmentKind {
            ...CancelPoliciesType
          }
          hideForIndividual
        }
      }
    }
    listCheckupOptionIndividuals {
      items {
        option {
          regularPrice
          id
          label
          description
          clinicType
          requiredMin
          treatmentKind {
            id
            name
          }
          order
          subTitle
          warning
        }
      }
    }
  }
  ${CancelPoliciesType}
`;
