import gql from 'graphql-tag';

import { CancelPoliciesType } from './cancelPolicies';

export const CheckupMemberEventType = gql`
  fragment CheckupMemberEventType on CheckupOrganizationMemberEvent {
    id
    name
    email
    address
    hiringDate
    invitedAt
    appointments {
      items {
        id
        treatment {
          items {
            id
            department {
              id
            }
            status
          }
        }
        treatmentStatus
      }
    }
    plan {
      id
      label
      price
      treatmentKindId
      options
      treatmentKind {
        ...CancelPoliciesType
      }
    }
    planBillType
    kitDeliverType
    expenseOptions
    event {
      expenseOptions
      discountCode
      plan {
        id
        label
        price
        treatmentKindId
        options
        treatmentKind {
          ...CancelPoliciesType
        }
      }
      planBillType
      kitDeliverType
      id
      address
      union
      organization {
        id
        name
      }
      options {
        items {
          option {
            id
            label
            regularPrice
            description
            gender
            aboutDisplay
            annotation
            clinicType
            requiredMin
            treatmentKind {
              id
              name
            }
            order
          }
          price
          billType
        }
      }
    }
  }
  ${CancelPoliciesType}
`;
