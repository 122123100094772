import { action, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../../../../gql/client';
import {
  GetInsuranceCardSignedPostQuery,
  GetInsuranceCardSignedPostQueryVariables,
  InsuranceCardInput,
} from '../../../../../../../../gql/gql-types';
import { getInsuranceCardSignedPost } from '../../../../../../../../gql/operations/getInsuranceCardSignedPost';

export class InsuranceCardStore {
  static Context = createContext<InsuranceCardStore | null>(null);
  constructor() {
    makeAutoObservable(this, {
      files: observable,
      addKey: action,
      addUrl: action,
      keys: observable,
      setFiles: action,
      setKeys: action,
      upload: action,
      urls: observable,
      removeKey: action,
      setUrls: action,
      userId: observable,
      setUserId: action,
    });
  }

  public files: File[] = [];

  public setFiles(files: FileList | null) {
    if (!files) {
      this.files = [];
      return;
    }
    this.files = new Array(files.length)
      .fill(undefined)
      .map((_, i) => files.item(i))
      .filter((x): x is File => Boolean(x));
  }

  public setKeys(keys: string[]) {
    this.keys = keys;
  }

  public addKey(key: string) {
    this.keys = [...this.keys, key];
  }

  public addUrl(url: Url) {
    this.urls = [...this.urls, url];
  }

  public setUrls(urls: Url[]) {
    this.urls = urls;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public keys: string[] = [];
  public urls: Url[] = [];
  public userId?: string = undefined;

  public async upload() {
    this.urls = [];

    const userId = this.userId;
    if (!userId) {
      return;
    }
    const newKeys = await Promise.all(
      this.files.map(file =>
        GQL.query<GetInsuranceCardSignedPostQueryVariables, GetInsuranceCardSignedPostQuery>(
          getInsuranceCardSignedPost,
          {
            input: {
              fileName: file.name,
              userId,
            },
          },
        ).then(async res => {
          const signedPost = res.getInsuranceCardSignedPost;
          if (!signedPost) {
            return null;
          }
          const d = new FormData();
          signedPost.fields.forEach(f => d.append(f.key, f.value));
          d.append('file', file, file.name);

          await fetch(signedPost.url, {
            headers: {
              accept: 'multipart/form-data',
            },
            mode: 'no-cors',
            body: d,
            method: 'POST',
          });

          return d.get('key') as string;
        }),
      ),
    ).then(r => r.filter((x): x is string => !!x));

    this.keys = [...this.keys, ...newKeys];
    const newUrls = await Promise.all(
      newKeys.map(async (key, i) => ({ key, filename: this.files[i].name, url: await this.readFile(this.files[i]) })),
    );
    this.urls = [...this.urls, ...newUrls];
    this.setFiles(null);
  }

  private async readFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<string>(res => {
      reader.onload = () => res(String(reader.result || ''));
    });
  }

  removeKey(key: string) {
    this.urls = this.urls.slice(0).filter(x => x.key !== key);
    this.keys = this.keys.slice(0).filter(x => x !== key);
  }

  public toInput(): InsuranceCardInput[] {
    return this.urls.map(u => ({
      key: u.key,
    }));
  }
}

type Url = {
  key: string;
  url: string;
  filename: string;
};
