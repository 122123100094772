import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { FormItem, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import { ProfileStore } from '../../stores/ProfileStore';
import { NameInput } from '../components/NameInput';

export const AccountsNewNameInputPharmacy = observer(() => {
  const { push } = useHistory();
  const store = useMemo(() => new ProfileStore(), []);
  const session = useContext(SessionStore.Context);
  const [doCallback, status] = useTransaction(async () => {
    await store.register();
    push(Routes.Paths.AccountNamePharmacyComplete);
  });

  const [authStateKnown, setAuthStateKnown] = useState(false);
  const [concent, setConcent] = useState(false);

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }

    const user = session.user?.userEntity;
    if (!user) {
      setAuthStateKnown(true);
      return;
    }

    push(Routes.Paths.AccountNamePharmacyComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown]);

  if (!authStateKnown) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        氏名の入力
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <Button type="submit" block onClick={() => doCallback()} disabled={status.running || !store.valid || !concent}>
          登録する
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
