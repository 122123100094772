import format from 'date-fns/format';
import { css, cx } from 'emotion';
import React, { ComponentProps, useMemo } from 'react';

import { Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { Calendar, Time } from '.';
import { TableItem } from './TableItem';

type Props = Required<
  Pick<ComponentProps<typeof Calendar>, 'alertThreshold' | 'onSelect' | 'now' | 'waitingListEnabled'>
> & {
  items: Time[][];
};

export const TableBody = ({ items, alertThreshold, onSelect, now, waitingListEnabled }: Props) => (
  <div className={baseStyle}>
    <div className={timeColumnStyle}>
      {items[0].map(({ date }, i) => (
        <TimeCell key={i} item={date} />
      ))}
    </div>

    {items.map((item, index) => (
      <div key={index} className={itemColumnStyle}>
        {item.map(({ date, available, doctor, estimatedDuration, laneId, waitingListAvailable }, j) => (
          <TableItem
            now={now}
            key={j}
            date={date}
            available={available}
            alertThreshold={alertThreshold}
            onSelect={onSelect}
            doctor={doctor}
            estimatedDuration={estimatedDuration}
            laneId={laneId}
            waitingListAvailable={waitingListEnabled && waitingListAvailable}
          />
        ))}
      </div>
    ))}
  </div>
);

const TimeCell = ({ item }: { item: Date }) => {
  const label = useMemo(() => format(item, 'HH:mm'), [item]);

  return <span className={cx(timeCellStyle, label.includes('00') && timeCellOfClockStyle)}>{label}</span>;
};

const baseStyle = css`
  display: flex;
  width: 100%;
  background: ${Color.GrayscaleWhite};
`;

const timeColumnStyle = css`
  flex: 1 1 100%;
  min-width: 60px;
  max-width: 96px;
`;

const timeCellStyle = css`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 ${gutter(3)};
  font-size: ${FontSize.Regular};
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;

const timeCellOfClockStyle = css`
  font-weight: 700;
`;

const itemColumnStyle = css`
  flex: 1 0 auto;
  min-width: 45px;
  border-left: 1px solid ${Color.GrayscaleLight};
`;
