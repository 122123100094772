import { IconName } from '@clinic-monorepo/clinic-icon';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../../gql/client';
import {
  GetReservationQuery,
  GetReservationQueryVariables,
  Maybe,
  TreatmentStatus,
  UpdateTreatmentMutation,
  UpdateTreatmentMutationVariables,
} from '../../../../../../gql/gql-types';
import getReservation from '../../../../../../gql/operations/getReservation';
import { updateTreatment } from '../../../../../../gql/operations/updateTreatment';
import { Department } from '../../../../../domains/reservation/Department';
import { CancelPolicyStore } from '../../../../../stores/CancelPolicyStore';

type TreatmentKind = { id: string; name: string };
type EditCondition = { doctor?: { id: string }; duration: number; anyone?: Maybe<boolean> };

type Reservation = {
  id: string;
  date: Date;
  department: Department;
  treatmentKind: TreatmentKind;
  doctor: string;
  duration: number;
  editCondition?: EditCondition[];
  laneId: string;
};

/**
 * 予約変更画面の状態および更新処理を司る
 */
export class ReservationEditStore {
  public static Context = createContext<ReservationEditStore | null>(null);

  public id?: string = undefined;
  public date?: Date = undefined;
  public department?: Department = undefined;
  public treatmentKind?: TreatmentKind = undefined;
  public duration = 0;
  public physician?: string = undefined;
  public condition?: EditCondition[] = undefined;
  public laneId?: string = undefined;
  public cancelPolicies: CancelPolicyStore = new CancelPolicyStore();

  constructor() {
    makeAutoObservable(this, {
      date: observable,
      department: observable,
      setDate: action,
      setDepartment: action,
      update: action,
      id: observable,
      duration: observable,
      setDuration: action,
      setTreatmentKind: action,
      physician: observable,
      setPhysician: action,
      condition: observable,
      setCondition: action,
      laneId: observable,
      setLaneId: action,
    });
  }

  public setReservation(reservation: Reservation) {
    runInAction(() => {
      this.id = reservation.id;
      this.date = reservation.date;
      this.department = reservation.department;
      this.duration = reservation.duration;
      this.physician = reservation.doctor;
      this.treatmentKind = reservation.treatmentKind;
      this.condition = reservation.editCondition;
      this.laneId = reservation.laneId;
    });
  }

  public setDate(d: Date) {
    this.date = d;
  }

  public setDepartment(d: Department) {
    this.department = d;
  }

  public setDuration(d: number) {
    this.duration = d;
  }

  public setTreatmentKind(t: TreatmentKind) {
    this.treatmentKind = t;
  }

  public setPhysician(p?: string) {
    this.physician = p;
  }

  public setCondition(c?: EditCondition[]) {
    this.condition = c;
  }

  public setLaneId(laneId: string) {
    this.laneId = laneId;
  }

  public async update() {
    if (!this.date || !this.department || !this.id || !this.laneId) {
      throw new Error('required values are empty');
    }
    const res = await GQL.query<UpdateTreatmentMutationVariables, UpdateTreatmentMutation>(updateTreatment, {
      id: this.id,
      input: {
        clinic: this.department.clinic,
        date: format(this.date, 'yyyy-MM-dd'),
        time: format(this.date, 'HH:mm'),
        department: this.department.id,
        status: TreatmentStatus.Fixed,
        yearMonth: format(this.date, 'yyyyMM'),
        physician: this.physician || '10001', // FIXME
        duration: this.duration,
        laneId: this.laneId,
        cancelPolicyRevision: this.cancelPolicies.cancelPolicyInfo?.cancelPolicyRevision,
      },
    });
    return res.updateTreatmentWithValidation?.id;
  }

  public async fetchReservation(treatmentId: string) {
    const res = await GQL.query<GetReservationQueryVariables, GetReservationQuery>(getReservation, {
      treatmentId,
    });
    res.getTreatment &&
      this.setReservation({
        date: parse(`${res.getTreatment.date} ${res.getTreatment.time}`, 'yyyy-MM-dd HH:mm', new Date()),
        department: {
          id: res.getTreatment.department?.id || '',
          name: res.getTreatment.department?.name || '',
          baseDuration: res.getTreatment.department?.baseDuration || 0,
          clinic: res.getTreatment.department?.clinic?.id || '00',
          icon: (res.getTreatment.department?.icon || 'men') as IconName,
          clinicName: res.getTreatment.department?.clinic?.name,
          termsUrl: res.getTreatment.department?.clinic?.termsUrl || '',
        },
        id: treatmentId,
        treatmentKind: {
          id: res.getTreatment.treatmentKind?.id || '',
          name: res.getTreatment.treatmentKind?.name || '',
        },
        duration: res.getTreatment.duration || 0,
        doctor: res.getTreatment.physician || '',
        editCondition: res.getTreatment.editCondition?.map(c => ({
          doctor: { id: c.doctor?.id || '' },
          duration: c.duration,
          anyone: c.anyone,
        })),
        laneId: res.getTreatment.laneId || '',
      });

    res.getTreatment?.treatmentKind?.cancelPolicies &&
      this.cancelPolicies.setCancelPolicies(res.getTreatment.treatmentKind.cancelPolicies);
  }
}
