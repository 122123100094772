import React, { useEffect, useMemo, useState } from 'react';

import { useReservationId } from '../../../hooks/useReservationId';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useTransaction } from '../../../hooks/useTransaction';
import { ReservationDetailStore } from '../../Reservations/ReservationDetail/stores/ReservationDetailStore';
import { InterviewsConfirmation } from './InterviewsConfirmation';
import { InterviewsInput } from './InterviewsInput';
import { Interviews } from './stores/Interviews';

type step = 'INPUT' | 'CONFIRMATION';
export const InterviewsNew = () => {
  useScrollToTop();
  const [step, setStep] = useState<step>('INPUT');
  const { reservationId } = useReservationId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new Interviews(reservationId), []);
  const [doStartInterview, startInterviewStatus] = useTransaction(() => store.startInterview());
  const reservation = useMemo(() => new ReservationDetailStore(), []);

  useEffect(() => {
    doStartInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Interviews.Context.Provider value={store}>
        <ReservationDetailStore.Context.Provider value={reservation}>
          {(() => {
            switch (step) {
              case 'INPUT':
              default:
                return (
                  <InterviewsInput onNext={() => setStep('CONFIRMATION')} loading={startInterviewStatus.running} />
                );
              case 'CONFIRMATION':
                return <InterviewsConfirmation onBack={() => setStep('INPUT')} />;
            }
          })()}
        </ReservationDetailStore.Context.Provider>
      </Interviews.Context.Provider>
    </>
  );
};
