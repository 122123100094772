import { urlSafeEncode } from '@aws-amplify/core';
import { atcb_action } from 'add-to-calendar-button';
import { addMinutes, format } from 'date-fns';
import { useMemo, useRef } from 'react';

import { Defaults } from '../constants/Defaults';
import { Routes } from '../constants/Routes';
import { frontLiffUrl, getFrontUrl } from '../environments';
import { WindowUtil } from '../utils/WindowUtil';
import { useReservationId } from './useReservationId';

/**
 * Appleのカレンダーへの登録需要があるかどうかの検証を行う(iPhoneのみに表示する)
 * 試験的に"add-to-calendar-button"というライブラリを使用する
 */

export const useAddToCalendarButton = (date: Date, displayDuration?: number) => {
  const { reservationId } = useReservationId();
  const ref = useRef<HTMLButtonElement | null>(null);

  const calendarEvent = useMemo(
    () => ({
      name: 'CLINIC TEN SHIBUYA 受診予定',
      startDate: format(date, 'yyyy-MM-dd'),
      endDate: format(date, 'yyyy-MM-dd'),
      startTime: format(date, 'HH:mm'),
      endTime: format(addMinutes(date, displayDuration || Defaults.DURATION), 'HH:mm'),
      description: `詳細はこちらをご確認ください。<br>→ [url]${frontLiffUrl(
        `/reservations/${urlSafeEncode(reservationId)}`,
      )}[/url]<br><br>予約の変更やキャンセルなどは自動更新されません。`,
      location: '〒150-0002 東京都渋谷区渋谷2丁目20-12 日永ビル2F',
      timeZone: 'Asia/Tokyo',
      options: ['Apple' as const],
      trigger: 'click' as const,
      listStyle: 'modal' as const,
      language: 'ja' as const,
      iCalFileName: `CLINICTEN-${format(date, 'yyyy-MM-dd-HH:mm')}`,
    }),
    [date, displayDuration, reservationId],
  );

  const handleClick = () => {
    // lineブラウザだとライブラリを使えないため、標準ブラウザのカレンダー登録専用ページに遷移
    if ((window as any).liff?.isInClient?.()) {
      WindowUtil.openInDefaultBrowser(
        `${getFrontUrl()}${Routes.Paths.addToIPhoneCalendar}?date=${format(date, 'yyyy-MM-dd')}&time=${format(
          date,
          'HH:mm',
        )}&duration=${displayDuration}`,
      );
      return;
    }
    atcb_action(calendarEvent, ref.current || undefined);
  };

  const isiPhone = () => ['iPhone Simulator', 'iPhone'].includes(navigator.platform);

  return { ref, isiPhone, handleClick };
};
