import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../../../gql/client';
import {
  AnswerInterviewInput,
  GetCheckupInterviewQuery,
  GetCheckupInterviewQueryVariables,
  GetCheckupTreatmentWithInterviewQuery,
  GetCheckupTreatmentWithInterviewQueryVariables,
  StartInterviewMutation,
  SubmitCheckupInterviewMutation,
  SubmitCheckupInterviewMutationVariables,
} from '../../../../../../../gql/gql-types';
import { getCheckupInterview } from '../../../../../../../gql/operations/getCheckupInterview';
import { getCheckupTreatmentWithInterview } from '../../../../../../../gql/operations/getCheckupTreatmentWithInterview';
import { submitCheckupInterview } from '../../../../../../../gql/operations/submitCheckupInterview';
import { safeFilter } from '../../../../../../utils/CollectionUtil';

type Interview = StartInterviewMutation['startInterview'];

// FIXME
const defaultQuestionLength = 10;

export class CheckupPreInterviewPostReserveStore {
  public static Context = createContext<CheckupPreInterviewPostReserveStore | null>(null);
  public interview: Interview | null = null;

  constructor(public treatmentId: string) {
    makeObservable(this, {
      interview: observable,
      setInterview: action,
      startInterview: action,
      answerInterview: action,
      treatmentId: observable,
      estimatedQuestions: computed,
    });
  }

  get estimatedQuestions() {
    return Math.max(this.interview?.questions?.length || 0, defaultQuestionLength);
  }

  setInterview(i: Interview) {
    this.interview = i;
  }

  async startInterview() {
    const origin = await GQL.query<
      GetCheckupTreatmentWithInterviewQueryVariables,
      GetCheckupTreatmentWithInterviewQuery
    >(getCheckupTreatmentWithInterview, {
      id: this.treatmentId,
    });
    const res = await GQL.query<GetCheckupInterviewQueryVariables, GetCheckupInterviewQuery>(getCheckupInterview, {
      input: {
        options: origin.getTreatment?.options || [],
        plan: origin.getTreatment?.checkupAppointment?.plan.id || '',
        timing: '2_AFTER_RESERVING',
      },
    });
    this.setInterview(res.getCheckupInterview);
  }

  async answerInterview(inp: AnswerInterviewInput) {
    if (!this.interview) {
      return;
    }
    this.interview = {
      ...this.interview,
      questions: [
        ...safeFilter(this.interview.questions).map(q => ({
          ...q,
          answer: safeFilter(inp.answers.find(answer => answer.id === q.id)?.answer).map(ans => ({
            answer: ans.value,
            option: {
              id: ans.id,
              value: ans.value,
            },
          })),
        })),
      ],
    };
  }

  async submitInterview(inp: AnswerInterviewInput) {
    if (!this.interview) {
      return;
    }

    await GQL.query<SubmitCheckupInterviewMutationVariables, SubmitCheckupInterviewMutation>(submitCheckupInterview, {
      input: {
        answers: inp.answers,
        timing: '2_AFTER_RESERVING',
        treatmentId: this.treatmentId,
      },
    });
  }
}
