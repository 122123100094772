import format from 'date-fns/format';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  CancelCheckupAppointmentMutation,
  CancelCheckupAppointmentMutationVariables,
  CancelTreatmentMutation,
  CancelTreatmentMutationVariables,
  DepartmentType,
  GetReservationQuery,
  GetReservationQueryVariables,
  ListTreatmentsQuery,
  ListTreatmentsQueryVariables,
  Maybe,
  ReservationFragment,
  TreatmentCancelReason,
  TreatmentKindTypeFragment,
  TreatmentStatus,
} from '../../../../../gql/gql-types';
import { cancelCheckupReservation } from '../../../../../gql/operations/cancelCheckupReservation';
import { cancelLumeccaTreatment } from '../../../../../gql/operations/cancelLumeccaTreatment';
import cancelTreatment from '../../../../../gql/operations/cancelTreatment';
import getReservation from '../../../../../gql/operations/getReservation';
import { listTreatments } from '../../../../../gql/operations/listTreatments';
import { Defaults } from '../../../../../src/constants/Defaults';
import { CancelPolicyStore } from '../../../../stores/CancelPolicyStore';
import { TreatmentDate } from '../../../Treatments/TreatmentList/stores/TreatmentListStore';

export class ReservationDetailStore {
  public static Context = createContext<ReservationDetailStore | null>(null);

  public reservation?: Reservation | undefined = undefined;

  public needsRefresh = true;

  public setReservation(reservation: Reservation | undefined) {
    this.reservation = reservation;
  }

  public penaltyCount?: number = undefined;

  public completedTreatmentsCount?: number = undefined;

  constructor() {
    makeAutoObservable(this, {
      reservation: observable,
      setReservation: action,
      fetchReservation: action,
      clear: action,
      needsRefresh: observable,
      penaltyCount: observable,
    });
  }

  public clear() {
    this.setReservation(undefined);
    this.needsRefresh = true;
  }

  public async fetchReservation(treatmentId: string) {
    if (treatmentId === this.reservation?.id && !this.needsRefresh) {
      return;
    }

    const res = await GQL.query<GetReservationQueryVariables, GetReservationQuery>(getReservation, { treatmentId });
    res.getTreatment &&
      this.setReservation({
        id: res.getTreatment.checkupAppointment ? res.getTreatment.id : treatmentId,
        status: res.getTreatment.status || TreatmentStatus.Fixed,
        date: res.getTreatment.date
          ? new TreatmentDate(res.getTreatment.date, res.getTreatment.time).toDate()
          : new Date(),
        department: {
          id: res.getTreatment.department?.id || '',
          name: res.getTreatment.department?.name || '',
          baseDuration: res.getTreatment.department?.baseDuration || 0,
          // 健診の性質を帯びている場合はタイプ：健診とみなす
          type: res.getTreatment.checkupAppointment
            ? DepartmentType.Checkup
            : res.getTreatment.department?.type || undefined,
          editInterval: res.getTreatment.department?.editInterval || 0,
          announcement: res.getTreatment.department?.announcement,
          requirement: res.getTreatment.department?.requirement,
        },
        clinic: {
          id: '',
          name: '',
          address: '',
          lat: 0,
          lng: 0,
          ...res.getTreatment.department?.clinic,
          mapUrl: res.getTreatment.department?.clinic?.mapUrl || '',
        },
        duration: res.getTreatment.displayDuration || res.getTreatment.duration || Defaults.DURATION,
        interview: {
          id: res.getTreatment.interviewId || undefined,
          submitted: res.getTreatment.interview?.submitted || false,
        },
        treatmentKind: {
          id: res.getTreatment.treatmentKind?.id,
          name: res.getTreatment.treatmentKind?.name,
          course: !!res.getTreatment.treatmentKind?.course,
          vaccine: !!res.getTreatment.treatmentKind?.vaccine,
          vaccineSequence: res.getTreatment.treatmentKind?.vaccineSequence || undefined,
          announcement: res.getTreatment.treatmentKind?.announcement,
          requirement: res.getTreatment.treatmentKind?.requirement,
          online: res.getTreatment.treatmentKind?.online,
          cancelPolicies: res.getTreatment.treatmentKind?.cancelPolicies,
        },
        displayDuration: res.getTreatment.displayDuration || 0,
        isIndividualCheckup: !!(
          res.getTreatment.checkupAppointment && !res.getTreatment.checkupAppointment.checkupOrganizationMemberEvent?.id
        ),
        noInterview: !!res.getTreatment.noInterview,
        checkupAppointmentId: res.getTreatment.checkupAppointment?.id,
        hidden: !!res.getTreatment.hidden,
      });
    this.needsRefresh = false;
    this.checkup.setReservation(res.getTreatment);
    this.cancelPolicies.setCancelPolicies(res.getTreatment?.treatmentKind?.cancelPolicies);
    this.penaltyCount = res.getTreatment?.user?.penaltyCount || 0;
  }

  public async setToCanceled() {
    if (!this.reservation) {
      return;
    }

    if (this.reservation.department.type === DepartmentType.Checkup) {
      await GQL.query<CancelCheckupAppointmentMutationVariables, CancelCheckupAppointmentMutation>(
        cancelCheckupReservation,
        {
          input: {
            appointmentId: this.reservation.checkupAppointmentId,
          },
        },
      );
      return;
    }

    const treatmentId: string | undefined = this.reservation.treatmentKind?.id;
    // 診察照射セットの照射は非表示にしている。照射のみは通常通りのキャンセルフロー。
    if (
      treatmentId &&
      (treatmentId === lumeccaIds.IPL ||
        treatmentId === lumeccaIds.HairRemoval ||
        // HIFUも連鎖予約対象
        hifuTreatmentIds.includes(treatmentId) ||
        peelingTreatmentIds.includes(treatmentId))
    ) {
      await GQL.query<CancelTreatmentMutationVariables, CancelTreatmentMutation>(cancelLumeccaTreatment, {
        input: {
          clinic: this.reservation.clinic.id,
          date: format(this.reservation.date, 'yyyy-MM-dd'),
          id: this.reservation.id,
          cancelReason: TreatmentCancelReason.PatientConvenience,
        },
      });
      return;
    }
    await GQL.query<CancelTreatmentMutationVariables, CancelTreatmentMutation>(cancelTreatment, {
      input: {
        clinic: this.reservation.clinic.id,
        date: format(this.reservation.date, 'yyyy-MM-dd'),
        id: this.reservation.id,
        cancelReason: TreatmentCancelReason.PatientConvenience,
      },
    });
  }

  public checkup: CheckupReservationDetailStore = new CheckupReservationDetailStore();
  public async fetchUserTreatments(userId: string) {
    const completedTreatmentsCount = (
      await GQL.query<ListTreatmentsQueryVariables, ListTreatmentsQuery>(listTreatments, {
        userId,
      })
    ).getUser?.completedTreatment.items?.length;
    this.completedTreatmentsCount = completedTreatmentsCount;
  }

  public cancelPolicies: CancelPolicyStore = new CancelPolicyStore();
}

/**
 * 健診関連のドメインロジックを集約する。
 */
class CheckupReservationDetailStore {
  constructor() {
    makeAutoObservable(this, {
      reservation: observable,
      setReservation: action,
      isCheckup: computed,
      isIndividual: computed,
      options: computed,
      plan: computed,
    });
  }

  public reservation?: ReservationFragment = undefined;
  public setReservation(reservation?: Maybe<ReservationFragment>) {
    reservation && (this.reservation = reservation);
  }

  public get isCheckup() {
    return this.reservation && this.reservation.department?.type === DepartmentType.Checkup;
  }

  public get plan():
    | {
        name: string;
        regularAmount: number;
        discountAmount: number;
        billingTarget: '1_organization' | '2_own';
      }
    | undefined {
    if (!this.reservation || !this.isCheckup) {
      return undefined;
    }

    return {
      name: this.reservation.checkupAppointment?.plan.label || '',
      regularAmount: this.reservation.checkupAppointment?.plan.price || 0,
      discountAmount: this.reservation.checkupAppointment?.planPrice || 0,
      billingTarget: this.isIndividual
        ? '2_own'
        : this.reservation.checkupAppointment?.checkupOrganizationMemberEvent?.event?.planBillType === '1_organization'
        ? '1_organization'
        : '2_own',
    };
  }

  public get options(): {
    name: string;
    regularAmount: number;
    discountAmount: number;
    billingTarget: '1_organization' | '2_own';
  }[] {
    if (!this.reservation || !this.isCheckup) {
      return [];
    }

    return (
      this.reservation.checkupAppointment?.optionPrices.map(o => ({
        name: o.option?.label || '',
        regularAmount: o.option?.regularPrice || 0,
        discountAmount: o.price,
        billingTarget: this.isIndividual ? '2_own' : o.billType === '1_organization' ? '1_organization' : '2_own',
      })) || []
    );
  }

  public get isIndividual() {
    return this.isCheckup && !this.reservation?.checkupAppointment?.checkupOrganizationMemberEvent;
  }
}

type Reservation = {
  id: string;
  status: TreatmentStatus;
  date: Date;
  department: {
    id: string;
    name: string;
    baseDuration: number;
    type?: DepartmentType;
    editInterval: number;
    announcement?: Maybe<string>;
    requirement?: Maybe<string>;
  };
  clinic: {
    id: string;
    name: string;
    address: string;
    lat: number;
    lng: number;
    mapUrl: string;
  };
  duration: number;
  interview?: {
    id?: string;
    submitted?: boolean;
  };
  treatmentKind?: {
    id?: string;
    name?: string;
    course?: boolean;
    vaccine?: boolean;
    vaccineSequence?: { end?: Maybe<boolean> };
    announcement?: Maybe<string>;
    requirement?: Maybe<string>;
    online?: Maybe<boolean>;
    cancelPolicies?: TreatmentKindTypeFragment['cancelPolicies'];
  };
  displayDuration?: number;
  isIndividualCheckup?: boolean;
  noInterview?: boolean;
  checkupAppointmentId?: string;
  hidden?: boolean;
};

type Lumecca = {
  IPL: string;
  HairRemoval: string;
};

const lumeccaIds: Lumecca = {
  IPL: '12E',
  HairRemoval: '12G',
};
const hifuTreatmentIds = ['12I', '12K', '12Q', '12S'];

const peelingTreatmentIds = ['12P'];
