import { useEffect, useMemo, useState } from 'react';

import { Department } from '../../../../../domains/reservation/Department';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { TreatmentKind } from '../../../ReservationNew/stores/ReservationStore';
import { EditCondition, VacanciesStore } from '../../../stores/VacanciesStore';
import { ReservationEditStore } from '../stores/ReservationEditStore';

export const useVacancyLoad = () => {
  const detailStore = useContext(ReservationEditStore.Context);
  const vacancyStore = useMemo(() => new VacanciesStore(), []);
  const [doLoad, loadStatus] = useTransaction(
    (week: Date, department: Department, treatmentKind?: TreatmentKind, editCondition?: EditCondition[]) =>
      vacancyStore.fetchVacancies(week, department, treatmentKind, editCondition),
  );
  const [week, setWeek] = useState(new Date());

  useEffect(() => {
    detailStore.department && doLoad(week, detailStore.department, detailStore.treatmentKind, detailStore.condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, detailStore.department, detailStore.treatmentKind, detailStore.condition]);

  return {
    vacancyStore,
    loadStatus,
    week,
    setWeek,
  };
};
