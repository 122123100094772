import { css } from '@emotion/css';

import { Color } from '../../../../../constants/Style';

// 今後のため新規の共通部分は同じものを用意しておく

export const errorStyle = css`
  margin-bottom: 8px;
  color: ${Color.FunctionalRedNeutral};
`;
