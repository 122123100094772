import { css } from 'emotion';
import React, { memo } from 'react';

import { Routes } from '../../constants/Routes';
import { Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = {
  loggedIn: boolean;
  onLogout: () => void;
};

export const SubMenu = memo(({ loggedIn, onLogout }: Props) => (
  <ul className={baseStyle}>
    {data.map(({ label, to }) => (
      <li key={label} className={itemStyle}>
        <a href={to} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      </li>
    ))}
    {loggedIn ? (
      <li className={itemStyle}>
        <span onClick={onLogout}>ログアウト</span>
      </li>
    ) : null}
  </ul>
));

const data = [
  {
    label: ' CLINIC TEN SHIBUYA',
    to: 'https://clinicten.jp',
  },
  {
    label: ' DENTAL CLINIC TEN SHIBUYA',
    to: 'https://dental.clinicten.jp',
  },
  {
    label: 'よくあるご質問 / ヘルプ',
    to: Routes.Externals.FAQ.Root,
  },
  {
    label: '利用規約',
    to: Routes.Paths.UsagePolicy,
  },
  {
    label: 'プライバシーポリシー',
    to: Routes.Paths.PrivacyPolicy,
  },
] as const;

const baseStyle = css`
  margin-top: ${gutter(10)};
`;

const itemStyle = css`
  font-size: ${FontSize.Small};

  & + & {
    margin-top: ${gutter(4)};
  }

  > a,
  > span {
    display: block;
    color: ${Color.GrayscaleDarker};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
