import gql from 'graphql-tag';

import { CheckupMemberEventType } from '../fragments/checkupMemberEvent';
import { InterviewType } from '../fragments/interview';

export const getCheckupOrganizationMemberEventByTreatmentId = gql`
  query getCheckupOrganizationMemberEventByTreatmentId($id: String) {
    getTreatment(id: $id) {
      id
      options
      preInterview {
        ...InterviewType
      }
      checkupAppointment {
        id
        checkupOrganizationMemberEvent {
          ...CheckupMemberEventType
        }
      }
    }
  }
  ${InterviewType}
  ${CheckupMemberEventType}
`;
