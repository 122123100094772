import gql from 'graphql-tag';

import { TreatmentKindType } from '../fragments/treatmentKind';

export const getTreatmentKind = gql`
  query getTreatmentKind($treatmentKindId: ID!) {
    getTreatmentKind(id: $treatmentKindId) {
      ...TreatmentKindType
    }
  }
  ${TreatmentKindType}
`;
