import { makeAutoObservable } from 'mobx';

import {
  CheckupResultItemGroupTypeFragment,
  CheckupResultItemSubGroupTypeFragment,
  CheckupResultJudgedSubGroupTypeFragment,
  TreatmentHistoryFragment,
} from '../../../../../gql/gql-types';
import { safeFilter } from '../../../../utils/CollectionUtil';
import { CheckupResultSubGroupStore } from './CheckupResultSubGroupStore';

/**
 * グループの定義と、子要素となるSubGroupの配列を持つStore
 */
export class CheckupResultGroupStore {
  constructor(public group: CheckupResultItemGroupTypeFragment, public subGroups: CheckupResultSubGroupStore[]) {
    makeAutoObservable(this, {});
  }

  static fromFragments(
    treatmentId: string,
    results: CheckupResultJudgedSubGroupTypeFragment[],
    masters: CheckupResultItemSubGroupTypeFragment[],
    groups: CheckupResultItemGroupTypeFragment[],
    histories: TreatmentHistoryFragment[],
  ) {
    const subGroups = safeFilter(
      results.map(r => {
        const master = masters.find(m => m.id === r.subGroupId);
        if (!master) {
          return undefined;
        }
        return new CheckupResultSubGroupStore(treatmentId, r, master, histories);
      }),
    );
    return groups.map(
      g =>
        new CheckupResultGroupStore(
          g,
          subGroups.filter(s => s.subGroup.group?.id === g.id),
        ),
    );
  }
}
