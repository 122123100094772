import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { css, cx } from 'emotion';
import React, { ComponentProps, useMemo } from 'react';

import { Color, Duration, FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';
import { Icon } from '../Icon';
import { Calendar } from '.';

type Props = {
  date: Date;
  /**
   * 予約可能な枠数
   */
  available: number;

  estimatedDuration: number;

  laneId: string;

  doctor?: string;

  waitingListAvailable?: boolean;
} & Required<Pick<ComponentProps<typeof Calendar>, 'alertThreshold' | 'onSelect' | 'now'>>;

export const TableItem = ({
  date,
  available,
  alertThreshold,
  onSelect,
  now,
  estimatedDuration,
  doctor,
  laneId,
  waitingListAvailable,
}: Props) => {
  const warning = available <= alertThreshold;

  const handleClick = () =>
    onSelect({ date, estimatedDuration, laneId, doctor, waitingList: !available && waitingListAvailable });

  const calendarCellType: CalendarCellType = useMemo(
    () => (available ? 'AVAILABLE' : waitingListAvailable ? 'WAITING_LIST_AVAILABLE' : 'UNAVAILABLE'),
    [available, waitingListAvailable],
  );

  const before = isBefore(date, now);
  const onThreshold = before && isAfter(add(date, { minutes: 15 }), now);

  if (before) {
    const disabledStyle = cx(baseStyle, themeStyle.disabled);
    return (
      <span className={onThreshold ? cx(disabledStyle, onThresholdStyle) : disabledStyle}>
        <Icon name="times" />
      </span>
    );
  }

  switch (calendarCellType) {
    case 'AVAILABLE':
    default:
      return (
        <button className={cx(baseStyle, warning ? themeStyle.waring : themeStyle.open)} onClick={handleClick}>
          <Icon name={warning ? 'triangle' : 'circle'} />
          {warning ? <span className={summaryStyle}>残り{available}枠</span> : null}
        </button>
      );
    case 'UNAVAILABLE':
      return (
        <span className={cx(baseStyle, themeStyle.mute)}>
          <Icon name="times" />
        </span>
      );
    case 'WAITING_LIST_AVAILABLE':
      return (
        <button className={cx(baseStyle, themeStyle.neutral)} onClick={handleClick}>
          <Icon name="bell" />
        </button>
      );
  }
};

type CalendarCellType = 'AVAILABLE' | 'WAITING_LIST_AVAILABLE' | 'UNAVAILABLE';

const baseStyle = css`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: ${gutter(1)};
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Color.GrayscaleLight};
  transition: background-color ${Duration.Enter};

  > svg {
    ${square(IconSize.Regular)}
  }
`;

const summaryStyle = css`
  margin: 0 -1em;
  font-size: ${FontSize.Nano};
  color: ${Color.FunctionalBlueNeutral};
  user-select: none;
`;

const onThresholdStyle = css`
  border-bottom: 2px solid ${Color.GrayscaleDarker};
`;

const themeStyle = {
  open: css`
    &:hover {
      background-color: ${Color.FunctionalRedLight};
    }

    > svg {
      fill: ${Color.FunctionalRedNeutral};
    }
  `,
  waring: css`
    &:hover {
      background-color: ${Color.FunctionalBlueLight};
    }

    > svg {
      fill: ${Color.FunctionalBlueNeutral};
    }
  `,
  disabled: css`
    cursor: default;
    background-color: ${Color.GrayscaleLighter};

    > svg {
      fill: ${Color.GrayscaleLight};
    }
  `,
  mute: css`
    cursor: default;

    > svg {
      fill: ${Color.GrayscaleLight};
    }
  `,
  neutral: css`
    background-color: ${Color.FunctionalYellowLighter};

    &:hover {
      background-color: ${Color.FunctionalYellowLight};
    }

    > svg {
      fill: ${Color.FunctionalYellowNeutral};
    }
  `,
};
