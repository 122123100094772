import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Color } from '../../constants/Style';

type Props = {
  children: ReactNode;
};
export const ErrorMessage = ({ children }: Props) => <small className={style}>{children}</small>;

const style = css`
  color: ${Color.FunctionalRedNeutral};
`;
