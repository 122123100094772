import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Icon } from '../../../components/Icon';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../domains/base/SearchParams';
import { SignInLegacyPage } from '../SignInLegacy';
// import { SignInPhonePage } from '../SignInPhone';
import { AccountsNewLegacyInputPage } from './AccountsNewLegacy/AccountsNewLegacyInput';
import { AccountsNewLineConcent } from './AccountsNewLine/AccountsNewLineConcent';
// import { AccountsNewPhoneInputPage } from './AccountsNewPhone/AccountsNewPhoneInput';

export type SignInMethod = 'LINE' | 'LEGACY';
// export type SignInMethod = 'LINE' | 'LEGACY' | 'PHONE';

type type = SignInMethod;

/**
 * アカウント登録画面
 */
export const AccountNew = () => {
  const { location } = useHistory();
  const [type, setType] = useState<type | null>(null);
  const [legacyMode, setLegacy] = useState(false);
  useEffect(() => {
    setType(new URLSearchParams(location.search).get('type') as type);
    setLegacy(new URLSearchParams(location.search).has('legacy'));
  }, [location]);
  switch (type) {
    case 'LEGACY':
      return legacyMode ? <AccountsNewLegacyInputPage /> : <SignInLegacyPage onChangeMethod={setType} />;
    case 'LINE':
      return <AccountsNewLineConcent onChangeMethod={setType} />;
    // case 'PHONE':
    //   return <AccountsNewPhoneInputPage />;
    default:
      return <SignUpMethodSelectPage onChangeMethod={setType} />;
  }
};

type Props = {
  onChangeMethod: (method: type) => void;
};

const SignUpMethodSelectPage = ({ onChangeMethod }: Props) => {
  const { push } = useHistory();

  const action = new URLSearchParams(window.location.search).get('action');

  return (
    <Wrapper>
      <DocumentTitle title="認証方法の選択" />
      <Caption type="header">認証方法を選択してください</Caption>
      {action === 'startCheckup' && (
        <Caption type="body">※認証に利用したアカウント情報が会社に共有されることはありません。</Caption>
      )}
      <ButtonGroup gutter={10}>
        <Button
          theme="line"
          onClick={() => {
            push({
              pathname: Routes.Paths.AccountNew,
              search: new URLSearchParamsMonad(new URL(window.location.href).searchParams)
                .accept('type', 'LINE')
                .toString(),
            });
            onChangeMethod('LINE');
          }}
        >
          <Icon name="line" />
          LINEで認証
        </Button>
        <Button
          onClick={() => {
            push({
              pathname: Routes.Paths.AccountNew,
              search: new URLSearchParamsMonad(new URL(window.location.href).searchParams)
                .accept('type', 'LEGACY')
                .toString(),
            });
            onChangeMethod('LEGACY');
          }}
        >
          メールアドレスで認証
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};
