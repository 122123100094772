import gql from 'graphql-tag';

import { ReservationType } from '../fragments/reservation';
import { TreatmentType } from '../fragments/treatment';
import { WaitingListItemType } from '../fragments/waitingListItem';

export default gql`
  query getMyPage($userId: ID!) {
    getUser(id: $userId) {
      id
      fixedTreatment: treatment(statusDate: { beginsWith: { status: "FIXED" } }, sortDirection: ASC) {
        items {
          ...Reservation
        }
        nextToken
      }
      completedTreatment: treatment(statusDate: { beginsWith: { status: "COMPLETED" } }, sortDirection: DESC) {
        items {
          ...TreatmentHistory
        }
        nextToken
      }
      waitingList {
        items {
          ...WaitingListItemType
        }
      }
    }
  }
  ${ReservationType}
  ${TreatmentType}
  ${WaitingListItemType}
`;
