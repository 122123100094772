import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { TextField } from '../../../components/TextField';
import { Wrapper } from '../../../components/Wrapper';
import { RegularExpressions } from '../../../constants/RegularExpressions';
import { gutter } from '../../../helpers/Style';

type Props = {
  onSubmit: () => void;
  setEmail: (email: string) => void;
  email: string;
  disable: boolean;
};

export const EmailInputPage = observer(({ onSubmit, setEmail, email, disable }: Props) => {
  // const store = useMemo(() => new TreatmentListStore(), []);
  const { goBack } = useHistory();
  const [emailValidation, setEmailValidation] = useState(true);

  useEffect(() => {
    setEmail(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const emailCheck = () => {
    if (RegularExpressions.EMAIL.test(email)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  return (
    <Wrapper>
      <DocumentTitle title="診察履歴・領収書一覧" />
      <Caption type="header" align="center">
        送付メールアドレスの入力
      </Caption>
      <CaptionGroup>
        <Caption type="div" gutter={2}>
          領収書データはまとめてメールでお送りします。送付するメールアドレスをご入力ください。
        </Caption>
      </CaptionGroup>
      <div className={inputStyle}>
        <TextField
          type="email"
          color="white"
          value={email || ''}
          placeholder="メールアドレス"
          onChange={v => {
            setEmail(v);
            emailCheck();
          }}
          onFocusout={() => emailCheck()}
        />
        {!emailValidation && <ErrorMessage>メールアドレスの形式で入力してください</ErrorMessage>}
      </div>
      <div className={buttonStyle}>
        <Button
          disabled={disable || !email || !emailValidation}
          block
          onClick={() => {
            onSubmit();
          }}
        >
          送信する
        </Button>
      </div>
      <div className={buttonStyle}>
        <Button
          block
          ghost
          onClick={() => {
            goBack();
          }}
        >
          戻る
        </Button>
      </div>
    </Wrapper>
  );
});

const inputStyle = css`
  margin-bottom: ${gutter(8)};
`;

const buttonStyle = css`
  width: 100%;
  margin-bottom: ${gutter(4)};
`;
