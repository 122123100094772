import { css } from 'emotion';
import React from 'react';

type Props = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
};

export const Image = ({ src, alt, width, height }: Props) => (
  <div className={imageStyle}>
    <img src={src} alt={alt} width={width} height={height} />
  </div>
);

const imageStyle = css`
  margin: 0 auto;
  text-align: center;
`;
