import { Auth } from '@aws-amplify/auth';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { action, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../gql/client';
import { GetUserQuery, GetUserQueryVariables } from '../../gql/gql-types';
import getUser from '../../gql/operations/getUser';
import { Environment, isLiffEnabled } from '../environments';

export type UserEntity = GetUserQuery['getUser'];

type User = {
  getIdToken(): string;
  getRefreshToken(): string;
  getUserId(): string;
  getUserEntity(): UserEntity;
};

/**
 * アプリケーションの認証情報の状態および認証処理を司る。
 */
export class SessionStore {
  public static Context = createContext<SessionStore | null>(null);

  /**
   * 認証済みかどうか
   */
  public authenticated = false;

  /**
   * 認証済みかどうかわかっているかどうか
   */
  public authStateKnown = false;

  /**
   * ユーザー情報
   */
  public user?: UserStore = undefined;

  constructor() {
    makeAutoObservable(this, {
      authenticated: observable,
      authStateKnown: observable,
      setAuthenticated: action,
      lineSignIn: action,
      fetchSession: action,
      user: observable,
      setUser: action,
    });
  }

  public setUser(user: UserStore | undefined) {
    this.user = user;
  }

  /**
   * LINEでのサインインを開始する。
   */
  public lineSignIn(customState: string) {
    if (!isLiffEnabled()) {
      Auth.federatedSignIn({ customProvider: 'LINE', customState });
      return;
    }
    window.location.href = `https://liff.line.me/${Environment.liffId}/?customState=${encodeURIComponent(
      customState,
    )}&origin=${encodeURIComponent(new URL(window.location.href).origin)}`;
  }

  /**
   * 現在のセッションを取得し、状態に反映する。
   */
  public async fetchSession() {
    try {
      const res: CognitoUser = await Auth.currentAuthenticatedUser();
      const session = res.getSignInUserSession();
      if (!session) {
        this.setUser(undefined);
        this.setAuthenticated(false);
        return;
      }
      const user = await GQL.query<GetUserQueryVariables, GetUserQuery>(getUser, {
        userId: session.getIdToken().payload['cognito:username'],
      });
      this.setUser(
        new UserStore({
          getIdToken: () => session.getIdToken().getJwtToken(),
          getRefreshToken: () => session.getRefreshToken().getToken(),
          getUserId: () => session.getIdToken().payload['cognito:username'],
          getUserEntity: () => user.getUser,
        }),
      );
      this.setAuthenticated(true);

      // add sentry context
      Sentry.setUser({
        id: this.user?.userId,
      });
      Sentry.addBreadcrumb({
        category: 'auth',
        message: `Authenticated user ${this.user?.userId}`,
        level: Sentry.Severity.Info,
      });
      datadogLogs.addLoggerGlobalContext('clinicten-checkup-user', {
        email: session.getIdToken().decodePayload()['email'],
        id: session.getIdToken().decodePayload()['cognito:username'],
      });
      datadogRum.setUser({
        email: session.getIdToken().decodePayload()['email'],
        id: session.getIdToken().decodePayload()['cognito:username'],
      });
    } catch (e) {
      // サインインしていなかった場合、Auth#currentAuthenticatedUser()で例外が発生するため、キャッチする。
      if (e === 'not authenticated' || e === 'The user is not authenticated') {
        this.setUser(undefined);
        this.setAuthenticated(false);
        return;
      }
      throw e;
    }
  }

  public async fetchUserEntity() {
    if (!this.authenticated || !this.user) {
      return;
    }
    const user = await GQL.query<GetUserQueryVariables, GetUserQuery>(getUser, { userId: this.user.userId });
    this.user.setUserEntity(user.getUser);
  }

  public setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
    this.authStateKnown = true;
  }

  /**
   * アプリケーションからログアウトする。
   *
   * ログアウト処理後は returnTo で指定した URL が IDaaS 側のホワイトリストに含まれていれば
   * その URL へリダイレクトされ、ページがリフレッシュ（HTTP リロード）される。
   * @param returnTo ログアウト後の遷移先 URL
   */
  public async logout(returnTo = '') {
    await Auth.signOut();
    this.setAuthenticated(false);
    Sentry.configureScope(scope => scope.setUser(null));
    window.location.replace(returnTo);
  }

  public async legacySignIn(email: string, password: string) {
    await Auth.signIn({ username: email, password });
    await this.fetchSession();
  }
}

class UserStore {
  constructor(origin: User) {
    this.idToken = origin.getIdToken();
    this.refreshToken = origin.getRefreshToken();
    this.userId = origin.getUserId();
    this.userEntity = origin.getUserEntity();
    makeAutoObservable(this, {
      idToken: observable,
      refreshToken: observable,
      userEntity: observable,
      userId: observable,
      setUserEntity: action,
    });
  }

  idToken: string;
  refreshToken: string;
  userId: string;
  userEntity: UserEntity;

  public setUserEntity(userEntity: UserEntity) {
    this.userEntity = userEntity;
  }
}
