import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { DepartmentType } from '../../../../../../gql/gql-types';
import { useSteps } from '../../../../../hooks/useSteps';
import { ValueOf } from '../../../../../interface';
import { CheckupVacancyStore } from '../../../Reservations/CheckupReservationsNew/CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { CheckupReservationsCourseSelectPage } from './CheckupReservationsCourseSelect';
import { IndividualCheckupPlanSelect } from './IndividualCheckupPlanSelect';
import { IndividualCheckupReservationConfirmPage } from './IndividualCheckupReservationConfirm';
import { IndividualCheckupVacancySelectPage } from './IndividualCheckupVacancySelect';
import { IndividualCheckupReservationStore } from './stores/IndividualCheckupReservationStore';

const STEPS = {
  VACANCY_SELECT: 'vacancySelect',
  START: 'start',
  PLAN_SELECT: 'planSelect',
  INSTRUCTION: 'instruction',
} as const;

type Step = ValueOf<typeof STEPS>;

export const IndividualCheckupReservationNewPage = observer(() => {
  const [step, setStep] = useState<Step>(STEPS.START);
  const store = useMemo(() => {
    const res = new IndividualCheckupReservationStore();
    res.department = {
      baseDuration: 0,
      clinic: '01',
      icon: 'men',
      id: 'C',
      name: '健診',
      termsUrl: '',
      alertThreshold: 0,
      type: DepartmentType.Checkup,
      clinicName: 'CLINIC TEN SHIBUYA',
    };
    return res;
  }, []);
  const vacancyStore = useMemo(() => new CheckupVacancyStore(), []);
  useSteps(step, setStep);

  useEffect(() => {
    if (step === STEPS.PLAN_SELECT) {
      store.fetchMasters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <IndividualCheckupReservationStore.Context.Provider value={store}>
      <CheckupVacancyStore.Context.Provider value={vacancyStore}>
        <Main step={step} setStep={setStep} />
      </CheckupVacancyStore.Context.Provider>
    </IndividualCheckupReservationStore.Context.Provider>
  );
});

type Props = {
  step?: Step;
  setStep: (step: Step) => void;
};

const Main = observer(({ step, setStep }: Props) => {
  switch (step) {
    case 'start':
    default:
      return <CheckupReservationsCourseSelectPage onNext={() => setStep(STEPS.INSTRUCTION)} />;
    case 'instruction':
      return <IndividualCheckupReservationConfirmPage onNext={() => setStep(STEPS.PLAN_SELECT)} />;
    case 'planSelect':
      return <IndividualCheckupPlanSelect onNext={() => setStep(STEPS.VACANCY_SELECT)} />;
    case 'vacancySelect':
      return <IndividualCheckupVacancySelectPage onBack={() => setStep(STEPS.PLAN_SELECT)} />;
  }
});
