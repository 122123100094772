import { css, cx } from 'emotion';
import React, { ReactNode, useMemo } from 'react';

import { BorderRadius, Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

const statusIndicatorStyle = css`
  width: fit-content;
  padding: ${gutter(1)} ${gutter(3)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleWhite};
  border: 1px solid ${Color.GrayscaleWhite};
  border-radius: ${BorderRadius.Regular};
`;

const invertedStyle = cx(
  statusIndicatorStyle,
  css`
    background: ${Color.BrandTenBrown};
    border: 1px solid ${Color.BrandTenBrown};
  `,
);

type Props = {
  children: ReactNode;
  inverted?: boolean;
  className?: string;
};

export const Tip = ({ children, inverted, className }: Props) => {
  const baseStyle = useMemo(() => (inverted ? invertedStyle : statusIndicatorStyle), [inverted]);
  const style = useMemo(() => cx(baseStyle, className), [baseStyle, className]);

  return <div className={style}>{children}</div>;
};
