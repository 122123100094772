import { css } from 'emotion';
import React, { memo } from 'react';

import { AnswerValueInput, Question } from '../../../gql/gql-types';
import { Color } from '../../constants/Style';
import { useScrollAnchor } from '../../hooks/useScrollToElement';
import { ElementTypeof } from '../../interface';
import { FormItem } from '../Form';
import { PharmacyMap } from '../Map/PharmacyMap';
import { InterviewCheckboxGroup } from './Checkbox';
import { InterviewDateInput } from './Date';
import { InterviewPainRadioGroup } from './PainRadio';
import { InterviewRadioGroup } from './Radio';
import { InterviewStomachMap } from './StomachMap';
import { InterviewTextArea } from './TextArea';
import { InterviewInput } from './TextField';
import { InterviewFormItemType } from './types';
import { InterviewUploadInput } from './Upload';

type Prop = {
  def: Pick<Question, 'id' | 'label' | 'options' | 'required' | 'additional_type' | 'isNarrowDown'>;
  value: AnswerValueInput[];
  onChange: (inp: AnswerValueInput[]) => void;
  errors?: string;
  analyticsClassName?: string;
};

export const toNonNull = (inp: ElementTypeof<Question['options']>) => ({
  label: inp?.label || '',
  value: inp?.value || '',
  id: String(inp?.id || ''),
  type: inp?.type || '',
  placeholder: inp?.placeholder || '',
});

export const InterviewReactiveFormItem = memo(({ def, value, onChange, errors, analyticsClassName }: Prop) => {
  const ref = useScrollAnchor(String(def.id));
  const componentType: InterviewFormItemType = (def.options || []).reduce((bef: InterviewFormItemType, opt) => {
    if (opt?.type === 'stomachMap') {
      return 'stomachMap';
    }
    if (opt?.type === 'painScale') {
      return 'painScale';
    }
    if (opt?.type === 'radio') {
      return 'radio';
    }
    if (opt?.type === 'checkbox') {
      return 'checkbox';
    }
    if (opt?.type === 'number' && bef !== 'checkbox') {
      return 'number';
    }
    if (opt?.type === 'date') {
      return 'date';
    }
    if (opt?.type === 'hidden') {
      return 'hidden';
    }
    if (opt?.type === 'pharmacy') {
      return 'pharmacy';
    }
    if (opt?.type === 'textarea') {
      return 'textarea';
    }
    return bef;
  }, (def.additional_type || 'input') as InterviewFormItemType);

  if (!def.options || def.options.length === 0) {
    return <></>;
  }

  switch (componentType) {
    case 'radio':
      return (
        <FormItem _ref={ref}>
          <InterviewRadioGroup
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'pharmacy':
      return (
        <>
          <FormItem _ref={ref}>
            <InterviewRadioGroup
              def={def}
              onChange={onChange}
              value={value}
              errors={errors}
              analyticsClassName={analyticsClassName}
            />
          </FormItem>
          <FormItem>
            <PharmacyMap />
          </FormItem>
        </>
      );
    case 'checkbox':
      return (
        <FormItem _ref={ref}>
          <InterviewCheckboxGroup
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'painScale':
      return (
        <FormItem _ref={ref}>
          <InterviewPainRadioGroup
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'stomachMap':
      return (
        <FormItem _ref={ref}>
          <InterviewStomachMap
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'number':
      return (
        <FormItem _ref={ref}>
          <InterviewInput
            def={def}
            onChange={onChange}
            value={value}
            type="number"
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'date':
      return (
        <FormItem _ref={ref}>
          <InterviewDateInput
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'hidden':
      return (
        <FormItem>
          <InterviewInput
            def={def}
            onChange={onChange}
            value={value}
            type="hidden"
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'textarea':
      return (
        <FormItem _ref={ref}>
          <InterviewTextArea
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
    case 'upload':
      return (
        <FormItem _ref={ref}>
          <InterviewUploadInput def={def} onChange={onChange} value={value} analyticsClassName={analyticsClassName} />
        </FormItem>
      );
    case 'input':
    default:
      return (
        <FormItem _ref={ref}>
          <InterviewInput
            def={def}
            onChange={onChange}
            value={value}
            errors={errors}
            analyticsClassName={analyticsClassName}
          />
        </FormItem>
      );
  }
});

export const errorStyle = css`
  border: 1px solid ${Color.FunctionalRedNeutral};
`;
