import React from 'react';

import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';
import { Caption } from '../../Caption';
import { Card } from '../../Card';
import { Modal } from '../../Modal';

type Props = {
  onChange?: (files: FileList | null) => void;
  open: boolean;
  onClose?: () => void;
  onUpload?: () => void;
  disabled?: boolean;
  analyticsClassName?: string;
};
export const UploadDialog = ({ onChange, open, onClose, onUpload, disabled, analyticsClassName }: Props) => (
  <Modal visible={open}>
    <Card>
      <Caption type="header">ファイルアップロード</Caption>
      <input type="file" onChange={v => onChange?.(v.target.files)} accept="image/*" multiple />
      <div>
        <Caption type="small">.png、.jpeg、.jpgファイルのみアップロードできます。</Caption>
      </div>
      <div>
        <ButtonGroup gutter={20}>
          <Button onClick={() => onUpload?.()} disabled={disabled} className={analyticsClassName}>
            アップロード
          </Button>
          <Button onClick={() => onClose?.()} ghost>
            閉じる
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  </Modal>
);
