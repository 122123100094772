import gql from 'graphql-tag';

export const WaitingListItemType = gql`
  fragment WaitingListItemType on WaitingListItem {
    clinic {
      id
      name
    }
    department {
      id
      name
      icon
    }
    treatmentKind {
      id
      name
    }
    start
    end
    userId
  }
`;
