import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../../components/Caption';
import { Color, FontSize } from '../../../../../../../constants/Style';
import { useDebounce } from '../../../../../../../hooks/useDebounce';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

/**
 * 予約診療費徴収の説明表示するコンテンツ
 */
export const MedicalAppointmentFeeContent = observer(({ onConfirm, onCancel }: Props) => {
  const timerEnabled = useDebounce(1000);
  return (
    <div className={appointmentFeeContentStyle}>
      <Caption type="header" align="center" underline>
        保険診療時における予約料について
      </Caption>
      <Caption type="body">
        <div className={captionBodyStyle}>
          <div className={captionSectionStyle}>
            <p>
              ご予約時刻から15分以内に開始した診察には、厚生労働省に定められた「予約に基づく診察に関する費用（予約に関する選定療養費）」として以下の通り発生いたします。
            </p>
          </div>
          <div className={captionSectionStyle}>
            <p>＜選定療養費＞</p>
            <p className={captionSectionTextRedStyle}>220円 (税込)</p>
          </div>
          <div className={captionSectionStyle}>
            <p>＜予約時間＞</p>
            <p className={captionSectionTextRedStyle}>平日9:00-18:00</p>
          </div>
          <div className={captionSectionStyle}>
            <p>＜対象診療科＞ (自費診療は除く)</p>
            <p className={captionSectionTextRedStyle}>
              内科/皮膚科/泌尿器科/婦人科/睡眠外来/発熱・感染症外来/メンズヘルス外来
            </p>
          </div>
          <div className={captionSectionStyle}>
            <p>
              今後も極力少ない待ち時間と、医師との対話の時間をしっかり確保した診療とを両立できる医療体制の維持に努めてまいります。
            </p>
          </div>
        </div>
      </Caption>
      <ButtonGroup>
        <Button block disabled={!timerEnabled} type="button" onClick={() => onConfirm()}>
          了承する
        </Button>
        <Button block theme="neutral" ghost type="button" onClick={() => onCancel()}>
          戻る
        </Button>
      </ButtonGroup>
    </div>
  );
});

const appointmentFeeContentStyle = css`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 4px;
`;

const captionBodyStyle = css`
  margin-bottom: 24px;
  font-size: ${FontSize.Medium};
  font-weight: 400;
`;

const captionSectionStyle = css`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const captionSectionTextRedStyle = css`
  font-weight: 600;
  color: ${Color.FunctionalRedNeutral};
`;
