import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card } from '../../../components/Card';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { JUDGED_RESULT } from '../../../constants/CheckupResult';
import { Routes } from '../../../constants/Routes';
import { Breakpoint, Color, Dimension } from '../../../constants/Style';
import { gutter } from '../../../helpers/Style';
import { useTransaction } from '../../../hooks/useTransaction';
import { useTreatmentId } from '../../../hooks/useTreatmentId';
import { Result } from '../../../interfaces/Result';
import { VacanciesStore } from '../../../pages/Reservations/stores/VacanciesStore';
import { SessionStore } from '../../../stores/SessionStore';
import { safeFilter } from '../../../utils/CollectionUtil';
import { WindowUtil } from '../../../utils/WindowUtil';
import { ReserveButton } from '../components/ReserveButton';
import { ToTopButton } from '../components/ToTopButton';
import { OtherItems } from './components/OtherItems';
import { RecommendConsultation } from './components/RecommendConsultation';
import { ResultDoctorWrapper } from './components/ResultDoctorWrapper';
import { ShareButton } from './components/shareButton';
import { ShareModal } from './components/ShareModal';
import { SummaryComment } from './components/summaryComment';
import { SummaryStore } from './stores/SummaryStore';

export const CheckupResultSummaryPage = observer(() => {
  const sessionStore = useContext(SessionStore.Context);
  const store = useMemo(() => new SummaryStore(), []);
  const vacanciesStore = useMemo(() => new VacanciesStore(), []);
  const history = useHistory();
  const week = new Date();
  const [doFetchInitial] = useTransaction(treatmentId => store.fetchInitial(treatmentId));

  const [modalOpen, setModalOpen] = useState(false);
  const params = useParams<{ treatmentId: string }>();
  const { decode } = useTreatmentId();

  const treatmentId = decode(params.treatmentId);
  useEffect(() => {
    if (!sessionStore?.user) {
      return;
    }
    store.setProfile({ id: sessionStore.user.userId });
    doFetchInitial(treatmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore?.user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const toReservation = () => {
    history.push(
      `${Routes.Paths.ReservationNew}?department=${store.targetTreatmentCheckupResultSummary?.departmentId}&treatmentKind=${store.targetTreatmentCheckupResultSummary?.departmentId}`,
    );
  };

  const [doLoadDownloadLink, loadDownloadLinkStatus] = useTransaction(async (key: string) => {
    const url = await store.fetchDownloadableLink(key);
    if (!url) {
      return;
    }
    WindowUtil.download(url, '添付ファイル.pdf');
  });

  useEffect(() => {
    vacanciesStore.fetchNearestOneData(week, {
      id: store.departmentId,
      clinic: store.departmentClinicId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.departmentId, store.departmentClinicId]);

  return (
    <div className={relativeStyle}>
      <ResultDoctorWrapper
        name={sessionStore?.user?.userEntity?.name || ''}
        organizationName={
          store.targetTreatment?.checkupAppointment?.checkupOrganizationMemberEvent?.event?.organization?.name || ''
        }
        planName={store.targetTreatment?.checkupAppointment?.plan.label || ''}
        sex={sessionStore?.user?.userEntity?.sex || ''}
        date={store.targetDate}
        result={store.targetTreatmentCheckupResultSummary?.result as Result}
      />
      <div className={absoluteStyle}>
        <Wrapper>
          <SummaryComment
            attachmentId={store.checkupResultAttachmentId}
            download={doLoadDownloadLink}
            downloadDisable={!!loadDownloadLinkStatus.running}
            result={store.targetTreatmentCheckupResultSummary?.result as Result}
            resultItems={safeFilter(
              store.subGroups.filter(j => j.result === store.targetTreatmentCheckupResultSummary?.result),
            )}
            comment={store.targetTreatment?.checkupAppointment?.result?.summary?.overallComment || ''}
          />
          {store.targetTreatmentCheckupResultSummary?.result !== JUDGED_RESULT['A'] && store.departmentId && (
            <div className={reserveButtonContainerStyle}>
              <ReserveButton
                onClick={() => toReservation()}
                departmentName={store.departmentName || ''}
                nearest={vacanciesStore.nearestVacancy}
              />
            </div>
          )}
          <ShareButton
            onClick={setModalOpen}
            marginTop={store.targetTreatmentCheckupResultSummary?.result === JUDGED_RESULT['A']}
          />
          <ShareModal
            result={store.targetTreatmentCheckupResultSummary?.result as Result}
            modalOpen={modalOpen}
            onClick={setModalOpen}
          />
        </Wrapper>
        <Wrapper>
          <RecommendConsultation groups={store.filterGroups} />
        </Wrapper>
        <div className={whiteContainerStyle}>
          <Wrapper>
            <OtherItems items={store.groups} />
          </Wrapper>
          <Wrapper>
            <ToTopButton />
          </Wrapper>
        </div>
      </div>
      <Modal visible={!!loadDownloadLinkStatus.running}>
        <Card>ファイルをダウンロード中</Card>
      </Modal>
    </div>
  );
});

const relativeStyle = css`
  position: absolute;
  top: 0;
  width: 100%;
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: ${Dimension.GlobalNavigationWidth / 2}px;
  }
`;
const absoluteStyle = css`
  position: absolute;
  top: 443px;
  width: 100%;
`;

const whiteContainerStyle = css`
  padding-bottom: ${gutter(15.25)};
  background: ${Color.GrayscaleWhite};
`;

const reserveButtonContainerStyle = css`
  margin-top: ${gutter(10)};
`;
