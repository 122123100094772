import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { MutableRefObject, useContext, useEffect } from 'react';

import { Caption } from '../../../../../components/Caption';
import { Checkbox } from '../../../../../components/Checkbox';
import { FormItem, FormLabel, FormLayout, FormSubLabel } from '../../../../../components/Form';
import { Color, FontSize } from '../../../../../constants/Style';
import { Card } from '../../../../../domains/customer/Card';
import { ArgOf } from '../../../../../interface';
import { KEYS, usePayjp } from '../../hooks/usePayjp';
import { PayjpStore } from '../../stores/PayjpStore';
import { BrandIcon } from '../BrandIcon';
import { CardInfo } from '../CardInfo';
import { PseudoInput } from '../PseudoInput';

type Props = {
  callback: Callback;
  onSubmit: MutableRefObject<(() => void) | undefined>;
  onStatusChange?: (status?: Status) => void;
  required?: boolean;
  onPreCardValidateChange?: (preValidate: PreValidate) => void;
  // 設定済みのカードを選択できるプロパティ
  isUsingDefaultCard?: boolean;
  setIsUsingDefaultCard?: (bool: boolean) => void;
  defaultCard?: Card;
};

export const CardInputLayout = observer(
  ({
    callback,
    onSubmit: ref,
    onStatusChange,
    required,
    onPreCardValidateChange,
    isUsingDefaultCard,
    setIsUsingDefaultCard,
    defaultCard,
  }: Props) => {
    const store = useContext(PayjpStore.Context);
    const { focused, brand, onSubmit, status, preValidate } = usePayjp(inputStyle, callback, store);
    useEffect(() => {
      ref.current = () => onSubmit.submit?.();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmit]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onStatusChange?.(status), [status]);

    useEffect(() => onPreCardValidateChange?.(preValidate), [preValidate]);

    return (
      <FormLayout>
        <FormItem>
          <FormLabel required={required}>クレジットカード</FormLabel>
          {defaultCard && setIsUsingDefaultCard && (
            <div className={cardCheckBoxStyle}>
              <Checkbox
                label="登録済みのカードを利用"
                checked={isUsingDefaultCard}
                onChange={e => setIsUsingDefaultCard(e.target.checked)}
              />
            </div>
          )}
          <div className={isUsingDefaultCard ? hiddenStyle : ''}>
            <FormLayout>
              <FormItem>
                <BrandIcon brand={brand} />
              </FormItem>
              <FormItem>
                <FormSubLabel>カード番号</FormSubLabel>
                <PseudoInput
                  anchor={KEYS[0]}
                  focused={focused === KEYS[0]}
                  isError={!!preValidate.cardNumber.error || !!status.error}
                />
              </FormItem>
              <FormItem>
                <div className={rowStyle}>
                  <FormItem>
                    <FormSubLabel>有効期限</FormSubLabel>
                    <PseudoInput
                      anchor={KEYS[1]}
                      focused={focused === KEYS[1]}
                      isError={!!preValidate.cardExpiry.error || !!status.error}
                    />
                  </FormItem>
                  <FormItem>
                    <FormSubLabel>CVV</FormSubLabel>
                    <PseudoInput
                      anchor={KEYS[2]}
                      focused={focused === KEYS[2]}
                      isError={!!preValidate.cardCvc.error || !!status.error}
                    />
                  </FormItem>
                </div>
              </FormItem>
            </FormLayout>
            <div className={descriptionSectionStyle}>
              <Caption type="body">
                <span className={descriptionTextStyle}>
                  デビットカードの場合は、登録時に有効期限確認のため50円の課金を行う場合があります。
                  24時間後に自動的に返金されますので、予めご了承ください。
                </span>
              </Caption>
            </div>
          </div>
          {defaultCard && isUsingDefaultCard && (
            <div className={cardInfoStyle}>
              <CardInfo card={defaultCard} />
            </div>
          )}
        </FormItem>
      </FormLayout>
    );
  },
);

const rowStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

const inputStyle = {
  base: {
    backgroundColor: Color.GrayscaleWhite,
    fontSize: FontSize.Medium,
    lineHeight: 1,
  },
};

const hiddenStyle = css`
  display: none;
`;

const cardCheckBoxStyle = css`
  padding: 16px;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 5px;
`;

const descriptionSectionStyle = css`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${Color.GrayscaleNeutral};
`;

const descriptionTextStyle = css`
  color: ${Color.GrayscaleNeutral};
`;

const cardInfoStyle = css`
  margin-left: 8px;
`;

type Status = {
  running: boolean;
  error: string;
};

export type PreValidate = {
  cardNumber: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
  cardExpiry: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
  cardCvc: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
};

type Callback = ArgOf<typeof usePayjp>[1];
