import format from 'date-fns/format';
import { css } from 'emotion';
import React, { useMemo } from 'react';

import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { gutter } from '../../../../../helpers/Style';
import { Treatment } from '../../../../../interfaces/Treatment';

type Props = {
  width?: number;
  onClick?: (id: string) => void;
  checked?: boolean;
} & Pick<
  Treatment,
  'clinic' | 'date' | 'department' | 'soap' | 'payment' | 'id' | 'duration' | 'attachments' | 'isNoCharge'
>;

export const TreatmentCard = ({
  width,
  onClick,
  checked,
  clinic,
  date,
  department,
  payment,
  id,
  duration,
  isNoCharge,
}: Props) => {
  const toggleCheck = (id: string) => {
    onClick?.(id);
  };

  return (
    <Card
      shadowType="floating"
      width={width}
      onClick={() => {
        toggleCheck(id);
      }}
    >
      <TreatmentCardHeader
        id={id}
        date={date}
        clinic={clinic}
        department={department}
        duration={duration}
        checked={checked}
      />
      <TreatmentCardFooter payment={payment} isNoCharge={isNoCharge} />
    </Card>
  );
};

type HeaderProps = Pick<Props, 'id' | 'clinic' | 'date' | 'department' | 'duration' | 'checked'>;
const TreatmentCardHeader = ({ id, clinic, date, department, checked }: HeaderProps) => (
  <header>
    <Caption type="subheader">
      <div className={headerStyle}>
        <Checkbox key={id} checked={checked} />
        {format(date, 'yyyy年MM月dd日')} ({WeekLabels[date.getDay() || 0]})
      </div>
    </Caption>
    <Caption type="small">
      <div className={clinicNameStyle}>
        {clinic.name} / {department.name}
      </div>
    </Caption>
  </header>
);

type FooterProps = Pick<Props, 'payment' | 'isNoCharge'>;

const formatYen = (n: number) => n.toLocaleString();

const TreatmentCardFooter = ({ isNoCharge, payment }: FooterProps) => {
  const isUndef = useMemo(() => payment.amount === null || payment.amount === undefined, [payment]);
  return (
    <footer className={footerStyle}>
      {isNoCharge ? (
        <>
          <Caption type="small">診察費用合計</Caption>
          <div>¥0</div>
        </>
      ) : !isUndef ? (
        <>
          <Caption type="small">診察費用合計</Caption>
          <div>¥{formatYen(payment.amount as number)}</div>
        </>
      ) : (
        <Caption type="small">診察費用未確定</Caption>
      )}
    </footer>
  );
};

const headerStyle = css`
  display: flex;
  align-items: center;

  > label {
    margin-right: ${gutter(3)};
  }
`;

const clinicNameStyle = css`
  margin-left: ${gutter(8)};
`;

const footerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;
