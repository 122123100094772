import { css, cx } from 'emotion';
import React, { Children, HTMLAttributes, MouseEvent, MutableRefObject, ReactNode, useMemo } from 'react';

import { BorderRadius, Color, Duration, FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';

type Theme = 'neutral' | 'line' | 'google';

type Variant = 'lightGreyContained' | 'functionRedContained' | 'whiteContained';

type BorderColor = 'darkGrey' | 'functionBlue';

type Props = Partial<{
  type: 'submit' | 'reset' | 'button';
  children: ReactNode;
  disabled: boolean;
  tabIndex: HTMLAttributes<HTMLElement>['tabIndex'];
  theme: Theme;
  ghost: boolean;
  block: boolean;
  /**
   * ドロップシャドウで浮いたようなスタイルを適用する
   */
  floating: boolean;
  /**
   * Pill 型のボタンシェイプにする
   */
  pill: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  noBorder: boolean;
  className: string;
  variant: Variant;
  borderColor: BorderColor;
  ref: MutableRefObject<HTMLButtonElement | null>;
}>;

export const Button = ({
  type,
  children,
  disabled,
  tabIndex,
  theme = 'neutral',
  ghost,
  block,
  floating,
  pill,
  onClick,
  noBorder,
  className,
  variant,
  borderColor,
  ref,
}: Props) => {
  const buttonStyle = useMemo(
    () =>
      cx(
        baseStyle,
        themeStyle[theme],
        ghost && ghostStyle[theme],
        block && blockStyle,
        floating && floatingStyle,
        pill && pillStyle,
        noBorder && noBorderStyle,
        variant && variantStyle[variant],
        borderColor && borderColorStyle[borderColor],
      ),
    [ghost, block, floating, pill, theme, noBorder, variant, borderColor],
  );

  return (
    <button
      ref={ref}
      className={`${buttonStyle} ${className}`}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {Children.toArray(children).map(child =>
        typeof child === 'string' ? <span key={`${child}`}>{child}</span> : child,
      )}
    </button>
  );
};

function singleFill(variant: string) {
  switch (variant) {
    case 'lightGreyContained':
      return css`
        color: ${Color.GrayscaleDark};
        background-color: ${Color.GrayscaleLight};
      `;
    case 'functionRedContained':
      return css`
        color: ${Color.GrayscaleWhite};
        background-color: ${Color.FunctionalRedNeutral};
      `;
    case 'whiteContained':
    default:
      return css`
        color: ${Color.BrandTenBlack};
        background-color: ${Color.GrayscaleWhite};

        > svg {
          fill: ${Color.BrandTenBlack};
        }
      `;
  }
}

function setBorderColor(borderColor: string) {
  switch (borderColor) {
    case 'functionBlue':
      return css`
        border-color: ${Color.FunctionalBlueNeutral};
      `;
    case 'darkGrey':
    default:
      return css`
        border-color: ${Color.GrayscaleLight};
      `;
  }
}

function variantFill(color: Color) {
  return css`
    color: ${Color.GrayscaleWhite};
    background-color: ${color};
    border-color: ${color};
    outline-color: ${color};
    fill: ${Color.GrayscaleWhite};

    > svg {
      fill: ${Color.GrayscaleWhite};
    }
  `;
}

function variantGhost(color: Color) {
  return css`
    color: ${color};
    background-color: transparent;

    > svg {
      fill: ${color};
    }

    &:disabled {
      color: ${Color.GrayscaleLight};
      background-color: transparent;
    }
  `;
}

const baseStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: ${gutter(4)} ${gutter(6)};
  font-size: ${FontSize.Medium};
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: ${BorderRadius.Regular};
  transition: opacity ${Duration.Fade};

  > svg {
    ${square(IconSize.Large)}

    &:first-child:not(:last-child) {
      margin-right: ${gutter(2)};
    }

    &:last-child:not(:first-child) {
      margin-left: ${gutter(2)};
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${Color.GrayscaleLight};
    border-color: ${Color.GrayscaleLight};

    /* stylelint-disable-next-line */
    > svg {
      fill: ${Color.GrayscaleLight};
    }
  }

  &:active {
    transition: none;
  }
`;

const noBorderStyle = css`
  border: none;
`;

const blockStyle = css`
  display: flex;
  width: 100%;
`;

const floatingStyle = css`
  box-shadow: 4px 16px 32px rgb(0 0 0 / 16%);
`;

const pillStyle = css`
  border-radius: ${BorderRadius.Circle};
`;

const borderColorStyle: Record<BorderColor, string> = {
  darkGrey: setBorderColor('darkGrey'),
  functionBlue: setBorderColor('functionBlue'),
} as const;

const variantStyle: Record<Variant, string> = {
  lightGreyContained: singleFill('lightGreyContained'),
  functionRedContained: singleFill('functionRedContained'),
  whiteContained: singleFill('whiteContained'),
} as const;

const themeStyle: Record<Theme, string> = {
  neutral: variantFill(Color.BrandTenBlack),
  line: variantFill(Color.BrandLine),
  google: variantFill(Color.BrandGoogle),
} as const;

const ghostStyle: Record<Theme, string> = {
  neutral: variantGhost(Color.BrandTenBlack),
  line: variantGhost(Color.BrandLine),
  google: variantGhost(Color.BrandGoogle),
};
