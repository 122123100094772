import gql from 'graphql-tag';

export const SignedPostType = gql`
  fragment SignedPostType on SignedPost {
    url
    fields {
      key
      value
    }
  }
`;
