import React, { useEffect } from 'react';

import { URLSearchParamsMonad } from '../../../domains/base/SearchParams';
import { useDebounce } from '../../../hooks/useDebounce';
import { LineAuthFailed } from '../../Error/LineAuthFailed';

export const AggressiveBotLink = () => {
  useEffect(() => {
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?${new URLSearchParamsMonad(
      window.location.search,
    )
      .accept('prompt', 'concent')
      .accept('bot_prompt', 'aggressive')
      .toString()}`;
  }, []);
  const failed = useDebounce(3000);

  return failed ? <LineAuthFailed /> : <></>;
};
