import React from 'react';

import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { useContext } from '../../hooks/useContext';
import { useTransaction } from '../../hooks/useTransaction';
import { Demo2Store } from '../../stores/Demo2Store';

type Props = {
  onChangeState: (nextState: 'input' | 'confirm' | 'complete') => void;
};

export const ConfirmSection = ({ onChangeState }: Props) => {
  const store = useContext(Demo2Store.Context);

  const handleClickBack = () => onChangeState('input');

  const handleSubmit = () => {
    onSubmit({ name: store.name, job: store.job });
  };

  // 非同期処理が成功したら実行される関数。
  const handleSuccess = () => onChangeState('complete');

  const { onSubmit, fetching } = useSubmit(handleSuccess);

  return (
    <>
      <h2>Confirm section</h2>
      <p>
        入力内容を{' '}
        <a href="http://jsonplaceholder.typicode.com/" target="_blank" rel="noreferrer noopener">
          JSONPlaceholder
        </a>
        のデモ API に送信します。
      </p>
      <dl>
        <dt>Name:</dt>
        <dd>
          <strong>{store.name}</strong>
        </dd>
        <dt>Job:</dt>
        <dd>
          <strong>{store.job}</strong>
        </dd>
      </dl>
      <div>
        <Button ghost onClick={handleClickBack} disabled={fetching}>
          戻る
        </Button>
        <Button onClick={handleSubmit} disabled={fetching}>
          送信
        </Button>
      </div>
      <Modal visible={fetching}>
        <strong>送信中…</strong>
      </Modal>
    </>
  );
};

/**
 * 非同期処理の一連をカスタムフックとして切り出した例。
 *
 * useTransaction を使って
 * 非同期処理を呼び出す関数と処理中の状態変化がリアルタイムで分かる値を作成し、これを返す。
 *
 * @param onSuccess 非同期処理成功後に呼び出したいコールバック関数
 */
function useSubmit(onSuccess: () => void) {
  // MobX ストアを参照する
  const store = useContext(Demo2Store.Context);

  // onSubmit: 実行すると useTransaction の第一引数に渡した関数が走る
  // submitStatus: onSubmit で実行する処理の状態（処理中かどうかのフラグなど）がリアルタイムで更新される
  const [onSubmit, submitStatus] = useTransaction(
    async ({ name, job }: { name: string; job: string }) => {
      store.setName(name);
      store.setJob(job);

      await store.postUser();

      onSuccess();
    },
    e => console.error('😓😓😓', e.message),
  );

  return { onSubmit, fetching: !!submitStatus.running };
}
