import gql from 'graphql-tag';

import { TreatmentType } from '../fragments/treatment';

export const listYearNoShowTreatments = gql`
  query listYearNoShowTreatments($userId: ID!, $year: String) {
    getUser(id: $userId) {
      noShowTreatment: treatment(statusDate: { beginsWith: { status: "NO_SHOW", date: $year } }, sortDirection: DESC) {
        items {
          ...TreatmentHistory
        }
      }
    }
  }
  ${TreatmentType}
`;
