import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Modal } from '../../../../../../components/Modal';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';
import { Defaults } from '../../../../../../constants/Defaults';
import { FontSize } from '../../../../../../constants/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { approximate } from '../../../../../../utils/NumberUtil';
import { CancelPolicyContent } from '../../../../components/CancelPolicyContent';
import { LastWarningContent } from '../../../../components/LastWarningContent';
import { ReservationDetailStore } from '../../../stores/ReservationDetailStore';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const extraLargeStyle = css`
  font-size: ${FontSize.ExtraLarge};
  font-weight: 700;
`;

type Props = {
  open: boolean;
  handleStateChange: (newState: boolean) => void;
  onConfirm: () => void;
  disabled: boolean;
  covidWarning: boolean; // FIXME COVID only
};

export const CancelConfirmDialog = observer(
  ({ disabled = false, open, handleStateChange, onConfirm, covidWarning }: Props) => {
    const store = useContext(ReservationDetailStore.Context);

    const { visibleLastCaution } = store.cancelPolicies;

    const hasCancelPenalty = useMemo(
      () => !!store.reservation?.date && store.cancelPolicies.hasPenalty(store.reservation.date),
      [store],
    );

    const handleConfirmClick = () => {
      hasCancelPenalty ? store.cancelPolicies.setVisibleLastCaution(true) : onConfirm();
    };

    if (!store.reservation) {
      return (
        <Modal visible={open}>
          <Card>
            <Caption type="header">日付が選択されていません。</Caption>
            <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
              キャンセル
            </Button>
          </Card>
        </Modal>
      );
    }

    return (
      <>
        <Modal visible={open}>
          <Card width={375}>
            {!visibleLastCaution && (
              <div className={centerStyle}>
                <CaptionGroup>
                  <Caption type="subheader">以下の予約を取り消しますか？</Caption>
                  {covidWarning && (
                    <Caption type="danger">
                      原則予約のキャンセルはできません。もしキャンセルした場合、確保したワクチンは別の方に接種いたします旨ご了承下さい。
                    </Caption>
                  )}
                  <hr />
                  <Caption type="subheader">
                    {format(store.reservation.date, 'yyyy年M月d日')}({WeekLabels[getDay(store.reservation.date)]})
                  </Caption>
                  <div className={extraLargeStyle}>
                    {format(store.reservation.date, 'HH:mm')}
                    <span className={smallTimeTildeStyle}> ～ </span>
                    <span className={smallTimeStyle}>
                      [所要{approximate(store.reservation.duration, Defaults.DURATION)}分]
                    </span>
                  </div>
                  <div>CLINIC TEN SHIBUYA / {store.reservation.department.name}</div>
                  <div className={cancelPolicySectionStyle}>
                    <CancelPolicyContent
                      store={store.cancelPolicies}
                      date={store.reservation.date}
                      actionType="cancel"
                    />
                  </div>
                </CaptionGroup>
                <ButtonGroup>
                  <Button
                    disabled={disabled}
                    type="button"
                    onClick={handleConfirmClick}
                    className={hasCancelPenalty ? '' : GtmClasses.gtm.cancel.onCancel}
                  >
                    予約を取り消す
                  </Button>
                  <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
                    前の画面に戻る
                  </Button>
                </ButtonGroup>
              </div>
            )}
            {visibleLastCaution && (
              <LastWarningContent
                type="cancel"
                store={store.cancelPolicies}
                onConfirm={onConfirm}
                onCancel={() => handleStateChange(false)}
                confirmAnalyticsClassName={GtmClasses.gtm.cancel.onCancel}
                penaltyCount={store.penaltyCount}
              />
            )}
          </Card>
        </Modal>
      </>
    );
  },
);

const smallTimeStyle = css`
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const cancelPolicySectionStyle = css`
  margin-top: 16px;
  font-weight: 300;
  text-align: left;
`;
