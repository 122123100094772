import { isSameDay } from 'date-fns/esm';
import getDay from 'date-fns/getDay';
import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

import { Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = {
  items: Date[];
  now?: Date;
};

export const TableHeader = ({ items, now }: Props) => (
  <div className={baseStyle}>
    <div className={spacerStyle} />
    {items.map((item, i) => (
      <Item key={i} index={(getDay(items[0]) + i) % 7} item={item} now={now} />
    ))}
  </div>
);

const Item = ({ index, item, now }: { index: number; item: Date; now?: Date }) => {
  const date = item.getDate();
  const itemHighlightStyle = useMemo(() => {
    if (now && isSameDay(item, now)) {
      return invertedStyle;
    }
    return '';
  }, [now, item]);

  const itemThemeStyle = useMemo(() => {
    if (index === 0) {
      return themeStyle.holiday;
    }
    if (index === 6) {
      return themeStyle.saturday;
    }
    return themeStyle.weekday;
  }, [index]);

  return (
    <div className={cx(itemStyle, itemThemeStyle, itemHighlightStyle)}>
      <span className={dateLabelStyle}>{date}</span>
      <span className={dayLabelStyle}>{WeekLabels[index]}</span>
    </div>
  );
};

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const baseStyle = css`
  display: flex;
  width: 100%;
  background: ${Color.GrayscaleWhite};
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;

const spacerStyle = css`
  flex: 1 1 100%;
  min-width: 60px;
  max-width: 96px;
`;

const itemStyle = css`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  min-width: 45px;
  padding: ${gutter(1)} ${gutter(2)};
  text-align: center;
  border-left: 1px solid ${Color.GrayscaleLight};
`;

const dateLabelStyle = css`
  width: 2em;
  margin-bottom: 2px;
  font-size: ${FontSize.Regular};
  font-weight: 700;
`;

const dayLabelStyle = css`
  font-size: ${FontSize.Tiny};
`;

const themeStyle = {
  weekday: css`
    > .${dayLabelStyle} {
      color: ${Color.GrayscaleNeutral};
    }
  `,
  saturday: css`
    background: ${Color.FunctionalBlueLight};
    &,
    > .${dayLabelStyle} {
      color: ${Color.FunctionalBlueNeutral};
    }
  `,
  holiday: css`
    background: ${Color.FunctionalRedLight};
    &,
    > .${dayLabelStyle} {
      color: ${Color.FunctionalRedNeutral};
    }
  `,
} as const;

const invertedStyle = css`
  color: ${Color.GrayscaleWhite};
  background: ${Color.BrandTenBrown};
`;
