import gql from 'graphql-tag';

import { TreatmentType } from '../fragments/treatment';

export const listYearTreatments = gql`
  query listYearTreatments($userId: ID!, $year: String, $nextToken: String) {
    getUser(id: $userId) {
      completedTreatment: treatment(
        statusDate: { beginsWith: { status: "COMPLETED", date: $year } }
        sortDirection: DESC
        limit: 4
        nextToken: $nextToken
      ) {
        items {
          ...TreatmentHistory
        }
        nextToken
      }
    }
  }
  ${TreatmentType}
`;
