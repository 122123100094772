import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { DepartmentType } from '../../../../../../gql/gql-types';
import { Card } from '../../../../../components/Card';
import { Modal } from '../../../../../components/Modal';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useSteps } from '../../../../../hooks/useSteps';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { ValueOf } from '../../../../../interface';
import { IndividualCheckupReservationStore } from '../IndividualCheckupReservationsNew/stores/IndividualCheckupReservationStore';
import { IndividualCheckupReservationPostInterview } from './IndividualCheckupInterview';
import { IndividualCheckupOptionSelectPage } from './IndividualCheckupOptionSelect';
import { IndividualCheckupReservationPreInterview } from './IndividualCheckupPreInterview';

const STEPS = {
  PRE_INTERVIEW: 'preInterview',
  OPTION_SELECT: 'optionSelect',
  POST_INTERVIEW: 'postInterview',
} as const;

type Step = ValueOf<typeof STEPS>;

export const IndividualCheckupReservationFixPage = observer(() => {
  const { reservationId } = useReservationId();
  const [step, setStep] = useState<Step>(STEPS.PRE_INTERVIEW);
  const store = useMemo(() => {
    const res = new IndividualCheckupReservationStore();
    res.department = {
      baseDuration: 0,
      clinic: '01',
      icon: 'men',
      id: 'C',
      name: '健診',
      termsUrl: '',
      alertThreshold: 0,
      type: DepartmentType.Checkup,
      clinicName: 'CLINIC TEN SHIBUYA',
    };
    return res;
  }, []);
  useSteps(step, setStep);
  const [load, loadStatus] = useTransaction(() =>
    store.fetchCheckupTreatmentInfo(reservationId).then(() => store.fetchPreInterview()),
  );

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <IndividualCheckupReservationStore.Context.Provider value={store}>
      <Modal visible={!!loadStatus.running}>
        <Card>読み込み中です</Card>
      </Modal>
      <Main step={step} setStep={setStep} />
    </IndividualCheckupReservationStore.Context.Provider>
  );
});

type Props = {
  step?: Step;
  setStep: (step: Step) => void;
};

const Main = observer(({ step, setStep }: Props) => {
  switch (step) {
    case 'preInterview':
    default:
      return <IndividualCheckupReservationPreInterview onNext={() => setStep(STEPS.OPTION_SELECT)} />;
    case 'optionSelect':
      return <IndividualCheckupOptionSelectPage onNext={() => setStep(STEPS.POST_INTERVIEW)} />;
    case 'postInterview':
      return <IndividualCheckupReservationPostInterview />;
  }
});
