import { css, cx } from 'emotion';
import React, { ReactNode, useMemo } from 'react';

import { gutter } from '../../helpers/Style';

const baseStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${gutter(3)};
  width: 100%;
`;

type Props = {
  children: ReactNode;
  gutter?: number;
  className?: string;
};

export const ButtonGroup = ({ className, children, gutter = 0 }: Props) => {
  const style = useMemo(
    () =>
      cx(
        baseStyle,
        css`
          margin: ${gutter}px 0;
        `,
        className,
      ),
    [gutter],
  );
  return <div className={style}>{children}</div>;
};
