import { css } from 'emotion';
import React from 'react';

import { Card } from '../../../../../../components/Card';
import { ProgressBar } from '../../../../../../components/ProgressBar';

type Props = {
  answered: number;
  total: number;
};
export const InterviewProgress = ({ answered, total }: Props) => (
  <div className={style}>
    <Card shadowType="floating">
      <div>残り{total - answered}問</div>
      <div>
        <ProgressBar value={(answered / total) * 100} />
      </div>
    </Card>
  </div>
);

const style = css`
  width: 100%;
`;
