import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Breakpoint, Color } from '../../../constants/Style';
import { gutter } from '../../../helpers/Style';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';
import { Card } from '../../Card';
import { Icon } from '../../Icon';
import { Modal } from '../../Modal';

type FileProps = {
  filename: string;
  fileKey: string;
  fileUrl?: string;
};
type Props = {
  files: FileProps[];
  onDelete?: (key: string) => void;
  onClickUpload?: () => void;
  disabled?: boolean;
  confirm?: boolean;
};

export const UploadedInsurancePreviews = observer(({ files, onDelete, onClickUpload, disabled, confirm }: Props) => {
  const [preview, setPreview] = useState<string>('');

  return (
    <div className={layout}>
      {/* 現状だと一つの画像のみなので一つのURLであれば表示させる対応で */}
      {files.length === 1 ? (
        <div className={cardLayout}>
          {files.map(f => (
            <div className={imageStyle} key={f.fileKey}>
              <PreviewDialog
                open={preview === f.fileKey}
                filename={f.filename}
                fileUrl={f.fileUrl}
                onClose={() => setPreview('')}
              />
              <div className={fileName} onClick={() => setPreview(f.fileKey)}>
                <img src={f.fileUrl} alt="保険証画像" />
              </div>
              {!confirm && (
                <div className={buttonContainerStyle}>
                  <Button noBorder variant="lightGreyContained" disabled={disabled} onClick={() => onClickUpload?.()}>
                    別のファイルを選択
                  </Button>
                  <Button noBorder variant="whiteContained" disabled={disabled} onClick={() => onDelete?.(f.fileKey)}>
                    <Icon name="trash" />
                    削除
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <Card shadowType="none" disabled={disabled} onClick={() => onClickUpload?.()}>
          <div className={uploadStyle}>
            <Icon name="upload" />
            <p>
              保険証表面の写真を撮影して、
              <br /> アップロードしてください
            </p>
          </div>
        </Card>
      )}
    </div>
  );
});

type DialogProps = Pick<FileProps, 'filename' | 'fileUrl'> & {
  open: boolean;
  onClose: () => void;
};
const PreviewDialog = ({ fileUrl, filename, onClose, open }: DialogProps) => (
  <Modal visible={open}>
    <Card>
      <div>{filename}</div>
      <div className={imageContainerStyle}>
        <img src={fileUrl} alt={filename} />
      </div>
      <div>
        <ButtonGroup>
          <Button block onClick={() => onClose()}>
            閉じる
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  </Modal>
);

const imageContainerStyle = css`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

const imageStyle = css`
  margin-bottom: ${gutter(2)};
`;

const layout = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fileName = css`
  margin-bottom: ${gutter(3)};
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
`;

const buttonContainerStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: ${Breakpoint.ScreenSm}px) {
    justify-content: space-around;
  }
`;

const cardLayout = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const uploadStyle = css`
  color: ${Color.GrayscaleNeutral};
  text-align: center;

  > svg {
    width: 33px;
    height: 33px;
    fill: ${Color.GrayscaleNeutral};
  }
`;
