import { format } from 'date-fns/esm';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../gql/client';
import { CheckupResultSetStatus, GetMyPageQuery, GetMyPageQueryVariables, TreatmentStatus } from '../../gql/gql-types';
import getMyPage from '../../gql/operations/getMyPage';
import { Defaults } from '../constants/Defaults';
import { Reservation } from '../interfaces/Reservation';
import { Treatment } from '../interfaces/Treatment';
import { TreatmentDate, TreatmentListStore } from '../pages/Treatments/TreatmentList/stores/TreatmentListStore';
import { WaitingListItemStore } from '../pages/WaitingListItems/stores/WaitingListItemStore';
import { safeFilter } from '../utils/CollectionUtil';

type Profile = Pick<Required<NonNullable<GetMyPageQuery['getUser']>>, 'id'>;

const toNonNullArray = <X>(nullable: Array<X | null | undefined> | null | undefined): X[] =>
  nullable ? nullable.filter(n => n !== undefined && n != null).map(n => n as X) : [];

export class MyPageStore {
  public static Context = createContext<null | MyPageStore>(null);
  public static toNonNullArray = toNonNullArray;

  constructor() {
    makeAutoObservable(this, {
      profile: observable,
      treatments: observable,
      displayReservations: computed,
      reservations: observable,
      waitingListItems: observable,
      fetchInitial: action,
      setProfile: action,
      hasResultTreatments: computed,
    });
  }

  public profile?: Profile = undefined;

  public setProfile(p: Profile) {
    this.profile = p;
  }

  public treatments: Treatment[] = [];

  public reservations: Reservation[] = [];

  public waitingListItems: WaitingListItemStore[] = [];

  public get displayReservations() {
    return safeFilter([...this.reservations, ...this.waitingListItems.map(x => x.reservationCardProps)]);
  }

  public async fetchInitial() {
    if (!this.profile?.id) {
      return;
    }
    const res = await GQL.query<GetMyPageQueryVariables, GetMyPageQuery>(getMyPage, { userId: this.profile.id });

    runInAction(() => {
      this.profile = res.getUser ? { id: res.getUser.id } : undefined;
      this.reservations = res.getUser?.fixedTreatment.items
        ? res.getUser.fixedTreatment.items
            .filter(item => item?.date && item.date >= format(new Date(), 'yyyy-MM-dd'))
            .map(item => ({
              id: item?.id || '',
              clinic: {
                id: '',
                name: '',
                address: '東京都渋谷区道玄坂1-10-20 渋谷ビル3F',
                lat: 0,
                lng: 0,
                ...item?.department?.clinic,
              },
              date: item ? new TreatmentDate(item.date, item.time).toDate() : new Date(),
              department: { id: '', name: '', ...item?.department },
              treatmentKind: { id: '', name: '', ...item?.treatmentKind },
              status: item?.status || TreatmentStatus.Fixed,
              duration: item?.displayDuration || item?.duration || Defaults.DURATION,
              interviewStatus: item?.noInterview
                ? 'notNeeded'
                : !item?.interview
                ? 'notStarted'
                : item.interview.submitted
                ? 'finished'
                : 'started',
              hidden: item?.hidden || false,
              displayDuration: item?.displayDuration || 0,
            }))
        : [];
      this.treatments = safeFilter(
        TreatmentListStore.convertTreatment(safeFilter(res.getUser?.completedTreatment.items)),
      );

      this.waitingListItems = safeFilter(res.getUser?.waitingList.items).map(x => WaitingListItemStore.fromFragment(x));
    });
  }

  public get hasResultTreatments() {
    return this.treatments.filter(t => t.checkupAppointment.result?.status === CheckupResultSetStatus.Sent);
  }
}
