import React from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';

type Props = {
  onClickCancel?: () => void;
  disabled?: boolean;
};
export const InterviewWarningCard = ({ onClickCancel, disabled }: Props) => (
  <Card>
    <CaptionGroup>
      <Caption type="danger">
        咳、のどの痛み、全身倦怠感、関節痛、下痢、発熱、吐き気・嘔吐の症状がある方は、感染症リスクから当院では受診いただけません。
      </Caption>
    </CaptionGroup>
    <ButtonGroup gutter={10}>
      <Button onClick={onClickCancel} disabled={disabled}>
        内科をキャンセルする
      </Button>
    </ButtonGroup>
  </Card>
);
