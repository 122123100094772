import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Box } from '../../../../components/Box';
import { Color, FontSize } from '../../../../constants/Style';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const EmptyCards = ({ children, onClick }: Props) => (
  <Box>
    <div onClick={onClick} className={style}>
      {children}
    </div>
  </Box>
);

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 166px;
  font-size: ${FontSize.Regular};
  color: ${Color.GrayscaleNeutral};
`;
