import gql from 'graphql-tag';

export const listLumeccaVacancies = gql`
  query listLumeccaVacancies($input: VacancyUnitCalculation!) {
    listLumeccaConsecutiveVacancies(input: $input) {
      date
      time
      available
      estimatedDuration
      doctor
      laneId
      waitAvailable
    }
  }
`;
