import { addYears, format, getYear } from 'date-fns/esm';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { Button } from '../../../components/Button';
import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Option, Select } from '../../../components/Select';
import { Wrapper } from '../../../components/Wrapper';
import { Defaults } from '../../../constants/Defaults';
import { Color } from '../../../constants/Style';
import { gutter } from '../../../helpers/Style';
import { useContext } from '../../../hooks/useContext';
import { useSteps } from '../../../hooks/useSteps';
import { TransactionStatus, useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { ReceiptListStore } from '../stores/ReceiptListStore';
import { DownloadButton } from './components/DownloadButton';
import { TreatmentCardListLayout } from './components/TreatmentCardListLayout';

const Years = [
  ReceiptListStore.ALL_TERMS,
  // リリース日から、
  ...new Array(Math.abs(getYear(new Date()) - getYear(Defaults.RELEASED_AT)) + 1)
    .fill(undefined)
    // 一年ずつ足していき、
    .map((_, i) => addYears(Defaults.RELEASED_AT, i))
    .map(d => format(d, 'yyyy'))
    // 反転する（全年度の次は今年）
    .reverse(),
];

type Props = {
  store: ReceiptListStore;
  doDownload: () => void;
  downloadLinkStatus: TransactionStatus;
};
export const ReceiptsListPage = observer(({ store, doDownload, downloadLinkStatus }: Props) => {
  const sessionStore = useContext(SessionStore.Context);
  const [doFetchNext, fetchNextStatus] = useTransaction(() => store.fetchNext());
  const STEP = {
    SELECT: 'receiptSelect',
    EMAIL: 'emailInput',
    Complete: 'receiptSendComplete',
  };
  const initialCheckedIds: string[] = [];
  const [step, setStep] = useState<string>(STEP.SELECT);
  const [checkedIds, setCheckedIds] = useState(initialCheckedIds);
  useSteps(step, setStep);

  const [receiptType, setReceiptType] = useState<'completed' | 'penalty'>('completed');

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    store.setInitial(sessionStore.user.userId, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  useEffect(() => {
    receiptType === 'completed' ? store.loadSelectedYearTreatments(true) : store.loadSelectedNoShowYearTreatments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.year, receiptType]);

  useEffect(() => {
    setCheckedIds(store.checkedIds);
  }, [store.checkedIds]);

  return (
    <Wrapper>
      <DocumentTitle title="診察履歴・領収書一覧" />
      <Caption type="header" underline align="center">
        領収書・ダウンロード
      </Caption>
      <Caption type="subheader">
        {receiptType === 'completed' && '診療費用領収証'}
        {receiptType === 'penalty' && 'キャンセル料領収証'}
      </Caption>
      <div className={selectContainerStyle}>
        <Select color="white" value={store.year} onChange={year => store.setYear(year)}>
          {Years.map(year => (
            <Option value={year} key={year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
      <div className={buttonContainerStyle}>
        <div className={buttonStyle}>
          <Button block onClick={() => store.setAllCheckedIds()}>
            全て選択
          </Button>
        </div>
        <div className={buttonStyle}>
          <Button block ghost onClick={() => store.setAllCheckedIdsReset()}>
            選択解除
          </Button>
        </div>
      </div>
      <TreatmentCardListLayout
        treatments={store.list}
        checkedIds={checkedIds}
        hasNext={receiptType !== 'penalty' && !fetchNextStatus.running && !!store.nextToken}
        onClick={id => store.setCheckedId(id)}
        onClickNext={() => doFetchNext()}
        onDownloadAll={() => doDownload()}
        downloadDisabled={downloadLinkStatus.running}
      />
      <div className={captionStyle}>
        {receiptType === 'completed' && (
          <>
            キャンセル料の明細は
            <button className={linkStyle} onClick={() => setReceiptType('penalty')}>
              こちら
            </button>
            から
          </>
        )}
        {receiptType === 'penalty' && (
          <>
            診療の明細は
            <button className={linkStyle} onClick={() => setReceiptType('completed')}>
              こちら
            </button>
            から
          </>
        )}
      </div>
      <DownloadButton checkedIds={checkedIds} onClick={() => setStep(STEP.EMAIL)} />
    </Wrapper>
  );
});
const selectContainerStyle = css`
  margin-top: ${gutter(4)};
  margin-bottom: ${gutter(8)};
`;

const buttonContainerStyle = css`
  display: flex;
  margin-bottom: ${gutter(8)};
`;

const buttonStyle = css`
  width: 100%;

  :first-of-type {
    margin-right: ${gutter(4)};
  }

  :last-of-type {
    margin-left: ${gutter(4)};
  }
`;

const captionStyle = css`
  margin-top: ${gutter(8)};
  text-align: right;
`;

const linkStyle = css`
  padding: 0;
  color: ${Color.FunctionalBlueNeutral};
  background: none;
  border: none;
  border-bottom: 1px solid ${Color.FunctionalBlueNeutral};
  outline: none;
`;
