import {
  AnswerInterviewInput,
  AnswerInterviewMutation,
  AnswerInterviewMutationVariables,
  ReAnswerInterviewInput,
  ReAnswerInterviewMutation,
  ReAnswerInterviewMutationVariables,
  StartInterviewMutation,
  StartInterviewMutationVariables,
} from 'gql/gql-types';
import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import answerInterview from '../../../../../gql/operations/answerInterview';
import reAnswerInterview from '../../../../../gql/operations/reAnswerInterview';
import startInterview from '../../../../../gql/operations/startInterview';

type Interview = StartInterviewMutation['startInterview'];

// FIXME
const defaultQuestionLength = 8;

export class Interviews {
  public static Context = createContext<Interviews | null>(null);
  public interview: Interview | null = null;

  constructor(public treatmentId: string) {
    makeObservable(this, {
      interview: observable,
      setInterview: action,
      startInterview: action,
      answerInterview: action,
      treatmentId: observable,
      estimatedQuestions: computed,
      reAnswerInterview: action,
    });
  }

  get estimatedQuestions() {
    if (this.interview?.finished) {
      return this.interview.questions?.length || 0;
    }
    return Math.max(this.interview?.questions?.length || 0, defaultQuestionLength);
  }

  setInterview = (i: Interview) => {
    this.interview = i;
  };

  startInterview = async () => {
    const res = await GQL.query<StartInterviewMutationVariables, StartInterviewMutation>(startInterview, {
      input: { treatmentId: this.treatmentId },
    });
    this.setInterview(res.startInterview);
  };

  answerInterview = async (inp: AnswerInterviewInput) => {
    const res = await GQL.query<AnswerInterviewMutationVariables, AnswerInterviewMutation>(answerInterview, {
      input: inp,
    });
    this.setInterview(res.answerInterview);
  };

  reAnswerInterview = async (inp: ReAnswerInterviewInput) => {
    const res = await GQL.query<ReAnswerInterviewMutationVariables, ReAnswerInterviewMutation>(reAnswerInterview, {
      input: inp,
    });
    this.setInterview(res.reAnswerInterview);
  };
}
