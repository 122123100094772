import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';

import { DepartmentType } from '../../../../../gql/gql-types';
import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Icon } from '../../../../components/Icon';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ReservationDetailInfo } from '../components/ReservationDetailInfo';
import { ReservationDetailStore } from '../stores/ReservationDetailStore';
import { CancelConfirmDialog } from './components/CancelConfirmDialog';
import { ConfirmDialog } from './components/ConfirmDialog';

export const ReservationActionsPage = observer(() => {
  const store = useContext(ReservationDetailStore.Context);
  const { push, reservationId } = useReservationId();
  const [doCancel, cancelStatus] = useTransaction(() => store.setToCanceled());

  const [editConfirming, setEditConfirming] = useState(false);
  const [cancelConfirming, setCancelConfirming] = useState(false);

  const { addToast } = useToast();

  const isLate = useMemo(() => (store.reservation?.date ?? new Date()) < new Date(), [store.reservation?.date]);

  const onCancelConfirm = async () => {
    await doCancel();

    const toastMsg =
      store.reservation?.date && store.cancelPolicies.isJustBeforeAction(store.reservation.date)
        ? '予約を取り消しました'
        : 'またのご予約をお待ちしております';
    addToast({ message: toastMsg, icon: 'check-circle', theme: 'success' });

    push(Routes.Paths.Root);
  };

  const checkup = store.reservation?.department.type && store.reservation.department.type === DepartmentType.Checkup;

  const pushToReservationEdit = () => {
    if (checkup && store.reservation?.isIndividualCheckup) {
      push(Routes.Paths.CheckupReservationEditIndividual, store.reservation.id);
      return;
    }

    if (checkup) {
      push(Routes.Paths.CheckupReservationEdit, store.reservation?.id);
      return;
    }

    push(Routes.Paths.ReservationEdit, store.reservation?.id);
  };

  const isJustBeforeEdit = !!store.reservation?.date && store.cancelPolicies.isJustBeforeAction(store.reservation.date);

  const hasPenalty = !!store.reservation?.date && store.cancelPolicies.hasPenalty(store.reservation.date);

  return (
    <Wrapper>
      <DocumentTitle title="予約時間の変更・取消" />
      <ConfirmDialog open={editConfirming} handleStateChange={setEditConfirming} onConfirm={pushToReservationEdit} />
      <CancelConfirmDialog
        disabled={!!cancelStatus.running}
        handleStateChange={setCancelConfirming}
        open={cancelConfirming}
        onConfirm={onCancelConfirm}
        covidWarning={!!store.reservation?.treatmentKind?.vaccine}
      />
      <Caption type="header" align="center" underline>
        {checkup ? '予約時間・オプションの変更・取消' : ' 予約時間の変更・取消'}
      </Caption>
      {isLate && (
        <Caption type="danger" align="left">
          予約時間が過ぎているため予約の変更・キャンセルはできません。
        </Caption>
      )}
      <ReservationDetailInfo
        date={store.reservation?.date}
        duration={store.reservation?.displayDuration || store.reservation?.duration}
        clinic={store.reservation?.clinic}
        department={store.reservation?.department}
        treatmentKind={store.reservation?.treatmentKind}
        isCorporateCheckup={
          (store.reservation?.checkupAppointmentId && !store.reservation.isIndividualCheckup) || false
        }
      />

      <ButtonGroup gutter={20}>
        {!isLate && (
          <>
            <Button
              disabled={!store.reservation || store.reservation.treatmentKind?.vaccine /** FIXME COVID only */}
              onClick={isJustBeforeEdit ? () => setEditConfirming(true) : pushToReservationEdit}
            >
              {checkup ? '予約時間・オプションを変更する' : '予約時間を変更する'}
            </Button>
            {hasPenalty && (
              <Caption type="danger" align="left">
                直前の予約時間の変更は、キャンセル後に再予約という扱いとなります。
              </Caption>
            )}
            <Button ghost onClick={() => setCancelConfirming(true)} disabled={!store.reservation}>
              予約を取り消す
            </Button>
          </>
        )}
        <Button ghost noBorder onClick={() => push(Routes.Paths.ReservationShow, reservationId)}>
          <Icon name="sign-in-alt" />
          予約詳細に戻る
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
