import { differenceInMinutes } from 'date-fns/esm';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ComponentProps } from 'react';

import { GQL } from '../../../../gql/client';
import {
  DeleteWaitingListItemMutation,
  DeleteWaitingListItemMutationVariables,
  GetWaitingListItemQuery,
  GetWaitingListItemQueryVariables,
  TreatmentStatus,
  WaitingListItemTypeFragment,
} from '../../../../gql/gql-types';
import { deleteWaitingListItem } from '../../../../gql/operations/deleteWaitingListItem';
import { getWaitingListItem } from '../../../../gql/operations/getWaitingListItem';
import { GoogleCalendarURL } from '../../Reservations/ReservationDetail';
import { ReservationCard } from '../../Root/components/ReservationCards/ReservationCard';

export class WaitingListItemStore {
  constructor() {
    makeAutoObservable(this, {
      origin: observable,
      setOrigin: action,
      id: computed,
      duration: computed,
      reservationCardProps: computed,
      googleCalendarUrl: computed,
      fetch: action,
    });
  }

  public origin?: WaitingListItemTypeFragment = undefined;

  public async fetch(id: string) {
    const [clinic, sortKey] = [id.split('#')[0], id.split('#').slice(1).join('#')];
    const item = await GQL.query<GetWaitingListItemQueryVariables, GetWaitingListItemQuery>(getWaitingListItem, {
      clinic,
      sortKey,
    });

    this.origin = item.getWaitingListItem || undefined;
  }

  public async deleteItem() {
    await GQL.query<DeleteWaitingListItemMutationVariables, DeleteWaitingListItemMutation>(deleteWaitingListItem, {
      input: {
        clinic: this.origin?.clinic?.id || '',
        startDepartmentTreatmentKindUserId: this.sortKey,
      },
    });
  }

  public setOrigin(origin: WaitingListItemTypeFragment) {
    this.origin = origin;
  }

  public static fromFragment(fragment: WaitingListItemTypeFragment) {
    const res = new WaitingListItemStore();
    res.setOrigin(fragment);
    return res;
  }

  public get id() {
    return `${this.origin?.clinic?.id}#${this.sortKey}`;
  }

  public get sortKey() {
    return `${this.origin?.start}#${this.origin?.department?.id}#${
      this.origin?.treatmentKind?.id || this.origin?.department?.id
    }#${this.origin?.userId}`;
  }

  public get duration() {
    if (!this.origin) {
      return 0;
    }
    return Math.abs(differenceInMinutes(new Date(this.origin.start), new Date(this.origin.end)));
  }

  public get reservationCardProps(): ComponentProps<typeof ReservationCard> | undefined {
    if (!this.origin) {
      return undefined;
    }
    return {
      clinic: this.origin.clinic || { id: '', name: '' },
      date: new Date(this.origin.start),
      department: (this.origin.department as any) || { id: '', name: '' },
      treatmentKind: (this.origin.treatmentKind as any) || { id: '', name: '' },
      duration: this.duration,
      id: this.id,
      status: TreatmentStatus.Fixed,
      displayDuration: this.duration,
      variant: 'waitingList',
    };
  }

  public get googleCalendarUrl() {
    return new GoogleCalendarURL(
      `${this.origin?.clinic?.name} キャンセル待ち予定`,
      [this.date, this.endDate],
      '',
      '',
    ).toString();
  }

  public get date() {
    return new Date(this.origin?.start || 0);
  }

  public get endDate() {
    return new Date(this.origin?.end || 0);
  }
}
