import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DepartmentType } from '../../../../../gql/gql-types';
import { Caption, CaptionGroup } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { useError } from '../../../../components/ErrorDialog';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { Department } from '../../../../domains/reservation/Department';
import { useContext } from '../../../../hooks/useContext';
import { useTransaction } from '../../../../hooks/useTransaction';
import { SessionStore } from '../../../../stores/SessionStore';
import { VacanciesStore } from '../../stores/VacanciesStore';
import { DepartmentsStore } from '../stores/DepartmentsStore';
import { ReservationStore } from '../stores/ReservationStore';
import { ClinicDepartmentCardLayout } from './components/DepartmentCardLayout';

/**
 * 診療科を選択するページコンポーネント
 */
export const DepartmentSelectPage: React.FC<{ onNext?: () => void }> = observer(({ onNext }) => {
  const reservationStore = useContext(ReservationStore.Context);
  const departmentsStore = useMemo(() => new DepartmentsStore(), []);
  const sessionStore = useContext(SessionStore.Context);
  const vacancyStore = useContext(VacanciesStore.Context);
  const { setError } = useError();
  const location = useLocation();

  const onSelectDepartment = useCallback(
    (department: Department) => {
      reservationStore.setDepartment(department);
      onNext?.();
    },
    [onNext, reservationStore],
  );

  const [doFetchDepartments] = useTransaction(async () => {
    if (sessionStore.authenticated) {
      await sessionStore.fetchUserEntity();

      if (!sessionStore.user?.userEntity?.name) {
        setError({
          message: 'ユーザー登録がまだ完了していません。先にユーザー情報を登録してください。',
          onOk: () => {
            window.location.replace(`${Routes.Paths.AccountName}`);
          },
        });
        return;
      }

      if (!sessionStore.user.userEntity.customer?.defaultCard) {
        setError({
          message: '決済情報登録がまだ完了していません。先に決済情報を登録してください。',
          onOk: () => {
            window.location.replace(
              `${Routes.Paths.AccountSettlementEdit}?url=${encodeURI(new URL(window.location.href).pathname)}`,
            );
          },
        });
        return;
      }
    }
    await departmentsStore.fetchDepartments();
  });

  useEffect(() => {
    doFetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const week = useMemo(() => new Date(), []);
  useEffect(() => {
    // コースベースのものは直近予約を決められないので
    departmentsStore.departments
      .filter(dp => !dp.type || dp.type !== DepartmentType.CourseBased)
      .forEach(d => vacancyStore.prefetch(week, d, reservationStore.online));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsStore.departments]);

  useEffect(() => {
    doFetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clinics = useMemo(
    () =>
      departmentsStore.clinics.map(c => ({
        ...c,
        departments: c.departments.map(d => ({
          department: d,
          nearest: vacancyStore.findNearest(week, d.clinic, d.id),
        })),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departmentsStore.clinics, vacancyStore.resolvedAt],
  );

  useEffect(() => {
    const targetStr = 'departmentId=';
    if (location.search.indexOf(targetStr) && clinics.length > 0) {
      const departmentId = location.search.substring(location.search.indexOf(targetStr) + targetStr.length);
      const targetClinicDepartment = clinics[1].departments.find(d => d.department.id === departmentId);
      if (targetClinicDepartment) {
        onSelectDepartment(targetClinicDepartment.department);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinics]);

  return (
    <Wrapper>
      <DocumentTitle title="診療科選択" />
      <CaptionGroup>
        <Caption type="header">クリニック・診療科を選択してください</Caption>
        <Caption type="body">
          ※新型コロナウィルス対策やスムーズな来院体験を優先し、原則「完全WEB予約制」とさせていただいております
          <br />
          ※必ずご本人のLINEアカウントでのご予約をお願いします。（診療カルテと連携しているため、ご本人以外のLINEアカウントの場合、受診予約が完了できません。）
        </Caption>
      </CaptionGroup>

      <ClinicDepartmentCardLayout
        clinics={clinics}
        selectedId={reservationStore.department?.id}
        onClick={onSelectDepartment}
        online={reservationStore.online}
      />

      {/* {reservationStore.department && (
        <DepartmentConfirmButton departmentName={reservationStore.department.name} onClick={() => onNext?.()} />
      )} */}
    </Wrapper>
  );
});
