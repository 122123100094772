import gql from 'graphql-tag';

export const validateVaccinePassword = gql`
  query validateVaccinePassword($input: ValidateVaccinePasswordInput!) {
    validateVaccinePassword(input: $input) {
      valid
      error
    }
  }
`;
