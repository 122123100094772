import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { Calendar } from '../../../components/Calendar';
import { Wrapper } from '../../../components/Wrapper';
import { Dimension } from '../../../constants/Style';
import { useMedia } from '../../../hooks/useMedia';
import { useTransaction } from '../../../hooks/useTransaction';
import { noop } from '../../../utils/FunctionUtil';
import { VacanciesStore } from '../../Reservations/stores/VacanciesStore';

type VacancyFetchArgs = {
  department: { id: string; clinic: string };
  treatmentKind: { id: string };
};

const useVacancyFetchArgs = () => {
  const [args, setArgs] = useState<VacancyFetchArgs>();
  useEffect(() => {
    const query = new URL(window.location.href).searchParams;
    if (!(query.has('clinic') && query.has('department'))) {
      return;
    }
    setArgs({
      department: { id: query.get('department') as string, clinic: query.get('clinic') as string },
      treatmentKind: { id: query.get('treatmentKind') || (query.get('department') as string) },
    });
  }, []);
  return args;
};

export const VacanciesDisplayPage = observer(() => {
  const store = useMemo(() => new VacanciesStore(), []);
  const args = useVacancyFetchArgs();
  const wrapped = useMedia([{ query: `(min-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);
  const now = useMemo(() => new Date(), []);
  const [week, setWeek] = useState(new Date());

  const [loadVacancies] = useTransaction(async (week: Date) => {
    if (!args) {
      return;
    }
    await store.fetchVacancies(week, args.department, args.treatmentKind);
    await store.prefetchNext(week, args.department, false, args.treatmentKind);
  });

  useEffect(() => {
    loadVacancies(week);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, args]);

  return wrapped ? (
    <Wrapper>
      {store.displayVacancies && (
        <Calendar
          items={store.displayVacancies}
          now={now}
          onSelect={noop}
          onClickNextWeek={d => setWeek(d)}
          onClickPrevWeek={d => setWeek(d)}
          alertThreshold={0}
        />
      )}
    </Wrapper>
  ) : (
    <>
      {store.displayVacancies && (
        <Calendar
          items={store.displayVacancies}
          now={now}
          onSelect={noop}
          onClickNextWeek={d => setWeek(d)}
          onClickPrevWeek={d => setWeek(d)}
          alertThreshold={0}
        />
      )}
    </>
  );
});
