import { css } from 'emotion';
import React from 'react';

import { Card } from '../../../../../components/Card';
import { Icon } from '../../../../../components/Icon';
import { Modal } from '../../../../../components/Modal';
import { Breakpoint, Color, FontSize } from '../../../../../constants/Style';
import { gutter, square } from '../../../../../helpers/Style';

type Props = {
  name?: string;
  description?: string;
  modalOpen: boolean;
  onClick: (modalOpen: boolean) => void;
};

export const GroupInfoModal = ({ name, description, modalOpen, onClick }: Props) => (
  <Modal onClick={onClick} visible={modalOpen}>
    <div className={containerStyle}>
      <Card>
        <div className={headerContainerStyle}>
          <Icon name="question-circle" />
          <p className={headerWordStyle}>{name}</p>
        </div>
        <p className={descriptionStyle}>{description}</p>

        <p className={closeStyle} onClick={() => onClick(false)}>
          閉じる
        </p>
      </Card>
    </div>
  </Modal>
);

const containerStyle = css`
  max-width: 540px;
  padding-top: ${gutter(2)};
  text-align: center;
  @media (max-width: ${Breakpoint.ScreenXs}px) {
    max-width: 320px;
  }
`;

const headerContainerStyle = css`
  display: flex;
  align-items: center;

  > svg {
    ${square(24)};

    margin-right: ${gutter(2.25)};
    fill: ${Color.FunctionalBlueNeutral};
  }
`;

const headerWordStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const descriptionStyle = css`
  margin-bottom: ${gutter(2.5)};
  font-size: ${FontSize.Regular};
  color: ${Color.GrayscaleNeutral};
  text-align: left;
`;

const closeStyle = css`
  padding-top: ${gutter(3.5)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
  cursor: pointer;
  border-top: 1px solid ${Color.GrayscaleLight};
`;
