import { css } from 'emotion';
import React, { useEffect } from 'react';

import { DocumentTitle } from '../../components/DocumentTitle';
import { Wrapper } from '../../components/Wrapper';
import { useContext } from '../../hooks/useContext';
import { useTransaction } from '../../hooks/useTransaction';
import { MyPageStore } from '../../stores/MyPageStore';
import { SessionStore } from '../../stores/SessionStore';
import { CheckupResultCards } from './components/CheckupResultCards';
import { News } from './components/News';
import { ReservationCards } from './components/ReservationCards';
import { TreatmentCards } from './components/TreatmentCards';

export const RootPage = () => {
  const sessionStore = useContext(SessionStore.Context);
  const store = useContext(MyPageStore.Context);
  const [doFetchInitial] = useTransaction(() => store.fetchInitial());

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    store.setProfile({ id: sessionStore.user.userId });
    doFetchInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  return (
    <>
      <DocumentTitle title="マイページ" />
      <Wrapper>
        <div className={layoutStyle}>
          <ReservationCards reservations={store.displayReservations} />
          <TreatmentCards treatments={store.treatments} />
          <CheckupResultCards treatments={store.hasResultTreatments} />
          <News articles={[]} />
        </div>
      </Wrapper>
    </>
  );
};

const layoutStyle = css`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 42px;
`;
