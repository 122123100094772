import format from 'date-fns/format';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { DepartmentType } from '../../../../gql/gql-types';
import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { useContext } from '../../../hooks/useContext';
import { ClinicMap } from './components/ClinicMap';
import { ReservationDetailActions } from './components/ReservationDetailAction';
import { ReservationDetailInfo } from './components/ReservationDetailInfo';
import { useReservationLocation } from './hooks/useReservationLocation';
import { ReservationDetailStore } from './stores/ReservationDetailStore';

type Props = {
  reservation: ReservationDetailStore['reservation'];
  checkup: ReservationDetailStore['checkup'];
};

export const ReservationDetailPage = observer(() => {
  const store = useContext(ReservationDetailStore.Context);

  return (
    <>
      <DocumentTitle title="予約詳細" />
      <ReservationDetailPageContent reservation={store.reservation} checkup={store.checkup} />
    </>
  );
});

export const ReservationDetailPageContent = observer(({ reservation, checkup }: Props) => {
  const isMapAvailable = useMemo(() => !reservation?.treatmentKind?.online, [reservation?.treatmentKind]);
  const isLate = useMemo(() => (reservation?.date ?? new Date()) < new Date(), [reservation?.date]);
  const location = useReservationLocation({
    clinic: reservation?.clinic,
    treatmentKind: reservation?.treatmentKind?.id || '',
  });

  return (
    <Wrapper>
      <Caption type="header" align="center" underline>
        予約詳細
      </Caption>
      {isLate ? (
        <>
          <Caption type="danger" align="left">
            予約時間が過ぎているため予約の変更・キャンセルはできません。
          </Caption>
        </>
      ) : (
        <></>
      )}
      {reservation && (
        <ReservationDetailInfo
          date={reservation.date}
          duration={reservation.duration}
          clinic={reservation.clinic}
          department={reservation.department}
          treatmentKind={reservation.treatmentKind}
          isCorporateCheckup={
            !reservation.isIndividualCheckup && reservation.department.type === DepartmentType.Checkup
          }
          plan={checkup.plan}
          options={checkup.options}
        />
      )}
      <ReservationDetailActions reservation={reservation} />
      {isMapAvailable && location && <ClinicMap clinic={location} />}
    </Wrapper>
  );
});

export class GoogleCalendarURL {
  constructor(private text: string, dates: [Date, Date], private location: string = '', private href: string) {
    // to UTC
    this.dates = dates;
  }

  private static MOBILE = /webOS|BlackBerry|IEMobile|Opera Mini/i;
  private static IOS = /iPhone|iPad|iPod/i;
  private static ANDROID = /android/i;
  private dates: [Date, Date];

  toString() {
    const sp = GoogleCalendarURL.IOS.test(navigator.userAgent);
    if (sp) {
      return `com.google.calendar://?action=create&text=${this.text}&dates=${this.dates
        .map(d => `${format(d, 'yyyyMMdd')}T${format(d, 'HHmmss')}`)
        .join('/')}&location=${this.location.replace(/\s/g, '+')}&trp=true&trp=undefined&trp=true&sprop=${
        window.location.href
      }&details=予約内容は以下からご確認ください（要LINEログイン）%0D%0A+${this.href}`;
    }
    if (GoogleCalendarURL.ANDROID.test(navigator.userAgent.toLowerCase())) {
      return `https://www.google.com/calendar/render?pli=1&bm=1&action=TEMPLATE&text=${this.text}&dates=${this.dates
        .map(d => `${format(d, 'yyyyMMdd')}T${format(d, 'HHmmss')}`)
        .join('/')}&location=${encodeURIComponent(this.location)}&details=${encodeURIComponent(
        `予約内容は以下からご確認ください（要LINEログイン）${this.href}`,
      )}`;
    }
    const mobile = GoogleCalendarURL.MOBILE.test(navigator.userAgent);
    if (mobile) {
      return `https://www.google.com/calendar/gp?pli=1#~calendar:view=e&bm=1&action=TEMPLATE&text=${
        this.text
      }&dates=${this.dates
        .map(d => `${format(d, 'yyyyMMdd')}T${format(d, 'HHmmss')}`)
        .join('/')}&location=${this.location.replace(/\s/g, '+')}&trp=true&trp=undefined&trp=true&sprop=${
        window.location.href
      }&details=予約内容は以下からご確認ください（要LINEログイン）%0D%0A+${this.href}`;
    }
    return `https://calendar.google.com/calendar/r/eventedit?dates=${this.dates
      .map(d => `${format(convertDateToUTC(d), 'yyyyMMdd')}T${format(convertDateToUTC(d), 'HHmmss')}Z`)
      .join('/')}&trp=false&text=${this.text}&location=${this.location.replace(
      /\s/g,
      '+',
    )}&sf=true&details=予約内容は以下からご確認ください（要LINEログイン）%0D%0A+${this.href}`;
  }
}

const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
