import parse from 'date-fns/parse';
import { action, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../gql/client';
import {
  GetBulkReceiptsDownloadLinkQuery,
  GetBulkReceiptsDownloadLinkQueryVariables,
  ListYearNoShowTreatmentsQuery,
  ListYearNoShowTreatmentsQueryVariables,
  ListYearTreatmentsQuery,
  ListYearTreatmentsQueryVariables,
  Maybe,
  TreatmentHistoryFragment,
} from '../../../../gql/gql-types';
import getBulkReceiptsDownloadLink from '../../../../gql/operations/getBulkReceiptsDownloadLink';
import { listYearNoShowTreatments } from '../../../../gql/operations/listYearNoShowTreatments';
import { listYearTreatments } from '../../../../gql/operations/listYearTreatments';
import { Defaults } from '../../../constants/Defaults';
import { Treatment } from '../../../interfaces/Treatment';
import { safeFilter } from '../../../utils/CollectionUtil';

export class ReceiptListStore {
  public static ALL_TERMS = '全年度';
  public static Context = createContext(new ReceiptListStore());

  constructor() {
    makeAutoObservable(this, {
      list: observable,
      checkedIds: observable,
      nextToken: observable,
      loadSelectedYearTreatments: action,
      year: observable,
      fetchNext: action,
      userId: observable,
      downloadAllReceipts: action,
      setCheckedId: action,
      setAllCheckedIds: action,
      setAllCheckedIdsReset: action,
      email: observable,
      setEmail: action,
      receiptDownloadUrl: observable,
      setReceiptDownloadUrl: action,
    });
  }

  public userId?: string = undefined;
  public list: Treatment[] = [];
  public checkedIds: string[] = [];
  public nextToken?: string = undefined;
  public year = '';
  public email = '';
  public receiptDownloadUrl?: string = '';

  public setInitial(userId: string, list: Treatment[], nextToken?: string) {
    this.userId = userId;
    this.list = list;
    this.checkedIds = [];
    this.nextToken = nextToken;
    this.year = '';
  }

  public setCheckedId(targetId: string) {
    if (this.checkedIds.indexOf(targetId)) {
      this.checkedIds = [...this.checkedIds, targetId];
    } else {
      this.checkedIds = this.checkedIds.filter(id => id !== targetId);
    }
  }

  public setYear(year: string) {
    this.year = year;
  }

  public setAllCheckedIds() {
    this.checkedIds = this.list.map(item => item['id']);
  }

  public setAllCheckedIdsReset() {
    this.checkedIds = [];
  }

  public setEmail(email: string) {
    this.email = email;
  }

  public setReceiptDownloadUrl(url?: string) {
    this.receiptDownloadUrl = url;
  }

  public async loadSelectedYearTreatments(clear?: boolean) {
    if (!this.userId) {
      return;
    }

    if (clear) {
      this.nextToken = undefined;
    }

    if (this.year === ReceiptListStore.ALL_TERMS) {
      this.list = [];
      await this.fetchNext();
      return;
    }

    const next = await GQL.query<ListYearTreatmentsQueryVariables, ListYearTreatmentsQuery>(listYearTreatments, {
      userId: this.userId,
      year: this.year,
      nextToken: this.nextToken,
    });

    if (!next.getUser?.completedTreatment.items) {
      return;
    }

    this.list = this.nextToken
      ? [...this.list, ...ReceiptListStore.convertTreatment(next.getUser.completedTreatment.items)]
      : [...ReceiptListStore.convertTreatment(next.getUser.completedTreatment.items)];
    this.nextToken = next.getUser.completedTreatment.nextToken || undefined;
  }

  public async loadSelectedNoShowYearTreatments() {
    if (!this.userId) {
      return;
    }

    const next = await GQL.query<ListYearNoShowTreatmentsQueryVariables, ListYearNoShowTreatmentsQuery>(
      listYearNoShowTreatments,
      {
        userId: this.userId,
        year: this.year === ReceiptListStore.ALL_TERMS ? undefined : this.year,
      },
    );

    // noShowのうち請求のあるもののみに絞り込む
    const treatments = next.getUser?.noShowTreatment.items?.filter(treatment =>
      treatment?.payment?.job.items?.some(item => !!item?.amount),
    );
    if (!treatments) {
      return;
    }

    this.list = [...ReceiptListStore.convertTreatment(treatments)];
  }

  public async fetchNext() {
    if (!this.userId) {
      return;
    }
    const next = await GQL.query<ListYearTreatmentsQueryVariables, ListYearTreatmentsQuery>(listYearTreatments, {
      userId: this.userId,
      year: this.year === ReceiptListStore.ALL_TERMS ? undefined : this.year,
      nextToken: this.nextToken,
    });

    if (!next.getUser?.completedTreatment.items) {
      return;
    }

    this.list = [...this.list, ...ReceiptListStore.convertTreatment(next.getUser.completedTreatment.items)];
    this.nextToken = next.getUser.completedTreatment.nextToken || undefined;
  }

  public async downloadAllReceipts(ids: string[], name: string, email: string) {
    return GQL.query<GetBulkReceiptsDownloadLinkQueryVariables, GetBulkReceiptsDownloadLinkQuery>(
      getBulkReceiptsDownloadLink,
      { input: { ids, name, email } },
    );
  }

  public static convertTreatment(items: Maybe<TreatmentHistoryFragment>[]): Treatment[] {
    return safeFilter(items).map(
      item =>
        ({
          clinic: { id: '', name: '', ...item.department?.clinic },
          date: new TreatmentDate(item.date, item.time).toDate(),
          department: { id: '', name: '', ...item.department },
          doctor: { id: '', name: '', ...item.feedback?.doctor },
          isNoCharge: !!item.noChargeReason,
          payment: {
            amount: item.payment?.job.items?.sort((a, b) => (b?.createdAt || '').localeCompare(a?.createdAt || ''))?.[0]
              ?.amount,
          },
          attachments: [...safeFilter(item.attachments.items)],
          id: item.id || '',
          soap: {
            doctor: { id: '', name: '', ...item.feedback?.doctor },
            feedback: (() => {
              if (item.status === 'NO_SHOW') {
                return '利用規約に基づき、キャンセル料を請求させていただきます。';
              }
              return item.feedback?.feedback || '受診お疲れ様でした。お大事にしてください。';
            })(),
          },
          duration: item.duration || Defaults.DURATION,
          treatmentKind: { online: item.treatmentKind?.online },
          checkupAppointment: {
            result: item.checkupAppointment?.result,
            plan: { label: item.checkupAppointment?.plan.label || '' },
            organizationName: item.checkupAppointment?.checkupOrganizationMemberEvent?.event?.organization?.name || '',
          },
        } as Treatment),
    );
  }
}

// TODO 層を検討
export class TreatmentDate {
  constructor(private date: string, private time: string) {}
  toDate() {
    return parse(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', new Date());
  }
}
