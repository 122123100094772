import gql from 'graphql-tag';

import { SignedPostType } from '../fragments/signedPost';

export const getInterviewDownloadableUrl = gql`
  query getInterviewDownloadableUrl($input: InterviewDownloadableUrlQueryInput!) {
    getInterviewDownloadableUrl(input: $input) {
      ...SignedPostType
    }
  }
  ${SignedPostType}
`;
