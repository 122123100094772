import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { gutter } from '../../../../../helpers/Style';
import { EmptyCards } from '../../EmptyCards';
import { CheckupResultCard } from '../CheckupResultCard';

type Props = { wide?: boolean; treatments?: ComponentProps<typeof CheckupResultCard>[] };
export const CheckupResultCardLayout = ({ treatments, wide }: Props) => (
  <>
    {treatments && treatments.length > 0 ? (
      <div className={wide ? lineStyle : horizontalScrollStyle}>
        {treatments.map((treatment, i) => (
          <div key={i} className={wide ? wideContainerStyle : containerStyle}>
            <CheckupResultCard
              key={i}
              clinic={treatment.clinic}
              date={treatment.date}
              department={treatment.department}
              id={treatment.id}
              payment={treatment.payment}
              soap={treatment.soap}
              duration={treatment.duration}
              checkupAppointment={treatment.checkupAppointment}
            />
          </div>
        ))}
      </div>
    ) : (
      <EmptyCards>健康診断結果はありません</EmptyCards>
    )}
  </>
);

const fixedWidth = 280;

const wideContainerStyle = css`
  min-width: ${fixedWidth}px;
  cursor: pointer;
`;

const containerStyle = css`
  min-width: ${fixedWidth}px;
  margin-right: ${gutter(4)};
  cursor: pointer;
`;

const horizontalScrollStyle = css`
  display: flex;
  overflow-x: scroll;
`;

const lineStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: ${gutter(5)};
  justify-content: space-around;
`;
