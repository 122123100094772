import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useRouterLog = () => {
  const { listen } = useHistory();
  useEffect(
    () =>
      listen(loc => {
        datadogLogs.logger.debug(`navigated to ${loc.pathname}${loc.search}${loc.hash}`, {
          url: window.location.href,
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
