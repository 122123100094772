import { IconName } from '@clinic-monorepo/clinic-icon';
import { css } from 'emotion';
import React, { MouseEvent } from 'react';

import { BorderRadius, Color, Duration, IconSize } from '../../constants/Style';
import { square, toRGBA } from '../../helpers/Style';
import { Icon } from '../Icon';

type Props = {
  name: IconName;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const IconButton = ({ name, disabled, onClick }: Props) => (
  <button type="button" tabIndex={-1} className={baseStyle} disabled={disabled} onClick={onClick}>
    <Icon name={name} />
  </button>
);

const baseStyle = css`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    background: ${toRGBA(Color.GrayscaleLight, 0.5)};
    border-radius: ${BorderRadius.Circle};
    transition: transform ${Duration.Fade};
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
    ${square(40)}
  }

  > svg {
    z-index: 1;
    ${square(IconSize.Large)}
  }

  &:hover {
    &::before {
      transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
    }

    > svg {
      opacity: 0.7;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;

    > svg {
      fill: ${Color.GrayscaleLight};
    }
  }

  &:active {
    transition: none;
  }
`;
