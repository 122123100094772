import gql from 'graphql-tag';

export const createDosageWithPrescription = gql`
  mutation createDosageWithPrescription($input: CreateDosageWithPrescriptionInput!) {
    createDosageWithPrescription(input: $input) {
      urls {
        url
        fields {
          key
          value
        }
      }
    }
  }
`;
