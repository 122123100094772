import { Auth } from '@aws-amplify/auth';
import { action, makeAutoObservable, observable } from 'mobx';

export class SignInLegacyStore {
  public static KEY = 'clinic-ten-confirm';

  constructor() {
    makeAutoObservable(this, {
      email: observable,
      password: observable,
      setEmail: action,
      setPassword: action,
      signIn: action,
    });
  }

  public email?: string = undefined;
  public password?: string = undefined;

  public setEmail(email: string) {
    this.email = email;
  }

  public setPassword(password: string) {
    this.password = password;
  }

  public async signIn() {
    if (!this.email || !this.password) {
      return false;
    }
    try {
      await Auth.signIn({
        username: this.email,
        password: this.password,
      });
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
