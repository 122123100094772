import React from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Modal } from '../../../../../../components/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  onCommit: () => void;
  disabled?: boolean;
};
export const InterviewDiscardPrompt = ({ open, onClose, onCommit, disabled }: Props) => (
  <Modal visible={open}>
    <Card>
      <CaptionGroup>
        <Caption type="header" align="center">
          予約の取り消し
        </Caption>
        <Caption type="body" align="center">
          予約を取り消し、診療科から選びなおします。よろしいですか？
        </Caption>
      </CaptionGroup>
      <ButtonGroup>
        <Button block onClick={onCommit} disabled={disabled}>
          予約を取り消して診療科選択からやり直す
        </Button>
        <Button ghost block onClick={onClose} disabled={disabled}>
          キャンセル
        </Button>
      </ButtonGroup>
    </Card>
  </Modal>
);
