import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';

import { Button } from '../../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../../components/Caption';
import { Card } from '../../../../../../../components/Card';
import { Checkbox } from '../../../../../../../components/Checkbox';
import { CheckboxGroup, FormItem } from '../../../../../../../components/Form';
import { Icon } from '../../../../../../../components/Icon';
import { Modal } from '../../../../../../../components/Modal';
import { GtmClasses } from '../../../../../../../constants/AnalyticsTags';
import { Color, FontSize } from '../../../../../../../constants/Style';
import { square } from '../../../../../../../helpers/Style';
import { useContext } from '../../../../../../../hooks/useContext';
import { useTransaction } from '../../../../../../../hooks/useTransaction';
import { LastWarningContent } from '../../../../../../Reservations/components/LastWarningContent';
import { CheckupReservationStore } from '../../../stores/CheckupReservationStore';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const extraLargeStyle = css`
  font-size: ${FontSize.ExtraLarge};
  font-weight: 700;
`;

type Props = {
  open: boolean;
  handleStateChange: (newState: boolean) => void;
  onConfirm: () => void;
  disabled?: boolean;
};

export const CheckupReservationConfirmDialog = observer(({ open, handleStateChange, onConfirm, disabled }: Props) => {
  const store = useContext(CheckupReservationStore.Context);
  const [cancelPolicyConcent, setCancelPolicyConcent] = useState(false);

  if (store.details.length <= 0) {
    return (
      <Modal visible={open}>
        <Card>
          <Caption type="header">日付が選択されていません。</Caption>
          <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
            キャンセル
          </Button>
        </Card>
      </Modal>
    );
  }

  const { cancelPolicyInfo, isJustBeforeAction, visibleLastCaution } = store.cancelPolicy;
  const isJustBeforeReserve = isJustBeforeAction(store.details[0].date);

  return (
    <>
      <Modal visible={open}>
        <Card width={375}>
          {!visibleLastCaution && (
            <div className={centerStyle}>
              <ConfirmSection hint={<></>} />
              {cancelPolicyInfo && (
                <div className={cancelPolicySectionStyle}>
                  <CancelPolicyCard />
                  <>
                    <Checkbox
                      label="上記キャンセルポリシーに同意する"
                      checked={cancelPolicyConcent}
                      onChange={v => setCancelPolicyConcent(v.target.checked)}
                    />
                  </>
                </div>
              )}
              <AuthenticatedAction
                onConfirm={isJustBeforeReserve ? () => store.cancelPolicy.setVisibleLastCaution(true) : onConfirm}
                handleStateChange={handleStateChange}
                disabled={disabled || (!!cancelPolicyInfo && !cancelPolicyConcent)}
                isJustBeforeReserve={isJustBeforeReserve}
              />
            </div>
          )}
          {visibleLastCaution && (
            <LastWarningContent
              store={store.cancelPolicy}
              disabled={disabled}
              onConfirm={onConfirm}
              onCancel={() => handleStateChange(false)}
              confirmAnalyticsClassName={`${GtmClasses.gtm.conversion.checkup} ${store.department?.id} ${store.treatmentKind.id}`}
            />
          )}
        </Card>
      </Modal>
    </>
  );
});

type ConfirmSectionProps = {
  hint?: ReactNode;
};

type ActionProps = Pick<Props, 'onConfirm' | 'handleStateChange' | 'disabled'> & { isJustBeforeReserve: boolean };

const AuthenticatedAction = observer(({ onConfirm, handleStateChange, disabled, isJustBeforeReserve }: ActionProps) => {
  const store = useContext(CheckupReservationStore.Context);
  const [loadConfirm, loadConfirmStatus] = useTransaction(() => store.fetchShouldConfirm());
  const [concent, setConcent] = useState(false);

  useEffect(() => {
    loadConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.department]);

  return (
    <ButtonGroup>
      {!loadConfirmStatus.running && store.shouldConfirm ? (
        <FormItem>
          <CheckboxGroup>
            <Checkbox
              label={`${store.department?.clinicName}およびDENTAL CLINIC TEN SHIBUYAへの情報提供に同意する`}
              checked={concent}
              onChange={v => setConcent(v.target.checked)}
            />
            <div>
              <a
                style={{ textDecoration: 'underline' }}
                href={store.department?.termsUrl || '/'}
                target="_blank"
                rel="noreferrer"
              >
                {store.department?.clinicName}の利用規約を確認(別タブが開きます)
              </a>
            </div>
            <div>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://dental.clinicten.jp/term"
                target="_blank"
                rel="noreferrer"
              >
                DENTAL CLINIC TEN SHIBUYAの利用規約を確認(別タブが開きます)
              </a>
            </div>
          </CheckboxGroup>
        </FormItem>
      ) : (
        <></>
      )}
      <Button
        disabled={loadConfirmStatus.running || (store.shouldConfirm && !concent) || disabled}
        type="button"
        onClick={() => onConfirm()}
        className={
          isJustBeforeReserve
            ? ''
            : `${GtmClasses.gtm.conversion.checkup} ${store.department?.id} ${store.treatmentKind.id}`
        }
      >
        {isJustBeforeReserve ? '予約する' : '予約を完了する'}
      </Button>
      <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
        キャンセル
      </Button>
    </ButtonGroup>
  );
});

const ConfirmSection = observer(({ hint }: ConfirmSectionProps) => {
  const store = useContext(CheckupReservationStore.Context);
  if (store.details.length === 0) {
    throw new Error('unreachable code');
  }
  return (
    <CaptionGroup>
      <Caption type="subheader">以下の内容で予約しますか？</Caption>
      <hr />
      <Caption type="subheader">
        {format(store.details[0].date, 'yyyy年MM月dd日')}
        {WeekLabels[getDay(store.details[0].date)]}
      </Caption>
      <div className={extraLargeStyle}>
        {format(store.details[0].date, 'HH:mm')}
        <span className={smallTimeTildeStyle}> ～ </span>
        <span className={smallTimeStyle}>
          [所要{store.displayDuration}
          分]
        </span>
      </div>
      <div>CLINIC TEN SHIBUYA / {store.department?.name}</div>
      <Caption type="small">{hint}</Caption>
    </CaptionGroup>
  );
});

const smallTimeStyle = css`
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const CancelPolicyCard = observer(() => {
  const store = useContext(CheckupReservationStore.Context);
  if (!store.cancelPolicy.cancelPolicyInfo) {
    return null;
  }

  const { greetingText, timeToBan, cancelFee, cancelableTimeLimitText } = store.cancelPolicy.cancelPolicyInfo;

  return (
    <div className={cancelPolicyCardStyle}>
      <Card color="redNeutral">
        <div className={cancelPolicyIconStyle}>
          <Icon name="exclamation-triangle" />
        </div>
        {greetingText}
        <span className={cancelPolicyRedStyle}>{`キャンセル・予約変更は${cancelableTimeLimitText}まで`}</span>
        にお願いします。
        {cancelFee.map(({ differenceText, feeAmount }) => {
          if (!differenceText || !feeAmount) return <></>;
          return (
            <>
              {`自費診療は予約時間${differenceText}からキャンセル料${feeAmount}円が発生します。`}
              <br />
            </>
          );
        })}
        {`${timeToBan.maxBanCount}回以上の直前/当日予約変更はアカウント停止となりますのでご注意ください。`}
      </Card>
    </div>
  );
});

const cancelPolicySectionStyle = css`
  margin-bottom: 16px;
  font-weight: 300;
  text-align: left;
`;

const cancelPolicyCardStyle = css`
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 4px;
`;

const cancelPolicyIconStyle = css`
  text-align: center;

  > svg {
    ${square(32)}

    fill: ${Color.FunctionalRedNeutral};
  }
`;

const cancelPolicyRedStyle = css`
  font-weight: 600;
  color: ${Color.FunctionalRedNeutral};
`;
