import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '../../../constants/Routes';
import { useReservationId } from '../../../hooks/useReservationId';
import { ReservationDetailPage } from '../../../pages/Reservations/ReservationDetail';
import { ReservationActionsPage } from '../../../pages/Reservations/ReservationDetail/ReservationActions';
import { ReservationDetailStore } from '../../../pages/Reservations/ReservationDetail/stores/ReservationDetailStore';
import { ReservationEditApp } from './ReservationEdit';

/**
 * 予約一件を管理するアプリケーション。
 * ReservationDetailStoreで情報を管理し、コンテキストとして提供する。
 * uuidが変更されたまたはReservationDetailStore.needsRefreshがtrueになったときに、ロードする。
 */
export const ReservationDetailApp = () => {
  const detailStore = useMemo(() => new ReservationDetailStore(), []);
  const { reservationId } = useReservationId();

  useEffect(() => {
    detailStore.fetchReservation(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId, detailStore.needsRefresh]);

  return (
    <Switch>
      <ReservationDetailStore.Context.Provider value={detailStore}>
        <Route path={Routes.Paths.ReservationShow} component={ReservationDetailPage} exact />
        <Route path={Routes.Paths.ReservationActions} component={ReservationActionsPage} exact />
        <Route path={Routes.Paths.ReservationEdit} component={ReservationEditApp} />
      </ReservationDetailStore.Context.Provider>
    </Switch>
  );
};
