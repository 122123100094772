import { css } from 'emotion';
import React from 'react';

import { Color } from '../../../../constants/Style';
import { gutter } from '../../../../helpers/Style';

export const BottomBorder = () => <div className={bottomBorderStyle} />;

const bottomBorderStyle = css`
  margin: ${gutter(1.5)} 0;
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;
