import { css } from 'emotion';
import React, { useState } from 'react';

import { Button } from '../Button';

type Props = {
  onChange?: (c: FileList | null) => void;
};
// export const Upload = ({ value, onChange }: Props) => {
export const Upload = ({ onChange }: Props) => {
  const [file, setFile] = useState('');
  return (
    <>
      <div className={style}>
        <div>
          <Button ghost>
            <label htmlFor="upload">ファイルを選択する</label>
          </Button>
        </div>
        <div style={{ marginLeft: '8px' }}>{file}</div>
      </div>
      <input
        className={hiddenStyle}
        type="file"
        id="upload"
        onChange={v => {
          setFile(v.target.files?.item(0)?.name || '');
          v.target.files && onChange?.(v.target.files);
        }}
      />
    </>
  );
};

const style = css`
  display: flex;
  align-items: center;
`;

const hiddenStyle = css`
  display: none;
`;
