import parse from 'date-fns/parse';
import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../gql/client';
import {
  AttachmentType,
  DownloadAllReceiptsQuery,
  DownloadAllReceiptsQueryVariables,
  GetDownloadableLinkQuery,
  GetDownloadableLinkQueryVariables,
  ListTreatmentsQuery,
  ListTreatmentsQueryVariables,
  Maybe,
  TreatmentHistoryFragment,
} from '../../../../../gql/gql-types';
import downloadAllReceipts from '../../../../../gql/operations/downloadAllReceipts';
import getDownloadableLink from '../../../../../gql/operations/getDownloadableLink';
import { listTreatments } from '../../../../../gql/operations/listTreatments';
import { Defaults } from '../../../../constants/Defaults';
import { Treatment } from '../../../../interfaces/Treatment';
import { safeFilter } from '../../../../utils/CollectionUtil';
import { latestPaymentState } from '../../utils/latestPaymentState';

export class TreatmentListStore {
  constructor() {
    makeAutoObservable(this, {
      list: observable,
      nextToken: observable,
      fetchNext: action,
      userId: observable,
      downloadAllReceipts: action,
    });
  }

  public userId?: string = undefined;
  public list: Treatment[] = [];
  public nextToken?: string = undefined;

  public setInitial(userId: string, list: Treatment[], nextToken?: string) {
    this.userId = userId;
    this.list = list;
    this.nextToken = nextToken;
  }

  public async fetchNext() {
    if (!this.userId) {
      return;
    }
    const next = await GQL.query<ListTreatmentsQueryVariables, ListTreatmentsQuery>(listTreatments, {
      userId: this.userId,
      nextToken: this.nextToken,
    });

    if (!next.getUser?.completedTreatment.items) {
      return;
    }

    this.list = [...this.list, ...TreatmentListStore.convertTreatment(next.getUser.completedTreatment.items)];
    this.nextToken = next.getUser.completedTreatment.nextToken || undefined;
  }

  public async fetchDownloadableLink(id: string) {
    const res = await GQL.query<GetDownloadableLinkQueryVariables, GetDownloadableLinkQuery>(getDownloadableLink, {
      input: { id },
    });
    return res.getDownloadableLink?.url;
  }

  public async downloadAllReceipts() {
    const res = await GQL.query<DownloadAllReceiptsQueryVariables, DownloadAllReceiptsQuery>(downloadAllReceipts, {});
    return res.downloadAllReceipts?.url;
  }

  public static convertTreatment(items: Maybe<TreatmentHistoryFragment>[]): Treatment[] {
    return safeFilter(items).map(item => ({
      clinic: { id: '', name: '', ...item.department?.clinic },
      date: new TreatmentDate(item.date, item.time).toDate(),
      department: { id: '', name: '', ...item.department },
      doctor: { id: '', name: '', ...item.feedback?.doctor },
      isNoCharge: !!item.noChargeReason,
      payment: {
        amount: latestPaymentState(item.payment as any)?.amount,
      },
      attachments: [
        ...safeFilter(
          item.attachments.items?.map(item => ({
            id: item?.id || '',
            name: item?.name || '',
            type: item?.type || AttachmentType.Free,
          })),
        ),
      ],
      id: item.id || '',
      soap: {
        doctor: { id: '', name: '', ...item.feedback?.doctor },
        feedback: (() => {
          if (item.status === 'NO_SHOW') {
            return '利用規約に基づき、キャンセル料を請求させていただきます。';
          }
          return item.feedback?.feedback || '受診お疲れ様でした。お大事にしてください。';
        })(),
      },
      duration: item.duration || Defaults.DURATION,
      treatmentKind: { online: item.treatmentKind?.online },
      checkupAppointment: {
        result: item.checkupAppointment?.result,
        plan: { label: item.checkupAppointment?.plan.label || '' },
      },
      time: '',
    }));
  }

  public static convertCheckupResultTreatment(
    items?: Maybe<Maybe<TreatmentHistoryFragment>[]>,
  ): TreatmentHistoryFragment[] {
    return safeFilter(items?.filter(i => i?.checkupAppointment?.result)).map(item => ({
      ...item,
      clinic: { id: '', name: '', ...item.department?.clinic },
      department: { id: '', name: '', ...item.department },
      doctor: { id: '', name: '', ...item.feedback?.doctor },
      payment: {
        amount: latestPaymentState(item.payment as any)?.amount,
        job: {
          items: undefined,
        },
      },
      id: item.id || '',
      soap: {
        doctor: { id: '', name: '', ...item.feedback?.doctor },
        feedback: item.feedback?.feedback || '受診お疲れ様でした。お大事にしてください。',
      },
      duration: item.duration || Defaults.DURATION,
      treatmentKind: { online: item.treatmentKind?.online },
      checkupAppointment: {
        result: item.checkupAppointment?.result,
        plan: { label: item.checkupAppointment?.plan.label || '' },
        organizationName: item.checkupAppointment?.checkupOrganizationMemberEvent?.event?.organization?.name || '',
      },
    }));
  }
}

// TODO 層を検討
export class TreatmentDate {
  constructor(private date: string, private time: string) {}
  toDate() {
    return parse(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', new Date());
  }
}
