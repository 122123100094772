import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Modal } from '../../../../../../components/Modal';
import { ReservationStore } from '../../../stores/ReservationStore';

type Props = {
  store: ReservationStore;
};
export const VaccineWarningDialog = observer(({ store }: Props) => {
  const [vaccineWarning, setVaccineWarning] = useVaccineWarning(store);
  return (
    <Modal visible={vaccineWarning}>
      <Card>
        {/** FIXME COVID only コロナウイルスワクチン専用  */}
        <CaptionGroup>
          <Caption type="header">予約時の注意点</Caption>
          <Caption type="danger">
            <h3>①1回目接種と2回目接種（4週間後の同じ曜日）の両日を必ず予約して下さい。</h3>
            <br />
            2回目の予約が完了するまでは予約は未完了です。
            <br />
            1回目終了後に画面を閉じたりしないようご注意ください。
            <br />
            <h3>② 一度予約すると、変更/キャンセルはできません。</h3> <br />
            1回目予約前に、1回目・2回目（4週間後の同じ曜日＝28日後）の両日予定が合うことをご確認下さい。
            <br />
            1回目も2回目も一度予約したら変更もキャンセルもできませんのでご注意下さい。
            <br />
          </Caption>
        </CaptionGroup>
        <Button block onClick={() => setVaccineWarning(false)}>
          OK
        </Button>
      </Card>
    </Modal>
  );
});

export const useVaccineWarning = (store: ReservationStore) => {
  const isCovidVaccine = !!store.vaccineEventPatientId && store.treatmentKind?.id === 'COVID1'; // FIXME FIXME! COVID only
  return useState(isCovidVaccine); // FIXME FIXME!!! COVID only
};
