import { addMinutes, parse } from 'date-fns/esm';
import format from 'date-fns/format';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../../gql/client';
import {
  AnswerValueInput,
  CancelPoliciesTypeFragment,
  CheckupMemberEventTypeFragment,
  ClinicType,
  CreateCheckupReservationForOrganizationMutation,
  CreateCheckupReservationForOrganizationMutationVariables,
  FixCheckupAppointmentOrganizationMutation,
  FixCheckupAppointmentOrganizationMutationVariables,
  GetCheckupInterviewQuery,
  GetCheckupInterviewQueryVariables,
  GetCheckupOrganizationMemberEventByTreatmentIdQuery,
  GetCheckupOrganizationMemberEventByTreatmentIdQueryVariables,
  GetCheckupOrganizationMemberEventQuery,
  GetCheckupOrganizationMemberEventQueryVariables,
  GetCheckupTreatmentTemporallyQuery,
  GetCheckupTreatmentTemporallyQueryVariables,
  InterviewTypeFragment,
  ListCheckupOptionSuggestionQuery,
  ListCheckupOptionSuggestionQueryVariables,
  ListIndividualCheckupMastersQuery,
  ListIndividualCheckupMastersQueryVariables,
  Maybe,
  Sex,
  TreatmentStatus,
  UpdateCheckupAppointmentOrganizationMutation,
  UpdateCheckupAppointmentOrganizationMutationVariables,
} from '../../../../../../gql/gql-types';
import { createCheckupReservationForOrganization } from '../../../../../../gql/operations/createCheckupReservationForOrganization';
import { fixCheckupAppointmentOrganization } from '../../../../../../gql/operations/fixCheckupAppointmentOrganization';
import { getCheckupInterview } from '../../../../../../gql/operations/getCheckupInterview';
import { getCheckupOrganizationMemberEvent } from '../../../../../../gql/operations/getCheckupOrganizationMemberEvent';
import { getCheckupOrganizationMemberEventByTreatmentId } from '../../../../../../gql/operations/getCheckupOrganizationMemberEventByTreatmentId';
import { getCheckupTreatmentTemporally } from '../../../../../../gql/operations/getCheckupTreatmentTemporally';
import { listCheckupSuggestions } from '../../../../../../gql/operations/listCheckupSuggestions';
import { listIndividualCheckupMasters } from '../../../../../../gql/operations/listIndividualCheckupMasters';
import { updateCheckupAppointmentOrganization } from '../../../../../../gql/operations/updateCheckupAppointmentOrganization';
import { Defaults } from '../../../../../../src/constants/Defaults';
import { Department } from '../../../../../domains/reservation/Department';
import { CancelPolicyStore } from '../../../../../stores/CancelPolicyStore';
import { safeFilter } from '../../../../../utils/CollectionUtil';
import { approximate } from '../../../../../utils/NumberUtil';
// 基本コースの支払先のタイプ
type PlanBillType =
  | '1_organization' // 団体持ち
  | '2_own'; // 個人持ち

// オプションの請求先のタイプ
type OptionBillType =
  | '1_organization' // 団体持ち
  | '2_own'; // 個人持ち

// キット送付タイプ
type KitDeliverType =
  | '1_organization' // 団体（企業）の住所へ一括送付
  | '2_own_on_flexible' // 準備ができ次第、それぞれの住所へ送付
  | '3_own_on_reserved'; // 予約完了後、それぞれの住所へ送付
type Mode = 'new' | 'edit';

export type CheckupOrganization = {
  id: string;
  name: string;
  planBillType: PlanBillType;
  kitDeliverType: KitDeliverType;
};

export type CheckupOrganizationMemberEvent = {
  id: string;
  kana: string;
  name: string;
  birthDate: Date;
  kitDeliveryAddress: string;
  course: CheckupCourse;
};

export type CheckupCourse = {
  id: string;
  name: string;
  treatmentKindId?: string;
  options: string[];
  cancelPolicies?: CancelPoliciesTypeFragment['cancelPolicies'];
};

export type CheckupOption = {
  id: string;
  name: string;
  price: number;
  regularPrice: number;
  suggestText?: string;
  billType: OptionBillType;
  clinicType: ClinicType;
  treatmentKind?: TreatmentKind;
  requiredMin: number;
  description?: string;
  suggestSeverity?: string;

  subTitle?: Maybe<string>;
  warning?: Maybe<string>;
};

type TreatmentKind = {
  id: string;
  name: string;
};

export type CheckupReservationDetail = {
  date: Date;
  department: Department;
  duration: number;
  laneId: string;
};

export class CheckupReservationStore {
  public static Context = createContext<CheckupReservationStore | null>(null);

  constructor() {
    makeAutoObservable(this, {
      checkupOrganization: observable,
      checkupOrganizationMember: observable,
      receiveStatus: observable,
      fetchCheckupInfo: action,
      setReceiveStatus: action,
      memberId: observable,
      setAnswer: action,
      preInterviewValid: computed,
      fetchPreInterview: action,
      preInterview: observable,
      name: observable,
      setName: action,
      birthDate: observable,
      setBirthDate: action,
      kitDeliveryAddress: observable,
      setKitDeliveryAddress: action,
      course: observable,
      setCourse: action,
      options: observable,
      addOption: action,
      removeOption: action,
      basicInfoValid: computed,
      selectableCourses: observable,
      selectableOptions: computed,
      selectableOptions_: observable,
      department: observable,
      details: observable,
      setDetails: action,
      fetchShouldConfirm: action,
      shouldConfirm: observable,
      fetchSuggestions: action,
      setCheckupInfo: action,
      fetchCheckupTreatmentInfo: action,
      mode: observable,
      setMode: action,
      store: action,
      flush: action,
      copyFromStorage: action,
      appointmentId: observable,
      treatmentKind: computed,
      temporallyDate: observable,
      displayDuration: computed,
    });
    this.copyFromStorage(JSON.parse(window.sessionStorage.getItem('clinicten.checkup') || '{}'));
  }

  public memberId?: string = undefined;
  public checkupOrganization?: CheckupOrganization = undefined;
  public checkupOrganizationMember?: CheckupOrganizationMemberEvent = undefined;
  public receiveStatus = true;

  public preInterview?: InterviewTypeFragment = undefined;

  public name?: string = undefined;
  public birthDate?: Date = undefined;
  public kitDeliveryAddress?: string = undefined;
  public course?: CheckupCourse = undefined;
  public options: CheckupOption[] = [];

  public selectableCourses: CheckupCourse[] = [];
  public selectableOptions_: CheckupOption[] = [];

  public get selectableOptions() {
    return this.selectableOptions_.filter(x => !this.course?.options.includes(x.id));
  }

  public details: CheckupReservationDetail[] = [];
  public department?: Department = undefined;
  public shouldConfirm = false;

  public mode: Mode = 'new';

  public appointmentId?: string = undefined;

  public temporallyDate?: Date = undefined;

  public union?: string = undefined;

  public cancelPolicy: CancelPolicyStore = new CancelPolicyStore();

  public store() {
    window.sessionStorage.setItem(
      'clinicten.checkup',
      JSON.stringify({
        memberId: this.memberId,
        preInterview: this.preInterview,
        options: this.options,
        birthDate: this.birthDate && format(this.birthDate, 'yyyy-MM-dd'),
      }),
    );
  }

  public flush() {
    window.sessionStorage.removeItem('clinicten.checkup');
  }

  public copyFromStorage(state: any) {
    if (state.preInterview) {
      this.preInterview = state.preInterview;
      this.fetchSuggestions();
    }
    if (state.options) {
      this.options = state.options;
    }
    if (state.birthDate) {
      this.birthDate = parse(state.birthDate, 'yyyy-MM-dd', new Date());
    }
  }

  public setReceiveStatus(r: boolean) {
    this.receiveStatus = r;
  }

  public setAnswer(key: number, answer: AnswerValueInput[]) {
    if (!this.preInterview) {
      return;
    }

    const origin = this.preInterview.questions || [];
    this.preInterview.questions = [
      ...origin.slice(0, key),
      {
        ...origin[key],
        answer: answer.map(a => ({
          answer: a.value,
          option: {
            id: a.id,
            label: a.label,
            type: origin[key]?.options?.find(o => o?.id === a.id)?.type,
            value: origin[key]?.options?.find(o => o?.id === a.id)?.value,
          },
        })),
      },
      ...origin.slice(key + 1),
    ];

    this.birthDate = new Date(
      this.preInterview.questions.find(q => q?.options?.some(o => o?.type === 'date'))?.answer?.[0]?.answer || '',
    );

    this.fetchSuggestions();
    this.store();
  }

  public get preInterviewValid() {
    return (
      this.preInterview &&
      this.preInterview.questions?.filter(q => q?.required).every(q => q?.answer && q.answer.length > 0)
    );
  }

  public setName(n: string) {
    this.name = n;
  }

  public setBirthDate(bd: Date) {
    this.birthDate = bd;
  }

  public setKitDeliveryAddress(a: string) {
    this.kitDeliveryAddress = a;
  }

  public setCourse(c: CheckupCourse) {
    this.course = c;
  }

  public addOption(o: CheckupOption) {
    this.options = [...this.options, o];
  }

  public removeOption(o: CheckupOption) {
    this.options = this.options.slice(0).filter(op => op.id !== o.id);
  }

  public get basicInfoValid() {
    return (
      this.course &&
      this.name &&
      this.birthDate &&
      (this.checkupOrganization?.kitDeliverType === '1_organization' || this.kitDeliveryAddress)
    );
  }

  public setDetails(ds: CheckupReservationDetail[]) {
    this.details = ds;
  }

  public setMode(mode: Mode) {
    this.mode = mode;
  }

  // api calls

  public setCheckupInfo(fragment?: CheckupMemberEventTypeFragment) {
    if (!fragment) {
      return;
    }

    const plan = fragment.plan || fragment.event?.plan;
    this.checkupOrganization = {
      id: fragment.event?.organization?.id || '',
      name: fragment.event?.organization?.name || '',
      kitDeliverType: (fragment.kitDeliverType || fragment.event?.kitDeliverType || '1_organization') as KitDeliverType,
      planBillType: (fragment.planBillType || fragment.event?.planBillType || '1_organization') as PlanBillType,
    };
    const checkupOrganizationMember = {
      id: fragment.id,
      name: fragment.name,
      birthDate: new Date(),
      kana: '_',
      kitDeliveryAddress: fragment.address,
      course: {
        id: plan?.id || '',
        name: plan?.label || '',
        treatmentKindId: plan?.treatmentKindId || '',
        options: safeFilter(plan?.options),
      },
    };
    if (plan?.treatmentKind?.cancelPolicies) {
      this.cancelPolicy.setCancelPolicies(plan.treatmentKind.cancelPolicies);
    }

    this.checkupOrganizationMember = checkupOrganizationMember;

    this.course = checkupOrganizationMember.course;
    this.name = checkupOrganizationMember.name;
    this.birthDate = checkupOrganizationMember.birthDate;
    this.kitDeliveryAddress = checkupOrganizationMember.kitDeliveryAddress;

    this.department = {
      baseDuration: 15,
      clinic: '01',
      icon: 'men',
      id: 'C',
      name: '健診',
      clinicName: 'CLINIC TEN SHIBUYA',
      termsUrl: 'https://clinicten.jp/term',
    };
  }

  async fetchCheckupInfo(memberId: string) {
    this.memberId = memberId;
    const checkupEvent = await GQL.query<
      GetCheckupOrganizationMemberEventQueryVariables,
      GetCheckupOrganizationMemberEventQuery
    >(getCheckupOrganizationMemberEvent, {
      id: memberId,
    });

    if (!checkupEvent.getCheckupOrganizationMemberEvent) {
      throw new Error('不正なURLからの遷移です。企業から送付されたURLから入り直してください。');
    }

    this.setCheckupInfo(checkupEvent.getCheckupOrganizationMemberEvent);
    this.selectableCourses = safeFilter(checkupEvent.listCheckupPlans?.items).map(item => ({
      id: item.id,
      name: item.label,
      treatmentKindId: item.treatmentKindId || '',
      options: safeFilter(item.options),
      cancelPolicies: item.treatmentKind?.cancelPolicies,
    }));

    this.expenseOptions = safeFilter(
      checkupEvent.getCheckupOrganizationMemberEvent.expenseOptions ||
        checkupEvent.getCheckupOrganizationMemberEvent.event?.expenseOptions,
    );
    this.discountCode = checkupEvent.getCheckupOrganizationMemberEvent.event?.discountCode || '';
    this.union = checkupEvent.getCheckupOrganizationMemberEvent.event?.union || '';

    await this.fetchOptions();

    if (
      (checkupEvent.getCheckupOrganizationMemberEvent.appointments.items?.flatMap(appointment =>
        appointment?.treatment.items?.filter(
          item =>
            item?.status !== TreatmentStatus.Canceled &&
            item?.status !== TreatmentStatus.NoShow &&
            item?.status !== TreatmentStatus.Temporally,
        ),
      ).length || 0) > 0
    ) {
      throw new Error(
        '既に診察予約が登録済みの健診です。日時やオプションを変更したい場合は予約確認・変更から変更してください。',
      );
    }
  }

  private expenseOptions: string[] = [];
  private discountCode = '';

  get interviewBirthDate() {
    return this.preInterview?.questions
      ?.find(q => q?.additional_column_name === 'birthDate')
      ?.answer?.find(x => x?.answer)?.answer;
  }

  get sex() {
    return this.preInterview?.questions?.find(q => q?.additional_column_name === 'sex')?.answer?.find(x => x?.answer)
      ?.answer as Maybe<Sex>;
  }

  async fetchOptions() {
    const discount = await GQL.query<ListIndividualCheckupMastersQueryVariables, ListIndividualCheckupMastersQuery>(
      listIndividualCheckupMasters,
      {
        discountCode: this.discountCode,
        union: this.union,
        birthDate: this.interviewBirthDate,
        sex: this.sex,
      },
    );

    this.selectableOptions_ = safeFilter(discount.listCheckupOptionIndividuals?.items)
      .sort((a, b) => (a.option.order || 0) - (b.option.order || 0))
      .map(o => ({
        ...this.selectableOptions_.find(selectableOption => selectableOption.id === o.option.id),
        id: o.option.id || '',
        name: o.option.label || '',
        regularPrice: o.option.regularPrice || 0,
        price:
          discount.getDiscountCheckupIndividualMasters?.options.find(od => od.optionId === o.option.id)?.price || 0,
        billType: this.expenseOptions.includes(o.option.id) ? '1_organization' : '2_own',
        clinicType: o.option.clinicType || ClinicType.Checkup,
        treatmentKind: o.option.treatmentKind || undefined,
        requiredMin: o.option.requiredMin || 0,
      }));
  }

  async fetchCheckupTreatmentInfo(treatmentId: string) {
    const treatment = await GQL.query<
      GetCheckupOrganizationMemberEventByTreatmentIdQueryVariables,
      GetCheckupOrganizationMemberEventByTreatmentIdQuery
    >(getCheckupOrganizationMemberEventByTreatmentId, {
      id: treatmentId,
    });
    this.setCheckupInfo(treatment.getTreatment?.checkupAppointment?.checkupOrganizationMemberEvent || undefined);
    this.preInterview = treatment.getTreatment?.preInterview || undefined;
    this.options = this.selectableOptions_.filter(o => treatment.getTreatment?.options?.includes(o.id));
    this.memberId = treatment.getTreatment?.checkupAppointment?.checkupOrganizationMemberEvent?.id;
    this.appointmentId = treatment.getTreatment?.checkupAppointment?.id;

    const member = treatment.getTreatment?.checkupAppointment?.checkupOrganizationMemberEvent;
    this.expenseOptions = safeFilter(member?.expenseOptions || member?.event?.expenseOptions);
    this.discountCode = member?.event?.discountCode || '';
    this.union = member?.event?.union || '';
    await this.fetchOptions();

    if (this.preInterview?.questions) {
      this.birthDate = new Date(
        this.preInterview.questions.find(q => q?.options?.some(o => o?.type === 'date'))?.answer?.[0]?.answer || '',
      );
    }

    this.store();
  }

  async fetchPreInterview() {
    if (!this.memberId) {
      throw new Error('不正なURLからの遷移です。企業から送付されたURLから入り直してください。');
    }

    this.preInterview =
      (
        await GQL.query<GetCheckupInterviewQueryVariables, GetCheckupInterviewQuery>(getCheckupInterview, {
          input: {
            timing: '1_BEFORE_RESERVING',
            plan: this.course?.id || '',
            options: [],
          },
        })
      ).getCheckupInterview || undefined;
  }

  public async fetchSuggestions() {
    const { listCheckupOptionSuggestion } = await GQL.query<
      ListCheckupOptionSuggestionQueryVariables,
      ListCheckupOptionSuggestionQuery
    >(listCheckupSuggestions, {
      input: safeFilter(this.preInterview?.questions)
        .map(q => safeFilter(q.answer))
        .reduce((res, elem) => [...res, ...elem], [])
        .map(ans => ({
          id: Number(ans.option?.id || 0),
          label: ans.option?.value || '',
          value: ans.answer || '',
        })),
    });

    this.selectableOptions_ = this.selectableOptions_.map(option => ({
      ...option,
      suggestText: listCheckupOptionSuggestion?.find(s => s?.optionId === option.id)?.text,
      suggestSeverity: listCheckupOptionSuggestion?.find(s => s?.optionId === option.id)?.severity || undefined,
    }));
  }

  async fetchShouldConfirm() {
    this.shouldConfirm = this.mode === 'new';
  }

  async postTreatment() {
    if (!this.department || this.details.length === 0 || !this.memberId || !this.name || !this.preInterview) {
      throw new Error('必須項目が入力されていません。');
    }

    const res = await GQL.query<
      CreateCheckupReservationForOrganizationMutationVariables,
      CreateCheckupReservationForOrganizationMutation
    >(createCheckupReservationForOrganization, {
      input: {
        checkupTreatment: {
          clinic: this.department.clinic,
          date: format(this.details[0].date, 'yyyy-MM-dd'),
          time: format(this.details[0].date, 'HH:mm'),
          department: this.department.id,
          duration: this.details[0].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[0].date, 'yyyyMM'),
          checkupOrganizationMemberEventId: this.memberId,
          treatmentKind: this.course?.treatmentKindId || this.department.id,
          options: this.options.map(o => o.id),
          displayDuration: this.displayDuration,
          laneId: this.details[0]?.laneId,
          cancelPolicyRevision: this.cancelPolicy.cancelPolicyInfo?.cancelPolicyRevision,
        },
        dentalTreatment: this.details[1]
          ? {
              clinic: '02', // FIXME
              date: format(this.details[1].date, 'yyyy-MM-dd'),
              time: format(this.details[1].date, 'HH:mm'),
              department: 'D',
              duration: this.details[1].duration,
              physician: '10001', // FIXME
              status: TreatmentStatus.Fixed,
              yearMonth: format(this.details[1].date, 'yyyyMM'),
              checkupOrganizationMemberEventId: this.memberId,
              treatmentKind: this.getDentalTreatmentKind()?.id || 'DC1',
              options: this.options.map(o => o.id),
              hidden: true,
              laneId: this.details[1]?.laneId,
              cancelPolicyRevision: this.cancelPolicy.cancelPolicyInfo?.cancelPolicyRevision,
            }
          : null,
        appointment: {
          address: this.kitDeliveryAddress || '',
          birthDate: '', // todo
          optionIds: this.options.map(o => o.id),
          checkupOrganizationMemberEventId: this.memberId,
        },
        interview: {
          ...this.preInterview,
          finished: true,
          submitted: true,
          questions: safeFilter(this.preInterview.questions).map(q => ({
            ...q,
            answer: safeFilter(q.answer),
            id: String(q.id || 0),
            options: safeFilter(q.options),
          })),
        },
      },
    });
    this.flush();
    return res.reserveCheckupAppointmentOrganization?.id;
  }

  get displayDuration() {
    const end = this.details
      .map(x => addMinutes(x.date, approximate(x.duration, 15)))
      .sort((a, b) => b.getTime() - a.getTime())[0];
    const start = this.details.map(x => x.date).sort((a, b) => a.getTime() - b.getTime())[0];
    return (end.getTime() - start.getTime()) / 60_000 + Defaults.INTERVAL;
  }

  async updateTreatment() {
    if (!this.department || this.details.length === 0 || !this.memberId || !this.name || !this.preInterview) {
      throw new Error('必須項目が入力されていません。');
    }
    const res = await GQL.query<
      UpdateCheckupAppointmentOrganizationMutationVariables,
      UpdateCheckupAppointmentOrganizationMutation
    >(updateCheckupAppointmentOrganization, {
      input: {
        checkupTreatment: {
          clinic: this.department.clinic,
          date: format(this.details[0].date, 'yyyy-MM-dd'),
          time: format(this.details[0].date, 'HH:mm'),
          department: this.department.id,
          duration: this.details[0].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[0].date, 'yyyyMM'),
          checkupOrganizationMemberEventId: this.memberId,
          treatmentKind: this.course?.treatmentKindId || this.department.id,
          options: this.options.map(o => o.id),
          displayDuration: this.displayDuration,
          laneId: this.details[0]?.laneId,
        },
        dentalTreatment: this.details[1]
          ? {
              clinic: '02', // FIXME
              date: format(this.details[1].date, 'yyyy-MM-dd'),
              time: format(this.details[1].date, 'HH:mm'),
              department: 'D',
              duration: this.details[1].duration,
              physician: '10001', // FIXME
              status: TreatmentStatus.Fixed,
              yearMonth: format(this.details[1].date, 'yyyyMM'),
              checkupOrganizationMemberEventId: this.memberId,
              treatmentKind: this.getDentalTreatmentKind()?.id || 'DC1',
              options: this.options.map(o => o.id),
              hidden: true,
              laneId: this.details[1]?.laneId,
            }
          : null,
        appointment: {
          address: this.kitDeliveryAddress || '未入力',
          birthDate: this.birthDate ? format(this.birthDate, 'yyyy-MM-dd') : '',
          appointmentId: this.appointmentId,
          checkupOrganizationMemberEventId: this.memberId,
          optionIds: this.options.map(o => o.id),
          planId: this.course?.id,
        },
      },
    });
    this.flush();
    return res.updateCheckupAppointmentOrganization?.id;
  }

  getDentalTreatmentKind(): TreatmentKind | undefined {
    return this.options
      .filter(o => o.clinicType === ClinicType.Dental && o.treatmentKind)
      .sort((a, b) => b.requiredMin - a.requiredMin)
      .map(o => o.treatmentKind)[0];
  }

  get treatmentKind() {
    return this.course
      ? { id: this.course.treatmentKindId || 'C', name: '' }
      : { id: this.department?.id || 'C', name: '' };
  }

  public async fetchTemporally(id: string) {
    const treatment = await GQL.queryAsGuest<
      GetCheckupTreatmentTemporallyQueryVariables,
      GetCheckupTreatmentTemporallyQuery
    >(getCheckupTreatmentTemporally, { id });

    if (
      !treatment.getTemporallyTreatment ||
      !treatment.getTemporallyTreatment.checkupAppointment?.checkupOrganizationMemberEvent?.id
    ) {
      return;
    }

    await this.fetchCheckupInfo(treatment.getTemporallyTreatment.checkupAppointment.checkupOrganizationMemberEvent.id);
    this.temporallyDate = parse(
      `${treatment.getTemporallyTreatment.date} ${treatment.getTemporallyTreatment.time}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );
  }

  public async fix(id: string) {
    if (!this.preInterview) {
      return null;
    }
    const res = await GQL.query<
      FixCheckupAppointmentOrganizationMutationVariables,
      FixCheckupAppointmentOrganizationMutation
    >(fixCheckupAppointmentOrganization, {
      input: {
        id,
        preInterview: {
          ...this.preInterview,
          finished: true,
          submitted: true,
          questions: safeFilter(this.preInterview.questions).map(q => ({
            ...q,
            answer: safeFilter(q.answer),
            id: String(q.id || 0),
            options: safeFilter(q.options),
          })),
        },
      },
    });

    return res.fixCheckupAppointmentOrganization?.id;
  }

  hasCheckupInfo() {
    return !!this.name;
  }
}
