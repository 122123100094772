import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { useContext } from '../../../../../hooks/useContext';
import { SessionStore } from '../../../../../stores/SessionStore';
import { CardInputLayout } from '../../../AccountDetail/components/CardInputLayout';
import { errorStyle } from '../components/AccountsNameInput';
import { NameInput } from '../components/NameInput';
import { useActions } from './hooks/useActions';
import { GuestWaitingListStore } from './stores/GuestWaitingListStore';

export const AccountsNewNameInputFixWaitingListItem = observer(() => {
  const session = useContext(SessionStore.Context);
  const { doFix, fixStatus, load, loadStatus, store } = useActions(session);

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown]);

  if (!session.authStateKnown || loadStatus.running || fixStatus.running) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  return <GuestWaitingListFix store={store} onCommit={() => doFix()} disabled={fixStatus.running} />;
});

type ChildProps = {
  store: GuestWaitingListStore;
  onCommit: () => void;
  disabled?: boolean;
};

const GuestWaitingListFix = observer(({ store, onCommit, disabled }: ChildProps) => {
  const [concent, setConcent] = useState(false);
  const onSubmit = useRef<() => void>(onCommit);

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        患者プロフィール登録
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <CardInputLayout
            required
            callback={async t => {
              store.setCard({
                brand: t.brand,
                id: t.payjpID,
                expAt: t.expAt,
                fourDigit: t.fourDigit,
              });
              return onCommit();
            }}
            onSubmit={onSubmit}
            onStatusChange={s => store.cardStatus.setCardTransactionStatus(s)}
            onPreCardValidateChange={v => store.cardStatus.setPreCardValidate(v)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>メールアドレス(任意)</FormLabel>
          <TextField type="email" color="white" value={store.mail} onChange={v => store.setMail(v)} />
          <Caption type="small">予約時にGoogleカレンダー招待をお送りします。</Caption>
        </FormItem>
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <div className={errorStyle}>{store.cardStatus.cardTransactionStatus?.error}</div>
        <Button
          type="submit"
          block
          onClick={() => onSubmit.current()}
          disabled={
            disabled ||
            !store.valid ||
            !concent ||
            store.cardStatus.cardTransactionStatus?.running ||
            store.cardStatus.hasPreCardError
          }
        >
          登録してキャンセル待ちを確定
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
