// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { css } from '@emotion/css';
import add from 'date-fns/add';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import React, { useMemo } from 'react';

import { AttachmentType } from '../../../../../../gql/gql-types';
import { Accordion } from '../../../../../components/Accordion';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Defaults } from '../../../../../constants/Defaults';
import { Routes } from '../../../../../constants/Routes';
import { Color, FontSize } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { Treatment } from '../../../../../interfaces/Treatment';
import { approximate } from '../../../../../utils/NumberUtil';
import { DoctorIcon } from './DoctorIcon';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

type Props = {
  width?: number;
  onClick?: (key: string) => Promise<void>;
} & Pick<
  Treatment,
  'clinic' | 'date' | 'department' | 'soap' | 'payment' | 'id' | 'duration' | 'attachments' | 'isNoCharge'
>;

export const TreatmentCard = ({
  width,
  clinic,
  date,
  department,
  soap,
  payment,
  id,
  duration,
  attachments,
  isNoCharge,
  onClick,
}: Props) => {
  const { push } = useReservationId();
  return (
    <Card shadowType="floating" width={width}>
      <div onClick={() => push(Routes.Paths.MedicalHistoryShow, id)} className={style}>
        <TreatmentCardHeader date={date} clinic={clinic} department={department} duration={duration} />
        <TreatmentCardBody soap={soap} />
        <TreatmentCardFooter payment={payment} isNoCharge={isNoCharge} />
      </div>
      {attachments.length > 0 && <TreatmentCardAccordion onClick={onClick} attachments={attachments} />}
    </Card>
  );
};

const style = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 204px;
  margin-bottom: ${gutter(4)};
`;

type HeaderProps = Pick<Props, 'clinic' | 'date' | 'department' | 'duration'>;
const TreatmentCardHeader = ({ clinic, date, department, duration }: HeaderProps) => (
  <header>
    <Caption type="subheader">
      {format(date, 'yyyy年MM月dd日')}({WeekLabels[getDay(date)]}){format(date, 'HH:mm')} -{' '}
      {format(add(date, { minutes: approximate(duration, Defaults.DURATION) }), 'HH:mm')}
    </Caption>
    <Caption type="small">
      {clinic.name} / {department.name}
    </Caption>
  </header>
);

type FooterProps = Pick<Props, 'payment' | 'isNoCharge'>;

const formatYen = (n: number) => n.toLocaleString();

const TreatmentCardFooter = ({ payment, isNoCharge }: FooterProps) => {
  const isUndef = useMemo(() => payment.amount === null || payment.amount === undefined, [payment]);
  return (
    <footer className={footerStyle}>
      {isNoCharge ? (
        <>
          <Caption type="small">診察費用合計</Caption> : <div>¥0</div>
        </>
      ) : !isUndef ? (
        <>
          <Caption type="small">診察費用合計</Caption> : <div>¥{formatYen(payment.amount as number)}</div>
        </>
      ) : (
        <Caption type="small">診察費用未確定</Caption>
      )}
    </footer>
  );
};

type AccordionProps = { onClick?: (key: string) => Promise<void> } & Pick<Props, 'attachments'>;

const TreatmentCardAccordion = ({ attachments, onClick }: AccordionProps) => {
  const attachmentsGroup = useMemo(
    () => [
      { title: '領収書ダウンロード', items: attachments.filter(item => item.type === AttachmentType.Receipt) || [] },
      { title: '検査結果ダウンロード', items: attachments.filter(item => item.type !== AttachmentType.Receipt) || [] },
    ],
    [attachments],
  );

  return (
    <ButtonGroup>
      {attachmentsGroup.map(attachments => (
        <React.Fragment key={attachments.title}>
          {attachments.items.length > 0 && (
            <Accordion header={<div>{attachments.title}</div>} textBold>
              {attachments.items.map((attachment, index) => (
                <Card key={index} outlineLight menuItem>
                  <button className={downloadTextStyle} onClick={() => onClick?.(attachment.id)}>
                    {attachment.name}
                  </button>
                </Card>
              ))}
            </Accordion>
          )}
        </React.Fragment>
      ))}
    </ButtonGroup>
  );
};

const footerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const doctorStyle = css`
  display: flex;
  align-items: center;
`;

const downloadTextStyle = css`
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;

type BodyProps = Pick<Props, 'soap'>;
const TreatmentCardBody = ({ soap }: BodyProps) => (
  <div>
    <Card color="inset" shadowType="none">
      <div className={doctorStyle}>
        <DoctorIcon id={soap.doctor.id} />
        <div>{soap.doctor.name}</div>
      </div>
      <p className={bodyStyle}>
        <>
          {soap.feedback.split('\\n').map((line, key) => (
            <p key={key}>{line}</p>
          ))}
        </>
      </p>
    </Card>
  </div>
);

const bodyStyle = css`
  height: 42px;
  margin-block: 0;
  overflow: hidden;
  font-size: ${FontSize.Regular};
  line-height: 1.5;
  color: ${Color.GrayscaleDarker};
  text-overflow: ellipsis;
`;
