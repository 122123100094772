import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';

import { App } from './apps/App';
import { Environment } from './environments';
import { applyGlobalStyle } from './helpers/Style';

datadogRum.init({
  applicationId: '61f8b863-7d32-4043-bae2-434ad78f2987',
  clientToken: 'pubed41b89916ce65040e1530a0370b9e81',
  site: 'datadoghq.com',
  service: 'clinic-front',

  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

Sentry.init({
  dsn: 'https://fae669465335447293b40fc7c3fa7686@o440290.ingest.sentry.io/5605794',
  integrations: [new Integrations.BrowserTracing() as any],
  tracesSampleRate: 1.0,
});

datadogLogs.init({
  clientToken: 'pub0b148f956ce46b5093fc1de25ffd2390',
  site: 'datadoghq.com',
  sampleRate: 100,
  forwardErrorsToLogs: true,
  service: `clinic-front`,
  env: process.env.APP_ENV,
});

// Amplifyの設定
Amplify.configure(Environment.Amplify);

// setup styles
applyGlobalStyle();

window.document.body.addEventListener('click', ev => {
  const tag = ev.target as HTMLElement;
  Sentry.addBreadcrumb({
    category: 'ui.click',
    data: {
      textContent: tag.textContent,
      tagName: tag.tagName,
      attributes: tag.getAttributeNames().map(n => tag.getAttribute(n)),
    },
    level: Sentry.Severity.Debug,
  });
  datadogLogs.logger.info(
    `ui.click ${tag.tagName.toLowerCase()} ${tag
      .getAttributeNames()
      .map(n => `${n}:${tag.getAttribute(n)}`)
      .join(' ')}  ${tag.textContent} `,
  );
});

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('app'),
);
