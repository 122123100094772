import format from 'date-fns/format';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Card } from '../../../../../components/Card';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { useError } from '../../../../../components/ErrorDialog';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import {
  ConfirmationFormArea,
  ConfirmationFormItem,
  ConfirmationFormLabel,
} from '../../../../Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { CheckupReservationStore } from '../../CheckupReservationsNew/stores/CheckupReservationStore';

type Props = {
  onNext?: () => void;
};

export const CheckupReservationFixStartPage = observer(({ onNext }: Props) => {
  const store = useContext(CheckupReservationStore.Context);
  const session = useContext(SessionStore.Context);
  const { setError } = useError();
  const { reservationId } = useReservationId();

  const [load, loadStatus] = useTransaction(async () => {
    if (!reservationId) {
      setError({
        message: '不正なURLです。企業から送付されたURLから入りなおしてください。',
        onOk: () => {
          window.location.replace('/');
        },
      });
      return;
    }
    try {
      await store.fetchTemporally(reservationId);
    } catch (e) {
      setError({
        message: (e as any).message || e,
        onOk: () => {
          window.location.replace('/');
        },
      });
    }
    await session.fetchUserEntity();
    if (!session.user?.userEntity) {
      setError({
        message: 'ユーザー登録がまだ完了していません。先に氏名を登録してください。',
        onOk: () => {
          window.location.replace(
            `${Routes.Paths.AccountName}?action=redirect&url=${encodeURI(new URL(window.location.href).pathname)}`,
          );
        },
      });
    }
  });

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <DocumentTitle title="所属と氏名を確認してください。" />
      <Modal visible={!!loadStatus.running}>
        <Card>健診情報を取得中</Card>
      </Modal>
      <FormLayout>
        <FormItem>
          <FormLabel>所属と氏名を確認してください。</FormLabel>
          <ConfirmationFormArea>
            <ConfirmationFormItem>
              <ConfirmationFormLabel>所属</ConfirmationFormLabel>
              {store.checkupOrganization?.name}
            </ConfirmationFormItem>
            <ConfirmationFormItem>
              <ConfirmationFormLabel>氏名</ConfirmationFormLabel>
              {store.checkupOrganizationMember?.name}
            </ConfirmationFormItem>
          </ConfirmationFormArea>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>コース</ConfirmationFormLabel>
            {store.checkupOrganizationMember?.course.name}
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>受診予定日時</ConfirmationFormLabel>
            {store.temporallyDate && (
              <>
                {format(store.temporallyDate, 'yyyy年MM月dd日')}({WeekLabels[store.temporallyDate.getDay() || 0]}){' '}
                {format(store.temporallyDate, 'HH:mm')}
              </>
            )}
          </ConfirmationFormItem>
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={16}>
        <Button onClick={onNext} disabled={!store.checkupOrganizationMember}>
          次へ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;
