import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../components/Button';
import { Caption } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { useError } from '../../../components/ErrorDialog';
import { Icon } from '../../../components/Icon';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { LineConnectStore } from '../AccountsNew/AccountsNewNameInput/AccountsNewNameInputLineConnect/stores/LineConnectStore';

export const AccountConnectPage = observer(() => {
  const session = useContext(SessionStore.Context);
  const [load, loadStatus] = useTransaction(() => session.fetchSession());
  const { decode } = useReservationId();
  const connectionStore = useMemo(() => new LineConnectStore(), []);
  const { setError } = useError();

  const [connect, connectStatus] = useTransaction(async () => {
    const url = await connectionStore.issueNonce();
    if (!url) {
      return;
    }
    window.location.href = url;
  });

  useEffect(() => {
    load();
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      setError({ message: '不正なURLです。' });
      return;
    }
    connectionStore.setToken(decode(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Modal visible={!!loadStatus.running}>
        <Card>ユーザー情報を取得中</Card>
      </Modal>
      <Caption type="header" align="center" underline>
        アカウント連携
      </Caption>

      <Card>
        <div className={subHeaderStyle}>
          <Caption type="subheader">LINEアカウントとTENユーザーを連携します。</Caption>
        </div>
        <div className={divStyle}>
          <Caption type="div">
            ログイン中：{session.user?.userEntity?.name} (患者番号：{session.user?.userEntity?.patient?.patientNo})
          </Caption>
        </div>

        <div className={bodyStyle}>
          <Caption type="body">
            LINEアカウントと連携すると、LINE上でリアルタイムにお問い合わせができるほか、医師・薬剤師からのフィードバックや次回診療のお知らせなど便利な情報が届くようになります。
          </Caption>
        </div>
        <Button block onClick={() => connect()} disabled={connectStatus.running}>
          ログイン中のアカウントで連携する
        </Button>
        <Button
          block
          onClick={() => {
            session.logout(Routes.Paths.AccountNew + window.location.search);
          }}
          ghost
        >
          <Icon name="arrow-left-circle" /> 別のアカウントで連携する
        </Button>
      </Card>
    </Wrapper>
  );
});

const subHeaderStyle = css`
  margin: 16px 0;
`;

const divStyle = css`
  margin: 8px 0;
`;

const bodyStyle = css`
  margin: 8px 0;
`;
