import React, { useEffect, useMemo } from 'react';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
// import { useReservationId } from '../../../hooks/useReservationId';
import {
  ConfirmationFormArea,
  ConfirmationFormGroup,
  ConfirmationFormItem,
  ConfirmationFormItemValue,
  ConfirmationFormLabel,
  ConfirmationGroupTitle,
} from '../InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { transformer } from '../InterviewsNew/InterviewsInput/transformer';
import { InterviewStore } from './stores/InterviewStore';

export const InterviewsDetailPage = () => {
  const { reservationId, push } = useReservationId();
  const store = useMemo(() => new InterviewStore(), []);
  const [load] = useTransaction(() => store.fetchInterview(reservationId));

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <Wrapper>
      <DocumentTitle title="事前問診の確認・編集" />
      <Caption type="header" align="center" underline>
        事前問診の確認・編集
      </Caption>
      <ConfirmationFormArea>
        <ConfirmationFormGroup>
          <ConfirmationGroupTitle
            editable
            onClickEdit={() => push(Routes.Paths.ReservationPreInterview, reservationId)}
          >
            問診内容
          </ConfirmationGroupTitle>
          {store.interview?.questions
            ?.map(question => question && { ...question, ...transformer([], question) })
            .map(q => (
              <>
                {q && (
                  <ConfirmationFormItem key={q.id}>
                    <ConfirmationFormLabel>{q.confirm_text}</ConfirmationFormLabel>
                    <ConfirmationFormItemValue question={q} />
                  </ConfirmationFormItem>
                )}
              </>
            ))}
        </ConfirmationFormGroup>
      </ConfirmationFormArea>
    </Wrapper>
  );
};
