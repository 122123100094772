import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../gql/client';
import {
  ListLumeccaVacanciesQuery,
  ListLumeccaVacanciesQueryVariables,
  ListVacanciesForWeekQuery,
  ListVacanciesForWeekQueryVariables,
  Maybe,
  VacancyUnitCalculation,
} from '../../../../gql/gql-types';
import { listLumeccaVacancies } from '../../../../gql/operations/listLumeccaVacancies';
import { listVacancies } from '../../../../gql/operations/listVacancies';
import { CalendarCells, Time } from '../../../components/Calendar';
import { Observable, PrefetchCacheStore } from '../../../stores/PrefetchCacheStore';

export type EditCondition = { doctor?: { id: string }; duration: number; anyone?: Maybe<boolean> };

type Lumecca = {
  IPL: string;
  HairRemoval: string;
};

/**
 * 空枠を管理するstore
 */
export class VacanciesStore {
  public static Context = createContext<VacanciesStore | null>(null);

  private allVacancies?: Time[][][] = undefined;
  public displayVacancies?: CalendarCells = undefined;
  public nearestVacancy: { date: string; time: string } = { date: '', time: '' };

  public cache = new PrefetchCacheStore<VacancyUnitCalculation, Observable<ListVacanciesForWeekQuery>>();
  public lumeccaCache = new PrefetchCacheStore<VacancyUnitCalculation, Observable<ListLumeccaVacanciesQuery>>();
  public resolvedAt?: Date = undefined;

  constructor() {
    makeObservable(this, {
      nearest: computed,
      vacancies: computed,
      fetchVacancies: action,
      setVacancies: action,
      displayVacancies: observable,
      cache: observable,
      prefetchNext: action,
      prefetch: action,
      findNearest: action,
      resolvedAt: observable,
      dispatchResolved: action,
      nearestVacancy: observable,
    });
  }

  public get vacancies() {
    return this.displayVacancies;
  }

  public get nearest() {
    const now = new Date();
    return this.allVacancies
      ?.slice(0)
      .reduce((a, b) => [...a, ...b], [])
      .reduce((a, b) => [...a, ...b], [])
      .filter(v => isAfter(v.date, now))
      .filter(v => v.available > 0)
      .sort((a, b) => compareAsc(a.date, b.date))[0];
  }

  public setVacancies(vacancies: CalendarCells) {
    this.displayVacancies = vacancies;
    if ('empty' in vacancies) {
      return;
    }
    this.allVacancies = [
      ...(this.allVacancies || []).filter(v => !isSameDay(v[0][0].date, vacancies[0][0].date)),
      vacancies,
    ].sort((a, b) => compareAsc(a[0][0].date, b[0][0].date));
  }

  public async fetchVacancies(
    week: Date,
    department: { id: string; clinic: string },
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
    online?: boolean,
  ) {
    const lumeccaIds: Lumecca = {
      IPL: '12E',
      HairRemoval: '12G',
    };
    const hifuTreatmentIds = ['12I', '12K', '12Q', '12S'];
    const peelingTreatmentIds = ['12P'];
    if (
      treatmentKind?.id &&
      (treatmentKind.id === lumeccaIds.IPL ||
        treatmentKind.id === lumeccaIds.HairRemoval ||
        // HIFUも連鎖予約対象とする
        hifuTreatmentIds.includes(treatmentKind.id) ||
        peelingTreatmentIds.includes(treatmentKind.id))
    ) {
      const lumeccaResponse = await this.fetchCacheFirstLumecca(
        week,
        department,
        treatmentKind,
        conditions,
        vaccinePatientId,
        online,
      );

      if (!lumeccaResponse) {
        return;
      }
      const times: Time[][] = lumeccaResponse.listLumeccaConsecutiveVacancies.reduce((r, e) => {
        const last = r.slice(-1)[0];
        const time = {
          date: parse(`${e.date} ${e.time}`, 'yyyy-MM-dd HH:mm', new Date()),
          available: e.available,
          doctor: e.doctor || undefined,
          estimatedDuration: e.estimatedDuration,
          laneId: e.laneId,
          waitingListAvailable: e.waitAvailable || false,
        };
        if (r.length === 0) {
          return [[time]];
        }
        if (isSameDay(last[0].date, time.date)) {
          return [...r.slice(0, -1), [...last, time]];
        }
        return [...r, [time]];
      }, new Array<Time[]>());
      if (times.length === 0) {
        this.setVacancies({
          empty: true,
          week,
        });
        return;
      }
      this.setVacancies(times);
      return;
    }
    const res = await this.fetchCacheFirst(week, department, treatmentKind, conditions, vaccinePatientId, online);
    if (!res) {
      return;
    }
    const times: Time[][] = res.listVacancies.reduce((r, e) => {
      const last = r.slice(-1)[0];
      const time = {
        date: parse(`${e.date} ${e.time}`, 'yyyy-MM-dd HH:mm', new Date()),
        available: e.available,
        doctor: e.doctor || undefined,
        estimatedDuration: e.estimatedDuration,
        laneId: e.laneId,
        waitingListAvailable: e.waitAvailable || false,
      };
      if (r.length === 0) {
        return [[time]];
      }
      if (isSameDay(last[0].date, time.date)) {
        return [...r.slice(0, -1), [...last, time]];
      }
      return [...r, [time]];
    }, new Array<Time[]>());
    if (times.length === 0) {
      this.setVacancies({
        empty: true,
        week,
      });
      return;
    }
    this.setVacancies(times);
  }

  public prefetch(
    week: Date,
    department: { id: string; clinic: string },
    online?: boolean,
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
  ) {
    return this.fetchCacheFirst(week, department, treatmentKind, conditions, vaccinePatientId, online);
  }

  public prefetchNext(
    week: Date,
    department: { id: string; clinic: string },
    online?: boolean,
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
  ) {
    return this.fetchCacheFirst(addDays(week, 7), department, treatmentKind, conditions, vaccinePatientId, online);
  }

  private async fetchCacheFirst(
    week: Date,
    department: { id: string; clinic: string },
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
    online?: boolean,
  ) {
    const input = {
      departmentId: department.id,
      fromDate: format(week, 'yyyy-MM-dd'),
      fromTime: '00:00',
      toDate: format(addDays(week, 8), 'yyyy-MM-dd'),
      toTime: '00:00',
      treatmentKind: treatmentKind?.id || department.id,
      options: [],
      editCondition: conditions?.map(c => ({ doctor: c.doctor?.id, duration: c.duration, anyone: c.anyone })),
      clinic: department.clinic,
      vaccinePatientId,
      online,
    };

    const observable = new Observable(
      GQL.queryMaybeGuest<ListVacanciesForWeekQueryVariables, ListVacanciesForWeekQuery>(listVacancies, {
        input,
      }),
    );

    this.cache.addCache({ key: input, expire: addMinutes(new Date(), 5), value: observable });

    const cacheHit = await this.cache.fetch(input);

    return (cacheHit ? cacheHit.flat() : observable.flat()).then(res => {
      this.dispatchResolved();
      return res;
    });
  }

  private async fetchCacheFirstLumecca(
    week: Date,
    department: { id: string; clinic: string },
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
    online?: boolean,
  ) {
    const input = {
      departmentId: department.id,
      fromDate: format(week, 'yyyy-MM-dd'),
      fromTime: '00:00',
      toDate: format(addDays(week, 8), 'yyyy-MM-dd'),
      toTime: '00:00',
      treatmentKind: treatmentKind?.id || department.id,
      options: [],
      editCondition: conditions?.map(c => ({ doctor: c.doctor?.id, duration: c.duration, anyone: c.anyone })),
      clinic: department.clinic,
      vaccinePatientId,
      online,
    };

    const observable = new Observable(
      GQL.queryMaybeGuest<ListLumeccaVacanciesQueryVariables, ListLumeccaVacanciesQuery>(listLumeccaVacancies, {
        input,
      }),
    );

    this.lumeccaCache.addCache({ key: input, expire: addMinutes(new Date(), 5), value: observable });

    const cacheHit = await this.lumeccaCache.fetch(input);

    return (cacheHit ? cacheHit.flat() : observable.flat()).then(res => {
      this.dispatchResolved();
      return res;
    });
  }

  public dispatchResolved() {
    this.resolvedAt = new Date();
  }

  /**
   * computedでもactionでもないため、要useMemo
   */
  public findNearest(week: Date, clinic: string, departmentId: string, treatmentKinds_?: string[]) {
    const treatmentKinds = treatmentKinds_ || [departmentId];
    const keys: VacancyUnitCalculation[] = treatmentKinds.map(treatmentKind => ({
      departmentId,
      fromDate: format(week, 'yyyy-MM-dd'),
      fromTime: '00:00',
      toDate: format(addDays(week, 8), 'yyyy-MM-dd'),
      toTime: '00:00',
      treatmentKind,
      options: [],
      clinic,
      online: false,
      editCondition: undefined,
      vaccinePatientId: undefined,
    }));
    const vacancies = keys.map(key => this.cache.fetchAssert(key));
    const nearest = vacancies.reduce(
      (prev, res) => {
        if (res === false || !res.cache) {
          return prev;
        }
        const earliest = res.cache.listVacancies.filter(v => v.available > 0).reduce(min, { date: '', time: '' });
        return min(prev, earliest);
      },
      { date: '', time: '' },
    );

    if (!nearest.date) {
      return null;
    }
    return parse(`${nearest.date} ${nearest.time}`, 'yyyy-MM-dd HH:mm', week);
  }

  public async fetchNearestOneData(
    week: Date,
    department: { id: string; clinic: string },
    treatmentKind?: { id: string },
    conditions?: EditCondition[],
    vaccinePatientId?: string,
    online?: boolean,
  ) {
    const input = {
      departmentId: department.id,
      fromDate: format(week, 'yyyy-MM-dd'),
      fromTime: '00:00',
      toDate: format(addDays(week, 8), 'yyyy-MM-dd'),
      toTime: '00:00',
      treatmentKind: treatmentKind?.id || department.id,
      options: [],
      editCondition: conditions?.map(c => ({ doctor: c.doctor?.id, duration: c.duration, anyone: c.anyone })),
      clinic: department.clinic,
      vaccinePatientId,
      online,
    };
    const vacancies = await GQL.queryMaybeGuest<ListVacanciesForWeekQueryVariables, ListVacanciesForWeekQuery>(
      listVacancies,
      {
        input,
      },
    );
    const vacancy = vacancies.listVacancies.filter(v => v.available > 0).reduce(min, { date: '', time: '' });
    this.nearestVacancy = { date: format(new Date(vacancy.date), 'MM月dd日'), time: vacancy.time };
  }
}

const min = (a: { date: string; time: string }, b: { date: string; time: string }) => {
  if (!b.date) {
    return a;
  }
  if (!a.date || a.date > b.date) {
    return b;
  }
  if (b.date > a.date) {
    return a;
  }
  return a.time > b.time ? b : a;
};
