import React from 'react';

export const Yahoo = () => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1976 0.138139C6.35711 -0.0460461 6.64284 -0.0460463 6.80235 0.138138L9.30429 3.02713C9.52864 3.28619 9.34461 3.68899 9.00191 3.68899H7.49998V4.98899H11.7C12.418 4.98899 13 5.57102 13 6.28899V16.689C13 17.407 12.418 17.989 11.7 17.989H1.3C0.58203 17.989 0 17.407 0 16.689V6.28899C0 5.57102 0.58203 4.98899 1.3 4.98899H5.49998V3.68899H3.99804C3.65533 3.68899 3.47132 3.28619 3.69567 3.02713L6.1976 0.138139ZM5.49998 6.28899H1.3V16.689H11.7V6.28899H7.49998V10.189C7.49998 10.7413 7.05226 11.189 6.49998 11.189C5.94769 11.189 5.49998 10.7413 5.49998 10.189V6.28899Z"
      fill="#EB5757"
    />
  </svg>
);
