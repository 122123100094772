import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../domains/base/SearchParams';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { ReservationDetailInfoHeader } from '../../Reservations/ReservationDetail/components/ReservationDetailInfo';
import { WaitingListItemStore } from '../stores/WaitingListItemStore';

export const WaitingListItemDetailPage = observer(() => {
  const store = useMemo(() => new WaitingListItemStore(), []);
  const { reservationId } = useReservationId();
  const { push } = useHistory();
  const [load, loadStatus] = useTransaction(async (id: string) => {
    await store.fetch(id);
  });

  const [deleteItem, deleteStatus] = useTransaction(async () => {
    await store.deleteItem();
    push({
      pathname: Routes.Paths.WaitingListItemDeleteComplete,
      search: new URLSearchParamsMonad(new URLSearchParams())
        .accept('department', store.origin?.department?.id || '')
        .accept('treatmentKind', store.origin?.treatmentKind?.id || '')
        .toString(),
    });
  });

  useEffect(() => {
    load(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <Wrapper>
      <Modal visible={!!loadStatus.running}>
        <Card>データ取得中です。</Card>
      </Modal>
      <Caption type="header" underline align="center">
        キャンセル通知詳細
      </Caption>

      <ReservationDetailInfoHeader
        clinicName={store.origin?.clinic?.name || ''}
        date={store.date}
        departmentName={store.origin?.department?.name || ''}
        duration={store.duration}
      />

      <Caption type="small">
        ※キャンセル通知登録は、空き枠発生時の予約確定をお約束するものではありません。
        <br />
        ※空き枠が出た際に、LINEで通知します。先着で予約確定した方に受診いただけます。
      </Caption>

      <ButtonGroup gutter={20}>
        <Button ghost onClick={deleteItem} disabled={deleteStatus.running}>
          キャンセル通知の解除
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
