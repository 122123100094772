import { IconName } from '@clinic-monorepo/clinic-icon';
import { css } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Icon } from '../../../../../components/Icon';
import { gutter, square } from '../../../../../helpers/Style';

type Props = {
  iconName: IconName;
  headerTitle: string;
};
export const SectionHeader = ({ iconName, headerTitle }: Props) => (
  <div>
    <Caption type="header">
      <div className={headerWrapper}>
        <Icon name={iconName} />
        {headerTitle}
      </div>
    </Caption>
  </div>
);

const headerWrapper = css`
  display: flex;
  align-items: center;
  padding-top: ${gutter(8.25)};

  > svg {
    ${square(24)}

    margin-right: ${gutter(2)};
  }
`;
