import { addMinutes } from 'date-fns/esm';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import { CreateWaitingListItemMutation, CreateWaitingListItemMutationVariables } from '../../../../../gql/gql-types';
import { createWaitingListItem } from '../../../../../gql/operations/createWaitingListItem';
import { ReservationStore } from './ReservationStore';

export class WaitingListStore {
  public static Context = createContext<WaitingListStore | null>(null);

  constructor() {
    makeAutoObservable(this, {
      department: observable,
      end: observable,
      register: action,
      setInitial: action,
      setRange: action,
      setUserId: action,
      start: observable,
      treatmentKind: observable,
      userId: observable,
      id: computed,
    });
  }

  public department: ReservationStore['department'] = undefined;
  public treatmentKind: ReservationStore['treatmentKind'] = undefined;

  public start?: Date = undefined;
  public end?: Date = undefined;
  public userId?: string = undefined;

  public setInitial(store: ReservationStore) {
    this.department = store.department;
    this.treatmentKind = store.treatmentKind;
    this.start = store.date;
    this.end = this.start ? addMinutes(this.start, store.duration) : undefined;
  }

  public setRange(start?: Date, end?: Date) {
    this.start = start;
    this.end = end;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public async register() {
    if (!this.userId || !this.start || !this.end || !this.department) {
      return;
    }

    await GQL.query<CreateWaitingListItemMutationVariables, CreateWaitingListItemMutation>(createWaitingListItem, {
      input: {
        end: this.end.toISOString(),
        start: this.start.toISOString(),
        userId: this.userId,
        clinic: this.department.clinic,
        department: this.department.id,
        treatmentKind: this.treatmentKind?.id || this.department.id,
      },
    });
  }

  public get id() {
    return `${this.department?.clinic}#${this.start?.toISOString()}#${this.department?.id}#${
      this.treatmentKind?.id || this.department?.id
    }#${this.userId}`;
  }
}
