import { IconName } from '@clinic-monorepo/clinic-icon';
import { css } from 'emotion';
import React from 'react';

import { Maybe } from '../../../../../../../../gql/gql-types';
import { Caption } from '../../../../../../../components/Caption';
import { Card } from '../../../../../../../components/Card';
import { Icon } from '../../../../../../../components/Icon';
import { Color, IconSize } from '../../../../../../../constants/Style';
import { square } from '../../../../../../../helpers/Style';
import { approximate } from '../../../../../../../utils/NumberUtil';

type Props = {
  id: string;
  name: string;
  icon?: IconName;
  estimatedDuration?: Maybe<number>;
  onClick?: () => void;
};

type CardProps = {
  name: string;
  icon?: IconName;
  estimatedDuration?: Maybe<number>;
};

export const CourseCard = ({ name, onClick, icon, estimatedDuration }: Props) => (
  <Card onClick={onClick} shadowType="floating">
    <CourseCardTitle name={name} icon={icon} estimatedDuration={estimatedDuration} />
  </Card>
);

const CourseCardTitle: React.FC<CardProps> = ({ name, estimatedDuration }: CardProps) => (
  <div className={courseCardStyle}>
    <div className={courseCardTitleStyle}>
      <Caption type="subheader">{name}</Caption>
    </div>
    <div className={courseCardEndStyle}>
      {estimatedDuration && <Caption type="subheader">{approximate(estimatedDuration, 15)}分</Caption>}
      <Icon name="angle-right" />
    </div>
  </div>
);
const courseCardStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const courseCardTitleStyle = css`
  width: 200px;
  text-overflow: wrap;
`;

const courseCardEndStyle = css`
  display: flex;
  align-items: center;

  > svg {
    ${square(IconSize.Regular)}

    fill: ${Color.GrayscaleNeutral};
  }
`;
