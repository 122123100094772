type Props = {
  clinic?: { id: string; name: string; address: string; lng: number; lat: number; mapUrl: string };
  treatmentKind?: string;
};
export const useReservationLocation = ({ clinic, treatmentKind }: Props) => {
  if (treatmentKind === 'COVID3') {
    return {
      name: 'セブンリッチ会計事務所',
      address: '東京都渋谷区渋谷3-19-1 オミビル9F（渋谷駅から徒歩5分程）',
      mapUrl: 'https://goo.gl/maps/vyjY3WcrieCVBJ5L7',
      lat: 35.6579,
      lng: 139.7024,
    };
  }

  return clinic;
};
