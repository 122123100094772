import { css } from 'emotion';
import React, { ReactNode, useMemo } from 'react';

import { gutter } from '../../helpers/Style';

type Props = {
  vertical?: number;
  horizontal?: number;
  children: ReactNode;
};
export const Gutter = ({ vertical, horizontal, children }: Props) => {
  const className = useMemo(
    () => css`
      margin: ${gutter(vertical || 0)} ${gutter(horizontal || 0)};
    `,
    [vertical, horizontal],
  );

  return <div className={className}>{children}</div>;
};
