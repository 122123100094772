import { action, makeAutoObservable, observable } from 'mobx';

type Cache<K, V> = {
  key: K;
  value: V;
  expire: Date;
};

/**
 * キャッシュストア
 */
export class PrefetchCacheStore<K, V> {
  public cache: Cache<K, V>[] = [];

  constructor() {
    makeAutoObservable(this, {
      cache: observable,
      addCache: action,
      fetch: action,
    });
  }

  public addCache(cache: Cache<K, V>) {
    this.cache.push(cache);
  }

  public async fetch(key: K): Promise<V | false> {
    const hit = this.cache.find(c => shallowEquals(c.key, key) && new Date() < c.expire);
    if (hit) {
      return hit.value;
    }
    return false;
  }

  public fetchAssert(key: K): V | false {
    const hit = this.cache.find(c => shallowEquals(c.key, key));
    if (hit) {
      return hit.value;
    }
    return false;
  }
}

/**
 * observableのモッククラス
 * 複数回resolveできる
 */
export class Observable<X> {
  constructor(public core: Promise<X>) {
    makeAutoObservable(this, {
      cache: observable,
      resolved: observable,
      listeners: observable,
      flat: action,
      dispatch: action,
    });

    core.then(x => this.dispatch(x));
  }

  public cache?: X = undefined;
  public resolved = false;
  public listeners: ((x?: X) => void)[] = [];

  public addListener(listener: (x?: X) => void) {
    if (this.resolved) {
      listener(this.cache);
      return;
    }
    this.listeners = [...this.listeners, listener];
  }

  public async flat(): Promise<X | undefined> {
    if (this.resolved) {
      return this.cache;
    }
    return new Promise(resolve => {
      this.addListener(resolve);
    });
  }

  public dispatch(value: X) {
    this.listeners.forEach(l => l(value));
    this.cache = value;
    this.resolved = true;
  }
}

const shallowEquals = (one: any, another: any): boolean => {
  if (typeof one !== typeof another) {
    return false;
  }
  if (typeof one === 'string' || typeof one === 'number' || typeof one === 'boolean') {
    return one === another;
  }

  if (typeof one === 'undefined' || one === null) {
    return true;
  }

  if (!Object.keys(one).every(k => k in another) || !Object.keys(another).every(k => k in one)) {
    return false;
  }

  return Object.entries(one).every(([oneKey, oneValue]) => shallowEquals(oneValue, another[oneKey]));
};
