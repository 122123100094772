import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { useContext } from '../../../../hooks/useContext';
import { useSteps } from '../../../../hooks/useSteps';
import { AccountDetailStore } from '../stores/AccountDetailStore';
import { ConfirmPage } from './confirm/index';
import { RegisterPage } from './register/index';

export const STEP = {
  REGISTER: 'register',
  CONFIRM: 'confirm',
};
export const FirstReserverAccountEditPage = observer(() => {
  const store = useContext(AccountDetailStore.Context);

  const [step, setStep] = useState<string>(STEP.REGISTER);
  useSteps(step, setStep);

  return (
    <div>
      {(() => {
        switch (step) {
          case STEP.REGISTER:
          default:
            return <RegisterPage store={store} setStep={setStep} />;
          case STEP.CONFIRM:
            return <ConfirmPage store={store} setStep={setStep} />;
        }
      })()}
    </div>
  );
});
