import { css, cx } from 'emotion';
import React, { ChangeEvent, CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

import { BorderRadius, Color, Duration, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = Partial<{
  value: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  /**
   * 指定した行数分の高さを確保する。
   * @default 3
   */
  lines: number;
  onChange: (value: string) => void;
  color?: 'grey' | 'white';
  onFocusout?: () => void;
  analyticsClassName: string;
}>;

export const TextArea = ({
  color = 'grey',
  value,
  placeholder,
  disabled,
  required,
  lines = 3,
  onChange,
  onFocusout,
  analyticsClassName,
}: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const [minHeight, setMinHeight] = useState<CSSProperties['minHeight']>('auto');

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => !disabled && onChange?.(e.target.value);

  useEffect(() => {
    if (!ref.current) return;

    const style = window.getComputedStyle(ref.current);
    const lineHeight =
      style.lineHeight !== 'normal' ? parseInt(style.lineHeight, 10) : parseInt(style.fontSize, 10) * 1.2;

    setMinHeight(
      lineHeight * lines +
        parseInt(style.paddingBottom, 10) +
        parseInt(style.paddingTop, 10) +
        parseInt(style.borderTopWidth, 10) +
        parseInt(style.borderBottomWidth, 10),
    );
  }, [lines]);

  const style = useMemo(
    () =>
      cx(
        baseStyle,
        css`
          background-color: ${color === 'grey' ? Color.TextureInput : Color.GrayscaleWhite};
        `,
      ),
    [color],
  );

  return (
    <textarea
      ref={ref}
      className={`${style} ${analyticsClassName}`}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      style={{ minHeight }}
      onChange={handleChange}
      onBlur={onFocusout}
    />
  );
};

const baseStyle = css`
  display: block;
  width: 100%;
  padding: ${gutter(4)};
  font-size: ${FontSize.Medium};
  resize: vertical;
  background-color: ${Color.TextureInput};
  border: none;
  border-radius: ${BorderRadius.Small};
  outline: none;
  box-shadow: none;
  transition: box-shadow ${Duration.Fade};
  appearance: none;

  &::placeholder {
    color: ${Color.GrayscaleNeutral};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Color.GrayscaleDarker};
  }

  &:disabled {
    color: ${Color.GrayscaleNeutral};
    cursor: not-allowed;
  }
`;
