import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { Card } from '../../../../../components/Card';
import { TreatmentCard } from '../TreatmentCard';

type Props = {
  treatments: ComponentProps<typeof TreatmentCard>[];
  hasNext?: boolean;
  onClick?: (id: string) => void;
  onClickNext?: () => void;
  onDownloadAll?: () => void;
  downloadDisabled?: boolean;
  checkedIds: string[];
};

export const TreatmentCardListLayout = ({ treatments, hasNext, onClick, onClickNext, checkedIds }: Props) => (
  <div className={listItemStyle}>
    {treatments.map((reservation, inx) => (
      <div key={inx}>
        <TreatmentCard
          id={reservation.id}
          date={reservation.date}
          clinic={reservation.clinic}
          department={reservation.department}
          payment={reservation.payment}
          soap={reservation.soap}
          duration={reservation.duration}
          attachments={reservation.attachments}
          isNoCharge={reservation.isNoCharge}
          onClick={onClick}
          checked={checkedIds.length > 0 && !!checkedIds.find(checkedId => checkedId === reservation.id)}
        />
      </div>
    ))}
    {hasNext && (
      <Card shadowType="floating" onClick={onClickNext}>
        さらに表示
      </Card>
    )}
  </div>
);

const listItemStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
`;
