import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';

import { Button } from '../../../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../../../components/Caption';
import { Card } from '../../../../../../../../components/Card';
import { Checkbox } from '../../../../../../../../components/Checkbox';
import { CheckboxGroup, FormItem } from '../../../../../../../../components/Form';
import { Icon } from '../../../../../../../../components/Icon';
import { Modal } from '../../../../../../../../components/Modal';
import { GtmClasses } from '../../../../../../../../constants/AnalyticsTags';
import { FontSize } from '../../../../../../../../constants/Style';
import { useContext } from '../../../../../../../../hooks/useContext';
import { useTransaction } from '../../../../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../../../../stores/SessionStore';
import { SignInMethod } from '../../../../../../../Accounts/AccountsNew';
import { CancelPolicyContent } from '../../../../../../../Reservations/components/CancelPolicyContent';
import { LastWarningContent } from '../../../../../../../Reservations/components/LastWarningContent';
import { IndividualCheckupReservationStore } from '../../../stores/IndividualCheckupReservationStore';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const extraLargeStyle = css`
  font-size: ${FontSize.ExtraLarge};
  font-weight: 700;
`;

type Props = {
  open: boolean;
  handleStateChange: (newState: boolean) => void;
  onConfirm: (signInMethod?: SignInMethod) => void;
  disabled?: boolean;
};

export const IndividualCheckupReservationConfirmDialog = observer(
  ({ open, handleStateChange, onConfirm, disabled }: Props) => {
    const store = useContext(IndividualCheckupReservationStore.Context);
    const session = useContext(SessionStore.Context);

    const [cancelPolicyConcent, setCancelPolicyConcent] = useState(false);

    const { cancelPolicyInfo, isJustBeforeAction, visibleLastCaution } = store.cancelPolicies;
    const isJustBeforeReserve = !!store.details[0] && isJustBeforeAction(store.details[0].date);

    const [signInMethod, setSignInMethod] = useState<SignInMethod>('LINE');

    const handleConfirmClick = (method?: SignInMethod) => {
      method && setSignInMethod(method);
      if (isJustBeforeReserve) {
        store.cancelPolicies.setVisibleLastCaution(true);
        return;
      }
      session.authenticated ? onConfirm() : onConfirm(method);
    };

    const handleConfirmLastWarningClick = () => {
      session.authenticated ? onConfirm() : onConfirm(signInMethod);
    };

    if (store.details.length <= 0) {
      return (
        <Modal visible={open}>
          <Card>
            <Caption type="header">日付が選択されていません。</Caption>
            <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
              キャンセル
            </Button>
          </Card>
        </Modal>
      );
    }

    return (
      <>
        <Modal visible={open}>
          <Card width={375}>
            {!visibleLastCaution && (
              <div className={centerStyle}>
                <ConfirmSection hint={<></>} />
                {cancelPolicyInfo && (
                  <div className={cancelPolicySectionStyle}>
                    <CancelPolicyContent
                      store={store.cancelPolicies}
                      date={store.details[0].date}
                      actionType="reserve"
                    />
                    <>
                      <Checkbox
                        label="上記キャンセルポリシーに同意する"
                        checked={cancelPolicyConcent}
                        onChange={v => setCancelPolicyConcent(v.target.checked)}
                      />
                    </>
                  </div>
                )}
                {session.authenticated ? (
                  <AuthenticatedAction
                    onConfirm={handleConfirmClick}
                    handleStateChange={handleStateChange}
                    disabled={disabled || (!!store.cancelPolicies.cancelPolicyInfo && !cancelPolicyConcent)}
                    isJustBeforeReserve={isJustBeforeReserve}
                    departmentId={store.department?.id}
                    treatmentKindId={store.treatmentKind.id}
                  />
                ) : (
                  <UnAuthenticatedAction
                    onConfirm={handleConfirmClick}
                    handleStateChange={handleStateChange}
                    disabled={disabled || !cancelPolicyConcent}
                    isJustBeforeReserve={isJustBeforeReserve}
                    departmentId={store.department?.id}
                    treatmentKindId={store.treatmentKind.id}
                  />
                )}
              </div>
            )}
            {visibleLastCaution && (
              <LastWarningContent
                store={store.cancelPolicies}
                disabled={disabled}
                onConfirm={handleConfirmLastWarningClick}
                onCancel={() => handleStateChange(false)}
                confirmAnalyticsClassName={`${
                  session.authenticated ? GtmClasses.gtm.conversion.checkup : GtmClasses.gtm.temporallyReservation.def
                } ${store.department?.id} ${store.treatmentKind.id}`}
              />
            )}
          </Card>
        </Modal>
      </>
    );
  },
);

type ConfirmSectionProps = {
  hint?: ReactNode;
};

type ActionProps = Pick<Props, 'onConfirm' | 'handleStateChange' | 'disabled'> & {
  isJustBeforeReserve: boolean;
  departmentId?: string;
  treatmentKindId?: string;
};

const UnAuthenticatedAction = ({
  onConfirm,
  handleStateChange,
  disabled,
  isJustBeforeReserve,
  departmentId,
  treatmentKindId,
}: ActionProps) => (
  <ButtonGroup>
    <Button
      className={
        isJustBeforeReserve ? '' : `${GtmClasses.gtm.temporallyReservation.def} ${departmentId} ${treatmentKindId}`
      }
      block
      theme="line"
      type="button"
      onClick={() => onConfirm('LINE')}
      disabled={disabled}
    >
      <Icon name="line" />
      LINEログインで予約
    </Button>
    <Button block theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
      戻る
    </Button>
    <div className={legacySignUpButtonStyle} onClick={() => onConfirm('LEGACY')}>
      メールアドレスでログインして予約
    </div>
  </ButtonGroup>
);

const legacySignUpButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: ${FontSize.Regular};
  font-weight: bold;
  cursor: pointer;
`;

const AuthenticatedAction = observer(
  ({ onConfirm, handleStateChange, disabled, isJustBeforeReserve, departmentId, treatmentKindId }: ActionProps) => {
    const store = useContext(IndividualCheckupReservationStore.Context);
    const [loadConfirm, loadConfirmStatus] = useTransaction(() => store.fetchShouldConfirm());
    const [concent, setConcent] = useState(false);

    useEffect(() => {
      loadConfirm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.department]);

    return (
      <ButtonGroup>
        {!loadConfirmStatus.running && store.shouldConfirm ? (
          <FormItem>
            <div className={termsConfirmedSectionStyle}>
              <CheckboxGroup>
                <Checkbox
                  label={`DENTAL CLINIC TEN SHIBUYAおよび${store.department?.clinicName}への情報提供に同意する`}
                  checked={concent}
                  onChange={v => setConcent(v.target.checked)}
                />
                <div>
                  <a
                    style={{ textDecoration: 'underline' }}
                    href={store.department?.termsUrl || '/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {store.department?.clinicName}の利用規約を確認(別タブが開きます)
                  </a>
                </div>
                <div>
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://dental.clinicten.jp/term"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DENTAL CLINIC TEN SHIBUYAの利用規約を確認(別タブが開きます)
                  </a>
                </div>
              </CheckboxGroup>
            </div>
          </FormItem>
        ) : (
          <></>
        )}
        <Button
          disabled={loadConfirmStatus.running || (store.shouldConfirm && !concent) || disabled}
          type="button"
          onClick={() => onConfirm()}
          className={
            isJustBeforeReserve ? '' : `${GtmClasses.gtm.conversion.checkup} ${departmentId} ${treatmentKindId}`
          }
        >
          {isJustBeforeReserve ? '予約する' : '予約を完了する'}
        </Button>
        <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
          戻る
        </Button>
      </ButtonGroup>
    );
  },
);

const termsConfirmedSectionStyle = css`
  text-align: left;
`;

const ConfirmSection = observer(({ hint }: ConfirmSectionProps) => {
  const store = useContext(IndividualCheckupReservationStore.Context);
  if (store.details.length === 0) {
    throw new Error('unreachable code');
  }
  return (
    <CaptionGroup>
      <Caption type="subheader">以下の内容で予約しますか？</Caption>
      <hr />
      <Caption type="subheader">
        {format(store.details[0].date, 'yyyy年M月d日')}({WeekLabels[getDay(store.details[0].date)]})
      </Caption>
      <div className={extraLargeStyle}>
        {format(store.details[0].date, 'HH:mm')}
        <span className={smallTimeTildeStyle}> ～ </span>
        <span className={smallTimeStyle}>
          [所要{store.displayDuration}
          分]
        </span>
      </div>
      <div>CLINIC TEN SHIBUYA / {store.department?.name}</div>
      <Caption type="small">{hint}</Caption>
    </CaptionGroup>
  );
});

const smallTimeStyle = css`
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const cancelPolicySectionStyle = css`
  margin-bottom: 16px;
  font-weight: 300;
  text-align: left;
`;
