import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../../components/Caption';
import { Card } from '../../../../../../../components/Card';
import { Modal } from '../../../../../../../components/Modal';

type Props = {
  open?: boolean;
  onConfirm?: () => void;
};

export const WarningBeforeLogin = observer(({ open, onConfirm }: Props) => (
  <Modal visible={!!open}>
    <Card>
      <Caption type="header">LINE友だち登録が必須です</Caption>
      <ul>
        <li>LINEログイン後、LINE友だち追加ボタンを必ず押してください。</li>
        <li>診療カルテ連携にLINE友だち追加が必須です。</li>
      </ul>
      <ButtonGroup gutter={10}>
        <Button block onClick={onConfirm}>
          了解した
        </Button>
      </ButtonGroup>
    </Card>
  </Modal>
));
