import gql from 'graphql-tag';

import { TreatmentKindType } from '../fragments/treatmentKind';

export const getNextTreatmentPlan = gql`
  query getNextTreatmentPlan($input: GetNextTreatmentPlanQueryInput!) {
    getNextTreatmentPlan(input: $input) {
      ...TreatmentKindType
    }
  }
  ${TreatmentKindType}
`;
