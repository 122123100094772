import { css } from 'emotion';
import React, { RefCallback, useCallback, useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { FormItem, FormLayout, FormSubLabel } from '../../../../../../components/Form';
import { Option, Select } from '../../../../../../components/Select';
import { TextField } from '../../../../../../components/TextField';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';
import { Prefectures } from '../../../../../../constants/Prefectures';
import { Address } from '../../../../../../domains/user/Address';
import { gutter } from '../../../../../../helpers/Style';
import { useZipCode } from '../../../../../../hooks/useZipCode';

type Props = {
  value: Address;
  onChange?: (v: Address) => void;
};
export const AddressInput = ({ value, onChange }: Props) => {
  const getZipCode = useZipCode();
  const [line1, setLine1] = useState<HTMLInputElement | undefined>(undefined);
  // const [line2, setLine2] = useState<HTMLInputElement>();
  const refLine1: RefCallback<HTMLInputElement> = useCallback(node => {
    node && setLine1(node);
  }, []);
  // const refLine2: RefCallback<HTMLInputElement> = useCallback(node => {
  //   node && setLine2(node);
  // }, []);
  return (
    <FormLayout>
      <FormItem>
        <FormSubLabel>郵便番号</FormSubLabel>
        <div className={zipStyle}>
          <div>
            <TextField
              value={value.zipCode}
              onChange={v => onChange?.({ ...value, zipCode: v })}
              color="white"
              analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.postCode}
            />
          </div>
          <div>
            <Button
              onClick={() => {
                getZipCode(value.zipCode).then(res => {
                  onChange?.({
                    zipCode: res.zipCode,
                    pref: res.pref,
                    addressLine1: res.addressLine1,
                    addressLine2: value.addressLine2,
                  });
                  line1 && line1.focus();
                });
              }}
            >
              郵便番号から住所を取得
            </Button>
          </div>
        </div>
      </FormItem>
      <FormItem>
        <FormSubLabel>都道府県</FormSubLabel>
        <Select
          color="white"
          value={value.pref || '東京都'}
          onChange={v => onChange?.({ ...value, pref: v })}
          analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.prefecture}
        >
          {Prefectures.map(pref => (
            <Option value={pref} key={pref}>
              {pref}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem>
        <FormSubLabel>市区町村・番地</FormSubLabel>
        <TextField
          color="white"
          value={value.addressLine1}
          onChange={v => onChange?.({ ...value, addressLine1: v })}
          refCallback={refLine1}
        />
      </FormItem>
      <FormItem>
        <FormSubLabel>建物名・部屋番号</FormSubLabel>
        <TextField
          color="white"
          // refCallback={refLine2}
          value={value.addressLine2}
          onChange={v => onChange?.({ ...value, addressLine2: v })}
        />
      </FormItem>
    </FormLayout>
  );
};

const zipStyle = css`
  display: grid;
  grid-template-columns: 96px 1fr;
  column-gap: ${gutter(4)};
`;
