import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { Caption } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Icon } from '../../../components/Icon';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { gutter } from '../../../helpers/Style';
import { useContext } from '../../../hooks/useContext';
import { useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { WindowUtil } from '../../../utils/WindowUtil';
import { TreatmentCardListLayout } from './components/TreatmentCardListLayout';
import { TreatmentListStore } from './stores/TreatmentListStore';

export const TreatmentsListPage = observer(() => {
  const store = useMemo(() => new TreatmentListStore(), []);
  const sessionStore = useContext(SessionStore.Context);
  const [doFetchNext, fetchNextStatus] = useTransaction(() => store.fetchNext());

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    store.setInitial(sessionStore.user.userId, []);
    doFetchNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  // 全ダウンロード
  const [doDownload, downloadLinkStatus] = useTransaction(async () => {
    const url = await store.downloadAllReceipts();
    if (!url) {
      return;
    }
    const downLoadLink = document.createElement('a');
    downLoadLink.download = '添付ファイル.zip';
    downLoadLink.href = url;
    downLoadLink.click();
  });

  const [doLoadDownloadLink, loadDownloadLinkStatus] = useTransaction(async (key: string) => {
    const url = await store.fetchDownloadableLink(key);
    if (!url) {
      return;
    }
    WindowUtil.download(url, '添付ファイル.pdf');
  });

  return (
    <Wrapper>
      <DocumentTitle title="診察履歴・領収書一覧" />
      <Caption type="header" underline align="center">
        診察履歴・領収書一覧
      </Caption>
      <div className={buttonContainerStyle}>
        <Link to={Routes.Paths.Receipts}>
          <Button block ghost className={buttonStyle}>
            <div>領収書をダウンロード</div>
            <Icon name="angle-right" />
          </Button>
        </Link>
      </div>

      <TreatmentCardListLayout
        treatments={store.list}
        hasNext={!fetchNextStatus.running && !!store.nextToken}
        onClick={doLoadDownloadLink}
        onClickNext={() => doFetchNext()}
        onDownloadAll={() => doDownload()}
        downloadDisabled={downloadLinkStatus.running}
      />
      <Modal visible={!!loadDownloadLinkStatus.running}>
        <Card>ファイルをダウンロード中</Card>
      </Modal>
    </Wrapper>
  );
});

const buttonContainerStyle = css`
  width: 100%;
  margin-bottom: ${gutter(4)};
`;

const buttonStyle = css`
  position: relative;

  > svg {
    position: absolute;
    right: ${gutter(4)};
  }
`;
