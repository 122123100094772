import { ValueOf } from '../interface';

export const Color = {
  // Brand
  BrandTenBlack: '#2D1F20',
  BrandTenNavy: '#000025',
  BrandTenBeige: '#BDB8AB',
  BrandTenBrown: '#2E1900',
  BrandTenPink: '#F1C8B9',
  BrandTenCharcoalGray: '#575153',
  BrandLine: '#06B805',
  BrandGoogle: '#1a73e8',

  // Grayscale
  GrayscaleDarker: '#2D1F20',
  GrayscaleDark: '#615758',
  GrayscaleNeutral: '#968F8F',
  GrayscaleCommon: '#A3A3A3',
  GrayscaleLight: '#E4E3E3',
  GrayscaleLighter: '#F5F4F4',
  GrayscaleWhite: '#FFFFFF',
  GrayscaleSns: '#999999',

  // Functional
  FunctionalRedNeutral: '#EB5757',
  FunctionalRedLight: '#FFE6E6',
  FunctionalBlueNeutral: '#2CA9E1',
  FunctionalBlueLight: '#EFFAFF',
  FunctionalGreenNeutral: '#27AE60',
  FunctionalGreenLight: '#EFFFF6',
  FunctionalYellowNeutral: '#FFE39C',
  FunctionalYellowLight: '#FFF3D3',
  FunctionalYellowLighter: '#FFFBF1',
  FunctionalOrangeNeutral: '#FF7B35',

  // Texture
  TextureBody: '#F5F4F4',
  TextureInput: '#F5F4F4',

  // Result
  NotingResult: '#309E14',
  NotingResultBackground: '#E0F0DB',
  AttentionResult: '#E7B400',
  AttentionResultBackground: '#F2ECDE',
  ConsultationResult: '#A3006C',
  ConsultationResultBackground: '#FFECF8',
  TreatmentResult: '#DB0D00',
  TreatmentResultBackground: '#FFEEED',
} as const;
export type Color = ValueOf<typeof Color>;

export const FontSize = {
  MaximLarge: '40px',
  ExtraLarge: '24px',
  Large: '20px',
  MediumRegular: '18px',
  Medium: '16px',
  SmallMedium: '15px',
  Regular: '14px',
  SmallRegular: '13px',
  Small: '12px',
  Tiny: '11px',
  Nano: '10px',
} as const;

export const LineHeight = {
  Medium: 1.8,
  Regular: 1.6,
  Compressed: 1.4,
} as const;

export const FontFamily = {
  Regular: 'proxima-nova, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif',
} as const;

export const BorderRadius = {
  Circle: '999em',
  Regular: '5px',
  Small: '2px',
} as const;

export const IconSize = {
  ExtraLarge: '36px',
  Large: '24px',
  Regular: '20px',
} as const;

export const Dimension = {
  GlobalNavigationWidth: 248,
  ContentWidthMax: 576,
} as const;

export const Breakpoint = {
  ScreenXs: 480,
  ScreenSm: 600,
  ScreenMd: 1024,
} as const;

export const ZIndex = {
  GlobalHeader: 10,
  GlobalNavigation: 20,
  Modal: 100,
} as const;

export const Duration = {
  Fade: '0.15s',
  Enter: '0.25s',
  Leave: '0.3s',
} as const;

export const Easing = {
  Enter: 'cubic-bezier(.11, .57, .14, 1)',
  Leave: 'cubic-bezier(0, .14, .75, 1)',
  Filter: 'cubic-bezier(0, 2.5, 0.2, 2.5)',
} as const;
