import { css, cx } from 'emotion';
import React, { ReactNode } from 'react';

import { BorderRadius, Color, Duration, FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';

type Props = Partial<{
  children: ReactNode;
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  color?: 'white' | 'grey';
  analyticsClassName: string;
}>;

export const Select = ({ color = 'grey', children, value, onChange, placeholder, analyticsClassName }: Props) => (
  <label className={containerStyle}>
    <select
      className={`${analyticsClassName} ${placeholder && !value ? emptyStyle(style[color]) : style[color]}`}
      value={value}
      onChange={v => onChange?.(v.currentTarget.value)}
    >
      {placeholder && <option className={invisibleStyle}>{placeholder}</option>}
      {children}
    </select>
  </label>
);

const invisibleStyle = css`
  display: none;
`;

const containerStyle = css`
  position: relative;
  display: block;
  width: 100%;

  &::after {
    position: absolute;
    top: 50%;
    right: 20px;
    display: block;
    ${square(IconSize.Regular)}

    color: ${Color.GrayscaleDarker};
    pointer-events: none;
    content: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9IiM5NjhGOEYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2Ljk5OTkgOS4xNjk3QzE2LjgxMjYgOC45ODM0NSAxNi41NTkxIDguODc4OTEgMTYuMjk0OSA4Ljg3ODkxQzE2LjAzMDggOC44Nzg5MSAxNS43NzczIDguOTgzNDUgMTUuNTg5OSA5LjE2OTdMMTEuOTk5OSAxMi43MDk3TDguNDU5OTUgOS4xNjk3QzguMjcyNTkgOC45ODM0NSA4LjAxOTEzIDguODc4OTEgNy43NTQ5NSA4Ljg3ODkxQzcuNDkwNzYgOC44Nzg5MSA3LjIzNzMxIDguOTgzNDUgNy4wNDk5NSA5LjE2OTdDNi45NTYyMiA5LjI2MjY2IDYuODgxODMgOS4zNzMyNiA2LjgzMTA2IDkuNDk1MTJDNi43ODAyOSA5LjYxNjk4IDYuNzU0MTUgOS43NDc2OSA2Ljc1NDE1IDkuODc5N0M2Ljc1NDE1IDEwLjAxMTcgNi43ODAyOSAxMC4xNDI0IDYuODMxMDYgMTAuMjY0M0M2Ljg4MTgzIDEwLjM4NjEgNi45NTYyMiAxMC40OTY3IDcuMDQ5OTUgMTAuNTg5N0wxMS4yODk5IDE0LjgyOTdDMTEuMzgyOSAxNC45MjM0IDExLjQ5MzUgMTQuOTk3OCAxMS42MTU0IDE1LjA0ODZDMTEuNzM3MiAxNS4wOTk0IDExLjg2NzkgMTUuMTI1NSAxMS45OTk5IDE1LjEyNTVDMTIuMTMyIDE1LjEyNTUgMTIuMjYyNyAxNS4wOTk0IDEyLjM4NDUgMTUuMDQ4NkMxMi41MDY0IDE0Ljk5NzggMTIuNjE3IDE0LjkyMzQgMTIuNzA5OSAxNC44Mjk3TDE2Ljk5OTkgMTAuNTg5N0MxNy4wOTM3IDEwLjQ5NjcgMTcuMTY4MSAxMC4zODYxIDE3LjIxODggMTAuMjY0M0MxNy4yNjk2IDEwLjE0MjQgMTcuMjk1NyAxMC4wMTE3IDE3LjI5NTcgOS44Nzk3QzE3LjI5NTcgOS43NDc2OSAxNy4yNjk2IDkuNjE2OTggMTcuMjE4OCA5LjQ5NTEyQzE3LjE2ODEgOS4zNzMyNiAxNy4wOTM3IDkuMjYyNjYgMTYuOTk5OSA5LjE2OTdaIiBmaWxsPSIjOTY4RjhGIj48L3BhdGg+PC9zdmc+');
    transform: translate(80%, -50%);

    > svg {
      ${square(IconSize.Regular)}

      color: ${Color.GrayscaleDarker};
    }
  }
`;

const baseStyle = css`
  position: relative;
  display: block;
  width: 100%;
  padding: ${gutter(4)};
  font-size: ${FontSize.Medium};
  line-height: 1;
  background-color: ${Color.TextureInput};
  border: none;
  border-radius: ${BorderRadius.Small};
  outline: none;
  box-shadow: none;
  transition: box-shadow ${Duration.Fade};
  appearance: none;

  &:focus {
    box-shadow: 0 0 0 2px ${Color.GrayscaleDarker};
  }

  &:disabled {
    color: ${Color.GrayscaleNeutral};
    cursor: not-allowed;
  }
`;

const emptyStyle = (style: string) =>
  cx(
    style,
    css`
      &:not(:focus) {
        color: ${Color.GrayscaleNeutral};
      }
    `,
  );

const whiteStyle = cx(
  baseStyle,
  css`
    background-color: ${Color.GrayscaleWhite};
  `,
);

const style = {
  white: whiteStyle,
  grey: baseStyle,
} as const;

type OptionProps = Partial<{
  value: string;
  children: ReactNode;
}>;
export const Option = ({ value, children }: OptionProps) => <option value={value}>{children}</option>;
