import gql from 'graphql-tag';

import { CheckupResultItemSubGroupType } from './checkupResultItemSubGroup';

export const CheckupResultJudgedSubGroupType = gql`
  fragment CheckupResultJudgedSubGroupType on CheckupResultJudgedSubGroup {
    result
    comment
    subGroupId
    subGroup {
      ...CheckupResultItemSubGroupType
    }
    departmentId
    items {
      result
      itemId
      item {
        unit
        displayName
        displayStrategy
        halfName
        pairName
        description
        criteriaChart {
          min
          max
          result
        }
      }
      valueStr
    }
  }
  ${CheckupResultItemSubGroupType}
`;
