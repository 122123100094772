import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';

import { useContext } from '../../hooks/useContext';
import { ExtendedDictionaryStore } from '../../stores/ExtendedDictionaryStore';

type Props = {
  children: ReactNode;
  dictKey: string;
  input: any;
};
export const Dictionary = observer(({ children, dictKey: key, input }: Props) => {
  const store = useContext(ExtendedDictionaryStore.Context);
  const memo = computed(() => store.render(key, input)).get();
  return <>{memo || children}</>;
});

export const DictionaryKeys = {
  RESERVATION_COMPLETE_BODY: 'reservationComplete.hint',
} as const;
