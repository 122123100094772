import { urlSafeDecode } from '@aws-amplify/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { useError } from '../../../../../components/ErrorDialog';
import { Modal } from '../../../../../components/Modal';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import { CheckupReservationStore } from '../stores/CheckupReservationStore';
import { CheckupReservationStep } from './components/CheckupReservationStep';

type Props = {
  onNext?: () => void;
};

export const CheckupReservationStartPage = observer(({ onNext }: Props) => {
  const store = useContext(CheckupReservationStore.Context);
  const session = useContext(SessionStore.Context);
  const { setError } = useError();

  const [load, loadStatus] = useTransaction(async () => {
    const id = new URLSearchParams(window.location.search).get('checkupOrganizationMemberEventId');
    if (!id) {
      setError({
        message: '不正なURLです。企業から送付されたURLから入りなおしてください。',
        onOk: () => {
          window.location.replace('/');
        },
      });
      return;
    }
    try {
      await store.fetchCheckupInfo(urlSafeDecode(id));
    } catch (e) {
      setError({
        message: (e as any).message || e,
        onOk: () => {
          window.location.replace('/');
        },
      });
    }
    await session.fetchUserEntity();
    if (!session.user?.userEntity) {
      setError({
        message: 'ユーザー登録がまだ完了していません。先に氏名を登録してください。',
        onOk: () => {
          window.location.replace(
            `${Routes.Paths.AccountName}?checkupOrganizationMemberEventId=${id}&action=startCheckup`,
          );
        },
      });
    }
  });

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <DocumentTitle title="健康診断ご予約の流れ" />
      <Modal visible={!!loadStatus.running}>
        <Card>健診情報を取得中</Card>
      </Modal>
      <Caption type="header" align="center" underline>
        健康診断ご予約の流れ
      </Caption>
      {Steps.map((Step, key) => (
        <CheckupReservationStep
          key={key}
          href={Step.href}
          header={Step.header}
          body={Step.body}
          info={Step.info}
          alt={Step.alt}
        />
      ))}
      <ButtonGroup gutter={10}>
        <Button block onClick={onNext}>
          次へ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});

const Steps = [
  {
    href: '/images/checkup-1.svg',
    body: '所属と氏名を確認した後に、事前問診にお答えください。',
    header: '1.事前問診(1-2分)に答える',
    info: [],
    alt: '事前問診のイラスト',
  },
  {
    href: '/images/checkup-2.svg',
    header: '2.追加検査オプションの選択',
    body: '事前問診から、あなたに合った追加検査オプションを選択することができます。',
    info: ['※検査結果は1ヶ月程度でお知らせします。余裕をもってご予約ください。'],
    alt: '検査のイラスト',
  },
  {
    href: '/images/checkup-3.svg',
    header: '3.予約日時の選択',
    body: '氏名、生年月日、受診されるコース、検査オプション選択後に予約日時を選択していただきます。',
    info: [],
    alt: 'カレンダーのイラスト',
  },
];
