import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { ComponentProps, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { FormItem } from '../../../../components/Form';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { Color } from '../../../../constants/Style';
import { useContext } from '../../../../hooks/useContext';
import { CardInputLayout } from '../components/CardInputLayout';
import { AccountDetailStore } from '../stores/AccountDetailStore';

type Callback = ComponentProps<typeof CardInputLayout>['callback'];

export const SettlementEditPage = observer(() => {
  const store = useContext(AccountDetailStore.Context);
  const { push } = useHistory();
  const { addToast } = useToast();

  const callback: Callback = useCallback(async input => {
    await store.updateCard({ ...input, id: input.payjpID });
    addToast({ message: '支払方法を更新しました。', icon: 'check-circle' });
    const url = new URLSearchParams(window.location.search).get('url');
    push(url || Routes.Paths.Account);
  }, []);

  const onSubmit = useRef<() => void>();

  return (
    <Wrapper>
      <DocumentTitle title="決済情報編集" />
      <Caption type="header" underline align="center">
        決済情報
      </Caption>
      <CardInputLayout
        callback={callback}
        onSubmit={onSubmit}
        onStatusChange={s => store.edit?.cardStatus.setCardTransactionStatus(s)}
        onPreCardValidateChange={v => store.edit?.cardStatus.setPreCardValidate(v)}
      />
      {store.edit?.cardStatus.cardTransactionStatus?.error && (
        <div className={errorStyle}>{store.edit.cardStatus.cardTransactionStatus.error}</div>
      )}
      <FormItem>
        <ButtonGroup gutter={4}>
          <Button
            onClick={() => onSubmit.current?.()}
            disabled={store.edit?.cardStatus.cardTransactionStatus?.running || store.edit?.cardStatus.hasPreCardError}
          >
            この内容で保存する
          </Button>
        </ButtonGroup>
      </FormItem>
    </Wrapper>
  );
});

const errorStyle = css`
  margin-top: 8px;
  color: ${Color.FunctionalRedNeutral};
`;
