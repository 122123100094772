import { css } from 'emotion';
import React, { ComponentProps, useMemo } from 'react';

import { Maybe } from '../../../../../../../../gql/gql-types';
import { Icon } from '../../../../../../../components/Icon';
import { Breakpoint, Color, Dimension, FontSize, IconSize } from '../../../../../../../constants/Style';
import { square } from '../../../../../../../helpers/Style';
import { useMedia } from '../../../../../../../hooks/useMedia';
import { DepartmentCard } from '.';
import { formatNearestAvailable } from './DepartmentCardFooter';

type Props = Pick<ComponentProps<typeof DepartmentCard>['department'], 'icon' | 'name'> & { nearest?: Maybe<Date> };

export const DepartmentCardHeader = ({ icon, name, nearest }: Props) => {
  const sp = useMedia([{ query: `(max-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);
  const displayDate = useMemo(
    () => (nearest ? formatNearestAvailable(nearest, new Date(Date.now())) : { date: '', time: '' }),
    [nearest],
  );
  return (
    <header className={header}>
      <div className={iconDepartmentWrapper}>
        <div className={departmentIcon}>
          <Icon name={icon} />
        </div>
        <div className={departmentName}>{name}</div>
      </div>
      <div className={cardArrow}>
        {sp && displayDate.date && (
          <div className={nearestDisplay}>
            {displayDate.date}
            {displayDate.day ? `(${displayDate.day})` : ''}
            {displayDate.time}
          </div>
        )}
        <Icon name="angle-right" />
      </div>
    </header>
  );
};

const nearestDisplay = css`
  font-size: ${FontSize.Medium};
  color: ${Color.FunctionalRedNeutral};
`;

const header = css`
  display: flex;
  justify-content: space-between;
`;

const iconDepartmentWrapper = css`
  @media (max-width: ${Breakpoint.ScreenXs}px) {
    display: flex;
  }
`;

const departmentName = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.BrandTenBlack};
  white-space: pre-wrap;
`;

const cardArrow = css`
  display: flex;
  align-items: center;

  > svg {
    ${square(IconSize.Large)}

    fill: ${Color.GrayscaleNeutral};
  }
`;
const departmentIcon = css`
  > svg {
    ${square(IconSize.Large)}
    @media (max-width: ${Breakpoint.ScreenXs}px) {
      display: none;
      ${square(IconSize.Regular)}
    }
  }
`;
