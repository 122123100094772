import { Maybe } from '../../gql/gql-types';

type UnsafeMaybe<X> = Maybe<X> | undefined;

export const safeFilter = <X>(arr: UnsafeMaybe<UnsafeMaybe<X>[]>): X[] => (arr || []).filter(safePredicate);
const safePredicate = <X>(elem: UnsafeMaybe<X>): elem is X => !!elem;

export type ElementOf<X> = X extends Array<infer R> ? R : never;

export const orderBy = <X>(arr: X[], extract: (i: X) => string) =>
  arr.slice(0).sort((a, b) => (extract(a) > extract(b) ? 1 : -1));

export class OrderByBuilder<X> {
  public compareTo: (a: X, b: X) => number = () => 0;

  public sort(arr: X[]) {
    return arr.slice(0).sort((a, b) => this.compareTo(a, b));
  }

  public asc(extractKey: (elem: X) => any) {
    const res = new OrderByBuilder<X>();
    res.compareTo = (a, b) => {
      if (this.compareTo(a, b) !== 0) {
        return this.compareTo(a, b);
      }
      return extractKey(a) > extractKey(b) ? 1 : extractKey(a) === extractKey(b) ? 0 : -1;
    };
    return res;
  }

  public desc(extractKey: (elem: X) => any) {
    const res = new OrderByBuilder<X>();
    res.compareTo = (a, b) => {
      if (this.compareTo(a, b) !== 0) {
        return this.compareTo(a, b);
      }
      return extractKey(a) > extractKey(b) ? -1 : extractKey(a) === extractKey(b) ? 0 : 1;
    };
    return res;
  }
}

type Group<X> = {
  [key: string]: X[];
};
export const groupBy = <X>(arr: X[], extract: (i: X) => string): Group<X> =>
  arr.reduce(
    (res: Group<X>, elem) => ({
      ...res,
      [extract(elem)]: [...(extract(elem) in res ? res[extract(elem)] : []), elem],
    }),
    {},
  );
