import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Alignment, Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { CheckboxGroup, FormItem, FormLabel, FormLayout } from '../../../../../../components/Form';
import { TextArea } from '../../../../../../components/TextArea';
import { Wrapper } from '../../../../../../components/Wrapper';
import { useContext } from '../../../../../../hooks/useContext';
import { CheckupOptionCheckbox } from '../../../../Reservations/CheckupReservationsNew/CheckupReservationBasicInfo/components/CheckupOptionCheckbox';
import { CheckupVacancyStore } from '../../../../Reservations/CheckupReservationsNew/CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { IndividualCheckupReservationStore } from '../../IndividualCheckupReservationsNew/stores/IndividualCheckupReservationStore';

type Props = {
  onNext?: () => void;
};
export const IndividualCheckupReservationEditOptionSelectPage = observer(({ onNext }: Props) => {
  const store = useContext(IndividualCheckupReservationStore.Context);

  useEffect(() => {
    store.fetchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // make cache ahead of user page transition
  const week = useMemo(() => new Date(), []);
  const vacancyStore = useContext(CheckupVacancyStore.Context);
  useEffect(() => {
    store.department &&
      vacancyStore.fetchVacancies(
        week,
        store.department,
        store.options.map(o => o.id),
        store.treatmentKind,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.options]);

  return (
    <Wrapper>
      <FormLayout>
        <FormItem>
          <FormLabel required>キット送付先の住所</FormLabel>
          <TextArea color="white" value={store.kitDeliveryAddress} onChange={v => store.setKitDeliveryAddress(v)} />
        </FormItem>
        <FormItem>
          <FormLabel>追加されるオプションを選択してください</FormLabel>
          <Card>
            <CheckboxGroup>
              {store.selectableOptions
                .filter(o => o.suggestText)
                .map((option, key) => (
                  <CheckupOptionCheckbox
                    price={option.regularPrice}
                    discountPrice={option.price !== option.regularPrice ? option.price : undefined}
                    label={option.name}
                    value={option.id}
                    checked={store.options.some(o => o.id === option.id)}
                    onChange={v => (v.target.checked ? store.addOption(option) : store.removeOption(option))}
                    key={key}
                    suggestText={option.suggestText}
                    billingType={option.billType}
                    suggestSeverity={option.suggestSeverity}
                  />
                ))}
            </CheckboxGroup>
          </Card>
        </FormItem>
        <FormItem>
          <div>その他のオプション</div>
          <Card>
            <CheckboxGroup>
              {store.selectableOptions
                .filter(o => !o.suggestText)
                .map((option, key) => (
                  <CheckupOptionCheckbox
                    price={option.regularPrice}
                    discountPrice={option.price !== option.regularPrice ? option.price : undefined}
                    label={option.name}
                    value={option.id}
                    checked={store.options.some(o => o.id === option.id)}
                    onChange={v => (v.target.checked ? store.addOption(option) : store.removeOption(option))}
                    key={key}
                    suggestText={option.suggestText}
                    billingType={option.billType}
                  />
                ))}
            </CheckboxGroup>
          </Card>
        </FormItem>
        <FormItem>
          <Card>
            <Caption type="subheader">合計負担額(税込み)</Caption>
            <Alignment align="right">
              {`¥ ${store.options
                .filter(o => o.billType !== '1_organization')
                .map(o => o.price)
                .reduce((a, b) => a + b, 0)
                .toLocaleString()} -`}
            </Alignment>
          </Card>
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={16}>
        <Button onClick={onNext}>予約日の選択へ</Button>
      </ButtonGroup>
    </Wrapper>
  );
});
