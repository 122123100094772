import React, { ComponentProps, useEffect, useState } from 'react';

import { ErrorLabel, FormLabel } from '../Form';
import { TextField } from '../TextField';
import { InterviewReactiveFormItem } from '.';

type Prop = ComponentProps<typeof InterviewReactiveFormItem> & {
  type?: 'text' | 'number' | 'date' | 'hidden';
};

export const InterviewInput = ({ def, value, onChange, type = 'text', errors, analyticsClassName }: Prop) => {
  const [state, setState] = useState(value[0]?.value);

  useEffect(() => {
    if (!def.options || def.options.length <= 0 || type !== 'hidden') {
      return;
    }

    if (value[0]?.value === def.options[0]?.value) {
      return;
    }

    onChange([
      { id: String(def.options[0]?.id || 0), label: def.options[0]?.label || '', value: def.options[0]?.value || '' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [def.options, value]);

  useEffect(() => {
    if (!value[0]?.value) {
      return;
    }
    setState(value[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value[0]?.value]);

  const onBlur = () => {
    onChange([{ id: String(def.options?.[0]?.id || 0), label: def.options?.[0]?.label || '', value: state }]);
  };

  return (
    <>
      {type === 'hidden' ? (
        <></>
      ) : (
        <>
          <FormLabel required={!!def.required}>{def.label}</FormLabel>
          <TextField
            formatStr={type === 'date' ? 'yyyy-MM-dd' : undefined}
            color="white"
            type={type}
            value={state}
            onFocusout={onBlur}
            onChange={(v: string) => setState(v)}
            error={!!errors}
            analyticsClassName={analyticsClassName}
          />
          <ErrorLabel errors={errors} />
        </>
      )}
    </>
  );
};
