import { css } from 'emotion';
import React from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Icon } from '../../../../../components/Icon';
import { Map } from '../../../../../components/Map';
import { Color, FontSize } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';

type Props = {
  clinic: {
    id?: string;
    lat: number;
    lng: number;
    name: string;
    address: string;
    mapUrl: string;
  };
};
export const ClinicMap = ({ clinic }: Props) => (
  <>
    <Map lat={clinic.lat} lng={clinic.lng} type={clinic.id === '02' ? 'dental' : 'clinic'} /> {/** FIXME* */}
    <div className={accessCaptionStyle}>
      <div className={clinicStyle}>{clinic.name}</div>
      <p>{clinic.address}</p>
    </div>
    <ButtonGroup gutter={20}>
      <Button
        ghost
        theme="google"
        onClick={() => window.open(clinic.mapUrl || `https://www.google.co.jp/maps?q=${clinic.lat},${clinic.lng}`)}
      >
        <Icon name="map-marker" />
        Google Mapで見る
      </Button>
    </ButtonGroup>
  </>
);

const accessCaptionStyle = css`
  margin: ${gutter(4)} 0;
`;

const clinicStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.GrayscaleDarker};
`;
