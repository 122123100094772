import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Maybe } from '../../../../../../../gql/gql-types';
import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { ErrorSummaryItem, FormLayout } from '../../../../../../components/Form';
import { InterviewReactiveFormItem } from '../../../../../../components/InterviewForm';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Breakpoint, Dimension } from '../../../../../../constants/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { ScrollToElementContext, useScrollTo } from '../../../../../../hooks/useScrollToElement';
import { FormStore } from '../../../../../../stores/FormStore';
import { safeFilter } from '../../../../../../utils/CollectionUtil';
import { InterviewProgress } from '../../../../../Interviews/InterviewsNew/InterviewsInput/components/InterviewProgress';
import { IndividualCheckupReservationStore } from '../../IndividualCheckupReservationsNew/stores/IndividualCheckupReservationStore';

type Props = {
  onNext?: () => void;
  onBack?: () => void;
};

export const IndividualCheckupReservationPreInterview = observer(({ onNext, onBack }: Props) => {
  const store = useContext(IndividualCheckupReservationStore.Context);
  const form = useMemo(() => new FormStore(), []);

  useEffect(() => {
    form.items.length === 0 &&
      form.setItems(
        (store.preInterview?.questions || []).map(q => ({
          id: String(q?.id),
          name: q?.label || '',
          validator: v => (!v && q?.required ? '入力してください。' : null),
        })),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.preInterview?.questions]);

  useEffect(() => {
    form.setValue(
      (store.preInterview?.questions || []).reduce(
        (prev, elem) => ({
          ...prev,
          [String(elem?.id)]: elem?.answer?.map(a => a?.answer).join(','),
        }),
        {},
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.preInterview?.questions]);

  const errors: { [key: string]: Maybe<string> } = useMemo(
    () => form.displayErrors.reduce((prev, elem) => ({ ...prev, [elem.id]: elem.error }), {}),
    [form.displayErrors],
  );

  const { scrollMap, scrollTo } = useScrollTo();

  return (
    <>
      <div className={interviewProgressStyle}>
        <InterviewProgress
          answered={store.preInterview?.questions?.filter(q => q?.answer?.filter(x => x?.answer)?.length)?.length || 0}
          total={store.preInterview?.questions?.length || 10}
        />
      </div>
      <Wrapper>
        <CaptionGroup>
          <Caption type="header" underline align="center">
            オプション選択の問診
          </Caption>
        </CaptionGroup>
        <FormLayout>
          <ScrollToElementContext.Provider value={scrollMap}>
            {store.preInterview?.questions?.map((q, key) => (
              <InterviewReactiveFormItem
                key={key}
                def={{
                  id: q?.id,
                  label: q?.label,
                  options: q?.options,
                  required: q?.required,
                }}
                onChange={v => store.setPreInterviewAnswer(key, v)}
                value={safeFilter(q?.answer || []).map(a => ({
                  id: String(a.option?.id || 0),
                  label: safeFilter(q?.options).find(o => o.id === a.option?.id)?.label || '',
                  value: a.answer || '',
                }))}
                errors={errors[String(q?.id)] || undefined}
              />
            ))}
          </ScrollToElementContext.Provider>
        </FormLayout>

        {form.displayErrors.length > 0 && (
          <div className={errorSummaryContainerStyle}>
            {form.displayErrors.map((e, key) => (
              <ErrorSummaryItem key={key} errors={e.error} label={e.name} onClick={() => scrollTo(e.id)} />
            ))}
          </div>
        )}

        <ButtonGroup gutter={16}>
          <Button onClick={() => (form.valid ? onNext?.() : form.setDirtyAll())}>
            {form.valid ? '次へ' : '未入力項目があります'}
          </Button>
          <Button ghost onClick={onBack}>
            戻る
          </Button>
        </ButtonGroup>
      </Wrapper>
    </>
  );
});

const interviewProgressStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: ${Dimension.GlobalNavigationWidth}px;
  }
`;

const errorSummaryContainerStyle = css`
  margin-top: 27px;
`;
