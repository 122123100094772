import { History, Location } from 'history';

import { Routes } from '../../../../constants/Routes';

export const UseToOtherSubGroup = (
  subGroupId: string,
  history: History<unknown> | string[],
  params: { treatmentId: any },
  location: Location<unknown>,
) => {
  history.push(
    Routes.Paths.CheckupResultBase.replace(':subGroupId', subGroupId).replace(
      ':treatmentId',
      params.treatmentId + location.hash,
    ),
  );
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
