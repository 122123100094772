import { css, cx } from 'emotion';
import React, { ChangeEvent, ReactNode } from 'react';

import { BorderRadius, Color, FontSize, LineHeight } from '../../constants/Style';
import { square } from '../../helpers/Style';

type Props = {
  name: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  /**
   * ラベルの前に表示する要素
   */
  children?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;

  suffix?: ReactNode;
  analyticsClassName?: string;
};

export const Radio = ({
  name,
  label = '',
  checked = false,
  disabled,
  value,
  children,
  onChange,
  suffix,
  analyticsClassName,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => !disabled && onChange?.(e);
  return (
    <label className={`${baseStyle} ${analyticsClassName}`}>
      <input
        className={inputStyle}
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={handleChange}
      />
      <span className={cx(indicatorStyle, checked && indicatorActiveStyle)}>
        <span className={checked ? symbolCheckedStyle : undefined} />
      </span>
      <span className={labelStyle}>
        {children ? <span className={prefixStyle}>{children}</span> : null}
        {label}
        {suffix ? <span className={suffixStyle}>{suffix}</span> : null}
      </span>
    </label>
  );
};

const baseStyle = css`
  position: relative;
  display: inline-grid;
  grid-template-columns: 20px auto;
  align-items: center;
  cursor: pointer;
`;

const inputStyle = css`
  /* 視覚上見えないが操作可能な Style 指定 */

  /* 参考: https://github.com/twbs/bootstrap/blob/a4a04cd9ec741050390746f8056cc79a9c04c8df/scss/mixins/_screen-reader.scss#L8-L18 */
  position: absolute;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  ${square(1)}
`;

const indicatorStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${Color.GrayscaleWhite};
  border: 2px solid ${Color.GrayscaleLight};
  border-radius: ${BorderRadius.Circle};
  ${square(20)}
`;

const indicatorActiveStyle = css`
  border-color: ${Color.GrayscaleDarker};
`;

const symbolCheckedStyle = css`
  display: inline-block;
  background: ${Color.GrayscaleDarker};
  border-radius: ${BorderRadius.Circle};
  ${square(8)}
`;

const labelStyle = css`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  font-size: ${FontSize.Medium};
  line-height: ${LineHeight.Compressed};
  user-select: none;

  &:empty {
    margin-left: 0;
  }
`;

const prefixStyle = css`
  margin-right: 10px;

  > * {
    display: block;
  }
`;

const suffixStyle = css`
  flex-grow: 1;
  margin-left: 10px;

  > * {
    display: block;
  }
`;
