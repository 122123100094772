import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Breakpoint, Color } from '../../../constants/Style';
import { square } from '../../../helpers/Style';
import { Box } from '../../Box';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';
import { Card } from '../../Card';
import { Icon } from '../../Icon';
import { Modal } from '../../Modal';

type FileProps = {
  filename: string;
  fileKey: string;
  fileUrl?: string;
};
type Props = {
  files: FileProps[];
  onDelete?: (key: string) => void;
  onClickUpload?: () => void;
  disabled?: boolean;
};

export const UploadedPreviews = observer(({ files, onDelete, onClickUpload, disabled }: Props) => {
  const [preview, setPreview] = useState<string>();
  return (
    <Box>
      <div className={layout}>
        {files.length > 0 ? (
          <div className={cardLayout}>
            {files.map(f => (
              <Card key={f.fileKey} shadowType="floating">
                <PreviewDialog
                  open={preview === f.fileKey}
                  filename={f.filename}
                  fileUrl={f.fileUrl}
                  onClose={() => setPreview(undefined)}
                />
                <div className={bodyLayout}>
                  <div className={fileName} onClick={() => setPreview(f.fileKey)}>
                    {f.filename}
                  </div>
                  <div className={icon} onClick={() => !disabled && onDelete?.(f.fileKey)}>
                    <Icon name="times" />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div>添付ファイルなし</div>
        )}
        <div>
          <Button disabled={disabled} onClick={() => onClickUpload?.()}>
            ファイルを選択
          </Button>
        </div>
      </div>
    </Box>
  );
});

type DialogProps = Pick<FileProps, 'filename' | 'fileUrl'> & {
  open: boolean;
  onClose: () => void;
};
const PreviewDialog = ({ fileUrl, filename, onClose, open }: DialogProps) => (
  <Modal visible={open}>
    <Card>
      <div>{filename}</div>
      <div className={imageContainerStyle}>
        <img src={fileUrl} alt={filename} />
      </div>
      <div>
        <ButtonGroup>
          <Button block onClick={() => onClose()}>
            閉じる
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  </Modal>
);

const imageContainerStyle = css`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

const layout = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  @media (min-width: ${Breakpoint.ScreenSm}px) {
    padding: 32px;
  }
`;

const bodyLayout = css`
  display: flex;
  justify-content: space-between;
  color: ${Color.GrayscaleDarker};

  /* padding: 0px 16px; */
`;
const icon = css`
  > svg {
    ${square(16)}
  }
`;

const fileName = css`
  width: 32px;
  @media (min-width: ${Breakpoint.ScreenSm}px) {
    width: 100px;
  }

  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
`;

const cardLayout = css`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${Breakpoint.ScreenSm}px) {
    grid-template-columns: 1fr 1fr;
  }

  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
`;
