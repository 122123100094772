import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { DocumentTitle } from '../../../../../../components/DocumentTitle';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { AccountDetailStore } from '../../../../AccountDetail/stores/AccountDetailStore';

export const AccountsNewNameInputPharmacyComplete = observer(() => {
  const account = useMemo(() => new AccountDetailStore(), []);
  const session = useContext(SessionStore.Context);
  const { push } = useHistory();

  useEffect(() => {
    if (!session.authenticated || !session.user) {
      return;
    }
    account.fetchProfile(session.user.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <Wrapper>
      <DocumentTitle title="ご協力ありがとうございます" />
      <div className={imageStyle}>
        <img src="/images/doctors_bowing.png" alt="医師がお辞儀をしている画像" />
      </div>
      <CaptionGroup>
        <Caption type="header">ご協力ありがとうございます</Caption>
        <Caption type="div">
          {account.profile?.patientNo ? (
            <>お客様の番号は「{account.profile.patientNo}」です。カウンターでこの画面をお見せください。</>
          ) : (
            <>登録情報を取得中です。しばらくお待ちください。</>
          )}
        </Caption>
      </CaptionGroup>
      <ButtonGroup gutter={10}>
        <Button block onClick={() => push(Routes.Paths.PrescriptionUpload)}>
          続けて処方箋をアップロードする
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});

const imageStyle = css`
  display: flex;
  justify-content: center;
`;
