import { css } from 'emotion';
import React, { MutableRefObject, ReactNode } from 'react';

import { Maybe } from '../../../gql/gql-types';
import { BorderRadius, Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type BaseProps = {
  children: ReactNode;
};

type FormItemProps = BaseProps & {
  gutter?: number;
  _ref?: MutableRefObject<HTMLDivElement | null>;
};

type FormLabelProps = BaseProps & {
  required?: boolean;
};

export const FormItem = ({ children, gutter: gutterPx, _ref }: FormItemProps) => (
  <div ref={_ref} style={gutterPx ? { margin: `${gutter(gutterPx)} 0px` } : {}}>
    {children}
  </div>
);

const formStyle = css`
  display: grid;
  flex-direction: column;
  row-gap: 27px;
`;

export const FormLayout = ({ children }: BaseProps) => <div className={formStyle}>{children}</div>;

const labelStyle = css`
  margin-bottom: ${gutter(4)};
  font-size: ${FontSize.Medium};
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
`;

const requiredLabelStyle = css`
  padding: 4px 8px;
  margin-left: 8px;
  font-size: ${FontSize.Tiny};
  font-weight: 600;
  color: ${Color.GrayscaleWhite};
  white-space: nowrap;
  vertical-align: top;
  background-color: ${Color.FunctionalRedNeutral};
  border-radius: ${BorderRadius.Regular};
`;

export const FormLabel = ({ children, required }: FormLabelProps) => (
  <div className={labelStyle}>
    {children}
    {required ? <span className={requiredLabelStyle}>必須</span> : null}
  </div>
);

const checkboxGroupStyle = css`
  display: grid;
  flex-direction: column;
  row-gap: 16px;
`;

export const CheckboxGroup = ({ children }: BaseProps) => <div className={checkboxGroupStyle}>{children}</div>;

export const FormSubLabel = ({ children }: BaseProps) => <div className={subLabelStyle}>{children}</div>;

const subLabelStyle = css`
  margin-bottom: 10px;
  font-size: ${FontSize.Regular};
  font-weight: 500;
  line-height: 1.2;
  color: ${Color.GrayscaleDarker};
`;

type ErrorProps = {
  errors?: Maybe<string>;
};
export const ErrorLabel = ({ errors }: ErrorProps) => (
  <>{errors ? <div className={errorStyle}>{errors}</div> : <div />}</>
);

const errorStyle = css`
  margin-top: 8px;
  font-size: ${FontSize.Regular};
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;

type ErrorSummaryProps = {
  onClick?: () => void;
  label?: string;
  errors?: Maybe<string>;
};
export const ErrorSummaryItem = ({ onClick, label, errors }: ErrorSummaryProps) => (
  <div className={errorSummaryStyle.container}>
    <span className={errorSummaryStyle.label} onClick={onClick}>
      {label}
    </span>
    <span> {errors}</span>
  </div>
);

const errorSummaryStyle = {
  container: css`
    color: ${Color.FunctionalRedNeutral};
    text-align: center;
  `,
  label: css`
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  `,
};
