import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';

import { Card } from '../../components/Card';
import { Modal } from '../../components/Modal';
import { useContext } from '../../hooks/useContext';
import { useSteps } from '../../hooks/useSteps';
import { useTransaction } from '../../hooks/useTransaction';
import { SessionStore } from '../../stores/SessionStore';
import { EmailInputPage } from './EmailInput';
import { ReceiptsListPage } from './ReceiptList';
import { ReceiptsSendCompletePage } from './ReceiptsSendComplete';
import { ReceiptListStore } from './stores/ReceiptListStore';

export const ReceiptsPage = observer(() => {
  const store = useMemo(() => new ReceiptListStore(), []);
  const sessionStore = useContext(SessionStore.Context);
  const STEP = {
    SELECT: 'receiptSelect',
    EMAIL: 'emailInput',
    Complete: 'receiptSendComplete',
  };
  const [step, setStep] = useState<string>(STEP.SELECT);
  useSteps(step, setStep);
  const [email, setEmail] = useState('');

  const onSubmit = () => {
    doDownload();
  };

  // 全ダウンロード
  const [doDownload, downloadLinkStatus] = useTransaction(async () => {
    if (!sessionStore.user?.userEntity?.name || !email) {
      return;
    }
    const res = await store.downloadAllReceipts(store.checkedIds, sessionStore.user.userEntity.name, email);
    if (!res.getBulkReceiptDownload?.success) {
      return;
    }
    store.setReceiptDownloadUrl(res.getBulkReceiptDownload.url || '');
    setStep(STEP.Complete);
  });

  return (
    <div>
      {(() => {
        switch (step) {
          case STEP.SELECT:
          default:
            return <ReceiptsListPage store={store} doDownload={doDownload} downloadLinkStatus={downloadLinkStatus} />;
          case STEP.EMAIL:
            return (
              <EmailInputPage
                onSubmit={() => onSubmit()}
                setEmail={setEmail}
                email={email || (sessionStore.user?.userEntity?.mail ?? '')}
                disable={!!downloadLinkStatus.running}
              />
            );
          case STEP.Complete:
            return <ReceiptsSendCompletePage url={store.receiptDownloadUrl} />;
        }
      })()}
      <Modal visible={!!downloadLinkStatus.running}>
        <Card>メール送信を準備中です・・・</Card>
      </Modal>
    </div>
  );
});
