import { format, parse } from 'date-fns/esm';
import { css } from 'emotion';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { TextField } from '../TextField';

type Props = {
  value: Date;
  onChange?: (value: Date) => void;
};
export const DateTime = observer(({ value, onChange }: Props) => {
  const store = useMemo(() => new DateTimeStore(), []);

  useEffect(() => {
    store.setDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    onChange?.(store.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <div className={style}>
      <div>
        <TextField type="date" value={store.dateStr} onChange={v => store.setDateStr(v)} />
      </div>
      <div>
        <TextField type="time" value={store.time} onChange={v => store.setTime(v)} />
      </div>
    </div>
  );
});

const style = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

class DateTimeStore {
  constructor() {
    makeAutoObservable(this, {
      date: observable,
      setDate: action,
      dateStr: computed,
      setDateStr: action,
      setTime: action,
      time: computed,
    });
  }

  public date: Date = new Date();

  public setDate(d: Date) {
    this.date = d;
  }

  public setTime(time: string) {
    this.setDate(parse(time, 'HH:mm', this.date));
  }

  public get time() {
    return format(this.date, 'HH:mm');
  }

  public setDateStr(date: string) {
    this.setDate(parse(`${date} ${this.time}`, 'yyyy-MM-dd HH:mm', this.date));
  }

  public get dateStr() {
    return format(this.date, 'yyyy-MM-dd');
  }
}
