import gql from 'graphql-tag';

import { TreatmentType } from '../fragments/treatment';

export const listTreatments = gql`
  query listTreatments($userId: ID!, $nextToken: String) {
    getUser(id: $userId) {
      completedTreatment: treatment(
        statusDate: { beginsWith: { status: "COMPLETED" } }
        sortDirection: DESC
        limit: 4
        nextToken: $nextToken
      ) {
        items {
          ...TreatmentHistory
        }
        nextToken
      }
    }
  }
  ${TreatmentType}
`;
