import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { CardInputLayout } from '../../../../../pages/Accounts/AccountDetail/components/CardInputLayout';
import { SessionStore } from '../../../../../stores/SessionStore';
import { ProfileStore } from '../../stores/ProfileStore';
import { errorStyle } from '../components/AccountsNameInput';
import { NameInput } from '../components/NameInput';

export const AccountsNewNameInputNormal = observer(() => {
  const { push } = useHistory();
  const store = useMemo(() => new ProfileStore(), []);
  const session = useContext(SessionStore.Context);
  const [doCallback, status] = useTransaction(async () => {
    await store.register();
    const url = new URLSearchParams(window.location.search).get('url');
    push(url || Routes.BasePath);
  });

  const [authStateKnown, setAuthStateKnown] = useState(false);
  const [concent, setConcent] = useState(false);

  const onSubmit = useRef<() => void>(doCallback);

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }

    const user = session.user?.userEntity;
    if (!user) {
      setAuthStateKnown(true);
      return;
    }

    const url = new URLSearchParams(window.location.search).get('url');
    push(url || Routes.BasePath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown]);

  if (!authStateKnown) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        患者プロフィール登録
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <CardInputLayout
            required
            callback={async t => {
              store.setCard({
                brand: t.brand,
                id: t.payjpID,
                expAt: t.expAt,
                fourDigit: t.fourDigit,
              });
              return doCallback();
            }}
            onSubmit={onSubmit}
            onStatusChange={s => store.cardStatus.setCardTransactionStatus(s)}
            onPreCardValidateChange={v => store.cardStatus.setPreCardValidate(v)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>メールアドレス(任意)</FormLabel>
          <TextField type="email" color="white" value={store.mail} onChange={v => store.setMail(v)} />
          <Caption type="small">予約時にGoogleカレンダー招待をお送りします。</Caption>
        </FormItem>
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <div className={errorStyle}>{store.cardStatus.cardTransactionStatus?.error}</div>
        <Button
          type="submit"
          block
          onClick={() => onSubmit.current()}
          disabled={
            status.running ||
            !store.valid ||
            !concent ||
            store.cardStatus.cardTransactionStatus?.running ||
            store.cardStatus.hasPreCardError
          }
        >
          登録する
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
