import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { FaqStore } from '../stores/FaqStore';
import { FaqListItem } from './components/FaqListItem';

export const FaqListPage = observer(() => {
  const store = useMemo(() => new FaqStore(), []);
  const { push } = useHistory();

  useEffect(() => {
    store.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <DocumentTitle title="ヘルプ" />
        <Caption type="header">ヘルプ</Caption>
        <div>
          {store.list.map(l => (
            <FaqListItem
              key={l.id}
              title={l.title}
              updatedAt={new Date(l.updatedAt)}
              onClick={() => push(Routes.Paths.FaqShow.replace(':questionId', l.id))}
            />
          ))}
        </div>
      </Wrapper>
    </>
  );
});
