import gql from 'graphql-tag';

import { ExtendedDictionaryType } from '../fragments/extendedDictionary';

export const listExtendedDictionaries = gql`
  query listExtendedDictionaries {
    listExtendedDictionarys {
      items {
        ...ExtendedDictionaryType
      }
    }
  }
  ${ExtendedDictionaryType}
`;
