import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Wrapper } from '../../../../../components/Wrapper';
import { useContext } from '../../../../../hooks/useContext';
import {
  ConfirmationFormArea,
  ConfirmationFormItem,
  ConfirmationFormLabel,
} from '../../../../Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { CheckupReservationStore } from '../stores/CheckupReservationStore';

type Props = {
  onNext?: () => void;
};

export const CheckupReservationManifestPage = observer(({ onNext }: Props) => {
  const store = useContext(CheckupReservationStore.Context);

  return (
    <Wrapper>
      <DocumentTitle title="所属と氏名を確認してください。" />
      <FormLayout>
        <FormItem>
          <FormLabel>所属と氏名を確認してください。</FormLabel>
          <ConfirmationFormArea>
            <ConfirmationFormItem>
              <ConfirmationFormLabel>所属</ConfirmationFormLabel>
              {store.checkupOrganization?.name}
            </ConfirmationFormItem>
            <ConfirmationFormItem>
              <ConfirmationFormLabel>氏名</ConfirmationFormLabel>
              {store.checkupOrganizationMember?.name}
            </ConfirmationFormItem>
          </ConfirmationFormArea>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>コース</ConfirmationFormLabel>
            {store.checkupOrganizationMember?.course.name}
          </ConfirmationFormItem>
        </FormItem>
        {/* <FormItem>
          <FormLabel required>以下をご選択ください</FormLabel>
          <FormItem>
            <Card>
              <CheckboxGroup>
                <Radio
                  label="健康診断を受診する"
                  name="receiveStatus"
                  value="true"
                  checked={store.receiveStatus}
                  onChange={e => e.target.checked && store.setReceiveStatus(true)}
                />
                <Radio
                  label="健康診断を受診しない"
                  name="receiveStatus"
                  value="false"
                  checked={!store.receiveStatus}
                  onChange={e => e.target.checked && store.setReceiveStatus(false)}
                />
              </CheckboxGroup>
            </Card>
          </FormItem>
        </FormItem> */}
      </FormLayout>
      <ButtonGroup gutter={16}>
        <Button onClick={onNext} disabled={!store.checkupOrganizationMember}>
          次へ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
