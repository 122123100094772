import { useEffect, useMemo } from 'react';

import { CourseStore } from '../stores/CourseStore';
import { DepartmentsStore } from '../stores/DepartmentsStore';
import { ReservationStore } from '../stores/ReservationStore';

export const useAutoDepartmentAndCourseSelect = (store: ReservationStore, dispatch: () => void) => {
  const departmentStore = useMemo(() => new DepartmentsStore(), []);
  const coursesStore = useMemo(() => new CourseStore(), []);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const departmentId = query.get('department');
    if (!departmentId) {
      return;
    }
    departmentStore.fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const departmentId = query.get('department');
    if (!departmentId) {
      return;
    }
    const found = departmentStore.departments.find(d => d.id === departmentId);
    if (found) {
      store.setDepartment(found);
      dispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentStore.departments]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const treatmentKindId = query.get('treatmentKind');
    if (!treatmentKindId) {
      return;
    }
    coursesStore.fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const treatmentKindId = query.get('treatmentKind');
    if (!treatmentKindId) {
      return;
    }
    const found = coursesStore.courses.find(d => d.id === treatmentKindId);

    if (found) {
      store.setTreatmentKind(found);
      dispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesStore.courses]);
};
