import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../domains/base/SearchParams';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { WindowUtil } from '../../../utils/WindowUtil';
import { WaitingListItemStore } from '../stores/WaitingListItemStore';

export const WaitingListSuccessPage = observer(() => {
  const store = useMemo(() => new WaitingListItemStore(), []);
  const { reservationId } = useReservationId();
  const { push } = useHistory();
  const [load, loadStatus] = useTransaction(async (id: string) => {
    await store.fetch(id);
  });

  const pushToReservePage = () => {
    push({
      pathname: Routes.Paths.ReservationNew,
      search: new URLSearchParamsMonad(new URLSearchParams())
        .accept('department', store.origin?.department?.id || '')
        .accept('treatmentKind', store.origin?.treatmentKind?.id || '')
        .toString(),
    });
  };

  useEffect(() => {
    load(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <Wrapper>
      <Modal visible={!!loadStatus.running}>
        <Card>データ取得中です。</Card>
      </Modal>
      <CaptionGroup>
        <Caption type="header">{store.origin?.department?.name}のキャンセル通知登録が完了しました</Caption>
        <Caption type="body">
          ※キャンセル通知登録は、空き枠発生時の予約確定をお約束するものではありません。
          <br />
          ※空き枠が出た際に、LINEで通知します。先着で予約確定した方に受診いただけます。
        </Caption>
      </CaptionGroup>
      <Button
        theme="google"
        onClick={() => {
          WindowUtil.openInDefaultBrowser(store.googleCalendarUrl);
        }}
        block
      >
        Googleカレンダーに枠を登録
      </Button>

      <div className={suggestSectionStyle}>
        <Card>
          <Caption type="header" align="center">
            他の枠で予約を抑えておくことも可能です
          </Caption>
          <Caption type="body">
            キャンセル通知を登録しつつ、ご都合の合う他の空き枠で確実に予約をとることもできます。
          </Caption>
        </Card>

        <ButtonGroup gutter={20}>
          <Button ghost onClick={pushToReservePage}>
            他の空き枠を予約する
          </Button>
        </ButtonGroup>
      </div>
    </Wrapper>
  );
});

const suggestSectionStyle = css`
  margin-top: 30px;
`;
