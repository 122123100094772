import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';
import { Result } from '../../../../interfaces/Result';

type Props = {
  criteria: CriteriaChartDef[];
  value: number;
};

type CriteriaChartDef = {
  min: number;
  max: number;
  result: Result;
};

export const ResultBarChart = ({ criteria, value }: Props) => (
  <div className={barChartContainerStyle}>
    {criteria.map((c, index) => (
      <div key={index}>
        <BarParts
          result={value && value >= c.min && value <= c.max ? value : undefined}
          resultTarget={c.result}
          min={c.min}
          max={c.max}
          notShowMax={criteria.length - 1 > index}
        />
      </div>
    ))}
  </div>
);

const barChartContainerStyle = css`
  display: flex;
  justify-content: center;
  margin: ${gutter(4)};
`;

type BarPartsProps = {
  result?: number;
  resultTarget: Result;
  min: number;
  max: number;
  notShowMax: boolean;
};

const BarParts = ({ result, resultTarget, min, max, notShowMax }: BarPartsProps) => (
  <>
    <div className={barPartsContainerStyle}>
      <p className={cx(baseStyle, colorStyles[resultTarget])} />
      {(min || min === 0) && <p className={cx(baseLabelStyle, barPartsMinStyle)}>{min}</p>}
      {max && !notShowMax && <p className={cx(baseLabelStyle, barPartsMaxStyle)}>{max}</p>}
      {(result || result === 0) && <ResultRound max={max} min={min} result={resultTarget} resultNumber={result} />}
    </div>
  </>
);

type ResultRoundProps = {
  result: 'A' | 'B' | 'C' | 'D';
  max: number;
  min: number;
  resultNumber: number;
};
const ResultRound = ({ result, max, min, resultNumber }: ResultRoundProps) => {
  const roundPosition = useMemo(
    () =>
      resultNumber - min === 0
        ? -roundLeftPosition
        : (barWidth * (resultNumber - min)) / (max - min) - roundLeftPosition,
    [max, min, resultNumber],
  );
  return (
    <div
      className={cx(
        resultRoundStyle,
        colorStyles[result],
        css`
          left: ${roundPosition}px;
        `,
      )}
    >
      <div className={cx(resultInnerRoundStyle)} />
    </div>
  );
};

const barWidth = 58;
// 8px ずらす
const roundLeftPosition = 8;

const resultRoundStyle = css`
  ${square(16)}

  position: absolute;
  top: -5px;
  left: 0;
  z-index: 99;
  border-radius: 100%;
`;

const resultInnerRoundStyle = css`
  ${square(12)}

  position: absolute;
  top: 2px;
  left: 2px;
  background: ${Color.GrayscaleWhite};
  border-radius: 100%;
`;

const barPartsContainerStyle = css`
  position: relative;
  font-size: ${FontSize.Tiny};
`;

const baseLabelStyle = css`
  margin-top: ${gutter(1.66)};
  color: ${Color.GrayscaleNeutral};
`;
const barPartsMinStyle = css`
  position: absolute;
`;

const barPartsMaxStyle = css`
  position: absolute;
  right: 0;
`;

const colorStyles = {
  A: css`
    background: ${Color.NotingResult};
  `,
  B: css`
    background: ${Color.AttentionResult};
  `,
  C: css`
    background: ${Color.ConsultationResult};
  `,
  D: css`
    background: ${Color.TreatmentResult};
  `,
};

const baseStyle = css`
  width: ${barWidth}px;
  height: 6px;
`;
