import React from 'react';

import { Button } from '../../components/Button';
import { useContext } from '../../hooks/useContext';
import { Demo2Store } from '../../stores/Demo2Store';

type Props = {
  onChangeState: (nextState: 'input' | 'confirm' | 'complete') => void;
};

export const CompleteSection = ({ onChangeState }: Props) => {
  const store = useContext(Demo2Store.Context);

  const handleClickBack = () => onChangeState('input');

  return (
    <>
      <h2>Complete section</h2>
      <p>下記のユーザを作成しました。</p>
      <div>
        <pre>
          <code>{JSON.stringify(store.user, null, 2)}</code>
        </pre>
      </div>
      <Button onClick={handleClickBack}>戻る</Button>
    </>
  );
};
