import gql from 'graphql-tag';

export const CheckupResultItemSubGroupType = gql`
  fragment CheckupResultItemSubGroupType on CheckupResultItemSubGroup {
    id
    name
    description
    order
    group {
      id
      friendlyName
    }
  }
`;
