import { css, cx } from 'emotion';
import React from 'react';

import { Icon } from '../../../../../components/Icon';
import { gutter } from '../../../../../helpers/Style';

type Props = {
  onClick: (modalOpen: boolean) => void;
  marginTop?: boolean;
};
export const ShareButton = ({ onClick, marginTop }: Props) => (
  <div className={cx(containerStyle, marginTop ? marginTopStyle : '')} onClick={() => onClick(true)}>
    <Icon name="sns-share" />
    <p className={boldStyle}>SNSでシェア</p>
  </div>
);

const containerStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: ${gutter(15)};
  cursor: pointer;

  > svg {
    width: 20px;
    height: 20px;
    margin-right: ${gutter(3)};
  }
`;

const marginTopStyle = css`
  margin-top: ${gutter(7.75)};
`;

const boldStyle = css`
  font-weight: bold;
`;
