import { css, cx } from 'emotion';
import React, { ComponentProps, ReactNode, useState } from 'react';

import { IconSize } from '../../constants/Style';
import { square } from '../../helpers/Style';
import { Card } from '../Card';
import { Icon } from '../Icon';

type Props = {
  children: ReactNode;
  header: ReactNode;
  textBold?: boolean;
  cardProps?: Partial<Omit<ComponentProps<typeof Card>, 'outlineLight' | 'open' | 'children'>>;
};
export const Accordion = ({ children, header, textBold, cardProps }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Card color={cardProps?.color} outlineLight open={!!open}>
        <div className={headerStyle} onClick={() => setOpen(!open)}>
          <div className={cx(textBold ? boldStyle : '')}>{header}</div>
          <div className={angleStyle}>
            <Icon name="angle-down" />
          </div>
        </div>
      </Card>
      <div>{open ? children : <></>}</div>
    </div>
  );
};

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const boldStyle = css`
  font-weight: bold;
`;

const angleStyle = css`
  > svg {
    ${square(IconSize.Regular)}
  }
`;
