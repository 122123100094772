import gql from 'graphql-tag';

import { ReservationType } from '../fragments/reservation';

export default gql`
  query getReservation($treatmentId: String) {
    getTreatment(id: $treatmentId) {
      ...Reservation
      checkupAppointment {
        id
        checkupOrganizationMemberEvent {
          id
        }
        treatment {
          items {
            status
          }
        }
      }
      user {
        penaltyCount
      }
    }
  }
  ${ReservationType}
`;
