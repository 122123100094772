import { css, keyframes } from 'emotion';
import React from 'react';

import { BorderRadius, Color, Duration, Easing } from '../../constants/Style';
import { toRGBA } from '../../helpers/Style';

type Props = {
  /**
   * 0 ~ 100 を指定する
   */
  value: number;
};

export const ProgressBar = ({ value }: Props) => (
  <div className={baseStyle}>
    <span
      className={valueStyle}
      style={{
        width: `${value}%`,
      }}
    />
  </div>
);

const baseStyle = css`
  width: 100%;
  height: 5px;
  text-align: left;
  background: ${toRGBA(Color.GrayscaleDarker, 0.2)};
  border-radius: ${BorderRadius.Circle};
`;

const valueStyle = css`
  display: inline-block;
  width: 0;
  height: 5px;
  vertical-align: top;
  background: ${Color.GrayscaleDarker};
  border-radius: ${BorderRadius.Circle};
  transition: width ${Duration.Enter} ${Easing.Enter};
  transform-origin: 0% 50%;
  animation: ${keyframes`
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  `} 0.2s ${Easing.Enter} 0s;
`;
