import React, { ComponentProps, useEffect, useState } from 'react';

import { AnswerValueInput } from '../../../gql/gql-types';
import { Card } from '../Card';
import { CheckboxGroup, ErrorLabel, FormLabel } from '../Form';
import { Radio } from '../Radio';
import { TextField } from '../TextField';
import { errorStyle, InterviewReactiveFormItem, toNonNull } from '.';
import { OptionProp } from './types';

type Prop = ComponentProps<typeof InterviewReactiveFormItem>;
type AnswerOpt = AnswerValueInput;

export const InterviewRadioGroup = ({ def, value, onChange, errors, analyticsClassName }: Prop) => (
  <>
    <FormLabel required={!!def.required}>{def.label}</FormLabel>
    <Card className={errors && errorStyle}>
      <CheckboxGroup>
        {(def.options || []).map((option, index) => (
          <InterviewRadio
            def={def}
            option={toNonNull(option)}
            value={value}
            onChange={onChange}
            key={option?.id}
            analyticsClassName={analyticsClassName ? `${analyticsClassName}_${index + 1}` : undefined}
          />
        ))}
      </CheckboxGroup>
    </Card>
    <ErrorLabel errors={errors} />
  </>
);

const InterviewRadio = ({ def, option, value, onChange, analyticsClassName }: OptionProp) => {
  const [freeInput, setFreeInput] = useState(value.find(v => v.id === option.id)?.value);
  const answerOpt = { label: option.label, value: option.value, id: String(option.id) };

  useEffect(() => {
    setFreeInput(value.find(v => v.id === option.id)?.value);
  }, [value, option.id]);

  if (option.type === 'radio' || option.type === 'pharmacy' || option.type === 'FEVER') {
    return (
      <Radio
        key={option.value}
        value={String(option.id)}
        name={String(def.id)}
        label={option.label}
        checked={value.some(v => v.id === option.id)}
        onChange={i => i.target.checked && onChange([answerOpt])}
        analyticsClassName={analyticsClassName}
      />
    );
  }

  const onBlur = () => {
    dispatchValueChange(onChange, answerOpt, freeInput || '');
  };

  const placeholder = freeInput === undefined ? '' : option.placeholder || '記載してください';

  return (
    <>
      <div>
        <Radio
          key={option.value}
          name={String(def.id)}
          value={option.value}
          label={option.label}
          checked={freeInput !== undefined}
          onChange={t => t.target.checked && dispatchValueChange(onChange, answerOpt, '')}
          analyticsClassName={analyticsClassName}
        />
      </div>
      <div>
        <TextField
          key={`${option.value}input`}
          type="text"
          onChange={t => setFreeInput(t)}
          onFocusout={onBlur}
          disabled={freeInput === undefined}
          value={freeInput || ''}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

const dispatchValueChange = (onChange: (inp: AnswerValueInput[]) => void, option: AnswerOpt, value: string) =>
  onChange([{ ...option, value }]);
