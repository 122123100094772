import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Checkbox } from '../../../../../../components/Checkbox';
import { Modal } from '../../../../../../components/Modal';
import { Defaults } from '../../../../../../constants/Defaults';
import { Color, FontSize } from '../../../../../../constants/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { approximate } from '../../../../../../utils/NumberUtil';
import { CancelPolicyContent } from '../../../../components/CancelPolicyContent';
import { LastWarningContent } from '../../../../components/LastWarningContent';
import { ReservationEditStore } from '../../stores/ReservationEditStore';

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

type Props = {
  open: boolean;
  handleStateChange: (newState: boolean) => void;
  onConfirm: () => void;
  disabled?: boolean;
};

export const ConfirmDialog = observer(({ disabled = false, open, handleStateChange, onConfirm }: Props) => {
  const store = useContext(ReservationEditStore.Context);
  const [cancelPolicyConcent, setCancelPolicyConcent] = useState(false);

  const { cancelPolicyInfo, visibleLastCaution } = store.cancelPolicies;
  const isJustBeforeReserve = store.date && store.cancelPolicies.isJustBeforeAction(store.date);

  const handleConfirmClick = () => {
    isJustBeforeReserve ? store.cancelPolicies.setVisibleLastCaution(true) : onConfirm();
  };

  if (!store.date) {
    return (
      <Modal visible={open}>
        <Card>
          <Caption type="header">日付が選択されていません。</Caption>
          <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
            キャンセル
          </Button>
        </Card>
      </Modal>
    );
  }

  return (
    <>
      <Modal visible={open}>
        <Card width={375}>
          {!visibleLastCaution && (
            <div className={centerStyle}>
              <div className={captionContainerStyle}>
                <div className={confirmHeaderStyle}>以下の予約を変更しますか？</div>
                <hr className={confirmHrStyle} />
                <div className={confirmBodyStyle}>
                  <div className={confirmDateStyle}>
                    {format(store.date, 'yyyy年M月d日')}({WeekLabels[getDay(store.date)]})
                  </div>
                  <div className={extraLargeStyle}>
                    {format(store.date, 'HH:mm')}
                    <span className={smallTimeTildeStyle}> ～ </span>
                    <span className={smallTimeStyle}>[所要{approximate(store.duration, Defaults.DURATION)}分]</span>
                  </div>
                  <div className={departmentStyle}>
                    {store.department?.clinicName} / {store.department?.name}
                  </div>
                </div>
              </div>
              <div className={cancelPolicySectionStyle}>
                <CancelPolicyContent date={store.date} store={store.cancelPolicies} actionType="reserve" />
                {store.cancelPolicies.cancelPolicyInfo && (
                  <div className={cancelPolicyCheckBoxStyle}>
                    <Checkbox
                      label="上記キャンセルポリシーに同意する"
                      checked={cancelPolicyConcent}
                      onChange={v => setCancelPolicyConcent(v.target.checked)}
                    />
                  </div>
                )}
              </div>
              <ButtonGroup>
                <Button
                  disabled={disabled || (!!cancelPolicyInfo && !cancelPolicyConcent)}
                  type="button"
                  onClick={handleConfirmClick}
                >
                  予約を変更する
                </Button>
                <Button theme="neutral" ghost type="button" onClick={() => handleStateChange(false)}>
                  予約日時の選択に戻る
                </Button>
              </ButtonGroup>
            </div>
          )}
          {visibleLastCaution && (
            <LastWarningContent
              type="reserve"
              store={store.cancelPolicies}
              onConfirm={onConfirm}
              onCancel={() => handleStateChange(false)}
            />
          )}
        </Card>
      </Modal>
    </>
  );
});

const confirmBodyStyle = css`
  margin: 24px 0;
`;

const confirmHeaderStyle = css`
  margin: 10px 0;
  font-size: ${FontSize.Medium};
  font-weight: bold;
`;

const confirmDateStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
`;

const confirmHrStyle = css`
  border: 0.5px solid ${Color.GrayscaleLight};
  opacity: 0.5;
`;

const extraLargeStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${FontSize.ExtraLarge};
  margin: 16px 0;
  font-size: ${FontSize.ExtraLarge};
  font-weight: 700;
  letter-spacing: -0.05rem;
  white-space: nowrap;
`;

const departmentStyle = css`
  margin: 16px 0;
`;

const captionContainerStyle = css`
  width: 100%;
`;

const smallTimeStyle = css`
  align-self: flex-end;
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  margin-right: 4px;
  margin-left: 4px;
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const cancelPolicySectionStyle = css`
  margin-bottom: 16px;
  font-weight: 300;
  text-align: left;
`;

const cancelPolicyCheckBoxStyle = css`
  margin-top: 8px;
`;
