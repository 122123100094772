import { css } from 'emotion';
import React, { ComponentProps } from 'react';
import { createPortal } from 'react-dom';

import { gutter } from '../../helpers/Style';
import { Toast } from './Toast';

type Props = {
  toasts: ComponentProps<typeof Toast>[];
};

export const Container = ({ toasts }: Props) =>
  createPortal(
    <div className={baseStyle}>
      {toasts.map(({ id, message, icon, theme }) => (
        <Toast key={id} id={id} message={message} icon={icon} theme={theme} />
      ))}
    </div>,
    document.body,
  );

const baseStyle = css`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: ${gutter(4)};
  transform: translate3d(-50%, 0, 0);
`;
