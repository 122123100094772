import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { FormItem, FormLabel, FormLayout } from '../../../../components/Form';
import { TextField } from '../../../../components/TextField';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ForgotPasswordStore } from '../stores/ForgotPasswordStore';

export const ResetPasswordPage = observer(() => {
  const store = useMemo(() => new ForgotPasswordStore(), []);
  const { push } = useHistory();
  const { addToast } = useToast();
  const [error, setError] = useState('');
  const { params } = useRouteMatch<{ id: string }>();
  const { decode } = useReservationId();

  useEffect(() => {
    if (!params.id) {
      return;
    }
    store.setEmail(decode(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [confirm, confirmStatus] = useTransaction(
    async () => {
      const res = await store.confirmForgotPassword();
      if (!res) {
        throw new Error('必須項目が空白です。');
      }

      addToast({ message: '新しいパスワードを確認しました。再度サインインしてください。' });
      push(Routes.Paths.SignIn);
    },
    e => {
      setError(e.message);
    },
  );

  return (
    <Wrapper>
      <DocumentTitle title="パスワード再設定" />
      <CaptionGroup>
        <Caption type="header" align="center">
          パスワード再設定メールを送信しました
        </Caption>

        <Caption type="body">
          記入いただいたメールアドレス宛にパスワード再設定コードをお送りしました。記載された認証コードを入力し、パスワード再設定を完了してください。
        </Caption>
        <Caption type="body">
          メールを再送する場合は
          <button onClick={() => store.forgotPassword()}>こちら</button>
        </Caption>
      </CaptionGroup>

      <FormLayout>
        <FormItem>
          <FormLabel>認証コード</FormLabel>
          <TextField color="white" value={store.code} onChange={v => store.setCode(v)} />
        </FormItem>
        <FormItem>
          <FormLabel>パスワード(変更後) ※半角英数8文字以上 大文字小文字と数値を混在させてください。</FormLabel>
          <TextField type="password" color="white" value={store.newPassword} onChange={v => store.setNewPassword(v)} />
        </FormItem>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <FormItem>
          <ButtonGroup>
            <Button onClick={confirm} disabled={!!confirmStatus.running}>
              確認する
            </Button>
          </ButtonGroup>
        </FormItem>
      </FormLayout>
    </Wrapper>
  );
});
