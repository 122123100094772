import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Color, FontSize } from '../../../../../../constants/Style';
import { gutter } from '../../../../../../helpers/Style';

type BaseProps = {
  children: ReactNode;
};

const containerStyle = css`
  width: 100%;
  padding: ${gutter(4)};
  padding-left: ${gutter(8)};
  margin-bottom: ${gutter(8)};
  word-break: break-all;
  background-color: ${Color.GrayscaleWhite};
`;

const UsageListContainer = ({ children }: BaseProps) => <div className={containerStyle}>{children}</div>;
const discListStyle = css`
  list-style-type: disc;
`;

const listItemStyle = css`
  font-size: ${FontSize.Small};
  line-height: 1.4;
`;

export const UsageListItem = ({ children }: BaseProps) => <li className={listItemStyle}>{children}</li>;
export const UsageList = ({ children }: BaseProps) => (
  <UsageListContainer>
    <ul className={discListStyle}>{children}</ul>
  </UsageListContainer>
);
