import { Auth } from '@aws-amplify/auth';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormItem, FormLayout } from '../../../../../components/Form';
import { TextField } from '../../../../../components/TextField';
import { useToast } from '../../../../../components/Toast';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import { AccountLegacyStore } from '../AccountsNewLegacyInput/stores/AccountsLegacyStore';

export const AccountsNewLegacyConfirmPage = () => {
  const store = useContext(SessionStore.Context);
  const { push } = useHistory();
  const { addToast } = useToast();
  const json = window.sessionStorage.getItem(AccountLegacyStore.KEY);
  const [confirm, status] = useTransaction(async () => {
    const json = window.sessionStorage.getItem(AccountLegacyStore.KEY);
    if (!json) {
      throw new Error('');
    }
    const signIn = JSON.parse(json);
    await Auth.confirmSignUp(signIn.username, code);
    await store.legacySignIn(signIn.email, signIn.password);
    window.sessionStorage.removeItem(AccountLegacyStore.KEY);
    addToast({ message: 'ユーザーを認証しました。', icon: 'check-circle' });
    push({
      pathname: Routes.Paths.AccountName,
      search: window.location.search,
    });
  });
  const [code, setCode] = useState('');

  return (
    <Wrapper>
      <DocumentTitle title="新規会員登録 - 認証コード入力" />
      <CaptionGroup>
        <Caption type="header" align="center">
          登録メールを送信しました
        </Caption>

        <Caption type="body">
          記入いただいたメールアドレス宛にアカウント認証URLをお送りしました。記載された認証コードを入力し、アカウント登録を完了してください。
        </Caption>
        <Caption type="body">
          メールを再送する場合は
          <button onClick={() => Auth.resendSignUp(json && JSON.parse(json).username)}>こちら</button>
        </Caption>
      </CaptionGroup>

      <FormLayout>
        <FormItem>
          <TextField color="white" value={code} onChange={v => setCode(v)} />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <Button onClick={confirm} disabled={!!status.running}>
              確認する
            </Button>
            <Button ghost>ログインページに戻る</Button>
          </ButtonGroup>
        </FormItem>
      </FormLayout>
    </Wrapper>
  );
};
