import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../../../gql/client';
import { IssueUserNonceMutation, IssueUserNonceMutationVariables } from '../../../../../../../gql/gql-types';
import { issueUserNonce } from '../../../../../../../gql/operations/issueUserNonce';

export class LineConnectStore {
  constructor() {
    makeAutoObservable(this, {
      token: observable,
      issueNonce: action,
      setToken: action,
    });
  }

  public token?: string = undefined;

  public setToken(token: string) {
    this.token = token;
  }

  public async issueNonce() {
    if (!this.token) {
      return null;
    }

    const res = await GQL.query<IssueUserNonceMutationVariables, IssueUserNonceMutation>(issueUserNonce, {
      input: {
        token: this.token,
      },
    });

    return res.issueUserNonce?.url;
  }
}
