import { action, makeObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { requestPostUser } from '../api/demo-client';
import { User } from '../api/demo-model';

export class Demo2Store {
  // このストアの React.Context。
  // ここに定義することでこれ以上ないほどの凝集性が表現される。
  public static Context = createContext<Demo2Store | null>(null);

  public name = '';

  public job = '';

  public user: User = {
    name: '',
    job: '',
    id: 0,
  };

  constructor() {
    makeObservable(this, {
      name: observable,
      job: observable,
      user: observable,
      setName: action,
      setJob: action,
    });
  }

  public setName(name: string) {
    this.name = name;
  }

  public setJob(job: string) {
    this.job = job;
  }

  public async postUser() {
    const user = await requestPostUser({
      send: {
        name: this.name,
        job: this.job,
      },
    });

    runInAction(() => {
      this.user = user;
    });
  }
}
