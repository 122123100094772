import { makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

export class PayjpStore {
  public static Context = createContext<PayjpStore>(new PayjpStore());

  constructor() {
    makeAutoObservable(this, {
      payjp: observable,
      valid: observable,
    });
  }

  public payjp = undefined;
  public valid = true;

  public setPayjp(payjp: any) {
    this.payjp = payjp;
  }

  public setValid(valid: boolean) {
    this.valid = valid;
  }
}
