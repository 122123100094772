import gql from 'graphql-tag';

export default gql`
  query getBulkReceiptsDownloadLink($input: BulkReceiptDownloadInput!) {
    getBulkReceiptDownload(input: $input) {
      success
      url
    }
  }
`;
