import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../gql/client';
import { GetInterviewQuery, GetInterviewQueryVariables } from '../../../../../gql/gql-types';
import getInterview from '../../../../../gql/operations/getInterview';

export class InterviewStore {
  constructor() {
    makeAutoObservable(this, {
      interview: observable,
      fetchInterview: action,
    });
  }

  interview?: GetInterviewQuery['getInterview'] = undefined;

  public async fetchInterview(id: string) {
    const res = await GQL.query<GetInterviewQueryVariables, GetInterviewQuery>(getInterview, { id });
    this.interview = res.getInterview;
  }
}
