type GeoLocation = {
  zipCode: string;
  address: string;
  pref: string;
  addressLine1: string;
};

export const useZipCode =
  () =>
  async (zipCode_: string): Promise<GeoLocation> => {
    const zipCode = sanitizeZipCode(zipCode_);
    const headers = new Headers();
    // cspell:disable-next-line
    headers.append('apikey', `auVhTGBNhKVXZI52m6aGeiyo6lwDT3gmzsEJ81p`);
    return fetch(`https://apis.postcode-jp.com/api/v4/postcodes/${sanitizeZipCode(zipCode)}`, {
      headers,
    })
      .then(res => res.json())
      .then(res => res[0])
      .then(res => ({
        pref: res.pref,
        addressLine1: res.allAddress,
        address: res.allAddress,
        zipCode,
      }));
  };

const sanitizeZipCode = (zipCode: string) =>
  zipCode.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0)).replace(/[^0-9]/g, '');
