import { css } from 'emotion';
import React from 'react';

import { Button } from '../../../../components/Button';

type Props = {
  onClick: () => void;
  checkedIds: string[];
};
export const DownloadButton = ({ onClick, checkedIds }: Props) => (
  <>
    <div className={style}>
      <Button disabled={checkedIds.length === 0} pill floating block onClick={() => onClick()}>
        <div>領収書をメールで送付する</div>
      </Button>
    </div>
  </>
);

const style = css`
  position: fixed;
  bottom: 55px;
  left: 50%;
  width: 280px;
  line-height: 19px;
  text-decoration: none;
  transform: translate(-50%, 0);
`;
