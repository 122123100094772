import gql from 'graphql-tag';

export const ExtendedDictionaryType = gql`
  fragment ExtendedDictionaryConditionType on ExtendedDictionaryCondition {
    left
    right
    comparator
  }
  fragment ExtendedDictionaryOverrideType on ExtendedDictionaryOverride {
    template
    conditions {
      ...ExtendedDictionaryConditionType
    }
  }
  fragment ExtendedDictionaryType on ExtendedDictionary {
    key
    template
    override {
      ...ExtendedDictionaryOverrideType
    }
  }
`;
