import { action, computed, makeAutoObservable, observable } from 'mobx';

import { Maybe } from '../../gql/gql-types';

export class FormStore {
  constructor() {
    makeAutoObservable(this, {
      allErrors: computed,
      dirtyState: observable,
      displayErrors: computed,
      items: observable,
      setDirty: action,
      setDirtyAll: action,
      setItems: action,
      setValue: action,
      valid: computed,
      value: observable,
    });
  }

  items: FormItemDef[] = [];

  setItems(items: FormItemDef[]) {
    this.items = items;
  }

  setValue(value: any) {
    this.value = value;
  }

  value: any = undefined;

  dirtyState: string[] = [];

  setDirty(id: string) {
    this.dirtyState.push(id);
  }

  setDirtyAll() {
    this.dirtyState = this.items.map(i => i.id);
  }

  computeDirty(id: string) {
    return this.dirtyState.includes(id);
  }

  get allErrors() {
    return this.items
      .map(i => ({ id: i.id, name: i.name, error: i.validator(this.value?.[i.id], this.value) }))
      .filter(x => !!x.error);
  }

  get valid() {
    return this.allErrors.length === 0;
  }

  get displayErrors() {
    return this.allErrors.filter(e => this.computeDirty(e.id));
  }
}

type FormItemDef = {
  validator: (target: any, fullValue: any) => Maybe<string>;
  name: string;
  id: string;
};
