import { css } from '@emotion/css';
import add from 'date-fns/add';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { observer } from 'mobx-react';
import React, { ComponentProps, useMemo } from 'react';

import { Card } from '../../../../../components/Card';
import { Defaults } from '../../../../../constants/Defaults';
import { Color, FontSize } from '../../../../../constants/Style';
import { approximate } from '../../../../../utils/NumberUtil';
import { TreatmentCard } from '../../../TreatmentList/components/TreatmentCard';
import { DoctorIcon } from './DoctorIcon';

type Props = Partial<ComponentProps<typeof TreatmentCard>>;
type NonNullProps = Required<Props>;
const duration = 15;
const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;

export const TreatmentDetailCard = observer(
  ({
    soap = { doctor: { id: '', name: '' }, feedback: '' },
    clinic = { id: '', name: '' },
    date = new Date(),
    department = { id: '', name: '' },
    payment = { amount: 0 },
    isNoCharge,
  }: Props) => (
    <Card>
      <TreatmentDetailCardHeader clinic={clinic} date={date} department={department} />
      <TreatmentDetailCardBody soap={soap} department={department} />
      <hr className={hrStyle} />
      <TreatmentDetailCardFooter payment={payment} isNoCharge={!!isNoCharge} />
    </Card>
  ),
);

const hrStyle = css`
  border-top: 1px dashed ${Color.GrayscaleLight};
`;

type HeaderProps = Pick<NonNullProps, 'clinic' | 'date' | 'department'>;

const TreatmentDetailCardHeader = ({ clinic, date, department }: HeaderProps) => (
  <header>
    <div className={dateStyle}>
      {format(date, 'yyyy年MM月dd日')}({WeekLabels[getDay(date)]}){format(date, 'HH:mm')} -{' '}
      {format(add(date, { minutes: approximate(duration, Defaults.DURATION) }), 'HH:mm')}
    </div>
    <div className={clinicStyle}>
      {clinic.name} / {department.name}
    </div>
  </header>
);

const dateStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const clinicStyle = css`
  font-size: ${FontSize.Regular};
  color: ${Color.GrayscaleNeutral};
`;

type BodyProps = Pick<NonNullProps, 'soap' | 'department'>;
const TreatmentDetailCardBody = ({ soap, department }: BodyProps) => (
  <div>
    <div className={doctorStyle}>
      <div>
        <DoctorIcon id={soap.doctor.id} />
      </div>
      <div>
        <div className={doctorNameStyle}>{soap.doctor.name}</div>
        <div className={departmentStyle}>{department.name}</div>
      </div>
    </div>
    <>
      {soap.feedback.split('\\n').map((line, key) => (
        <p key={key}>{line}</p>
      ))}
    </>
  </div>
);

const doctorStyle = css`
  display: flex;
`;
const doctorNameStyle = css`
  font-size: ${FontSize.Medium};
`;
const departmentStyle = css`
  font-size: ${FontSize.Small};
`;

type FooterProps = Pick<NonNullProps, 'payment' | 'isNoCharge'>;

const formatYen = (n: number) => n.toLocaleString();

const TreatmentDetailCardFooter = ({ payment, isNoCharge }: FooterProps) => {
  const isUndef = useMemo(() => payment.amount === null || payment.amount === undefined, [payment]);
  return (
    <footer className={footerStyle}>
      {isNoCharge ? (
        <>
          <div className={footerLabelStyle}>診察費用合計</div>
          <div className={moneyStyle}>¥0</div>
        </>
      ) : !isUndef ? (
        <>
          <div className={footerLabelStyle}>診察費用合計</div>
          <div className={moneyStyle}>¥{formatYen(payment.amount as number)}</div>
        </>
      ) : (
        <div className={footerLabelStyle}>診察費用未確定</div>
      )}
    </footer>
  );
};

const footerLabelStyle = css`
  margin-right: 10px;
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleDarker};
`;

const moneyStyle = css`
  font-size: ${FontSize.ExtraLarge};
  font-weight: bold;
`;

const footerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
