import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../components/Button';
import { Caption, CaptionGroup } from '../../../../components/Caption';
import { Wrapper } from '../../../../components/Wrapper';
import { GtmClasses } from '../../../../constants/AnalyticsTags';
import { Routes } from '../../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../../domains/base/SearchParams';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { useTransaction } from '../../../../hooks/useTransaction';
import { safeFilter } from '../../../../utils/CollectionUtil';
import { ReservationDetailStore } from '../../../Reservations/ReservationDetail/stores/ReservationDetailStore';
import { transformer } from '../InterviewsInput/transformer';
import { Interviews } from '../stores/Interviews';
import {
  ConfirmationFormArea,
  ConfirmationFormGroup,
  ConfirmationFormItem,
  ConfirmationFormItemValue,
  ConfirmationFormLabel,
  ConfirmationGroupTitle,
} from './components/ConfirmationForm';

type QuestionType = NonNullable<NonNullable<Interviews['interview']>['questions']>;

type Props = {
  onBack?: () => void;
};

export const InterviewsConfirmation = observer(({ onBack }: Props) => {
  useScrollToTop();
  const { interview, answerInterview } = useContext(Interviews.Context);
  const { reservationId, push } = useReservationId();
  const reservationStore = useContext(ReservationDetailStore.Context);
  const [submit, submitStatus] = useTransaction(async () => {
    interview &&
      (await answerInterview({
        treatmentId: reservationId,
        answers: safeFilter(interview.questions || []).map(question => ({
          id: String(question.id || 0),
          answer: safeFilter(question.answer || []).map(ans => ({
            id: String(ans.option?.id || 0),
            label: ans.option?.value || '',
            value: ans.answer || '',
          })),
        })),
        isSubmitted: true,
      }));
    push(
      `${Routes.Paths.ReservationPreInterviewSuccess}?${new URLSearchParamsMonad('')
        .accept('clinicName', reservationStore.reservation?.clinic.name || '')
        .accept('date', (reservationStore.reservation?.date || new Date()).toISOString())
        .accept('duration', String(reservationStore.reservation?.duration || 15))
        .toString()}`,
      reservationId,
    );
  });

  const categories = (interview?.questions || [])
    .map(question => question && { ...question, ...transformer([], question), category: '問診内容' })
    .reduce((res, elem) => {
      const last = res.slice(-1);
      if (last[0] && last[0].category === elem?.category) {
        return [...res.slice(0, -1), { ...last[0], items: [...last[0].items, elem] }];
      }
      return [
        ...res.slice(0),
        {
          category: elem?.category || '',
          items: [elem],
        },
      ];
    }, new Array<{ category: string; items: QuestionType }>());

  return (
    <Wrapper>
      <CaptionGroup>
        <Caption type="header">入力内容をご確認ください</Caption>
      </CaptionGroup>
      <ConfirmationFormArea>
        {categories.map((category, ci) => (
          <ConfirmationFormGroup key={ci}>
            <ConfirmationGroupTitle editable onClickEdit={() => onBack?.()}>
              {category.category}
            </ConfirmationGroupTitle>
            {category.items.map(q => (
              <>
                {q && (
                  <ConfirmationFormItem key={q.id}>
                    <ConfirmationFormLabel>{q.confirm_text}</ConfirmationFormLabel>
                    <ConfirmationFormItemValue question={q} />
                  </ConfirmationFormItem>
                )}
              </>
            ))}
          </ConfirmationFormGroup>
        ))}
        <ConfirmationFormItem>
          <Button
            type="button"
            block
            onClick={submit}
            disabled={submitStatus.running}
            className={GtmClasses.gtm.registration.preInterview.finish}
          >
            この内容で問診確定する
          </Button>
        </ConfirmationFormItem>
      </ConfirmationFormArea>
    </Wrapper>
  );
});
