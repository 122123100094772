import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Caption, CaptionGroup } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { Icon } from '../../../components/Icon';
import { Wrapper } from '../../../components/Wrapper';
import { SUMMARY } from '../../../constants/CheckupResult';
import { Routes } from '../../../constants/Routes';
import { Color, FontSize } from '../../../constants/Style';
import { gutter, square } from '../../../helpers/Style';
import { useTransaction } from '../../../hooks/useTransaction';
import { useTreatmentId } from '../../../hooks/useTreatmentId';
import { Result } from '../../../interfaces/Result';
import { VacanciesStore } from '../../../pages/Reservations/stores/VacanciesStore';
import { SessionStore } from '../../../stores/SessionStore';
import { Accordion } from '../components/Accordion';
import { ReserveButton } from '../components/ReserveButton';
import { ResultRound } from '../components/ResultRound';
import { StrategicResultItemCards } from '../components/StrategicResultItemCard';
import { ToTopButton } from '../components/ToTopButton';
import { ItemInfo } from '../Summary/components/OtherItems/ItemInfo';
import { GroupInfoModal } from './components/GroupInfoModal';
import { BaseStore } from './stores/BaseStore';

export const CheckupResultBasePage = observer(() => {
  const sessionStore = useContext(SessionStore.Context);
  const store = useMemo(() => new BaseStore(), []);
  const vacanciesStore = useMemo(() => new VacanciesStore(), []);
  const history = useHistory();
  const [doFetchInitial] = useTransaction((subGroupId, treatmentId) => store.fetchInitial(subGroupId, treatmentId));
  const [modalOpen, setModalOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const params = useParams<{ treatmentId: string; subGroupId: string }>();
  const week = new Date();
  const { treatmentId, push } = useTreatmentId();

  useEffect(() => {
    if (!sessionStore?.user) {
      return;
    }
    store.setProfile({ id: sessionStore.user.userId });
    doFetchInitial(params.subGroupId, treatmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore?.user, params]);

  const backSummary = () => {
    push(Routes.Paths.CheckupResultSummary, treatmentId);
  };

  const toOtherSubGroup = (subGroupId: string) => {
    push(Routes.Paths.CheckupResultBase.replace(':subGroupId', subGroupId), treatmentId);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const toReservation = () => {
    history.push(`${Routes.Paths.ReservationNew}?departmentId=${store.departmentId}`);
  };

  useEffect(() => {
    if (store.departmentId && store.departmentClinicId) {
      vacanciesStore.fetchNearestOneData(week, {
        id: store.departmentId,
        clinic: store.departmentClinicId,
      });
    }
  }, [store.departmentId, store.departmentClinicId, vacanciesStore, week]);

  return (
    <div>
      <Wrapper>
        <div className={mainContainerStyle}>
          <div className={pageBackStyle} onClick={() => backSummary()}>
            <Icon name="angle-left" />
            <Caption type="small">結果サマリー</Caption>
          </div>
          <div className={headerContainerStyle}>
            <Caption type="header" align="center" underline>
              {store.subGroup?.name}
            </Caption>
            {store.subGroup?.description && (
              <div className={questionIconContainerStyle} onClick={() => setModalOpen(true)}>
                <Icon name="question-circle" />
              </div>
            )}
          </div>
          <GroupInfoModal
            name={store.subGroup?.name}
            description={store.subGroup?.description || ''}
            modalOpen={modalOpen}
            onClick={setModalOpen}
          />
          <p className={evaluationHeaderStyle}>
            <Caption type="subheader">カテゴリ評価</Caption>
          </p>
          <div className={resultCardContainerStyle}>
            <Card>
              <div className={resultContainerStyle}>
                <ResultRound result={store.subGroup?.result as Result} medium />
                <p className={cx(resultWordStyle[store.subGroup?.result as Result], WordStyle)}>
                  {store.subGroup?.result === 'A' ||
                  store.subGroup?.result === 'B' ||
                  store.subGroup?.result === 'C' ||
                  store.subGroup?.result === 'D'
                    ? SUMMARY[store.subGroup.result]
                    : '-'}
                </p>
              </div>
              <p>{store.subGroup?.subgroupResult.comment}</p>
            </Card>
          </div>
          {store.departmentName && (
            <ReserveButton
              onClick={toReservation}
              departmentName={store.departmentName}
              nearest={vacanciesStore.nearestVacancy}
            />
          )}
          <CaptionGroup>
            <Caption type="subheader">検査項目詳細</Caption>
          </CaptionGroup>
          {store.subGroup && <StrategicResultItemCards store={store.subGroup} line />}
        </div>
      </Wrapper>
      <div className={bottomContainerStyle}>
        <Card>
          <div className={accordionContainerStyle}>
            <Wrapper>
              <Accordion
                groupName={store.subGroup?.subGroup.group?.friendlyName}
                open={accordionOpen}
                setOpen={setAccordionOpen}
              />
              {accordionOpen &&
                store.treatment?.checkupAppointment?.result?.judged
                  .filter(judge => judge.subGroup?.group?.id === store.subGroup?.subGroup.group?.id)
                  .map((filterJudge, index) => (
                    <div
                      key={index}
                      className={cx(pointerStyle)}
                      onClick={() => toOtherSubGroup(filterJudge.subGroupId)}
                    >
                      <ItemInfo name={filterJudge.subGroup?.name} result={filterJudge.result as Result} />
                    </div>
                  ))}
            </Wrapper>
          </div>
          <Wrapper>
            <ToTopButton />
          </Wrapper>
        </Card>
      </div>
    </div>
  );
});

const pageBackStyle = css`
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  > svg {
    ${square(20)}
  }
`;

const headerContainerStyle = css`
  display: flex;
  justify-content: center;
  margin-left: 25px;
`;

const evaluationHeaderStyle = css`
  display: flex;
  align-items: center;
  margin-left: ${gutter(2.5)};

  > h3 {
    margin-right: ${gutter(2.5)};
  }
`;

const questionIconContainerStyle = css`
  display: flex;
  padding: ${gutter(3)} 0 0 ${gutter(1.75)};

  > svg {
    ${square(24)}

    fill: ${Color.FunctionalBlueNeutral}
  }
`;

const resultCardContainerStyle = css`
  margin: ${gutter(5)} auto ${gutter(6.25)};
`;

const resultContainerStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${gutter(5.25)};
`;

const resultWordStyle: Record<Result, string> = {
  A: textColor(Color.NotingResult),
  B: textColor(Color.AttentionResult),
  C: textColor(Color.ConsultationResult),
  D: textColor(Color.TreatmentResult),
};

function textColor(color: Color) {
  return css`
    color: ${color};
  `;
}

const WordStyle = css`
  margin-left: ${gutter(2.5)};
  font-size: ${FontSize.ExtraLarge};
  font-weight: bold;
`;

const mainContainerStyle = css`
  margin-bottom: ${gutter(12)};
`;

const accordionContainerStyle = css`
  padding: ${gutter(13)} 0 ${gutter(17.5)};
`;

const pointerStyle = css`
  cursor: pointer;
`;
const bottomContainerStyle = css`
  margin-bottom: -16px;

  > article {
    padding-bottom: ${gutter(15)};
  }
`;
