import { format, getDate, getDaysInMonth, getMonth, getYear, parse } from 'date-fns/esm';
import { css } from 'emotion';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { ComponentProps, useEffect, useMemo } from 'react';

import { Card } from '../Card';
import { ErrorLabel, FormItem, FormLabel } from '../Form';
import { Select } from '../Select';
import { errorStyle, InterviewReactiveFormItem } from '.';

type Props = ComponentProps<typeof InterviewReactiveFormItem>;

export const InterviewDateInput = observer(({ value, def, onChange, errors, analyticsClassName }: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new DateStore(value[0]?.value), []);

  useEffect(() => {
    onChange([
      {
        id: String(def.options?.[0]?.id || 0),
        label: def.options?.[0]?.label || '',
        value: store.dateStr,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.dateStr]);

  useEffect(() => {
    if (!value[0]?.value) {
      return;
    }
    store.setByFormattedString(value[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value[0]?.value]);

  return (
    <FormItem>
      <FormLabel required={!!def.required}>{def.label}</FormLabel>
      <Card className={errors && errorStyle}>
        <div className={dateStyle}>
          <div>
            <Select
              color="white"
              value={String(store.year)}
              onChange={v => store.setYear(parseInt(v, 10))}
              analyticsClassName={`${analyticsClassName}_year`}
            >
              {store.years.map((year, key) => (
                <option value={year} key={key}>
                  {year}年
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              color="white"
              value={String(store.month)}
              onChange={v => store.setMonth(parseInt(v, 10))}
              analyticsClassName={`${analyticsClassName}_month`}
            >
              {store.months.map((month, key) => (
                <option value={month} key={key}>
                  {month}月
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              color="white"
              value={String(store.date)}
              onChange={v => store.setDate(parseInt(v, 10))}
              analyticsClassName={`${analyticsClassName}_date`}
            >
              {store.dates.map((date, key) => (
                <option value={date} key={key}>
                  {date}日
                </option>
              ))}
              月
            </Select>
          </div>
        </div>
      </Card>
      <ErrorLabel errors={errors} />
    </FormItem>
  );
});

const dateStyle = css`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 8px;
`;

const now = new Date();

class DateStore {
  constructor(defaultValue?: string) {
    makeAutoObservable(this, {
      year: observable,
      month: observable,
      date: observable,
      setYear: action,
      setMonth: action,
      setDate: action,
      setByFormattedString: action,
      dateStr: computed,
      years: observable,
      months: observable,
      dates: computed,
      dateValue: computed,
    });

    if (defaultValue) {
      this.setByFormattedString(defaultValue);
    }
  }

  public year = 1990;
  public month = 1;
  public date = 1;

  public years: number[] = new Array(100).fill(undefined).map((_, i) => getYear(now) - (98 - i));
  public months: number[] = new Array(12).fill(undefined).map((_, i) => i + 1);

  public get dates() {
    return new Array(getDaysInMonth(this.dateValue)).fill(undefined).map((_, i) => i + 1);
  }

  setYear(year: number) {
    this.year = year;
  }

  setMonth(month: number) {
    this.month = month;
  }

  setDate(date: number) {
    this.date = date;
  }

  setByFormattedString(str: string) {
    const date = parse(str, DateStore.FORMAT, new Date());
    runInAction(() => {
      this.year = getYear(date);
      this.month = getMonth(date) + 1;
      this.date = getDate(date);
    });
  }

  get dateStr() {
    return format(new Date(this.year, this.month - 1, this.date), DateStore.FORMAT);
  }

  get dateValue() {
    return new Date(this.year, this.month - 1, this.date);
  }

  public static FORMAT = 'yyyy-MM-dd';
}
