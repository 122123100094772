import { css } from 'emotion';
import { observer } from 'mobx-react-lite';
import React, { ComponentProps, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useTransaction } from '../../../hooks/useTransaction';
import { ReservationDetailStore } from '../../../pages/Reservations/ReservationDetail/stores/ReservationDetailStore';
import { Caption } from '../../Caption';
import { FormItem, FormLabel } from '../../Form';
import { InterviewReactiveFormItem } from '..';
import { InterviewUploadStore } from './stores/InterviewUploadStore';
import { UploadDialog } from './UploadDialog';
import { UploadedPreviews } from './UploadedPreviews';

type Prop = ComponentProps<typeof InterviewReactiveFormItem> & {
  disabled?: boolean;
};

const DELIMITER = '|';

export const InterviewUploadInput = observer(({ def, onChange, value, disabled }: Prop) => {
  const reservationStore = useContext(ReservationDetailStore.Context);
  const store = useMemo(() => new InterviewUploadStore(), []);
  const triggerOnChange = () => {
    const option = def.options?.[0];
    option &&
      !disabled &&
      onChange([
        {
          id: String(option.id || 0),
          label: option.label || '',
          value: store.keys.filter(Boolean).join(DELIMITER),
        },
      ]);
  };

  const [upload, uploadStatus] = useTransaction(() =>
    store.upload().then(() => {
      setModalOpen(false);
      triggerOnChange();
    }),
  );

  useEffect(() => {
    reservationStore?.reservation?.interview?.id && store.setInterviewId(reservationStore.reservation.interview.id);
    def.id && store.setQuestionId(def.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationStore?.reservation?.interview?.id, def.id]);

  const keys = useMemo(() => (value[0]?.value || '').split(DELIMITER).filter(Boolean).sort(), [value]);

  useEffect(() => {
    store.setKeys(keys);
    store.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys]);

  const [modalOpen, setModalOpen] = useState(false);

  const removeKey = (key: string) => {
    store.removeKey(key);
    triggerOnChange();
  };

  return (
    <FormItem>
      <FormLabel required={!!def.required}>{def.label}</FormLabel>
      <div>
        <UploadDialog
          onChange={files => store.setFiles(files)}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          disabled={store.files.length === 0 || uploadStatus.running}
          onUpload={() => upload()}
        />
        {!disabled && (
          <div className={descriptionStyle}>
            <Caption type="body">写真の例）</Caption>
            <PhotoExampleContainer>
              <div>
                <img src="/images/photo-sample1.png" alt="(1)患部のアップ" />
                <Caption type="small">(1)患部のアップ</Caption>
              </div>
              <div>
                <img src="/images/photo-sample2.png" alt="(2)部位全体" />
                <Caption type="small">(2)部位全体</Caption>
              </div>
            </PhotoExampleContainer>
            <Caption type="body">
              {`＜撮影のポイント＞
・明るいところで撮る
・影が映らないようにする
・ぶれないようにする
・ピントを合わせる
※ピントを合わせてからズームをするときれいに撮影できます。`}
            </Caption>
          </div>
        )}
        <div>
          <UploadedPreviews
            files={store.urls.map(u => ({ filename: u.filename, fileKey: u.key, fileUrl: u.url }))}
            onDelete={k => removeKey(k)}
            onClickUpload={() => setModalOpen(true)}
            disabled={disabled}
          />
        </div>
      </div>
    </FormItem>
  );
});

type ChildProps = {
  children: ReactNode;
};

const PhotoExampleStyle = css`
  display: grid;
  grid-template-columns: 120px 120px;
  column-gap: 16px;
  margin: 16px 0;
`;

const PhotoExampleContainer = ({ children }: ChildProps) => <div className={PhotoExampleStyle}>{children}</div>;

const descriptionStyle = css`
  margin: 16px 0;
`;
