import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Modal } from '../../../../../../components/Modal';
import { Routes } from '../../../../../../constants/Routes';
import { ReservationStore } from '../../../stores/ReservationStore';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const feverDepartment = '09';

export const FeverWarningDialog = observer(({ visible, onClose }: Props) => {
  const [confirming, setConfirming] = useState(false);

  const onReservationNew = () => {
    window.location.href = `${Routes.Paths.ReservationNew}?department=${feverDepartment}#DepartmentSelect`;
  };

  return (
    <Modal visible={visible}>
      {confirming ? (
        <Card>
          <CaptionGroup>
            <Caption type="danger">
              発熱、せき・のどの痛み、下痢、吐き気、痛みを伴う目の充血などの症状がある方は、発熱・感染症外来をご利用ください。
            </Caption>
          </CaptionGroup>
          <ButtonGroup gutter={10}>
            <Button onClick={onReservationNew}>発熱・感染症外来で予約する</Button>
          </ButtonGroup>
        </Card>
      ) : (
        <Card>
          <CaptionGroup>
            <Caption type="danger">
              発熱、せき・のどの痛み、下痢、吐き気、痛みを伴う目の充血などの症状はありますか？
            </Caption>
          </CaptionGroup>
          <ButtonGroup gutter={10}>
            <Button onClick={() => setConfirming(true)}>ある／少しある</Button>
            <Button onClick={() => onClose()}>全くない</Button>
          </ButtonGroup>
        </Card>
      )}
    </Modal>
  );
});

const departments = [
  '01', // 内科,
  '02', // 皮膚科
  '04', // 泌尿器科
];

export const useFeverWarning = (store: ReservationStore) => {
  const [visible, setVisible] = useState(departments.includes(store.department?.id ?? ''));
  return {
    visible,
    onClose: () => setVisible(false),
  };
};
