import { format, parse } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Alignment, Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { CheckboxGroup, FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { TextArea } from '../../../../../components/TextArea';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { useContext } from '../../../../../hooks/useContext';
import { useScrollToTop } from '../../../../../hooks/useScrollToTop';
import { CheckupVacancyStore } from '../CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { CheckupReservationStore } from '../stores/CheckupReservationStore';
import { CheckupOptionCheckbox } from './components/CheckupOptionCheckbox';

type Props = {
  onNext?: () => void;
  onBack?: () => void;
};
export const CheckupReservationBasicInfoInputPage = observer(({ onNext, onBack }: Props) => {
  const store = useContext(CheckupReservationStore.Context);

  useScrollToTop();

  // make cache ahead of user page transition
  const week = useMemo(() => new Date(), []);
  const vacancyStore = useContext(CheckupVacancyStore.Context);
  useEffect(() => {
    store.department &&
      vacancyStore.fetchVacancies(
        week,
        store.department,
        store.options.map(o => o.id),
        store.treatmentKind,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.options]);

  useEffect(() => {
    store.fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <DocumentTitle title="健診オプション選択" />
      <FormLayout>
        <FormItem>
          <FormLabel required>氏名</FormLabel>
          <TextField color="white" value={store.name} onChange={c => store.setName(c)} />
        </FormItem>
        <FormItem>
          <FormLabel required>生年月日</FormLabel>
          <TextField
            color="white"
            type="date"
            value={store.birthDate && format(store.birthDate, 'yyyy-MM-dd')}
            onChange={c => store.setBirthDate(parse(c, 'yyyy-MM-dd', new Date()))}
            disabled
          />
        </FormItem>
        {store.checkupOrganization?.kitDeliverType === '1_organization' ? (
          ''
        ) : (
          <FormItem>
            <FormLabel required>キット送付先の住所</FormLabel>
            <TextArea color="white" value={store.kitDeliveryAddress} onChange={v => store.setKitDeliveryAddress(v)} />
          </FormItem>
        )}
        <FormItem>
          <FormLabel>追加されるオプションを選択してください</FormLabel>
          <Card>
            <div>おすすめのオプション</div>
            <CheckboxGroup>
              {store.selectableOptions
                .filter(o => o.suggestText)
                .map((option, key) => (
                  <CheckupOptionCheckbox
                    price={option.regularPrice}
                    discountPrice={option.price !== option.regularPrice ? option.price : undefined}
                    label={option.name}
                    value={option.id}
                    checked={store.options.some(o => o.id === option.id)}
                    onChange={v => (v.target.checked ? store.addOption(option) : store.removeOption(option))}
                    key={key}
                    suggestText={option.suggestText}
                    billingType={option.billType}
                    suggestSeverity={option.suggestSeverity}
                  />
                ))}
            </CheckboxGroup>
          </Card>
        </FormItem>
        <FormItem>
          <div>その他のオプション</div>
          <Card>
            <CheckboxGroup>
              {store.selectableOptions
                .filter(o => !o.suggestText)
                .map((option, key) => (
                  <CheckupOptionCheckbox
                    price={option.regularPrice}
                    discountPrice={option.price !== option.regularPrice ? option.price : undefined}
                    label={option.name}
                    value={option.id}
                    checked={store.options.some(o => o.id === option.id)}
                    onChange={v => (v.target.checked ? store.addOption(option) : store.removeOption(option))}
                    key={key}
                    suggestText={option.suggestText}
                    billingType={option.billType}
                    suggestSeverity={option.suggestSeverity}
                  />
                ))}
            </CheckboxGroup>
          </Card>
        </FormItem>
        <FormItem>
          <Card>
            <Caption type="subheader">合計負担額(税込み)</Caption>
            <Alignment align="right">
              {`¥ ${store.options
                .filter(o => o.billType !== '1_organization')
                .map(o => o.price)
                .reduce((a, b) => a + b, 0)
                .toLocaleString()} -`}
            </Alignment>
          </Card>
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={16}>
        <Button onClick={onNext} disabled={!store.basicInfoValid}>
          予約日の選択へ
        </Button>
        <Button ghost onClick={onBack}>
          問診に戻る
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
