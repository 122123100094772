import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Layout } from '../../components/Layout';
import { Routes } from '../../constants/Routes';
import { AccountNew } from '../../pages/Accounts/AccountsNew';
import { AccountsNewLegacyConfirmPage } from '../../pages/Accounts/AccountsNew/AccountsNewLegacy/AccountsNewLegacyConfirm';
import { AccountsNewPharmacyPage } from '../../pages/Accounts/AccountsNew/AccountsNewPharmacy';
import { AggressiveBotLink } from '../../pages/Accounts/AggressiveBotLink';
import { StartAuthRedirection } from '../../pages/Accounts/AuthRedirection';
import { SignInLegacyPage } from '../../pages/Accounts/SignInLegacy';
import { ForgotPasswordPage } from '../../pages/Accounts/SignInLegacy/ForgotPassword';
import { ResetPasswordPage } from '../../pages/Accounts/SignInLegacy/ResetPassword';
import { AppleCalendarPage } from '../../pages/AppleCalendar';
import { AttachmentPage } from '../../pages/Attachments';
import { IdpResponsePage } from '../../pages/Auth/IdpResponse';
import { IndividualCheckupReservationNewPage } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationsNew';
import { Demo2Page } from '../../pages/Demo2';
import { FaqDetailPage } from '../../pages/Faq/FaqDetail';
import { FaqListPage } from '../../pages/Faq/FaqList';
import { NotFoundPage } from '../../pages/NotFound';
import { PrivacyPolicyPage } from '../../pages/Policies/Privacy';
import { UsagePolicyPage } from '../../pages/Policies/Usage';
import { ReservationNewPage } from '../../pages/Reservations/ReservationNew';
import { OnlineReservationNewPage } from '../../pages/Reservations/ReservationNew/online';
import { VacanciesDisplayPage } from '../../pages/Vacancies/VacanciesDisplay';
import { WindowUtil } from '../../utils/WindowUtil';
// import { AccountsNewPhoneConfirmPage } from '../../pages/Accounts/AccountsNew/AccountsNewPhone/AccountsNewPhoneConfirm';

/**
 * ユーザがログインしていない時は、このコンポーネントを読み込むこと。
 */
export const Guest = () => (
  <Switch>
    <Route path={Routes.Paths.EmbeddedVacancies}>
      <VacanciesDisplayPage />
    </Route>
    <Route>
      <Layout>
        <Switch>
          <Route path={Routes.Paths.Root} exact>
            <Redirect to={Routes.Paths.ReservationNew} />
          </Route>
          <Route path={Routes.Paths.UsagePolicy} exact>
            <UsagePolicyPage />
          </Route>
          <Route path={Routes.Paths.PrivacyPolicy} exact>
            <PrivacyPolicyPage />
          </Route>
          <Route path={Routes.Paths.ReservationNew}>
            <ReservationNewPage />
          </Route>
          <Route path={Routes.Paths.ReservationNewOnline}>
            <OnlineReservationNewPage />
          </Route>
          <Route path={Routes.Paths.AccountNew}>
            <AccountNew />
          </Route>
          <Route path={Routes.Paths.AccountLineConnect}>
            <AccountNew />
          </Route>
          <Route path={Routes.Paths.AccountConfirm}>
            <AccountsNewLegacyConfirmPage />
            {/* <AccountsNewPhoneConfirmPage /> */}
          </Route>
          <Route path={Routes.Paths.IdpResponse}>
            <IdpResponsePage />
          </Route>
          <Route path="/demo2">
            <Demo2Page />
          </Route>
          <Route path={Routes.Paths.SignIn}>
            <SignInLegacyPage />
          </Route>
          <Route path={Routes.Paths.ReservationNew}>
            <ReservationNewPage />
          </Route>
          <Route path={Routes.Paths.CheckupReservationNew}>
            <AccountNew />
          </Route>
          <Route path={Routes.Paths.FaqList} exact>
            <FaqListPage />
          </Route>
          <Route path={Routes.Paths.FaqShow} exact>
            <FaqDetailPage />
          </Route>
          <Route path={Routes.Paths.CheckupReservationNewIndividual} exact>
            <IndividualCheckupReservationNewPage />
          </Route>
          <Route path={Routes.Paths.ForgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route path={Routes.Paths.AccountNewPharmacy}>
            <AccountsNewPharmacyPage />
          </Route>
          <Route path={Routes.Paths.ResetPassword}>
            <ResetPasswordPage />
          </Route>
          <Route path={Routes.Paths.AuthorizedAttachment}>
            <AttachmentPage />
          </Route>
          <Route path={Routes.Paths.StartAuth}>
            <StartAuthRedirection />
          </Route>
          <Route path={Routes.Paths.AggressiveBotLink}>
            <AggressiveBotLink />
          </Route>
          {Routes.AuthRoutes.map((path, i) => (
            <Route key={i} path={path} exact>
              <Unauthorized />
            </Route>
          ))}
          <Route
            path={Routes.Paths.GoogleCalendarURLSchemeRedirect}
            render={() => {
              if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                WindowUtil.openInDefaultBrowser(`com.google.calendar://?${window.location.search}`);
              } else {
                WindowUtil.openInDefaultBrowser(`https://www.google.com/calendar/render?${window.location.search}`);
              }
              return <>Google Calendarアプリにリダイレクトしています。</>;
            }}
          />
          <Route path={Routes.Paths.addToIPhoneCalendar}>
            <AppleCalendarPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Layout>
    </Route>
  </Switch>
);

const Unauthorized = () => (
  <Redirect
    to={{
      pathname: Routes.Paths.AccountNew,
      search: new URLSearchParams({ url: window.location.pathname, action: 'redirect' } as Record<
        'url' | 'action',
        string
      >).toString(),
    }}
  />
);
