import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { BreadCrumb } from '../../../components/BreadCrumb';
import { Card } from '../../../components/Card';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { FaqStore } from '../stores/FaqStore';
import { FaqTitle } from './components/FaqTitle';
import { Markdown } from './components/Markdown';

type Props = {
  questionId: string;
};
export const FaqDetailPage = observer(() => {
  const store = useMemo(() => new FaqStore(), []);
  const { params } = useRouteMatch<Props>();
  const { push } = useHistory();

  useEffect(() => {
    store.fetch(params.questionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Wrapper>
      {store.detail && (
        <>
          <DocumentTitle title={`ヘルプ - ${store.detail.title}`} />
          <BreadCrumb
            items={[
              { label: 'TOP', onClick: () => window.location.replace('/') },
              { label: 'ヘルプ', onClick: () => push(Routes.Paths.FaqList) },
              { label: store.detail.title, onClick: () => window.location.replace('/') },
            ]}
          />
          <FaqTitle title={store.detail.title} updatedAt={new Date(store.detail.updatedAt)} />
          <div className={bodyStyle}>
            <Card>
              <Markdown body={store.detail.body} />
            </Card>
          </div>
        </>
      )}
    </Wrapper>
  );
});

const bodyStyle = css`
  margin: 16px 0;
`;
