const Environments = {
  local: {
    Amplify: {
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `http://localhost:3000/auth/idpresponse/`,
          redirectSignOut: `http://localhost:3000/`,
          clientId: '2gugdrvg35k4ujl1tu1b9lpkfp',
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-dev.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40208btznj`,
    liffId: '1657395185-b6XG8mYa',
  },
  dev: {
    Amplify: {
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://${process.env.APP_ENV}.clinicten.jp/auth/idpresponse/`,
          redirectSignOut: `https://${process.env.APP_ENV}.clinicten.jp/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40208btznj`,
    liffId: '1654883109-LAOGQ0vj',
  },
  dev2: {
    Amplify: {
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://${process.env.APP_ENV}.clinicten.jp/auth/idpresponse/`,
          redirectSignOut: `https://${process.env.APP_ENV}.clinicten.jp/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40208btznj`,
    liffId: '1654883109-LAOGQ0vj',
  },
  ren1: {
    Amplify: {
      region: 'ap-northeast-1',
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://${process.env.APP_ENV}.clinicten.jp/auth/idpresponse/`,
          redirectSignOut: `https://${process.env.APP_ENV}.clinicten.jp/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40208btznj`,
    liffId: '1657395185-b6XG8mYa',
  },
  stg: {
    Amplify: {
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://${process.env.APP_ENV}.clinicten.jp/auth/idpresponse/`,
          redirectSignOut: `https://${process.env.APP_ENV}.clinicten.jp/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40791mvxiw`,
    liffId: '1655777674-j204V5yw',
  },
  prod: {
    Amplify: {
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://reserve.clinicten.jp/auth/idpresponse/`,
          redirectSignOut: `https://reserve.clinicten.jp/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40791mvxiw`,
    liffId: '1655807621-nwMoEkV6',
  },
  default: {
    Amplify: {
      region: 'ap-northeast-1',
      Auth: {
        identityPoolId: process.env.APP_IDENTITY_POOL_ID,
        region: 'ap-northeast-1',
        userPoolId: process.env.APP_USER_POOL_ID,
        userPoolWebClientId: process.env.APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: `clinic-${process.env.APP_ENV}.auth.ap-northeast-1.amazoncognito.com`,
          scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `${new URL(window.location.href).origin}/auth/idpresponse/`,
          redirectSignOut: `${new URL(window.location.href).origin}/`,
          clientId: process.env.APP_USER_POOL_CLIENT_ID,
          responseType: 'code',
        },
      },
      aws_appsync_graphqlEndpoint: process.env.APP_GQL_ENDPOINT,
      aws_appsync_region: 'ap-northeast-1',
    },
    publicAssetUrl: `https://clinic-public-asset-${process.env.APP_ENV}.s3-ap-northeast-1.amazonaws.com`,
    friendRequestUrl: `https://line.me/R/ti/p/%40208btznj`,
    liffId: '',
  },
};

type EnvironmentName = keyof typeof Environments;

const getEnvironment = (envName: string) => {
  const isExplicitEnvironment = (x: string): x is EnvironmentName => x in Environments;
  if (isExplicitEnvironment(envName)) {
    return Environments[envName];
  }
  return Environments.default;
};

export const Environment = getEnvironment(process.env.APP_ENV || 'local');

export const getFrontUrl = () => {
  if (!process.env.APP_ENV || process.env.APP_ENV === 'local') {
    return 'http://localhost:3000';
  }
  if (process.env.APP_ENV === 'prod') {
    return 'https://reserve.clinicten.jp';
  }
  return `https://${process.env.APP_ENV}.clinicten.jp`;
};

export const isLiffEnabled = () => process.env.APP_ENV !== 'local' && Environment.liffId;

export const frontLiffUrl = (route: string) =>
  `https://liff.line.me/${Environment.liffId}/?customState=${encodeURIComponent(route)}&origin=${encodeURIComponent(
    getFrontUrl(),
  )}`;
