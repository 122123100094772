import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Gutter } from '../../../../components/Gutter';
import { Modal } from '../../../../components/Modal';
import { Wrapper } from '../../../../components/Wrapper';
import { useContext } from '../../../../hooks/useContext';
import { useTransaction } from '../../../../hooks/useTransaction';
import { SessionStore } from '../../../../stores/SessionStore';
import { VacanciesStore } from '../../stores/VacanciesStore';
import { CourseStore } from '../stores/CourseStore';
import { ReservationStore } from '../stores/ReservationStore';
import { SubtitledCourseCardLayout } from './components/CourseCardLayout';
import { PasswordInputDialog } from './components/PasswordInputDialog';

type Props = {
  onNext: () => void;
};

export const CourseSelectPage = observer(({ onNext: onSelect }: Props) => {
  const store = useContext(ReservationStore.Context);
  const courses = useMemo(() => new CourseStore(), []);
  const session = useContext(SessionStore.Context);

  const [loadCourses, loadCoursesStatus] = useTransaction(async () => {
    if (!store.department) return;

    courses.setDepartment(store.department);
    store.revisit && courses.setRevisit(store.revisit);
    await courses.fetchCourses(session.authenticated);
  });

  useEffect(() => {
    loadCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authenticated, store.department]);

  const week = useMemo(() => new Date(), []);

  const vacancyStore = useContext(VacanciesStore.Context);

  useEffect(() => {
    const { department } = courses;
    if (!department) return;
    courses.courses.forEach(c => vacancyStore.prefetch(week, department, store.online, c));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses.courses]);

  const [passwordInput, setPasswordInput] = useState(false);

  const [validatePasswordError, setValidatePasswordError] = useState('');
  const [validatePassword, validatePasswordStatus] = useTransaction(async (password: string) => {
    const res = await store.validateVaccineId(password);
    if (!res?.valid) {
      setValidatePasswordError(res?.error || 'パスワードが違います。');
      throw res?.error;
    }
    setPasswordInput(false);
    onSelect();
  });

  const onSelectTreatmentKind = (id: string) => {
    const selected = courses.nextCourses.find(c => c.id === id) || courses.courses.find(c => c.id === id);
    if (!selected) {
      return;
    }
    store.setTreatmentKind(selected);
    if (!selected.vaccine) {
      onSelect();
    }
    setPasswordInput(true);
  };

  const subTitles = useMemo(() => {
    const d = store.department;
    if (!d) {
      return courses.subTitles;
    }
    return courses.subTitles.map(s => ({
      ...s,
      // DB側修正までフロント側の固定値で暫定対応
      nearest:
        // department.id 12は美容皮膚科を指す
        d.id === '12'
          ? ''
          : vacancyStore.findNearest(
              week,
              d.clinic,
              d.id,
              s.courses.map(c => c.id),
            ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.department, courses.subTitles, vacancyStore.resolvedAt]);

  return (
    <Wrapper>
      <Modal visible={!!loadCoursesStatus.running}>
        <Card>コースを読み込み中です</Card>
      </Modal>
      <DocumentTitle title="コース選択" />
      <Gutter vertical={10}>
        <Caption type="header">希望するコースを選択してください</Caption>
        <Caption type="body">
          {store.department?.id !== 'D'
            ? '※12歳未満の方は当院では診療を承っておりません。小児科等の受診をお願いいたします。'
            : ''}
        </Caption>
      </Gutter>
      <PasswordInputDialog
        open={passwordInput}
        onSubmit={v => validatePassword(v)}
        disabled={validatePasswordStatus.running}
        error={validatePasswordError}
        onCancel={() => setPasswordInput(false)}
      />
      <SubtitledCourseCardLayout
        subTitles={subTitles}
        nextCourses={courses.nextCourses}
        onClick={c => onSelectTreatmentKind(c.id)}
      />
    </Wrapper>
  );
});
