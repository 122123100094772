export const WindowUtil = {
  download: (url: string, as: string) => {
    if ((window as any).liff?.isInClient?.()) {
      (window as any).liff.openWindow({
        url,
        external: true,
      });
      return;
    }
    const downLoadLink = document.createElement('a');
    downLoadLink.download = as;
    downLoadLink.href = url;
    downLoadLink.click();
  },
  openInDefaultBrowser: (url: string) => {
    if ((window as any).liff?.isInClient?.()) {
      window.open(`${url}&openExternalBrowser=1`);
      return;
    }
    window.location.href = url;
  },
};
