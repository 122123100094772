import { css } from 'emotion';
import React from 'react';

import { Button } from '../../../../components/Button';
import { Caption } from '../../../../components/Caption';
import { FontSize } from '../../../../constants/Style';
import { gutter } from '../../../../helpers/Style';

type Props = {
  onClick: () => void;
  departmentName: string;
  nearest?: { date: string; time: string };
};

const convertDateWord = (date: string) => {
  const today = new Date();
  const month = String(today.getMonth() + 1);
  const day = String(today.getDate());
  if (
    (date.slice(0, 2) === month || date.slice(1, 2) === month) &&
    (date.slice(3, 5) === day || date.slice(4, 5) === day)
  ) {
    return '本日';
  }
  return date;
};
export const ReserveButton = ({ onClick, departmentName, nearest }: Props) => (
  <>
    <div className={containerStyle}>
      <div className={captionContainerStyle}>
        <Caption type="subheader">オンラインでもご相談可能です</Caption>
      </div>
      <Button noBorder variant="functionRedContained" pill floating block onClick={() => onClick()}>
        <div className={buttonContainerStyle}>
          <p>{departmentName}で予約する</p>
          {nearest && (
            <p className={nearestStyle}>
              最短枠:{convertDateWord(nearest.date)}
              {nearest.time}〜
            </p>
          )}
        </div>
      </Button>
    </div>
  </>
);

const containerStyle = css`
  position: relative;
  left: 50%;
  width: 280px;
  margin-bottom: ${gutter(6.5)};
  line-height: 19px;
  text-decoration: none;
  transform: translate(-50%, 0);

  > button {
    height: 57px;
  }
`;

const captionContainerStyle = css`
  margin-bottom: ${gutter(4)};
  text-align: center;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const nearestStyle = css`
  margin-top: ${gutter(1.25)};
  font-size: ${FontSize.Small};
  opacity: 0.5;
`;
