import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { Button } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { Caption } from '../../components/Caption';
import { Card } from '../../components/Card';
import { Modal } from '../../components/Modal';
import { Wrapper } from '../../components/Wrapper';
import { Routes } from '../../constants/Routes';
import { useReservationId } from '../../hooks/useReservationId';
import { useTransaction } from '../../hooks/useTransaction';
import { WindowUtil } from '../../utils/WindowUtil';
import { AttachmentStore } from './stores/AttachmentStore';

export const AttachmentPage = observer(() => {
  const { decode } = useReservationId();
  const { params } = useRouteMatch<{ attachmentId: string }>();
  const store = useMemo(() => new AttachmentStore(), []);

  const [download, downloadStatus] = useTransaction(async () => {
    const link = await store.fetchDownloadableLink();
    if (!link) {
      return;
    }
    WindowUtil.download(link, '添付ファイル.pdf');
  });

  const [load, loadStatus] = useTransaction((id: string) => store.fetch(id));

  useEffect(() => {
    if (!params.attachmentId) {
      return;
    }
    load(decode(params.attachmentId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const { push } = useHistory();

  return (
    <Wrapper>
      <Modal visible={!!downloadStatus.running}>
        <Card>ダウンロードファイルを作成中です・・・</Card>
      </Modal>
      <Modal visible={!!downloadStatus.error || !!loadStatus.error}>
        <Card>
          <Caption type="subheader">ファイルにアクセスする権限がありません。</Caption>
          未ログインの場合は、ログインしてからやり直してください。
          <ButtonGroup>
            <Button block theme="line" onClick={() => push({ pathname: Routes.Paths.AccountNew, search: 'type=LINE' })}>
              LINEでログイン
            </Button>
          </ButtonGroup>
        </Card>
      </Modal>
      <Caption type="header" align="center" underline>
        添付ファイル照会
      </Caption>
      <Card>
        <Caption type="subheader">ファイル名:{store.name}</Caption>
        <ButtonGroup gutter={10}>
          <Button block onClick={() => download()}>
            ファイルをダウンロード
          </Button>
        </ButtonGroup>
      </Card>
    </Wrapper>
  );
});
