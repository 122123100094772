import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../../components/Button';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { useReservationId } from '../../../../../../hooks/useReservationId';
import { useScrollToTop } from '../../../../../../hooks/useScrollToTop';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { safeFilter } from '../../../../../../utils/CollectionUtil';
import {
  ConfirmationFormArea,
  ConfirmationFormGroup,
  ConfirmationFormItem,
  ConfirmationFormItemValue,
  ConfirmationFormLabel,
  ConfirmationGroupTitle,
} from '../../../../../Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { transformer } from '../../../../../Interviews/InterviewsNew/InterviewsInput/transformer';
import { Interviews } from '../../../../../Interviews/InterviewsNew/stores/Interviews';
import { CheckupPreInterviewPostReserveStore } from '../stores/CheckupPreInterviewPostReserveStore';

type QuestionType = NonNullable<NonNullable<Interviews['interview']>['questions']>;

type Props = {
  onBack?: () => void;
};

export const CheckupPreInterviewPostReserveConfirmStep = observer(({ onBack }: Props) => {
  useScrollToTop();
  const store = useContext(CheckupPreInterviewPostReserveStore.Context);
  const { reservationId, push } = useReservationId();
  const [submit, submitStatus] = useTransaction(async () => {
    store.interview &&
      (await store.submitInterview({
        treatmentId: reservationId,
        answers: safeFilter(store.interview.questions || []).map(question => ({
          id: String(question.id || 0),
          answer: safeFilter(question.answer || []).map(ans => ({
            id: String(ans.option?.id || 0),
            label: ans.option?.value || '',
            value: ans.answer || '',
          })),
        })),
      }));
    push(Routes.Paths.ReservationPreInterviewSuccess, reservationId);
  });

  const categories = (store.interview?.questions || [])
    .map(question => question && { ...question, ...transformer([], question), category: '問診内容' })
    .reduce((res, elem) => {
      const last = res.slice(-1);
      if (last[0] && last[0].category === elem?.category) {
        return [...res.slice(0, -1), { ...last[0], items: [...last[0].items, elem] }];
      }
      return [
        ...res.slice(0),
        {
          category: elem?.category || '',
          items: [elem],
        },
      ];
    }, new Array<{ category: string; items: QuestionType }>());

  return (
    <Wrapper>
      <CaptionGroup>
        <Caption type="header">入力内容をご確認ください</Caption>
      </CaptionGroup>
      <ConfirmationFormArea>
        {categories.map((category, ci) => (
          <ConfirmationFormGroup key={ci}>
            <ConfirmationGroupTitle editable onClickEdit={() => onBack?.()}>
              {category.category}
            </ConfirmationGroupTitle>
            {category.items.map(q => (
              <>
                {q && (
                  <ConfirmationFormItem key={q.id}>
                    <ConfirmationFormLabel>{q.confirm_text}</ConfirmationFormLabel>
                    <ConfirmationFormItemValue question={q} />
                  </ConfirmationFormItem>
                )}
              </>
            ))}
          </ConfirmationFormGroup>
        ))}
        <ConfirmationFormItem>
          <Button type="button" block onClick={submit} disabled={submitStatus.running}>
            この内容で送信する
          </Button>
        </ConfirmationFormItem>
      </ConfirmationFormArea>
    </Wrapper>
  );
});
