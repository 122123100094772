import React, { ComponentProps } from 'react';

import { Routes } from '../../../../constants/Routes';
import { MyPageSectionHeader } from '../MyPageSectionHeader';
import { ReservationCardLayout } from './ReservationCardLayout';

type Props = ComponentProps<typeof ReservationCardLayout>;
export const ReservationCards = ({ reservations }: Props) => (
  <>
    <section>
      <MyPageSectionHeader href={Routes.Paths.Reservations} icon="calendar">
        直近の予約
      </MyPageSectionHeader>
      <ReservationCardLayout reservations={reservations} />
    </section>
  </>
);
