import React, { ComponentProps } from 'react';

import { Routes } from '../../../../constants/Routes';
import { MyPageSectionHeader } from '../MyPageSectionHeader';
import { TreatmentCardLayout } from './TreatmentCardLayout';

type Props = ComponentProps<typeof TreatmentCardLayout>;
export const TreatmentCards = ({ treatments }: Props) => (
  <section>
    <MyPageSectionHeader href={Routes.Paths.MedicalHistories} icon="medical-checkup">
      診察履歴・領収書
    </MyPageSectionHeader>
    <TreatmentCardLayout treatments={treatments} />
  </section>
);
