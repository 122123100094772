import { format } from 'date-fns/esm';
import { css } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Icon } from '../../../../../components/Icon';
import { Color, IconSize } from '../../../../../constants/Style';
import { square } from '../../../../../helpers/Style';

type Props = {
  onClick?: () => void;
  title: string;
  updatedAt: Date;
};
export const FaqListItem = ({ onClick, title, updatedAt }: Props) => (
  <Card onClick={onClick}>
    <div className={contentActionContainerStyle}>
      <div>
        <div>{title}</div>
        <Caption type="small">{format(updatedAt, 'yyyy年M月d日 HH:mm')}</Caption>
      </div>
      <div className={actionStyle}>
        <Icon name="angle-right" />
      </div>
    </div>
  </Card>
);

const actionStyle = css`
  display: flex;
  align-items: center;

  > svg {
    fill: ${Color.GrayscaleNeutral};
    ${square(IconSize.Regular)}
  }
`;

const contentActionContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;
