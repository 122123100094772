import React, { useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { Checkbox } from '../../../components/Checkbox';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { BrowserStateStore } from '../../../stores/BrowserStateStore';

export const OnlineReservationNewPage = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const store = useMemo(() => new BrowserStateStore(), []);
  const pushToReservation = () => {
    history.push(Routes.Paths.ReservationNew);
  };
  const handleOnline = () => {
    store.setOnline();
    if (checked) {
      store.agreeOnlineTreatment();
    }
    pushToReservation();
  };
  // 過去にオンライン診療注意喚起を読んでいる場合はそのまま予約画面へ
  if (store.isAgreedOnlineTreatment()) {
    store.setOnline();
    return <Redirect to={{ pathname: Routes.Paths.ReservationNew }} />;
  }

  return (
    <Wrapper>
      <DocumentTitle title="オンライン診療に関するお知らせ" />
      <CaptionGroup>
        <Caption type="header">オンライン診療に関する注意事項</Caption>
        <Caption type="div">
          当院では質の高い診療を提供するために原則来院での診察を推奨しておりますが、症状が安定しておりなかなか受診のお時間が取れない方に関しては内科、皮膚科・美容皮膚科、薄毛外来でオンライン診療も提供しております。ただし、症状に応じて適宜来院での再診・検査をお願いすることもございます。
        </Caption>
        <Caption type="danger">
          ※ メンズヘルス外来、筋肉増強相談についてはご来院のみで対応させていただきます。
          <br />※
          新型コロナウイルス感染症を疑う以下の症状のある方（発熱、咽頭痛、咳嗽、鼻汁、味覚異常、下痢など）は原則としてご来院での発熱・感染外来受診をお願いしていますが、既に確定診断がされている場合はオンライン診療で対応可能な場合もありますのでご相談ください。
        </Caption>
      </CaptionGroup>
      <CaptionGroup>
        <Checkbox label="次回から表示しない" onChange={() => setChecked(!checked)} checked={checked} />
      </CaptionGroup>
      <div />
      <ButtonGroup>
        <Button onClick={handleOnline}>このままオンライン診療を予約する</Button>
        <Button theme="neutral" ghost onClick={pushToReservation}>
          来院を予約する
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};
