import React, { useContext, useEffect } from 'react';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { useTransaction } from '../../../hooks/useTransaction';
import { CheckupResultCardLayout } from '../../../pages/Root/components/CheckupResultCards/CheckupResultCardLayout';
import { MyPageStore } from '../../../stores/MyPageStore';
import { SessionStore } from '../../../stores/SessionStore';

export const CheckupResultHistoriesPage = () => {
  const sessionStore = useContext(SessionStore.Context);
  const store = useContext(MyPageStore.Context);
  const [doFetchInitial] = useTransaction(async () => store?.fetchInitial());

  useEffect(() => {
    if (!sessionStore?.user) {
      return;
    }
    store?.setProfile({ id: sessionStore.user.userId });
    doFetchInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore?.user]);

  return (
    <Wrapper>
      <DocumentTitle title="健康診断結果" />
      <Caption type="header" underline align="center">
        健康診断結果
      </Caption>
      <CheckupResultCardLayout treatments={store?.hasResultTreatments} wide />
    </Wrapper>
  );
};
