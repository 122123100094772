import isBefore from 'date-fns/isBefore';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Modal } from '../../../../components/Modal';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { Reservation } from '../../../../interfaces/Reservation';
import { MyPageStore } from '../../../../stores/MyPageStore';
import { SessionStore } from '../../../../stores/SessionStore';
import { OrderByBuilder } from '../../../../utils/CollectionUtil';

export const InterviewUnansweredPage = observer(() => {
  const store = useContext(MyPageStore.Context);
  const sessionStore = useContext(SessionStore.Context);
  const [doFetchInitial, fetchStatus] = useTransaction(async () => {
    await store.fetchInitial();
    const nearestUnanswered = findNearest(store.reservations, now);

    if (nearestUnanswered) {
      nearestUnanswered.department.id === 'C'
        ? push(Routes.Paths.CheckupPreInterviewPostReserveEdit, nearestUnanswered.id)
        : push(Routes.Paths.ReservationPreInterview, nearestUnanswered.id);
      return;
    }
    push(Routes.Paths.Root);
  });
  const { push } = useReservationId();
  const now = useMemo(() => new Date(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nearestUnanswered = useMemo(() => findNearest(store.reservations, now), [store.reservations]);

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    store.setProfile({ id: sessionStore.user.userId });
    doFetchInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  return (
    <Wrapper>
      <DocumentTitle title="未回答の問診" />
      <Modal visible={!!fetchStatus.running}>
        <Card>未回答の問診を検索中です。</Card>
      </Modal>

      <Modal visible={!fetchStatus.running && !nearestUnanswered}>
        <Card>未回答の問診が見つかりませんでした。トップページに移動します。</Card>
      </Modal>
    </Wrapper>
  );
});

const findNearest = (reservations: Reservation[], now: Date) =>
  // 現在からの距離が短い順にソートして、未回答のものが残っていたらそれを表示
  new OrderByBuilder<Reservation>()
    .asc(x => Math.abs(now.getTime() - x.date.getTime()))
    .sort(reservations.filter(r => isBefore(r.date, now)))
    .find(p => p.interviewStatus !== 'notNeeded' && p.interviewStatus !== 'finished');
