import React, { ComponentProps, createContext, ReactNode, useCallback, useState } from 'react';

import { useContext } from '../../hooks/useContext';
import { Container } from './Container';
import { Toast } from './Toast';

type Props = {
  children: ReactNode;
};

type Toast = ComponentProps<typeof Toast>;

type Context = {
  addToast: ({ message, icon, theme }: Omit<Toast, 'id'>) => void;
  removeToast: (id: number) => void;
};

export const ToastProvider = ({ children }: Props) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    ({ message, icon, theme }: Omit<Toast, 'id'>) =>
      setToasts(toasts => [...toasts, { id: Date.now(), message, icon, theme }]),
    [],
  );

  const removeToast = useCallback((id: number) => setToasts(toasts => toasts.filter(toast => toast.id !== id)), []);

  return (
    <Context.Provider value={{ addToast, removeToast }}>
      {children}
      <Container toasts={toasts} />
    </Context.Provider>
  );
};

export const useToast = (): Context => useContext(Context);

const Context = createContext<Context | null>(null);
