import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isSameDay from 'date-fns/isSameDay';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { ComponentProps, useMemo } from 'react';

import { Color, FontSize } from '../../../../../../../constants/Style';
import { DepartmentCard } from '.';

type Props = Pick<ComponentProps<typeof DepartmentCard>, 'nearest'>;

export const DepartmentCardFooter = observer(({ nearest }: Props) => {
  const displayDate = useMemo(
    () => (nearest ? formatNearestAvailable(nearest, new Date(Date.now())) : { date: '', time: '' }),
    [nearest],
  );
  return (
    <footer>
      <div className={nearestLabel}>{displayDate.date ? '最短の予約時間：' : null}</div>
      <div className={nearestValue}>
        {nearest ? (
          <>
            <>
              {displayDate.date} {displayDate.time}
            </>
          </>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </footer>
  );
});

const days = ['日', '月', '火', '水', '木', '金', '土'] as const;

/**
 *  直近の空き枠を表示するためのフォーマットを行う
 */
export const formatNearestAvailable = (d: Date, today: Date) => {
  if (isSameDay(d, today)) {
    return { date: '本日', time: format(d, 'HH:mm') };
  }
  return { date: format(d, 'M/d'), day: days[getDay(d)], time: format(d, 'HH:mm') };
};

const nearestLabel = css`
  font-size: ${FontSize.Tiny};
  color: ${Color.GrayscaleNeutral};
`;

const nearestValue = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;
