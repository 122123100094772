import React, { useEffect, useMemo, useState } from 'react';

import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { CheckupPreInterviewPostReserveConfirmStep } from './Confirm';
import { CheckupPreInterviewPostReserveInputStep } from './Input';
import { CheckupPreInterviewPostReserveStore } from './stores/CheckupPreInterviewPostReserveStore';

type STEP = 'input' | 'confirm';

export const CheckupPreInterviewPostReserveInputPage = () => {
  const { reservationId } = useReservationId();
  const store = useMemo(() => new CheckupPreInterviewPostReserveStore(reservationId), [reservationId]);
  const [step, setStep] = useState<STEP>('input');

  useEffect(() => {
    store.startInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <CheckupPreInterviewPostReserveStore.Context.Provider value={store}>
      <DocumentTitle title="追加問診 - 健診" />
      {(() => {
        switch (step) {
          case 'input':
          default:
            return <CheckupPreInterviewPostReserveInputStep onNext={() => setStep('confirm')} />;
          case 'confirm':
            return <CheckupPreInterviewPostReserveConfirmStep onBack={() => setStep('input')} />;
        }
      })()}
    </CheckupPreInterviewPostReserveStore.Context.Provider>
  );
};
