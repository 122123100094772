import { css } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Icon } from '../../../../../components/Icon';
import { Modal } from '../../../../../components/Modal';
import { OGP_URL, TWITTER_URL } from '../../../../../constants/CheckupResult';
import { Color, FontSize } from '../../../../../constants/Style';
import { gutter, square } from '../../../../../helpers/Style';
import { Result } from '../../../../../interfaces/Result';
import { switchTemplate } from '../../../../CheckupResult/utils/switchTemplate';

type Props = {
  modalOpen: boolean;
  onClick: (modalOpen: boolean) => void;
  result: Result;
};

export const ShareModal = ({ result, modalOpen, onClick }: Props) => (
  <Modal onClick={onClick} visible={modalOpen}>
    <div className={containerStyle}>
      <Card>
        <Caption type="regularHeader">結果をシェアする</Caption>
        <div className={iconContainerStyle}>
          <a
            href={`https://twitter.com/share?text=${switchTemplate(result, 'twitter')}`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="twitter" />
          </a>
          <a
            href={`//timeline.line.me/social-plugin/share?text=${switchTemplate(result, 'line')}&url=${
              TWITTER_URL[result] && TWITTER_URL[result].replace('/photo/1', '')
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="line" />
          </a>
          <a
            href={`https://www.facebook.com/sharer.php?quote=${switchTemplate(result, 'facebook')}&u=${
              OGP_URL[result]
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="facebook" />
          </a>
        </div>
        <p className={closeStyle} onClick={() => onClick(false)}>
          閉じる
        </p>
      </Card>
    </div>
  </Modal>
);

const containerStyle = css`
  min-width: 320px;
  padding-top: ${gutter(2)};
  text-align: center;
`;

const iconContainerStyle = css`
  display: flex;
  justify-content: space-evenly;
  margin: ${gutter(6.75)} auto;

  > a > svg {
    ${square(37)}

    fill: ${Color.GrayscaleSns}
  }
`;

const closeStyle = css`
  padding-top: ${gutter(3.5)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
  cursor: pointer;
  border-top: 1px solid ${Color.GrayscaleLight};
`;
