import gql from 'graphql-tag';

export const ProfileType = gql`
  fragment ProfileType on User {
    id
    name
    sei
    mei
    phone
    mail
    addressLine1
    addressLine2
    pref
    zipCode
    sex
    age
    birthday
    insuranceCards {
      key
      url
    }
    customer {
      defaultCard
      cards {
        items {
          id
          fourDigit
          brand
          expAt
        }
      }
    }
    patient {
      patientNo
    }
    accountRestricted
  }
`;
