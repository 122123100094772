import gql from 'graphql-tag';

import { WaitingListItemType } from '../fragments/waitingListItem';

export const getWaitingListItem = gql`
  query getWaitingListItem($clinic: ID!, $sortKey: ID!) {
    getWaitingListItem(clinic: $clinic, startDepartmentTreatmentKindUserId: $sortKey) {
      ...WaitingListItemType
    }
  }
  ${WaitingListItemType}
`;
