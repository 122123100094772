import { compareDesc, differenceInHours, differenceInMinutes } from 'date-fns';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { CancelPenaltyConditionDiffUnit, CancelPoliciesTypeFragment } from '../../gql/gql-types';
/**
 * キャンセルポリシー関連のドメインロジックを集約する。
 */

export class CancelPolicyStore {
  constructor() {
    makeAutoObservable(this, {
      cancelPolicies: observable,
      setCancelPolicies: action,
      cancelPolicyInfo: computed,
      visibleLastCaution: observable,
      setVisibleLastCaution: action,
    });
  }

  public cancelPolicies: CancelPoliciesTypeFragment['cancelPolicies'] | undefined = undefined;
  public setCancelPolicies(cancelPolicies?: CancelPoliciesTypeFragment['cancelPolicies']) {
    cancelPolicies && (this.cancelPolicies = cancelPolicies);
  }

  get cancelPolicyInfo() {
    const { cancelPolicies } = this;
    if (!cancelPolicies) {
      return null;
    }

    // 複数ある場合、最新のキャンセルポリシーを適用
    const cancelPolicy = cancelPolicies.slice().sort((pre, next) => {
      if (!pre?.revisedAt || !next?.revisedAt) return 1;
      return compareDesc(new Date(pre.revisedAt), new Date(next.revisedAt));
    })[0];

    const cancelPolicyRevision = cancelPolicy?.revisedAt;
    const greetingText = cancelPolicy?.greetingText || '';

    let timeToBan: {
      maxBanCount?: number;
      differenceMinutes?: number;
    } = {};
    const cancelFee: {
      differenceText?: string;
      feeAmount?: number;
    }[] = [];
    let cancelableTimeLimitText: string | null = null;

    cancelPolicy?.cancelPenaltyPolicy.forEach(penalty => {
      penalty.timeToBan &&
        (timeToBan = {
          maxBanCount: penalty.timeToBan,
          differenceMinutes: this.makeDifferenceMinute(penalty.difference, penalty.unit) || 30,
        });

      penalty.feeAmount &&
        cancelFee.push({
          differenceText: this.makeTimeLimitText(penalty.difference, penalty.unit),
          feeAmount: penalty.feeAmount,
        });
    });

    if (cancelPolicy?.cancelableUntil) {
      cancelableTimeLimitText = this.makeTimeLimitText(
        cancelPolicy.cancelableUntil.difference,
        cancelPolicy.cancelableUntil.unit,
      );
    }
    return { greetingText, timeToBan, cancelFee, cancelableTimeLimitText, cancelPolicyRevision };
  }

  private makeTimeLimitText = (difference: number, unit: CancelPenaltyConditionDiffUnit) => {
    if (difference === 1 && unit === 'DAYS') {
      return '前日';
    }
    if (unit === 'DAYS') {
      return `${difference}日前`;
    }
    return difference % 60 === 0 ? `${difference / 60}時間前` : `${difference}分前`;
  };

  private makeDifferenceMinute = (difference: number, unit: CancelPenaltyConditionDiffUnit) => {
    const unitMinutes = unit === 'DAYS' ? 24 * 60 : 1;
    return difference * unitMinutes;
  };

  // 直前予約操作をしようとしたユーザーに対する警告
  public visibleLastCaution = false;

  public setVisibleLastCaution(visible: boolean) {
    this.visibleLastCaution = visible;
  }

  public isJustBeforeAction(reservationDate: Date) {
    return differenceInHours(reservationDate, new Date()) < 24;
  }

  public hasPenalty(reservationDate: Date) {
    if (!this.cancelPolicyInfo?.timeToBan.differenceMinutes) {
      return false;
    }
    return differenceInMinutes(reservationDate, new Date()) < this.cancelPolicyInfo.timeToBan.differenceMinutes;
  }

  public makeLastWarningTextList = (type: 'reserve' | 'edit' | 'cancel') => {
    let textList = { headerText: '', confirmText: '', cancelText: '' };
    switch (type) {
      case 'edit':
        textList = {
          headerText: '直前の予約変更です',
          confirmText: '了承して予約を変更する',
          cancelText: '前の画面に戻る',
        };
        break;
      case 'cancel':
        textList = {
          headerText: '直前キャンセルです',
          confirmText: '了承して予約を取り消す',
          cancelText: '前の画面に戻る',
        };
        break;
      case 'reserve':
      default:
        textList = {
          headerText: '24時間以内の予約についての注意',
          confirmText: '了承して予約を完了する',
          cancelText: '予約日時の選択に戻る',
        };
        break;
    }
    return textList;
  };
}
