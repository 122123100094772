import React, { useReducer } from 'react';

import { Button } from '../../components/Button';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { useContext } from '../../hooks/useContext';
import { Demo2Store } from '../../stores/Demo2Store';

type Props = {
  onChangeState: (nextState: 'input' | 'confirm' | 'complete') => void;
};

type State = {
  name: string;
  job: string;
};

type Action =
  | {
      type: 'name';
      payload: Pick<State, 'name'>;
    }
  | {
      type: 'job';
      payload: Pick<State, 'job'>;
    };

export const InputSection = ({ onChangeState }: Props) => {
  // このストアのインスタンスはすぐ上の親コンポーネントで作成されてるので、
  // わざわざ React.Context 経由で取得せずとも直接 props から受け取れば充分とも言える。
  // props のバケツリレーが激しくなるようなケースでは、useContext を使うのがおすすめ。
  const store = useContext(Demo2Store.Context);

  // この程度なら useReducer を使うまでもなく useState で充分だったりする。
  // あくまで一例なので、適切だと思う方を使えば良いと思う。
  const [{ name, job }, dispatch] = useReducer(reducer, { name: store.name, job: store.job });

  const handleChangeName = (value: string) => dispatch({ type: 'name', payload: { name: value } });

  const handleChangeJob = (value: string) => dispatch({ type: 'job', payload: { job: value } });

  const handleSubmit = () => {
    store.setName(name);
    store.setJob(job);
    onChangeState('confirm');
  };

  const validInputs = !!name && !!job;

  return (
    <form onSubmit={e => e.preventDefault()}>
      <h2>Input section</h2>
      <label>
        Name
        <TextField placeholder="名無しさん" value={name} onChange={handleChangeName} />
      </label>
      <label>
        Job
        <TextArea placeholder="ソフトウェアエンジニア" value={job} onChange={handleChangeJob} />
      </label>
      <div>
        <Button onClick={handleSubmit} disabled={!validInputs}>
          確認画面へ
        </Button>
      </div>
    </form>
  );
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload.name };
    case 'job':
      return { ...state, job: action.payload.job };
    default:
      return state;
  }
};
