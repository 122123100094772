import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  GetMyPageQuery,
  GetMyPageQueryVariables,
  ListCheckupResultItemSubGroupsQuery,
  ListCheckupResultItemSubGroupsQueryVariables,
  ListDepartmentsQuery,
  ListDepartmentsQueryVariables,
  TreatmentHistoryFragment,
} from '../../../../../gql/gql-types';
import getMyPage from '../../../../../gql/operations/getMyPage';
import { listCheckupResultItemSubGroups } from '../../../../../gql/operations/listCheckupResultItemSubGroup';
import { listDepartments } from '../../../../../gql/operations/listDepartment';
import { TreatmentListStore } from '../../../../pages/Treatments/TreatmentList/stores/TreatmentListStore';
import { safeFilter } from '../../../../utils/CollectionUtil';
import { CheckupResultSubGroupStore } from './CheckupResultSubGroupStore';

type Profile = Pick<Required<NonNullable<GetMyPageQuery['getUser']>>, 'id'>;

type Department = {
  id: string;
  name: string;
  clinic: {
    id: string;
  };
};

export class BaseStore {
  public static Context = createContext<null | BaseStore>(null);

  constructor() {
    makeAutoObservable(this, {
      profile: observable,
      treatment: computed,
      treatments: observable,
      fetchInitial: action,
      setProfile: action,
      departments: observable,
      subGroup: computed,
      subGroupId: observable,
      treatmentId: observable,
      subGroupStores: observable,
    });
  }

  public profile?: Profile = undefined;

  public setProfile(p: Profile) {
    this.profile = p;
  }

  public treatments: TreatmentHistoryFragment[] = [];

  public get treatment() {
    return this.treatments.find(t => t.id === this.treatmentId);
  }

  public departments: Department[] = [];
  public department?: Department = undefined;

  public subGroupStores: CheckupResultSubGroupStore[] = [];

  public get subGroup() {
    return this.subGroupStores.find(s => s.id === this.subGroupId);
  }

  public subGroupId?: string = undefined;
  public treatmentId?: string = undefined;

  public async fetchInitial(subGroupId: string, treatmentId: string) {
    if (!this.profile?.id) {
      return;
    }
    const res = await GQL.query<GetMyPageQueryVariables, GetMyPageQuery>(getMyPage, { userId: this.profile.id });
    const resDepartments = await GQL.query<ListDepartmentsQueryVariables, ListDepartmentsQuery>(listDepartments, {});
    const resCheckupResultItemSubGroups = await GQL.query<
      ListCheckupResultItemSubGroupsQueryVariables,
      ListCheckupResultItemSubGroupsQuery
    >(listCheckupResultItemSubGroups, {});

    runInAction(() => {
      this.profile = res.getUser ? { id: res.getUser.id } : undefined;
      this.treatments = TreatmentListStore.convertCheckupResultTreatment(res.getUser?.completedTreatment.items);
      this.departments = safeFilter(
        resDepartments.listDepartments?.items?.map(item => ({
          id: item?.id || '',
          name: item?.name || '',
          clinic: {
            id: item?.clinic?.id || '',
          },
        })),
      );
      const treatment = safeFilter(res.getUser?.completedTreatment.items).find(x => x.id === treatmentId);
      this.subGroupStores = safeFilter(
        safeFilter(treatment?.checkupAppointment?.result?.judged).map(j => {
          const master = safeFilter(resCheckupResultItemSubGroups.listCheckupResultItemSubGroups?.items).find(
            x => x.id === j.subGroupId,
          );
          if (!master) {
            return undefined;
          }
          return new CheckupResultSubGroupStore(
            treatmentId,
            j,
            master,
            safeFilter(res.getUser?.completedTreatment.items),
          );
        }),
      );
      this.subGroupId = subGroupId;
      this.treatmentId = treatmentId;
    });
  }

  public get departmentName() {
    if (this.treatments.length === 0) {
      return undefined;
    }
    return this.departments.find(d => d.id === this.subGroup?.subgroupResult.departmentId)?.name;
  }

  public get departmentId() {
    if (this.treatments.length === 0) {
      return undefined;
    }
    return this.departments.find(d => d.id === this.subGroup?.subgroupResult.departmentId)?.id;
  }

  public get departmentClinicId() {
    if (this.treatments.length === 0) {
      return undefined;
    }
    return this.departments.find(d => d.id === this.subGroup?.subgroupResult.departmentId)?.clinic.id;
  }
}
