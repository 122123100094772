import { addMinutes } from 'date-fns';
import addDays from 'date-fns/addDays';
import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import { action, computed, makeAutoObservable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../../../gql/client';
import {
  ListCheckupVacanciesQuery,
  ListCheckupVacanciesQueryVariables,
  VacancyUnitCalculation,
} from '../../../../../../../gql/gql-types';
import { listCheckupVacancies } from '../../../../../../../gql/operations/listCheckupVacancies';
import { CalendarCells, Time } from '../../../../../../components/Calendar';
import { Department } from '../../../../../../domains/reservation/Department';
import { Observable, PrefetchCacheStore } from '../../../../../../stores/PrefetchCacheStore';
import { ElementOf } from '../../../../../../utils/CollectionUtil';
import { TreatmentKind } from '../../../../../Reservations/ReservationNew/stores/ReservationStore';

type RawData = ElementOf<ListCheckupVacanciesQuery['listCheckupVacancies']>;

export class CheckupVacancyStore {
  public static Context = createContext<CheckupVacancyStore | null>(null);

  private allVacancies?: Time[][][] = undefined;
  private displayVacancies?: CalendarCells = undefined;
  private raw?: RawData[] = undefined;
  public cache = new PrefetchCacheStore<VacancyUnitCalculation, Observable<ListCheckupVacanciesQuery>>();

  constructor() {
    makeAutoObservable(this, {
      nearest: computed,
      vacancies: computed,
      fetchVacancies: action,
      getSelected: action,
    });
  }

  public getSelected(d: Date) {
    const { date, time } = split(d);
    return this.raw?.find(r => r.checkup.date === date && r.checkup.time === time);
  }

  public get vacancies() {
    return this.displayVacancies;
  }

  public get nearest() {
    const now = new Date();
    return this.allVacancies
      ?.slice(0)
      .reduce((a, b) => [...a, ...b], [])
      .reduce((a, b) => [...a, ...b], [])
      .filter(v => isAfter(v.date, now))
      .filter(v => v.available > 0)
      .sort((a, b) => compareAsc(a.date, b.date))[0];
  }

  public setVacancies(vacancies: CalendarCells) {
    if ('empty' in vacancies) {
      this.displayVacancies = vacancies;
      return;
    }
    this.displayVacancies = vacancies;
    this.allVacancies = [
      ...(this.allVacancies || []).filter(v => !isSameDay(v[0][0].date, vacancies[0][0].date)),
      vacancies,
    ].sort((a, b) => compareAsc(a[0][0].date, b[0][0].date));
  }

  public async fetchVacancies(week: Date, department: Department, options: string[], treatmentKind?: TreatmentKind) {
    const res = await this.fetchCacheFirst(week, department, options, treatmentKind);
    if (!res) {
      return;
    }

    this.raw = res.listCheckupVacancies;

    const times: Time[][] = res.listCheckupVacancies.reduce((r, e) => {
      const last = r.slice(-1)[0];
      const time = {
        date: parse(`${e.checkup.date} ${e.checkup.time}`, 'yyyy-MM-dd HH:mm', new Date()),
        available: e.dental ? Math.min(e.checkup.available, e.dental.available) : e.checkup.available,
        doctor: e.checkup.doctor || undefined,
        estimatedDuration: e.checkup.estimatedDuration + (e.dental?.estimatedDuration || 0),
        laneId: e.checkup.laneId, // ダミー。本来はgetSelectedで生の値を取るため問題なし
      };
      if (r.length === 0) {
        return [[time]];
      }
      if (isSameDay(last[0].date, time.date)) {
        return [...r.slice(0, -1), [...last, time]];
      }
      return [...r, [time]];
    }, new Array<Time[]>());
    if (times.length === 0) {
      this.setVacancies({
        empty: true,
        week,
      });
      return;
    }
    this.setVacancies(times);
  }

  /**
   * キャッシュをObservableとしてストアしつつ、値を取ってくる
   * @param week
   * @param department
   * @param options
   * @param treatmentKind
   * @returns
   */
  private async fetchCacheFirst(week: Date, department: Department, options: string[], treatmentKind?: TreatmentKind) {
    const input = {
      departmentId: department.id,
      fromDate: format(week, 'yyyy-MM-dd'),
      fromTime: '00:00',
      toDate: format(addDays(week, 8), 'yyyy-MM-dd'),
      toTime: '00:00',
      treatmentKind: treatmentKind?.id || department.id,
      options,
      clinic: department.clinic,
    };

    const cacheHit = await this.cache.fetch(input);

    const observable = new Observable(
      GQL.queryAsGuest<ListCheckupVacanciesQueryVariables, ListCheckupVacanciesQuery>(listCheckupVacancies, {
        input,
      }),
    );

    this.cache.addCache({ key: input, expire: addMinutes(new Date(), 5), value: observable });

    return cacheHit ? cacheHit.flat() : observable.flat();
  }

  /**
   * 次週分をprefetchしてキャッシュしておく
   * @param week
   * @param department
   * @param options
   * @param treatmentKind
   */
  public async prefetchNext(week: Date, department: Department, options: string[], treatmentKind?: TreatmentKind) {
    await this.fetchCacheFirst(addDays(week, 7), department, options, treatmentKind);
  }
}

const split = (d: Date) => ({ date: format(d, 'yyyy-MM-dd'), time: format(d, 'HH:mm') });
