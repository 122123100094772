import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { CheckboxGroup, FormItem, FormLayout } from '../../../../../../components/Form';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Color, FontSize } from '../../../../../../constants/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { GroupedCheckupOptionSelect } from '../../IndividualCheckupReservationsNew/IndividualCheckupPlanSelect/components/GroupedCheckupOptionSelect';
import { IndividualCheckupReservationStore } from '../../IndividualCheckupReservationsNew/stores/IndividualCheckupReservationStore';
import { CheckupAmountDisplay } from './components/CheckupAmountDisplay';
import { CheckupOptionSuggested } from './components/CheckupOptionSuggested';

type Props = {
  onNext?: () => void;
  onBack?: () => void;
};

export const IndividualCheckupOptionSelectPage = observer(({ onNext, onBack }: Props) => {
  const store = useContext(IndividualCheckupReservationStore.Context);
  const session = useContext(SessionStore.Context);
  useEffect(() => window.scrollTo({ top: 0 }), []);

  return (
    <Wrapper>
      <Caption type="header" align="center" underline>
        オプション選択
      </Caption>
      <FormLayout>
        <FormItem>
          <SuggestionCaption name={session.user?.userEntity?.sei || session.user?.userEntity?.name || ''} />
          <CheckboxGroup>
            {store.afterReservingOptions
              .filter(x => !store.course?.options.includes(x.id))
              .filter(o => o.suggestText)
              .map((option, key) => (
                <CheckupOptionSuggested
                  key={key}
                  option={{
                    billingTarget: option.billType,
                    discountAmount: option.price,
                    estimatedDuration: option.requiredMin,
                    id: option.id,
                    label: option.name,
                    regularAmount: option.regularPrice,
                    subTitle: option.subTitle || '',
                    description: option.suggestText,
                    warning: '',
                  }}
                  checked={store.options.some(o => o.id === option.id)}
                  onToggle={() => store.toggleOption(option.id)}
                />
              ))}
          </CheckboxGroup>
        </FormItem>
        <FormItem>
          <h3 className={unSuggestedCaptionStyle}>その他のオプション</h3>
          <Card>
            <GroupedCheckupOptionSelect
              checkupOptions={store.afterReservingOptions
                .filter(x => !store.course?.options.includes(x.id))
                .filter(o => !o.suggestText)
                .map(x => ({
                  id: x.id,
                  billingTarget: x.billType,
                  discountAmount: x.price,
                  estimatedDuration: x.requiredMin,
                  label: x.name,
                  regularAmount: x.regularPrice,
                  subTitle: x.subTitle || '',
                  description: x.description || undefined,
                  warning: x.warning || undefined,
                }))}
              checkedOptionIds={store.options.map(o => o.id)}
              onToggle={id => store.toggleOption(id)}
            />
          </Card>
        </FormItem>
        <FormItem>
          {store.course && (
            <CheckupAmountDisplay
              plan={{
                name: store.course.name,
                billingTarget: '2_own',
                discountAmount: store.course.price,
                regularAmount: store.course.regularPrice,
              }}
              options={store.options.map(o => ({
                billingTarget: o.billType,
                name: o.name,
                discountAmount: o.price,
                regularAmount: o.regularPrice,
              }))}
            />
          )}
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={10}>
        <Button block onClick={onNext}>
          オプションを確定
        </Button>
        <Button block ghost onClick={onBack}>
          戻る
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});

type SuggestionCaptionProps = {
  name: string;
};
const SuggestionCaption = ({ name }: SuggestionCaptionProps) => (
  <div className={suggestionCaptionStyle.container}>
    <h3 className={suggestionCaptionStyle.header}>{name}さんにおすすめ</h3>
    <p className={suggestionCaptionStyle.body}>問診の内容に基づいた、あなたにおすすめのオプションです。</p>
  </div>
);

const suggestionCaptionStyle = {
  container: css`
    margin-bottom: 8px;
  `,
  header: css`
    font-size: ${FontSize.Medium};
    font-weight: bold;
    color: ${Color.FunctionalGreenNeutral};
  `,
  body: css`
    font-size: ${FontSize.Regular};

    /* line-height: 0.14; */
    color: ${Color.GrayscaleDarker};
  `,
};

const unSuggestedCaptionStyle = css`
  margin-bottom: 16px;
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.GrayscaleDarker};
`;
