import { Hub, urlSafeDecode } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from '../constants/Routes';

/**
 * Cognito経由でリダイレクトした際に、useFederatedSignInで指定したパスに再度リダイレクトするhook。
 */
export const useAuthRedirect = () => {
  const { push } = useHistory();
  // const sessionStore = useMemo(() => new SessionStore(), []);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.info({ event, data });
      if (event === 'customOAuthState') {
        const to = data;
        push(to || Routes.BasePath);
      }
      if (event === 'customState_failure') {
        const to = urlSafeDecode(new URL(window.location.href).searchParams.get('state')?.split('-')[1] || '');
        Auth.federatedSignIn({ customProvider: 'LINE', customState: to || Routes.BasePath });
        // sessionStore.lineSignIn(to || Routes.BasePath);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
