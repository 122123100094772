import { addDays, addMinutes, differenceInMinutes, format, isSameDay, setHours, setMinutes } from 'date-fns/esm';
import { ja } from 'date-fns/locale';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { FormItem, FormLayout } from '../../../../../../components/Form';
import { Icon } from '../../../../../../components/Icon';
import { Modal } from '../../../../../../components/Modal';
import { Option, Select } from '../../../../../../components/Select';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';

type Props = {
  clinic: Master;
  department?: Master;
  treatmentKind?: Master;
  onCommit: () => void;
  disabled?: boolean;

  open: boolean;
  onClose?: () => void;

  authenticated: boolean;
} & RangeProps;

type Range = {
  start?: Date;
  end?: Date;
};

type Master = {
  id: string;
  name: string;
};

export const WaitingListApplicationDialog = observer(
  ({ clinic, department, onChange, onCommit, open, range, treatmentKind, disabled, onClose, authenticated }: Props) => {
    const defaultDate = useMemo(() => new Date(), []);
    return (
      <Modal visible={open}>
        <Card width={295}>
          <div className={headerStyle}>
            <Caption type="subheader" align="center">
              キャンセル通知登録
            </Caption>
            <Caption type="body">
              ご指定の時間帯に空き枠が出た際にLINEで通知します。先着で予約確定した方に受診いただけます。
            </Caption>
          </div>
          <div className={captionGroupStyle}>
            <Caption type="div" align="center">
              {clinic.name} {department?.name} {treatmentKind && `/ ${treatmentKind.name}`}
            </Caption>
            <Caption type="header" align="center">
              {format(range.start || defaultDate, 'yyyy年M月d日(E)', { locale: ja })}
            </Caption>
          </div>
          <FormLayout>
            <FormItem>
              <RangeInput range={range} onChange={onChange} />
            </FormItem>
          </FormLayout>
          <ButtonGroup className={actionStyle}>
            {authenticated ? (
              <Button
                block
                onClick={() => onCommit()}
                disabled={disabled}
                className={GtmClasses.gtm.cancel.addWaitingList}
              >
                キャンセル通知を登録
              </Button>
            ) : (
              <Button
                block
                theme="line"
                type="button"
                onClick={() => onCommit()}
                disabled={disabled}
                className={GtmClasses.gtm.cancel.addWaitingList}
              >
                <Icon name="line" />
                LINEログインで通知登録
              </Button>
            )}
            <Button block ghost onClick={onClose}>
              戻る
            </Button>
          </ButtonGroup>
        </Card>
      </Modal>
    );
  },
);

const captionGroupStyle = css`
  display: grid;
  row-gap: 15px;
`;
const headerStyle = cx(
  captionGroupStyle,
  css`
    margin-bottom: 40px;
  `,
);

const actionStyle = css`
  margin-top: 30px;
`;

type RangeProps = {
  range: Range;
  onChange: (r: Range) => void;
};

const RangeInput = ({ range, onChange }: RangeProps) => {
  const defaultDate = useMemo(() => new Date(), []);
  const startOptions = useMemo(() => {
    const openHoursStart = setMinutes(setHours(range.start || defaultDate, 9), 0);
    const openHoursEnd = setMinutes(setHours(range.start || defaultDate, 22), 0);
    const tick = 15;
    return new Array(Math.abs(differenceInMinutes(openHoursStart, openHoursEnd)) / tick)
      .fill(undefined)
      .map((_, key) => addMinutes(openHoursStart, tick * key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.start]);

  const endOptions = useMemo(() => {
    const openHoursStart = setMinutes(setHours(range.start || defaultDate, 9), 0);
    const openHoursEnd = setMinutes(setHours(range.start || defaultDate, 22), 0);
    const tick = 15;
    return new Array(Math.abs(differenceInMinutes(openHoursStart, openHoursEnd)) / tick)
      .fill(undefined)
      .map((_, key) => {
        const candidate = addMinutes(range.start || defaultDate, tick * (key + 1));
        const overrun = differenceInMinutes(candidate, openHoursEnd);
        // 終了時間以降であれば翌日の開始時間からスタート
        if (overrun > 0) {
          return addMinutes(addDays(openHoursStart, 1), overrun);
        }
        return candidate;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.start]);

  return (
    <div className={rangeStyle}>
      <Select
        onChange={v => onChange({ start: new Date(v), end: range.end })}
        value={(range.start || defaultDate).toISOString()}
      >
        {startOptions.map((item, key) => (
          <Option key={key} value={item.toISOString()}>
            {format(item, 'HH:mm')}
          </Option>
        ))}
      </Select>
      <div>～</div>
      <Select
        onChange={v => onChange({ end: new Date(v), start: range.start })}
        value={(range.end || defaultDate).toISOString()}
      >
        {endOptions.map((item, key) => (
          <Option key={key} value={item.toISOString()}>
            {isSameDay(item, range.start || defaultDate) ? format(item, 'HH:mm') : `翌${format(item, 'HH:mm')}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const rangeStyle = css`
  display: grid;
  grid-template-columns: auto min-content auto;
  column-gap: 10px;
  align-items: center;
`;
