import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Color, FontSize } from '../../../constants/Style';
import { gutter } from '../../../helpers/Style';
import { useContext } from '../../../hooks/useContext';
import { useTransaction } from '../../../hooks/useTransaction';
import { MyPageStore } from '../../../stores/MyPageStore';
import { SessionStore } from '../../../stores/SessionStore';
import { ReservationCardListLayout } from './components/ReservationCardListLayout';

export const ReservationListPage = observer(() => {
  const store = useContext(MyPageStore.Context);
  const sessionStore = useContext(SessionStore.Context);
  const [doFetchInitial] = useTransaction(() => store.fetchInitial());

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    store.setProfile({ id: sessionStore.user.userId });
    doFetchInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  return (
    <Wrapper>
      <DocumentTitle title="予約確認・変更" />
      <Caption type="header" underline align="center">
        予約確認・変更
      </Caption>
      <p className={hintStyle}>
        患者様お一人毎にご予約を確保しお待ちしています。
        <br />
        <span className={textEmphasisStyle}>キャンセル・予約変更は前日まで</span>にお願いします。
      </p>
      <ReservationCardListLayout reservations={store.displayReservations} />
    </Wrapper>
  );
});

const hintStyle = css`
  padding-top: ${gutter(1)};
  padding-bottom: ${gutter(3)};
  font-size: ${FontSize.Regular};
  font-weight: bold;
  color: ${Color.GrayscaleDarker};
`;

const textEmphasisStyle = css`
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;
