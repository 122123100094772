import gql from 'graphql-tag';

import { CheckupResultItemSubGroupType } from './checkupResultItemSubGroup';

export const CheckupResultItemGroupType = gql`
  fragment CheckupResultItemGroupType on CheckupResultItemGroup {
    id
    technicalName
    friendlyName
    order
    subGroups {
      items {
        ...CheckupResultItemSubGroupType
      }
    }
  }
  ${CheckupResultItemSubGroupType}
`;
