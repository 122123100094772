import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';
import { ReservationType } from '../fragments/reservation';

export const getCheckupAppointmentByTreatmentId = gql`
  query getCheckupAppointmentByTreatmentId($id: String) {
    getTreatment(id: $id) {
      ...Reservation
      options
      discountCode
      preInterview {
        ...InterviewType
      }
      checkupAppointment {
        id
        address
        plan {
          id
          label
          price
        }
        optionPrices {
          optionId
          price
          discountAmount {
            discountRate
            discountPrice
          }
          billType
          option {
            label
            regularPrice
          }
        }
        treatment {
          items {
            ...Reservation
          }
        }
      }
    }
  }
  ${ReservationType}
  ${InterviewType}
`;
