import { useEffect, useState } from 'react';

type MediaQuery<T> = {
  query: string;
  value: T;
};

/**
 * メディアクエリを実行し、**最初にマッチしたものの**valueを返す。
 * @param query メディアクエリ（"(min-width: 1500px)"の形式）とその場合の値のペアの配列（順序を考慮）
 * @param defaultValue マッチしなかった場合のデフォルト値
 */
export const useMedia = <T>(query: readonly MediaQuery<T>[], defaultValue: T) => {
  const mediaQueryLists = query.map(q => window.matchMedia(q.query));

  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return query[index]?.value || defaultValue;
  };

  const [value, setValue] = useState<T>(getValue());

  useEffect(
    () => {
      const handler = () => setValue(getValue());
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return value;
};
