import { css } from 'emotion';
import React, { ComponentProps, useMemo, useState } from 'react';

import { Caption } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Gutter } from '../../../../../../components/Gutter';
import { Modal } from '../../../../../../components/Modal';
import { gutter } from '../../../../../../helpers/Style';
import { formatNearestAvailable } from '../../../DepartmentSelect/components/DepartmentCardLayout/DepartmentCard/DepartmentCardFooter';
import { CourseCard } from './CourseCard';

type ChildProps = Pick<ComponentProps<typeof CourseCard>, 'id' | 'name' | 'icon'> & { returning?: boolean };
type SubtitledCourse = {
  subTitle: string;
  courses: Course[];
  nearest?: Date;
};

type Props = {
  courses: Course[];
  onClick: (selected: ChildProps) => void;
};

type Course = ChildProps & {};
type SubtitledProps = {
  subTitles: SubtitledCourse[];
  nextCourses?: Course[];
  onClick: (selected: ChildProps) => void;
};

export const SubtitledCourseCardLayout = ({ subTitles, nextCourses, onClick }: SubtitledProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      {(nextCourses || []).length > 0 && (
        <CourseCardLayout
          courses={[{ id: 'next-course', name: '前回の続きを予約' }]}
          onClick={() => setShowModal(true)}
        />
      )}
      {subTitles.map((subTitle, key) => (
        <div key={key}>
          <Gutter vertical={4}>
            <SubTitle subTitle={subTitle} />
          </Gutter>
          <CourseCardLayout courses={subTitle.courses} onClick={onClick} />
        </div>
      ))}
      <Modal visible={showModal} onClick={() => setShowModal(false)}>
        <Card>
          <CourseCardLayout courses={nextCourses || []} onClick={onClick} />
        </Card>
      </Modal>
    </div>
  );
};

type SubTitleSingleProps = {
  subTitle: SubtitledCourse;
};
const SubTitle = ({ subTitle }: SubTitleSingleProps) => {
  const today = useMemo(() => new Date(), []);
  const nearest = subTitle.nearest && formatNearestAvailable(subTitle.nearest, today);
  return (
    <div className={subTitleStyles.main}>
      <>
        <Caption type="subheader">{subTitle.subTitle}</Caption>
        {nearest && (
          <div className={subTitleStyles.sub}>
            <Gutter horizontal={2}>
              <Caption type="small">最短の予約時間</Caption>
            </Gutter>
            <Caption type="danger">
              {nearest.date}
              {nearest.day && `(${nearest.day})`}
              {nearest.time}
            </Caption>
          </div>
        )}
      </>
    </div>
  );
};

const subTitleStyles = {
  main: css`
    display: flex;
    justify-content: space-between;
  `,
  sub: css`
    display: flex;
    align-items: flex-end;
  `,
};

export const CourseCardLayout = ({ courses, onClick }: Props) => (
  <div className={grid}>
    {courses.map((course, i) => (
      <CourseCard key={i} {...course} onClick={() => onClick(course)} />
    ))}
  </div>
);

const grid = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gutter(4)};
`;
