import { IconName } from '@clinic-monorepo/clinic-icon';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Icon } from '../../../../components/Icon';
import { Link } from '../../../../components/Link';
import { FontSize, IconSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';

type Props = {
  icon: IconName;
  children: ReactNode;
  href: string;
};
export const MyPageSectionHeader = ({ icon, children, href }: Props) => (
  <div className={rowStyle}>
    <div className={mainStyle}>
      <div className={iconStyle}>
        <Icon name={icon} />
      </div>
      <h3 className={headerStyle}>{children}</h3>
    </div>
    <div className={linkStyle}>
      <Link to={href}>一覧を見る</Link>
    </div>
  </div>
);

const iconStyle = css`
  display: flex;
  margin-right: ${gutter(2)};

  > svg {
    ${square(IconSize.Large)}
  }
`;

const headerStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
  line-height: 1;
`;

const mainStyle = css`
  display: flex;
  align-items: center;
`;

const rowStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const linkStyle = css`
  display: flex;
  align-items: center;
`;
