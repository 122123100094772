import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { AppleCalendarButton } from '../../components/CalendarButton/AppleCalendarButton';
import { Wrapper } from '../../components/Wrapper';
import { Defaults } from '../../constants/Defaults';
import { Color } from '../../constants/Style';

/**
 * LINEブラウザ上でappleのカレンダーを開けないため、
 * 標準ブラウザ上のこのページに遷移させてカレンダー登録作業を行なってもらう
 * Appleカレンダーの需要が高ければ、より使いやすくする手段を考える
 */

export const AppleCalendarPage = () => {
  const dateInfo = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const date = query.get('date');
    const time = query.get('time');
    const displayDuration = Number(query.get('duration')) || Defaults.DURATION;
    if (!date || !time) {
      return;
    }

    return {
      date: new Date(`${date}T${time}`),
      displayDuration,
    };
  }, []);

  if (!dateInfo?.date) {
    return <></>;
  }

  return (
    <Wrapper>
      <AppleCalendarButton date={dateInfo.date} displayDuration={dateInfo.displayDuration} />
      <p className={cautionStyle}>カレンダー登録後、続けて利用される方はLINEアプリにお戻りください</p>
    </Wrapper>
  );
};

const cautionStyle = css`
  margin-top: 8px;
  color: ${Color.FunctionalRedNeutral};
`;
