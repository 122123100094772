import gql from 'graphql-tag';

import { CheckupResultItemGroupType } from '../fragments/checkupResultItemGroup';

export const listCheckupResultItemSubGroups = gql`
  query listCheckupResultItemSubGroups {
    listCheckupResultItemSubGroups {
      items {
        description
        id
        order
        name
        group {
          ...CheckupResultItemGroupType
        }
      }
    }
  }
  ${CheckupResultItemGroupType}
`;
