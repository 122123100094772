import { addMinutes, format } from 'date-fns/esm';
import parse from 'date-fns/parse';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { IconName } from '../../../../../../../../clinic-icon/dist';
import { GQL } from '../../../../../../../gql/client';
import {
  AnswerValueInput,
  ClinicType,
  CreateCheckupReservationForIndividualMutation,
  CreateCheckupReservationForIndividualMutationVariables,
  CreateTemporallyCheckupReservationIndividualMutation,
  CreateTemporallyCheckupReservationIndividualMutationVariables,
  DepartmentType,
  GetCheckupAppointmentByTreatmentIdQuery,
  GetCheckupAppointmentByTreatmentIdQueryVariables,
  GetCheckupInterviewQuery,
  GetCheckupInterviewQueryVariables,
  InterviewTypeFragment,
  ListCheckupOptionSuggestionQuery,
  ListCheckupOptionSuggestionQueryVariables,
  ListIndividualCheckupMastersQuery,
  ListIndividualCheckupMastersQueryVariables,
  TreatmentStatus,
  UpdateCheckupAppointmentForIndividualMutation,
  UpdateCheckupAppointmentForIndividualMutationVariables,
} from '../../../../../../../gql/gql-types';
import { createCheckupReservationForIndividual } from '../../../../../../../gql/operations/createCheckupReservationForIndividual';
import { createTemporallyCheckupReservationIndividual } from '../../../../../../../gql/operations/createTemporallyCheckupReservationForIndividual';
import { getCheckupAppointmentByTreatmentId } from '../../../../../../../gql/operations/getCheckupAppointmentByTreatmentId';
import { getCheckupInterview } from '../../../../../../../gql/operations/getCheckupInterview';
import { listCheckupSuggestions } from '../../../../../../../gql/operations/listCheckupSuggestions';
import { listIndividualCheckupMasters } from '../../../../../../../gql/operations/listIndividualCheckupMasters';
import { updateCheckupReservationForIndividual } from '../../../../../../../gql/operations/updateCheckupReservationForIndividual';
import { Defaults } from '../../../../../../../src/constants/Defaults';
import { Department } from '../../../../../../domains/reservation/Department';
import { CancelPolicyStore } from '../../../../../../stores/CancelPolicyStore';
import { safeFilter } from '../../../../../../utils/CollectionUtil';
import { approximate } from '../../../../../../utils/NumberUtil';
import {
  CheckupCourse,
  CheckupOption,
  CheckupReservationDetail,
} from '../../../../Reservations/CheckupReservationsNew/stores/CheckupReservationStore';

export class IndividualCheckupReservationStore {
  private static DATE_FORMAT = 'yyyy-MM-dd';
  private static TIME_FORMAT = 'HH:mm';
  private static STORAGE_KEY = 'clinicten.checkup.individual';
  public static Context = createContext<IndividualCheckupReservationStore | null>(null);

  constructor() {
    makeAutoObservable(this, {
      setPreInterviewAnswer: action,
      setPostInterviewAnswer: action,
      fetchPreInterview: action,
      preInterview: observable,
      postInterview: observable,
      kitDeliveryAddress: observable,
      setKitDeliveryAddress: action,
      course: observable,
      setCourse: action,
      options: observable,
      addOption: action,
      removeOption: action,
      selectableCourses: observable,
      selectableOptions: observable,
      department: observable,
      details: observable,
      setDetails: action,
      fetchShouldConfirm: action,
      shouldConfirm: observable,
      fetchSuggestions: action,
      store: action,
      flush: action,
      copyFromStorage: action,
      discountCode: observable,
      setDiscountCode: action,
      fetchMasters: action,
      fetchCheckupTreatmentInfo: action,
      getDentalTreatmentKind: action,
      postTreatment: action,
      updateTreatment: action,
      appointmentId: observable,
      treatmentKind: computed,
      toggleOption: action,
      afterReservingOptions: computed,
      beforeReservingOptions: computed,
      fetchPostInterview: action,
      displayDuration: computed,
    });
    this.copyFromStorage(
      JSON.parse(window.sessionStorage.getItem(IndividualCheckupReservationStore.STORAGE_KEY) || '{}'),
    );
  }

  public preInterview?: InterviewTypeFragment = undefined;
  public postInterview?: InterviewTypeFragment = undefined;

  public kitDeliveryAddress?: string = undefined;
  public course?: CheckupCourseWithPrice = undefined;
  public options: CheckupOption[] = [];

  public selectableCourses: CheckupCourseWithPrice[] = [];
  public selectableOptions: CheckupOption[] = [];

  public details: CheckupReservationDetail[] = [];
  public department?: Department = undefined;
  public shouldConfirm = false;

  public appointmentId?: string = undefined;

  public discountCode?: string = undefined;

  public cancelPolicies: CancelPolicyStore = new CancelPolicyStore();

  // actions
  public store() {
    window.sessionStorage.setItem(
      IndividualCheckupReservationStore.STORAGE_KEY,
      JSON.stringify({
        preInterview: this.preInterview,
        options: this.options,
      }),
    );
  }

  public flush() {
    window.sessionStorage.removeItem(IndividualCheckupReservationStore.STORAGE_KEY);
  }

  public copyFromStorage(state: any) {
    if (state.preInterview) {
      this.preInterview = state.preInterview;
      this.fetchSuggestions();
    }
    if (state.options) {
      this.options = state.options;
    }
  }

  public setPreInterviewAnswer(key: number, answer: AnswerValueInput[]) {
    if (!this.preInterview) {
      return;
    }

    const origin = this.preInterview.questions || [];
    this.preInterview.questions = [
      ...origin.slice(0, key),
      {
        ...origin[key],
        answer: answer.map(a => ({
          answer: a.value,
          option: {
            id: a.id,
            label: a.label,
            type: origin[key]?.options?.find(o => o?.id === a.id)?.type,
            value: origin[key]?.options?.find(o => o?.id === a.id)?.value,
          },
        })),
      },
      ...origin.slice(key + 1),
    ];

    this.fetchSuggestions();
    this.store();
  }

  public setPostInterviewAnswer(key: number, answer: AnswerValueInput[]) {
    if (!this.postInterview) {
      return;
    }

    const origin = this.postInterview.questions || [];
    this.postInterview.questions = [
      ...origin.slice(0, key),
      {
        ...origin[key],
        answer: answer.map(a => ({
          answer: a.value,
          option: {
            id: a.id,
            label: a.label,
            type: origin[key]?.options?.find(o => o?.id === a.id)?.type,
            value: origin[key]?.options?.find(o => o?.id === a.id)?.value,
          },
        })),
      },
      ...origin.slice(key + 1),
    ];
  }

  public setKitDeliveryAddress(a: string) {
    this.kitDeliveryAddress = a;
  }

  public setCourse(c: CheckupCourseWithPrice) {
    this.course = c;
  }

  public addOption(o: CheckupOption) {
    this.options = [...this.options, o];
  }

  public removeOption(o: CheckupOption) {
    this.options = this.options.slice(0).filter(op => op.id !== o.id);
  }

  public toggleOption(id: string) {
    const existent = this.options.find(o => o.id === id);
    if (existent) {
      this.removeOption(existent);
      return;
    }

    const o = this.selectableOptions.find(x => x.id === id);
    o && this.addOption(o);
  }

  public setDetails(ds: CheckupReservationDetail[]) {
    this.details = ds;
  }

  public setDiscountCode(code?: string) {
    this.discountCode = code;
  }

  public get beforeReservingOptions() {
    return this.selectableOptions.filter(x => x.requiredMin > 0);
  }

  public get afterReservingOptions() {
    return this.selectableOptions.filter(x => x.requiredMin <= 0);
  }

  // api calls

  async fetchMasters() {
    await GQL.queryAsGuest<ListIndividualCheckupMastersQueryVariables, ListIndividualCheckupMastersQuery>(
      listIndividualCheckupMasters,
      {
        discountCode: this.discountCode,
      },
    ).then(res => {
      runInAction(() => {
        this.selectableCourses = safeFilter(res.listCheckupPlanIndividuals?.items)
          .slice(0)
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .filter(c => !c.plan.hideForIndividual)
          .map(item => ({
            id: item.plan.id,
            name: item.plan.label,
            price: res.getDiscountCheckupIndividualMasters?.plans.find(p => p.planId === item.plan.id)?.price || 0,
            regularPrice:
              res.getDiscountCheckupIndividualMasters?.plans.find(p => p.planId === item.plan.id)?.regularPrice || 0,
            description: item.plan.description || '',
            options: safeFilter(item.plan.options),
            treatmentKindId: item.plan.treatmentKindId || '',
            cancelPolicies: item.plan.treatmentKind?.cancelPolicies,
          }));
        this.selectableOptions = safeFilter(res.listCheckupOptionIndividuals?.items)
          .sort((a, b) => (a.option.order || 0) - (b.option.order || 0))
          .map(item => ({
            id: item.option.id,
            billType: '2_own',
            clinicType: item.option.clinicType || ClinicType.Checkup,
            name: item.option.label,
            price:
              res.getDiscountCheckupIndividualMasters?.options.find(p => p.optionId === item.option.id)?.price || 0,
            regularPrice:
              res.getDiscountCheckupIndividualMasters?.options.find(p => p.optionId === item.option.id)?.regularPrice ||
              0,
            requiredMin: item.option.requiredMin,
            suggestText: '',
            treatmentKind: {
              id: item.option.treatmentKind?.id || '',
              name: item.option.treatmentKind?.name || '',
            },
            description: item.option.description,
            subTitle: item.option.subTitle,
            warning: item.option.warning,
          }));

        if (this.course) {
          const course = this.selectableCourses.find(c => c.id === this.course?.id);
          course && this.setCourse(course);
        }
      });
    });
  }

  async fetchPreInterview() {
    this.preInterview =
      (
        await GQL.queryAsGuest<GetCheckupInterviewQueryVariables, GetCheckupInterviewQuery>(getCheckupInterview, {
          input: {
            timing: '1_BEFORE_RESERVING',
            plan: this.course?.id || '',
            options: [],
          },
        })
      ).getCheckupInterview || undefined;
  }

  async fetchPostInterview() {
    this.postInterview =
      (
        await GQL.queryAsGuest<GetCheckupInterviewQueryVariables, GetCheckupInterviewQuery>(getCheckupInterview, {
          input: {
            timing: '2_AFTER_RESERVING',
            plan: this.course?.id || '',
            options: this.options.map(o => o.id),
          },
        })
      ).getCheckupInterview || undefined;
  }

  public async fetchSuggestions() {
    const { listCheckupOptionSuggestion } = await GQL.queryAsGuest<
      ListCheckupOptionSuggestionQueryVariables,
      ListCheckupOptionSuggestionQuery
    >(listCheckupSuggestions, {
      input: safeFilter(this.preInterview?.questions)
        .map(q => safeFilter(q.answer))
        .reduce((res, elem) => [...res, ...elem], [])
        .map(ans => ({
          id: Number(ans.option?.id || 0),
          label: ans.option?.value || '',
          value: ans.answer || '',
        })),
    });

    this.selectableOptions = this.selectableOptions.map(option => ({
      ...option,
      suggestText: listCheckupOptionSuggestion?.find(s => s?.optionId === option.id)?.text,
      suggestSeverity: listCheckupOptionSuggestion?.find(s => s?.optionId === option.id)?.severity || undefined,
    }));
  }

  async fetchShouldConfirm() {
    this.shouldConfirm = true;
  }

  async fetchCheckupTreatmentInfo(treatmentId: string) {
    await this.fetchMasters();
    const treatment = await GQL.query<
      GetCheckupAppointmentByTreatmentIdQueryVariables,
      GetCheckupAppointmentByTreatmentIdQuery
    >(getCheckupAppointmentByTreatmentId, {
      id: treatmentId,
    });

    this.preInterview = treatment.getTreatment?.preInterview || undefined;
    this.options = this.selectableOptions.filter(o => treatment.getTreatment?.options?.includes(o.id));
    const course = this.selectableCourses.find(o => treatment.getTreatment?.checkupAppointment?.plan.id === o.id);
    this.course = course;
    course?.cancelPolicies && this.cancelPolicies.setCancelPolicies(course.cancelPolicies);
    this.appointmentId = treatment.getTreatment?.checkupAppointment?.id;
    this.discountCode = treatment.getTreatment?.discountCode || undefined;
    if (treatment.getTreatment?.department) {
      const department = {
        id: treatment.getTreatment.department.id,
        baseDuration: treatment.getTreatment.department.baseDuration || 0,
        name: treatment.getTreatment.department.name || '',
        clinic: treatment.getTreatment.department.clinic?.id || '',
        icon: treatment.getTreatment.department.icon as IconName,
        termsUrl: treatment.getTreatment.department.clinic?.termsUrl || '',
        alertThreshold: 0,
        clinicName: treatment.getTreatment.department.clinic?.name,
        type: treatment.getTreatment.department.type || DepartmentType.Checkup,
      };
      this.department = department;

      this.details = safeFilter(treatment.getTreatment.checkupAppointment?.treatment.items)
        .filter(i => i.status !== TreatmentStatus.Canceled)
        .sort(i => (i.department?.id === 'C' ? -1 : 1))
        .map(i => ({
          date: parse(`${i.date} ${i.time}`, 'yyyy-MM-dd HH:mm', new Date()),
          department,
          duration: i.duration || 0,
          laneId: i.laneId || '',
        }));
    }

    this.store();
  }

  async postTemporallyTreatment() {
    if (!this.department || this.details.length === 0) {
      throw new Error('必須項目が入力されていません。');
    }

    const res = await GQL.queryAsGuest<
      CreateTemporallyCheckupReservationIndividualMutationVariables,
      CreateTemporallyCheckupReservationIndividualMutation
    >(createTemporallyCheckupReservationIndividual, {
      input: {
        checkupTreatment: {
          clinic: this.department.clinic,
          date: format(this.details[0].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[0].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: this.department.id,
          duration: this.details[0].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[0].date, 'yyyyMM'),
          treatmentKind: this.course?.treatmentKindId || this.department.id,
          options: this.options.map(o => o.id),
          displayDuration: this.displayDuration,
          laneId: this.details[0]?.laneId,
          discountCode: this.discountCode,
        },
        dentalTreatment: this.details[1] && {
          clinic: '02', // FIXME
          date: format(this.details[1].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[1].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: 'D',
          duration: this.details[1].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[1].date, 'yyyyMM'),
          treatmentKind: this.getDentalTreatmentKind()?.id || 'DC1',
          options: this.options.map(o => o.id),
          hidden: true,
          laneId: this.details[1]?.laneId,
          discountCode: this.discountCode,
        },
        appointment: {
          address: this.kitDeliveryAddress || '',
          birthDate: '', // todo
          optionIds: this.options.map(o => o.id),
          planId: this.course?.id,
        },
        interview: this.buildPreInterview(),
      },
    });
    this.flush();
    return res.reserveCheckupAppointmentIndividualTemporarily?.id;
  }

  static buildInterview(i?: InterviewTypeFragment) {
    return i
      ? {
          ...i,
          finished: true,
          submitted: true,
          questions: safeFilter(i.questions).map(q => ({
            ...q,
            answer: safeFilter(q.answer),
            id: String(q.id || 0),
            options: safeFilter(q.options),
          })),
        }
      : {
          id: '',
          questions: [],
        };
  }

  buildPreInterview() {
    return IndividualCheckupReservationStore.buildInterview(this.preInterview);
  }

  buildPostInterview() {
    return IndividualCheckupReservationStore.buildInterview(this.postInterview);
  }

  get displayDuration() {
    const end = this.details
      .map(x => addMinutes(x.date, approximate(x.duration, 15)))
      .sort((a, b) => b.getTime() - a.getTime())[0];
    const start = this.details.map(x => x.date).sort((a, b) => a.getTime() - b.getTime())[0];
    return (end.getTime() - start.getTime()) / 60_000 + Defaults.INTERVAL;
  }

  async postTreatment() {
    if (!this.department || this.details.length === 0) {
      throw new Error('必須項目が入力されていません。');
    }

    const res = await GQL.query<
      CreateCheckupReservationForIndividualMutationVariables,
      CreateCheckupReservationForIndividualMutation
    >(createCheckupReservationForIndividual, {
      input: {
        checkupTreatment: {
          clinic: this.department.clinic,
          date: format(this.details[0].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[0].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: this.department.id,
          duration: this.details[0].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[0].date, 'yyyyMM'),
          treatmentKind: this.course?.treatmentKindId || this.department.id,
          options: this.options.map(o => o.id),
          displayDuration: this.displayDuration,
          laneId: this.details[0]?.laneId,
          discountCode: this.discountCode,
          cancelPolicyRevision: this.cancelPolicies.cancelPolicyInfo?.cancelPolicyRevision,
        },
        dentalTreatment: this.details[1] && {
          clinic: '02', // FIXME
          date: format(this.details[1].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[1].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: 'D',
          duration: this.details[1].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[1].date, 'yyyyMM'),
          treatmentKind: this.getDentalTreatmentKind()?.id || 'DC1',
          options: this.options.map(o => o.id),
          hidden: true,
          laneId: this.details[1]?.laneId,
          discountCode: this.discountCode,
          cancelPolicyRevision: this.cancelPolicies.cancelPolicyInfo?.cancelPolicyRevision,
        },
        appointment: {
          address: this.kitDeliveryAddress || '',
          birthDate: '', // todo
          optionIds: this.options.map(o => o.id),
          planId: this.course?.id,
        },
        interview: this.buildPreInterview(),
      },
    });
    this.flush();
    return res.reserveCheckupAppointmentIndividual?.id;
  }

  async updateTreatment() {
    if (!this.department || this.details.length === 0) {
      throw new Error('必須項目が入力されていません。');
    }
    const res = await GQL.query<
      UpdateCheckupAppointmentForIndividualMutationVariables,
      UpdateCheckupAppointmentForIndividualMutation
    >(updateCheckupReservationForIndividual, {
      input: {
        checkupTreatment: {
          clinic: this.department.clinic,
          date: format(this.details[0].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[0].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: this.department.id,
          duration: this.details[0].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[0].date, 'yyyyMM'),
          treatmentKind: this.course?.treatmentKindId || this.department.id,
          options: this.options.map(o => o.id),
          displayDuration: this.displayDuration,
          laneId: this.details[0]?.laneId,
          preInterview: this.buildPreInterview(),
          interview: this.buildPostInterview(),
          discountCode: this.discountCode,
        },
        dentalTreatment: this.details[1] && {
          clinic: '02', // FIXME
          date: format(this.details[1].date, IndividualCheckupReservationStore.DATE_FORMAT),
          time: format(this.details[1].date, IndividualCheckupReservationStore.TIME_FORMAT),
          department: 'D',
          duration: this.details[1].duration,
          physician: '10001', // FIXME
          status: TreatmentStatus.Fixed,
          yearMonth: format(this.details[1].date, 'yyyyMM'),
          treatmentKind: this.getDentalTreatmentKind()?.id || 'DC1',
          options: this.options.map(o => o.id),
          hidden: true,
          laneId: this.details[1]?.laneId,
          preInterview: this.buildPreInterview(),
          interview: this.buildPostInterview(),
          discountCode: this.discountCode,
        },
        appointment: {
          address: this.kitDeliveryAddress || '',
          birthDate: '',
          appointmentId: this.appointmentId,
          optionIds: this.options.map(o => o.id),
          planId: this.course?.id,
        },
      },
    });
    this.flush();
    return res.updateCheckupAppointmentIndividual?.id;
  }

  getDentalTreatmentKind(): TreatmentKind | undefined {
    return this.options
      .filter(o => o.clinicType === ClinicType.Dental && o.treatmentKind)
      .sort((a, b) => b.requiredMin - a.requiredMin)
      .map(o => o.treatmentKind)[0];
  }

  get treatmentKind() {
    return this.course
      ? { id: this.course.treatmentKindId || 'C', name: '' }
      : { id: this.department?.id || 'C', name: '' };
  }
}

type TreatmentKind = {
  id: string;
  name: string;
};

type CheckupCourseWithPrice = CheckupCourse & {
  price: number;
  regularPrice: number;
  description: string;
  options: string[];
};
