import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export const getCheckupTreatmentWithInterview = gql`
  query getCheckupTreatmentWithInterview($id: String) {
    getTreatment(id: $id) {
      interview {
        ...InterviewType
      }
      preInterview {
        ...InterviewType
      }
      options
      checkupAppointment {
        plan {
          id
        }
      }
    }
  }
  ${InterviewType}
`;
