import { css } from 'emotion';

import { Color } from '../../../constants/Style';
import { Result } from '../../../interfaces/Result';

export const resultWordStyle: Record<Result, string> = {
  A: textColor(Color.NotingResult),
  B: textColor(Color.AttentionResult),
  C: textColor(Color.ConsultationResult),
  D: textColor(Color.TreatmentResult),
};

function textColor(color: Color) {
  return css`
    color: ${color};
  `;
}
