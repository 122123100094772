import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DepartmentType } from '../../../../gql/gql-types';
import { BreadCrumb } from '../../../components/BreadCrumb';
import { Card } from '../../../components/Card';
import { Modal } from '../../../components/Modal';
import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { useSteps } from '../../../hooks/useSteps';
import { SessionStore } from '../../../stores/SessionStore';
import { AccountRestrictedToast } from '../components/AccountRestrictedToast';
import { VacanciesStore } from '../stores/VacanciesStore';
import { CourseSelectPage } from './CourseSelect';
import { DepartmentSelectPage } from './DepartmentSelect';
import { useAutoDepartmentAndCourseSelect } from './hooks/useAutoDepartmentAndCourseSelect';
import { useTreatmentRegisterFlow } from './hooks/useTreatmentRegisterFlow';
import { useWaitingListRegisterFlow } from './hooks/useWaitingListRegisterFlow';
import { RevisitSelectPage } from './RevisitSelect';
import { CourseStore } from './stores/CourseStore';
import { ReservationStore } from './stores/ReservationStore';
import { WaitingListStore } from './stores/WaitingListStore';
import { VacancySelectPage } from './VacancySelect';

type Step = 'DepartmentSelect' | 'VacancySelect' | 'CourseSelect' | 'RevisitSelect';
/**
 * 予約登録画面
 */
export const ReservationNewPage = observer(() => {
  const sessionStore = useContext(SessionStore.Context);
  const [step, setStep] = useState<Step>('DepartmentSelect');
  useSteps(step, setStep);
  const {
    store,
    transaction: [doRegister, registerStatus],
    vacancyStore,
    session,
  } = useTreatmentRegisterFlow();
  const { waitingListStore, doRegisterWaitingList, registerWaitingListStatus } = useWaitingListRegisterFlow();
  const { push } = useHistory();
  const onSelectDepartment = () => {
    if (store.department?.id === 'D' && store.treatmentKind?.id) {
      // コースが選択されている場合は初診として日付選択へ
      store.setRevisit('NEW');
      setStep('VacancySelect');
      return;
    }
    if (store.department?.id === 'D') {
      setStep('RevisitSelect');
      return;
    }
    // 診療科とコースが決まってたら日付選択へ
    if (store.department?.id && store.treatmentKind?.id) {
      // 任意の診療科を選択後ブラウザバック。他の診療科を選択した場合にdepartmentIdとtreatmentKindIdの整合性が取れていないためIDの確認処理を追加。

      if (CourseStore.treatmentKindIds[store.department.id]?.includes(store.treatmentKind?.id)) {
        setStep('VacancySelect');
        return;
      }
    }
    switch (store.department?.type) {
      case DepartmentType.Standard:
      default:
        setStep('VacancySelect');
        return;
      case DepartmentType.CourseBased:
        setStep('CourseSelect');
        return;
      case DepartmentType.Checkup:
        push(Routes.Paths.CheckupReservationNewIndividual);
    }
  };
  useAutoDepartmentAndCourseSelect(store, () => {
    onSelectDepartment();
  });
  const breadCrumbs = {
    DepartmentSelect: [
      {
        label: 'TOP',
        step: 'DepartmentSelect',
        onClick: () => {
          push(Routes.Paths.Root);
        },
      },
      {
        label: '診療科目選択',
        step: 'DepartmentSelect',
        onClick: () => {
          setStep('DepartmentSelect');
        },
      },
    ],
    CourseSelect: [
      {
        label: 'TOP',
        step: 'DepartmentSelect',
        onClick: () => {
          push(Routes.Paths.Root);
        },
      },
      {
        label: '診療科目選択',
        step: 'DepartmentSelect',
        onClick: () => {
          setStep('DepartmentSelect');
        },
      },
      {
        label: `${store.department?.name}のコースの選択`,
        step: 'CourseSelect',
        onClick: () => {
          setStep('CourseSelect');
        },
      },
    ],
    RevisitSelect: [
      {
        label: 'TOP',
        step: 'DepartmentSelect',
        onClick: () => {
          push(Routes.Paths.Root);
        },
      },
      {
        label: '診療科目選択',
        step: 'DepartmentSelect',
        onClick: () => {
          setStep('DepartmentSelect');
        },
      },
      {
        label: `来院・再来院の選択`,
        step: 'RevisitSelect',
        onClick: () => {
          setStep('RevisitSelect');
        },
      },
    ],
    VacancySelect: [
      {
        label: 'TOP',
        step: 'DepartmentSelect',
        onClick: () => {
          push(Routes.Paths.Root);
        },
      },
      {
        label: '診療科目選択',
        step: 'DepartmentSelect',
        onClick: () => {
          setStep('DepartmentSelect');
        },
      },
      {
        label: `${store.department?.name}のコースの選択`,
        step: 'CourseSelect',
        onClick: () => {
          setStep('CourseSelect');
        },
      },
      {
        label: '予約日時の選択',
        step: 'VacancySelect',
        onClick: () => {
          setStep('VacancySelect');
        },
      },
    ],
  };

  return (
    <>
      <AccountRestrictedToast accountRestricted={!!sessionStore.user?.userEntity?.accountRestricted} />
      <ReservationStore.Context.Provider value={store}>
        <VacanciesStore.Context.Provider value={vacancyStore}>
          <WaitingListStore.Context.Provider value={waitingListStore}>
            <Modal visible={!!registerStatus.running || !!registerWaitingListStatus.running}>
              <Card>予約状況を確認中...</Card>
            </Modal>
            <BreadCrumb
              items={breadCrumbs[step].filter(
                x => store.department?.type === DepartmentType.CourseBased || x.step !== 'CourseSelect',
              )}
            />
            {(() => {
              switch (step) {
                default:
                case 'DepartmentSelect':
                  return <DepartmentSelectPage onNext={onSelectDepartment} />;
                case 'CourseSelect':
                  return <CourseSelectPage onNext={() => setStep('VacancySelect')} />;
                case 'RevisitSelect':
                  return <RevisitSelectPage onNext={() => setStep('CourseSelect')} />;
                case 'VacancySelect':
                  return (
                    <VacancySelectPage
                      onNext={(type?: string) => {
                        doRegister(session, type);
                      }}
                      onRegisterWaitingList={() => {
                        doRegisterWaitingList();
                      }}
                    />
                  );
              }
            })()}
          </WaitingListStore.Context.Provider>
        </VacanciesStore.Context.Provider>
      </ReservationStore.Context.Provider>
    </>
  );
});
