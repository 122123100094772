import React, { useMemo, useState } from 'react';

import { Card } from '../../../../components/Card';
import { Modal } from '../../../../components/Modal';
import { Routes } from '../../../../constants/Routes';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useSteps } from '../../../../hooks/useSteps';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ValueOf } from '../../../../interface';
import { CheckupReservationPreInterview } from '../CheckupReservationsNew/CheckupReservationPreInterview';
import { CheckupReservationStore } from '../CheckupReservationsNew/stores/CheckupReservationStore';
import { CheckupReservationFixStartPage } from './CheckupReservationFixStart';

const STEPS = {
  CODE_INPUT: 'codeInput',
  MANIFEST: 'manifest',
  PRE_INTERVIEW: 'preInterview',
  BASIC_INFO: 'basicInfo',
  VACANCY_SELECT: 'vacancySelect',
  START: 'start',
} as const;

type Step = ValueOf<typeof STEPS>;

export const CheckupReservationFixPage = () => {
  const [step, setStep] = useState<Step>((window.location.hash || '').slice(1) as Step);
  const store = useMemo(() => new CheckupReservationStore(), []);
  useSteps(step, setStep);
  const { push, reservationId } = useReservationId();

  const [fix, fixStatus] = useTransaction(async () => {
    const newId = await store.fix(reservationId);
    if (!newId) {
      return;
    }
    push(Routes.Paths.ReservationSuccess, newId);
  });

  const main = (step: Step | undefined) => {
    switch (step) {
      case 'manifest':
      default:
        return <CheckupReservationFixStartPage onNext={() => setStep(STEPS.PRE_INTERVIEW)} />;
      case 'preInterview':
        return <CheckupReservationPreInterview onNext={() => fix()} onBack={() => setStep(STEPS.MANIFEST)} />;
    }
  };

  return (
    <CheckupReservationStore.Context.Provider value={store}>
      <Modal visible={!!fixStatus.running}>
        <Card>予約を確定中です。</Card>
      </Modal>
      {main(step)}
    </CheckupReservationStore.Context.Provider>
  );
};
