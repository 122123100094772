import gql from 'graphql-tag';

import { CancelPoliciesType } from '../fragments/cancelPolicies';
import { CheckupMemberEventType } from '../fragments/checkupMemberEvent';

export const getCheckupOrganizationMemberEvent = gql`
  query getCheckupOrganizationMemberEvent($id: ID!) {
    getCheckupOrganizationMemberEvent(id: $id) {
      ...CheckupMemberEventType
    }
    listCheckupPlans {
      items {
        id
        label
        price
        treatmentKindId
        options
        treatmentKind {
          ...CancelPoliciesType
        }
      }
    }
  }
  ${CheckupMemberEventType}
  ${CancelPoliciesType}
`;
