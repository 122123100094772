import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  CreateCardMutation,
  CreateCardMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  Maybe,
  ProfileTypeFragment,
  Sex,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../../../../../gql/gql-types';
import createCard from '../../../../../gql/operations/createCard';
import getUser from '../../../../../gql/operations/getUser';
import { updateUser } from '../../../../../gql/operations/updateUser';
import { Card } from '../../../../domains/customer/Card';
import { Address } from '../../../../domains/user/Address';
import { ElementOf, safeFilter } from '../../../../utils/CollectionUtil';
import { CreditCardStore } from '../../store/CreditCardStore';
import { InsuranceCardStore } from '../AccountEdit/components/InsuranceCardInput/stores/InsuranceCardStore';

export class AccountDetailStore {
  public static Context = createContext<AccountDetailStore | null>(null);

  constructor() {
    makeAutoObservable(this, {
      id: observable,
      edit: observable,
      profile: observable,
      fetchProfile: action,
      setProfile: action,
      updateProfile: action,
    });
  }

  public id?: string = undefined;

  public edit?: ProfileStore = undefined;
  public profile?: ProfileStore = undefined;

  public setProfile(res?: Maybe<ProfileTypeFragment>) {
    if (!res) {
      return;
    }

    const defaultCard = res.customer?.cards.items?.find(card => card?.id === res.customer?.defaultCard);
    this.profile = new ProfileStore({
      name: res.name,
      id: res.id,
      address: {
        zipCode: res.zipCode,
        addressLine1: res.addressLine1,
        addressLine2: res.addressLine2,
        pref: res.pref,
      },
      phone: res.phone,
      sex: res.sex,
      mail: res.mail,
      age: res.age,
      birthday: res.birthday,
      card: defaultCard,
      patientNo: res.patient?.patientNo,
    });
    this.profile.setInsuranceCards(safeFilter(res.insuranceCards));
    this.edit = new ProfileStore({ ...this.profile });
    this.edit.cardStatus.setIsUsingDefaultCard(!!defaultCard);
  }

  public async fetchProfile(id: string) {
    this.id = id;
    const res = (
      await GQL.query<GetUserQueryVariables, GetUserQuery>(getUser, {
        userId: this.id,
      })
    ).getUser;
    this.setProfile(res);
  }

  public async updateProfile() {
    if (!this.id || !this.edit || !this.edit.name || !this.edit.valid) {
      return;
    }
    const { updateProfile: res } = await GQL.query<UpdateUserMutationVariables, UpdateUserMutation>(updateUser, {
      input: {
        id: this.id,
        name: this.edit.name,
        age: this.edit.age,
        birthday: this.edit.birthday,
        mail: this.edit.mail,
        phone: this.edit.phone,
        sex: this.edit.sex,
        zipCode: this.edit.address?.zipCode,
        addressLine1: this.edit.address?.addressLine1,
        addressLine2: this.edit.address?.addressLine2,
        pref: this.edit.address?.pref,
        insuranceCards: this.edit.insuranceCards.urls.map(u => ({ key: u.key })),
      },
    });

    this.setProfile(res);
  }

  public async updateCard(card?: Card) {
    if (!this.id || !card) {
      return;
    }

    await GQL.query<CreateCardMutationVariables, CreateCardMutation>(createCard, {
      input: {
        brand: card.brand,
        expAt: card.expAt,
        fourDigit: card.fourDigit,
        id: card.id,
        payjpID: card.id,
        cardCustomerId: this.id,
      },
    });

    this.profile?.setCard(card);
  }

  public async updateFirstReserveProfile() {
    const card = this.edit?.card;
    await this.updateProfile();
    await this.updateCard(card);
  }
}

class ProfileStore {
  constructor(origin?: any) {
    Object.assign(this, { ...origin, insuranceCards: undefined });
    this.insuranceCards = new InsuranceCardStore();
    this.insuranceCards.setUrls(origin?.insuranceCards?.urls || []);
    this.insuranceCards.setUserId(origin.id);
    makeAutoObservable(this, {
      name: observable,
      id: observable,
      address: observable,
      phone: observable,
      sex: observable,
      mail: observable,
      age: observable,
      birthday: observable,
      card: observable,
      patientNo: observable,
      setName: action,
      setId: action,
      setAddress: action,
      setMail: action,
      setPhone: action,
      setSex: action,
      setAge: action,
      setCard: action,
      valid: computed,
      firstReserveValidation: action,
      setPatientNo: action,
      setInsuranceCards: action,
      insuranceCards: observable,
      errors: observable,
      preEditValid: observable,
      setPreEditValid: action,
    });
  }

  public name?: string = undefined;
  public id?: string = undefined;
  public address?: Address = undefined;
  public phone?: string = undefined;
  public sex?: Sex = undefined;
  public mail?: string = undefined;
  public age?: number = undefined;
  public birthday?: string = undefined;
  public card?: Card = undefined;
  public patientNo?: string = undefined;
  public insuranceCards: InsuranceCardStore = new InsuranceCardStore();
  public errors: ErrorInfo[] = [];
  public preEditValid = true;
  public cardStatus: CreditCardStore = new CreditCardStore();

  public setName(name: string) {
    this.name = name;
  }

  public setId(id: string) {
    this.id = id;
  }

  public setAddress(address: Address) {
    this.address = address;
    this.preEditValidation('zipCode', address.zipCode);
    this.preEditValidation('addressLine1', address.addressLine1);
    this.preEditValidation('addressLine2', address.addressLine2);
  }

  public setPhone(phone: string) {
    this.phone = phone;
    this.preEditValidation('phone', phone);
  }

  public setMail(mail: string) {
    this.mail = mail;
  }

  public setSex(sex: Sex) {
    this.sex = sex;
  }

  public setAge(age: number) {
    this.age = age;
  }

  public setBirthday(birthday: string) {
    this.birthday = birthday;
  }

  public setCard(card?: Card) {
    this.card = card;
  }

  public setPatientNo(patientNo: string) {
    this.patientNo = patientNo;
  }

  public setInsuranceCards(cards: InsuranceCard[]) {
    this.insuranceCards.setUrls(
      cards.map(c => ({ key: c.key || '', url: c.url || '', filename: c.key?.split('/').pop() || '' })),
    );
    if (this.insuranceCards.urls.length > 0) {
      this.preEditValidation('insuranceCard', this.insuranceCards.urls[0].url);
    }
  }

  public get valid() {
    return this.name;
  }

  public setPreEditValid(status: boolean) {
    this.preEditValid = status;
  }

  public firstReserveValidation() {
    this.setPreEditValid(false);
    this.preEditValidation('insuranceCard', this.insuranceCards.urls.length > 0 ? this.insuranceCards.urls[0].url : '');
    this.preEditValidation('phone', this.phone || '');
    this.preEditValidation('zipCode', this.address?.zipCode || '');
    this.preEditValidation('addressLine1', this.address?.addressLine1 || '');
    this.preEditValidation('addressLine2', this.address?.addressLine2 || '');
  }

  private preEditValidation(target: string, param?: string) {
    runInAction(() => {
      const errors = this.errors.filter(error => error.field !== target);
      if (target === 'insuranceCard' && String(param) === '') {
        this.errors = [...errors, { field: target, error: '保険証を選択してください' }];
      } else if (target !== 'addressLine2' && String(param) === '') {
        this.errors = [...errors, { field: target, error: '必須項目です' }];
      } else {
        this.errors = errors;
      }
    });
  }
}

type InsuranceCard = NonNullable<ElementOf<NonNullable<ProfileTypeFragment['insuranceCards']>>>;

type ErrorInfo = {
  field: string;
  error: string;
};
