import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { Calendar, CalendarOnSelectParam } from '../../../../components/Calendar';
import { Caption, CaptionGroup } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Modal } from '../../../../components/Modal';
import { Wrapper } from '../../../../components/Wrapper';
import { Dimension } from '../../../../constants/Style';
import { Department } from '../../../../domains/reservation/Department';
import { useContext } from '../../../../hooks/useContext';
import { useMedia } from '../../../../hooks/useMedia';
import { useTransaction } from '../../../../hooks/useTransaction';
import { AnnouncementStore } from '../../../../stores/AnnouncementStore';
import { SessionStore } from '../../../../stores/SessionStore';
import { VacanciesStore } from '../../stores/VacanciesStore';
import { ReservationStore, TreatmentKind } from '../stores/ReservationStore';
import { WaitingListStore } from '../stores/WaitingListStore';
import { ConfirmDialog } from './components/ConfirmDialog';
import { FeverWarningDialog, useFeverWarning } from './components/FeverWarningDialog';
import { VaccineWarningDialog } from './components/VaccineWarningDialog';
import { WaitingListApplicationDialog } from './components/WaitingListApplicationDialog';

type Prop = {
  onNext?: (type?: string) => void;
  onRegisterWaitingList?: () => void;
};

export const VacancySelectPage = observer(({ onNext, onRegisterWaitingList }: Prop) => {
  const store = useContext(ReservationStore.Context);
  const session = useContext(SessionStore.Context);
  const vacanciesStore = useContext(VacanciesStore.Context);
  const waitingListStore = useContext(WaitingListStore.Context);
  const announcementStore = useMemo(() => new AnnouncementStore(), []);
  const [loadVacancies, status] = useTransaction(
    (week: Date, department: Department, treatmentKind?: TreatmentKind, vaccinePatientId?: string, online?: boolean) =>
      vacanciesStore.fetchVacancies(week, department, treatmentKind, undefined, vaccinePatientId, online),
  );
  const [confirming, setConfirming] = useState(false);
  const [waitingListConfirming, setWaitingListConfirming] = useState(false);
  const wrapped = useMedia([{ query: `(min-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);
  const [week, setWeek] = useState(new Date());
  const now = new Date();

  useEffect(() => {
    if (!store.department) {
      return;
    }
    loadVacancies(week, store.department, store.treatmentKind, store.vaccineEventPatientId, store.online);
    vacanciesStore.prefetchNext(
      week,
      store.department,
      store.online,
      store.treatmentKind,
      undefined,
      store.vaccineEventPatientId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.department, week, store.online]);

  useEffect(() => {
    if (!store.department) {
      return;
    }
    if (!session.authenticated) {
      return;
    }
    store.fetchShouldConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.department, session]);

  useEffect(() => {
    announcementStore.setDepartment(store.department);
    announcementStore.setTreatmentKind(store.treatmentKind);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.department, store.treatmentKind]);

  const handleConfirmation = async (type?: string) => {
    setConfirming(false);
    onNext?.(type);
  };

  const onSelect = ({ date, estimatedDuration, laneId, doctor, waitingList }: CalendarOnSelectParam) => {
    store.setDate(date);
    store.setDuration(estimatedDuration);
    store.setPhysician(doctor);
    store.setLaneId(laneId);

    if (waitingList) {
      setWaitingListConfirming(true);
      waitingListStore.setInitial(store);
      return;
    }
    setConfirming(true);
  };

  const feverWarningProps = useFeverWarning(store);

  const isCovidVaccine = !!store.vaccineEventPatientId && store.treatmentKind?.id === 'COVID1'; // FIXME FIXME! COVID only
  if (status.running || !vacanciesStore.vacancies) {
    return <Modal visible>loading...</Modal>;
  }

  return (
    <>
      <DocumentTitle title={`空き時間選択 - ${store.department?.name}`} />
      <VaccineWarningDialog store={store} />
      <FeverWarningDialog {...feverWarningProps} />
      <WaitingListApplicationDialog
        clinic={{ id: waitingListStore.department?.clinic || '', name: waitingListStore.department?.clinicName || '' }}
        department={waitingListStore.department}
        treatmentKind={waitingListStore.treatmentKind}
        onChange={({ start, end }) => waitingListStore.setRange(start, end)}
        range={{ start: waitingListStore.start, end: waitingListStore.end }}
        open={waitingListConfirming}
        onClose={() => setWaitingListConfirming(false)}
        onCommit={() => onRegisterWaitingList?.()}
        authenticated={session.authenticated}
      />
      <ConfirmDialog
        open={confirming}
        handleStateChange={setConfirming}
        onConfirm={(type?: string) => {
          handleConfirmation(type);
        }}
      />
      <ConditionalWrapper
        conditional={
          <>
            <Calendar
              now={now}
              items={vacanciesStore.vacancies}
              onClickNextWeek={d => setWeek(d)}
              onClickPrevWeek={d => setWeek(d)}
              onSelect={onSelect}
              alertThreshold={store.department?.alertThreshold}
              waitingListEnabled
            />

            {/* 障害対応までの暫定対応
             <QuickReserveButton onClick={onSelect} time={vacanciesStore.nearest} now={now} /> */}
          </>
        }
        wrapped={wrapped}
      >
        <CaptionGroup>
          {/** FIXME COVID only FIXME! */}
          <Caption type="header">
            {store.treatmentKind ? (
              <>{store.treatmentKind.name}の予約日時を選択してください</>
            ) : (
              <>{store.department?.name}の予約日時を選択してください</>
            )}
          </Caption>
          {!(store.treatmentKind?.vaccine || store.department?.requirement) && (
            <Caption type="body">※ご予約可能な期間は当日より3ヶ月までとなっております。</Caption>
          )}
          {store.treatmentKind?.vaccine && (
            <Caption type="danger">
              ※2回目は、1回目の4週間後の同じ曜日にしかご予約できません。予約前に、必ず1回目・2回目の両日が空いていることを確認して下さい。一度予約するとキャンセルはできません
            </Caption>
          )}
          {
            isCovidVaccine && (
              <Caption type="danger">職域接種は原則として4週間後の同じ曜日にしか接種はできません。</Caption>
            ) /** FIXME COVID only FIXME! */
          }
          {announcementStore.announcement && announcementStore.announcement.length <= 300 && (
            <Caption type="danger" break>
              {announcementStore.announcement}
            </Caption>
          )}
          {announcementStore.subAnnouncement && announcementStore.subAnnouncement.length <= 300 && (
            <Caption type="mediumBody" break>
              {announcementStore.subAnnouncement}
            </Caption>
          )}
        </CaptionGroup>
      </ConditionalWrapper>
    </>
  );
});

type Props = {
  children: ReactNode;
  conditional: ReactNode;
  wrapped: boolean;
};
export const ConditionalWrapper = observer(({ children, conditional, wrapped }: Props) => (
  <>
    {wrapped ? (
      <Wrapper>
        {children} {conditional}
      </Wrapper>
    ) : (
      <>
        <Wrapper>{children}</Wrapper>
        {conditional}
      </>
    )}
  </>
));
