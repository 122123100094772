import { css } from 'emotion';
import React from 'react';

import { Icon } from '../../../../components/Icon';
import { gutter, square } from '../../../../helpers/Style';

export const ToTopButton = () => {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className={toTopContainerStyle} onClick={() => toTop()}>
      <Icon name="arrow-up" />
      トップに戻る
    </div>
  );
};

const toTopContainerStyle = css`
  display: flex;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;

  > svg {
    ${square(20)}

    margin-right: ${gutter(3.5)};
  }
`;
