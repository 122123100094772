import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from '../../constants/Routes';
import { Breakpoint, Color, Duration } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { useContext } from '../../hooks/useContext';
import { SessionStore } from '../../stores/SessionStore';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { MainMenu } from './MainMenu';
import { SNSMenu } from './SNSMenu';
import { SubMenu } from './SubMenu';

type Props = {
  onClose: () => void;
};

export const GlobalNavigation = observer(({ onClose }: Props) => {
  const sessionStore = useContext(SessionStore.Context);
  const { push } = useHistory();
  const loggedIn = sessionStore.authenticated;

  // TODO サインイン方法を選択するUIを挟む
  const handleClickMainButton = () => {
    loggedIn ? push(Routes.Paths.ReservationNew) : push(Routes.Paths.AccountNew);
  };

  const handleClickLogout = () => sessionStore.logout();

  return (
    <nav className={baseStyle}>
      <header className={headerStyle}>
        <IconButton name="times" onClick={onClose} />
      </header>

      <h1 className={brandLogoStyle}>
        <Link to={Routes.Paths.Root}>
          <img src="/images/logo-system.svg" alt="TEN" />
        </Link>
      </h1>

      <div className={containerStyle}>
        {loggedIn ? <MainMenu onSelect={onClose} /> : null}
        <div className={buttonContainerStyle}>
          <Button block ghost={!loggedIn} onClick={handleClickMainButton}>
            {loggedIn ? 'WEB予約' : 'ログイン・新規登録'}
          </Button>
        </div>
        <SubMenu loggedIn={loggedIn} onLogout={handleClickLogout} />
        <SNSMenu />
      </div>
    </nav>
  );
});

const baseStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${gutter(4)} 0 ${gutter(8)};
  background: ${Color.GrayscaleWhite};

  @media (min-width: ${Breakpoint.ScreenMd}px) {
    padding: ${gutter(10)} 0;
  }
`;

const headerStyle = css`
  width: 100%;
  padding: 0 ${gutter(4)};
  margin-bottom: ${gutter(8)};

  @media (min-width: ${Breakpoint.ScreenMd}px) {
    display: none;
  }
`;

const brandLogoStyle = css`
  width: 110px;

  > a {
    display: block;
    opacity: 1;
    transition: opacity ${Duration.Fade};

    &:hover {
      opacity: 0.7;
    }
  }
`;

const containerStyle = css`
  width: 194px;
`;

const buttonContainerStyle = css`
  margin-top: ${gutter(7)};
`;
