import { css } from 'emotion';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import { square } from '../../../helpers/Style';
import { Card } from '../../Card';
import { Icon } from '../../Icon';
import { Pin as MainPin } from '../Pin';

export const PharmacyMap = () => (
  <div style={{ width: '100%', height: '300px' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyAaKxa9875joW0quBpYoFOwiRfV9Z3O1ic' }}
      zoom={16.5}
      center={{
        lat: 35.65957,
        lng: 139.70317,
      }}
    >
      <Pin lat={35.66008} lng={139.70469} n="日本調剤　宮益坂薬局（190m）" width={200} />
      <Pin lat={35.65879} lng={139.70523} n="ファーコス薬局 渋谷（210m）" width={200} />
      <Pin lat={35.66083} lng={139.70203} n="ミキ薬局渋谷店（450m）" width={200} />
      <Pin lat={35.65957} lng={139.70317} n="PHARMACY KOU（0m）" width={200} />
      <MainPin lat={35.66} lng={139.7026} />
    </GoogleMapReact>
  </div>
);

type Props = {
  lat: number;
  lng: number;
  n: string;
  width: number;
};

const Pin = ({ n, width }: Props) => (
  <>
    <div className={markerStyle}>
      <div className={pinStyle}>
        <Card shadowType="floating" width={width}>
          {n}
        </Card>
      </div>
      <Icon name="map-marker" />
    </div>
  </>
);

const markerStyle = css`
  > svg {
    ${square(28)}
  }

  position: relative;
`;

const pinStyle = css`
  position: absolute;
  top: -48px;
  transform: translate(-50%, 0);
`;
