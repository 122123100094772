import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { Dictionary, DictionaryKeys } from '../../../../../components/Dictionary';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { Wrapper } from '../../../../../components/Wrapper';
import { GtmClasses } from '../../../../../constants/AnalyticsTags';
import { Routes } from '../../../../../constants/Routes';
import { Color } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';
import { useReservationId } from '../../../../../hooks/useReservationId';

const descriptionStyle = css`
  width: 100%;
  padding: ${gutter(4)};
  margin-top: 55px;

  /* margin-bottom: ${gutter(8)}; */
  word-break: break-all;
  background-color: ${Color.GrayscaleWhite};
`;

const imageContainerStyle = css`
  display: flex;
  justify-content: center;
  margin-top: -55px;
  margin-bottom: ${gutter(8)};
`;

export const IndividualCheckupReservationCompletePage = () => {
  const { push, reservationId } = useReservationId();

  return (
    <Wrapper>
      <DocumentTitle title="ご予約が完了しました" />
      <CaptionGroup>
        <Caption type="header">ご予約ありがとうございます</Caption>
        <Caption type="body">
          <Dictionary dictKey={DictionaryKeys.RESERVATION_COMPLETE_BODY} input={{}}>
            <div>
              予約時間の5分程前には来院し、受付で保険証とクレジットカードをご提示ください。（当院は完全キャッシュレスとなります）
            </div>
          </Dictionary>
        </Caption>
      </CaptionGroup>
      <InterviewRequest onClick={() => push(Routes.Paths.CheckupReservationFixIndividual, reservationId)} />
    </Wrapper>
  );
};

type InterviewRequestProps = {
  onClick?: () => void;
};

const InterviewRequest = observer(({ onClick }: InterviewRequestProps) => {
  const { push } = useHistory();
  return (
    <div>
      <div className={descriptionStyle}>
        <div className={imageContainerStyle}>
          <img src="/images/note_taking.png" alt="医師が問診している画像" />
        </div>
        <Caption type="header" align="center">
          事前問診の回答及び
          <br />
          オプション選択をお願いします
        </Caption>
      </div>
      <div>
        <ButtonGroup gutter={10}>
          <Button className={GtmClasses.gtm.startInterview.onCompleted} block type="button" onClick={onClick}>
            問診の回答・オプション追加
          </Button>
          <Button block type="button" ghost onClick={() => push(Routes.Paths.Root)}>
            あとで回答する（マイページへ）
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
});
