import GoogleMapReact from 'google-map-react';
import React, { ComponentProps } from 'react';

import { Pin } from './Pin';

type Props = ComponentProps<typeof Pin>;

export const Map = ({ lat, lng, type }: Props) => (
  <>
    {lat && lng && lat > 0 && lng > 0 ? (
      <div style={{ width: '100%', height: '300px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAaKxa9875joW0quBpYoFOwiRfV9Z3O1ic' }}
          center={{ lat, lng }}
          zoom={18}
        >
          <Pin lat={lat} lng={lng} type={type} />
        </GoogleMapReact>
      </div>
    ) : (
      <></>
    )}
  </>
);
