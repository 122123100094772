import { css } from 'emotion';
import React, { useMemo, useState } from 'react';

import { Card } from '../../../../../../../../components/Card';
import { Icon } from '../../../../../../../../components/Icon';
import { Tip } from '../../../../../../../../components/Tip';
import { Color, FontSize } from '../../../../../../../../constants/Style';
import { square } from '../../../../../../../../helpers/Style';

type Props = {
  plan: Amount;
  options: Amount[];
  foldable?: boolean;
};

type Amount = {
  name: string;
  regularAmount: number;
  discountAmount: number;
  billingTarget: '1_organization' | '2_own';
};

export const CheckupAmountDisplay = ({ plan, options, foldable }: Props) => {
  const total = useMemo(
    () =>
      (plan.billingTarget === '2_own' ? plan.discountAmount : 0) +
      options.reduce((a, b) => a + (b.billingTarget === '2_own' ? b.discountAmount : 0), 0),
    [plan, options],
  );
  const [open, setOpen] = useState(false);
  return (
    <Card className={cardStyle}>
      <div className={headerContainerStyle}>
        <div className={headerStyle}>合計負担額（税込み）</div>
        <div className={headerAmountStyle}>¥{total.toLocaleString()}</div>
      </div>
      <hr className={hrStyle} />
      <div className={detailContainerStyle}>
        <div className={foldable ? toolbarStyle : ''} onClick={() => setOpen(!open)}>
          <div>内訳</div>
          {foldable && (
            <div className={iconContainerStyle}>
              <div className={open ? reverseStyle : ''}>
                <Icon name="angle-down" />
              </div>
            </div>
          )}
        </div>
        {(!foldable || open) && (
          <>
            <CheckupAmountDisplayDetail amount={plan} />
            {options.map((option, key) => (
              <CheckupAmountDisplayDetail amount={option} key={key} />
            ))}
          </>
        )}
      </div>
    </Card>
  );
};

const toolbarStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const iconContainerStyle = css`
  > div > svg {
    fill: ${Color.GrayscaleNeutral};
    ${square(FontSize.Small)}
  }
`;

const reverseStyle = css`
  transform: rotate(-180deg);
`;

type CoreProps = {
  amount: Amount;
};
const CheckupAmountDisplayDetail = ({ amount }: CoreProps) => (
  <div className={detailStyle}>
    <div>{amount.name}</div>
    <div className={detailAmountStyle}>
      {amount.billingTarget === '1_organization' && <Tip className={tipStyle}>会社負担</Tip>}
      {amount.discountAmount !== amount.regularAmount && (
        <s className={detailItemStyle}>¥{amount.regularAmount.toLocaleString()}</s>
      )}
      <span className={detailItemStyle}>¥{amount.discountAmount.toLocaleString()}</span>
    </div>
  </div>
);

const cardStyle = css`
  padding: 20px 16px;
`;

const detailStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const detailAmountStyle = css`
  display: flex;
  align-items: center;
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;

const tipStyle = css`
  padding: 0 4px;
  color: ${Color.GrayscaleWhite};
  background: ${Color.GrayscaleNeutral};
`;

const detailContainerStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;

const detailItemStyle = css`
  margin-left: 8px;
`;

const headerStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.GrayscaleDarker};
`;

const headerContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const headerAmountStyle = css`
  font-size: ${FontSize.Medium};
`;

const hrStyle = css`
  color: ${Color.GrayscaleLight};
`;
