import gql from 'graphql-tag';

import { CancelPoliciesType } from './cancelPolicies';

export const ReservationType = gql`
  fragment Reservation on Treatment {
    id
    status
    date
    time
    interviewId
    department {
      id
      name
      baseDuration
      icon
      type
      editInterval
      clinic {
        id
        name
        address
        lat
        lng
        mapUrl
        termsUrl
      }
      announcement
      requirement
    }
    treatmentKind {
      id
      name
      course
      vaccine
      vaccineSequence {
        end
      }
      announcement
      requirement
      online
      ...CancelPoliciesType
    }
    duration
    physician
    editCondition {
      doctor {
        id
      }
      duration
      anyone
    }
    interview {
      id
      submitted
    }
    hidden
    displayDuration
    noInterview
    checkupAppointment {
      checkupOrganizationMemberEvent {
        event {
          planBillType
        }
      }
      id
      plan {
        label
        price
      }
      planPrice
      optionPrices {
        optionId
        price
        option {
          label
          regularPrice
        }
        discountAmount {
          discountRate
          discountPrice
        }
        billType
      }
    }
    laneId
  }
  ${CancelPoliciesType}
`;
