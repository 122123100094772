import { css } from 'emotion';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { JUDGED_RESULT } from '../../../../../../constants/CheckupResult';
import { gutter } from '../../../../../../helpers/Style';
import { Result } from '../../../../../../interfaces/Result';
import { safeFilter } from '../../../../../../utils/CollectionUtil';
import { CheckupResultSubGroupStore } from '../../../../Base/stores/CheckupResultSubGroupStore';
import { Accordion } from '../../../../components/Accordion';
import { UseToOtherSubGroup } from '../../../hooks/useToOtherSubGroup';
import { ItemInfo } from '../ItemInfo';

type Props = {
  groupName: string;
  subGroup?: CheckupResultSubGroupStore[];
};

export const OtherItemGroup = ({ groupName, subGroup }: Props) => {
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const params = useParams<{ treatmentId: string }>();
  const location = useLocation();

  const toOtherSubGroup = (subGroupId: string) => {
    UseToOtherSubGroup(subGroupId, history, params, location);
  };
  return (
    <div className={containerStyle}>
      {/* 表示させるものがない場合Accordion自体表示させない */}
      {subGroup?.some(s => s.result === JUDGED_RESULT['A']) && (
        <Accordion groupName={groupName} open={open} setOpen={setOpen} />
      )}
      {open &&
        safeFilter(
          subGroup?.map(
            sub =>
              sub.result === JUDGED_RESULT['A'] && (
                <div key={sub.id} className={pointerStyle} onClick={() => toOtherSubGroup(sub.id)}>
                  <ItemInfo name={sub.name} result={sub.result as Result} />
                </div>
              ),
          ),
        )}
    </div>
  );
};

const containerStyle = css`
  margin-bottom: ${gutter(10.5)};
`;

const pointerStyle = css`
  cursor: pointer;
`;
