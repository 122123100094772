import { css } from 'emotion';
import React from 'react';

import { Button } from '../../../../../components/Button';
import { Card } from '../../../../../components/Card';
import { Icon } from '../../../../../components/Icon';
import { Result } from '../../../../../interfaces/Result';

type Props = {
  attachmentId: string;
  download: (key: string) => void;
  downloadDisable: boolean;
  result: Result;
  resultItems?: {
    subGroup: {
      name: string;
    };
  }[];
  comment?: string;
};
export const SummaryComment = ({ attachmentId, download, downloadDisable, comment, resultItems }: Props) =>
  resultItems && resultItems.length === 0 ? (
    <></>
  ) : (
    <div>
      <Card>
        <p className={commentStyle}>{comment}</p>
        <Button block ghost borderColor="darkGrey">
          <Icon name="file-download-alt" />
          <div onClick={() => !downloadDisable && download(attachmentId)}>健診結果表PDFダウンロード</div>
        </Button>
      </Card>
    </div>
  );

const commentStyle = css`
  white-space: pre-wrap;
`;
