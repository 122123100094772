import gql from 'graphql-tag';

export const listVacancies = gql`
  query ListVacanciesForWeek($input: VacancyUnitCalculation!) {
    listVacancies(input: $input) {
      date
      time
      available
      estimatedDuration
      doctor
      laneId
      waitAvailable
    }
  }
`;
