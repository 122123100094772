import gql from 'graphql-tag';

import { ProfileType } from '../fragments/user';

export const updateUser = gql`
  mutation updateUser($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      ...ProfileType
    }
  }
  ${ProfileType}
`;
