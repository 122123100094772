import gql from 'graphql-tag';

import { CheckupResultItemGroupType } from '../fragments/checkupResultItemGroup';

export const listCheckupResultItemGroups = gql`
  query listCheckupResultItemGroups {
    listCheckupResultItemGroups {
      items {
        ...CheckupResultItemGroupType
      }
    }
  }
  ${CheckupResultItemGroupType}
`;
