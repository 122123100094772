import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../../../domains/base/SearchParams';
import { useFederatedSignIn } from '../../../../../hooks/useFederatedSignIn';
import { useCookie } from '../../../../../utils/CookieUtil';
import { UsageList, UsageListItem } from './components/UsageList';
import { WarningBeforeLogin } from './components/WarningBeforeLogin';

type type = 'LINE' | 'LEGACY';
type Props = {
  onChangeMethod: (method: type | null) => void;
};

export const AccountsNewLineConcent = observer(({ onChangeMethod }: Props) => {
  const lineSignInInternal = useFederatedSignIn();
  const { getCookie } = useCookie();
  const { goBack } = useHistory();
  const [confirm, setConfirm] = useState(false);

  const lineSignIn = useCallback(
    (path: string, searchInternal: string) => {
      // google ads id
      const search = new URLSearchParamsMonad(searchInternal)
        .acceptOptional('gclid', getCookie('_gcl_aw')?.split('.')?.[2] || '')
        .toString();
      lineSignInInternal(`${path}?${search}`);
    },
    [lineSignInInternal, getCookie],
  );

  return (
    <Wrapper>
      <DocumentTitle title="LINEアカウントの認証" />
      <WarningBeforeLogin
        open={confirm}
        onConfirm={() => {
          setConfirm(false);
          lineSignIn(Routes.Paths.AccountName, window.location.search);
        }}
      />
      <CaptionGroup>
        <Caption type="header">LINEアカウントの認証</Caption>
        <Caption type="body">
          本サービスでは、認証時にユーザーに許可された場合のみ、LINEアカウントに登録されているメールアドレスを取得します。取得したメールアドレスは以下の目的以外で使用されることはありません。また、法令で定められた場合を除き、第三者への提供はいたしません。
        </Caption>
      </CaptionGroup>

      <UsageList>
        <UsageListItem>ユーザーのユニークIDとして管理に利用</UsageListItem>
        <UsageListItem>本サービスからのお知らせやメールマガジンの配信に利用</UsageListItem>
        <UsageListItem>退会時、問い合わせ時などの連絡のために利用</UsageListItem>
      </UsageList>

      <ButtonGroup>
        <Button
          theme="line"
          type="button"
          block
          onClick={() => {
            setConfirm(true);
          }}
        >
          LINEログインで認証
        </Button>
        <Button
          theme="neutral"
          type="button"
          block
          ghost
          onClick={() => {
            goBack();
            onChangeMethod(null);
          }}
        >
          戻る
        </Button>
      </ButtonGroup>
      <div className={linkStyle}>
        <a href={Routes.Externals.FAQ.CANNOT_LOGIN}>ログインできない場合はこちら</a>
      </div>
    </Wrapper>
  );
});

const linkStyle = css`
  a {
    text-decoration: underline;
  }
`;
