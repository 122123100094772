import { css, cx } from 'emotion';
import React from 'react';

import { BorderRadius, Color, Duration, FontSize } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';

type Props = {
  anchor: string;
  focused?: boolean;
  isError?: boolean;
};

export const PseudoInput = ({ anchor, focused, isError }: Props) => (
  <label htmlFor={anchor}>
    <div id={anchor} className={isError ? errorStyle : focused ? focusedStyle : style} />
  </label>
);

const style = css`
  display: block;
  width: 100%;
  padding: ${gutter(4)};
  font-size: ${FontSize.Medium};
  line-height: 1;
  background-color: ${Color.GrayscaleWhite};
  border: none;
  border-radius: ${BorderRadius.Small};
  outline: none;
  box-shadow: none;
  transition: box-shadow ${Duration.Fade};
  appearance: none;
`;

const focusedStyle = cx(
  style,
  css`
    box-shadow: 0 0 0 2px ${Color.GrayscaleDarker};
  `,
);

const errorStyle = cx(
  style,
  css`
    box-shadow: 0 0 0 2px ${Color.FunctionalRedNeutral};
  `,
);
