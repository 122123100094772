import { parse } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Calendar, CalendarOnSelectParam } from '../../../../components/Calendar';
import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ErrorDialogContext } from '../../../../components/ErrorDialog';
import { Modal } from '../../../../components/Modal';
import { useToast } from '../../../../components/Toast';
import { Routes } from '../../../../constants/Routes';
import { Dimension } from '../../../../constants/Style';
import { useContext } from '../../../../hooks/useContext';
import { useMedia } from '../../../../hooks/useMedia';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ConditionalWrapper } from '../../ReservationNew/VacancySelect';
import { ReservationDetailStore } from '../stores/ReservationDetailStore';
import { ConfirmDialog } from './components/ConfirmDialog';
import { useVacancyLoad } from './hooks/useVacancyLoad';
import { ReservationEditStore } from './stores/ReservationEditStore';

export const ReservationEditPage = observer(() => {
  const store = useContext(ReservationEditStore.Context);
  const detailStore = useContext(ReservationDetailStore.Context);
  const { loadStatus, vacancyStore, setWeek } = useVacancyLoad();
  const { push } = useReservationId();
  const { setError } = useContext(ErrorDialogContext);
  const [doUpdate, updateStatus] = useTransaction(
    async () => {
      setConfirming(false);
      const id = await store.update();
      addToast({ message: '予約時間を変更しました', icon: 'check-circle', theme: 'success' });
      detailStore.clear();
      push(Routes.Paths.ReservationShow, id);
    },
    e => {
      const gqlError: { errors?: { message: string }[] } = e as any;
      setError({
        header: '予約に失敗しました',
        message: `${
          gqlError.errors ? gqlError.errors[0].message : ''
        }お手数ですが、再度はじめから予約をお願い致します。`,
        onOk: () => {
          setError();
          window.location.reload();
        },
      });
    },
  );
  const [confirming, setConfirming] = useState(false);
  const { addToast } = useToast();

  const { location } = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('confirm');
    if (query) {
      store.setDate(parse(query, 'yyyyMMddHHmm', new Date()));
      setConfirming(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const wrapped = useMedia([{ query: `(min-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);

  const onSelect = useCallback(
    (data: CalendarOnSelectParam) => {
      store.setDate(data.date);
      store.setDuration(data.estimatedDuration);
      store.setPhysician(data.doctor);
      store.setLaneId(data.laneId);
      setConfirming(true);
    },
    [store, setConfirming],
  );

  if (!vacancyStore.vacancies) {
    return (
      <Modal visible>
        <Card>予約状況を確認中...</Card>
      </Modal>
    );
  }

  return (
    <>
      <DocumentTitle title="予約時間の変更" />
      <ConfirmDialog
        open={confirming}
        handleStateChange={setConfirming}
        onConfirm={doUpdate}
        disabled={!!updateStatus.running}
      />
      <ConditionalWrapper
        conditional={
          <>
            <Calendar
              items={vacancyStore.vacancies}
              now={new Date()}
              onClickNextWeek={w => setWeek(w)}
              onClickPrevWeek={w => setWeek(w)}
              onSelect={onSelect}
              alertThreshold={store.department?.alertThreshold}
            />
          </>
        }
        wrapped={wrapped}
      >
        <Caption type="header" underline align="center">
          予約時間の変更
        </Caption>
        <Modal visible={!!updateStatus.running || !!loadStatus.running}>
          <Card>予約状況を確認中...</Card>
        </Modal>
      </ConditionalWrapper>
    </>
  );
});
