import React, { ComponentProps, useEffect, useState } from 'react';

import { Card } from '../Card';
import { Checkbox } from '../Checkbox';
import { CheckboxGroup, ErrorLabel, FormLabel } from '../Form';
import { TextField } from '../TextField';
import { errorStyle, InterviewReactiveFormItem, toNonNull } from '.';
import { OptionProp } from './types';

type Prop = ComponentProps<typeof InterviewReactiveFormItem>;

const dispatchValueChange = (
  onChange: Prop['onChange'],
  values: Prop['value'],
  def: Prop['def'],
  id: string | number,
  checked: boolean,
  value?: string,
) => {
  const predicate = (p: { id?: number | string | null } | null | undefined) => String(p?.id) === String(id);
  const v = def.options?.find(predicate);
  onChange([
    ...values.filter(p => !predicate(p)),
    ...(checked && v
      ? [{ id: String(v.id || 0), label: v.label || '', value: value === undefined ? v.value || '' : value }]
      : []),
  ]);
};

const InterviewCheckbox = ({ def, option, value, onChange, analyticsClassName }: OptionProp) => {
  const [inputValue, setInputValue] = useState(value.find(v => v.id === option.id)?.value);

  useEffect(() => {
    setInputValue(value.find(v => v.id === option.id)?.value);
  }, [value, option.id]);

  if (option.type === 'checkbox' || option.type === 'FEVER') {
    return (
      <Checkbox
        key={option.value}
        value={String(option.id)}
        label={option.label}
        checked={value.some(v => String(v.id) === String(option.id))}
        onChange={i => dispatchValueChange(onChange, value, def, i.target.value, i.target.checked)}
        analyticsClassName={analyticsClassName}
      />
    );
  }

  const onBlur = () => {
    dispatchValueChange(onChange, value, def, String(option.id), true, inputValue);
  };

  return (
    <>
      <div>
        <Checkbox
          key={option.value}
          value={String(option.id)}
          label={option.label}
          checked={inputValue !== undefined}
          onChange={i => dispatchValueChange(onChange, value, def, i.target.value, i.target.checked, '')}
          analyticsClassName={analyticsClassName}
        />
      </div>
      <div>
        <TextField
          key={`${option.value}input`}
          type={option.type === 'input ' ? 'text' : (option.type as any)}
          onChange={t => setInputValue(t)}
          onFocusout={onBlur}
          disabled={inputValue === undefined}
          value={inputValue || ''}
          placeholder={
            inputValue === undefined ? '' : option.type === 'number' ? '量を選択してください' : '症状を記載してください'
          }
        />
      </div>
    </>
  );
};

export const InterviewCheckboxGroup = ({ def, value, onChange, errors, analyticsClassName }: Prop) => (
  <>
    <FormLabel required={!!def.required}>{def.label}</FormLabel>
    <Card className={errors && errorStyle}>
      <CheckboxGroup>
        {(def.options || []).map((option, index) => (
          <InterviewCheckbox
            key={option?.id}
            def={def}
            value={value}
            onChange={onChange}
            option={toNonNull(option)}
            analyticsClassName={`${analyticsClassName}_${index + 1}`}
          />
        ))}
      </CheckboxGroup>
    </Card>
    <ErrorLabel errors={errors} />
  </>
);
