import { css } from 'emotion';
import React, { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { Routes } from '../../../../../constants/Routes';
import { gutter } from '../../../../../helpers/Style';
import { EmptyCards } from '../../EmptyCards';
import { ReservationCard } from '../ReservationCard';

type Props = {
  reservations: ComponentProps<typeof ReservationCard>[];
};

export const ReservationCardLayout = ({ reservations }: Props) => {
  const history = useHistory();
  return (
    <>
      {reservations.length > 0 ? (
        <div className={horizontalScrollStyle}>
          {reservations
            .filter(r => !r.hidden)
            .map((reservation, i) => (
              <div className={containerStyle} key={i}>
                <ReservationCard width={fixedWidth} {...reservation} />
              </div>
            ))}
        </div>
      ) : (
        <EmptyCards>
          <Button onClick={() => history.push(Routes.Paths.ReservationNew)}>WEB予約</Button>
        </EmptyCards>
      )}
    </>
  );
};

const fixedWidth = 280;

const containerStyle = css`
  min-width: ${fixedWidth}px;
  margin-right: ${gutter(4)};
`;

const horizontalScrollStyle = css`
  display: flex;
  overflow-x: scroll;
`;
