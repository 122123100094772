import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption } from '../../../../../components/Caption';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Icon } from '../../../../../components/Icon';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { Routes } from '../../../../../constants/Routes';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { AccountLegacyStore } from './stores/AccountsLegacyStore';

export const AccountsNewLegacyInputPage = observer(() => {
  const store = useMemo(() => new AccountLegacyStore(), []);
  const { push } = useHistory();
  const [error, setError] = useState<any[]>([]);
  const [signUp, status] = useTransaction(async () => {
    try {
      if (await store.signUp()) {
        push({
          pathname: Routes.Paths.AccountConfirm,
          search: window.location.search,
        });
      }
    } catch (e) {
      setError([e]);
    }
  });
  return (
    <Wrapper>
      <DocumentTitle title="新規会員登録 - メールアドレス入力" />
      <Caption type="header" align="center">
        新規会員登録
      </Caption>
      <FormLayout>
        <FormItem>
          <FormLabel>メールアドレス</FormLabel>
          <TextField color="white" type="email" value={store.email} onChange={v => store.setEmail(v)} />
        </FormItem>
        <FormItem>
          <FormLabel>パスワード(半角英数8文字以上 大文字小文字と数値を混在させてください。)</FormLabel>
          <TextField color="white" type="password" value={store.password} onChange={v => store.setPassword(v)} />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <Button onClick={() => signUp()} disabled={!!status.running}>
              アカウント登録
            </Button>
            {error.map(e => e?.message || 'エラーがあります。')}
          </ButtonGroup>
        </FormItem>
        <FormItem>
          <Caption type="body">
            ログインすることにより、当社の利用規約および プライバシー規約に同意したとみなされます。
          </Caption>
        </FormItem>
        <FormItem>
          <Button
            block
            ghost
            noBorder
            onClick={() =>
              push({
                pathname: Routes.Paths.SignIn,
                search: window.location.search,
              })
            }
          >
            <Icon name="sign-in-alt" />
            アカウントをお持ちの方
          </Button>
        </FormItem>
      </FormLayout>
    </Wrapper>
  );
});
