import gql from 'graphql-tag';

import { TreatmentKindType } from '../fragments/treatmentKind';

export const listTreatmentKind = gql`
  query listTreatmentKinds {
    listTreatmentKinds {
      items {
        ...TreatmentKindType
      }
    }
  }
  ${TreatmentKindType}
`;
