import { format, getDay, getHours } from 'date-fns/esm';
import { action, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  CreateTemporallyTreatmentMutation,
  CreateTemporallyTreatmentMutationVariables,
  CreateTreatmentMutation,
  CreateTreatmentMutationVariables,
  DepartmentType,
  GetTreatmentKindQuery,
  GetTreatmentKindQueryVariables,
  Maybe,
  ShouldConfirmQuery,
  ShouldConfirmQueryVariables,
  TreatmentKindTypeFragment,
  TreatmentStatus,
  ValidateVaccinePasswordQuery,
  ValidateVaccinePasswordQueryVariables,
} from '../../../../../gql/gql-types';
import createTemporallyTreatment from '../../../../../gql/operations/createTemporallyTreatment';
import createTreatment from '../../../../../gql/operations/createTreatment';
import { getTreatmentKind } from '../../../../../gql/operations/getTreatmentKind';
import { shouldConfirm } from '../../../../../gql/operations/shouldConfirm';
import { validateVaccinePassword } from '../../../../../gql/operations/validateVaccinePassword';
import holidays from '../../../../constants/holiday.json';
import { Department } from '../../../../domains/reservation/Department';
import { CancelPolicyStore } from '../../../../stores/CancelPolicyStore';

export type TreatmentKind = {
  id: string;
  name: string;
  vaccine?: boolean;
  noInterview?: boolean;
  announcement?: Maybe<string>;
  requirement?: Maybe<string>;
  displayDuration?: Maybe<number>;
  cancelPolicies?: TreatmentKindTypeFragment['cancelPolicies'];
  requiredReservationFee?: boolean;
};
export type Revisit = 'NEW' | 'REVISIT' | 'AWAY_FOR_3_MONTHS';

/**
 * 予約登録ウィザードの状態および登録処理を司る
 */
export class ReservationStore {
  public static Context = createContext<ReservationStore | null>(null);

  public date?: Date = undefined;
  public department?: Department = undefined;
  public treatmentKind?: TreatmentKind = undefined;
  public duration = 0;
  public physician?: string = undefined;
  public confirmRequired?: boolean = undefined;
  public reservationFeeRequired?: boolean = true;
  public confirmRequiredLoading?: boolean = undefined;
  public online = false;
  public laneId?: string = undefined;
  public vaccineEventPatientId?: string = undefined;
  public noInterview?: boolean = false;
  public revisit?: Revisit = undefined;
  public cancelPolicy: CancelPolicyStore = new CancelPolicyStore();

  constructor() {
    makeAutoObservable(this, {
      date: observable,
      department: observable,
      setDate: action,
      setDepartment: action,
      postTemporallyReservation: action,
      duration: observable,
      setDuration: action,
      setTreatmentKind: action,
      physician: observable,
      setPhysician: action,
      confirmRequired: observable,
      fetchShouldConfirm: action,
      confirmRequiredLoading: observable,
      vaccineEventPatientId: observable,
      setVaccineEventPatientId: action,
      validateVaccineId: action,
      noInterview: observable,
      setNoInterview: action,
      online: observable,
      setOnline: action,
      toggleOnline: action,
      laneId: observable,
      setLaneId: action,
      revisit: observable,
      setRevisit: action,
    });
  }

  public setRevisit(revisit: Revisit) {
    this.revisit = revisit;
  }

  public setLaneId(laneId: string) {
    this.laneId = laneId;
  }

  public setDate(d: Date) {
    this.date = d;
  }

  public async setDepartment(d: Department) {
    this.department = d;
    this.setDuration(d.baseDuration);
    if (!d.type || d.type === DepartmentType.Standard) {
      const { getTreatmentKind: treatmentKind } = await GQL.queryAsGuest<
        GetTreatmentKindQueryVariables,
        GetTreatmentKindQuery
      >(getTreatmentKind, { treatmentKindId: this.department.id });
      this.setTreatmentKind({
        id: treatmentKind?.id || this.department.id,
        name: treatmentKind?.name || this.department.name,
        vaccine: treatmentKind?.vaccine || undefined,
        noInterview: treatmentKind?.noInterview || undefined,
        announcement: treatmentKind?.announcement,
        requirement: treatmentKind?.requirement,
        displayDuration: treatmentKind?.displayDuration,
        cancelPolicies: treatmentKind?.cancelPolicies,
        requiredReservationFee: treatmentKind?.requiredReservationFee || false,
      });
    }
  }

  public setDuration(d: number) {
    this.duration = d;
  }

  public setTreatmentKind(t: TreatmentKind) {
    this.treatmentKind = t;
    if (t.noInterview) {
      this.setNoInterview(true);
    }
    if (t.cancelPolicies) {
      this.cancelPolicy.setCancelPolicies(t.cancelPolicies);
    }
  }

  public setPhysician(p?: string) {
    this.physician = p;
  }

  public setVaccineEventPatientId(id: string) {
    this.vaccineEventPatientId = id;
  }

  public setOnline(o: boolean) {
    this.online = o;
  }

  public toggleOnline() {
    this.online = !this.online;
  }

  public setNoInterview(noInterview: boolean) {
    this.noInterview = noInterview;
  }

  public async postTemporallyReservation() {
    if (!this.date || !this.department || !this.laneId) {
      throw new Error('required values are empty');
    }
    const res = await GQL.queryAsGuest<CreateTemporallyTreatmentMutationVariables, CreateTemporallyTreatmentMutation>(
      createTemporallyTreatment,
      {
        input: {
          clinic: this.department.clinic,
          date: format(this.date, 'yyyy-MM-dd'),
          physician: this.physician || '10001', // FIXME
          time: format(this.date, 'HH:mm'),
          status: TreatmentStatus.Temporally,
          department: this.department.id,
          temporally: new Date().toISOString(),
          treatmentKind: this.treatmentKind?.id || this.department.id,
          duration: this.duration,
          vaccineId: this.vaccineEventPatientId,
          noInterview: this.noInterview,
          ...covidDisplayDuration(this.treatmentKind?.id),
          online: this.online,
          laneId: this.laneId,
          reservationMemo: this.buildMemo(),
          displayDuration: this.treatmentKind?.displayDuration,
          consentedToReservationFee: this.checkedReservationFee,
        },
      },
    );
    return res.createTemporallyTreatment?.id;
  }

  public async postReservation(userId: string) {
    if (!this.date || !this.department || !this.laneId) {
      throw new Error('required values are empty');
    }
    const res = await GQL.query<CreateTreatmentMutationVariables, CreateTreatmentMutation>(createTreatment, {
      input: {
        clinic: this.department.clinic, // FIXME
        date: format(this.date, 'yyyy-MM-dd'),
        physician: this.physician || '10001', // FIXME
        time: format(this.date, 'HH:mm'),
        department: this.department.id,
        userId,
        status: TreatmentStatus.Fixed,
        yearMonth: format(this.date, 'yyyyMM'),
        treatmentKind: this.treatmentKind?.id || this.department.id,
        duration: this.duration,
        vaccineId: this.vaccineEventPatientId,
        noInterview: this.noInterview,
        ...covidDisplayDuration(this.treatmentKind?.id),
        online: this.online,
        laneId: this.laneId,
        reservationMemo: this.buildMemo(),
        displayDuration: this.treatmentKind?.displayDuration,
        cancelPolicyRevision: this.cancelPolicy.cancelPolicyInfo?.cancelPolicyRevision,
        consentedToReservationFee: this.checkedReservationFee,
      },
    });
    return res.createTreatmentWithValidation?.id;
  }

  private buildMemo() {
    const memos = [];
    switch (this.revisit) {
      case 'NEW':
        memos.push('【新患】');
        break;
      case 'REVISIT':
        memos.push('【再診自主予約】');
        break;
      case 'AWAY_FOR_3_MONTHS':
        memos.push(`【再診自己予約（3か月）】`);
        break;
      default:
        break;
    }
    return memos.join(' ');
  }

  public async fetchShouldConfirm() {
    this.confirmRequiredLoading = true;
    if (!this.department) {
      this.confirmRequired = true;
      this.confirmRequiredLoading = false;
      return;
    }

    const res = await GQL.query<ShouldConfirmQueryVariables, ShouldConfirmQuery>(shouldConfirm, {
      input: {
        clinic: this.department.clinic,
      },
    });

    this.confirmRequired = res.shouldConfirm?.confirmRequired;
    this.reservationFeeRequired = res.shouldConfirm?.confirmReservationFeeRequired;
    this.confirmRequiredLoading = false;
  }

  public async validateVaccineId(vaccineId: string) {
    if (!this.treatmentKind) {
      return { valid: false, error: 'コースを選択してください。' };
    }

    this.setVaccineEventPatientId(vaccineId);
    const res = await GQL.queryAsGuest<ValidateVaccinePasswordQueryVariables, ValidateVaccinePasswordQuery>(
      validateVaccinePassword,
      {
        input: {
          treatmentKind: this.treatmentKind.id,
          vaccinePatientId: vaccineId,
        },
      },
    );

    this.noInterview = true;
    return res.validateVaccinePassword;
  }

  public checkedReservationFee = false;
  public setCheckedReservationFee(checked: boolean) {
    this.checkedReservationFee = checked;
  }

  public isReservationFeeDay() {
    if (!this.treatmentKind?.requiredReservationFee) {
      return false;
    }

    const date = this.date ? new Date(this.date) : new Date();
    const day = getDay(date);
    const hours = getHours(date);
    // TODO: 2023年7月19日以後にこの処理を削除
    // 7月19日以後の予約
    if (date.getTime() < new Date('2023-07-19').getTime()) {
      return false;
    }

    // 平日のみ
    if (format(date, 'yyyy-MM-dd') in holidays || day === 0 || day === 6) {
      return false;
    }

    // 9時~18時のみが徴収対象
    return hours >= 9 && hours < 18;
  }
}

// FIXME COVID only
export const covidDisplayDuration = (treatmentKindId?: string) => {
  if (treatmentKindId === 'COVID1') {
    return { displayDuration: 60 };
  }
  if (treatmentKindId === 'COVID2') {
    return { displayDuration: 60 };
  }

  return {};
};
