import { makeAutoObservable } from 'mobx';

import { GQL } from '../../../../../gql/client';
import { CoopGoogleCalendarMutation, CoopGoogleCalendarMutationVariables } from '../../../../../gql/gql-types';
import { coopGoogleCalendarManually } from '../../../../../gql/operations/coopGoogleCalendarManually';

export class GoogleCalendarCoopStore {
  constructor() {
    makeAutoObservable(this, {});
  }

  public id = '';
  public setId(id: string) {
    this.id = id;
  }

  public email?: string = undefined;

  public setEmail(email: string) {
    this.email = email;
  }

  public async kickCoop() {
    await GQL.query<CoopGoogleCalendarMutationVariables, CoopGoogleCalendarMutation>(coopGoogleCalendarManually, {
      input: {
        email: this.email || '',
        treatmentId: this.id,
      },
    });
  }
}
