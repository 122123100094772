import gql from 'graphql-tag';

export const getTemporallyTreatment = gql`
  query getTemporallyTreatment($id: ID!) {
    getTemporallyTreatment(id: $id) {
      clinic {
        id
        name
        termsUrl
      }
      department {
        id
      }
      treatmentKind {
        id
      }
    }
  }
`;
