import 'add-to-calendar-button/assets/css/atcb.css';

import React from 'react';

import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { GtmClasses } from '../../../constants/AnalyticsTags';
import { useAddToCalendarButton } from '../../../hooks/useAddToCalendarButton';

type Props = {
  date: Date;
  displayDuration?: number;
};

export const AppleCalendarButton = ({ date, displayDuration }: Props) => {
  const { ref, handleClick, isiPhone } = useAddToCalendarButton(date, displayDuration);

  if (!isiPhone()) {
    return null;
  }

  return (
    <>
      <Button ref={ref} block ghost onClick={handleClick} className={GtmClasses.gtm.addCalendar.apple}>
        <Icon name="calendar" />
        カレンダーに追加
      </Button>
    </>
  );
};
