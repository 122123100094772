import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';

import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Gutter } from '../../../../components/Gutter';
import { Icon } from '../../../../components/Icon';
import { Wrapper } from '../../../../components/Wrapper';
import { Color, IconSize } from '../../../../constants/Style';
import { square } from '../../../../helpers/Style';
import { useContext } from '../../../../hooks/useContext';
import { ReservationStore, Revisit } from '../stores/ReservationStore';

type Props = {
  onNext: () => void;
};

export const RevisitSelectPage = observer(({ onNext }: Props) => {
  const store = useContext(ReservationStore.Context);
  const onSelect = (r: Revisit) => {
    store.setRevisit(r);
    onNext();
  };
  return (
    <Wrapper>
      <DocumentTitle title="新規・再来院の選択" />
      <Gutter vertical={10}>
        <Caption type="header">新規・再来院を選択してください</Caption>
      </Gutter>
      <Gutter vertical={4}>
        <Caption type="subheader">新規の方</Caption>
      </Gutter>
      <Card shadowType="floating" onClick={() => onSelect('NEW')}>
        <div className={cardStyle}>
          <Caption type="subheader">当院で診察を受けたことがない方</Caption>
          <Icon name="angle-right" />
        </div>
      </Card>
      <Gutter vertical={4}>
        <Caption type="subheader">再来院の方</Caption>
      </Gutter>
      <div className={cardLayout}>
        <Card shadowType="floating" onClick={() => onSelect('REVISIT')}>
          <div className={cardStyle}>
            <Caption type="subheader">3ヶ月以内に当院を受診した方</Caption>
            <Icon name="angle-right" />
          </div>
        </Card>
        <Card shadowType="floating" onClick={() => onSelect('AWAY_FOR_3_MONTHS')}>
          <div className={cardStyle}>
            <Caption type="subheader">
              3ヶ月以上間が空いている方
              <br />
              ※希望の診療が受けられない場合があります
            </Caption>
            <Icon name="angle-right" />
          </div>
        </Card>
      </div>
    </Wrapper>
  );
});

const cardStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    ${square(IconSize.Regular)}

    fill: ${Color.GrayscaleNeutral};
  }
`;

const cardLayout = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
`;
