import { css } from 'emotion';
import React, { useEffect, useMemo } from 'react';

import { Caption, CaptionGroup } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { useTransaction } from '../../../hooks/useTransaction';
import { PolicyStore } from '../stores/PolicyStore';

export const PrivacyPolicyPage = () => {
  const store = useMemo(() => new PolicyStore(), []);
  const [load, loadStatus] = useTransaction(() => store.fetchPrivacyPolicy());

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <DocumentTitle title="プライバシーポリシー" />
      <CaptionGroup>
        <Caption type="header" align="center" underline>
          プライバシーポリシー
        </Caption>
        <pre className={breakStyle}>{loadStatus.running ? '最新の規約を取得中です' : store.body}</pre>
      </CaptionGroup>
    </Wrapper>
  );
};

const breakStyle = css`
  white-space: break-spaces;
`;
