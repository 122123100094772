import { css } from 'emotion';
import React from 'react';

import { Caption, CaptionGroup } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

const imageStyle = css`
  display: flex;
  justify-content: center;
`;
export const PrescriptionUploadComplete = () => {
  useScrollToTop();
  return (
    <Wrapper>
      <DocumentTitle title="処方箋をアップロードしました" />
      <div className={imageStyle}>
        <img src="/images/doctors_bowing.png" alt="医師がお辞儀をしている画像" />
      </div>
      <CaptionGroup>
        <Caption type="header">処方箋をアップロードしました</Caption>
        <Caption type="div">ご来局をお待ちしております。準備ができましたらLINEでお呼び出しします。</Caption>
      </CaptionGroup>
    </Wrapper>
  );
};
