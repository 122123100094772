import React, { useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Card } from '../../../../../../components/Card';
import { ErrorMessage } from '../../../../../../components/ErrorMessage';
import { FormItem, FormLabel } from '../../../../../../components/Form';
import { Modal } from '../../../../../../components/Modal';
import { TextField } from '../../../../../../components/TextField';

type Props = {
  open: boolean;
  onSubmit: (v: string) => void;
  onCancel: () => void;
  disabled?: boolean;
  error?: string;
};

export const PasswordInputDialog = ({ open, onSubmit, disabled, error, onCancel }: Props) => {
  const [value, onChange] = useState('');
  return (
    <Modal visible={open}>
      <Card>
        <FormItem>
          <FormLabel>パスワード</FormLabel>
          <TextField type="password" value={value} onChange={onChange} />
        </FormItem>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonGroup gutter={8}>
          <Button block onClick={() => onSubmit(value)} disabled={disabled}>
            送信
          </Button>
          <Button block onClick={() => onCancel()} ghost>
            戻る
          </Button>
        </ButtonGroup>
      </Card>
    </Modal>
  );
};
