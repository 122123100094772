import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

import { SUMMARY } from '../../../../../../constants/CheckupResult';
import { Color, FontSize } from '../../../../../../constants/Style';
import { gutter } from '../../../../../../helpers/Style';
import { Result } from '../../../../../../interfaces/Result';
import { ResultRound } from '../../../../../CheckupResult/components/ResultRound';

type Props = {
  name?: string;
  result?: Result;
};
export const ItemInfo = ({ name, result }: Props) => {
  const bodyStyle = useMemo(() => cx(baseBodyStyle), []);
  return (
    <div className={bodyStyle}>
      <div>
        <p>{name}</p>
      </div>
      <div className={statusStyle}>
        {result ? (
          <>
            <p className={resultStyle[result]}>{SUMMARY[result]}</p>
            <ResultRound result={result} />
          </>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

const baseBodyStyle = css`
  display: flex;
  justify-content: space-between;
  padding: ${gutter(4)} 0;
  margin-bottom: ${gutter(4)};
  overflow: hidden;
  font-size: ${FontSize.MediumRegular};
  font-weight: bold;
  line-height: 1.5;
  color: ${Color.GrayscaleDarker};
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;

function variantColor(color: Color) {
  return css`
    font-weight: bold;
    color: ${color};
    background-color: transparent;
  `;
}

const resultStyle: Record<Result, string> = {
  A: variantColor(Color.NotingResult),
  B: variantColor(Color.AttentionResult),
  C: variantColor(Color.ConsultationResult),
  D: variantColor(Color.TreatmentResult),
};

const statusStyle = css`
  display: flex;

  > p {
    &:first-child {
      margin-right: ${gutter(1)};
    }
  }
`;
