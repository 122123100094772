import { css, cx } from 'emotion';
import React from 'react';

import { Breakpoint } from '../../../../../constants/Style';
import { Result } from '../../../../../interfaces/Result';

type Props = {
  result: Result;
};
export const ResultDoctorImage = ({ result }: Props) => {
  switch (result) {
    case 'A':
      return (
        <>
          <img
            className={cx(baseStyle, nurseImageAStyle)}
            src="/images/checkupResult/result-a-nurse.png"
            alt="喜ぶ看護師の画像"
          />
          <img
            className={cx(baseStyle, doctorImageAStyle)}
            src="/images/checkupResult/result-a-doctor.png"
            alt="喜ぶお医者さんの画像"
          />
        </>
      );
    case 'B':
      return (
        <img
          className={cx(baseStyle, doctorImageBStyle)}
          src="/images/checkupResult/result-b-doctor.png"
          alt="お医者さんの画像"
        />
      );
    case 'C':
      return (
        <img
          className={cx(baseStyle, doctorImageCStyle)}
          src="/images/checkupResult/result-c-doctor.png"
          alt="お医者さんの画像"
        />
      );
    case 'D':
      return (
        <>
          <img
            className={cx(baseStyle, nurseImageDStyle)}
            src="/images/checkupResult/result-d-nurse.png"
            alt="頭を抱える看護師の画像"
          />
          <img
            className={cx(baseStyle, doctorImageDStyle)}
            src="/images/checkupResult/result-d-doctor.png"
            alt="頭を抱えるお医者さんの画像"
          />
        </>
      );
    default:
      return <></>;
  }
};

const baseStyle = css`
  position: absolute;
  z-index: 0;
`;

const nurseImageAStyle = css`
  bottom: 66px;
  left: -27px;
  width: 155px;

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    bottom: 160px;
    left: -27px;
    width: 110.5px;
  }
`;

const doctorImageAStyle = css`
  right: -37px;
  bottom: 58px;
  width: 172px;

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    right: -35px;
    bottom: 84px;
    width: 137px;
  }
`;

const doctorImageBStyle = css`
  right: 0;
  bottom: 0;
  width: 172px;

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    right: -20px;
    bottom: 34px;
    width: 132px;
  }
`;

const doctorImageCStyle = css`
  right: 86px;
  bottom: 100px;
  width: 108px;

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    right: -20px;
    bottom: 20px;
    width: 150px;
  }
`;

const nurseImageDStyle = css`
  bottom: 0;
  left: -26px;
  width: 172px;
  @media (max-width: ${Breakpoint.ScreenXs}px) {
    bottom: 33px;
    left: -18px;
    width: 145px;
  }
`;

const doctorImageDStyle = css`
  right: -42px;
  bottom: 0;
  width: 217px;
  @media (max-width: ${Breakpoint.ScreenXs}px) {
    width: 196px;
  }
`;
