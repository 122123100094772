import format from 'date-fns/format';
import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { SUMMARY } from '../../../../../constants/CheckupResult';
import { Routes } from '../../../../../constants/Routes';
import { Color, FontSize } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';
import { useTreatmentId } from '../../../../../hooks/useTreatmentId';
import { Treatment } from '../../../../../interfaces/Treatment';
import { DoctorIcon } from './DoctorIcon';

type Props = {
  width?: number;
} & Pick<Treatment, 'clinic' | 'date' | 'department' | 'soap' | 'payment' | 'id' | 'duration' | 'checkupAppointment'>;

type Result = 'A' | 'B' | 'C' | 'D';

export const CheckupResultCard = ({ width, clinic, date, department, soap, id, checkupAppointment }: Props) => {
  const { push } = useTreatmentId();
  return (
    <Card onClick={() => push(Routes.Paths.CheckupResultSummary, id)} shadowType="floating" width={width}>
      <div className={style}>
        <CheckupResultCardHeader date={date} clinic={clinic} department={department} />
        <CheckupResultCardBody soap={soap} checkupAppointment={checkupAppointment} />
        <CheckupResultCardFooter checkupAppointment={checkupAppointment} soap={soap} />
      </div>
    </Card>
  );
};

const style = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 204px;
`;

type HeaderProps = Pick<Props, 'clinic' | 'date' | 'department'>;
const CheckupResultCardHeader = ({ clinic, date, department }: HeaderProps) => (
  <header>
    <Caption type="subheader">{format(date, 'yyyy年MM月dd日')} </Caption>
    <Caption type="small">
      {clinic.name} / {department.name}
    </Caption>
  </header>
);

type BodyProps = Pick<Props, 'soap' | 'checkupAppointment'>;
const CheckupResultCardBody = ({ checkupAppointment }: BodyProps) => {
  const bodyStyle = useMemo(() => cx(baseBodyStyle), []);
  return (
    <div>
      {checkupAppointment.result?.summary?.result && (
        <div className={bodyStyle}>
          <p className={roundStyle[checkupAppointment.result.summary.result as Result]}>
            {checkupAppointment.result.summary.result}
          </p>
          <p className={resultStyle[checkupAppointment.result.summary.result as Result]}>
            {SUMMARY[checkupAppointment.result.summary.result as Result]}
          </p>
        </div>
      )}
    </div>
  );
};

const baseBodyStyle = css`
  display: flex;
  height: 27px;
  overflow: hidden;
  font-size: ${FontSize.MediumRegular};
  line-height: 1.5;
  color: ${Color.GrayscaleDarker};
`;

function roundColor(color: Color) {
  return css`
    width: 24px;
    height: 24px;
    margin-right: ${gutter(2)};
    font-weight: bold;
    color: ${Color.GrayscaleWhite};
    text-align: center;
    background-color: ${color};
    border-radius: 100%;
  `;
}

const roundStyle: Record<Result, string> = {
  A: roundColor(Color.NotingResult),
  B: roundColor(Color.AttentionResult),
  C: roundColor(Color.ConsultationResult),
  D: roundColor(Color.TreatmentResult),
};

function variantColor(color: Color) {
  return css`
    font-weight: bold;
    color: ${color};
    background-color: transparent;
  `;
}

const resultStyle: Record<Result, string> = {
  A: variantColor(Color.NotingResult),
  B: variantColor(Color.AttentionResult),
  C: variantColor(Color.ConsultationResult),
  D: variantColor(Color.TreatmentResult),
};

type FooterProps = Pick<Props, 'soap' | 'checkupAppointment'>;

const CheckupResultCardFooter = ({ soap, checkupAppointment }: FooterProps) => (
  <div>
    <Card color="inset" shadowType="none">
      <div className={doctorStyle}>
        <DoctorIcon id={soap.doctor.id} />
        <div>{soap.doctor.name}</div>
      </div>
      <p className={footerStyle}>
        <p>{checkupAppointment.result?.summary?.comment}</p>
      </p>
    </Card>
  </div>
);

const footerStyle = css`
  display: flex;
  height: 42px;
  overflow: hidden;
  font-size: ${FontSize.Regular};
  line-height: 1.5;
  color: ${Color.GrayscaleDarker};
`;

const doctorStyle = css`
  display: flex;
  align-items: center;
`;
