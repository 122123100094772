import { IconName } from '@clinic-monorepo/clinic-icon';
import { css, keyframes } from 'emotion';
import React, { AnimationEvent, useEffect, useState } from 'react';

import { BorderRadius, Color, Duration, Easing, FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';
import { Icon } from '../Icon';
import { useToast } from '.';

type Theme = 'success';

type Props = {
  id: number;
  message: string;
  icon?: IconName;
  theme?: Theme;
};

export const Toast = ({ id, message, icon, theme = 'success' }: Props) => {
  const { removeToast } = useToast();

  const [addonStyle, setAddonStyle] = useState('');

  const handleAnimationEnd = (e: AnimationEvent<HTMLDivElement>) =>
    window.getComputedStyle(e.currentTarget).opacity === '0' && removeToast(id);

  useEffect(() => {
    const timer = window.setTimeout(() => setAddonStyle(removeStyle), hideDurationTime);

    return () => window.clearTimeout(timer);
  }, [id, removeToast]);

  return (
    <div className={`${itemStyle} ${Theme[theme]} ${addonStyle}`} onAnimationEnd={handleAnimationEnd}>
      {icon ? <Icon name={icon} /> : null}
      <span>{message}</span>
    </div>
  );
};

const hideDurationTime = 5000;

const itemStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 256px;
  padding: ${gutter(4)} ${gutter(8)};
  font-size: ${FontSize.Medium};
  font-weight: 700;
  background: ${Color.GrayscaleWhite};
  border-radius: ${BorderRadius.Regular};
  box-shadow: 0 12px 32px rgb(0 0 0 / 12%);
  animation: ${keyframes`
    from {
      opacity: ${0};
      transform: translate3d(0, -10%, 0);
    }
    to {
      opacity: ${1};
      transform: translate3d(0, 0, 0);
    }
  `} ${Duration.Enter} ${Easing.Enter};

  & + & {
    margin-top: ${gutter(4)};
  }

  > svg {
    ${square(IconSize.Large)}
  }
`;

const removeStyle = css`
  opacity: 0;
  animation: ${keyframes`
    from {
      opacity: ${1};
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: ${0};
      transform: translate3d(0, -40%, 0);
    }
  `} ${Duration.Leave} ${Easing.Leave};
`;

const Theme: Record<Theme, string> = {
  success: css`
    color: ${Color.FunctionalGreenNeutral};
    background: ${Color.FunctionalGreenLight};

    > svg {
      margin-right: ${gutter(3)};
      fill: ${Color.FunctionalGreenNeutral};
    }
  `,
} as const;
