import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { DepartmentType } from '../../../../../../../gql/gql-types';
import { Caption } from '../../../../../../components/Caption';
import { Breakpoint } from '../../../../../../constants/Style';
import { Department } from '../../../../../../domains/reservation/Department';
import { gutter } from '../../../../../../helpers/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { EmptyCards } from '../../../../../Root/components/EmptyCards';
import { DepartmentCard } from './DepartmentCard';

type DepartmentCardProps = Pick<ComponentProps<typeof DepartmentCard>, 'department' | 'nearest'>;

type ClinicProps = {
  departments: DepartmentCardProps[];
  onClick?: (department: Department) => void;
  selectedId?: string;
  online: boolean;
};

type Props = {
  clinics: Clinic[];
  onClick?: (department: Department) => void;
  selectedId?: string;
  online: boolean;
};

type Clinic = {
  name: string;
  departments: DepartmentCardProps[];
};

const clinicSubheaderStyle = css`
  margin: ${gutter(4)} 0;
`;

export const ClinicDepartmentCardLayout = ({ clinics, onClick, selectedId, online }: Props) => (
  <div>
    {clinics.length > 0 ? (
      clinics.map((clinic, key) => (
        <div key={key} className={departmentCardStyle}>
          <div className={clinicSubheaderStyle}>
            <Caption type="subheader">{clinic.name}</Caption>
          </div>
          <DepartmentCardLayout
            departments={clinic.departments}
            onClick={onClick}
            selectedId={selectedId}
            online={online}
          />
        </div>
      ))
    ) : (
      <div>
        <EmptyCards>
          <Caption type="subheader">空き枠情報を読み込んでいます</Caption>
        </EmptyCards>
      </div>
    )}
  </div>
);

const departmentCardStyle = css`
  margin-bottom: ${gutter(2)};
`;

/**
 * 診療科の一覧を受け取り、二列のグリッド状に配置するコンポーネント。
 */
const DepartmentCardLayout = ({ departments, onClick, selectedId, online }: ClinicProps) => {
  const session = useContext(SessionStore.Context);
  return (
    <div className={grid}>
      {departments.map((cell, inx) => {
        const disabled =
          (!!online && !cell.department.online) ||
          (cell.department.type !== DepartmentType.Checkup && !!session.user?.userEntity?.accountRestricted);
        return (
          <DepartmentCard
            key={inx}
            nearest={!session.user?.userEntity?.accountRestricted ? cell.nearest : undefined}
            onClick={department => onClick?.(department)}
            department={cell.department}
            selected={cell.department.id === selectedId}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

const grid = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${gutter(4)};

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    grid-template-columns: 1fr;
    grid-gap: ${gutter(3)};
  }
`;
