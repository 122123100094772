import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * ステップがあるウィザード形式の画面を簡単に実装する機能
 * history APIでURLを遷移させられるようにする
 */
export const useSteps = <T extends string>(step: T | undefined, setStep: (s: T) => void) => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen(e => {
        const hash = e.hash.replace('#', '');
        if (hash && step !== hash) {
          setStep(hash as T);
        }
      }),
    [step],
  );

  useEffect(() => {
    if (!step) {
      return;
    }
    window.location.hash = step;
  }, [step]);
};
