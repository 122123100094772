import { css } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../../../../components/Caption';
import { Card } from '../../../../../../../../components/Card';
import { Breakpoint } from '../../../../../../../../constants/Style';
import { gutter } from '../../../../../../../../helpers/Style';

type Props = {
  options: Option[];
  value?: string;
  onChange?: (newValue: string) => void;
};
type Option = {
  label: string;
  value: string;
  description: string;
  price: number;
  discountPrice?: number;
};

export const CheckupPlanSelect = ({ options, value, onChange }: Props) => (
  <div className={layoutStyle}>
    {options.map((o, key) => (
      <CheckupPlanCard option={o} key={key} onClick={() => onChange?.(o.value)} selected={value === o.value} />
    ))}
  </div>
);

type CardProps = {
  option: Option;
  selected?: boolean;
  onClick?: () => void;
};

const CheckupPlanCard = ({ option, selected, onClick }: CardProps) => (
  <Card outlined={selected} shadowType="floating" onClick={onClick}>
    <div className={containerStyle}>
      <div className={gutterStyle}>
        <Caption type="subheader">{option.label}</Caption>
      </div>
      <Caption type="body" break>
        {option.description}
      </Caption>
      <CheckupPlanCardMoney price={option.price} discountPrice={option.discountPrice} />
    </div>
  </Card>
);

type MoneyProps = Pick<Option, 'price' | 'discountPrice'>;

const CheckupPlanCardMoney = ({ price, discountPrice }: MoneyProps) => (
  <div className={moneyStyle}>
    {discountPrice !== undefined ? (
      <div className={discountStyle}>
        <s>¥{price.toLocaleString()}</s>
        <span>¥{discountPrice.toLocaleString()}（税込み）</span>
      </div>
    ) : (
      <span>¥{price.toLocaleString()}（税込み）</span>
    )}
  </div>
);

const discountStyle = css`
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 4px;
`;

const containerStyle = css`
  display: flex;
  flex-direction: column;

  /* min-height: 160px; */
  height: 100%;
`;

const moneyStyle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  font-weight: bold;
`;

const gutterStyle = css`
  margin: ${gutter(3)} 0;
`;

const layoutStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${Breakpoint.ScreenSm}px) {
    grid-template-columns: 1fr 1fr;
  }

  row-gap: 8px;
  column-gap: 8px;
`;
