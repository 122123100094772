import { Location } from 'history';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';

type LeaveGuardState = {
  isGuard: boolean;
  visible: boolean;
  lastLocation: Location | null;
  confirmedNavigation: boolean;
  unblockedAction?: () => void;
};

const initState = {
  isGuard: true,
  visible: false,
  lastLocation: null,
  confirmedNavigation: false,
};

export const useLeaveGuard = () => {
  const history = useHistory();
  const [leaveGuard, setLeaveGuard] = useState<LeaveGuardState>(initState);

  // 遷移可能かどうか判定(trueだとブロックしない)
  const checkBlockedNavigation = (nextLocation: Location): boolean => {
    if (!leaveGuard.confirmedNavigation) {
      setLeaveGuard({ ...leaveGuard, visible: true, lastLocation: nextLocation });
      return false;
    }

    return true;
  };

  const handleReset = () => setLeaveGuard(initState);

  // 遷移可能にする
  const handleDefaultNavigation = () => {
    setLeaveGuard({ ...leaveGuard, visible: false, confirmedNavigation: true });
  };

  // ブロックしない遷移関数がある場合、こちらをラップする
  const forcePush = (pushAction: (...args: any) => void) => {
    setLeaveGuard({ ...initState, isGuard: false, unblockedAction: pushAction });
  };

  useEffect(() => {
    // 遷移ブロックがオフになったら遷移関数を発火
    if (!leaveGuard.isGuard && !!leaveGuard.unblockedAction) {
      leaveGuard.unblockedAction();
    }

    // 遷移可能になったら遷移
    if (leaveGuard.confirmedNavigation && leaveGuard.lastLocation) {
      history.push({
        pathname: leaveGuard.lastLocation.pathname,
        search: leaveGuard.lastLocation.search,
        hash: leaveGuard.lastLocation.hash,
      });
    }
  }, [leaveGuard, history]);

  const PromptWrapper = ({ children }: { children: ReactNode }) => (
    <>
      <Prompt when={leaveGuard.isGuard} message={checkBlockedNavigation} />
      {children}
    </>
  );

  const { visible } = leaveGuard;

  return { visible, forcePush, handleReset, handleDefaultNavigation, PromptWrapper };
};
