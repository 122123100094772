import { css } from 'emotion';
import React, { ReactNode, useState } from 'react';

import { gutter } from '../../../../../../helpers/Style';
import { CheckupResultGroupStore } from '../../../../Base/stores/CheckupResultGroupStore';
import { Accordion } from '../../../../components/Accordion';
import { RecommendItem } from '../RecommendItem';

type Props = {
  groups: CheckupResultGroupStore[];
};
export const RecommendItems = ({ groups }: Props) => (
  <div>
    {groups.map(group => (
      <GroupContainer groupName={group.group.friendlyName} key={group.group.id} show={group.subGroups.length > 0}>
        <div>
          {group.subGroups.map(
            (judge, index) =>
              (judge.subgroupResult.result === 'B' ||
                judge.subgroupResult.result === 'C' ||
                judge.subgroupResult.result === 'D') && (
                <div key={index} className={recommendItemContainerStyle}>
                  <RecommendItem judged={judge} />
                </div>
              ),
          )}
        </div>
      </GroupContainer>
    ))}
  </div>
);

type GroupProps = {
  groupName: string;
  children: ReactNode;
  show: boolean;
};

export const GroupContainer = ({ groupName, children, show }: GroupProps) => {
  const [open, setOpen] = useState(true);

  return (
    <div>
      {show && <Accordion groupName={groupName} open={open} setOpen={setOpen} />}
      {open && children}
    </div>
  );
};

const recommendItemContainerStyle = css`
  margin-bottom: ${gutter(5)};

  &:last-child {
    margin-bottom: ${gutter(15.5)};
  }
`;
