import { css } from 'emotion';
import React from 'react';

import { CheckupResultGroupStore } from '../../../Base/stores/CheckupResultGroupStore';
import { SectionHeader } from '../SectionHeader';
import { OtherItemGroup } from './OtherItemGroup';

type Props = {
  items?: CheckupResultGroupStore[];
};

export const OtherItems = ({ items }: Props) => (
  <div className={containerStyle}>
    <SectionHeader iconName="check-circle" headerTitle="その他の項目" />
    {items?.map(({ group: item }, index) => (
      <div key={item.id}>
        <OtherItemGroup groupName={item.friendlyName} subGroup={items[index].subGroups} />
      </div>
    ))}
  </div>
);

const containerStyle = css``;
