import { action, computed, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../../../gql/client';
import {
  CreateCardInput,
  CreateUserInput,
  CreateWaitingListItemMutation,
  CreateWaitingListItemMutationVariables,
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from '../../../../../../../gql/gql-types';
import { createWaitingListItem } from '../../../../../../../gql/operations/createWaitingListItem';
import { registerUser } from '../../../../../../../gql/operations/registerUser';
import { Card } from '../../../../../../domains/customer/Card';
import { CreditCardStore } from '../../../../store/CreditCardStore';
import { Name } from '../../../stores/ProfileStore';

export class GuestWaitingListStore {
  constructor() {
    makeAutoObservable(this, {
      name: observable,
      setName: action,
      valid: computed,
      fix: action,
      userId: observable,
      errors: computed,
      mail: observable,
      setMail: action,
      card: observable,
      setCard: action,
    });
  }

  public name?: Name = undefined;
  public mail?: string = undefined;
  public userId?: string = undefined;

  public get fixable() {
    return this.valid;
  }

  public clinic?: string = undefined;
  public department?: string = undefined;
  public treatmentKind?: string = undefined;

  public start: string = new Date().toISOString();
  public end: string = new Date().toISOString();

  public newComer = true;

  public setNewComer(newComer: boolean) {
    this.newComer = newComer;
  }

  public async fix() {
    if (!this.userId) {
      return;
    }

    if (this.newComer) {
      const user: CreateUserInput = {
        id: this.userId,
        // eslint-disable-next-line no-irregular-whitespace
        name: this.name ? `${this.name.sei}　${this.name.mei}` : '',
        mail: this.mail,
        sei: this.name?.sei,
        mei: this.name?.mei,
        confirmed: [],
        insuranceCards: [],
      };

      const card: CreateCardInput | undefined = this.card
        ? {
            id: '_',
            cardCustomerId: '_',
            fourDigit: this.card.fourDigit || '',
            brand: this.card.brand || '',
            payjpID: this.card.id || '',
            expAt: this.card.expAt || '',
          }
        : undefined;

      await GQL.query<RegisterUserMutationVariables, RegisterUserMutation>(registerUser, {
        input: {
          user,
          card,
        },
      });
    }

    await GQL.query<CreateWaitingListItemMutationVariables, CreateWaitingListItemMutation>(createWaitingListItem, {
      input: {
        userId: this.userId,
        clinic: this.clinic,
        department: this.department,
        treatmentKind: this.treatmentKind,
        start: this.start,
        end: this.end,
      },
    });
  }

  public setName(name: Name) {
    this.name = name;
  }

  public setMail(mail: string) {
    this.mail = mail;
  }

  public get errors() {
    let errors: { field: string; error: string }[] = [];
    if (!this.name) {
      return [{ field: 'sei', error: '氏名を入力してください。' }];
    }
    if (!/^([ァ-ン]|ー)+$/.test(this.name.sei)) {
      errors = [...errors, { field: 'sei', error: '氏名は全角カタカナで入力してください。' }];
    }
    if (!/^([ァ-ン]|ー)+$/.test(this.name.mei)) {
      errors = [...errors, { field: 'mei', error: '氏名は全角カタカナで入力してください。' }];
    }

    return errors;
  }

  public get valid() {
    return this.errors.length === 0;
  }

  public get id() {
    return `${this.clinic}#${this.start}#${this.department}#${this.treatmentKind || this.department}#${this.userId}`;
  }

  public card?: Card = undefined;

  public setCard(card: Card) {
    this.card = card;
  }

  public cardStatus: CreditCardStore = new CreditCardStore();
}
