import { action, makeAutoObservable } from 'mobx';
import { createContext } from 'react';

export class BrowserStateStore {
  public static Context = createContext<BrowserStateStore | null>(null);

  /**
   * オンライン診療を予約する導線を経由した場合に
   * 予約画面でデフォルトチェックを入れるかどうかをコントロールする
   */
  private static ONLINE_RESERVATION_KEY = 'clinicten.browserOnlineReservation';
  private static ONLINE_AGREEMENT_KEY = 'clinicten.browserOnlineAgreement';

  constructor() {
    makeAutoObservable(this, {
      setOnline: action,
      getOnline: action,
      agreeOnlineTreatment: action,
      isAgreedOnlineTreatment: action,
    });
  }

  public setOnline() {
    this.setTemporary(BrowserStateStore.ONLINE_RESERVATION_KEY, '1');
  }

  /**
   * keyに該当するvalueが存在しない or 指定した日時を過ぎている場合はfalse
   * @returns
   */
  public getOnline() {
    return !!this.getItem(sessionStorage, BrowserStateStore.ONLINE_RESERVATION_KEY);
  }

  public agreeOnlineTreatment() {
    this.setForever(BrowserStateStore.ONLINE_AGREEMENT_KEY, '1');
  }

  /**
   * すでにオンライン診療の注意喚起を読んでいるかどうか
   * @returns
   */
  public isAgreedOnlineTreatment() {
    const stored = this.getItem(localStorage, BrowserStateStore.ONLINE_AGREEMENT_KEY);
    return !!stored;
  }

  private setForever(key: string, item: string) {
    this.setInternal(localStorage, key, item);
  }

  private setTemporary(key: string, item: string) {
    this.setInternal(sessionStorage, key, item);
  }

  private setInternal(store: Storage, key: string, item: string) {
    store.setItem(key, item);
  }

  private getItem(store: Storage, key: string) {
    return store.getItem(key);
  }
}
