import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption } from '../../../components/Caption';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';

export const WaitingListItemDeleteCompletePage = observer(() => {
  const { push } = useHistory();
  const pushToReserve = () => {
    push({ pathname: Routes.Paths.ReservationNew, search: window.location.search });
  };

  const pushToRoot = () => {
    push(Routes.Paths.Root);
  };
  return (
    <Wrapper>
      <Caption type="header" align="center">
        キャンセル通知の取消が
        <br />
        完了しました
      </Caption>

      <ButtonGroup gutter={20}>
        <Button block onClick={pushToReserve}>
          他の空き枠で予約する
        </Button>
        <Button block ghost onClick={pushToRoot}>
          マイページトップへ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
