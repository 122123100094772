import { useCallback, useState } from 'react';

import { useMounted } from './useMounted';

export type TransactionStatus = Partial<{
  running: boolean;
  error: boolean;
}>;

/**
 * 非同期処理をラップするカスタムフック。
 * 実行中か否かのフラグとエラーが起きたか否かのフラグを返す。
 *
 * @param onAction 非同期処理する関数
 * @param onError エラー時に実行する関数
 *
 * @example
 * const fooStore = useContext(FooStore.Context);
 *
 * const [handler, status] = useTransaction(
 *   async () => {
 *     await fooStore.loadAsync();
 *   },
 *   e => console.error(e.message),
 * );
 */
export function useTransaction<T extends any[]>(
  onAction: (...args: T) => Promise<void>,
  onError?: (error: Error) => void,
): [(...args: T) => Promise<void>, TransactionStatus] {
  const [status, setStatus] = useState<TransactionStatus>({});

  const mounted = useMounted();

  const handler = useCallback(
    async (...args: T) => {
      try {
        setStatus({ running: true, error: false });

        await onAction(...args);

        if (mounted.current) {
          setStatus({ running: false, error: false });
        }
      } catch (e) {
        console.error('useTransaction', e);

        onError?.(e as Error);

        if (mounted.current) {
          setStatus({ running: false, error: true });
        }

        throw e;
      }
    },
    [mounted, onAction, onError],
  );

  return [handler, status];
}
