import gql from 'graphql-tag';

export default gql`
  mutation validateCard($input: CardConfirmInfoInput!) {
    validateCard(input: $input) {
      success
      message {
        code
        message
        status
        type
      }
    }
  }
`;
