import { css } from '@emotion/css';
import React from 'react';

import { GtmClasses } from '../../../constants/AnalyticsTags';
import { Button } from '../../Button';

type Props = {
  onClick: () => void;
};

export const GoogleCalendarButton = ({ onClick }: Props) => (
  <Button ghost block onClick={onClick} className={GtmClasses.gtm.addCalendar.google}>
    <span className={googleCalendarIconStyle}>
      <img src="/images/google-calendar.svg" alt="Googleカレンダーの画像" />
    </span>
    Googleカレンダーに追加
  </Button>
);

const googleCalendarIconStyle = css`
  margin-right: 8px;
`;
