import { css, cx } from 'emotion';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Color, Duration, Easing, ZIndex } from '../../constants/Style';
import { square, toRGBA } from '../../helpers/Style';

type Props = {
  onClick?: (state: boolean) => void;
  visible: boolean;
  children: ReactNode;
};

export const Modal = ({ onClick, visible, children }: Props) =>
  createPortal(
    <div onClick={() => onClick && onClick(false)} className={cx(baseStyle, visible && visibleStyle)}>
      <div onClick={e => e.stopPropagation()} className={contentStyle}>
        {visible ? children : null}
      </div>
    </div>,
    document.body,
  );

const baseStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.Modal};
  display: flex;
  align-items: center;
  overflow: auto;
  visibility: hidden;
  background: ${toRGBA(Color.GrayscaleDarker, 0.5)};
  opacity: 0;
  transition: visibility ${Duration.Leave} opacity ${Duration.Leave} ${Easing.Enter};
  ${square('100%')}
`;

const contentStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform ${Duration.Enter} ${Easing.Enter};
  transform: translate3d(0, -24px, 0);
`;

const visibleStyle = css`
  visibility: visible;
  opacity: 1;
  transition: opacity ${Duration.Enter} ${Easing.Enter};

  .${contentStyle} {
    transition: transform ${Duration.Enter} ${Easing.Enter};
    transform: translate3d(0, 0, 0);
  }
`;
