import React, { ComponentProps } from 'react';

import { BodyMap } from '../BodyMap';
import { ErrorLabel, FormLabel } from '../Form';
import { InterviewReactiveFormItem } from '.';

type Prop = ComponentProps<typeof InterviewReactiveFormItem> & {
  disabled?: boolean;
};

const MapArray = [
  { value: 0, name: 'RHR' },
  { value: 1, name: 'EGR' },
  { value: 2, name: 'LHR' },
  { value: 4, name: 'RLR' },
  { value: 5, name: 'UR' },
  { value: 6, name: 'LLR' },
  { value: 7, name: 'RIR' },
  { value: 8, name: 'HGR' },
  { value: 9, name: 'LIR' },
  { value: 10, name: 'Lower LIR' },
  { value: 11, name: 'Lower RIR' },
] as const;

const valToName: { [key: number]: string } = MapArray.reduce((res, { value, name }) => ({ ...res, [value]: name }), {});
const nameToVal: { [key: string]: number } = MapArray.reduce((res, { value, name }) => ({ ...res, [name]: value }), {});

export const InterviewStomachMap = ({ def, value, onChange, disabled, errors }: Prop) => (
  <>
    <FormLabel required={!!def.required}>{def.label}</FormLabel>
    <BodyMap
      value={(value[0]?.value && nameToVal[value[0]?.value]) || -1}
      onSelect={(val: number) =>
        onChange([
          { id: String(def.options?.[0]?.id || 0), label: def.options?.[0]?.label || '', value: valToName[val] },
        ])
      }
      disabled={disabled}
    />
    <ErrorLabel errors={errors} />
  </>
);
