import { css } from 'emotion';
import React from 'react';
import { Dot, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';

import { Color, FontSize } from '../../../../constants/Style';
import { Result } from '../../../../interfaces/Result';

type Props = {
  size: 'small' | 'medium';
  result?: any;
  chartData: {
    name: string;
    valueStr: number;
    result: string;
  }[];
};

const WIDTH = {
  small: 263,
  medium: 310,
};

const HEIGHT = {
  small: 191,
  medium: 192,
};

export const ResultAreaChart = ({ size, chartData }: Props) => (
  <div className={containerStyle}>
    <ScatterChart width={WIDTH['small']} height={HEIGHT[size]}>
      <XAxis
        dataKey="name"
        stroke={Color.GrayscaleCommon}
        tick={{ stroke: Color.GrayscaleCommon, fontSize: FontSize.Tiny, fontWeight: 300 }}
        tickSize={0}
        tickMargin={6.5}
        fontFamily="Helvetica"
      />
      <YAxis
        dataKey="valueStr"
        domain={['dataMin - 200', 'dataMax + 200']}
        stroke={Color.GrayscaleCommon}
        tick={{ stroke: Color.GrayscaleCommon, fontSize: FontSize.Tiny, fontWeight: 300 }}
        tickSize={0}
        tickMargin={11.6}
        fontFamily="Helvetica"
      />
      <ZAxis dataKey="result" name="結果" unit="判定" />
      <Tooltip />
      <Scatter data={chartData} fill={Color.GrayscaleNeutral} line shape={<ChartDotShape result="A" cx={0} cy={0} />} />
    </ScatterChart>
  </div>
);

const ChartDotShape = (item: { result: Result; cx: number; cy: number }) => {
  const SwitchColor = {
    A: Color.NotingResult,
    B: Color.AttentionResult,
    C: Color.ConsultationResult,
    D: Color.TreatmentResult,
  };
  return (
    <>
      <Dot fill={SwitchColor[item.result]} cx={item.cx} cy={item.cy} r={4} />;
      <Dot cx={item.cx} cy={item.cy} r={2} fill="white" />;
    </>
  );
};

const containerStyle = css`
  > div {
    margin: 0 auto;

    > svg {
      position: absolute;
      left: -20px;
      cursor: pointer;
    }
  }
`;
