import { addDays, format, isPast, isToday, subWeeks } from 'date-fns/esm';
import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { Color, FontSize, IconSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { IconButton } from '../IconButton';
import { Calendar } from '.';

type Props = {
  /**
   * 表示する週の年月データ。
   *
   * 過去日だと前週に進むボタンが非活性となる。
   */
  start: Date;
  end: Date;
} & Pick<ComponentProps<typeof Calendar>, 'onClickPrevWeek' | 'onClickNextWeek'>;

export const Navigation = ({ start, end, onClickPrevWeek, onClickNextWeek }: Props) => {
  const handleClickPrev = () => onClickPrevWeek(subWeeks(start, 1));

  const handleClickNext = () => onClickNextWeek(addDays(end, 1));

  return (
    <nav className={baseStyle}>
      <span>
        <IconButton name="angle-left" onClick={handleClickPrev} disabled={isPast(start) || isToday(start)} />
      </span>
      <span className={displayStyle}>{format(start, 'yyyy年MM月')}</span>
      <span>
        <IconButton name="angle-right" onClick={handleClickNext} />
      </span>
    </nav>
  );
};

const baseStyle = css`
  display: grid;
  grid-template-columns: ${IconSize.Large} auto ${IconSize.Large};
  align-items: center;
  width: 100%;
  padding: ${gutter(3)} ${gutter(4)};
  background: ${Color.GrayscaleWhite};
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;

const displayStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: 700;
  text-align: center;
`;
