import React, { ComponentProps, useEffect, useState } from 'react';

import { ErrorLabel, FormLabel } from '../Form';
import { TextArea } from '../TextArea';
import { InterviewReactiveFormItem } from '.';

type Prop = ComponentProps<typeof InterviewReactiveFormItem>;

export const InterviewTextArea = ({ def, value, onChange, errors, analyticsClassName }: Prop) => {
  const [state, setState] = useState(value[0]?.value);

  useEffect(() => {
    if (!value[0]?.value) {
      return;
    }
    setState(value[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value[0]?.value]);

  const onBlur = () => {
    onChange([{ id: String(def.options?.[0]?.id || 0), label: state, value: state }]);
  };

  return (
    <>
      <FormLabel required={!!def.required}>{def.label}</FormLabel>
      <TextArea
        color="white"
        value={state}
        onChange={v => setState(v)}
        onFocusout={onBlur}
        analyticsClassName={analyticsClassName}
      />
      <ErrorLabel errors={errors} />
    </>
  );
};
