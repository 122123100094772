import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Color, FontSize } from '../../constants/Style';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const Box = ({ children, onClick }: Props) => (
  <div onClick={onClick} className={style}>
    {children}
  </div>
);

const style = css`
  width: 100%;
  font-size: ${FontSize.Regular};
  color: ${Color.GrayscaleNeutral};
  border: 1px solid ${Color.GrayscaleLight};
`;
