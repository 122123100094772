import { Maybe } from 'gql/gql-types';
import { action, computed, makeAutoObservable, observable } from 'mobx';

export class AnnouncementStore {
  constructor() {
    makeAutoObservable(this, {
      department: observable,
      treatmentKind: observable,
      setDepartment: action,
      setTreatmentKind: action,
      announcement: computed,
      subAnnouncement: computed,
      requirement: computed,
      visitingInformation: computed,
    });
  }

  department?: Department = undefined;
  treatmentKind?: TreatmentKind = undefined;

  setDepartment(department?: Department) {
    this.department = department;
  }

  setTreatmentKind(treatmentKind?: TreatmentKind) {
    this.treatmentKind = treatmentKind;
  }

  get announcement(): string {
    return this.treatmentKind?.announcement || this.department?.announcement || '';
  }

  get requirement(): string {
    return this.treatmentKind?.requirement || this.department?.requirement || this.announcement;
  }

  public get visitingInformation(): string {
    // TODO: オンラインによる文言の出し分けだけなのでベタ書きで対応。条件が複数になる場合はTreatmentKindに項目を追加して管理する
    return this.treatmentKind?.online
      ? '診療時間の5分前になりましたら、LINEにお送りしたURLよりオンライン診療へお入りください。(医師の診察状況によっては入室後5～10分ほどお待ちいただく場合があります。)'
      : 'ご来院をお待ちしております。予約時間の５分程前には来院し、受付で保険証とクレジットカードをご提示ください。（当院は完全キャッシュレスとなります）';
  }

  get subAnnouncement(): string {
    return this.treatmentKind?.subAnnouncement || '';
  }
}

type Department = {
  announcement?: Maybe<string>;
  requirement?: Maybe<string>;
};

type TreatmentKind = {
  announcement?: Maybe<string>;
  subAnnouncement?: Maybe<string>;
  requirement?: Maybe<string>;
  online?: Maybe<boolean>;
};
