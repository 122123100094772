import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from '../../components/Card';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Wrapper } from '../../components/Wrapper';
import { Demo2Store } from '../../stores/Demo2Store';
import { CompleteSection } from './CompleteSection';
import { ConfirmSection } from './ConfirmSection';
import { InputSection } from './InputSection';

type State = 'input' | 'confirm' | 'complete';

// @TODO ページ開発が本格化したら、当コンポーネントを削除する。
export const Demo2Page = () => {
  const history = useHistory();

  const [state, setState] = useState<State>('input');

  const demo2Store = useMemo(() => new Demo2Store(), []);

  const handleClick = () => history.push('/');

  return (
    <>
      <DocumentTitle title="Demo 2" />
      <Wrapper>
        <h1>Demo 2 Page</h1>
        <button onClick={handleClick}>go to Demo 1 w/ click event</button>
        <hr />
        <p>MobX + React で 非同期処理（API 疎通など）をするデモ。</p>
        <Card>
          <Demo2Store.Context.Provider value={demo2Store}>
            {state === 'input' ? <InputSection onChangeState={setState} /> : null}
            {state === 'confirm' ? <ConfirmSection onChangeState={setState} /> : null}
            {state === 'complete' ? <CompleteSection onChangeState={setState} /> : null}
          </Demo2Store.Context.Provider>
        </Card>
      </Wrapper>
    </>
  );
};
