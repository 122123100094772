import gql from 'graphql-tag';

export const listCheckupVacancies = gql`
  query listCheckupVacancies($input: VacancyUnitCalculation!) {
    listCheckupVacancies(input: $input) {
      checkup {
        clinic
        date
        time
        available
        estimatedDuration
        doctor
        laneId
      }
      dental {
        clinic
        date
        time
        available
        estimatedDuration
        doctor
        laneId
      }
    }
  }
`;
