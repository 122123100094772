import { format } from 'date-fns/esm';
import { css, cx } from 'emotion';
import React, { ChangeEvent, RefCallback, useMemo } from 'react';

import { BorderRadius, Color, Duration, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = Partial<{
  type: 'text' | 'email' | 'password' | 'search' | 'tel' | 'number' | 'date' | 'time';
  value: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  onChange: (value: string) => void;
  color: 'white' | 'grey';
  refCallback: RefCallback<HTMLInputElement>;
  formatStr?: string;
  onFocusout?: () => void;
  error: boolean;
  analyticsClassName: string;
}>;

export const TextField = ({
  type = 'text',
  value,
  placeholder,
  disabled,
  required,
  onChange,
  color = 'grey',
  refCallback,
  formatStr,
  onFocusout,
  error = false,
  analyticsClassName,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    !disabled && (formatStr ? onChange?.(format(new Date(e.target.value), formatStr)) : onChange?.(e.target.value));
  const style = useMemo(
    () =>
      cx(
        baseStyle,
        css`
          background-color: ${color === 'grey' ? Color.TextureInput : Color.GrayscaleWhite};
        `,
        error &&
          css`
            box-shadow: 0 0 0 2px ${Color.FunctionalRedNeutral};
          `,
      ),
    [color, error],
  );

  return (
    <input
      ref={refCallback}
      className={`${style} ${analyticsClassName}`}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onChange={handleChange}
      onBlur={onFocusout}
    />
  );
};

const baseStyle = css`
  display: block;
  width: 100%;
  padding: ${gutter(4)};
  font-size: ${FontSize.Medium};
  line-height: 1;
  background-color: ${Color.TextureInput};
  border: none;
  border-radius: ${BorderRadius.Small};
  outline: none;
  box-shadow: none;
  transition: box-shadow ${Duration.Fade};
  appearance: none;

  &::placeholder {
    color: ${Color.GrayscaleNeutral};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Color.GrayscaleDarker};
  }

  &:disabled {
    color: ${Color.GrayscaleNeutral};
    cursor: not-allowed;
  }
`;
