import { Auth } from '@aws-amplify/auth';
import { urlSafeEncode } from '@aws-amplify/core';
import { action, computed, makeAutoObservable, observable } from 'mobx';

export class AccountLegacyStore {
  public static KEY = 'clinic-ten-confirm';

  constructor() {
    makeAutoObservable(this, {
      email: observable,
      password: observable,
      setEmail: action,
      setPassword: action,
      signUp: action,
      valid: computed,
    });
  }

  public email?: string = undefined;
  public password?: string = undefined;

  public setEmail(email: string) {
    this.email = email;
  }

  public setPassword(password: string) {
    this.password = password;
  }

  public async signUp(): Promise<boolean> {
    if (!this.valid || !this.email || !this.password) {
      return false;
    }
    try {
      const username = urlSafeEncode(this.email);
      await Auth.signUp({
        attributes: {
          email: this.email,
        },
        username,
        password: this.password,
      });
      window.sessionStorage.setItem(
        AccountLegacyStore.KEY,
        JSON.stringify({ email: this.email, username, password: this.password }),
      );
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async signIn(): Promise<boolean> {
    if (!this.valid || !this.email || !this.password) {
      return false;
    }
    try {
      const username = urlSafeEncode(this.email);
      await Auth.signIn({
        username,
        password: this.password,
      });
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public get valid() {
    return this.email && this.password;
  }
}
