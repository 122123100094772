import React, { useMemo, useState } from 'react';

import { useSteps } from '../../../../hooks/useSteps';
import { ValueOf } from '../../../../interface';
import { CheckupReservationBasicInfoInputPage } from './CheckupReservationBasicInfo';
import { CheckupReservationCodeInputPage } from './CheckupReservationCodeInput';
import { CheckupReservationManifestPage } from './CheckupReservationManifest';
import { CheckupReservationPreInterview } from './CheckupReservationPreInterview';
import { CheckupReservationStartPage } from './CheckupReservationStart';
import { CheckupReservationVacancySelectPage } from './CheckupReservationVacancySelect';
import { CheckupVacancyStore } from './CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { CheckupReservationStore } from './stores/CheckupReservationStore';

const STEPS = {
  CODE_INPUT: 'codeInput',
  MANIFEST: 'manifest',
  PRE_INTERVIEW: 'preInterview',
  BASIC_INFO: 'basicInfo',
  VACANCY_SELECT: 'vacancySelect',
  START: 'start',
} as const;

type Step = ValueOf<typeof STEPS>;

export const CheckupReservationNewPage = () => {
  const [step, setStep] = useState<Step>((window.location.hash || '').slice(1) as Step);
  const store = useMemo(() => new CheckupReservationStore(), []);
  const vacancyStore = useMemo(() => new CheckupVacancyStore(), []);
  const id = new URLSearchParams(window.location.search).get('checkupOrganizationMemberEventId');
  useSteps(step, setStep);

  const main = (step: Step | undefined) => {
    // reloadした場合はidがあるのにstoreに情報が入っていないため、loadする
    if (!store.hasCheckupInfo() && id && step !== STEPS.START) {
      setStep(STEPS.START);
      return <></>;
    }
    switch (step) {
      case 'codeInput':
        return <CheckupReservationCodeInputPage onNext={() => setStep(STEPS.MANIFEST)} />;
      case 'start':
      default:
        return <CheckupReservationStartPage onNext={() => setStep(STEPS.MANIFEST)} />;
      case 'manifest':
        return <CheckupReservationManifestPage onNext={() => setStep(STEPS.PRE_INTERVIEW)} />;
      case 'preInterview':
        return (
          <CheckupReservationPreInterview
            onNext={() => setStep(STEPS.BASIC_INFO)}
            onBack={() => setStep(STEPS.MANIFEST)}
          />
        );
      case 'basicInfo':
        return (
          <CheckupReservationBasicInfoInputPage
            onNext={() => setStep(STEPS.VACANCY_SELECT)}
            onBack={() => setStep(STEPS.PRE_INTERVIEW)}
          />
        );
      case 'vacancySelect':
        return <CheckupReservationVacancySelectPage onBack={() => setStep(STEPS.BASIC_INFO)} />;
    }
  };

  return (
    <CheckupReservationStore.Context.Provider value={store}>
      <CheckupVacancyStore.Context.Provider value={vacancyStore}>{main(step)}</CheckupVacancyStore.Context.Provider>
    </CheckupReservationStore.Context.Provider>
  );
};
