import { addDays, parse } from 'date-fns/esm';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { Button } from '../../../../../components/Button';
import { Calendar } from '../../../../../components/Calendar';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { ErrorDialogContext } from '../../../../../components/ErrorDialog';
import { Modal } from '../../../../../components/Modal';
import { Routes } from '../../../../../constants/Routes';
import { Dimension } from '../../../../../constants/Style';
import { useContext } from '../../../../../hooks/useContext';
import { useMedia } from '../../../../../hooks/useMedia';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { ConditionalWrapper } from '../../../../Reservations/ReservationNew/VacancySelect';
import { CheckupReservationConfirmDialog } from '../../CheckupReservationsNew/CheckupReservationVacancySelect/component/CheckupReservationConfirmDialog';
import { CheckupVacancyStore } from '../../CheckupReservationsNew/CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { CheckupReservationStore } from '../../CheckupReservationsNew/stores/CheckupReservationStore';

type Props = {
  onBack?: () => void;
};

export const CheckupReservationEditVacancySelectPage = observer(({ onBack }: Props) => {
  const wrapped = useMedia([{ query: `(min-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);
  const store = useContext(CheckupReservationStore.Context);
  const vacancyStore = useContext(CheckupVacancyStore.Context);
  const { setError } = useContext(ErrorDialogContext);
  const [week, setWeek] = useState(new Date());
  const { push } = useReservationId();
  const [load, loadStatus] = useTransaction(
    async () =>
      store.department &&
      vacancyStore.fetchVacancies(
        week,
        store.department,
        store.options.map(o => o.id),
        store.treatmentKind,
      ),
  );
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    load();
    store.department &&
      vacancyStore.prefetchNext(
        week,
        store.department,
        store.options.map(o => o.id),
        store.treatmentKind,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week]);

  const onSelect = ({ date }: { date: Date }) => {
    const selected = vacancyStore.getSelected(date);
    if (!selected) {
      return;
    }
    store.setDetails([
      {
        date,
        department: store.department,
        duration: selected.checkup.estimatedDuration,
        laneId: selected.checkup.laneId,
      } as any,

      ...(selected.dental
        ? [
            {
              date: parse(`${selected.dental.date} ${selected.dental.time}`, 'yyyy-MM-dd HH:mm', new Date()),
              duration: selected.dental.estimatedDuration || 0,
              laneId: selected.dental.laneId || '',
            } as any,
          ]
        : []),
    ]);
    setConfirm(true);
  };

  const [commit, commitStatus] = useTransaction(
    async () => {
      const id = await store.updateTreatment();
      push(Routes.Paths.ReservationSuccess, id);
    },
    e => {
      const gqlError: { errors?: { message: string }[] } = e as any;
      setError({
        header: '予約に失敗しました',
        message: `${
          gqlError.errors ? gqlError.errors[0].message : ''
        }お手数ですが、再度はじめから予約をお願い致します。`,
        onOk: () => {
          setError();
          window.location.reload();
        },
      });
    },
  );

  return (
    <>
      <DocumentTitle title="健診予約の変更" />
      <ConditionalWrapper
        conditional={
          <>
            {vacancyStore.vacancies ? (
              <Calendar
                onSelect={onSelect}
                items={vacancyStore.vacancies}
                now={new Date()}
                onClickNextWeek={() => setWeek(addDays(week, 7))}
                onClickPrevWeek={() => setWeek(addDays(week, -7))}
                alertThreshold={0}
              />
            ) : (
              <></>
            )}
          </>
        }
        wrapped={wrapped}
      >
        <Modal visible={!!loadStatus.running}>
          <Card>予約状況を確認中...</Card>
        </Modal>
        <Caption type="header" underline align="center">
          クリニックのご予約
        </Caption>
        <Button ghost block onClick={onBack}>
          オプション選択に戻る
        </Button>
      </ConditionalWrapper>
      <CheckupReservationConfirmDialog
        handleStateChange={setConfirm}
        open={confirm}
        onConfirm={commit}
        disabled={!!commitStatus.running}
      />
    </>
  );
});
