import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../gql/client';
import {
  GetInterviewDownloadableUrlQuery,
  GetInterviewDownloadableUrlQueryVariables,
  GetInterviewPresignedPostQuery,
  GetInterviewPresignedPostQueryVariables,
} from '../../../../../gql/gql-types';
import { getInterviewDownloadableUrl } from '../../../../../gql/operations/getInterviewDownloadableUrl';
import { getInterviewPresignedPost } from '../../../../../gql/operations/getInterviewPreSignedPost';

export class InterviewUploadStore {
  constructor() {
    makeAutoObservable(this, {
      files: observable,
      addKey: action,
      addUrl: action,
      fetch: action,
      interviewId: observable,
      keys: observable,
      questionId: observable,
      setFiles: action,
      setInterviewId: action,
      setKeys: action,
      setQuestionId: action,
      upload: action,
      urls: observable,
      removeKey: action,
    });
  }

  public files: File[] = [];

  public setFiles(files: FileList | null) {
    if (!files) {
      this.files = [];
      return;
    }
    this.files = new Array(files.length)
      .fill(undefined)
      .map((_, i) => files.item(i))
      .filter((x): x is File => Boolean(x));
  }

  public setKeys(keys: string[]) {
    this.keys = keys;
  }

  public addKey(key: string) {
    this.keys = [...this.keys, key];
  }

  public addUrl(url: Url) {
    this.urls = [...this.urls, url];
  }

  public keys: string[] = [];
  public urls: Url[] = [];
  public interviewId?: string = undefined;
  public questionId?: string = undefined;

  public setInterviewId(interviewId: string) {
    this.interviewId = interviewId;
  }

  public setQuestionId(questionId: string) {
    this.questionId = questionId;
  }

  public async upload() {
    if (this.interviewId === undefined || this.questionId === undefined) {
      return;
    }
    const newKeys = await Promise.all(
      this.files.map(file =>
        GQL.query<GetInterviewPresignedPostQueryVariables, GetInterviewPresignedPostQuery>(getInterviewPresignedPost, {
          input: {
            fileName: file.name,
            interviewId: this.interviewId as string,
            questionId: this.questionId as string,
          },
        }).then(async res => {
          const signedPost = res.getInterviewPresignedPost;
          if (!signedPost) {
            return null;
          }
          const d = new FormData();
          signedPost.fields.forEach(f => d.append(f.key, f.value));
          d.append('file', file, file.name);

          await fetch(signedPost.url, {
            headers: {
              accept: 'multipart/form-data',
            },
            mode: 'no-cors',
            body: d,
            method: 'POST',
          });

          return d.get('key') as string;
        }),
      ),
    ).then(r => r.filter((x): x is string => !!x));
    this.keys = [...this.keys, ...newKeys];
    this.setFiles(null);
    await this.fetch();
  }

  public async fetch() {
    await Promise.all(
      this.keys
        .filter(f => f && !this.urls.some(u => u.key === f))
        .map(key =>
          GQL.query<GetInterviewDownloadableUrlQueryVariables, GetInterviewDownloadableUrlQuery>(
            getInterviewDownloadableUrl,
            {
              input: {
                key,
              },
            },
          ).then(res => {
            const url = res.getInterviewDownloadableUrl?.url;
            if (!url) {
              return;
            }
            this.addUrl({ url, key, filename: key.split('/').slice(-1)[0] });
          }),
        ),
    );
  }

  removeKey(key: string) {
    this.urls = this.urls.slice(0).filter(x => x.key !== key);
    this.keys = this.keys.slice(0).filter(x => x !== key);
  }
}

type Url = {
  key: string;
  url: string;
  filename: string;
};
