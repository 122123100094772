import React, { ComponentProps } from 'react';
import { Link as OriginalLink, useLocation } from 'react-router-dom';

type Props = ComponentProps<typeof OriginalLink>;

/**
 * <Link/> をRouterの外でも使えるようにしたコンポーネント。
 * fallbackとして<a>を利用する。
 */
export const Link = (p: Props) => {
  try {
    useLocation();
    return <OriginalLink {...p}>{p.children}</OriginalLink>;
  } catch (e) {
    return <a href="/">{p.children}</a>;
  }
};
