import React, { ComponentProps } from 'react';

import { Card } from '../../../../../../../../components/Card';
import { CheckupOptionCheckbox } from '../../../../IndividualCheckupReservationsNew/IndividualCheckupPlanSelect/components/GroupedCheckupOptionSelect';

type Props = ComponentProps<typeof CheckupOptionCheckbox>;

export const CheckupOptionSuggested = (props: Props) => (
  <Card shadowType="floating">
    <CheckupOptionCheckbox {...props} />
  </Card>
);
