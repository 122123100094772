import gql from 'graphql-tag';

export const shouldConfirm = gql`
  query shouldConfirm($input: ConfirmQueryInput!) {
    shouldConfirm(input: $input) {
      confirmRequired
      confirmReservationFeeRequired
    }
  }
`;
