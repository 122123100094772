import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export default gql`
  mutation startInterview($input: StartInterviewInput) {
    startInterview(input: $input) {
      ...InterviewType
    }
  }
  ${InterviewType}
`;
