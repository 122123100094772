import gql from 'graphql-tag';

import { SignedPostType } from '../fragments/signedPost';

export const getInterviewPresignedPost = gql`
  query getInterviewPresignedPost($input: InterviewPresignedPostQueryInput!) {
    getInterviewPresignedPost(input: $input) {
      ...SignedPostType
    }
  }
  ${SignedPostType}
`;
