import { css } from 'emotion';
import React, { RefCallback, useCallback, useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { ErrorMessage } from '../../../../../../components/ErrorMessage';
import { FormItem, FormLayout, FormSubLabel } from '../../../../../../components/Form';
import { Option, Select } from '../../../../../../components/Select';
import { TextField } from '../../../../../../components/TextField';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';
import { Address } from '../../../../../../domains/user/Address';
import { gutter } from '../../../../../../helpers/Style';
import { useZipCode } from '../../../../../../hooks/useZipCode';
import { AccountDetailStore } from '../../../stores/AccountDetailStore';
import { FormDirtyItem } from '../../register';

type Props = {
  value: Address;
  onChange?: (v: Address) => void;
  onFocusout?: (target: string) => void;
  store: AccountDetailStore;
  dirty: FormDirtyItem;
};

const Prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const AddressInput = ({ value, onChange, onFocusout, store, dirty }: Props) => {
  const getZipCode = useZipCode();
  const [line1, setLine1] = useState<HTMLInputElement>();
  const refLine1: RefCallback<HTMLInputElement> = useCallback(node => {
    node && setLine1(node);
  }, []);
  return (
    <FormLayout>
      <FormItem>
        <FormSubLabel>郵便番号</FormSubLabel>
        <div className={zipStyle}>
          <div>
            <TextField
              value={value.zipCode}
              onChange={v => onChange?.({ ...value, zipCode: v })}
              onFocusout={() => onFocusout?.('zipCode')}
              error={dirty.zipCode && store.edit?.errors.some(e => e.field === 'zipCode')}
              color="white"
              analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.postCode}
            />
            {dirty.zipCode &&
              store.edit?.errors
                .filter(e => e.field === 'zipCode')
                .map(e => <ErrorMessage key={e.error}>{e.error}</ErrorMessage>)}
          </div>
          <div>
            <Button
              onClick={() => {
                getZipCode(value.zipCode).then(res => {
                  onChange?.({
                    zipCode: res.zipCode,
                    pref: res.pref,
                    addressLine1: res.addressLine1,
                    addressLine2: value.addressLine2,
                  });

                  line1 && line1.focus();
                });
              }}
            >
              郵便番号から住所を取得
            </Button>
          </div>
        </div>
      </FormItem>
      <FormItem>
        <FormSubLabel>都道府県</FormSubLabel>
        <Select
          color="white"
          value={value.pref || '東京都'}
          onChange={v => onChange?.({ ...value, pref: v })}
          analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.prefecture}
        >
          {Prefectures.map(pref => (
            <Option value={pref} key={pref}>
              {pref}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem>
        <FormSubLabel>市区町村・番地</FormSubLabel>
        <TextField
          color="white"
          value={value.addressLine1}
          onChange={v => onChange?.({ ...value, addressLine1: v })}
          refCallback={refLine1}
          onFocusout={() => onFocusout?.('addressLine1')}
          error={dirty.addressLine1 && store.edit?.errors.some(e => e.field === 'addressLine1')}
          analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.municipalities}
        />
        {dirty.addressLine1 &&
          store.edit?.errors
            .filter(e => e.field === 'addressLine1')
            .map(e => <ErrorMessage key={e.error}>{e.error}</ErrorMessage>)}
      </FormItem>
      <FormItem>
        <FormSubLabel>建物名・部屋番号</FormSubLabel>
        <TextField
          color="white"
          // refCallback={refLine2}
          value={value.addressLine2}
          onChange={v => onChange?.({ ...value, addressLine2: v })}
          onFocusout={() => onFocusout?.('addressLine2')}
          error={dirty.addressLine2 && store.edit?.errors.some(e => e.field === 'addressLine2')}
          analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.building}
        />
        {dirty.addressLine2 &&
          store.edit?.errors
            .filter(e => e.field === 'addressLine2')
            .map(e => <ErrorMessage key={e.error}>{e.error}</ErrorMessage>)}
      </FormItem>
    </FormLayout>
  );
};

const zipStyle = css`
  display: grid;
  grid-template-columns: 96px 1fr;
  column-gap: ${gutter(4)};
`;
