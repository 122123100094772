import { action, makeAutoObservable, observable } from 'mobx';

type Faq = {
  id: string;
  title: string;
  body: string;
  updatedAt: string;
};

export class FaqStore {
  constructor() {
    makeAutoObservable(this, {
      detail: observable,
      list: observable,
      fetch: action,
    });
  }

  public detail?: Faq = undefined;
  public list: Faq[] = [];

  public async fetch(id?: string) {
    if (id) {
      this.detail =
        this.list.find(l => l.id === id) ||
        (await (
          await fetch(`https://clinicten.microcms.io/api/v1/questions/${id}`, {
            headers: new Headers({
              'X-API-KEY': '5c25d338-65d0-4cce-a044-892730fc24ab',
            }),
          })
        ).json());
      return;
    }

    this.list = (
      await (
        await fetch(`https://clinicten.microcms.io/api/v1/questions`, {
          headers: new Headers({
            'X-API-KEY': '5c25d338-65d0-4cce-a044-892730fc24ab',
          }),
        })
      ).json()
    ).contents;
  }
}
