import gql from 'graphql-tag';

export const getCheckupTreatmentTemporally = gql`
  query getCheckupTreatmentTemporally($id: ID!) {
    getTemporallyTreatment(id: $id) {
      date
      time
      checkupAppointment {
        checkupOrganizationMemberEvent {
          id
        }
      }
    }
  }
`;
