import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { DateTime } from '../../../components/DateTime';
import { FormItem, FormLabel, FormLayout } from '../../../components/Form';
import { useToast } from '../../../components/Toast';
import { Upload } from '../../../components/Upload';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { PrescriptionUploadStore } from './stores/PrescriptionUploadStore';

export const PrescriptionsUploadPage = observer(() => {
  const store = useMemo(() => new PrescriptionUploadStore(), []);
  const session = useContext(SessionStore.Context);
  const { addToast } = useToast();
  const { push } = useHistory();

  useEffect(() => {
    store.setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [post, postStatus] = useTransaction(async () => {
    if (!session.user) {
      return;
    }
    await store.post(session.user.userId);
    addToast({ message: '処方箋をアップロードしました。' });
    push(Routes.Paths.PrescriptionUploadComplete);
  });

  return (
    <Wrapper>
      <Caption type="header" align="center" underline>
        処方箋提出
      </Caption>
      <Card>
        <CaptionGroup>
          <Caption type="div">準備が完了し次第、LINEでお呼び出しいたします。</Caption>
        </CaptionGroup>
        <FormLayout>
          <FormItem>
            <FormLabel>処方箋ファイル</FormLabel>
            <Upload onChange={fl => fl && store.setFiles(fl)} />
          </FormItem>
          <FormItem>
            <FormLabel>希望受取日時</FormLabel>
            <DateTime value={store.date} onChange={v => store.setDate(v)} />
          </FormItem>
        </FormLayout>
        <ButtonGroup gutter={10}>
          {/* <Caption type="div">
            <a style={{ textDecoration: 'underline' }}>PHARMACY KOUの利用規約を読む（別タブが開きます）</a>
          </Caption> */}
          <Button disabled={!store.valid || postStatus.running} block onClick={post}>
            {/* 利用規約に同意して処方箋をアップロード */}
            処方箋をアップロード
          </Button>
        </ButtonGroup>
      </Card>
    </Wrapper>
  );
});
