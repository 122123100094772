import { css, cx } from 'emotion';
import React, { ComponentProps, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Card } from '../../../../components/Card';
import { Icon } from '../../../../components/Icon';
import { SUMMARY } from '../../../../constants/CheckupResult';
import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';
import { Result } from '../../../../interfaces/Result';
import { ResultAreaChart } from '../../../../pages/CheckupResult/components/ResultAreaChart';
import { ResultRound } from '../../../../pages/CheckupResult/components/ResultRound';
import { resultWordStyle } from '../../../../pages/CheckupResult/utils/resultColor';
import { GroupInfoModal } from '../../Base/components/GroupInfoModal';
import { ResultBarChart } from '../../components/ResultBarChart';
import { UseToOtherSubGroup } from '../../Summary/hooks/useToOtherSubGroup';

type ChartDefs = ComponentProps<typeof ResultBarChart>['criteria'];

type Props = {
  width?: number;
  height?: string;
  valueStr: string;
  result: Result;
  description?: string;
  size: 'small' | 'medium';
  item: {
    displayName: string;
    description: string;
  };
  chartData: {
    name: string;
    valueStr: number;
    result: string;
  }[];
  criteria: ChartDefs;
  subGroupId: string;
};

export const CriteriaCard = ({
  width,
  height,
  size,
  result,
  description,
  valueStr,
  item,
  chartData,
  criteria,
  subGroupId,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const params = useParams<{ treatmentId: string }>();
  const location = useLocation();

  const toOtherSubGroup = (subGroupId: string) => {
    UseToOtherSubGroup(subGroupId, history, params, location);
  };
  return (
    <>
      <div className={cardWrapperStyle} onClick={() => toOtherSubGroup(subGroupId)}>
        <Card shadowType="floating" width={width} height={height}>
          <div className={headerContainerStyle}>
            <div className={containerStyle}>
              <p className={subGroupNameStyle}>{item.displayName}</p>
              {description && (
                <div onClick={e => e.stopPropagation()}>
                  <div className={questionIconContainerStyle} onClick={() => setModalOpen(true)}>
                    <Icon name="question-circle" />
                  </div>
                </div>
              )}
            </div>
            <div className={containerStyle}>
              <div className={containerInnerStyle}>
                <p className={cx(resultWordStyle[result], marginRightStyle)}>{SUMMARY[result]}</p>
                <ResultRound small result={result} />
              </div>
              <p className={cx(resultWordStyle[result], valueStrStyle)}>{valueStr}</p>
            </div>
          </div>
          <div>
            <ResultBarChart value={parseInt(valueStr, 10)} criteria={criteria} />
          </div>
          {chartData.length > 1 && (
            <>
              <div className={borderBottomStyle} />
              <ResultAreaChart size={size} result={result} chartData={chartData} />
            </>
          )}
        </Card>
      </div>
      <GroupInfoModal
        name={item.displayName}
        description={item.description}
        modalOpen={modalOpen}
        onClick={setModalOpen}
      />
    </>
  );
};

const cardWrapperStyle = css`
  margin-bottom: ${gutter(5)};
  cursor: pointer;
`;

const headerContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FontSize.Large};
`;

const containerStyle = css`
  display: flex;
  align-items: center;

  > svg {
    margin-left: ${gutter(1.2)};
    ${square(22)}

    fill: ${Color.GrayscaleLight}
  }
`;

const containerInnerStyle = css`
  display: flex;
  margin-right: ${gutter(4)};
  font-size: ${FontSize.SmallRegular};
  font-weight: bold;
`;

const marginRightStyle = css`
  margin-right: ${gutter(1)};
`;

const subGroupNameStyle = css`
  font-weight: bold;
`;

const questionIconContainerStyle = css`
  display: flex;

  > svg {
    margin-left: ${gutter(1.7)};
    ${square(24)}

    fill: ${Color.GrayscaleLight}
  }
`;

const valueStrStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const borderBottomStyle = css`
  padding: ${gutter(4)} 0;
  margin-bottom: 16px;
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;
