import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useContext } from '../../../hooks/useContext';
import { SessionStore } from '../../../stores/SessionStore';

export const StartAuthRedirection = () => {
  const session = useContext(SessionStore.Context);
  const { push } = useHistory();
  useEffect(() => {
    const customState = new URL(window.location.href).searchParams.get('customState') || '/';
    if (session.authenticated) {
      push(customState);
      return;
    }
    Auth.federatedSignIn({ customProvider: 'LINE', customState: customState || '/' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
