import localforage from 'localforage';
import { action, makeAutoObservable, observable } from 'mobx';
import Mustache from 'mustache';
import { createContext } from 'react';

import { GQL } from '../../gql/client';
import {
  Comparator,
  ExtendedDictionaryConditionTypeFragment,
  ExtendedDictionaryOverrideTypeFragment,
  ExtendedDictionaryTypeFragment,
  ListExtendedDictionariesQuery,
  ListExtendedDictionariesQueryVariables,
} from '../../gql/gql-types';
import { listExtendedDictionaries } from '../../gql/operations/listExtendedDictionaries';

const render = Mustache.render;

export class ExtendedDictionaryStore {
  static Context = createContext<ExtendedDictionaryStore | null>(null);

  constructor() {
    makeAutoObservable(this, {
      dictionaries: observable,
      fetch: action,
      setDictionary: action,
      loadFromFetch: action,
    });
    localforage.config({
      driver: localforage.supports(localforage.INDEXEDDB) ? localforage.INDEXEDDB : localforage.LOCALSTORAGE,
      storeName: 'reserve.clinicten.jp',
    });
  }

  public dictionaries: Map<string, ExtendedDictionary> = new Map();

  public async loadFromFetch() {
    const res: ListExtendedDictionariesQuery | null = await localforage.getItem('ExtendedDictionary');
    if (!res) {
      return;
    }
    this.setDictionary(res);
  }

  public async setCache(cache: ListExtendedDictionariesQuery) {
    await localforage.setItem('ExtendedDictionary', cache);
  }

  public setDictionary(res: ListExtendedDictionariesQuery) {
    this.dictionaries = (res.listExtendedDictionarys?.items || []).reduce((prev, elem) => {
      if (!elem) {
        return prev;
      }
      prev.set(elem.key, new ExtendedDictionary(elem));
      return prev;
    }, new Map<string, ExtendedDictionary>());
  }

  public async fetch() {
    // SWR
    await this.loadFromFetch();
    const res = await GQL.queryAsGuest<ListExtendedDictionariesQueryVariables, ListExtendedDictionariesQuery>(
      listExtendedDictionaries,
      {},
    );

    this.setDictionary(res);
    await this.setCache(res);
  }

  public render(key: string, input: any) {
    return this.dictionaries.get(key)?.render(input) || '';
  }
}

class ExtendedDictionary {
  constructor(origin: ExtendedDictionaryTypeFragment) {
    this.overrides = origin.override.map(o => new ExtendedDictionaryOverride(o));
    this.template = origin.template;
  }

  private overrides: ExtendedDictionaryOverride[];
  private template: string;

  public render(input: any) {
    const found = this.overrides.find(o => o.test(input));
    if (found) {
      return found.render(input);
    }
    return render(this.template, input);
  }
}

class ExtendedDictionaryOverride {
  constructor(origin: ExtendedDictionaryOverrideTypeFragment) {
    this.conditions = origin.conditions.map(c => new ExtendedDictionaryCondition(c));
    this.template = origin.template;
  }

  private conditions: ExtendedDictionaryCondition[];
  private template: string;

  public test(input: any) {
    return this.conditions.every(c => c.test(input));
  }

  public render(input: any) {
    return render(this.template, input);
  }
}

class ExtendedDictionaryCondition {
  constructor(origin: ExtendedDictionaryConditionTypeFragment) {
    this.left = origin.left;
    this.right = origin.right;
    this.comparator = origin.comparator;
  }

  public left: string;
  public right: string;
  public comparator: Comparator;

  public test(input: any) {
    const left = this.tryGet(input);
    switch (this.comparator) {
      case Comparator.Eq:
      default:
        if (left === true) {
          return this.right === 'true';
        }
        if (left === false) {
          return this.right === 'false';
        }
        // eslint-disable-next-line eqeqeq
        return left == this.right;
      case Comparator.Ne:
        if (left === true) {
          return this.right === 'false';
        }
        if (left === false) {
          return this.right === 'true';
        }
        // eslint-disable-next-line eqeqeq
        return left != this.right;
      case Comparator.Lt:
        return left < this.right;
      case Comparator.Le:
        return left <= this.right;
      case Comparator.Gt:
        return left > this.right;
      case Comparator.Ge:
        return left >= this.right;
    }
  }

  public tryGet(input: any) {
    const accessors = this.left.split('.');
    return accessors.reduce((prev, elem) => {
      if (prev && prev[elem]) {
        return prev[elem];
      }
      return null;
    }, input);
  }
}
