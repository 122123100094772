import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export default gql`
  query getInterview($id: ID!) {
    getInterview(id: $id) {
      ...InterviewType
    }
  }
  ${InterviewType}
`;
