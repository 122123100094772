import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { gutter } from '../../../../../helpers/Style';
import { EmptyCards } from '../../EmptyCards';
import { TreatmentCard } from '../TreatmentCard';

type Props = { treatments: ComponentProps<typeof TreatmentCard>[] };
export const TreatmentCardLayout = ({ treatments }: Props) => (
  <>
    {treatments.length > 0 ? (
      <div className={horizontalScrollStyle}>
        {treatments.map((treatment, i) => (
          <div key={i} className={containerStyle}>
            <TreatmentCard
              key={i}
              width={fixedWidth}
              clinic={treatment.clinic}
              date={treatment.date}
              department={treatment.department}
              id={treatment.id}
              payment={treatment.payment}
              soap={treatment.soap}
              duration={treatment.duration}
              isNoCharge={treatment.isNoCharge}
            />
          </div>
        ))}
      </div>
    ) : (
      <EmptyCards>診察履歴はありません</EmptyCards>
    )}
  </>
);

const fixedWidth = 280;

const containerStyle = css`
  min-width: ${fixedWidth}px;
  margin-right: ${gutter(4)};
`;

const horizontalScrollStyle = css`
  display: flex;
  overflow-x: scroll;
`;
