import React, { useEffect } from 'react';

type Props = Partial<{
  title: string;
}>;

export const DocumentTitle = ({ title }: Props) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} | ${baseTitle}`;
    } else {
      document.title = baseTitle;
    }
  }, [title]);

  return <></>;
};

const baseTitle = 'TEN';
