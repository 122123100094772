import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { DocumentTitle } from '../../../../../../components/DocumentTitle';
import { Icon } from '../../../../../../components/Icon';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Routes } from '../../../../../../constants/Routes';
import { useReservationId } from '../../../../../../hooks/useReservationId';
import { useScrollToTop } from '../../../../../../hooks/useScrollToTop';
import { ReservationDetailStore } from '../../../../../Reservations/ReservationDetail/stores/ReservationDetailStore';

export const IndividualCheckupConfirmSuccess = observer(() => {
  useScrollToTop();
  const { reservationId, push } = useReservationId();
  const store = useMemo(() => new ReservationDetailStore(), []);

  useEffect(() => {
    store.fetchReservation(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);
  const handleGoogleCalendar = () => {
    push(Routes.Paths.GoogleCalendarCoopSetting, reservationId);
  };
  return (
    <Wrapper>
      <DocumentTitle title="問診のご協力ありがとうございます" />
      <div className={imageStyle}>
        <img src="/images/doctors_bowing.png" alt="医師がお辞儀をしている画像" />
      </div>
      <CaptionGroup>
        <Caption type="header" align="center">
          事前問診のご回答および
          <br />
          オプションの選択
          <br />
          ありがとうございます
        </Caption>
        <Caption type="body" align="center">
          予約時間の5分程前には来院し、受付で保険証とクレジットカードをご提示ください。
          <br />
          （当院は完全キャッシュレスとなります）
        </Caption>
      </CaptionGroup>
      <ButtonGroup>
        <Button block onClick={() => push(Routes.Paths.ReservationShow, reservationId)}>
          予約内容を確認する
        </Button>
        <Button block theme="google" onClick={() => handleGoogleCalendar()}>
          <Icon name="calendar" />
          Google Calendarに追加する
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});

const imageStyle = css`
  display: flex;
  justify-content: center;
`;
