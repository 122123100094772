import { css } from 'emotion';
import React from 'react';

import { Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type Props = {
  items: Item[];
};

type Item = {
  label: string;
  onClick?: () => void;
};

export const BreadCrumb = ({ items }: Props) => (
  <div className={style}>
    {items.slice(0, -1).map((item, key) => (
      <span key={key} onClick={() => item.onClick?.()}>
        <span className={itemStyle}>{item.label}</span>
        <span>{'>'}</span>
      </span>
    ))}
    <span className={itemStyle}>{items.slice(0).pop()?.label}</span>
  </div>
);

const style = css`
  margin: 8px 0;
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleDark};

  > span {
    cursor: pointer;
  }
`;

const itemStyle = css`
  margin: 0 ${gutter(2)};
`;
