import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../constants/Routes';
import { FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';
import { Icon } from '../Icon';

type Props = {
  onSelect: () => void;
};

export const MainMenu = ({ onSelect }: Props) => (
  <ul className={baseStyle}>
    {data.map(({ label, to, icon }) => (
      <li key={label} className={itemStyle}>
        <Link to={to} onClick={onSelect}>
          <Icon name={icon} />
          <span>{label}</span>
        </Link>
      </li>
    ))}
  </ul>
);

// @TODO 遷移先 URL 正式版に差し替える
const data = [
  {
    label: '予約確認・変更',
    to: Routes.Paths.Reservations,
    icon: 'calendar',
  },
  {
    label: '診察履歴・領収書',
    to: Routes.Paths.MedicalHistories,
    icon: 'medical-checkup',
  },
  {
    label: '健康診断結果',
    to: Routes.Paths.CheckupResultHistories,
    icon: 'consultation-status',
  },
  {
    label: 'お知らせ',
    to: Routes.Paths.Notifications,
    icon: 'bell',
  },
  {
    label: 'アカウント情報',
    to: Routes.Paths.Account,
    icon: 'user',
  },
] as const;

const baseStyle = css`
  margin-top: ${gutter(12)};
`;

const itemStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: 700;

  & + & {
    margin-top: ${gutter(8)};
  }

  > a {
    display: flex;
    align-items: center;

    > svg {
      margin-right: ${gutter(4)};
      ${square(IconSize.Large)}
    }
  }
`;
