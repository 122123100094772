import React from 'react';

import { CheckupResultGroupStore } from '../../../Base/stores/CheckupResultGroupStore';
import { SectionHeader } from '../SectionHeader';
import { RecommendItems } from './RecommendItems';

type Props = {
  groups: CheckupResultGroupStore[];
};
export const RecommendConsultation = ({ groups }: Props) => (
  <div>
    <SectionHeader iconName="exclamation-triangle" headerTitle="受診をおすすめします" />
    <RecommendItems groups={groups} />
  </div>
);
