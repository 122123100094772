import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';

type Props = {
  url?: string;
};
export const ReceiptsSendCompletePage = observer(({ url }: Props) => (
  <Wrapper>
    <DocumentTitle title="診察履歴・領収書一覧" />
    <Caption type="header" align="center">
      メールを送信しました
    </Caption>
    <p>記入いただいたメールアドレス宛に一括ダウンロードした領収書をお送りしました。</p>
    <br />
    <p>
      メールが届かない方は
      <a className={urlStyle} href={url}>
        こちら
      </a>
    </p>
  </Wrapper>
));

const urlStyle = css`
  text-decoration: underline;
`;
