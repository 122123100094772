import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Icon } from '../../../components/Icon';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { WindowUtil } from '../../../utils/WindowUtil';
import { AttachmentList } from './components/AttachmentList';
import { TreatmentDetailCard } from './components/TreatmentDetailCard';
import { TreatmentDetailStore } from './stores/TreatmentDetailStore';

export const TreatmentDetailPage = observer(() => {
  const { reservationId } = useReservationId();
  const store = useMemo(() => new TreatmentDetailStore(), []);
  const [downloadAlt, setDownloadAlt] = useState<string>();

  useEffect(() => {
    store.fetchTreatment(reservationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  const [doLoadDownloadLink, loadDownloadLinkStatus] = useTransaction(async (key: string) => {
    const url = await store.fetchDownloadableLink(key);
    if (!url) {
      return;
    }
    WindowUtil.download(url, '添付ファイル.pdf');
    setDownloadAlt(url);
  });

  const isMapAvailable = useMemo(() => !store.treatment?.treatmentKind.online, [store.treatment]);

  return (
    <Wrapper>
      <DocumentTitle title="診察・健診履歴詳細" />
      <Modal visible={!!loadDownloadLinkStatus.running}>
        <Card>ダウンロードファイルを準備中です・・・</Card>
      </Modal>
      <div className={style}>
        <Caption type="header" align="center" underline>
          診察・健診履歴詳細
        </Caption>
        <TreatmentDetailCard
          clinic={store.treatment?.clinic}
          date={store.treatment?.date}
          department={store.treatment?.department}
          id={store.treatment?.id}
          payment={store.treatment?.payment}
          soap={store.treatment?.soap}
          isNoCharge={store.treatment?.isNoCharge}
        />
        <ButtonGroup>
          {isMapAvailable && (
            <Button>
              <Icon name="comment-edit" /> Google Mapでレビューを書く
            </Button>
          )}
          <AttachmentList onClick={doLoadDownloadLink} attachments={store.treatment?.attachments || []} />
          {downloadAlt && (
            <a href={downloadAlt} target="_blank" rel="noreferrer">
              ダウンロードが自動で行われない場合はこちら
            </a>
          )}
        </ButtonGroup>
      </div>
    </Wrapper>
  );
});

const style = css`
  display: flex;
  flex-direction: column;
  row-gap: 27px;
`;
