import { css } from 'emotion';
import React from 'react';
import ReactMarkDown from 'react-markdown';

import { Color } from '../../../../../constants/Style';
import { gutter } from '../../../../../helpers/Style';

type Props = {
  body: string;
};

// work around https://github.com/remarkjs/react-markdown/issues/339#issuecomment-653396337

window.process = window.process || { cwd: () => '' };

export const Markdown = ({ body }: Props) => (
  <ReactMarkDown skipHtml className={cascading}>
    {body}
  </ReactMarkDown>
);

const cascading = css`
  blockquote {
    padding: ${gutter(4)};
    color: ${Color.GrayscaleDarker};
    background-color: ${Color.GrayscaleLighter};
  }

  h3,
  h2 {
    margin: 16px 0;
  }

  a {
    text-decoration: underline;
  }

  strong,
  strong a {
    color: ${Color.FunctionalRedNeutral};
  }

  img {
    max-height: 270px;
  }
`;
