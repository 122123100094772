import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Wrapper } from '../../../components/Wrapper';
import { Dictionary } from '../../../constants/Dictionaries';
import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import {
  ConfirmationFormArea,
  ConfirmationFormGroup,
  ConfirmationFormItem,
  ConfirmationFormLabel,
  ConfirmationGroupTitle,
} from '../../Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { InsuranceCardInput } from './AccountEdit/components/InsuranceCardInput';
import { CardInfo } from './components/CardInfo';
import { AccountDetailStore } from './stores/AccountDetailStore';

const NOT_REGISTERED = '未登録';

export const AccountDetailPage = observer(() => {
  useScrollToTop();
  const store = useContext(AccountDetailStore.Context);
  const { push } = useHistory();

  return (
    <Wrapper>
      <DocumentTitle title="アカウント情報" />
      <Caption type="header" underline align="center">
        アカウント情報
      </Caption>

      <ConfirmationFormArea>
        <ConfirmationFormGroup>
          <ConfirmationGroupTitle
            editable
            onClickEdit={() => {
              push(Routes.Paths.AccountProfileEdit);
            }}
          >
            プロフィール
          </ConfirmationGroupTitle>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>患者番号</ConfirmationFormLabel>
            <Conditional condition={store.profile?.patientNo} />
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>氏名</ConfirmationFormLabel>
            <Conditional condition={store.profile?.name} />
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>生年月日</ConfirmationFormLabel>
            <Conditional condition={store.profile?.birthday} />
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>性別</ConfirmationFormLabel>
            <Conditional condition={store.profile?.sex}>
              {store.profile?.sex && Dictionary.UserSex[store.profile.sex]}
            </Conditional>
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>メールアドレス</ConfirmationFormLabel>
            <Conditional condition={store.profile?.mail} />
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>電話番号</ConfirmationFormLabel>
            <Conditional condition={store.profile?.phone} />
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>住所</ConfirmationFormLabel>
            <Conditional condition={store.profile?.address}>
              <div>{store.profile?.address?.zipCode}</div>
              <div>
                {store.profile?.address?.pref}
                {store.profile?.address?.addressLine1} {store.profile?.address?.addressLine2}
              </div>
            </Conditional>
          </ConfirmationFormItem>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>保険証画像</ConfirmationFormLabel>
            <Conditional condition={store.profile?.insuranceCards}>
              {store.profile?.insuranceCards && (
                <InsuranceCardInput store={store.profile.insuranceCards} disabled confirm />
              )}
            </Conditional>
          </ConfirmationFormItem>
        </ConfirmationFormGroup>
        <ConfirmationFormGroup>
          <ConfirmationGroupTitle
            editable
            onClickEdit={() => {
              push(Routes.Paths.AccountSettlementEdit);
            }}
          >
            決済情報
          </ConfirmationGroupTitle>
          <ConfirmationFormItem>
            <ConfirmationFormLabel>クレジットカード登録情報</ConfirmationFormLabel>
            <Conditional condition={store.profile?.card}>
              {store.profile?.card && <CardInfo card={store.profile.card} />}
            </Conditional>
          </ConfirmationFormItem>
        </ConfirmationFormGroup>
      </ConfirmationFormArea>
    </Wrapper>
  );
});

type ConditionalProps = {
  children?: ReactNode;
  placeholder?: string;
  condition?: undefined | null | unknown;
};
export const Conditional = ({ children, placeholder = NOT_REGISTERED, condition }: ConditionalProps) => (
  <>{condition ? children || condition : placeholder}</>
);
