import gql from 'graphql-tag';

import { SignedPostType } from '../fragments/signedPost';

export const getInsuranceCardSignedPost = gql`
  query getInsuranceCardSignedPost($input: InsuranceCardSignedPostQueryInput!) {
    getInsuranceCardSignedPost(input: $input) {
      ...SignedPostType
    }
  }
  ${SignedPostType}
`;
