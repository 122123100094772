import { css } from 'emotion';
import React, { ComponentProps } from 'react';

import { ReservationCard } from '../ReservationCard';

type Props = {
  reservations: ComponentProps<typeof ReservationCard>[];
};
export const ReservationCardListLayout = ({ reservations }: Props) => (
  <div className={listItemStyle}>
    {reservations.map(
      (reservation, inx) =>
        !reservation.hidden && (
          <div key={inx}>
            <ReservationCard {...reservation} />
          </div>
        ),
    )}
  </div>
);

const listItemStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
