import { css } from 'emotion';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { Icon } from '../../../../components/Icon';
import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';
import { GroupInfoModal } from '../../Base/components/GroupInfoModal';
import { UseToOtherSubGroup } from '../../Summary/hooks/useToOtherSubGroup';
import { BottomBorder } from '../BottomBorder';

type Props = {
  width?: number;
  height?: string;
  item: {
    displayName: string;
    description: string;
  };
  previous: string;
  thisTime: string;
  unit: string;
  subGroupId: string;
};

export const ValueOnlyCard = ({ width, height, item, previous, thisTime, unit, subGroupId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();
  const params = useParams<{ treatmentId: string }>();
  const location = useLocation();

  const toOtherSubGroup = (subGroupId: string) => {
    UseToOtherSubGroup(subGroupId, history, params, location);
  };
  return (
    <>
      <div className={cardWrapperStyle} onClick={() => toOtherSubGroup(subGroupId)}>
        <Card shadowType="floating" width={width} height={height}>
          <div className={headerStyle}>
            {item.displayName}
            {item.description && (
              <div onClick={e => e.stopPropagation()}>
                <div className={questionIconContainerStyle} onClick={() => setModalOpen(true)}>
                  <Icon name="question-circle" />
                </div>
              </div>
            )}
          </div>{' '}
          <div className={valueOnlyStyle}>
            <Caption type="small">今回</Caption>
            <p>
              <span className={valueOnlyTextStyle}>{thisTime}</span>
              {unit}
            </p>
          </div>
          <BottomBorder />
          <div className={valueOnlyStyle}>
            <Caption type="small">前回</Caption>
            <p>
              <span className={valueOnlyTextStyle}>{previous || '-'}</span>
              {previous && unit}
            </p>
          </div>
        </Card>
      </div>
      <GroupInfoModal
        name={item.displayName}
        description={item.description}
        modalOpen={modalOpen}
        onClick={setModalOpen}
      />
    </>
  );
};

const cardWrapperStyle = css`
  margin-bottom: ${gutter(5)};
  cursor: pointer;
`;

const headerStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const questionIconContainerStyle = css`
  display: flex;

  > svg {
    margin-left: ${gutter(1.7)};
    ${square(24)}

    fill: ${Color.GrayscaleLight}
  }
`;

const valueOnlyStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const valueOnlyTextStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
`;
