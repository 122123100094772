import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export default gql`
  mutation answerInterview($input: AnswerInterviewInput) {
    answerInterview(input: $input) {
      ...InterviewType
    }
  }
  ${InterviewType}
`;
