import gql from 'graphql-tag';

import { CancelPoliciesType } from './cancelPolicies';

export const TreatmentKindType = gql`
  fragment TreatmentKindType on TreatmentKind {
    id
    name
    department {
      id
    }
    course
    icon
    vaccine
    noInterview
    subTitle
    estimatedDuration
    revisit
    order
    subAnnouncement
    announcement
    requirement
    displayDuration
    requiredReservationFee
    ...CancelPoliciesType
  }
  ${CancelPoliciesType}
`;
