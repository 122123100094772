export class URLSearchParamsMonad {
  private origin: URLSearchParams;
  constructor(origin: string | URLSearchParams) {
    this.origin = new URLSearchParams(origin.toString());
  }

  public accept(key: string, value: string) {
    const o = new URLSearchParams(this.origin);
    if (o.has(key)) {
      o.delete(key);
    }
    o.append(key, value);
    return new URLSearchParamsMonad(o);
  }

  public acceptOptional(key: string, value?: string) {
    if (!value) {
      return this;
    }

    return this.accept(key, value);
  }

  toString() {
    return this.origin.toString();
  }
}
