import { css, cx } from 'emotion';
import React, { ComponentProps, Fragment, ReactNode, useMemo } from 'react';

import { Color, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';

type BaseProps = {
  children: ReactNode;
};

type Align = 'left' | 'center' | 'right';

type Props = CaptionHeaderProps | CaptionProps;

type CaptionProps = {
  type: 'body' | 'small' | 'subheader' | 'regularHeader' | 'extraLarge' | 'div' | 'danger' | 'mediumBody';
  children: ReactNode;
  align?: Align;
  gutter?: number;
  break?: boolean;
};

type CaptionHeaderProps = {
  type: 'header';
  underline?: boolean;
  children: ReactNode;
  align?: Align;
  gutter?: number;
};

export const CaptionGroup = ({ children }: BaseProps) => <div className={groupStyle}>{children}</div>;

const groupStyle = css`
  margin: ${gutter(6)} 0;
`;

export const Caption = (props: Props) => {
  switch (props.type) {
    case 'header':
      return (
        <Alignment align={props.align}>
          <CaptionHeader type={props.type} underline={props.underline}>
            {props.children}
          </CaptionHeader>
        </Alignment>
      );
    case 'small':
      return (
        <Alignment align={props.align}>
          <CaptionSmall>{props.children}</CaptionSmall>
        </Alignment>
      );
    case 'subheader':
      return (
        <Alignment align={props.align}>
          <CaptionSubHeader>{props.children}</CaptionSubHeader>
        </Alignment>
      );
    case 'regularHeader':
      return (
        <Alignment align={props.align}>
          <CaptionRegularHeader>{props.children}</CaptionRegularHeader>
        </Alignment>
      );
    case 'extraLarge':
      return (
        <Alignment align={props.align}>
          <CaptionExtraLarge>{props.children}</CaptionExtraLarge>
        </Alignment>
      );
    case 'div':
      return (
        <Alignment align={props.align}>
          <div className={props.break ? breakStyle : ''}>{props.children}</div>
        </Alignment>
      );
    case 'danger':
      return (
        <Alignment align={props.align}>
          <div className={cx(dangerStyle, props.break ? breakStyle : '')}>
            <LinkDetection text={props.children as string} />
          </div>
        </Alignment>
      );
    case 'mediumBody':
      return (
        <Alignment align={props.align}>
          <div className={cx(subHeaderStyle, props.break ? breakStyle : '')}>
            <LinkDetection text={props.children as string} />
          </div>
        </Alignment>
      );
    case 'body':
    default:
      return (
        <Alignment align={props.align}>
          <CaptionBody>{props.children}</CaptionBody>
        </Alignment>
      );
  }
};

type LinkDetectionProps = {
  text: string;
};

// eslint-disable-next-line no-useless-escape
const LINK_DETECTION_REGEX = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

const LinkDetection = ({ text }: LinkDetectionProps) => {
  const chunks = useMemo(() => {
    if (typeof text !== 'string') {
      return undefined;
    }
    return (text || '')
      .split(/\n/)
      .reduce((prev, word) => {
        const last = prev[0];

        if (last === undefined) {
          return [word];
        }

        if (LINK_DETECTION_REGEX.test(last) || LINK_DETECTION_REGEX.test(word)) {
          return [word, last, ...prev.slice(1)];
        }
        return [[last, word].join('\n'), ...prev.slice(1)];
      }, new Array<string>())
      .reverse();
  }, [text]);

  // fallback
  if (typeof text !== 'string' || !chunks) {
    return <>{text}</>;
  }

  return (
    <>
      {chunks.map((word, key) => {
        if (LINK_DETECTION_REGEX.test(word)) {
          return (
            <a href={word} target="_blank" rel="noreferrer" key={key}>
              {word}
            </a>
          );
        }
        return (
          <Fragment key={key}>
            {word}
            {'\n'}
          </Fragment>
        );
      })}
    </>
  );
};

const breakStyle = css`
  white-space: break-spaces;
`;

type AlignmentProps = Pick<ComponentProps<typeof Caption>, 'children' | 'align'>;

const centerStyle = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const rightStyle = css`
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;

export const Alignment = ({ children, align }: AlignmentProps) => {
  switch (align) {
    case 'center':
      return <div className={centerStyle}>{children}</div>;
    case 'right':
      return <div className={rightStyle}>{children}</div>;
    case 'left':
    default:
      return <>{children}</>;
  }
};

const underlineStyle = css`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 50px;
  margin-bottom: ${gutter(5)};
  box-shadow: 0 2px 0 #000;
`;

const nonUnderlineStyle = css`
  margin-bottom: ${gutter(5)};
`;

const CaptionHeader = ({ children, underline }: CaptionHeaderProps) => (
  <div className={underline ? underlineStyle : nonUnderlineStyle}>
    <h1 className={headerStyle}>{children}</h1>
  </div>
);

const headerStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
  line-height: 1;
`;

const subHeaderStyle = css`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  line-height: 1;
`;

const regularHeaderStyle = css`
  font-size: ${FontSize.Regular};
  font-weight: bold;
  line-height: 1;
`;

const extraLargeStyle = css`
  font-size: ${FontSize.ExtraLarge};
  font-weight: bold;
  line-height: 1;
`;
const CaptionExtraLarge = ({ children }: BaseProps) => <div className={extraLargeStyle}>{children}</div>;

const CaptionSubHeader = ({ children }: BaseProps) => <h3 className={subHeaderStyle}>{children}</h3>;

const CaptionRegularHeader = ({ children }: BaseProps) => <h3 className={regularHeaderStyle}>{children}</h3>;

const CaptionBody = ({ children }: BaseProps) => <p className={bodyStyle}>{children}</p>;

const bodyStyle = css`
  font-size: ${FontSize.Small};
  font-weight: normal;
  line-height: 1.3;
  color: ${Color.GrayscaleDark};
  white-space: break-spaces;
`;

const smallStyle = css`
  font-size: ${FontSize.Small};
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: ${Color.GrayscaleNeutral};
`;

const CaptionSmall = ({ children }: BaseProps) => <small className={smallStyle}>{children}</small>;

const dangerStyle = css`
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;
