import React, { createContext, ReactNode, useState } from 'react';

import { useContext } from '../../hooks/useContext';
import { Button } from '../Button';
import { ButtonGroup } from '../ButtonGroup';
import { Caption } from '../Caption';
import { Card } from '../Card';
import { Modal } from '../Modal';

type Props = Context;
export const ErrorDialog = ({ error }: Props) => (
  <Modal visible={!!error}>
    <Card>
      <Caption type="header" align="center">
        {error?.header}
      </Caption>
      <div>{error?.message}</div>
      <ButtonGroup gutter={8}>
        <Button
          onClick={() => {
            error?.onOk?.();
          }}
        >
          OK
        </Button>
      </ButtonGroup>
    </Card>
  </Modal>
);

type Context = {
  error?: Error;
  setError: (error?: Error) => void;
};

type Error = {
  header?: string;
  message: string;
  reasons?: string[];
  onOk?: () => void;
};

export const ErrorDialogContext = createContext<Context | null>(null);

type ContainerProps = {
  children: ReactNode;
};
export const ErrorDialogProvider = ({ children }: ContainerProps) => {
  const [error, setError] = useState<Error>();
  return (
    <ErrorDialogContext.Provider value={{ error, setError }}>
      {children}
      <ErrorDialog error={error} setError={setError} />
    </ErrorDialogContext.Provider>
  );
};

export class HandledError {
  constructor(public message: string) {}
}

export const useError = () => ({ setError: useContext(ErrorDialogContext).setError });
