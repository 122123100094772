import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { DepartmentType } from '../../../../gql/gql-types';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { Card } from '../../../components/Card';
import { Dictionary, DictionaryKeys } from '../../../components/Dictionary';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { Modal } from '../../../components/Modal';
import { Wrapper } from '../../../components/Wrapper';
import { GtmClasses } from '../../../constants/AnalyticsTags';
import { Routes } from '../../../constants/Routes';
import { Color } from '../../../constants/Style';
import { gutter } from '../../../helpers/Style';
import { useContext } from '../../../hooks/useContext';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { AnnouncementStore } from '../../../stores/AnnouncementStore';
import { MyPageStore } from '../../../stores/MyPageStore';
import { SessionStore } from '../../../stores/SessionStore';
import { useLeaveGuard } from '../hooks/useRouteGuard';
import { ReservationDetailStore } from '../ReservationDetail/stores/ReservationDetailStore';
import { ReservationCard } from '../ReservationsList/components/ReservationCard';

const descriptionStyle = css`
  width: 100%;
  padding: ${gutter(4)};
  margin-top: 55px;
  margin-bottom: ${gutter(7)};

  /* margin-bottom: ${gutter(8)}; */
  word-break: break-all;
  background-color: ${Color.GrayscaleWhite};
`;

const imageContainerStyle = css`
  display: flex;
  justify-content: center;
  margin-top: -55px;
  margin-bottom: ${gutter(5)};
`;

export const ReservationComplete = observer(() => {
  const { push, reservationId } = useReservationId();
  const session = useContext(SessionStore.Context);
  const store = useMemo(() => new ReservationDetailStore(), []);
  const announcementStore = useMemo(() => new AnnouncementStore(), []);
  const [loadReservation, loadReservationStatus] = useTransaction(() => store.fetchReservation(reservationId));

  useEffect(() => {
    loadReservation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  useEffect(() => {
    if (!session.authenticated || !session.user) {
      return;
    }
    store.fetchUserTreatments(session.user.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    announcementStore.setDepartment(store.reservation?.department);
    announcementStore.setTreatmentKind(store.reservation?.treatmentKind);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.reservation]);

  const pushToPreInterview = () => {
    if (store.reservation?.department.type === DepartmentType.Checkup && store.completedTreatmentsCount !== 0) {
      push(Routes.Paths.CheckupPreInterviewPostReserveEdit, reservationId);
      return;
    }
    // store.completedTreatmentsCount === 0に修正する
    if (store.completedTreatmentsCount === 0) {
      push(Routes.Paths.ReservationAccountPreEdit, reservationId);
      return;
    }
    push(Routes.Paths.ReservationPreInterview, reservationId);
  };

  const { visible, PromptWrapper, handleDefaultNavigation, forcePush } = useLeaveGuard();

  return (
    <Wrapper>
      <DocumentTitle title="ご予約が完了しました" />
      <PromptWrapper>
        <Modal visible={visible}>
          <Card width={300} className={promptDialogCardStyle}>
            {store.reservation?.department.type === DepartmentType.Checkup ? (
              <div className={promptDialogCaptionGroupStyle}>
                <Caption type="header">
                  <span className={promptDialogCaptionHeaderStyle}>追加での事前問診への回答にご協力お願いします。</span>
                </Caption>
                <Caption type="body">
                  <span className={promptDialogCaptionBodyStyle}>
                    予約いただいたプランとオプションに合わせた追加問診項目になります。
                    <br />
                    未回答の患者様におかれましては、来院時に同様の問診に回答いただきます。
                  </span>
                </Caption>
              </div>
            ) : store.completedTreatmentsCount === 0 ? (
              // 初診だったら表示患者情報登録の画面へ
              <div className={promptDialogCaptionGroupStyle}>
                <Caption type="header" align="center">
                  <span className={cx(promptDialogCaptionHeaderStyle, promptDialogCaptionRedStyle)}>
                    忘れずにご登録ください！
                  </span>
                </Caption>
                <Caption type="body">
                  <span className={promptDialogCaptionBodyStyle}>
                    当院はクレジットカード決済です。
                    <br />
                    スムーズな診療にあたり必ず<span className={promptDialogCaptionBoldStyle}>「保険証」</span>と
                    <span className={promptDialogCaptionBoldStyle}>「クレジットカード」</span>
                    の事前登録をお願いいたします。
                  </span>
                </Caption>
              </div>
            ) : (
              <div className={promptDialogCaptionGroupStyle}>
                <Caption type="header" align="center">
                  <span className={promptDialogCaptionHeaderStyle}>
                    Web問診のご回答を <br />
                    お願いします！(約1分~2分)
                  </span>
                </Caption>
                <Caption type="body">
                  <span className={cx(promptDialogCaptionBodyStyle, promptDialogCaptionRedStyle)}>
                    事前問診のご回答により、スムーズに診療を受けていただくことが可能です。必ずご回答をお願いします。
                  </span>
                </Caption>
              </div>
            )}
            <ButtonGroup gutter={10}>
              <Button theme="neutral" type="button" onClick={() => forcePush(pushToPreInterview)}>
                {store.completedTreatmentsCount === 0 ? '今すぐに登録する' : 'WEB問診に回答する'}
              </Button>
              <Button theme="neutral" ghost type="button" onClick={handleDefaultNavigation}>
                キャンセル
              </Button>
            </ButtonGroup>
          </Card>
        </Modal>
      </PromptWrapper>
      <CaptionGroup>
        {/** FIXME COVID only */}
        <Caption type="header">{store.reservation?.treatmentKind?.name}のご予約が完了しました</Caption>
        <Caption type="body">
          {/** FIXME COVID only */}
          {store.reservation?.treatmentKind?.vaccine ? (
            <div>
              予約時間の5分前には来院し、受付にて以下3点を提示下さい
              <br />
              ①TEN用の接種記録書（印刷・患者欄記入済み）※予約案内メールに添付
              <br />
              ②TEN用の予診票（印刷・患者欄記入済み） ※予約案内メールに添付
              <br />
              ③身分証明書
            </div>
          ) : (
            <Dictionary dictKey={DictionaryKeys.RESERVATION_COMPLETE_BODY} input={store.reservation}>
              <div>ご来院をお待ちしております。</div>
              <div>
                予約時間の５分程前には来院し、受付で保険証とクレジットカードをご提示ください。（当院は完全キャッシュレスとなります）
              </div>
            </Dictionary>
          )}
        </Caption>
      </CaptionGroup>
      {!store.reservation?.noInterview && (
        <InterviewRequest
          store={store}
          announcementStore={announcementStore}
          onClick={() => forcePush(pushToPreInterview)}
          disabled={loadReservationStatus.running}
        />
      )}
      {store.reservation?.noInterview && announcementStore.requirement && (
        <div className={requirementStyle}>
          <Card>
            <Caption type="header">ご協力いただきたい事項</Caption>
            <Caption type="danger" break>
              {announcementStore.requirement}
            </Caption>
          </Card>
        </div>
      )}
      <CancelSuggest store={store} />
    </Wrapper>
  );
});

const promptDialogCardStyle = css`
  padding-top: 32px;
`;

const promptDialogCaptionGroupStyle = css`
  margin-bottom: 24px;
`;
const promptDialogCaptionHeaderStyle = css`
  line-height: 140%;
`;

const promptDialogCaptionBodyStyle = css`
  font-size: 15px;
`;

const promptDialogCaptionRedStyle = css`
  color: ${Color.FunctionalRedNeutral};
`;

const promptDialogCaptionBoldStyle = css`
  font-weight: bold;
`;

type StoreProps = {
  store: ReservationDetailStore;
  announcementStore: AnnouncementStore;
};

type InterviewRequestProps = StoreProps & {
  onClick?: () => void;
  disabled?: boolean;
};

const InterviewRequest = observer(({ store, announcementStore, onClick, disabled }: InterviewRequestProps) => {
  const { push } = useHistory();
  if (!store.reservation) {
    return <></>;
  }
  return (
    <div>
      <div className={descriptionStyle}>
        <div className={imageContainerStyle}>
          {store.reservation.department.type !== DepartmentType.Checkup && store.completedTreatmentsCount === 0 ? (
            <img src="/images/card_register.png" alt="保険証とカードを提出している画像" />
          ) : (
            <img src="/images/note_taking.png" alt="医師が問診している画像" />
          )}
        </div>
        {store.reservation.department.type === DepartmentType.Checkup ? (
          <>
            <Caption type="header">追加での事前問診への回答にご協力お願いします。</Caption>
            <Caption type="body">
              予約いただいたプランとオプションに合わせた追加問診項目になります。
              <br />
              未回答の患者様におかれましては、来院時に同様の問診に回答いただきます。
            </Caption>
          </>
        ) : store.completedTreatmentsCount === 0 ? (
          // 初診だったら表示患者情報登録の画面へ
          <>
            <Caption type="header" align="center">
              保険証の登録をお願いします。
            </Caption>
            <Caption type="body">事前にご登録いただくことで、スムーズにご受診いただけます</Caption>
          </>
        ) : (
          <>
            <Caption type="header" align="center">
              WEB事前問診（約1-2分）
              <br />
              ご協力のお願い
            </Caption>
            <Caption type="body">
              <span className={captionDangerStyle}>
                ご回答頂くと、医師との対話の質の向上やクリニック到着後、すぐに受診することが可能になります！
              </span>
            </Caption>
          </>
        )}
      </div>
      {announcementStore.requirement && (
        <div className={requirementStyle}>
          <Card>
            <Caption type="header">ご協力いただきたい事項</Caption>
            <Caption type="danger" break>
              {announcementStore.requirement}
            </Caption>
          </Card>
        </div>
      )}
      <div>
        {/* 初診だったらボタン変更 */}
        <ButtonGroup gutter={10}>
          {store.completedTreatmentsCount === 0 ? (
            <Button
              className={`${GtmClasses.gtm.startInterview.onCompleted} ${GtmClasses.gtm.registration.patientInformation.startCardRegistration}`}
              block
              type="button"
              disabled={disabled}
              onClick={onClick}
            >
              患者プロフィールの登録へ
            </Button>
          ) : (
            <>
              <Button
                className={GtmClasses.gtm.startInterview.onCompleted}
                block
                type="button"
                disabled={disabled}
                onClick={onClick}
              >
                {store.reservation.department.type === DepartmentType.Checkup
                  ? '追加事前問診に回答'
                  : 'WEB事前問診に回答'}
              </Button>
              <Button block type="button" ghost onClick={() => push(Routes.Paths.Root)}>
                あとで回答する（マイページへ）
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
});

const requirementStyle = css`
  margin: 16px 0;
`;

const captionDangerStyle = css`
  font-weight: bold;
  color: ${Color.FunctionalRedNeutral};
`;

type CancelSuggestProps = {
  store: ReservationDetailStore;
};
const CancelSuggest = observer(({ store }: CancelSuggestProps) => {
  const sessionStore = useContext(SessionStore.Context);
  const myPage = useContext(MyPageStore.Context);
  const [doFetchInitial] = useTransaction(() => myPage.fetchInitial());

  useEffect(() => {
    if (!sessionStore.user) {
      return;
    }
    myPage.setProfile({ id: sessionStore.user.userId });
    doFetchInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  const cancelSuggestTarget = useMemo(
    () =>
      myPage.reservations.find(
        r => r.id !== store.reservation?.id && r.department.id === store.reservation?.department.id && !r.hidden,
      ),
    [myPage.reservations, store.reservation],
  );

  return (
    <>
      {cancelSuggestTarget && (
        <div className={cancelSuggestContainerStyle}>
          <Caption type="subheader">重複している予約があればキャンセルしてください</Caption>
          <div className={cancelSuggestCardStyle}>
            <ReservationCard {...cancelSuggestTarget} />
          </div>
        </div>
      )}
    </>
  );
});

const cancelSuggestContainerStyle = css`
  margin-top: 42px;
`;
const cancelSuggestCardStyle = css`
  margin-top: 16px;
`;
