import { css } from 'emotion';
import React from 'react';

import { Color } from '../../../../../constants/Style';
import { toRGBA } from '../../../../../helpers/Style';
import { ValueOf } from '../../../../../interface';

const BRANDS = ['Visa', 'MasterCard', 'JCB', 'American Express', 'Discover'] as const;
type StringOf<X> = X extends string ? X : never;
type Brands = StringOf<ValueOf<typeof BRANDS>>;

type Props = {
  brand: Brands | 'unknown';
};
export const BrandIcon = ({ brand }: Props) => (
  <div className={rowStyle}>
    {BRANDS.map(b => (
      <SingleBrandIcon brand={b} key={b} disabled={brand !== 'unknown' && brand !== b} />
    ))}
  </div>
);

const rowStyle = css`
  display: grid;
  grid-template-columns: repeat(${BRANDS.length}, 50px);
  column-gap: 10px;
`;

type SingleProps = Required<Props> & {
  disabled?: boolean;
};
const SingleBrandIcon = ({ brand, disabled }: SingleProps) => (
  <div className={disabled ? disabledStyle : ''}>
    <img src={`/images/brands/${brand.replace(/\s/, '_')}.png`} alt={brand} />
  </div>
);

const disabledStyle = css`
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: ${toRGBA(Color.GrayscaleDark, 0.6)};
  }
`;
