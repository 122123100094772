import React from 'react';

export const NewTab = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1H1L1 11H3V4C3 3.44772 3.44772 3 4 3H11V1ZM12 3H14C14.5523 3 15 3.44772 15 4V14C15 14.5523 14.5523 15 14 15H4C3.44772 15 3 14.5523 3 14V12H1C0.447715 12 0 11.5523 0 11V1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V3ZM4 4H14V14H4L4 4Z"
      fill="#2CA9E1"
    />
  </svg>
);
