import gql from 'graphql-tag';

import { ProfileType } from '../fragments/user';

export default gql`
  query getUser($userId: ID!) {
    getUser(id: $userId) {
      ...ProfileType
    }
  }
  ${ProfileType}
`;
