import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export const getCheckupInterview = gql`
  query getCheckupInterview($input: CheckupInterviewInput!) {
    getCheckupInterview(input: $input) {
      ...InterviewType
    }
  }
  ${InterviewType}
`;
