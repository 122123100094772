import { IconName } from '@clinic-monorepo/clinic-icon';
import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import { ListDepartmentsQuery, ListDepartmentsQueryVariables, Maybe } from '../../../../../gql/gql-types';
import { listDepartments } from '../../../../../gql/operations/listDepartment';
import { Department as DepartmentBase } from '../../../../domains/reservation/Department';
import { groupBy, OrderByBuilder } from '../../../../utils/CollectionUtil';

type Department = DepartmentBase & {
  icon: IconName;
  displayName?: Maybe<string>;
};

/**
 * 診療科一覧の状態とその取得処理を司る
 */
export class DepartmentsStore {
  public static Context = createContext<DepartmentsStore | null>(null);
  public departments: Department[] = [];

  constructor() {
    makeObservable(this, {
      departments: observable,
      setDepartments: action,
      clinics: computed,
      fetchDepartments: action,
    });
  }

  setDepartments(d: Department[]) {
    this.departments = d;
  }

  get clinics() {
    return Object.entries(groupBy(this.departments, d => d.clinicName || '')).map(([name, departments]) => ({
      name,
      departments,
    }));
  }

  async fetchDepartments() {
    const listDepartment = await GQL.queryAsGuest<ListDepartmentsQueryVariables, ListDepartmentsQuery>(
      listDepartments,
      {},
    );

    this.setDepartments(
      new OrderByBuilder<SortableDepartment>()
        .asc(d => d.order)
        .asc(d => d.orcaID)
        .sort(
          listDepartment.listDepartments?.items
            ?.filter(d => !d?.hidden)
            .map(department => ({
              id: department?.id || '',
              name: department?.name || '',
              baseDuration: department?.baseDuration || 0,
              type: department?.type || undefined,
              icon: (department?.icon || 'men') as IconName,
              clinic: department?.clinic?.id || '00',
              clinicName: department?.clinic?.name || '',
              alertThreshold: department?.alertThreshold || 0,
              termsUrl: department?.clinic?.termsUrl || '',
              orcaID: department?.orcaID || '',
              online: department?.online || false,
              order: department?.order,
              displayName: department?.displayName,
              description: department?.description,
              announcement: department?.announcement,
              requirement: department?.requirement,
            }))
            .map(department => ({
              ...department,
            })) || [],
        ),
    );
  }
}

type SortableDepartment = Department & {
  order?: Maybe<number>;
  orcaID?: Maybe<string>;
};
