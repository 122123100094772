import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormLayout } from '../../../../../components/Form';
import { InterviewReactiveFormItem } from '../../../../../components/InterviewForm';
import { Wrapper } from '../../../../../components/Wrapper';
import { Breakpoint, Dimension } from '../../../../../constants/Style';
import { useContext } from '../../../../../hooks/useContext';
import { safeFilter } from '../../../../../utils/CollectionUtil';
import { InterviewProgress } from '../../../../Interviews/InterviewsNew/InterviewsInput/components/InterviewProgress';
import { CheckupReservationStore } from '../stores/CheckupReservationStore';

type Props = {
  onNext?: () => void;
  onBack?: () => void;
};

export const CheckupReservationPreInterview = observer(({ onNext, onBack }: Props) => {
  const store = useContext(CheckupReservationStore.Context);
  // const session = useContext(SessionStore.Context);

  useEffect(() => {
    !store.preInterview && store.fetchPreInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="事前問診 - 健診" />
      <div className={interviewProgressStyle}>
        <InterviewProgress
          answered={store.preInterview?.questions?.filter(q => q?.answer?.filter(x => x?.answer)?.length)?.length || 0}
          total={store.preInterview?.questions?.length || 10}
        />
      </div>
      <Wrapper>
        <CaptionGroup>
          <Caption type="header" underline align="center">
            事前問診
          </Caption>
        </CaptionGroup>
        <FormLayout>
          {store.preInterview?.questions?.map((q, key) => (
            <InterviewReactiveFormItem
              key={key}
              def={{
                id: q?.id,
                label: q?.label,
                options: q?.options,
                required: q?.required,
              }}
              onChange={v => store.setAnswer(key, v)}
              value={safeFilter(q?.answer || []).map(a => ({
                id: String(a.option?.id || 0),
                label: safeFilter(q?.options).find(o => o.id === a.option?.id)?.label || '',
                value: a.answer || '',
              }))}
            />
          ))}
        </FormLayout>
        <ButtonGroup gutter={16}>
          <Button onClick={onNext} disabled={!store.preInterviewValid}>
            次へ
          </Button>
          <Button ghost onClick={onBack}>
            戻る
          </Button>
        </ButtonGroup>
      </Wrapper>
    </>
  );
});

const interviewProgressStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: ${Dimension.GlobalNavigationWidth}px;
  }
`;
