import { css, cx } from 'emotion';
import React from 'react';

import { Color, FontSize } from '../../../../constants/Style';
import { square } from '../../../../helpers/Style';
import { Result } from '../../../../interfaces/Result';

type Props = {
  result: Result;
  small?: boolean;
  medium?: boolean;
};
export const ResultRound = ({ result, medium, small }: Props) => (
  <>
    <p className={cx(roundStyle[result], small ? smallStyle : '', medium ? mediumStyle : '')}>{result}</p>
  </>
);

const roundStyle: Record<Result, string> = {
  A: roundColor(Color.NotingResult),
  B: roundColor(Color.AttentionResult),
  C: roundColor(Color.ConsultationResult),
  D: roundColor(Color.TreatmentResult),
};

function roundColor(color: Color) {
  return css`
    ${square(24)}

    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: bold;
    color: ${Color.GrayscaleWhite};
    text-align: center;
    background-color: ${color};
    border-radius: 100%;
  `;
}

const smallStyle = css`
  ${square(18)}

  align-items: baseline;
  font-size: ${FontSize.SmallRegular};
`;

const mediumStyle = css`
  ${square(32)}

  align-items: center;
  font-size: ${FontSize.Large};
`;
