import { css } from 'emotion';
import React from 'react';

import { gutter } from '../../../../../helpers/Style';

type Props = {
  clinic: {
    id: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ReservationImage = (_: Props) => (
  <div className={style}>
    <img src="/images/shibuya-image.png" alt="クリニック内装の写真" />
  </div>
);

const style = css`
  margin: ${gutter(4)} 0;
`;
