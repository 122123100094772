import { SessionStore } from '../stores/SessionStore';
import { useContext } from './useContext';

/**
 * サインイン後、アプリ内でリダイレクトする場合このhookを使う。
 * state自体にリダイレクト先を持つとURL経由で盗まれる可能性があるため、
 * stateをキーとしてsessionStorageにリダイレクト先を保持する。
 */
export const useFederatedSignIn = () => {
  const store = useContext(SessionStore.Context);
  return (to: string) => {
    const state = to;
    store.lineSignIn(state);
  };
};
