import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../constants/Routes';
import { Breakpoint, Duration } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { IconButton } from '../IconButton';
import { LoginState } from './LoginState';

type Props = {
  onClickNavigationButton: () => void;
};

export const GlobalHeader = ({ onClickNavigationButton }: Props) => (
  <header className={baseStyle}>
    <div>
      <IconButton name="gnav" onClick={onClickNavigationButton} />
    </div>
    <h1 className={brandLogoStyle}>
      <Link to={Routes.Paths.Root}>
        <img src="/images/logo-system-header.svg" alt="TEN" />
      </Link>
    </h1>
    <div>
      <LoginState />
    </div>
  </header>
);

const baseStyle = css`
  display: grid;
  grid-template-columns: 40px auto 40px;
  align-items: center;
  padding: ${gutter(4)};

  @media (min-width: ${Breakpoint.ScreenMd}px) {
    visibility: hidden;
  }
`;

const brandLogoStyle = css`
  width: 61px;
  margin: auto;

  > a {
    display: block;
    opacity: 1;
    transition: opacity ${Duration.Fade};

    &:hover {
      opacity: 0.7;
    }
  }
`;
