import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { useError } from '../../../../../components/ErrorDialog';
import { FormItem, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { Wrapper } from '../../../../../components/Wrapper';
import { useContext } from '../../../../../hooks/useContext';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import { CardInputLayout } from '../../../AccountDetail/components/CardInputLayout';
import { ProfileStore } from '../../stores/ProfileStore';
import { errorStyle } from '../components/AccountsNameInput';
import { NameInput } from '../components/NameInput';
import { LineConnectStore } from './stores/LineConnectStore';

export const AccountsNewNameInputLineConnect = observer(() => {
  const store = useMemo(() => new ProfileStore(), []);
  const { setError } = useError();
  const { decode } = useReservationId();
  const connectionStore = useMemo(() => {
    const res = new LineConnectStore();
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    if (token) {
      res.setToken(decode(token));
      return res;
    }
    setError({ message: '不正なURLです。' });
    throw new Error('不正なURLです。');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const session = useContext(SessionStore.Context);
  const [connect] = useTransaction(async () => {
    const url = await connectionStore.issueNonce();
    if (!url) {
      return;
    }
    window.location.href = url;
  });

  const [doCallback, status] = useTransaction(async () => {
    await store.register();
    await connect();
  });

  const [authStateKnown, setAuthStateKnown] = useState(false);
  const [concent, setConcent] = useState(false);

  const onSubmit = useRef<() => void>(doCallback);

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }

    const user = session.user?.userEntity;
    if (!user) {
      setAuthStateKnown(true);
      return;
    }
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown, connectionStore.token]);

  if (!authStateKnown) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        患者プロフィール登録
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <CardInputLayout
            required
            callback={async t => {
              store.setCard({
                brand: t.brand,
                id: t.payjpID,
                expAt: t.expAt,
                fourDigit: t.fourDigit,
              });
              return doCallback();
            }}
            onSubmit={onSubmit}
            onStatusChange={s => store.cardStatus.setCardTransactionStatus(s)}
            onPreCardValidateChange={v => store.cardStatus.setPreCardValidate(v)}
          />
        </FormItem>
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <div className={errorStyle}>{store.cardStatus.cardTransactionStatus?.error}</div>
        <Button
          type="submit"
          block
          onClick={() => onSubmit.current()}
          disabled={
            status.running ||
            !store.valid ||
            !concent ||
            store.cardStatus.cardTransactionStatus?.running ||
            store.cardStatus.hasPreCardError
          }
        >
          登録する
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
