import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { FormItem, FormLabel, FormLayout } from '../../../../components/Form';
import { TextField } from '../../../../components/TextField';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { ForgotPasswordStore } from '../stores/ForgotPasswordStore';

export const ForgotPasswordPage = observer(() => {
  const store = useMemo(() => new ForgotPasswordStore(), []);
  const { push } = useHistory();
  const { addToast } = useToast();
  const [error, setError] = useState('');
  const { encode } = useReservationId();

  const [send, sendStatus] = useTransaction(
    async () => {
      if (!store.email) {
        return;
      }
      await store.forgotPassword();
      addToast({ message: '認証コードを送信しました。' });
      push(Routes.Paths.ResetPassword.replace(':id', encode(store.email)));
    },
    e => {
      setError(e.message);
    },
  );

  return (
    <Wrapper>
      <DocumentTitle title="パスワードをお忘れの方" />
      <CaptionGroup>
        <Caption type="header" align="center">
          パスワードをお忘れの方
        </Caption>

        <Caption type="body">
          登録時に設定したメールアドレスを記入してください。パスワード再設定コードをお送りします。
        </Caption>
      </CaptionGroup>

      <FormLayout>
        <FormItem>
          <FormLabel>送信先メールアドレス</FormLabel>
          <TextField color="white" value={store.email} onChange={v => store.setEmail(v)} />
        </FormItem>
        <FormItem>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonGroup>
            <Button onClick={send} disabled={!!sendStatus.running}>
              確認する
            </Button>
            <Button ghost onClick={() => push(Routes.Paths.SignIn)}>
              ログインページに戻る
            </Button>
          </ButtonGroup>
        </FormItem>
      </FormLayout>
    </Wrapper>
  );
});
