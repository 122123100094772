import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { GQL } from '../../../../../../../gql/client';
import {
  FixTemporallyCheckupReservationForIndividualMutation,
  FixTemporallyCheckupReservationForIndividualMutationVariables,
  GetTemporallyTreatmentQuery,
  GetTemporallyTreatmentQueryVariables,
  Maybe,
  ShouldConfirmQuery,
  ShouldConfirmQueryVariables,
} from '../../../../../../../gql/gql-types';
import { fixTemporallyCheckupReservationForIndividual } from '../../../../../../../gql/operations/fixTemporallyCheckupReservationForIndividual';
import { getTemporallyTreatment } from '../../../../../../../gql/operations/getTemporallyTreatment';
import { shouldConfirm } from '../../../../../../../gql/operations/shouldConfirm';
import { HandledError } from '../../../../../../components/ErrorDialog';
import { Card } from '../../../../../../domains/customer/Card';
import { CreditCardStore } from '../../../../store/CreditCardStore';
import { ErrorInfo, Name } from '../../../stores/ProfileStore';

type Clinic = {
  id: string;
  name: string;
  termsUrl?: Maybe<string>;
};

export class CheckupReservationTemporallyStore {
  constructor() {
    makeAutoObservable(this, {
      clinic: observable,
      fetch: action,
      confirmRequired: observable,
      name: observable,
      setName: action,
      valid: observable,
      nameRequired: observable,
      setNameRequired: action,
      errors: observable,
      mail: observable,
      setMail: action,
      card: observable,
      setCard: action,
    });
  }

  public clinic?: Clinic = undefined;
  public confirmRequired?: boolean | null = undefined;
  public nameRequired?: boolean = undefined;
  public name?: Name = undefined;
  public mail?: string = undefined;
  public id?: string = undefined;
  public departmentId?: string = undefined;
  public treatmentKindId?: string = undefined;
  public card?: Card = undefined;

  public async fetch(id: string) {
    const treatment = await GQL.queryAsGuest<GetTemporallyTreatmentQueryVariables, GetTemporallyTreatmentQuery>(
      getTemporallyTreatment,
      { id },
    );

    if (!treatment.getTemporallyTreatment) {
      throw new HandledError(
        '原因は15分以内に予約が完了されなかったためと考えられます。お手数ですが、再度はじめから予約をお願い致します。',
      );
    }

    this.confirmRequired = (
      await GQL.query<ShouldConfirmQueryVariables, ShouldConfirmQuery>(shouldConfirm, {
        input: {
          clinic: treatment.getTemporallyTreatment.clinic?.id || '00',
        },
      })
    ).shouldConfirm?.confirmRequired;

    this.clinic = treatment.getTemporallyTreatment.clinic || undefined;

    this.departmentId = treatment.getTemporallyTreatment.department?.id;
    this.treatmentKindId = treatment.getTemporallyTreatment.treatmentKind?.id;

    this.id = id;
  }

  public async fix() {
    if (!this.id) {
      return false;
    }
    const res = await GQL.query<
      FixTemporallyCheckupReservationForIndividualMutationVariables,
      FixTemporallyCheckupReservationForIndividualMutation
    >(fixTemporallyCheckupReservationForIndividual, {
      input: {
        id: this.id,
        // eslint-disable-next-line no-irregular-whitespace
        name: this.name ? `${this.name.sei}　${this.name.mei}` : '',
        mail: this.mail,
        sei: this.name?.sei,
        mei: this.name?.mei,
      },
    });

    return res.fixTreatmentCheckupIndividual?.id;
  }

  public setName(name: Name) {
    this.name = name;
    this.checkValidity();
  }

  public setMail(mail: string) {
    this.mail = mail;
  }

  public setNameRequired(required: boolean) {
    this.nameRequired = required;
    this.checkValidity();
  }

  public setCard(card: Card) {
    this.card = card;
  }

  public valid = false;
  public errors: ErrorInfo[] = [];

  private checkValidity() {
    runInAction(() => {
      if (!this.nameRequired) {
        this.valid = true;
        return;
      }

      this.errors = [];
      this.valid = false;
      if (!this.name) {
        this.errors = [{ field: 'sei', error: '氏名を入力してください。' }];
        return;
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.sei)) {
        this.errors = [...this.errors, { field: 'sei', error: '氏名は全角カタカナで入力してください。' }];
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.mei)) {
        this.errors = [...this.errors, { field: 'mei', error: '氏名は全角カタカナで入力してください。' }];
      }
      if (this.errors.length > 0) {
        return;
      }
      this.valid = true;
    });
  }

  public cardStatus: CreditCardStore = new CreditCardStore();
}
