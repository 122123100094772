import { useMemo } from 'react';

import { ErrorDialogContext } from '../../../../../../components/ErrorDialog';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { useReservationId } from '../../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { GuestWaitingListStore } from '../stores/GuestWaitingListStore';

export const useActions = (session: SessionStore) => {
  const { push } = useReservationId();
  const { setError } = useContext(ErrorDialogContext);

  const store = useMemo(() => {
    const res = new GuestWaitingListStore();
    res.userId = session.user?.userId;
    const search = new URL(window.location.href).searchParams;
    res.clinic = search.get('clinic') || '';
    res.department = search.get('department') || '';
    res.treatmentKind = search.get('treatmentKind') || '';
    res.start = search.get('start') || '';
    res.end = search.get('end') || '';
    return res;
  }, [session.user]);

  const handleError = (message: string) => {
    setError({
      header: '予約に失敗しました',
      message,
      onOk: () => {
        session.logout('/');
        setError();
      },
    });
  };

  const [load, loadStatus] = useTransaction(
    async () => {
      await session.fetchUserEntity();
      store.setNewComer(!session.user?.userEntity?.name);

      if (!store.newComer) {
        await doFix();
      }
    },
    e => {
      handleError(e.message);
    },
  );

  const [doFix, fixStatus] = useTransaction(
    () =>
      store.fix().then(() => {
        push(Routes.Paths.WaitingSuccess, store.id);
      }),
    e => {
      handleError(e.message);
    },
  );

  return {
    doFix,
    fixStatus,
    load,
    loadStatus,
    store,
  };
};
