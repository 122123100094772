import { useHistory, useRouteMatch } from 'react-router-dom';

function urlSafeEncode(str: string) {
  return str
    .split('')
    .map(char => char.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('');
}

function urlSafeDecode(hex: string) {
  return (
    hex
      .match(/.{2}/g)
      ?.map(char => String.fromCharCode(parseInt(char, 16)))
      .join('') || ''
  );
}

type Params = {
  treatmentId: string;
  subGroupId?: string;
};
export const useTreatmentId = () => {
  const {
    params: { treatmentId },
  } = useRouteMatch<Params>();
  const history = useHistory();
  const queryParam = new URLSearchParams(window.location.search).get('treatmentId');
  const param = treatmentId || queryParam;
  return {
    treatmentId: param ? urlSafeDecode(param) : '',
    encode: (id: string) => urlSafeEncode(id),
    push: (route: string, treatmentId?: string) =>
      treatmentId ? history.push(route.replace(':treatmentId', urlSafeEncode(treatmentId))) : history.push(route),
    decode: (id: string) => urlSafeDecode(id),
  };
};
