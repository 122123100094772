import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../src/components/Button';
import { Caption } from '../../../../src/components/Caption';
import { DocumentTitle } from '../../../../src/components/DocumentTitle';
import { Wrapper } from '../../../../src/components/Wrapper';
import { gutter } from '../../../../src/helpers/Style';
import { Routes } from '../../../constants/Routes';
import { Color } from '../../../constants/Style';
import { Image } from './components/image';
import { NewTab } from './components/image/newTab';
import { Safari } from './components/image/safari';
import { Yahoo } from './components/image/yahoo';

export const LineAuthFailed = observer(() => {
  const { push } = useHistory();
  return (
    <Wrapper>
      <DocumentTitle title="認証失敗時の案内" />
      <Caption type="header" align="center">
        LINE認証に失敗しました。
        <br />
        <span className={marginSmallStyle} />
        予約が完了しておりません。
      </Caption>
      <p className={marginMiddleStyle}>以下をご確認の上、再度ご予約をお試しください。</p>
      <div className={whiteStyle}>
        <div className={containerStyle}>
          <div className={blockStyle}>
            <h3>①デフォルトのブラウザアプリで開く</h3>
            <br />
            <p>メールアプリなどから閲覧していると、LINE認証がうまくできないことがあります</p>
          </div>
          <div className={blockStyle}>
            <h3>Gmail、slackなどで開いている場合</h3>
            <br />
            <div>
              右下に表示されている
              <span className={iconStyle}>
                <Safari />
              </span>
              マークを押して、Safariで再度お試しください。
            </div>
          </div>
          <div className={imageStyle}>
            <Image src="/images/line/safariTap.png" width={160} height={320} alt="safariTap" />
          </div>
          <div className={blockStyle}>
            <h3>Yahoo!メール等で開いている場合</h3>
            <br />
            <p className={tightStyle}>
              右下に表示されている
              <span className={iconStyle}>
                <Yahoo />
              </span>
              マークを押します。
            </p>
          </div>
          <div className={imageStyle}>
            <Image src="/images/line/yahooTap.png" width={160} height={320} alt="yahooTap" />
          </div>
          <div className={blockStyle}>
            <p>タップして「デフォルトのブラウザAppで開く」を選択</p>
          </div>
          <div className={imageMarginSmallStyle}>
            <Image src="/images/line/defaultApp.png" width={160} height={100} alt="defaultApp" />
          </div>
        </div>
      </div>
      <div className={whiteStyle}>
        <div className={containerStyle}>
          <div className={blockStyle}>
            <h3>方法２：シークレットモード・プライベートモードを解除する</h3>
            <p>1：iPhone で Safariを開きます。</p>
            <p>
              2：新規ページのボタン{' '}
              <span className={iconStyle}>
                <NewTab />
              </span>
              をタップします。
            </p>
          </div>
          <div className={imageStyle}>
            <Image src="/images/line/homepage.png" width={160} height={320} alt="homepage" />
          </div>
          <div className={blockStyle}>
            <p>3：「プライベート」をタップして開き、「○個のタブ」にチェックを入れて「完了」を押します。</p>
          </div>
          <div>
            <div className={imageMarginSmallStyle}>
              <Image src="/images/line/homepagePrivate.png" width={160} height={335} alt="homepagePrivate" />
            </div>
            <div className={imageMarginSmallStyle}>
              <Image src="/images/line/safariTab.png" width={160} height={120} alt="safariTab" />
            </div>
          </div>
        </div>
      </div>
      {/* ZenDesc導入次第実装 */}
      {/* <p className={attentionStyle}>
      上記の方法で解決できなかった場合、お手数をおかけしますが、こちらからお問い合わせください。
    </p> */}
      <Button ghost block onClick={() => push(Routes.Paths.Root)}>
        戻る
      </Button>
    </Wrapper>
  );
});

const marginSmallStyle = css`
  display: block;
  margin-bottom: ${gutter(3)};
`;

const marginMiddleStyle = css`
  margin-bottom: ${gutter(8)};
  text-align: center;
  letter-spacing: -1.1px;
`;

const whiteStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${gutter(10)};
  text-align: center;
  background: ${Color.GrayscaleWhite};
`;

const containerStyle = css`
  padding: ${gutter(4)};
  text-align: center;
`;

const imageStyle = css`
  display: inline-block;
  padding: ${gutter(4)};
  margin-bottom: ${gutter(15)};
`;

const imageMarginSmallStyle = css`
  display: inline-block;
  padding: ${gutter(4)};
`;

const blockStyle = css`
  margin-bottom: ${gutter(6)};
  text-align: left;
`;
const tightStyle = css`
  letter-spacing: -0.1px;
`;

const iconStyle = css`
  padding: ${gutter(2)};
  vertical-align: middle;
`;
