import { ErrorDialogContext } from '../../../../../../components/ErrorDialog';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { useReservationId } from '../../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { ReservationTemporallyStore } from '../stores/ReservationTemporallyStore';

export const useActions = (store: ReservationTemporallyStore, session: SessionStore) => {
  const { reservationId, push } = useReservationId();
  const { setError } = useContext(ErrorDialogContext);
  const handleError = (message: string) => {
    setError({
      header: '予約に失敗しました',
      message,
      onOk: () => {
        session.logout('/');
        setError();
      },
    });
  };

  const [load, loadStatus] = useTransaction(
    () =>
      store.fetch(reservationId).then(() => {
        if (store.fixable) {
          doFix();
        }
      }),
    e => {
      handleError(e.message);
    },
  );

  const [doFix, fixStatus] = useTransaction(
    () =>
      store.fix().then(res => {
        res && push(Routes.Paths.ReservationSuccess, res);
      }),
    e => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gqlError: { errors?: { message: string }[] } = e as any;
      handleError(gqlError.errors?.[0]?.message || e.message);
    },
  );

  return {
    doFix,
    fixStatus,
    load,
    loadStatus,
  };
};
