import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Caption, CaptionGroup } from '../../../../../../components/Caption';
import { Card } from '../../../../../../components/Card';
import { Icon } from '../../../../../../components/Icon';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Routes } from '../../../../../../constants/Routes';
import { Color, FontSize, IconSize } from '../../../../../../constants/Style';
import { square } from '../../../../../../helpers/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { SessionStore } from '../../../../../../stores/SessionStore';

type Props = {
  onNext?: () => void;
};

export const CheckupReservationsCourseSelectPage = ({ onNext }: Props) => {
  const { push } = useHistory();
  const sessionStore = useContext(SessionStore.Context);
  const accountRestricted = !!sessionStore.user?.userEntity?.accountRestricted;
  return (
    <Wrapper>
      <CaptionGroup>
        <Caption type="header">コースを選択してください</Caption>
      </CaptionGroup>
      <div className={gridStyle}>
        <Card shadowType="floating" onClick={() => !accountRestricted && onNext?.()} disabled={accountRestricted}>
          <div className={checkupCourseCardStyle.main}>
            <div className={checkupCourseCardStyle.header}>
              <div className={checkupCourseCardStyle.icon}>
                <Icon name="user" />
              </div>
              <Caption type="subheader">個人健診の方はこちら</Caption>
              <div className={checkupCourseCardStyle.icon}>
                <Icon name="angle-right" />
              </div>
            </div>
          </div>
        </Card>
        <Card
          shadowType="floating"
          onClick={() => push({ pathname: Routes.Paths.CheckupReservationNew, hash: 'codeInput' })}
        >
          <div className={checkupCourseCardStyle.main}>
            <div className={checkupCourseCardStyle.header}>
              <div className={checkupCourseCardStyle.icon}>
                <Icon name="building" />
              </div>
              <Caption type="subheader">企業健診の方はこちら</Caption>
              <div className={checkupCourseCardStyle.icon}>
                <Icon name="angle-right" />
              </div>
            </div>
          </div>
          <div>
            <div className={checkupCourseCardStyle.hint}>従業員コードをお持ちの方はこちらを選択してください</div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};

const checkupCourseCardStyle = {
  main: css`
    /* padding: 16px; */
  `,
  header: css`
    display: grid;
    grid-template-columns: 16px auto 16px;
    column-gap: 12px;
    align-items: center;
  `,
  icon: css`
    > svg {
      ${square(IconSize.Regular)}
    }
  `,
  hint: css`
    font-size: ${FontSize.Small};
    color: ${Color.GrayscaleNeutral};
  `,
};

const gridStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
`;
