import { css } from 'emotion';
import React from 'react';

import { Card } from '../../../../../domains/customer/Card';

type Props = {
  card: Card;
};

export const CardInfo = ({ card }: Props) => (
  <div className={cardGridStyle}>
    <div>カード番号</div>
    <div>**** **** **** {card.fourDigit}</div>
    <div>有効期限</div>
    <div>{card.expAt}</div>
  </div>
);

const cardGridStyle = css`
  display: grid;
  grid-template-columns: 90px 1fr;
`;
