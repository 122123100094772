import { TWITTER_URL } from '../../../constants/CheckupResult';
import { Result } from '../../../interfaces/Result';

type SNS = 'twitter' | 'facebook' | 'line';

export const switchTemplate = (result: Result, sns: SNS) => {
  const mention = '@clinic_ten';
  const wordBreak = '%0a';
  const link = 'https://clinicten.jp/checkup/  ';

  switch (result) {
    case 'A':
      return `＼今回の健康診断結果は「${result}判定」でした／${wordBreak}おめでとうございます！ 今後も食事、運動など健康な生活習慣を心がけましょう。${wordBreak}～待ち時間ほぼゼロの健康診断で、年に一度は健康状態チェックを～${wordBreak}${wordBreak}${link}${wordBreak}${mention}${wordBreak}${
        sns === 'twitter' ? TWITTER_URL[result] : ''
      }
      `;
    case 'B':
      return `＼今回の健康診断結果は「${result}判定」でした／${wordBreak}${result}判定の項目は、将来的に重大な病気のリスクになる可能性があるため、各検査結果を確認の上、生活習慣を改善しましょう。${wordBreak}～待ち時間ほぼゼロの健康診断で、年に一度は健康状態チェックを～${wordBreak}${link}${wordBreak}${mention}${wordBreak}${
        sns === 'twitter' ? TWITTER_URL[result] : ''
      }
      `;
    case 'C':
      return `＼今回の健康診断結果は「${result}判定」でした／${wordBreak}特に${result}判定の項目については、重大な病氣のリスクとなります。 追加検査が必要な場合があります。${wordBreak}～待ち時間ほぼゼロの健康診断で、年に一度は健康状態チェックを～${wordBreak}${link}${wordBreak}${mention} ${
        sns === 'twitter' ? TWITTER_URL[result] : ''
      }
        `;
    case 'D':
      return `＼今回の健康診断結果は「${result}判定」でした／${wordBreak}特に${result}判定の項目については、重大な病気が進行している可能性があります。追加検査や治療が必要な可能性があります。${wordBreak}～待ち時間ほぼゼロの健康診断で、年に一度は健康状態チェックを～${wordBreak}${link}${wordBreak}${mention} ${
        sns === 'twitter' ? TWITTER_URL[result] : ''
      }
      `;
    default:
      return '';
  }
};
