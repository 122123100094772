import { css } from 'emotion';
import React from 'react';

import { Environment } from '../../../../../../environments';

type Props = {
  id: string;
};

const style = css`
  > img {
    width: 52px;
    border-radius: 52px;
  }
`;

export const DoctorIcon = ({ id }: Props) => (
  <div className={style}>
    <img src={`${Environment.publicAssetUrl}/doctors/${id}.png`} alt="doctor" />
  </div>
);
