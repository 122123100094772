import { ClassNamesArg, css, cx } from 'emotion';
import React, { CSSProperties, ReactNode } from 'react';

import { BorderRadius, Breakpoint, Color, Duration } from '../../constants/Style';
import { gutter, toRGBA } from '../../helpers/Style';

type ShadowType = 'none' | 'floating';
type ColorType = 'normal' | 'inverted' | 'inset' | 'clinic' | 'dental' | 'disabled' | 'redNeutral' | 'warning';
type Props = Partial<{
  children: ReactNode;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  shadowType: ShadowType;
  color: ColorType;
  onClick: () => void;
  outlined?: boolean;
  outlineLight?: boolean;
  disabled?: boolean;
  smallPadding?: boolean;
  menuItem?: boolean;
  open?: boolean;
  className?: string;
  error?: boolean;
}>;

export const Card = ({
  children,
  width,
  height,
  shadowType = 'none',
  color = 'normal',
  onClick,
  outlined,
  outlineLight,
  disabled,
  smallPadding,
  menuItem,
  open,
  className,
  error,
}: Props) => (
  <article
    onClick={() => !disabled && onClick?.()}
    className={cx(
      baseStyle,
      shadowStyles[shadowType],
      colorStyles[color],
      outlined ? outlineStyle : '',
      outlineLight ? outlineLightStyle : '',
      disabled ? disabledStyle : '',
      smallPadding ? smallPaddingStyle : '',
      menuItem ? menuItemStyle : '',
      open ? openStyle : '',
      className,
      error ? errorStyle : '',
      onClick ? pointerStyle : '',
    )}
    style={{ width, height }}
  >
    {children}
  </article>
);

const baseStyle = css`
  max-width: 100%;
  padding: ${gutter(4)};
  border-radius: ${BorderRadius.Regular};
`;

const shadowStyles: Record<ShadowType, ClassNamesArg> = {
  none: css``,
  floating: css`
    box-shadow: 4px 8px 24px rgb(0 0 0 / 12%);
    transition: box-shadow ${Duration.Fade} ease-in;

    &:hover {
      box-shadow: 4px 4px 8px rgb(0 0 0 / 12%);
    }
  `,
} as const;

const colorStyles: Record<ColorType, ClassNamesArg> = {
  normal: css`
    background: ${Color.GrayscaleWhite};
  `,
  inverted: css`
    color: ${Color.GrayscaleWhite};
    background-color: ${Color.BrandTenBlack};
  `,
  inset: css`
    background: ${Color.GrayscaleLighter};
  `,
  clinic: css`
    background: ${Color.BrandTenBeige};
  `,
  dental: css`
    background: ${Color.BrandTenPink};
  `,
  disabled: css`
    background: ${Color.GrayscaleDark};
  `,
  redNeutral: css`
    background: ${toRGBA(Color.FunctionalRedNeutral, 0.1)};
  `,
  warning: css`
    color: ${Color.FunctionalOrangeNeutral};
    background: ${toRGBA(Color.FunctionalOrangeNeutral, 0.1)};
  `,
};

const openStyle = css`
  background: ${Color.GrayscaleLighter};
  border-radius: ${BorderRadius.Regular} ${BorderRadius.Regular} 0 0 !important;
`;
const errorStyle = css`
  box-shadow: 0 0 0 2px ${Color.FunctionalRedNeutral};
`;

const outlineStyle = css`
  border: 1px solid ${Color.GrayscaleDarker};
`;

const outlineLightStyle = css`
  border: 1px solid ${Color.GrayscaleLight};
`;

const disabledStyle = css`
  opacity: 0.5;

  /** disabled時はhoverを変えない */
  &:hover {
    box-shadow: 4px 8px 24px rgb(0 0 0 / 12%);
  }
`;
const smallPaddingStyle = css`
  @media (max-width: ${Breakpoint.ScreenXs}px) {
    padding: ${gutter(1)};
  }
`;

const menuItemStyle = css`
  article:nth-of-type(1) {
    border: 1px solid ${Color.GrayscaleLight};
    border-radius: ${BorderRadius.Regular};
  }

  border-top: none;

  &:not(:last-child) {
    border-radius: 0 !important;
  }

  border-radius: 0 0 ${BorderRadius.Regular} ${BorderRadius.Regular};
`;

const pointerStyle = css`
  cursor: pointer;
`;
