import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { ComponentProps } from 'react';

import {
  CheckupResultDisplayStrategy,
  CheckupResultItemSubGroupTypeFragment,
  CheckupResultJudgedSubGroupTypeFragment,
  CheckupResultSetStatus,
  TreatmentHistoryFragment,
} from '../../../../../gql/gql-types';
import { Result } from '../../../../interfaces/Result';
import { ElementOf, safeFilter } from '../../../../utils/CollectionUtil';
import { CriteriaCard } from '../../components/CriteriaCard';
import { PairCard } from '../../components/PairCard';
import { ResultAndValueCard } from '../../components/ResultAndValueCard';
import { ValueOnlyCard } from '../../components/ValueOnlyCard';

type ResultDisplayProps =
  | { strategy: CheckupResultDisplayStrategy.ValueOnly; props: ComponentProps<typeof ValueOnlyCard> }
  | { strategy: CheckupResultDisplayStrategy.ResultAndValue; props: ComponentProps<typeof ResultAndValueCard> }
  | { strategy: CheckupResultDisplayStrategy.Criteria; props: ComponentProps<typeof CriteriaCard> }
  | { strategy: CheckupResultDisplayStrategy.Pair; props: ComponentProps<typeof PairCard> };

type Item = ElementOf<NonNullable<NonNullable<CheckupResultJudgedSubGroupTypeFragment>['items']>>;

/**
 * カテゴリと、子要素となるItemを持つStore
 * 結果のトランザクションデータと、マスタデータ、そして過去履歴データを全て結合して持つ。
 */
export class CheckupResultSubGroupStore {
  constructor(
    public treatmentId: string,
    public subgroupResult: CheckupResultJudgedSubGroupTypeFragment,
    public subGroup: CheckupResultItemSubGroupTypeFragment,
    histories: TreatmentHistoryFragment[],
  ) {
    makeAutoObservable(this, {});
    this.histories = histories.map(x => new CheckupResultHistoryStore(x));
  }

  public get id() {
    return this.subGroup.id;
  }

  public get name() {
    return this.subGroup.name;
  }

  public get description() {
    return this.subGroup.description;
  }

  public get result() {
    return this.subgroupResult.result;
  }

  public histories: CheckupResultHistoryStore[] = [];

  public get sortedHistories() {
    return this.histories
      .filter(h => h.treatment.checkupAppointment?.result?.status === CheckupResultSetStatus.Sent)
      .slice(0)
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));
  }

  public get lastTreatment() {
    const index = this.sortedHistories.findIndex(x => x.id === this.treatmentId);
    return this.sortedHistories[index - 1];
  }

  public pastTreatment(length: number) {
    const index = this.sortedHistories.findIndex(x => x.id === this.treatmentId);
    return this.sortedHistories.slice(Math.max(0, index - length), index + 1);
  }

  public convertItemToValueOnlyProps(elem: Item): ResultDisplayProps {
    return {
      strategy: CheckupResultDisplayStrategy.ValueOnly,
      props: {
        unit: elem?.item?.unit || '',
        item: {
          description: elem?.item?.description || '',
          displayName: elem?.item?.displayName || '',
        },
        thisTime: elem?.valueStr || '',
        previous: this.lastTreatment?.getItem(elem?.itemId)?.valueStr || '',
        subGroupId: '',
      },
    };
  }

  public convertItemToResultAndValueProps(elem: Item): ResultDisplayProps {
    return {
      strategy: CheckupResultDisplayStrategy.ResultAndValue,
      props: {
        item: {
          displayName: elem?.item?.displayName || '',
          description: elem?.item?.description || '',
        },
        thisTime: elem?.valueStr || '',
        previous: this.lastTreatment?.getItem(elem?.itemId)?.valueStr || '',
        result: elem?.result as Result,
        previousResult: this.lastTreatment?.getItem(elem?.itemId)?.result as Result,
        subGroupId: '',
      },
    };
  }

  public convertItemToCriteriaProps(elem: Item): ResultDisplayProps {
    const criteriaBeforeData = safeFilter(
      this.pastTreatment(2).map(d => {
        const date = new Date(d.date);
        const pastItem = d.getItem(elem?.itemId);
        if (!pastItem) {
          return undefined;
        }
        return {
          name: format(date, 'yy/MM'),
          valueStr: Number(pastItem.valueStr),
          result: pastItem.result || '',
        };
      }),
    );
    return {
      strategy: CheckupResultDisplayStrategy.Criteria,
      props: {
        item: {
          description: elem?.item?.description || '',
          displayName: elem?.item?.displayName || '',
        },
        chartData: criteriaBeforeData,
        size: 'medium',
        valueStr: elem?.valueStr || '',
        result: elem?.result as Result,
        description: elem?.item?.description || undefined,
        criteria: safeFilter(elem?.item?.criteriaChart).map(c => ({
          max: c.max || 0,
          min: c.min || 0,
          result: c.result as Result,
        })),
        subGroupId: '',
      },
    };
  }

  public get cardProps(): ResultDisplayProps[] {
    return safeFilter(this.subgroupResult.items).reduce((prev, elem) => {
      if (!elem.item?.displayStrategy) {
        return prev;
      }
      switch (elem.item.displayStrategy) {
        case CheckupResultDisplayStrategy.ValueOnly:
          return [...prev, this.convertItemToValueOnlyProps(elem)];
        case CheckupResultDisplayStrategy.ResultAndValue:
          return [...prev, this.convertItemToResultAndValueProps(elem)];
        case CheckupResultDisplayStrategy.Criteria:
          return [...prev, this.convertItemToCriteriaProps(elem)];
        case CheckupResultDisplayStrategy.Pair:
          const last = this.lastTreatment;
          const otherHalfIndex = prev.findIndex(
            p => p.strategy === CheckupResultDisplayStrategy.Pair && p.props.item.displayName === elem.item?.pairName,
          );
          const otherHalf = prev[otherHalfIndex];

          if (!otherHalf) {
            const res3: ResultDisplayProps = {
              strategy: CheckupResultDisplayStrategy.Pair,
              props: {
                item: {
                  description: elem?.item?.description || '',
                  displayName: elem?.item?.displayName || '',
                },
                halfName: elem.item.halfName || '',
                nextHalfName: '',
                valueStr: elem.valueStr || '',
                nextValueStr: '',
                previousNextValueStr: '',
                previousValueStr: last?.getItem(elem.itemId)?.valueStr || '',
                subGroupId: '',
              },
            };
            return [...prev, res3];
          }

          return [
            ...prev.slice(0, otherHalfIndex),
            {
              strategy: CheckupResultDisplayStrategy.Pair,
              props: {
                ...otherHalf.props,
                nextHalfName: elem.item.halfName,
                nextValueStr: elem.valueStr,
                previousNextValueStr: last?.getItem(elem.itemId)?.valueStr || '',
              },
            },
            ...prev.slice(otherHalfIndex + 1),
          ] as ResultDisplayProps[];
        default:
          return prev;
      }
    }, new Array<ResultDisplayProps>());
  }
}

class CheckupResultHistoryStore {
  constructor(public treatment: TreatmentHistoryFragment) {
    makeAutoObservable(this, {});
  }

  getItem(itemId?: string) {
    return this.treatment.checkupAppointment?.result?.judged
      .flatMap(x => safeFilter(x.items))
      .find(x => x.itemId === itemId);
  }

  get date() {
    return this.treatment.date;
  }

  get id() {
    return this.treatment.id;
  }
}
