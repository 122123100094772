import { useMemo } from 'react';

/**
 * クエリパラメータをパースするhook
 */
export const useProfileAction = () =>
  useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('action');
  }, []);
