import { css } from 'emotion';
import React from 'react';

import { CheckupResultDisplayStrategy } from '../../../../../gql/gql-types';
import { gutter } from '../../../../helpers/Style';
import { CheckupResultSubGroupStore } from '../../Base/stores/CheckupResultSubGroupStore';
import { CriteriaCard } from '../CriteriaCard';
import { PairCard } from '../PairCard';
import { ResultAndValueCard } from '../ResultAndValueCard';
import { ValueOnlyCard } from '../ValueOnlyCard';

type Props = {
  store: CheckupResultSubGroupStore;
  line?: boolean;
};
export const StrategicResultItemCards = ({ store, line }: Props) => (
  <div className={line ? cardContainerLineStyle : cardContainerStyle}>
    {store.cardProps.map((card, key) => {
      switch (card.strategy) {
        case CheckupResultDisplayStrategy.ValueOnly:
          return (
            <ValueOnlyCard
              width={line ? undefined : fixedWidth}
              height={height}
              {...card.props}
              key={key}
              subGroupId={store.subGroup.id}
            />
          );
        case CheckupResultDisplayStrategy.ResultAndValue:
          return (
            <ResultAndValueCard
              {...card.props}
              width={line ? undefined : fixedWidth}
              height={height}
              key={key}
              subGroupId={store.subGroup.id}
            />
          );
        case CheckupResultDisplayStrategy.Criteria:
          return (
            <CriteriaCard
              {...card.props}
              width={line ? undefined : fixedWidth}
              height={height}
              size={
                store.cardProps.filter(c => c.strategy === CheckupResultDisplayStrategy.Criteria).length > 1
                  ? 'small'
                  : 'medium'
              }
              key={key}
              subGroupId={store.subGroup.id}
            />
          );
        case CheckupResultDisplayStrategy.Pair:
          return (
            <PairCard
              {...card.props}
              cardWidth={line ? undefined : fixedWidth}
              cardHeight={height}
              key={key}
              subGroupId={store.subGroup.id}
            />
          );
        default:
          return <></>;
      }
    })}
  </div>
);

const fixedWidth = 280;
const height = '100%';

const cardContainerStyle = css`
  display: flex;
  min-width: ${fixedWidth}px;
  overflow-x: scroll;

  > div > article {
    margin-right: ${gutter(4.25)};
  }
`;

const cardContainerLineStyle = css`
  min-width: ${fixedWidth}px;
`;
