import gql from 'graphql-tag';

export const CancelPoliciesType = gql`
  fragment CancelPoliciesType on TreatmentKind {
    cancelPolicies {
      cancelPenaltyPolicy {
        difference
        feeAmount
        timeToBan
        unit
      }
      cancelableUntil {
        difference
        unit
      }
      greetingText
      revisedAt
    }
  }
`;
