import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { UploadDialog } from '../../../../../../components/InterviewForm/Upload/UploadDialog';
import { UploadedInsurancePreviews } from '../../../../../../components/InterviewForm/Upload/UploadedInsuranceCardPreviews';
import { GtmClasses } from '../../../../../../constants/AnalyticsTags';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { InsuranceCardStore } from '../../../AccountEdit/components/InsuranceCardInput/stores/InsuranceCardStore';

type Props = {
  disabled?: boolean;
  store: InsuranceCardStore;
  confirm?: boolean;
};

export const InsuranceCardInput = observer(({ store, disabled, confirm }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [doUpload, uploadStatus] = useTransaction(async () => {
    await store.upload();
    setDialogOpen(false);
  });

  return (
    <div>
      <UploadDialog
        open={dialogOpen}
        onChange={f => store.setFiles(f)}
        onClose={() => setDialogOpen(false)}
        disabled={store.files.length === 0 || uploadStatus.running}
        onUpload={() => doUpload()}
        analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.insuranceCard.upload}
      />
      <UploadedInsurancePreviews
        disabled={disabled}
        files={store.urls.map(u => ({ filename: u.filename, fileKey: u.key, fileUrl: u.url }))}
        onClickUpload={() => setDialogOpen(true)}
        onDelete={key => store.removeKey(key)}
        confirm={confirm}
      />
    </div>
  );
});
