export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AbstractChatInput = {
  body: Scalars['String'];
  fromName: Scalars['String'];
  to: Scalars['String'];
  accountType: Scalars['String'];
  attachmentId?: Maybe<Scalars['String']>;
};

export type AccountLinkResult = {
  __typename?: 'AccountLinkResult';
  url: Scalars['String'];
};

export type AcPointDetail = {
  __typename?: 'AcPointDetail';
  acPointName?: Maybe<Scalars['String']>;
  acPoint?: Maybe<Scalars['Int']>;
};

export type AcPointDetailInput = {
  acPointName?: Maybe<Scalars['String']>;
  acPoint?: Maybe<Scalars['Int']>;
};

export type AcPointInformation = {
  __typename?: 'AcPointInformation';
  ttlPoint?: Maybe<Scalars['Int']>;
  acPointDetail?: Maybe<Array<Maybe<AcPointDetail>>>;
};

export type AcPointInformationInput = {
  ttlPoint?: Maybe<Scalars['Int']>;
  acPointDetail?: Maybe<Array<Maybe<AcPointDetailInput>>>;
};

export type AcSimulateResult = {
  __typename?: 'AcSimulateResult';
  acMoney?: Maybe<Scalars['Int']>;
  acPointInformation?: Maybe<AcPointInformation>;
};

export type AcSimulateResultInput = {
  acMoney?: Maybe<Scalars['Int']>;
  acPointInformation?: Maybe<AcPointInformationInput>;
};

export type ActivateScanner = {
  __typename?: 'ActivateScanner';
  id?: Maybe<Scalars['String']>;
};

export type ActivateScannerInput = {
  id: Scalars['String'];
};

export type AnswerChoiceResult = {
  __typename?: 'AnswerChoiceResult';
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  exit?: Maybe<Scalars['String']>;
};

export type AnswerInput = {
  id: Scalars['String'];
  answer: Array<AnswerValueInput>;
};

export type AnswerInterviewInput = {
  treatmentId: Scalars['String'];
  answers: Array<AnswerInput>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
};

export type AnswerResult = {
  __typename?: 'AnswerResult';
  answer?: Maybe<Scalars['String']>;
  option?: Maybe<QuestionOption>;
};

export enum AnswerType {
  Input = 'INPUT',
  Number = 'NUMBER',
  Date = 'DATE',
  Fever = 'FEVER'
}

export type AnswerValueInput = {
  id: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type AssignedRoom = {
  __typename?: 'AssignedRoom';
  roomVacancy?: Maybe<Array<Maybe<RoomVacancy>>>;
};

export type AssignedRoomInput = {
  roomVacancy?: Maybe<Array<Maybe<RoomVacancyInput>>>;
};

/** 担当スタッフ情報 */
export type AssignedStaff = {
  __typename?: 'AssignedStaff';
  staff?: Maybe<Staff>;
  anyone?: Maybe<Scalars['Boolean']>;
  fill?: Maybe<Scalars['Boolean']>;
  staffVacancy?: Maybe<Array<Maybe<StaffVacancy>>>;
};

export type AssignedStaffInput = {
  staff?: Maybe<Scalars['String']>;
  anyone?: Maybe<Scalars['Boolean']>;
  fill?: Maybe<Scalars['Boolean']>;
  staffVacancy?: Maybe<Array<Maybe<StaffCVacancyInput>>>;
};

export type AssignedStaffToVacancyInput = {
  staffId?: Maybe<Scalars['String']>;
  staffVacancy?: Maybe<Array<Maybe<StaffCVacancyInput>>>;
};

export type AsyncJob = {
  __typename?: 'AsyncJob';
  status: AsyncJobStatus;
  reason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  finished?: Maybe<Scalars['Boolean']>;
};

export enum AsyncJobStatus {
  ACreated = 'A_CREATED',
  BStarted = 'B_STARTED',
  CCompleted = 'C_COMPLETED',
  DFailed = 'D_FAILED'
}

export type AsyncProcessResult = {
  __typename?: 'AsyncProcessResult';
  uuid?: Maybe<Scalars['String']>;
};

export type AsyncProcessResultInput = {
  uuid?: Maybe<Scalars['String']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  treatment?: Maybe<Treatment>;
  checkupOrganizationEvent?: Maybe<CheckupOrganizationEvent>;
  key: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<AttachmentType>;
};

export enum AttachmentType {
  Receipt = 'RECEIPT',
  Free = 'FREE',
  Checkup = 'CHECKUP',
  CheckupManager = 'CHECKUP_MANAGER',
  Chat = 'CHAT'
}

export type AttachmentUrl = {
  __typename?: 'AttachmentUrl';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type AuditLogQueryIdInput = {
  id: Scalars['String'];
};

export type AuditLogQueryResult = {
  __typename?: 'AuditLogQueryResult';
  owner?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AuditLogQueryResults = {
  __typename?: 'AuditLogQueryResults';
  results: Array<AuditLogQueryResult>;
};

export type AutoNumber = {
  __typename?: 'AutoNumber';
  clinic: Scalars['String'];
  schema: Scalars['String'];
  current: Scalars['Int'];
  digits: Scalars['Int'];
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type BankInfo = {
  __typename?: 'BankInfo';
  bankCode: Scalars['String'];
  bankBranchCode: Scalars['String'];
  accountType: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type BankInfoInput = {
  bankCode: Scalars['String'];
  bankBranchCode: Scalars['String'];
  accountType: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type Block = {
  __typename?: 'Block';
  clinic: Scalars['String'];
  date: Scalars['String'];
  lane: Scalars['String'];
  start: Scalars['String'];
  roomType?: Maybe<Scalars['String']>;
  roomNo?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
};

export type BulkReceiptDownloadInput = {
  ids: Array<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
};

export type CancelableCondition = {
  __typename?: 'CancelableCondition';
  unit: CancelPenaltyConditionDiffUnit;
  difference: Scalars['Int'];
};

export type CancelableConditionInput = {
  unit: CancelPenaltyConditionDiffUnit;
  difference: Scalars['Int'];
};

export type CancelCheckupInput = {
  appointmentId?: Maybe<Scalars['String']>;
};

export type CancelCheckupReservationInput = {
  id: Scalars['String'];
};

export type CancelPaymentInput = {
  date: Scalars['String'];
  time: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  department: Scalars['String'];
  executionId: Scalars['String'];
};

/** 30分前「から」5500円の罰金など、最も早いタイミングを表現する */
export type CancelPenaltyCondition = {
  __typename?: 'CancelPenaltyCondition';
  unit: CancelPenaltyConditionDiffUnit;
  difference: Scalars['Int'];
  feeAmount?: Maybe<Scalars['Int']>;
  timeToBan?: Maybe<Scalars['Int']>;
};

export enum CancelPenaltyConditionDiffUnit {
  Minutes = 'MINUTES',
  Days = 'DAYS'
}

export type CancelPenaltyConditionInput = {
  unit: CancelPenaltyConditionDiffUnit;
  difference: Scalars['Int'];
  feeAmount?: Maybe<Scalars['Int']>;
  timeToBan?: Maybe<Scalars['Int']>;
};

export type CancelPolicy = {
  __typename?: 'CancelPolicy';
  /** 30分前から5500円の罰金など */
  cancelPenaltyPolicy: Array<CancelPenaltyCondition>;
  /** 前日までにしてください、など */
  cancelableUntil: CancelableCondition;
  /** 患者様お一人毎に診療室をご用意しお待ちしています、など */
  greetingText?: Maybe<Scalars['String']>;
  /** 版 */
  revisedAt: Scalars['String'];
};

export type CancelPolicyInput = {
  /** 患者様お一人毎に診療室をご用意しお待ちしています、など */
  greetingText?: Maybe<Scalars['String']>;
  /** 30分前から5500円の罰金など */
  cancelPenaltyPolicy: Array<CancelPenaltyConditionInput>;
  /** 前日までにしてください、など */
  cancelableUntil: CancelableConditionInput;
  /** 版 */
  revisedAt: Scalars['String'];
};

export type CancelTreatmentInput = {
  id: Scalars['ID'];
  date: Scalars['String'];
  clinic: Scalars['String'];
  cancelReason?: Maybe<TreatmentCancelReason>;
};

export type CancelVacancyNew = {
  __typename?: 'CancelVacancyNew';
  date?: Maybe<Scalars['String']>;
};

export type CancelVacancyNewInput = {
  assignedRoom?: Maybe<Array<Maybe<RoomVacancyInput>>>;
  assignedStaff?: Maybe<Array<Maybe<AssignedStaffToVacancyInput>>>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  customer: Customer;
  fourDigit: Scalars['String'];
  brand: Scalars['String'];
  payjpID: Scalars['String'];
  expAt: Scalars['String'];
};

export type CardConfirmation = {
  __typename?: 'CardConfirmation';
  success: Scalars['Boolean'];
  message?: Maybe<CardError>;
};

export type CardConfirmInfoInput = {
  card: Scalars['String'];
  clinicId: Scalars['String'];
};

export type CardError = {
  __typename?: 'CardError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ChatPresignedPostQueryInput = {
  userId: Scalars['String'];
  fileName: Scalars['String'];
};

export enum ChatTool {
  Slack = 'SLACK'
}

export enum ChatWorkspaceType {
  Slack = 'SLACK',
  Email = 'EMAIL'
}

export type CheckupAppointment = {
  __typename?: 'CheckupAppointment';
  id: Scalars['ID'];
  user: User;
  birthDate: Scalars['String'];
  address: Scalars['String'];
  plan: CheckupPlan;
  kitDeliverStatus: CheckupKitDeliverStatus;
  kitDeliverDate?: Maybe<Scalars['String']>;
  treatment: ModelTreatmentConnection;
  treatmentStatus: TreatmentStatus;
  planPrice: Scalars['Int'];
  planDiscountAmount?: Maybe<DiscountAmount>;
  planBillType?: Maybe<Scalars['String']>;
  optionPrices: Array<CheckupOptionPrice>;
  checkupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  createdAt?: Maybe<Scalars['String']>;
  result?: Maybe<CheckupResultSet>;
};


export type CheckupAppointmentTreatmentArgs = {
  filter?: Maybe<ModelTreatmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CheckupAppointmentEntityInput = {
  appointmentId?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  birthDate: Scalars['String'];
  planId?: Maybe<Scalars['String']>;
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkupOrganizationMemberEventId?: Maybe<Scalars['String']>;
};

export type CheckupAppointmentOrganizationFixInput = {
  id: Scalars['String'];
  preInterview: CreateInterviewInput;
};

export type CheckupAppointmentOrganizationTemporallyInput = {
  checkupTreatment: CreateTreatmentInput;
  dentalTreatment?: Maybe<CreateTreatmentInput>;
  interview: CreateInterviewInput;
  appointment: CheckupAppointmentEntityInput;
  eventId: Scalars['String'];
};

export type CheckupCriteria = {
  __typename?: 'CheckupCriteria';
  order: Scalars['Int'];
  conditions: Array<CheckupCriteriaCondition>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export enum CheckupCriteriaComparator {
  Eq = 'EQ',
  Ge = 'GE',
  Le = 'LE',
  Gt = 'GT',
  Lt = 'LT',
  Ne = 'NE'
}

export type CheckupCriteriaCondition = {
  __typename?: 'CheckupCriteriaCondition';
  left: Scalars['String'];
  comparator: CheckupCriteriaComparator;
  rightStr?: Maybe<Scalars['String']>;
  rightNum?: Maybe<Scalars['Float']>;
};

export type CheckupCriteriaConditionInput = {
  left: Scalars['String'];
  comparator: CheckupCriteriaComparator;
  rightStr?: Maybe<Scalars['String']>;
  rightNum?: Maybe<Scalars['Float']>;
};

export type CheckupCriteriaInput = {
  order: Scalars['Int'];
  conditions: Array<CheckupCriteriaConditionInput>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type CheckupDownloadableLinkQueryInput = {
  checkupOrganizationEventId: Scalars['String'];
  fileName: Scalars['String'];
};

export type CheckupInterviewCondition = {
  __typename?: 'CheckupInterviewCondition';
  plan?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type CheckupInterviewConditionInput = {
  plan?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type CheckupInterviewInput = {
  plan: Scalars['String'];
  timing: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type CheckupInterviewQuestion = {
  __typename?: 'CheckupInterviewQuestion';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<QuestionOption>>>;
  confirm_text?: Maybe<Scalars['String']>;
  additional_column_name?: Maybe<Scalars['String']>;
  additional_type?: Maybe<Scalars['String']>;
  /**
   * BeforeReserving: '1_BEFORE_RESERVING',
   * AfterReserving: '2_AFTER_RESERVING',
   */
  timing: Scalars['String'];
  condition: Array<CheckupInterviewCondition>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type CheckupInvitationInput = {
  checkupOrganizationMemberEventIds: Array<Scalars['String']>;
  chatWorkspaceTypes: Array<ChatWorkspaceType>;
  templateId?: Maybe<Scalars['String']>;
  remindTemplateId?: Maybe<Scalars['String']>;
};

export enum CheckupKitDeliverStatus {
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
  DependOnEvent = 'DEPEND_ON_EVENT'
}

export type CheckupMemberOptionBillingPolicyInput = {
  billingType: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
};

export type CheckupOption = {
  __typename?: 'CheckupOption';
  id: Scalars['ID'];
  label: Scalars['String'];
  requiredMin: Scalars['Int'];
  clinicType?: Maybe<ClinicType>;
  regularPrice: Scalars['Int'];
  description: Scalars['String'];
  gender: Scalars['String'];
  aboutDisplay: Scalars['Boolean'];
  annotation?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<TreatmentKind>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  recommendLogic?: Maybe<Array<CheckupOptionRecommendLogic>>;
  subTitle?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
  /** 新空き枠用 */
  operation?: Maybe<Operation>;
};

export type CheckupOptionDiscountPrice = {
  __typename?: 'CheckupOptionDiscountPrice';
  optionId: Scalars['String'];
  price: Scalars['Int'];
  regularPrice: Scalars['Int'];
  amount?: Maybe<DiscountAmount>;
};

export type CheckupOptionIndividual = {
  __typename?: 'CheckupOptionIndividual';
  id: Scalars['ID'];
  option: CheckupOption;
  terms: Array<CheckupOptionIndividualTerm>;
};

export type CheckupOptionIndividualTerm = {
  __typename?: 'CheckupOptionIndividualTerm';
  price: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CheckupOptionIndividualTermInput = {
  price: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CheckupOptionPrice = {
  __typename?: 'CheckupOptionPrice';
  optionId: Scalars['String'];
  price: Scalars['Int'];
  option?: Maybe<CheckupOption>;
  discountAmount?: Maybe<DiscountAmount>;
  billType?: Maybe<Scalars['String']>;
};

export type CheckupOptionPriceInput = {
  optionId: Scalars['String'];
  price: Scalars['Int'];
};

export enum CheckupOptionRecommendComparator {
  Eq = 'EQ',
  Ge = 'GE',
  Le = 'LE',
  Gt = 'GT',
  Lt = 'LT',
  Ne = 'NE'
}

export type CheckupOptionRecommendCondition = {
  __typename?: 'CheckupOptionRecommendCondition';
  questionId: Scalars['String'];
  comparator: CheckupOptionRecommendComparator;
  answerValue?: Maybe<Scalars['String']>;
};

export type CheckupOptionRecommendConditionInput = {
  questionId: Scalars['String'];
  comparator: CheckupOptionRecommendComparator;
  answerValue?: Maybe<Scalars['String']>;
};

export type CheckupOptionRecommendLogic = {
  __typename?: 'CheckupOptionRecommendLogic';
  conditions: Array<CheckupOptionRecommendCondition>;
  severity?: Maybe<CheckupOptionRecommendSeverity>;
  message: Scalars['String'];
};

export type CheckupOptionRecommendLogicInput = {
  conditions: Array<CheckupOptionRecommendConditionInput>;
  severity?: Maybe<CheckupOptionRecommendSeverity>;
  message: Scalars['String'];
};

export enum CheckupOptionRecommendSeverity {
  Info = 'INFO',
  Danger = 'DANGER'
}

export type CheckupOptionSuggestion = {
  __typename?: 'CheckupOptionSuggestion';
  optionId: Scalars['String'];
  text: Scalars['String'];
  severity?: Maybe<Scalars['String']>;
};

export type CheckupOptionSuggestionInput = {
  id: Scalars['Int'];
  value: Scalars['String'];
  label: Scalars['String'];
};

/**
 * 健診組織マスタ。
 * 営業が作成する。
 */
export type CheckupOrganization = {
  __typename?: 'CheckupOrganization';
  /** UUID(ログインID) */
  id: Scalars['ID'];
  /** 企業名 */
  name: Scalars['String'];
  /** 担当者氏名 */
  staffName: Scalars['String'];
  /** 担当者電話番号 */
  staffTel: Scalars['String'];
  /** 担当者メールアドレス */
  staffEmail: Scalars['String'];
  /** 年度の配列 */
  events: ModelCheckupOrganizationEventConnection;
  /** slack連携情報 */
  chatWorkspaces: ModelCheckupOrganizationChatWorkspaceConnection;
  /** ユーザー発行タイムスタンプ */
  userCreatedAt?: Maybe<Scalars['String']>;
  /** 使用停止フラグ */
  hidden?: Maybe<Scalars['Boolean']>;
  /** 期首 */
  yearBeginning: CheckupYearBeginning;
  /** 営業コメント */
  salesMemo?: Maybe<Scalars['String']>;
  /** 会社ID(kintone) */
  customerNo?: Maybe<Scalars['String']>;
};


/**
 * 健診組織マスタ。
 * 営業が作成する。
 */
export type CheckupOrganizationEventsArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/**
 * 健診組織マスタ。
 * 営業が作成する。
 */
export type CheckupOrganizationChatWorkspacesArgs = {
  filter?: Maybe<ModelCheckupOrganizationChatWorkspaceFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CheckupOrganizationChatWorkspace = {
  __typename?: 'CheckupOrganizationChatWorkspace';
  checkupOrganizationId: Scalars['String'];
  workspaceId: Scalars['String'];
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  type?: Maybe<ChatWorkspaceType>;
  accessToken?: Maybe<Scalars['String']>;
};

/** 健診年度 */
export type CheckupOrganizationEvent = {
  __typename?: 'CheckupOrganizationEvent';
  /** UUID */
  id: Scalars['ID'];
  /** 名称（「2022年度」等） */
  name: Scalars['String'];
  /**
   * 年度（2022等）
   * 年度の生成時にシーケンスとして利用する
   */
  year: Scalars['Int'];
  /** 年度 + 期首で算出した開始日 */
  start: Scalars['String'];
  /** 年度 + 期首で算出した終了日 */
  end: Scalars['String'];
  /** 企業とのリレーション */
  organization?: Maybe<CheckupOrganization>;
  /** 「受診者」とのリレーション */
  members: ModelCheckupOrganizationMemberEventConnection;
  /** オプション全量＋負担者設定の「デフォルト」 */
  options: ModelCheckupOrganizationEventOptionConnection;
  createdAt: Scalars['String'];
  /** プランの「デフォルト」 */
  plan?: Maybe<CheckupPlan>;
  /** プラン負担者設定の「デフォルト」 */
  planBillType: Scalars['String'];
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプの「デフォルト」 */
  kitDeliverType: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先の住所 */
  address: Scalars['String'];
  /** 希望開始月（年月） */
  desiredStartTerm: Scalars['String'];
  /** 希望終了月（年月） */
  desiredEndTerm: Scalars['String'];
  /** メモ */
  notedByOrganization?: Maybe<Scalars['String']>;
  /** 社内メモ */
  notedByAdmin?: Maybe<Scalars['String']>;
  /** リマインドテンプレートの「マスタ」の配列 */
  templates: ModelCheckupOrganizationEventTemplateV2Connection;
  /** 年度単位の添付ファイルの配列（厚労省提出資料等） */
  attachments: ModelAttachmentConnection;
  /** 適用済みディスカウント */
  discountCode?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  startMonth?: Maybe<Scalars['Int']>;
  /** 組合 */
  union?: Maybe<Scalars['String']>;
};


/** 健診年度 */
export type CheckupOrganizationEventMembersArgs = {
  filter?: Maybe<ModelCheckupOrganizationMemberEventFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/** 健診年度 */
export type CheckupOrganizationEventOptionsArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventOptionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/** 健診年度 */
export type CheckupOrganizationEventTemplatesArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventTemplateV2FilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/** 健診年度 */
export type CheckupOrganizationEventAttachmentsArgs = {
  filter?: Maybe<ModelAttachmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CheckupOrganizationEventOption = {
  __typename?: 'CheckupOrganizationEventOption';
  id: Scalars['ID'];
  event?: Maybe<CheckupOrganizationEvent>;
  option?: Maybe<CheckupOption>;
  billType: Scalars['String'];
  price: Scalars['Int'];
};

/** リマインド・案内テンプレート */
export type CheckupOrganizationEventTemplate = {
  __typename?: 'CheckupOrganizationEventTemplate';
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  event?: Maybe<CheckupOrganizationEvent>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindSchedule>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachment>;
};

export type CheckupOrganizationEventTemplateAttachment = {
  __typename?: 'CheckupOrganizationEventTemplateAttachment';
  key: Scalars['String'];
  fileName: Scalars['String'];
};

export type CheckupOrganizationEventTemplateAttachmentInput = {
  key: Scalars['String'];
  fileName: Scalars['String'];
};

/** リマインド・案内テンプレート */
export type CheckupOrganizationEventTemplateV2 = {
  __typename?: 'CheckupOrganizationEventTemplateV2';
  id: Scalars['ID'];
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  event?: Maybe<CheckupOrganizationEvent>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindSchedule>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachment>;
};

/** 「受診者」 */
export type CheckupOrganizationMemberEvent = {
  __typename?: 'CheckupOrganizationMemberEvent';
  /** UID 「受診者番号」 */
  id: Scalars['ID'];
  /** 別システムでの一意キー */
  employeeNo?: Maybe<Scalars['String']>;
  /** 所属する年度 */
  event?: Maybe<CheckupOrganizationEvent>;
  checkupOrganizationId?: Maybe<Scalars['String']>;
  /** 氏名 */
  name: Scalars['String'];
  /** メールアドレス + slackと照合する用のキー */
  email: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先住所 */
  address: Scalars['String'];
  /** 初回案内送付日時（= 確定日時） */
  invitedAt?: Maybe<Scalars['String']>;
  /** 利用しない */
  treatment: ModelTreatmentConnection;
  /** チャットツール上のハンドルネーム */
  chatName?: Maybe<Scalars['String']>;
  /** チャットツール上のID */
  chatId?: Maybe<Scalars['String']>;
  /** 実際に受診するプラン */
  plan?: Maybe<CheckupPlan>;
  /** プラン負担者 */
  planBillType?: Maybe<Scalars['String']>;
  /** 予約情報 */
  appointments: ModelCheckupAppointmentConnection;
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプ */
  kitDeliverType?: Maybe<Scalars['String']>;
  nextRemindOn?: Maybe<Scalars['String']>;
  /** 利用するテンプレート */
  template?: Maybe<CheckupOrganizationEventTemplateV2>;
  /** スケジュール設定（個別） */
  schedule?: Maybe<CheckupRemindSchedule>;
  /** 前回リマインド日 */
  recentRemindedAt?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  /** 受診予定期間 */
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  /** 事業所 */
  office?: Maybe<Scalars['String']>;
  /** 部門 */
  department?: Maybe<Scalars['String']>;
  /** 雇入年月日 */
  hiringDate?: Maybe<Scalars['String']>;
};


/** 「受診者」 */
export type CheckupOrganizationMemberEventTreatmentArgs = {
  filter?: Maybe<ModelTreatmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/** 「受診者」 */
export type CheckupOrganizationMemberEventAppointmentsArgs = {
  filter?: Maybe<ModelCheckupAppointmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CheckupOrganizationMemberEventInput = {
  id: Scalars['ID'];
  confirmedName: Scalars['String'];
  confirmedAddress: Scalars['String'];
  receiveStatus: Scalars['String'];
  hiringDate?: Maybe<Scalars['String']>;
};

export type CheckupPlan = {
  __typename?: 'CheckupPlan';
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentKindId?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<TreatmentKind>;
  order?: Maybe<Scalars['Int']>;
  hideForOrganization?: Maybe<Scalars['Boolean']>;
  hideForIndividual?: Maybe<Scalars['Boolean']>;
  urinalysisKit?: Maybe<Scalars['Boolean']>;
  stoolKit?: Maybe<Scalars['Boolean']>;
};

export type CheckupPlanDiscountPrice = {
  __typename?: 'CheckupPlanDiscountPrice';
  planId: Scalars['String'];
  price: Scalars['Int'];
  regularPrice: Scalars['Int'];
  amount?: Maybe<DiscountAmount>;
};

export type CheckupPlanIndividual = {
  __typename?: 'CheckupPlanIndividual';
  id: Scalars['ID'];
  plan: CheckupPlan;
  terms: Array<CheckupPlanIndividualTerm>;
  order?: Maybe<Scalars['Int']>;
};

export type CheckupPlanIndividualTerm = {
  __typename?: 'CheckupPlanIndividualTerm';
  price: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CheckupPlanIndividualTermInput = {
  price: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CheckupPlanPrice = {
  __typename?: 'CheckupPlanPrice';
  planId: Scalars['String'];
  price: Scalars['Int'];
};

export type CheckupPresignedPostQueryInput = {
  checkupOrganizationId: Scalars['String'];
  checkupOrganizationEventId: Scalars['String'];
  fileName: Scalars['String'];
};

/** リマインドのストラテジ */
export type CheckupRemindSchedule = {
  __typename?: 'CheckupRemindSchedule';
  /** ストラテジ */
  interval: CheckupRemindScheduleInterval;
  cycle: Scalars['Int'];
};

export type CheckupRemindScheduleInput = {
  /** ストラテジ */
  interval: CheckupRemindScheduleInterval;
  cycle: Scalars['Int'];
};

export enum CheckupRemindScheduleInterval {
  Week = 'WEEK',
  Month = 'MONTH',
  Day = 'DAY'
}

/** 健診結果の生データ */
export type CheckupResult = {
  __typename?: 'CheckupResult';
  patientNo: Scalars['String'];
  patientName?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  label: Scalars['String'];
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  status: CheckupResultStatus;
  dataSource: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type CheckupResultCriteriaChartDef = {
  __typename?: 'CheckupResultCriteriaChartDef';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['String']>;
};

export type CheckupResultCriteriaChartDefInput = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['String']>;
};

export enum CheckupResultDisplayStrategy {
  Criteria = 'CRITERIA',
  ResultAndValue = 'RESULT_AND_VALUE',
  ValueOnly = 'VALUE_ONLY',
  Pair = 'PAIR'
}

export type CheckupResultDoctor = {
  __typename?: 'CheckupResultDoctor';
  inCharge?: Maybe<CheckupResultDoctorSet>;
  commenter?: Maybe<CheckupResultDoctorSet>;
};

export type CheckupResultDoctorInput = {
  inCharge?: Maybe<CheckupResultDoctorSetInput>;
  commenter?: Maybe<CheckupResultDoctorSetInput>;
};

export type CheckupResultDoctorSet = {
  __typename?: 'CheckupResultDoctorSet';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckupResultDoctorSetInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckupResultItem = {
  __typename?: 'CheckupResultItem';
  id: Scalars['ID'];
  subTitle?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  mappingName?: Maybe<Scalars['String']>;
  mappingStrategy: CheckupResultItemMappingStrategy;
  unit?: Maybe<Scalars['String']>;
  criteria: Array<CheckupCriteria>;
  order: Scalars['Int'];
  group?: Maybe<CheckupResultItemSubGroup>;
  reference?: Maybe<Scalars['String']>;
  ignore?: Maybe<Scalars['Boolean']>;
  displayStrategy?: Maybe<CheckupResultDisplayStrategy>;
  criteriaChart?: Maybe<Array<Maybe<CheckupResultCriteriaChartDef>>>;
  pairName?: Maybe<Scalars['String']>;
  halfName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nurse?: Maybe<Scalars['Boolean']>;
  doctor?: Maybe<Scalars['Boolean']>;
  formQuestion?: Maybe<Question>;
};

export type CheckupResultItemGroup = {
  __typename?: 'CheckupResultItemGroup';
  id: Scalars['ID'];
  order: Scalars['Int'];
  friendlyName: Scalars['String'];
  technicalName: Scalars['String'];
  subGroups: ModelCheckupResultItemSubGroupConnection;
};


export type CheckupResultItemGroupSubGroupsArgs = {
  filter?: Maybe<ModelCheckupResultItemSubGroupFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum CheckupResultItemMappingStrategy {
  String = 'STRING',
  Number = 'NUMBER'
}

export type CheckupResultItemSubGroup = {
  __typename?: 'CheckupResultItemSubGroup';
  id: Scalars['ID'];
  order: Scalars['Int'];
  group?: Maybe<CheckupResultItemGroup>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  items: ModelCheckupResultItemConnection;
  strategy: CheckupResultSubGroupJudgeStrategy;
  conditions?: Maybe<Array<Maybe<CheckupResultSubGroupCondition>>>;
  comments?: Maybe<Array<Maybe<CheckupResultSubGroupCommentSet>>>;
};


export type CheckupResultItemSubGroupItemsArgs = {
  filter?: Maybe<ModelCheckupResultItemFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CheckupResultJudged = {
  __typename?: 'CheckupResultJudged';
  itemId: Scalars['String'];
  item?: Maybe<CheckupResultItem>;
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['String']>;
  doctor?: Maybe<Doctor>;
};

export type CheckupResultJudgedInput = {
  itemId: Scalars['String'];
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['String']>;
};

export type CheckupResultJudgedSubGroup = {
  __typename?: 'CheckupResultJudgedSubGroup';
  subGroupId: Scalars['String'];
  subGroup?: Maybe<CheckupResultItemSubGroup>;
  items?: Maybe<Array<Maybe<CheckupResultJudged>>>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  department?: Maybe<Department>;
};

export type CheckupResultJudgedSubGroupInput = {
  subGroupId: Scalars['String'];
  items: Array<CheckupResultJudgedInput>;
  result: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type CheckupResultMappingJobInput = {
  statusIn?: Maybe<Array<CheckupResultStatus>>;
};

export type CheckupResultRawData = {
  __typename?: 'CheckupResultRawData';
  itemId: Scalars['String'];
  item?: Maybe<CheckupResultItem>;
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type CheckupResultRawDataInput = {
  itemId: Scalars['String'];
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

/** 健診結果表データ */
export type CheckupResultSet = {
  __typename?: 'CheckupResultSet';
  /** CheckupAppointmentのID */
  id: Scalars['ID'];
  checkupAppointment?: Maybe<CheckupAppointment>;
  /** 取り込んだローデータ */
  raw: Array<CheckupResultRawData>;
  /** 手入力データ */
  override: Array<CheckupResultRawData>;
  /** 判定済みのデータ */
  judged: Array<CheckupResultJudgedSubGroup>;
  /** 総合判定 */
  summary?: Maybe<CheckupResultSummary>;
  /** 担当医の名前 */
  doctor?: Maybe<CheckupResultDoctor>;
  /** 検査日 */
  date: Scalars['String'];
  status: CheckupResultSetStatus;
  userId?: Maybe<Scalars['String']>;
};

export enum CheckupResultSetStatus {
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
  Ignore = 'IGNORE'
}

export type CheckupResultSheetPreview = {
  __typename?: 'CheckupResultSheetPreview';
  data: Scalars['String'];
};

export enum CheckupResultStatus {
  NotMapped = 'NOT_MAPPED',
  Mapped = 'MAPPED',
  MappingFailed = 'MAPPING_FAILED',
  Ignored = 'IGNORED',
  UnknownItem = 'UNKNOWN_ITEM'
}

export type CheckupResultSubGroupCommentSet = {
  __typename?: 'CheckupResultSubGroupCommentSet';
  result: Scalars['String'];
  comment: Scalars['String'];
  departmentId?: Maybe<Scalars['String']>;
};

export type CheckupResultSubGroupCommentSetInput = {
  result: Scalars['String'];
  comment: Scalars['String'];
  departmentId?: Maybe<Scalars['String']>;
};

export type CheckupResultSubGroupCondition = {
  __typename?: 'CheckupResultSubGroupCondition';
  order: Scalars['Int'];
  result: Scalars['String'];
  comment: Scalars['String'];
  departmentId?: Maybe<Scalars['String']>;
  criteria: Array<CheckupResultSubGroupConditionCriteria>;
};

export type CheckupResultSubGroupConditionCriteria = {
  __typename?: 'CheckupResultSubGroupConditionCriteria';
  leftItemId?: Maybe<Scalars['String']>;
  leftField?: Maybe<CheckupResultSubGroupConditionCriteriaField>;
  comparator?: Maybe<Comparator>;
  rightValue?: Maybe<Scalars['String']>;
};

export enum CheckupResultSubGroupConditionCriteriaField {
  Result = 'RESULT',
  Value = 'VALUE'
}

export type CheckupResultSubGroupConditionCriteriaInput = {
  leftItemId?: Maybe<Scalars['String']>;
  leftField?: Maybe<CheckupResultSubGroupConditionCriteriaField>;
  comparator?: Maybe<Comparator>;
  rightValue?: Maybe<Scalars['String']>;
};

export type CheckupResultSubGroupConditionInput = {
  order: Scalars['Int'];
  result: Scalars['String'];
  comment: Scalars['String'];
  criteria: Array<CheckupResultSubGroupConditionCriteriaInput>;
};

export enum CheckupResultSubGroupJudgeStrategy {
  IndividualSetting = 'INDIVIDUAL_SETTING',
  Simple = 'SIMPLE'
}

export type CheckupResultSummary = {
  __typename?: 'CheckupResultSummary';
  result?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  overallComment?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type CheckupResultSummaryInput = {
  result?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  overallComment?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type CheckupTemplateAttachmentDownloadableLinkInput = {
  key: Scalars['String'];
};

export type CheckupTemplateBody = {
  __typename?: 'CheckupTemplateBody';
  body?: Maybe<Scalars['String']>;
};

export type CheckupUnion = {
  __typename?: 'CheckupUnion';
  id: Scalars['ID'];
  unions: Array<Scalars['String']>;
};

export type CheckupVacancy = {
  __typename?: 'CheckupVacancy';
  checkup: ComputedVacancy;
  dental?: Maybe<ComputedVacancy>;
};

/** 期首設定 */
export type CheckupYearBeginning = {
  __typename?: 'CheckupYearBeginning';
  month: Scalars['Int'];
  date: Scalars['Int'];
};

export type CheckupYearBeginningInput = {
  month: Scalars['Int'];
  date: Scalars['Int'];
};

export enum ChoiceType {
  Textarea = 'TEXTAREA',
  Return = 'RETURN',
  Upload = 'UPLOAD',
  PainScale = 'PAIN_SCALE',
  StomachMap = 'STOMACH_MAP',
  Number = 'NUMBER',
  Date = 'DATE',
  Hidden = 'HIDDEN',
  Pharmacy = 'PHARMACY',
  CheckBox = 'CHECK_BOX',
  Radio = 'RADIO',
  FilterRadio = 'FILTER_RADIO'
}

export type Clinic = {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  name: Scalars['String'];
  departments: ModelDepartmentConnection;
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  bankInfo: BankInfo;
  mapUrl?: Maybe<Scalars['String']>;
  termsUrl?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
};


export type ClinicDepartmentsArgs = {
  filter?: Maybe<ModelDepartmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClinicMemo = {
  __typename?: 'ClinicMemo';
  clinic: Scalars['ID'];
  date: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum ClinicType {
  Clinic = 'CLINIC',
  Dental = 'DENTAL',
  Checkup = 'CHECKUP'
}

export enum Comparator {
  Eq = 'EQ',
  Ge = 'GE',
  Le = 'LE',
  Gt = 'GT',
  Lt = 'LT',
  Ne = 'NE'
}

/**
 * 表示用の空き枠モデル。
 * 「延べ」の受け入れ可能枠数を表示する。
 * なお、remainDurationが0の場合は、枠数に無関係に「0」という扱いにする。
 */
export type ComputedVacancy = {
  __typename?: 'ComputedVacancy';
  clinic: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  unit: Scalars['String'];
  available: Scalars['Int'];
  estimatedDuration: Scalars['Float'];
  doctor?: Maybe<Scalars['String']>;
  laneId: Scalars['String'];
  waitAvailable?: Maybe<Scalars['Boolean']>;
};

export type ConfirmedReservationFeeItem = {
  __typename?: 'confirmedReservationFeeItem';
  clinic: Scalars['String'];
  date: Scalars['String'];
};

export type ConfirmQueryInput = {
  clinic: Scalars['String'];
};

export type ConfirmQueryResult = {
  __typename?: 'ConfirmQueryResult';
  confirmRequired: Scalars['Boolean'];
  confirmReservationFeeRequired: Scalars['Boolean'];
};

export type CreateAttachmentInput = {
  id: Scalars['ID'];
  attachmentTreatmentId?: Maybe<Scalars['String']>;
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<AttachmentType>;
};

export type CreateAuditLogQueryJobInput = {
  queryId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type CreateAutoNumberInput = {
  clinic: Scalars['String'];
  schema: Scalars['String'];
  current: Scalars['Int'];
  digits: Scalars['Int'];
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type CreateBlockInput = {
  date: Scalars['String'];
  lane: Scalars['String'];
  start: Scalars['String'];
  reason: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  clinic: Scalars['String'];
};

export type CreateBlockNew = {
  __typename?: 'CreateBlockNew';
  date?: Maybe<Scalars['String']>;
};

export type CreateBlockToVacancyInput = {
  treatmentKindId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  blockDate?: Maybe<Scalars['String']>;
  blockTime?: Maybe<Scalars['String']>;
};

export type CreateCardInput = {
  id: Scalars['ID'];
  cardCustomerId: Scalars['String'];
  fourDigit: Scalars['String'];
  brand: Scalars['String'];
  payjpID: Scalars['String'];
  expAt: Scalars['String'];
};

export type CreateCheckupAdminUserInput = {
  id: Scalars['String'];
};

export type CreateCheckupAppointmentInput = {
  id: Scalars['ID'];
  userId: Scalars['String'];
  birthDate: Scalars['String'];
  address: Scalars['String'];
  planId: Scalars['String'];
  kitDeliverStatus: CheckupKitDeliverStatus;
  kitDeliverDate?: Maybe<Scalars['String']>;
  treatmentStatus: TreatmentStatus;
  planPrice: Scalars['Int'];
  planDiscountAmount?: Maybe<DiscountAmountInput>;
  planBillType?: Maybe<Scalars['String']>;
  optionPrices: Array<CheckupOptionPriceInput>;
  checkupOrganizationMemberEventId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type CreateCheckupAttachmentZipInput = {
  checkupOrganizationEventId: Scalars['String'];
  memberIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateCheckupInterviewQuestionInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  options: Array<Maybe<QuestionOptionInput>>;
  confirm_text?: Maybe<Scalars['String']>;
  additional_column_name?: Maybe<Scalars['String']>;
  additional_type?: Maybe<Scalars['String']>;
  /**
   * BeforeReserving: '1_BEFORE_RESERVING',
   * AfterReserving: '2_AFTER_RESERVING',
   */
  timing: Scalars['String'];
  condition: Array<CheckupInterviewConditionInput>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type CreateCheckupOptionIndividualInput = {
  id: Scalars['ID'];
  optionId: Scalars['String'];
  terms: Array<CheckupOptionIndividualTermInput>;
};

export type CreateCheckupOptionInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  requiredMin: Scalars['Int'];
  clinicType?: Maybe<ClinicType>;
  regularPrice: Scalars['Int'];
  description: Scalars['String'];
  gender: Scalars['String'];
  aboutDisplay: Scalars['Boolean'];
  annotation?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateCheckupOrganizationChatWorkspaceInput = {
  checkupOrganizationId: Scalars['String'];
  workspaceId: Scalars['String'];
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  type?: Maybe<ChatWorkspaceType>;
  accessToken?: Maybe<Scalars['String']>;
};

export type CreateCheckupOrganizationEventByCopyingLatestEventInput = {
  organizationId: Scalars['String'];
  year: Scalars['Int'];
};

export type CreateCheckupOrganizationEventByCopyingLatestEventResult = {
  __typename?: 'CreateCheckupOrganizationEventByCopyingLatestEventResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  event?: Maybe<CheckupOrganizationEvent>;
};

export type CreateCheckupOrganizationEventInput = {
  /** UUID */
  id: Scalars['ID'];
  /** 名称（「2022年度」等） */
  name: Scalars['String'];
  /**
   * 年度（2022等）
   * 年度の生成時にシーケンスとして利用する
   */
  year: Scalars['Int'];
  /** 年度 + 期首で算出した開始日 */
  start: Scalars['String'];
  /** 年度 + 期首で算出した終了日 */
  end: Scalars['String'];
  /** 企業とのリレーション */
  checkupOrganizationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** プランの「デフォルト」 */
  planId?: Maybe<Scalars['String']>;
  /** プラン負担者設定の「デフォルト」 */
  planBillType: Scalars['String'];
  /** 会社負担オプション設定 */
  expenseOptions: Array<Maybe<Scalars['String']>>;
  /** キット送付タイプの「デフォルト」 */
  kitDeliverType: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先の住所 */
  address: Scalars['String'];
  /** 希望開始月（年月） */
  desiredStartTerm: Scalars['String'];
  /** 希望終了月（年月） */
  desiredEndTerm: Scalars['String'];
  /** メモ */
  notedByOrganization?: Maybe<Scalars['String']>;
  /** 社内メモ */
  notedByAdmin?: Maybe<Scalars['String']>;
  /** 適用済みディスカウント */
  discountCode?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  startMonth?: Maybe<Scalars['Int']>;
  /** 組合 */
  union?: Maybe<Scalars['String']>;
};

export type CreateCheckupOrganizationEventOptionInput = {
  id: Scalars['ID'];
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['String']>;
  billType: Scalars['String'];
  price: Scalars['Int'];
};

export type CreateCheckupOrganizationEventTemplateInput = {
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachmentInput>;
};

export type CreateCheckupOrganizationEventTemplateV2Input = {
  id: Scalars['ID'];
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachmentInput>;
};

export type CreateCheckupOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  staffName: Scalars['String'];
  staffTel: Scalars['String'];
  staffEmail: Scalars['String'];
  userCreatedAt?: Maybe<Scalars['String']>;
  salesMemo?: Maybe<Scalars['String']>;
  customerNo?: Maybe<Scalars['String']>;
};

export type CreateCheckupOrganizationMemberEventInput = {
  /** UID 「受診者番号」 */
  id: Scalars['ID'];
  /** 別システムでの一意キー */
  employeeNo?: Maybe<Scalars['String']>;
  /** 所属する年度 */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  checkupOrganizationId?: Maybe<Scalars['String']>;
  /** 氏名 */
  name: Scalars['String'];
  /** メールアドレス + slackと照合する用のキー */
  email: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先住所 */
  address: Scalars['String'];
  /** 初回案内送付日時（= 確定日時） */
  invitedAt?: Maybe<Scalars['String']>;
  /** チャットツール上のハンドルネーム */
  chatName?: Maybe<Scalars['String']>;
  /** チャットツール上のID */
  chatId?: Maybe<Scalars['String']>;
  /** 実際に受診するプラン */
  plan?: Maybe<Scalars['String']>;
  /** プラン負担者 */
  planBillType?: Maybe<Scalars['String']>;
  /** 会社負担オプション設定 */
  expenseOptions: Array<Maybe<Scalars['String']>>;
  /** キット送付タイプ */
  kitDeliverType?: Maybe<Scalars['String']>;
  nextRemindOn?: Maybe<Scalars['String']>;
  /** 利用するテンプレート */
  template?: Maybe<Scalars['String']>;
  /** スケジュール設定（個別） */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** 前回リマインド日 */
  recentRemindedAt?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  /** 受診予定期間 */
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  /** 事業所 */
  office?: Maybe<Scalars['String']>;
  /** 部門 */
  department?: Maybe<Scalars['String']>;
  /** 雇入年月日 */
  hiringDate?: Maybe<Scalars['String']>;
};

export type CreateCheckupPlanIndividualInput = {
  id: Scalars['ID'];
  planId: Scalars['String'];
  terms: Array<CheckupPlanIndividualTermInput>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateCheckupPlanInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  options: Array<Maybe<Scalars['String']>>;
  treatmentKindId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hideForOrganization?: Maybe<Scalars['Boolean']>;
  hideForIndividual?: Maybe<Scalars['Boolean']>;
  urinalysisKit?: Maybe<Scalars['Boolean']>;
  stoolKit?: Maybe<Scalars['Boolean']>;
};

export type CreateCheckupResultInput = {
  patientNo: Scalars['String'];
  patientName?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  label: Scalars['String'];
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  status: CheckupResultStatus;
  dataSource: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type CreateCheckupResultItemGroupInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  friendlyName: Scalars['String'];
  technicalName: Scalars['String'];
};

export type CreateCheckupResultItemInput = {
  id: Scalars['ID'];
  subTitle?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  mappingName?: Maybe<Scalars['String']>;
  mappingStrategy: CheckupResultItemMappingStrategy;
  unit?: Maybe<Scalars['String']>;
  criteria: Array<CheckupCriteriaInput>;
  order: Scalars['Int'];
  subGroup?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  ignore?: Maybe<Scalars['Boolean']>;
  displayStrategy?: Maybe<CheckupResultDisplayStrategy>;
  criteriaChart: Array<Maybe<CheckupResultCriteriaChartDefInput>>;
  pairName?: Maybe<Scalars['String']>;
  halfName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nurse?: Maybe<Scalars['Boolean']>;
  doctor?: Maybe<Scalars['Boolean']>;
  formQuestion?: Maybe<QuestionInput>;
};

export type CreateCheckupResultItemSubGroupInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  group?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  strategy: CheckupResultSubGroupJudgeStrategy;
  conditions: Array<Maybe<CheckupResultSubGroupConditionInput>>;
  comments: Array<Maybe<CheckupResultSubGroupCommentSetInput>>;
};

export type CreateCheckupResultSetInput = {
  /** CheckupAppointmentのID */
  id: Scalars['ID'];
  /** 取り込んだローデータ */
  raw: Array<CheckupResultRawDataInput>;
  /** 手入力データ */
  override: Array<CheckupResultRawDataInput>;
  /** 判定済みのデータ */
  judged: Array<CheckupResultJudgedSubGroupInput>;
  /** 総合判定 */
  summary?: Maybe<CheckupResultSummaryInput>;
  /** 担当医の名前 */
  doctor?: Maybe<CheckupResultDoctorInput>;
  /** 検査日 */
  date: Scalars['String'];
  status: CheckupResultSetStatus;
};

export type CreateCheckupUnionInput = {
  id: Scalars['ID'];
  unions: Array<Scalars['String']>;
};

export type CreateClinicInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  bankInfo: BankInfoInput;
  mapUrl?: Maybe<Scalars['String']>;
  termsUrl?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
};

export type CreateClinicMemoInput = {
  clinic: Scalars['ID'];
  date: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  id: Scalars['ID'];
  customerUserId: Scalars['String'];
  defaultCard?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
};

export type CreateDentalEvaluationInput = {
  doctorId?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  evaluations: Array<Maybe<DentalEvaluationValueInput>>;
};

export type CreateDentalEvaluationItemsInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  children: Array<Maybe<DentalEvaluationItemInput>>;
  createdAt: Scalars['String'];
};

export type CreateDepartmentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<DepartmentType>;
  baseDuration?: Maybe<Scalars['Float']>;
  departmentClinicId?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  reserveInterval?: Maybe<Scalars['Int']>;
  editInterval?: Maybe<Scalars['Int']>;
  alertThreshold?: Maybe<Scalars['Int']>;
  orcaID: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  announcement?: Maybe<Scalars['String']>;
  requirement?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
};

export type CreateDiscountCodeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  strategy: DiscountStrategyInput;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type CreateDoctorInput = {
  id: Scalars['ID'];
  doctorDentalEvaluationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  clinic?: Maybe<Scalars['String']>;
};

export type CreateDosageFeedbackInput = {
  id: Scalars['String'];
  pharmacist?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  actions: Array<CreateRemindActionInput>;
};

export type CreateDosageInput = {
  /** pharmacy#date#time#userId */
  id?: Maybe<Scalars['String']>;
  pharmacy: Scalars['ID'];
  date: Scalars['String'];
  userId: Scalars['String'];
  treatmentId?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  interview?: Maybe<InterviewInput>;
  status: DosageStatus;
  pharmacist?: Maybe<Scalars['String']>;
  feedback?: Maybe<DosageFeedbackInput>;
  visitingDatetime?: Maybe<Scalars['String']>;
};

export type CreateDosagePrescriptionInput = {
  id: Scalars['ID'];
  key: Scalars['String'];
  dosageId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type CreateDosageWithPrescriptionInput = {
  dosage: CreateDosageInput;
  prescriptions: Array<Scalars['String']>;
};

export type CreateDosageWithPrescriptionResult = {
  __typename?: 'CreateDosageWithPrescriptionResult';
  urls: Array<SignedPost>;
};

export type CreateEmployeeInput = {
  id: Scalars['ID'];
  employeeOrganizationId?: Maybe<Scalars['String']>;
  employeeUserId?: Maybe<Scalars['String']>;
  chatTool?: Maybe<ChatTool>;
  chatId?: Maybe<Scalars['String']>;
};

export type CreateEventInstructionInput = {
  id?: Maybe<Scalars['ID']>;
  inChargeStaffId?: Maybe<Scalars['ID']>;
  instructorStaffId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  isDone?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
};

export type CreateExtendedDictionaryInput = {
  key: Scalars['ID'];
  template: Scalars['String'];
  type: ExtendedDictionaryArgType;
  override: Array<ExtendedDictionaryOverrideInput>;
};

export type CreateFeedbackInput = {
  id: Scalars['ID'];
  treatmentId: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  doctor?: Maybe<Scalars['String']>;
};

export type CreateFollowupInput = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body: Scalars['String'];
  followupTreatmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
};

export type CreateImportJobInput = {
  schema: Scalars['String'];
  name: Scalars['String'];
  startedAt: Scalars['String'];
  status: ImportJobStatus;
};

export type CreateInstructionInput = {
  id: Scalars['ID'];
  /** 処置内容 */
  content: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
};

export type CreateInterviewInput = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  firstVisit?: Maybe<Scalars['Boolean']>;
  questions: Array<Maybe<QuestionInput>>;
  submitted?: Maybe<Scalars['Boolean']>;
  negative_or_positive?: Maybe<NegaPosiInput>;
  isRenewal?: Maybe<Scalars['Boolean']>;
};

export type CreateLineBotMessageInput = {
  key: Scalars['String'];
  message: Scalars['String'];
  example?: Maybe<Scalars['String']>;
  selectClinic?: Maybe<Scalars['Boolean']>;
  childrenKeys: Array<Maybe<Scalars['String']>>;
};

export type CreateLineMessageInput = {
  id: Scalars['ID'];
  lineMessageLineUserId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  messageText: Scalars['String'];
  createdAt: Scalars['String'];
  messageType: LineMessageSendType;
  accountType: Scalars['String'];
  alreadyReadLineMessageId?: Maybe<Scalars['String']>;
  latestLineMessageId?: Maybe<Scalars['String']>;
  lineMessageUserThreadId?: Maybe<Scalars['String']>;
  /** jsonだけどぶっこむ */
  event?: Maybe<Scalars['String']>;
  attachmentId?: Maybe<Scalars['String']>;
  openedByUserAt?: Maybe<Scalars['String']>;
  /** どこ経由か。必ずオール小文字。現在は zendesk と search。 */
  via?: Maybe<Scalars['String']>;
  searchGroupId?: Maybe<Scalars['String']>;
  origin?: Maybe<LineMessageOrigin>;
};

export type CreateLineMessageTemplateInput = {
  key: LineTemplateKey;
  clinic: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type CreateLineReadStatusInput = {
  key: ModelLineReadStatusIdCompositeKeyInput;
  channelId?: Maybe<Scalars['String']>;
  lineReadStatusLineUserId?: Maybe<Scalars['String']>;
  alreadyReadLineMessageId?: Maybe<Scalars['String']>;
  latestLineMessageId?: Maybe<Scalars['String']>;
};

export type CreateLineUserInput = {
  id: Scalars['ID'];
  lineUserUserId?: Maybe<Scalars['String']>;
  accounts: Array<Maybe<Scalars['String']>>;
};

export type CreateLocationGroupInput = {
  id: Scalars['ID'];
  /** ロケーショングループ名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** クリニックID */
  clinicId: Scalars['String'];
};

export type CreateLocationInput = {
  id: Scalars['ID'];
  /** ロケーショングループ情報 */
  groupId: Scalars['ID'];
  /** ロケーション名 */
  name: Scalars['String'];
  /** クリニックID */
  clinicId: Scalars['String'];
  /** 部屋のタイプ waiting: 待合室, examination: 処置室 */
  roomType: Scalars['String'];
  /** 上から何番目かの表示位置 */
  top: Scalars['Int'];
  /** 左から何番目かの表示位置 */
  left: Scalars['Int'];
};

export type CreateMedicalQuestionnaireInterviewAnswersInput = {
  id: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  beforeReserve?: Maybe<InterviewAnswerStatusInput>;
  afterReserve?: Maybe<InterviewAnswerStatusInput>;
};

export type CreateMedicalQuestionnaireInterviewInput = {
  treatmentKind: Scalars['String'];
  interviewTime: InterviewTimeType;
  order: Scalars['Int'];
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  answerChoices: Array<Maybe<InterviewAnswerChoiceInput>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType?: Maybe<ChoiceType>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CreateMedicalQuestionnaireSurveyAnswersInput = {
  id: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  answers?: Maybe<SurveyAnswerStatusInput>;
};

export type CreateMedicalQuestionnaireSurveyInput = {
  surveyTime: SurveyTimeType;
  order: Scalars['String'];
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  answerChoices: Array<Maybe<SurveyAnswerChoiceInput>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType?: Maybe<ChoiceType>;
  medicalQuestionnaireSurvey?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CreateNonceInput = {
  nonce: Scalars['String'];
  userId: Scalars['String'];
  issueAt: Scalars['String'];
  expireAt: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type CreateOpenMonthInput = {
  yearMonth: Scalars['String'];
  open?: Maybe<Scalars['Boolean']>;
  clinic: Scalars['String'];
};

export type CreateOrcaPatientInput = {
  orcaID: Scalars['ID'];
  orcaPatientPatientId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  chatId: Scalars['String'];
  chatTool: ChatTool;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreatePatientCommentInput = {
  id: Scalars['ID'];
  patient?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CreatePatientInput = {
  id: Scalars['ID'];
  patientUserId?: Maybe<Scalars['String']>;
  patientNo: Scalars['String'];
  orcaID?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  seikana?: Maybe<Scalars['String']>;
  meikana?: Maybe<Scalars['String']>;
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  anyoneStaff?: Maybe<Scalars['Boolean']>;
  staffId?: Maybe<Scalars['String']>;
  /** 薬科の患者番号 */
  pharmacyPatientNo?: Maybe<Scalars['String']>;
};

export type CreatePatientTagAssociationInput = {
  patient?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
};

export type CreatePatientTagInput = {
  tag: Scalars['ID'];
  displayName: Scalars['String'];
  color: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type CreatePaymentInput = {
  date: Scalars['String'];
  time: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  paymentTreatmentId?: Maybe<Scalars['String']>;
};

export type CreatePaymentStateInput = {
  executionId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentJobType>;
  state?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type CreatePaymentStateMachineInput = {
  date: Scalars['String'];
  userId: Scalars['String'];
  department: Scalars['String'];
  executionId: Scalars['String'];
  time: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type CreatePharmacistInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreatePharmacyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateRemindActionInput = {
  id: Scalars['ID'];
  remindActionUserId: Scalars['String'];
  remindActionTreatmentId?: Maybe<Scalars['String']>;
  dosageId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  remindActionType?: Maybe<RemindActionType>;
  remindActionStatus?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type CreateRemindActionLogInput = {
  id: Scalars['ID'];
  remindActionLogUserId?: Maybe<Scalars['String']>;
  remindActionLogTreatmentId?: Maybe<Scalars['String']>;
  dosageId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CreateRemindActionPlanInput = {
  date: Scalars['ID'];
  dosageId?: Maybe<Scalars['String']>;
  type?: Maybe<RemindActionType>;
  status?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type CreateReviewInput = {
  schema: ReviewSchema;
  createdAt: Scalars['String'];
  answers: Array<KeyValueInput>;
  userId: Scalars['String'];
};

export type CreateRoomInput = {
  clinic?: Maybe<Scalars['String']>;
  roomType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateRoomVacancyInput = {
  roomType: Scalars['String'];
  roomNo: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatmentId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateSearchConditionInput = {
  type: Scalars['ID'];
  clinic: Scalars['String'];
  createdAt: Scalars['String'];
  title: Scalars['String'];
  treatment?: Maybe<SearchTreatmentSettingInput>;
  patient?: Maybe<PatientSearchSettingInput>;
};

export type CreateSentMessageGroupBySearchInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  mailSubject: Scalars['String'];
  templateMessage: Scalars['String'];
  searchCondition: SentMessageSearchConditionInput;
  targetUserIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  createdUserName: Scalars['String'];
};

export type CreateShiftInput = {
  clinic: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  date: Scalars['String'];
  doctor?: Maybe<Scalars['String']>;
  laneId?: Maybe<Scalars['String']>;
};

export type CreateSlackBotTokenInput = {
  token: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
};

export type CreateSlackNonceInput = {
  id: Scalars['ID'];
  slackId: Scalars['String'];
};

export type CreateSoapInfoInput = {
  id: Scalars['ID'];
  treatmentId: Scalars['String'];
  subjectComment?: Maybe<Scalars['String']>;
  sOAPInfoDoctorId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type CreateStaffInput = {
  role: StaffRoleType;
  staffId: Scalars['String'];
  enable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  enableTreatmentKindIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  iconSrc?: Maybe<Scalars['String']>;
};

export type CreateStaffShiftInput = {
  clinic?: Maybe<Scalars['String']>;
  staff: Scalars['String'];
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  failure?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateStaffVacancyInput = {
  clinic?: Maybe<Scalars['String']>;
  staff: Scalars['String'];
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatmentId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateTreatmentAdminInput = {
  treatment: CreateTreatmentInput;
  remindOn?: Maybe<Scalars['String']>;
  reservableFrom?: Maybe<Scalars['String']>;
  /** 管理側から連鎖予約を可能にするかどうか */
  isConsecutiveReservationAllowed?: Maybe<Scalars['Boolean']>;
};

export type CreateTreatmentInput = {
  userId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  estimatedEnd?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  department?: Maybe<Scalars['String']>;
  physician?: Maybe<Scalars['String']>;
  status?: Maybe<TreatmentStatus>;
  holdedAt?: Maybe<Scalars['String']>;
  yearMonth?: Maybe<Scalars['String']>;
  orcaReservationId?: Maybe<Scalars['String']>;
  orcaAcceptanceId?: Maybe<Scalars['String']>;
  treatmentSOAPInfoId?: Maybe<Scalars['String']>;
  clinic: Scalars['String'];
  temporally?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  editCondition?: Maybe<Array<TreatmentEditConditionInput>>;
  checkupOrganizationMemberEventId?: Maybe<Scalars['String']>;
  lumeccaConsecutiveId?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  reservationMemo?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  lateContactFlag?: Maybe<Scalars['Boolean']>;
  /** ユーザーから隠す */
  hidden?: Maybe<Scalars['Boolean']>;
  /** ユーザー表示用の所要時間 */
  displayDuration?: Maybe<Scalars['Float']>;
  /** ディスカウントコード */
  discountCode?: Maybe<Scalars['String']>;
  vaccineId?: Maybe<Scalars['String']>;
  noInterview?: Maybe<Scalars['Boolean']>;
  /** オンライン診療フラグ */
  online?: Maybe<Scalars['Boolean']>;
  /** レーンID */
  laneId?: Maybe<Scalars['String']>;
  reservableFrom?: Maybe<Scalars['String']>;
  preInterview?: Maybe<CreateInterviewInput>;
  interview?: Maybe<CreateInterviewInput>;
  detailedStatus?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  checklist?: Maybe<TreatmentChecklistInput>;
  /** 課金不要事由 */
  noChargeReason?: Maybe<NoChargeReason>;
  noChargeReasonDetail?: Maybe<Scalars['String']>;
  cancelPolicyRevision?: Maybe<Scalars['String']>;
  /** 予約診療費徴収の同意 */
  consentedToReservationFee?: Maybe<Scalars['Boolean']>;
};

export type CreateTreatmentKindInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  course?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  /** ワクチン制御の対象かどうか */
  vaccine?: Maybe<Scalars['Boolean']>;
  /** 複数回受診するワクチンの場合、シークエンスの設定 */
  vaccineSequence?: Maybe<VaccineSequenceInput>;
  /** リコール自動送信用のメッセージテンプレート */
  remindTemplate?: Maybe<Scalars['String']>;
  /** 診療科の重複の許可 */
  permitDuplicateDepartment?: Maybe<Scalars['Boolean']>;
  noInterview?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['String']>;
  interviewName?: Maybe<Scalars['String']>;
  estimatedDuration?: Maybe<Scalars['Float']>;
  revisit?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  /** Department.announcement をオーバーライドできる。 */
  announcement?: Maybe<Scalars['String']>;
  /** Department.requirement をオーバーライドできる。 */
  requirement?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  /** ユーザーに表示する長さ */
  displayDuration?: Maybe<Scalars['Int']>;
  /** 新空き枠用 */
  operation: Array<Maybe<OperationInput>>;
  requiredReservationFee?: Maybe<Scalars['Boolean']>;
  cancelPolicies: Array<CancelPolicyInput>;
};

export type CreateTreatmentProgressHistoryInput = {
  /** ID */
  historyId: Scalars['ID'];
  /** treatmentProgressテーブルとの紐付けをするためのID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId?: Maybe<Scalars['ID']>;
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 退室日時 */
  exitAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstructionInput>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

export type CreateTreatmentProgressInput = {
  /** ID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId?: Maybe<Scalars['ID']>;
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstructionInput>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

export type CreateTreatmentProgressNextStatusAssociationInput = {
  statusId: Scalars['String'];
  nextStatusId: Scalars['String'];
};

export type CreateTreatmentProgressStatusInput = {
  id: Scalars['ID'];
  /** 診察ステータス名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** 診察ステータスを上位表示する役職。役職によって表示順が変わるため。 */
  preferredRoles: Array<Maybe<Scalars['String']>>;
  /** 進行中、待機中を判別するためのステータス */
  status: Scalars['String'];
};

export type CreateUserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Sex>;
  mail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  pref?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  userPatientId?: Maybe<Scalars['String']>;
  userCustomerId?: Maybe<Scalars['String']>;
  userLineUserId?: Maybe<Scalars['String']>;
  confirmed: Array<Scalars['String']>;
  /**
   * 保険証（複数）
   * フィールドリゾルバーとして、ダウンロード可能なURLを返すlambdaをアタッチする。
   * 永続層としてはkeyを持つ。
   */
  insuranceCards?: Maybe<Array<Maybe<InsuranceCardInput>>>;
  profession?: Maybe<Scalars['String']>;
  insuranceCardCheckedAt?: Maybe<Scalars['String']>;
  penaltyCount?: Maybe<Scalars['Int']>;
  accountRestricted?: Maybe<Scalars['Boolean']>;
  introducer?: Maybe<Scalars['String']>;
};

export type CreateUserReserveConditionInput = {
  userId: Scalars['ID'];
  treatmentKind: Scalars['String'];
  start: Scalars['String'];
  conditions: Array<TreatmentEditConditionInput>;
  disabled?: Maybe<Scalars['Boolean']>;
  remindOn?: Maybe<Scalars['String']>;
  remindedAt?: Maybe<Scalars['String']>;
  reservationMemo?: Maybe<Scalars['String']>;
  reservableFrom?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  remindTemplate?: Maybe<Scalars['String']>;
};

export type CreateUserThreadInput = {
  /** lineMessageIdまたはメールのID */
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  /** LINE, MAIL */
  messageType: Scalars['String'];
  startMessageCreatedAt: Scalars['String'];
  name: Scalars['String'];
  userThreadClinicId?: Maybe<Scalars['String']>;
  correspondingType: UserThreadCorrespondingType;
  status: UserThreadStatus;
  userThreadLineMessageIds: Array<Maybe<Scalars['String']>>;
  userThreadLineReadStatusId?: Maybe<Scalars['String']>;
};

export type CreateVacanciesInput = {
  clinic?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  vacancies: Array<Maybe<VacancyInput>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateVacancyConfigSetInput = {
  id: Scalars['ID'];
  clinicId: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  set?: Maybe<VacancySetInput>;
};

export type CreateVacancyLaneInput = {
  id: Scalars['ID'];
  clinic?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  unit: Array<Maybe<Scalars['String']>>;
  defaultTotal: Scalars['Int'];
  duration: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type CreateVacancyNew = {
  __typename?: 'CreateVacancyNew';
  date?: Maybe<Scalars['String']>;
};

export type CreateVacancyNewInput = {
  treatmentId: Scalars['String'];
  treatmentKindId?: Maybe<Scalars['String']>;
  reserveDate?: Maybe<Scalars['String']>;
  reserveTime?: Maybe<Scalars['String']>;
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
};

export type CreateVacancySpacerInput = {
  clinic: Scalars['String'];
  date: Scalars['String'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export type CreateVacancySpacerTemplateInput = {
  clinic: Scalars['String'];
  week: Scalars['Int'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export type CreateVacancyTemplateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  vacancies: Array<Maybe<VacancyInput>>;
  conditions: Array<Maybe<VacancyTemplateConditionInput>>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateVacancyUnitInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  /** 空き枠編集の並び順。100単位で分かれる */
  order?: Maybe<Scalars['Int']>;
  departmentId: Scalars['String'];
  treatmentKind: Scalars['String'];
  options: Array<Maybe<Scalars['String']>>;
  defaultDuration: Scalars['Float'];
  online?: Maybe<Scalars['Boolean']>;
  lanePreference: Array<Maybe<Scalars['String']>>;
};

export type CreateVaccineEventInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 開始日付YYYY-MM-DD */
  start: Scalars['String'];
  /** 終了日付YYYY-MM-DD */
  end: Scalars['String'];
  treatmentKind?: Maybe<Scalars['String']>;
  /** 招待テンプレート */
  invitationTemplate?: Maybe<Scalars['String']>;
  /** 予約可能期間開始日YYYY-MM-DD */
  reserveTermStart?: Maybe<Scalars['String']>;
  /** 予約可能期間終了日YYYY-MM-DD */
  reserveTermEnd?: Maybe<Scalars['String']>;
};

export type CreateVaccinePatientInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  belongs?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  /** ワクチン接種シークエンスを管理する一貫したID。 */
  sequenceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  invitationSentAt?: Maybe<Scalars['String']>;
};

export type CreateWaitingListItemInput = {
  clinic?: Maybe<Scalars['String']>;
  start: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  end: Scalars['String'];
  userId: Scalars['String'];
  vacancyUnit?: Maybe<Scalars['String']>;
};

export type CreateZendeskMessageInput = {
  type: Scalars['String'];
  createdAt: Scalars['String'];
  message: Scalars['String'];
  messageId: Scalars['String'];
  from: Scalars['String'];
  ttl: Scalars['Int'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  user: User;
  cards: ModelCardConnection;
  defaultCard?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
};


export type CustomerCardsArgs = {
  filter?: Maybe<ModelCardFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeleteAttachmentInput = {
  id: Scalars['ID'];
};

export type DeleteAutoNumberInput = {
  clinic: Scalars['ID'];
  schema: Scalars['ID'];
};

export type DeleteBlockInput = {
  date: Scalars['ID'];
  laneStart: Scalars['ID'];
  clinic: Scalars['String'];
};

export type DeleteBlockNew = {
  __typename?: 'DeleteBlockNew';
  date?: Maybe<Scalars['String']>;
};

export type DeleteBlockToVacancyInput = {
  roomType: Scalars['String'];
  roomNo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
};

export type DeleteCardInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupAppointmentInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupAppointmentOrganizationInput = {
  id: Scalars['String'];
};

export type DeleteCheckupInterviewQuestionInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOptionIndividualInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOptionInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOrganizationChatWorkspaceInput = {
  checkupOrganizationId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};

export type DeleteCheckupOrganizationEventInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOrganizationEventOptionInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOrganizationEventTemplateInput = {
  checkupOrganizationEventId: Scalars['ID'];
  templateKind: Scalars['ID'];
};

export type DeleteCheckupOrganizationEventTemplateV2Input = {
  id: Scalars['ID'];
};

export type DeleteCheckupOrganizationInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupOrganizationMemberEventInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupPlanIndividualInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupPlanInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupResultInput = {
  patientNo: Scalars['ID'];
  dateLabel: Scalars['ID'];
};

export type DeleteCheckupResultItemGroupInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupResultItemInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupResultItemSubGroupInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupResultSetInput = {
  id: Scalars['ID'];
};

export type DeleteCheckupUnionInput = {
  id: Scalars['ID'];
};

export type DeleteClinicInput = {
  id: Scalars['ID'];
};

export type DeleteClinicMemoInput = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};

export type DeleteCustomerInput = {
  id: Scalars['ID'];
};

export type DeleteDentalEvaluationInput = {
  treatmentId: Scalars['ID'];
};

export type DeleteDentalEvaluationItemsInput = {
  id: Scalars['ID'];
};

export type DeleteDepartmentInput = {
  id: Scalars['ID'];
};

export type DeleteDiscountCodeInput = {
  id: Scalars['ID'];
};

export type DeleteDoctorInput = {
  id: Scalars['ID'];
};

export type DeleteDosageInput = {
  pharmacy: Scalars['ID'];
  dateTimeUserId: Scalars['ID'];
};

export type DeleteDosagePrescriptionInput = {
  id: Scalars['ID'];
};

export type DeleteEmployeeInput = {
  id: Scalars['ID'];
};

export type DeleteEventInstructionInput = {
  id: Scalars['ID'];
};

export type DeleteEventMembersInput = {
  checkupOrganizationMemberEventIds: Array<Scalars['String']>;
};

export type DeleteExtendedDictionaryInput = {
  key: Scalars['ID'];
};

export type DeleteFeedbackInput = {
  treatmentId: Scalars['ID'];
};

export type DeleteFollowupInput = {
  id: Scalars['ID'];
};

export type DeleteForUserAsset = {
  id: Scalars['String'];
};

export type DeleteImportJobInput = {
  schema: Scalars['ID'];
  startedAt: Scalars['ID'];
};

export type DeleteInstructionInput = {
  id: Scalars['ID'];
};

export type DeleteInterviewInput = {
  id: Scalars['ID'];
};

export type DeleteLineBotMessageInput = {
  key: Scalars['ID'];
};

export type DeleteLineMessageInput = {
  id: Scalars['ID'];
};

export type DeleteLineMessageTemplateInput = {
  key: Scalars['ID'];
  clinicDepartmentTreatmentKind: Scalars['ID'];
};

export type DeleteLineUserInput = {
  id: Scalars['ID'];
};

export type DeleteLocationGroupInput = {
  id: Scalars['ID'];
};

export type DeleteLocationInput = {
  id: Scalars['ID'];
};

export type DeleteMedicalQuestionnaireInterviewAnswersInput = {
  id: Scalars['ID'];
};

export type DeleteMedicalQuestionnaireInterviewInput = {
  domainIdentifier: Scalars['String'];
  interviewTime: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type DeleteMedicalQuestionnaireInterviewListInput = {
  deleteActionType: MedicalQuestionnaireInterviewDeleteActionType;
  medicalQuestionnaireInterviewDeleteInput: DeleteMedicalQuestionnaireInterviewInput;
};

export type DeleteMedicalQuestionnaireInterviewListResult = {
  __typename?: 'deleteMedicalQuestionnaireInterviewListResult';
  success: Scalars['Boolean'];
};

export type DeleteMedicalQuestionnaireSurveyAnswersInput = {
  id: Scalars['ID'];
};

export type DeleteMedicalQuestionnaireSurveyInput = {
  surveyTime: Scalars['ID'];
  id: Scalars['String'];
};

export type DeleteNonceInput = {
  nonce: Scalars['ID'];
};

export type DeleteOpenMonthInput = {
  yearMonth: Scalars['ID'];
  clinic: Scalars['ID'];
};

export type DeleteOrcaPatientInput = {
  orcaID: Scalars['ID'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID'];
};

export type DeletePatientCommentInput = {
  id: Scalars['ID'];
};

export type DeletePatientInput = {
  id: Scalars['ID'];
};

export type DeletePatientTagAssociationInput = {
  patient: Scalars['ID'];
  tag: Scalars['ID'];
};

export type DeletePatientTagInput = {
  tag: Scalars['ID'];
};

export type DeletePaymentInput = {
  date: Scalars['ID'];
  userIdDepartmentTime: Scalars['ID'];
};

export type DeletePaymentStateInput = {
  executionId: Scalars['ID'];
  createdAt: Scalars['ID'];
};

export type DeletePaymentStateMachineInput = {
  executionId: Scalars['ID'];
};

export type DeletePharmacistInput = {
  id: Scalars['ID'];
};

export type DeletePharmacyInput = {
  id: Scalars['ID'];
};

export type DeleteRemindActionInput = {
  id: Scalars['ID'];
};

export type DeleteRemindActionLogInput = {
  id: Scalars['ID'];
};

export type DeleteRemindActionPlanInput = {
  date: Scalars['ID'];
  dosageId: Scalars['ID'];
};

export type DeleteReviewInput = {
  schema: Scalars['ID'];
  createdAt: Scalars['ID'];
};

export type DeleteRoomInput = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};

export type DeleteRoomVacancyInput = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};

export type DeleteSearchConditionInput = {
  type: Scalars['ID'];
  createdAt: Scalars['ID'];
};

export type DeleteSentMessageGroupBySearchInput = {
  id: Scalars['ID'];
};

export type DeleteShiftInput = {
  clinic: Scalars['ID'];
  dateStartDoctor: Scalars['ID'];
};

export type DeleteSlackNonceInput = {
  id: Scalars['ID'];
};

export type DeleteSoapInfoInput = {
  treatmentId: Scalars['ID'];
};

export type DeleteStaffInput = {
  staffId: Scalars['ID'];
};

export type DeleteStaffShiftInput = {
  clinic?: Maybe<Scalars['String']>;
  staff: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
};

export type DeleteStaffVacancyInput = {
  clinic?: Maybe<Scalars['String']>;
  staff: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
};

export type DeleteTemporallyTreatmentInput = {
  id: Scalars['String'];
  date: Scalars['String'];
  clinic: Scalars['String'];
};

export type DeleteTreatmentInput = {
  clinic: Scalars['ID'];
  dateTimeDepartmentUserIdTemporally: Scalars['ID'];
};

export type DeleteTreatmentKindInput = {
  id: Scalars['ID'];
};

export type DeleteTreatmentProgressHistoryInput = {
  historyId: Scalars['ID'];
};

export type DeleteTreatmentProgressInput = {
  treatmentProgressId: Scalars['ID'];
};

export type DeleteTreatmentProgressNextStatusAssociationInput = {
  statusId: Scalars['ID'];
  nextStatusId: Scalars['ID'];
};

export type DeleteTreatmentProgressStatusInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteUserReserveConditionInput = {
  userId: Scalars['ID'];
  treatmentKindStart: Scalars['ID'];
};

export type DeleteUserThreadInput = {
  userId: Scalars['ID'];
  accountTypeMessageTypeStartMessageCreatedAt: Scalars['ID'];
};

export type DeleteVacanciesInput = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};

export type DeleteVacancyConfigSetInput = {
  id: Scalars['ID'];
};

export type DeleteVacancyLaneInput = {
  id: Scalars['ID'];
};

export type DeleteVacancySpacerInput = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};

export type DeleteVacancySpacerTemplateInput = {
  clinic: Scalars['ID'];
  weekLaneId: Scalars['ID'];
};

export type DeleteVacancyTemplateInput = {
  id: Scalars['ID'];
};

export type DeleteVacancyUnitInput = {
  id: Scalars['ID'];
};

export type DeleteVaccineEventInput = {
  id: Scalars['ID'];
};

export type DeleteVaccinePatientInput = {
  id: Scalars['ID'];
};

export type DeleteWaitingListItemInput = {
  clinic: Scalars['ID'];
  startDepartmentTreatmentKindUserId: Scalars['ID'];
};

export type DeleteZendeskMessageInput = {
  type: Scalars['ID'];
  createdAt: Scalars['ID'];
};

export type DentalEvaluation = {
  __typename?: 'DentalEvaluation';
  doctor?: Maybe<Doctor>;
  treatment?: Maybe<Treatment>;
  userId?: Maybe<Scalars['String']>;
  evaluations?: Maybe<Array<Maybe<DentalEvaluationValue>>>;
};

export type DentalEvaluationItem = {
  __typename?: 'DentalEvaluationItem';
  label: Scalars['String'];
  type: DentalEvaluationType;
  options?: Maybe<Array<Maybe<DentalEvaluationOption>>>;
};

export type DentalEvaluationItemInput = {
  label: Scalars['String'];
  type: DentalEvaluationType;
  options?: Maybe<Array<Maybe<DentalEvaluationOptionInput>>>;
};

export type DentalEvaluationItems = {
  __typename?: 'DentalEvaluationItems';
  id: Scalars['ID'];
  label: Scalars['String'];
  children?: Maybe<Array<Maybe<DentalEvaluationItem>>>;
  createdAt: Scalars['String'];
};

export type DentalEvaluationOption = {
  __typename?: 'DentalEvaluationOption';
  value?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['String']>;
};

export type DentalEvaluationOptionInput = {
  value?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['String']>;
};

export enum DentalEvaluationType {
  Boolean = 'BOOLEAN',
  Select = 'SELECT'
}

export type DentalEvaluationValue = {
  __typename?: 'DentalEvaluationValue';
  item?: Maybe<DentalEvaluationItems>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DentalEvaluationValueInput = {
  itemId?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<DepartmentType>;
  baseDuration?: Maybe<Scalars['Float']>;
  clinic?: Maybe<Clinic>;
  icon?: Maybe<Scalars['String']>;
  reserveInterval?: Maybe<Scalars['Int']>;
  editInterval?: Maybe<Scalars['Int']>;
  alertThreshold?: Maybe<Scalars['Int']>;
  orcaID: Scalars['String'];
  online?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  colorScheme?: Maybe<Scalars['String']>;
  /** 受診するにあたっての必要事項 */
  announcement?: Maybe<Scalars['String']>;
  /** 来院時の注意事項 */
  requirement?: Maybe<Scalars['String']>;
  treatmentKinds: ModelTreatmentKindConnection;
  /**
   * 問診リスト
   * 値のサンプル：12#AFTER_RESERVE（domainIdentifier#interviewTime でidは不要）
   */
  listMedicalQuestionnaireInterview: ModelMedicalQuestionnaireInterviewConnection;
};


export type DepartmentTreatmentKindsArgs = {
  filter?: Maybe<ModelTreatmentKindFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type DepartmentListMedicalQuestionnaireInterviewArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireInterviewFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum DepartmentType {
  Standard = 'STANDARD',
  CourseBased = 'COURSE_BASED',
  Checkup = 'CHECKUP'
}

export type DisableNonceInput = {
  nonce: Scalars['String'];
};

export type DiscountAmount = {
  __typename?: 'DiscountAmount';
  discountPrice?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
};

export type DiscountAmountInput = {
  discountPrice?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  strategy: DiscountStrategy;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DiscountCodeInput = {
  code?: Maybe<Scalars['String']>;
  union?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

export type DiscountIndividualCheckupMasters = {
  __typename?: 'DiscountIndividualCheckupMasters';
  plans: Array<CheckupPlanDiscountPrice>;
  options: Array<CheckupOptionDiscountPrice>;
};

export type DiscountStrategy = {
  __typename?: 'DiscountStrategy';
  checkupPlan?: Maybe<DiscountStrategyCheckupPlan>;
  checkupOptions?: Maybe<DiscountStrategyCheckupOptions>;
};

export type DiscountStrategyCheckupOptionOverride = {
  __typename?: 'DiscountStrategyCheckupOptionOverride';
  optionId: Scalars['String'];
  amount: DiscountAmount;
};

export type DiscountStrategyCheckupOptionOverrideInput = {
  optionId: Scalars['String'];
  amount: DiscountAmountInput;
};

export type DiscountStrategyCheckupOptions = {
  __typename?: 'DiscountStrategyCheckupOptions';
  base: DiscountAmount;
  override: Array<DiscountStrategyCheckupOptionOverride>;
};

export type DiscountStrategyCheckupOptionsInput = {
  base: DiscountAmountInput;
  override: Array<DiscountStrategyCheckupOptionOverrideInput>;
};

export type DiscountStrategyCheckupPlan = {
  __typename?: 'DiscountStrategyCheckupPlan';
  base: DiscountAmount;
  override: Array<DiscountStrategyCheckupPlanOverride>;
};

export type DiscountStrategyCheckupPlanInput = {
  base: DiscountAmountInput;
  override: Array<DiscountStrategyCheckupPlanOverrideInput>;
};

export type DiscountStrategyCheckupPlanOverride = {
  __typename?: 'DiscountStrategyCheckupPlanOverride';
  planId: Scalars['String'];
  amount: DiscountAmount;
};

export type DiscountStrategyCheckupPlanOverrideInput = {
  planId: Scalars['String'];
  amount: DiscountAmountInput;
};

export type DiscountStrategyInput = {
  checkupPlan?: Maybe<DiscountStrategyCheckupPlanInput>;
  checkupOptions?: Maybe<DiscountStrategyCheckupOptionsInput>;
};

export type Doctor = {
  __typename?: 'Doctor';
  id: Scalars['ID'];
  dentalEvaluation?: Maybe<DentalEvaluation>;
  name: Scalars['String'];
  clinic?: Maybe<Scalars['String']>;
};

export type DoctorInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  clinic?: Maybe<Scalars['String']>;
};

export type Dosage = {
  __typename?: 'Dosage';
  /** pharmacy#date#time#userId */
  id?: Maybe<Scalars['String']>;
  pharmacy?: Maybe<Pharmacy>;
  date: Scalars['String'];
  user?: Maybe<User>;
  time: Scalars['String'];
  treatment?: Maybe<Treatment>;
  remind: ModelRemindActionConnection;
  interview?: Maybe<Interview>;
  status: DosageStatus;
  prescriptions: ModelDosagePrescriptionConnection;
  visitingDatetime?: Maybe<Scalars['String']>;
  actionPlan: ModelRemindActionPlanConnection;
  pharmacist?: Maybe<Pharmacist>;
  feedback?: Maybe<DosageFeedback>;
};


export type DosageRemindArgs = {
  filter?: Maybe<ModelRemindActionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type DosagePrescriptionsArgs = {
  filter?: Maybe<ModelDosagePrescriptionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type DosageActionPlanArgs = {
  filter?: Maybe<ModelRemindActionPlanFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DosageFeedback = {
  __typename?: 'DosageFeedback';
  feedback: Scalars['String'];
  reviewAt: Scalars['String'];
};

export type DosageFeedbackInput = {
  feedback: Scalars['String'];
  reviewAt: Scalars['String'];
};

export type DosagePrescription = {
  __typename?: 'DosagePrescription';
  id: Scalars['ID'];
  key: Scalars['String'];
  dosage?: Maybe<Dosage>;
  fileName: Scalars['String'];
};

export enum DosageStatus {
  AReserved = 'a_RESERVED',
  BPrescriptionNotConfirmed = 'b_PRESCRIPTION_NOT_CONFIRMED',
  CNotDosed = 'c_NOT_DOSED',
  DNotFeedbackSent = 'd_NOT_FEEDBACK_SENT',
  ECompleted = 'e_COMPLETED'
}

export type DownloadableLinkInput = {
  id: Scalars['String'];
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
  chatTool?: Maybe<ChatTool>;
  chatId?: Maybe<Scalars['String']>;
};

export type EmptyInput = {
  empty?: Maybe<Scalars['Boolean']>;
};

export type EmptyResult = {
  __typename?: 'EmptyResult';
  empty?: Maybe<Scalars['Boolean']>;
};

export type EventInstruction = {
  __typename?: 'EventInstruction';
  id?: Maybe<Scalars['ID']>;
  inChargeStaffId?: Maybe<Scalars['ID']>;
  inChargeStaff?: Maybe<Staff>;
  instructorStaffId?: Maybe<Scalars['ID']>;
  instructorStaff?: Maybe<Staff>;
  content?: Maybe<Scalars['String']>;
  isDone?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
};

export type EventInstructionInput = {
  id?: Maybe<Scalars['ID']>;
  inChargeStaffId?: Maybe<Scalars['ID']>;
  inChargeStaff?: Maybe<InstructionStaffInput>;
  instructorStaffId?: Maybe<Scalars['ID']>;
  instructorStaff?: Maybe<InstructionStaffInput>;
  content?: Maybe<Scalars['String']>;
  isDone?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
};

export type EventMemberInput = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employeeNo?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  planBillType?: Maybe<Scalars['String']>;
  expenseOptions?: Maybe<Array<Scalars['String']>>;
  template?: Maybe<Scalars['String']>;
  schedule?: Maybe<CheckupRemindScheduleInput>;
  slackEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  kitDeliverType?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  office?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  hiringDate?: Maybe<Scalars['String']>;
};

export type ExtendedDictionary = {
  __typename?: 'ExtendedDictionary';
  key: Scalars['ID'];
  template: Scalars['String'];
  type: ExtendedDictionaryArgType;
  override: Array<ExtendedDictionaryOverride>;
};

export enum ExtendedDictionaryArgType {
  Treatment = 'TREATMENT'
}

export type ExtendedDictionaryCondition = {
  __typename?: 'ExtendedDictionaryCondition';
  comparator: Comparator;
  left: Scalars['String'];
  right: Scalars['String'];
};

export type ExtendedDictionaryConditionInput = {
  comparator: Comparator;
  left: Scalars['String'];
  right: Scalars['String'];
};

export type ExtendedDictionaryOverride = {
  __typename?: 'ExtendedDictionaryOverride';
  conditions: Array<ExtendedDictionaryCondition>;
  template: Scalars['String'];
};

export type ExtendedDictionaryOverrideInput = {
  conditions: Array<ExtendedDictionaryConditionInput>;
  template: Scalars['String'];
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  treatment: Treatment;
  feedback?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  doctor?: Maybe<Doctor>;
};

export type FeedbackQueryInput = {
  patientNo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type FixTreatmentInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  insuranceCards?: Maybe<Array<InsuranceCardInput>>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  pref?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  cardToken?: Maybe<Scalars['String']>;
  fourDigit?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  expAt?: Maybe<Scalars['String']>;
};

export type Followup = {
  __typename?: 'Followup';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body: Scalars['String'];
  treatment: Treatment;
  createdAt?: Maybe<Scalars['String']>;
};

export type FollowupInput = {
  title: Scalars['String'];
  body?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentId: Scalars['String'];
};

export type FollowupResult = {
  __typename?: 'FollowupResult';
  success?: Maybe<Scalars['Boolean']>;
  sent?: Maybe<Scalars['Boolean']>;
};

export type FullAnswerInput = {
  answer?: Maybe<Scalars['String']>;
  option?: Maybe<QuestionOptionInput>;
};

export type GetCheckupTemplateAttachmentSignedPostInput = {
  organizationId: Scalars['String'];
  eventId: Scalars['String'];
  fileName: Scalars['String'];
};

export type GetFeedbackResult = {
  __typename?: 'GetFeedbackResult';
  id?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type GetNextTreatmentPlanQueryInput = {
  department: Scalars['String'];
  clinic: Scalars['String'];
};

export type GetUserThreadInput = {
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  messageType: Scalars['String'];
  startMessageCreatedAt: Scalars['String'];
};

export type GoogleCalendarCoopInput = {
  treatmentId: Scalars['String'];
  email: Scalars['String'];
};

export type ImportInput = {
  schema: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
};

export type ImportJob = {
  __typename?: 'ImportJob';
  schema: Scalars['String'];
  name: Scalars['String'];
  startedAt: Scalars['String'];
  status: ImportJobStatus;
  hasError?: Maybe<Scalars['Boolean']>;
};

export enum ImportJobStatus {
  Started = 'STARTED',
  Abend = 'ABEND',
  Completed = 'COMPLETED'
}

export type ImportResultInput = {
  schema: Scalars['String'];
  id: Scalars['String'];
};

export type Income = {
  __typename?: 'Income';
  patientID?: Maybe<Scalars['String']>;
  asOf?: Maybe<Scalars['String']>;
  incomeInfos?: Maybe<Array<Maybe<IncomeDetail>>>;
};

export type IncomeDetail = {
  __typename?: 'IncomeDetail';
  invoiceNumber?: Maybe<Scalars['String']>;
  acMoney?: Maybe<Scalars['Int']>;
  performDate?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  asOf?: Maybe<Scalars['String']>;
};

/** 指示内容。技師対応、看護対応など。 */
export type Instruction = {
  __typename?: 'Instruction';
  id: Scalars['ID'];
  /** 処置内容 */
  content: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
};

export type InstructionStaffInput = {
  staffId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  iconSrc?: Maybe<Scalars['String']>;
};

export type InsuranceCard = {
  __typename?: 'InsuranceCard';
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type InsuranceCardInput = {
  key: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type InsuranceCardSignedPostQueryInput = {
  userId: Scalars['String'];
  fileName: Scalars['String'];
};

export enum InteractionMessageType {
  Line = 'LINE',
  Email = 'EMAIL'
}

export type Interview = {
  __typename?: 'Interview';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  firstVisit?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Maybe<Question>>>;
  submitted?: Maybe<Scalars['Boolean']>;
  negative_or_positive?: Maybe<NegaPosi>;
  /** 事前問診がBonBonを使ったものでなければtrue */
  isRenewal?: Maybe<Scalars['Boolean']>;
};

export type InterviewAnswer = {
  __typename?: 'InterviewAnswer';
  medicalQuestionnaireInterview: MedicalQuestionnaireInterview;
  answer?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InterviewAnswerChoice = {
  __typename?: 'InterviewAnswerChoice';
  id: Scalars['String'];
  order: Scalars['Int'];
  label: Scalars['String'];
  value: Scalars['String'];
  type?: Maybe<AnswerType>;
  placeholder?: Maybe<Scalars['String']>;
  exit?: Maybe<MedicalQuestionnaireInterview>;
  legacyId?: Maybe<Scalars['Int']>;
};

export type InterviewAnswerChoiceInput = {
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  exit?: Maybe<Scalars['String']>;
};

export type InterviewAnswerInput = {
  medicalQuestionnaireInterview: Scalars['String'];
  answer: Array<Maybe<Scalars['String']>>;
};

export type InterviewAnswerStatus = {
  __typename?: 'InterviewAnswerStatus';
  finished?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Maybe<InterviewAnswer>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type InterviewAnswerStatusInput = {
  finished?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Maybe<InterviewAnswerInput>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type InterviewDownloadableUrlQueryInput = {
  key: Scalars['String'];
};

export type InterviewInput = {
  id?: Maybe<Scalars['String']>;
};

export type InterviewPresignedPostQueryInput = {
  fileName: Scalars['String'];
  interviewId: Scalars['String'];
  questionId: Scalars['String'];
};

export enum InterviewTimeType {
  BeforeReserve = 'BEFORE_RESERVE',
  AfterReserve = 'AFTER_RESERVE'
}

export type IssueUserNonceInput = {
  token: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KickSlackUserConnectionInput = {
  workspaceId: Scalars['String'];
};

export type LineBotMessage = {
  __typename?: 'LineBotMessage';
  key: Scalars['String'];
  message: Scalars['String'];
  example?: Maybe<Scalars['String']>;
  selectClinic?: Maybe<Scalars['Boolean']>;
  childrenKeys: Array<Maybe<Scalars['String']>>;
};

export type LineMessage = {
  __typename?: 'LineMessage';
  id: Scalars['ID'];
  line?: Maybe<LineUser>;
  channelId: Scalars['String'];
  messageText: Scalars['String'];
  createdAt: Scalars['String'];
  messageType: LineMessageSendType;
  accountType: Scalars['String'];
  alreadyRead?: Maybe<LineReadStatus>;
  latest?: Maybe<LineReadStatus>;
  userThread?: Maybe<UserThread>;
  /** jsonだけどぶっこむ */
  event?: Maybe<Scalars['String']>;
  attachment?: Maybe<Attachment>;
  openedByUserAt?: Maybe<Scalars['String']>;
  /** どこ経由か。必ずオール小文字。現在は zendesk と search。 */
  via?: Maybe<Scalars['String']>;
  searchGroupId?: Maybe<Scalars['String']>;
  origin?: Maybe<LineMessageOrigin>;
};

export enum LineMessageOrigin {
  Auto = 'AUTO',
  FreeText = 'FREE_TEXT',
  Receive = 'RECEIVE'
}

export enum LineMessageSendType {
  Send = 'SEND',
  Receive = 'RECEIVE'
}

/**
 * key, clinic, department, treatmentKindの全件一致しているものから検索
 * key, clinic, department, treatmentKindで階層構造を意図
 * departmentが上位層のため、department = undefined, treatmentKind != undefined は想定していない
 * key, clinicIdのみでもmessageを抽出できます
 */
export type LineMessageTemplate = {
  __typename?: 'LineMessageTemplate';
  key: LineTemplateKey;
  clinic: Clinic;
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type LineReadStatus = {
  __typename?: 'LineReadStatus';
  /** userId + channelId */
  id: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
  line?: Maybe<LineUser>;
  alreadyRead?: Maybe<LineMessage>;
  latest?: Maybe<LineMessage>;
  latestUpdatedAt?: Maybe<Scalars['String']>;
  latestThread?: Maybe<UserThread>;
};

export enum LineTemplateKey {
  SameDayNotice = 'SAME_DAY_NOTICE',
  DaysBeforeNotice = 'DAYS_BEFORE_NOTICE'
}

export type LineUser = {
  __typename?: 'LineUser';
  id: Scalars['ID'];
  user?: Maybe<User>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListMedicalQuestionnaireInterviewByListTypeInput = {
  listType: MedicalQuestionnaireInterviewListType;
  domainIdentifier: Scalars['String'];
  interviewTime: Scalars['String'];
};

/** ロケーション。診察室、待合室など */
export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  /** ロケーショングループ情報 */
  groupId: Scalars['ID'];
  locationGroup?: Maybe<LocationGroup>;
  /** ロケーション名 */
  name: Scalars['String'];
  /** クリニックID */
  clinicId: Scalars['String'];
  /** 部屋のタイプ waiting: 待合室, examination: 処置室 */
  roomType: Scalars['String'];
  /** 上から何番目かの表示位置 */
  top: Scalars['Int'];
  /** 左から何番目かの表示位置 */
  left: Scalars['Int'];
};

/** ロケーショングループ。京都2Fなど */
export type LocationGroup = {
  __typename?: 'LocationGroup';
  id: Scalars['ID'];
  /** ロケーショングループ名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** クリニックID */
  clinicId: Scalars['String'];
};

export type MedicalInfoPresignedPostQueryInput = {
  treatmentId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
};

/**
 * 予約前確認と事前問診の設問
 * 予約前確認と事前問診はTreatmentに紐づく設問
 */
export type MedicalQuestionnaireInterview = {
  __typename?: 'MedicalQuestionnaireInterview';
  domainIdentifier: Scalars['String'];
  interviewTime: InterviewTimeType;
  /**
   * idはMedicalQuestionnaireInterviewでユニークのID
   * legacyIdがある場合は、文字列化したものを格納
   */
  id: Scalars['String'];
  department?: Maybe<Department>;
  treatmentKind?: Maybe<TreatmentKind>;
  order: Scalars['Int'];
  question: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  answerChoices?: Maybe<Array<Maybe<InterviewAnswerChoice>>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  previousInterviews?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultExit?: Maybe<MedicalQuestionnaireInterview>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
};

/** 予約前確認と事前問診の回答 */
export type MedicalQuestionnaireInterviewAnswers = {
  __typename?: 'MedicalQuestionnaireInterviewAnswers';
  id: Scalars['String'];
  user?: Maybe<User>;
  beforeReserve?: Maybe<InterviewAnswerStatus>;
  afterReserve?: Maybe<InterviewAnswerStatus>;
};

export enum MedicalQuestionnaireInterviewDeleteActionType {
  DeleteDraft = 'DELETE_DRAFT'
}

export enum MedicalQuestionnaireInterviewListType {
  Draft = 'DRAFT',
  Production = 'PRODUCTION'
}

export type MedicalQuestionnaireInterviewResult = {
  __typename?: 'MedicalQuestionnaireInterviewResult';
  domainIdentifier: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  interviewTime: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  question: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  answerChoices?: Maybe<Array<Maybe<AnswerChoiceResult>>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  previousInterviews?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultExit?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export enum MedicalQuestionnaireInterviewUpdateActionType {
  ToDraft = 'TO_DRAFT',
  ToProduction = 'TO_PRODUCTION'
}

/**
 * アンケートの設問
 * アンケートはUserに紐づく設問
 */
export type MedicalQuestionnaireSurvey = {
  __typename?: 'MedicalQuestionnaireSurvey';
  surveyTime: SurveyTimeType;
  /**
   * MedicalQuestionnaireInterviewでユニークのID
   * legacyIdがある場合は、文字列化したものを格納
   */
  id: Scalars['String'];
  order: Scalars['Int'];
  question: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  answerChoices?: Maybe<Array<Maybe<SurveyAnswerChoice>>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  previousSurveys?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultExit?: Maybe<MedicalQuestionnaireSurvey>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
};

/** アンケートの回答 */
export type MedicalQuestionnaireSurveyAnswers = {
  __typename?: 'MedicalQuestionnaireSurveyAnswers';
  id: Scalars['String'];
  user?: Maybe<User>;
  answers?: Maybe<SurveyAnswerStatus>;
};

export type MedicalQuestionnaireSurveyResult = {
  __typename?: 'MedicalQuestionnaireSurveyResult';
  surveyTime: SurveyTimeType;
  id: Scalars['String'];
  order: Scalars['Int'];
  question: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  answerChoices?: Maybe<Array<Maybe<AnswerChoiceResult>>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  previousSurveys?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultExit?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
};

export type ModelAttachmentConditionInput = {
  id?: Maybe<ModelIdInput>;
  key?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  type?: Maybe<ModelAttachmentTypeInput>;
  and?: Maybe<Array<Maybe<ModelAttachmentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelAttachmentConditionInput>>>;
  not?: Maybe<ModelAttachmentConditionInput>;
};

export type ModelAttachmentConnection = {
  __typename?: 'ModelAttachmentConnection';
  items?: Maybe<Array<Maybe<Attachment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAttachmentFilterInput = {
  id?: Maybe<ModelIdInput>;
  key?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  type?: Maybe<ModelAttachmentTypeInput>;
  and?: Maybe<Array<Maybe<ModelAttachmentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelAttachmentFilterInput>>>;
  not?: Maybe<ModelAttachmentFilterInput>;
};

export type ModelAttachmentTypeInput = {
  eq?: Maybe<AttachmentType>;
  ne?: Maybe<AttachmentType>;
};

export enum ModelAttributeTypes {
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet',
  Null = '_null'
}

export type ModelAutoNumberConditionInput = {
  clinic?: Maybe<ModelStringInput>;
  schema?: Maybe<ModelStringInput>;
  current?: Maybe<ModelIntInput>;
  digits?: Maybe<ModelIntInput>;
  prefix?: Maybe<ModelStringInput>;
  suffix?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelAutoNumberConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelAutoNumberConditionInput>>>;
  not?: Maybe<ModelAutoNumberConditionInput>;
};

export type ModelAutoNumberConnection = {
  __typename?: 'ModelAutoNumberConnection';
  items?: Maybe<Array<Maybe<AutoNumber>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAutoNumberFilterInput = {
  clinic?: Maybe<ModelStringInput>;
  schema?: Maybe<ModelStringInput>;
  current?: Maybe<ModelIntInput>;
  digits?: Maybe<ModelIntInput>;
  prefix?: Maybe<ModelStringInput>;
  suffix?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelAutoNumberFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelAutoNumberFilterInput>>>;
  not?: Maybe<ModelAutoNumberFilterInput>;
};

export type ModelBlockConditionInput = {
  date?: Maybe<ModelStringInput>;
  lane?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  reason?: Maybe<ModelStringInput>;
  color?: Maybe<ModelStringInput>;
  duration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelBlockConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelBlockConditionInput>>>;
  not?: Maybe<ModelBlockConditionInput>;
};

export type ModelBlockConnection = {
  __typename?: 'ModelBlockConnection';
  items?: Maybe<Array<Maybe<Block>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelBlockFilterInput = {
  date?: Maybe<ModelStringInput>;
  lane?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  reason?: Maybe<ModelStringInput>;
  color?: Maybe<ModelStringInput>;
  duration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelBlockFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelBlockFilterInput>>>;
  not?: Maybe<ModelBlockFilterInput>;
};

export type ModelBooleanInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelCardConditionInput = {
  id?: Maybe<ModelIdInput>;
  fourDigit?: Maybe<ModelStringInput>;
  brand?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  expAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCardConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCardConditionInput>>>;
  not?: Maybe<ModelCardConditionInput>;
};

export type ModelCardConnection = {
  __typename?: 'ModelCardConnection';
  items?: Maybe<Array<Maybe<Card>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCardFilterInput = {
  id?: Maybe<ModelIdInput>;
  fourDigit?: Maybe<ModelStringInput>;
  brand?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  expAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCardFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCardFilterInput>>>;
  not?: Maybe<ModelCardFilterInput>;
};

export type ModelChatToolInput = {
  eq?: Maybe<ChatTool>;
  ne?: Maybe<ChatTool>;
};

export type ModelChatWorkspaceTypeInput = {
  eq?: Maybe<ChatWorkspaceType>;
  ne?: Maybe<ChatWorkspaceType>;
};

export type ModelCheckupAppointmentConditionInput = {
  id?: Maybe<ModelIdInput>;
  birthDate?: Maybe<ModelStringInput>;
  address?: Maybe<ModelStringInput>;
  kitDeliverStatus?: Maybe<ModelCheckupKitDeliverStatusInput>;
  kitDeliverDate?: Maybe<ModelStringInput>;
  treatmentStatus?: Maybe<ModelTreatmentStatusInput>;
  planPrice?: Maybe<ModelIntInput>;
  planBillType?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupAppointmentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupAppointmentConditionInput>>>;
  not?: Maybe<ModelCheckupAppointmentConditionInput>;
};

export type ModelCheckupAppointmentConnection = {
  __typename?: 'ModelCheckupAppointmentConnection';
  items?: Maybe<Array<Maybe<CheckupAppointment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupAppointmentFilterInput = {
  id?: Maybe<ModelIdInput>;
  birthDate?: Maybe<ModelStringInput>;
  address?: Maybe<ModelStringInput>;
  kitDeliverStatus?: Maybe<ModelCheckupKitDeliverStatusInput>;
  kitDeliverDate?: Maybe<ModelStringInput>;
  treatmentStatus?: Maybe<ModelTreatmentStatusInput>;
  planPrice?: Maybe<ModelIntInput>;
  planBillType?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupAppointmentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupAppointmentFilterInput>>>;
  not?: Maybe<ModelCheckupAppointmentFilterInput>;
};

export type ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>>>;
  eq?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
  ge?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
  gt?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
  le?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
  lt?: Maybe<ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput>;
};

export type ModelCheckupAppointmentKitDeliverStatusAndDateCompositeKeyInput = {
  createdAt?: Maybe<Scalars['String']>;
};

export type ModelCheckupCriteriaComparatorInput = {
  eq?: Maybe<CheckupCriteriaComparator>;
  ne?: Maybe<CheckupCriteriaComparator>;
};

export type ModelCheckupInterviewQuestionConditionInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  confirm_text?: Maybe<ModelStringInput>;
  additional_column_name?: Maybe<ModelStringInput>;
  additional_type?: Maybe<ModelStringInput>;
  /**
   * BeforeReserving: '1_BEFORE_RESERVING',
   * AfterReserving: '2_AFTER_RESERVING',
   */
  timing?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  hidden?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupInterviewQuestionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupInterviewQuestionConditionInput>>>;
  not?: Maybe<ModelCheckupInterviewQuestionConditionInput>;
};

export type ModelCheckupInterviewQuestionConnection = {
  __typename?: 'ModelCheckupInterviewQuestionConnection';
  items?: Maybe<Array<Maybe<CheckupInterviewQuestion>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupInterviewQuestionFilterInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  confirm_text?: Maybe<ModelStringInput>;
  additional_column_name?: Maybe<ModelStringInput>;
  additional_type?: Maybe<ModelStringInput>;
  /**
   * BeforeReserving: '1_BEFORE_RESERVING',
   * AfterReserving: '2_AFTER_RESERVING',
   */
  timing?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  hidden?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupInterviewQuestionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupInterviewQuestionFilterInput>>>;
  not?: Maybe<ModelCheckupInterviewQuestionFilterInput>;
};

export type ModelCheckupKitDeliverStatusInput = {
  eq?: Maybe<CheckupKitDeliverStatus>;
  ne?: Maybe<CheckupKitDeliverStatus>;
};

export type ModelCheckupOptionConditionInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  requiredMin?: Maybe<ModelIntInput>;
  clinicType?: Maybe<ModelClinicTypeInput>;
  regularPrice?: Maybe<ModelIntInput>;
  description?: Maybe<ModelStringInput>;
  gender?: Maybe<ModelStringInput>;
  aboutDisplay?: Maybe<ModelBooleanInput>;
  annotation?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOptionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOptionConditionInput>>>;
  not?: Maybe<ModelCheckupOptionConditionInput>;
};

export type ModelCheckupOptionConnection = {
  __typename?: 'ModelCheckupOptionConnection';
  items?: Maybe<Array<Maybe<CheckupOption>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOptionFilterInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  requiredMin?: Maybe<ModelIntInput>;
  clinicType?: Maybe<ModelClinicTypeInput>;
  regularPrice?: Maybe<ModelIntInput>;
  description?: Maybe<ModelStringInput>;
  gender?: Maybe<ModelStringInput>;
  aboutDisplay?: Maybe<ModelBooleanInput>;
  annotation?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOptionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOptionFilterInput>>>;
  not?: Maybe<ModelCheckupOptionFilterInput>;
};

export type ModelCheckupOptionIndividualConditionInput = {
  id?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOptionIndividualConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOptionIndividualConditionInput>>>;
  not?: Maybe<ModelCheckupOptionIndividualConditionInput>;
};

export type ModelCheckupOptionIndividualConnection = {
  __typename?: 'ModelCheckupOptionIndividualConnection';
  items?: Maybe<Array<Maybe<CheckupOptionIndividual>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOptionIndividualFilterInput = {
  id?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOptionIndividualFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOptionIndividualFilterInput>>>;
  not?: Maybe<ModelCheckupOptionIndividualFilterInput>;
};

export type ModelCheckupOrganizationChatWorkspaceConditionInput = {
  checkupOrganizationId?: Maybe<ModelStringInput>;
  workspaceId?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  type?: Maybe<ModelChatWorkspaceTypeInput>;
  accessToken?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>;
};

export type ModelCheckupOrganizationChatWorkspaceConnection = {
  __typename?: 'ModelCheckupOrganizationChatWorkspaceConnection';
  items?: Maybe<Array<Maybe<CheckupOrganizationChatWorkspace>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationChatWorkspaceFilterInput = {
  checkupOrganizationId?: Maybe<ModelStringInput>;
  workspaceId?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  type?: Maybe<ModelChatWorkspaceTypeInput>;
  accessToken?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationChatWorkspaceFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationChatWorkspaceFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationChatWorkspaceFilterInput>;
};

export type ModelCheckupOrganizationConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  staffName?: Maybe<ModelStringInput>;
  staffTel?: Maybe<ModelStringInput>;
  staffEmail?: Maybe<ModelStringInput>;
  userCreatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationConditionInput>;
};

export type ModelCheckupOrganizationConnection = {
  __typename?: 'ModelCheckupOrganizationConnection';
  items?: Maybe<Array<Maybe<CheckupOrganization>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationEventConditionInput = {
  /** UUID */
  id?: Maybe<ModelIdInput>;
  /** 名称（「2022年度」等） */
  name?: Maybe<ModelStringInput>;
  /**
   * 年度（2022等）
   * 年度の生成時にシーケンスとして利用する
   */
  year?: Maybe<ModelIntInput>;
  /** 年度 + 期首で算出した開始日 */
  start?: Maybe<ModelStringInput>;
  /** 年度 + 期首で算出した終了日 */
  end?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  /** プラン負担者設定の「デフォルト」 */
  planBillType?: Maybe<ModelStringInput>;
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプの「デフォルト」 */
  kitDeliverType?: Maybe<ModelStringInput>;
  zip?: Maybe<ModelStringInput>;
  /** キット送付先の住所 */
  address?: Maybe<ModelStringInput>;
  /** 希望開始月（年月） */
  desiredStartTerm?: Maybe<ModelStringInput>;
  /** 希望終了月（年月） */
  desiredEndTerm?: Maybe<ModelStringInput>;
  /** メモ */
  notedByOrganization?: Maybe<ModelStringInput>;
  /** 社内メモ */
  notedByAdmin?: Maybe<ModelStringInput>;
  /** 適用済みディスカウント */
  discountCode?: Maybe<ModelStringInput>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<ModelBooleanInput>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<ModelBooleanInput>;
  startMonth?: Maybe<ModelIntInput>;
  /** 組合 */
  union?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventConditionInput>;
};

export type ModelCheckupOrganizationEventConnection = {
  __typename?: 'ModelCheckupOrganizationEventConnection';
  items?: Maybe<Array<Maybe<CheckupOrganizationEvent>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationEventFilterInput = {
  /** UUID */
  id?: Maybe<ModelIdInput>;
  /** 名称（「2022年度」等） */
  name?: Maybe<ModelStringInput>;
  /**
   * 年度（2022等）
   * 年度の生成時にシーケンスとして利用する
   */
  year?: Maybe<ModelIntInput>;
  /** 年度 + 期首で算出した開始日 */
  start?: Maybe<ModelStringInput>;
  /** 年度 + 期首で算出した終了日 */
  end?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  /** プラン負担者設定の「デフォルト」 */
  planBillType?: Maybe<ModelStringInput>;
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプの「デフォルト」 */
  kitDeliverType?: Maybe<ModelStringInput>;
  zip?: Maybe<ModelStringInput>;
  /** キット送付先の住所 */
  address?: Maybe<ModelStringInput>;
  /** 希望開始月（年月） */
  desiredStartTerm?: Maybe<ModelStringInput>;
  /** 希望終了月（年月） */
  desiredEndTerm?: Maybe<ModelStringInput>;
  /** メモ */
  notedByOrganization?: Maybe<ModelStringInput>;
  /** 社内メモ */
  notedByAdmin?: Maybe<ModelStringInput>;
  /** 適用済みディスカウント */
  discountCode?: Maybe<ModelStringInput>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<ModelBooleanInput>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<ModelBooleanInput>;
  startMonth?: Maybe<ModelIntInput>;
  /** 組合 */
  union?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventFilterInput>;
};

export type ModelCheckupOrganizationEventOptionConditionInput = {
  id?: Maybe<ModelIdInput>;
  billType?: Maybe<ModelStringInput>;
  price?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventOptionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventOptionConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventOptionConditionInput>;
};

export type ModelCheckupOrganizationEventOptionConnection = {
  __typename?: 'ModelCheckupOrganizationEventOptionConnection';
  items?: Maybe<Array<Maybe<CheckupOrganizationEventOption>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationEventOptionFilterInput = {
  id?: Maybe<ModelIdInput>;
  billType?: Maybe<ModelStringInput>;
  price?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventOptionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventOptionFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventOptionFilterInput>;
};

export type ModelCheckupOrganizationEventTemplateConditionInput = {
  /** 「案内」「リマインド」等。 */
  templateKind?: Maybe<ModelTemplateKindInput>;
  /** テンプレート本文 */
  template?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<ModelBooleanInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<ModelStringInput>;
  /** 識別名 */
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventTemplateConditionInput>;
};

export type ModelCheckupOrganizationEventTemplateConnection = {
  __typename?: 'ModelCheckupOrganizationEventTemplateConnection';
  items?: Maybe<Array<Maybe<CheckupOrganizationEventTemplate>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationEventTemplateFilterInput = {
  /** 「案内」「リマインド」等。 */
  templateKind?: Maybe<ModelTemplateKindInput>;
  /** テンプレート本文 */
  template?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<ModelBooleanInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<ModelStringInput>;
  /** 識別名 */
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventTemplateFilterInput>;
};

export type ModelCheckupOrganizationEventTemplateV2ConditionInput = {
  id?: Maybe<ModelIdInput>;
  /** 「案内」「リマインド」等。 */
  templateKind?: Maybe<ModelTemplateKindInput>;
  /** テンプレート本文 */
  template?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<ModelBooleanInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<ModelStringInput>;
  /** 識別名 */
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>;
};

export type ModelCheckupOrganizationEventTemplateV2Connection = {
  __typename?: 'ModelCheckupOrganizationEventTemplateV2Connection';
  items?: Maybe<Array<Maybe<CheckupOrganizationEventTemplateV2>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationEventTemplateV2FilterInput = {
  id?: Maybe<ModelIdInput>;
  /** 「案内」「リマインド」等。 */
  templateKind?: Maybe<ModelTemplateKindInput>;
  /** テンプレート本文 */
  template?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<ModelBooleanInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<ModelStringInput>;
  /** 識別名 */
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateV2FilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationEventTemplateV2FilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationEventTemplateV2FilterInput>;
};

export type ModelCheckupOrganizationFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  staffName?: Maybe<ModelStringInput>;
  staffTel?: Maybe<ModelStringInput>;
  staffEmail?: Maybe<ModelStringInput>;
  userCreatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationFilterInput>;
};

export type ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>>>;
  eq?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
  ge?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
  gt?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
  le?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
  lt?: Maybe<ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput>;
};

export type ModelCheckupOrganizationMemberEventCheckupMemberRemindCompositeKeyInput = {
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationMemberEventConditionInput = {
  /** UID 「受診者番号」 */
  id?: Maybe<ModelIdInput>;
  /** 別システムでの一意キー */
  employeeNo?: Maybe<ModelStringInput>;
  checkupOrganizationId?: Maybe<ModelStringInput>;
  /** 氏名 */
  name?: Maybe<ModelStringInput>;
  /** メールアドレス + slackと照合する用のキー */
  email?: Maybe<ModelStringInput>;
  zip?: Maybe<ModelStringInput>;
  /** キット送付先住所 */
  address?: Maybe<ModelStringInput>;
  /** 初回案内送付日時（= 確定日時） */
  invitedAt?: Maybe<ModelStringInput>;
  /** チャットツール上のハンドルネーム */
  chatName?: Maybe<ModelStringInput>;
  /** チャットツール上のID */
  chatId?: Maybe<ModelStringInput>;
  /** プラン負担者 */
  planBillType?: Maybe<ModelStringInput>;
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプ */
  kitDeliverType?: Maybe<ModelStringInput>;
  nextRemindOn?: Maybe<ModelStringInput>;
  /** 前回リマインド日 */
  recentRemindedAt?: Maybe<ModelStringInput>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<ModelBooleanInput>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<ModelBooleanInput>;
  /** 受診予定期間 */
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  /** 事業所 */
  office?: Maybe<ModelStringInput>;
  /** 部門 */
  department?: Maybe<ModelStringInput>;
  /** 雇入年月日 */
  hiringDate?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationMemberEventConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationMemberEventConditionInput>>>;
  not?: Maybe<ModelCheckupOrganizationMemberEventConditionInput>;
};

export type ModelCheckupOrganizationMemberEventConnection = {
  __typename?: 'ModelCheckupOrganizationMemberEventConnection';
  items?: Maybe<Array<Maybe<CheckupOrganizationMemberEvent>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupOrganizationMemberEventFilterInput = {
  /** UID 「受診者番号」 */
  id?: Maybe<ModelIdInput>;
  /** 別システムでの一意キー */
  employeeNo?: Maybe<ModelStringInput>;
  checkupOrganizationId?: Maybe<ModelStringInput>;
  /** 氏名 */
  name?: Maybe<ModelStringInput>;
  /** メールアドレス + slackと照合する用のキー */
  email?: Maybe<ModelStringInput>;
  zip?: Maybe<ModelStringInput>;
  /** キット送付先住所 */
  address?: Maybe<ModelStringInput>;
  /** 初回案内送付日時（= 確定日時） */
  invitedAt?: Maybe<ModelStringInput>;
  /** チャットツール上のハンドルネーム */
  chatName?: Maybe<ModelStringInput>;
  /** チャットツール上のID */
  chatId?: Maybe<ModelStringInput>;
  /** プラン負担者 */
  planBillType?: Maybe<ModelStringInput>;
  /** 会社負担オプション設定 */
  expenseOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** キット送付タイプ */
  kitDeliverType?: Maybe<ModelStringInput>;
  nextRemindOn?: Maybe<ModelStringInput>;
  /** 前回リマインド日 */
  recentRemindedAt?: Maybe<ModelStringInput>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<ModelBooleanInput>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<ModelBooleanInput>;
  /** 受診予定期間 */
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  /** 事業所 */
  office?: Maybe<ModelStringInput>;
  /** 部門 */
  department?: Maybe<ModelStringInput>;
  /** 雇入年月日 */
  hiringDate?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupOrganizationMemberEventFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupOrganizationMemberEventFilterInput>>>;
  not?: Maybe<ModelCheckupOrganizationMemberEventFilterInput>;
};

export type ModelCheckupPlanConditionInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  price?: Maybe<ModelIntInput>;
  description?: Maybe<ModelStringInput>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentKindId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  hideForOrganization?: Maybe<ModelBooleanInput>;
  hideForIndividual?: Maybe<ModelBooleanInput>;
  urinalysisKit?: Maybe<ModelBooleanInput>;
  stoolKit?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupPlanConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupPlanConditionInput>>>;
  not?: Maybe<ModelCheckupPlanConditionInput>;
};

export type ModelCheckupPlanConnection = {
  __typename?: 'ModelCheckupPlanConnection';
  items?: Maybe<Array<Maybe<CheckupPlan>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupPlanFilterInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  price?: Maybe<ModelIntInput>;
  description?: Maybe<ModelStringInput>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentKindId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  hideForOrganization?: Maybe<ModelBooleanInput>;
  hideForIndividual?: Maybe<ModelBooleanInput>;
  urinalysisKit?: Maybe<ModelBooleanInput>;
  stoolKit?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupPlanFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupPlanFilterInput>>>;
  not?: Maybe<ModelCheckupPlanFilterInput>;
};

export type ModelCheckupPlanIndividualConditionInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupPlanIndividualConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupPlanIndividualConditionInput>>>;
  not?: Maybe<ModelCheckupPlanIndividualConditionInput>;
};

export type ModelCheckupPlanIndividualConnection = {
  __typename?: 'ModelCheckupPlanIndividualConnection';
  items?: Maybe<Array<Maybe<CheckupPlanIndividual>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupPlanIndividualFilterInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelCheckupPlanIndividualFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupPlanIndividualFilterInput>>>;
  not?: Maybe<ModelCheckupPlanIndividualFilterInput>;
};

export type ModelCheckupRemindScheduleIntervalInput = {
  eq?: Maybe<CheckupRemindScheduleInterval>;
  ne?: Maybe<CheckupRemindScheduleInterval>;
};

export type ModelCheckupResultCheckupResultStatusCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>>>;
  eq?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
  ge?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
  gt?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
  le?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
  lt?: Maybe<ModelCheckupResultCheckupResultStatusCompositeKeyInput>;
};

export type ModelCheckupResultCheckupResultStatusCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultConditionInput = {
  patientNo?: Maybe<ModelStringInput>;
  patientName?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  label?: Maybe<ModelStringInput>;
  valueStr?: Maybe<ModelStringInput>;
  valueNum?: Maybe<ModelFloatInput>;
  unit?: Maybe<ModelStringInput>;
  status?: Maybe<ModelCheckupResultStatusInput>;
  dataSource?: Maybe<ModelStringInput>;
  errorMessage?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultConditionInput>>>;
  not?: Maybe<ModelCheckupResultConditionInput>;
};

export type ModelCheckupResultConnection = {
  __typename?: 'ModelCheckupResultConnection';
  items?: Maybe<Array<Maybe<CheckupResult>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultDisplayStrategyInput = {
  eq?: Maybe<CheckupResultDisplayStrategy>;
  ne?: Maybe<CheckupResultDisplayStrategy>;
};

export type ModelCheckupResultFilterInput = {
  patientNo?: Maybe<ModelStringInput>;
  patientName?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  label?: Maybe<ModelStringInput>;
  valueStr?: Maybe<ModelStringInput>;
  valueNum?: Maybe<ModelFloatInput>;
  unit?: Maybe<ModelStringInput>;
  status?: Maybe<ModelCheckupResultStatusInput>;
  dataSource?: Maybe<ModelStringInput>;
  errorMessage?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultFilterInput>>>;
  not?: Maybe<ModelCheckupResultFilterInput>;
};

export type ModelCheckupResultItemConditionInput = {
  id?: Maybe<ModelIdInput>;
  subTitle?: Maybe<ModelStringInput>;
  displayName?: Maybe<ModelStringInput>;
  mappingName?: Maybe<ModelStringInput>;
  mappingStrategy?: Maybe<ModelCheckupResultItemMappingStrategyInput>;
  unit?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  reference?: Maybe<ModelStringInput>;
  ignore?: Maybe<ModelBooleanInput>;
  displayStrategy?: Maybe<ModelCheckupResultDisplayStrategyInput>;
  pairName?: Maybe<ModelStringInput>;
  halfName?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  nurse?: Maybe<ModelBooleanInput>;
  doctor?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemConditionInput>>>;
  not?: Maybe<ModelCheckupResultItemConditionInput>;
};

export type ModelCheckupResultItemConnection = {
  __typename?: 'ModelCheckupResultItemConnection';
  items?: Maybe<Array<Maybe<CheckupResultItem>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultItemFilterInput = {
  id?: Maybe<ModelIdInput>;
  subTitle?: Maybe<ModelStringInput>;
  displayName?: Maybe<ModelStringInput>;
  mappingName?: Maybe<ModelStringInput>;
  mappingStrategy?: Maybe<ModelCheckupResultItemMappingStrategyInput>;
  unit?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  reference?: Maybe<ModelStringInput>;
  ignore?: Maybe<ModelBooleanInput>;
  displayStrategy?: Maybe<ModelCheckupResultDisplayStrategyInput>;
  pairName?: Maybe<ModelStringInput>;
  halfName?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  nurse?: Maybe<ModelBooleanInput>;
  doctor?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemFilterInput>>>;
  not?: Maybe<ModelCheckupResultItemFilterInput>;
};

export type ModelCheckupResultItemGroupConditionInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  friendlyName?: Maybe<ModelStringInput>;
  technicalName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemGroupConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemGroupConditionInput>>>;
  not?: Maybe<ModelCheckupResultItemGroupConditionInput>;
};

export type ModelCheckupResultItemGroupConnection = {
  __typename?: 'ModelCheckupResultItemGroupConnection';
  items?: Maybe<Array<Maybe<CheckupResultItemGroup>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultItemGroupFilterInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  friendlyName?: Maybe<ModelStringInput>;
  technicalName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemGroupFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemGroupFilterInput>>>;
  not?: Maybe<ModelCheckupResultItemGroupFilterInput>;
};

export type ModelCheckupResultItemMappingStrategyInput = {
  eq?: Maybe<CheckupResultItemMappingStrategy>;
  ne?: Maybe<CheckupResultItemMappingStrategy>;
};

export type ModelCheckupResultItemSubGroupConditionInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  strategy?: Maybe<ModelCheckupResultSubGroupJudgeStrategyInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemSubGroupConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemSubGroupConditionInput>>>;
  not?: Maybe<ModelCheckupResultItemSubGroupConditionInput>;
};

export type ModelCheckupResultItemSubGroupConnection = {
  __typename?: 'ModelCheckupResultItemSubGroupConnection';
  items?: Maybe<Array<Maybe<CheckupResultItemSubGroup>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultItemSubGroupFilterInput = {
  id?: Maybe<ModelIdInput>;
  order?: Maybe<ModelIntInput>;
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  strategy?: Maybe<ModelCheckupResultSubGroupJudgeStrategyInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultItemSubGroupFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultItemSubGroupFilterInput>>>;
  not?: Maybe<ModelCheckupResultItemSubGroupFilterInput>;
};

export type ModelCheckupResultPrimaryKeyCompositeKeyConditionInput = {
  eq?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
  le?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
  lt?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
  ge?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
  gt?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyInput>;
};

export type ModelCheckupResultPrimaryKeyCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>>>;
  eq?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
  ge?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
  gt?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
  le?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
  lt?: Maybe<ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput>;
};

export type ModelCheckupResultSetCheckupResultSetStatusDateCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultSetConditionInput = {
  /** CheckupAppointmentのID */
  id?: Maybe<ModelIdInput>;
  /** 検査日 */
  date?: Maybe<ModelStringInput>;
  status?: Maybe<ModelCheckupResultSetStatusInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultSetConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultSetConditionInput>>>;
  not?: Maybe<ModelCheckupResultSetConditionInput>;
};

export type ModelCheckupResultSetConnection = {
  __typename?: 'ModelCheckupResultSetConnection';
  items?: Maybe<Array<Maybe<CheckupResultSet>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupResultSetFilterInput = {
  /** CheckupAppointmentのID */
  id?: Maybe<ModelIdInput>;
  /** 検査日 */
  date?: Maybe<ModelStringInput>;
  status?: Maybe<ModelCheckupResultSetStatusInput>;
  and?: Maybe<Array<Maybe<ModelCheckupResultSetFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupResultSetFilterInput>>>;
  not?: Maybe<ModelCheckupResultSetFilterInput>;
};

export type ModelCheckupResultSetStatusInput = {
  eq?: Maybe<CheckupResultSetStatus>;
  ne?: Maybe<CheckupResultSetStatus>;
};

export type ModelCheckupResultStatusInput = {
  eq?: Maybe<CheckupResultStatus>;
  ne?: Maybe<CheckupResultStatus>;
};

export type ModelCheckupResultSubGroupJudgeStrategyInput = {
  eq?: Maybe<CheckupResultSubGroupJudgeStrategy>;
  ne?: Maybe<CheckupResultSubGroupJudgeStrategy>;
};

export type ModelCheckupUnionConditionInput = {
  id?: Maybe<ModelIdInput>;
  unions: Array<Scalars['String']>;
  and?: Maybe<Array<Maybe<ModelCheckupUnionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupUnionConditionInput>>>;
  not?: Maybe<ModelCheckupUnionConditionInput>;
};

export type ModelCheckupUnionConnection = {
  __typename?: 'ModelCheckupUnionConnection';
  items?: Maybe<Array<Maybe<CheckupUnion>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCheckupUnionFilterInput = {
  id?: Maybe<ModelIdInput>;
  unions: Array<Scalars['String']>;
  and?: Maybe<Array<Maybe<ModelCheckupUnionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCheckupUnionFilterInput>>>;
  not?: Maybe<ModelCheckupUnionFilterInput>;
};

export type ModelChoiceTypeInput = {
  eq?: Maybe<ChoiceType>;
};

export type ModelClinicConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  address?: Maybe<ModelStringInput>;
  lat?: Maybe<ModelFloatInput>;
  lng?: Maybe<ModelFloatInput>;
  mapUrl?: Maybe<ModelStringInput>;
  termsUrl?: Maybe<ModelStringInput>;
  reviewUrl?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelClinicConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelClinicConditionInput>>>;
  not?: Maybe<ModelClinicConditionInput>;
};

export type ModelClinicConnection = {
  __typename?: 'ModelClinicConnection';
  items?: Maybe<Array<Maybe<Clinic>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelClinicFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  address?: Maybe<ModelStringInput>;
  lat?: Maybe<ModelFloatInput>;
  lng?: Maybe<ModelFloatInput>;
  mapUrl?: Maybe<ModelStringInput>;
  termsUrl?: Maybe<ModelStringInput>;
  reviewUrl?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelClinicFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelClinicFilterInput>>>;
  not?: Maybe<ModelClinicFilterInput>;
};

export type ModelClinicMemoConditionInput = {
  clinic?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  memo?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelClinicMemoConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelClinicMemoConditionInput>>>;
  not?: Maybe<ModelClinicMemoConditionInput>;
};

export type ModelClinicMemoConnection = {
  __typename?: 'ModelClinicMemoConnection';
  items?: Maybe<Array<Maybe<ClinicMemo>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelClinicMemoFilterInput = {
  clinic?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  memo?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelClinicMemoFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelClinicMemoFilterInput>>>;
  not?: Maybe<ModelClinicMemoFilterInput>;
};

export type ModelClinicTypeInput = {
  eq?: Maybe<ClinicType>;
  ne?: Maybe<ClinicType>;
};

export type ModelCustomerConditionInput = {
  id?: Maybe<ModelIdInput>;
  defaultCard?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCustomerConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelCustomerConditionInput>>>;
  not?: Maybe<ModelCustomerConditionInput>;
};

export type ModelCustomerConnection = {
  __typename?: 'ModelCustomerConnection';
  items?: Maybe<Array<Maybe<Customer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCustomerFilterInput = {
  id?: Maybe<ModelIdInput>;
  defaultCard?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelCustomerFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCustomerFilterInput>>>;
  not?: Maybe<ModelCustomerFilterInput>;
};

export type ModelDentalEvaluationConditionInput = {
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDentalEvaluationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDentalEvaluationConditionInput>>>;
  not?: Maybe<ModelDentalEvaluationConditionInput>;
};

export type ModelDentalEvaluationConnection = {
  __typename?: 'ModelDentalEvaluationConnection';
  items?: Maybe<Array<Maybe<DentalEvaluation>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDentalEvaluationFilterInput = {
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDentalEvaluationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDentalEvaluationFilterInput>>>;
  not?: Maybe<ModelDentalEvaluationFilterInput>;
};

export type ModelDentalEvaluationItemsConditionInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDentalEvaluationItemsConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDentalEvaluationItemsConditionInput>>>;
  not?: Maybe<ModelDentalEvaluationItemsConditionInput>;
};

export type ModelDentalEvaluationItemsConnection = {
  __typename?: 'ModelDentalEvaluationItemsConnection';
  items?: Maybe<Array<Maybe<DentalEvaluationItems>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDentalEvaluationItemsFilterInput = {
  id?: Maybe<ModelIdInput>;
  label?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDentalEvaluationItemsFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDentalEvaluationItemsFilterInput>>>;
  not?: Maybe<ModelDentalEvaluationItemsFilterInput>;
};

export type ModelDepartmentConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  type?: Maybe<ModelDepartmentTypeInput>;
  baseDuration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelDepartmentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDepartmentConditionInput>>>;
  not?: Maybe<ModelDepartmentConditionInput>;
};

export type ModelDepartmentConnection = {
  __typename?: 'ModelDepartmentConnection';
  items?: Maybe<Array<Maybe<Department>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDepartmentFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  type?: Maybe<ModelDepartmentTypeInput>;
  baseDuration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelDepartmentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDepartmentFilterInput>>>;
  not?: Maybe<ModelDepartmentFilterInput>;
};

export type ModelDepartmentOpenMonthKeyConditionInput = {
  eq?: Maybe<ModelDepartmentOpenMonthKeyInput>;
  le?: Maybe<ModelDepartmentOpenMonthKeyInput>;
  lt?: Maybe<ModelDepartmentOpenMonthKeyInput>;
  ge?: Maybe<ModelDepartmentOpenMonthKeyInput>;
  gt?: Maybe<ModelDepartmentOpenMonthKeyInput>;
  between?: Maybe<Array<Maybe<ModelDepartmentOpenMonthKeyInput>>>;
  beginsWith?: Maybe<ModelDepartmentOpenMonthKeyInput>;
};

export type ModelDepartmentOpenMonthKeyInput = {
  yearMonth?: Maybe<Scalars['String']>;
};

export type ModelDepartmentTypeInput = {
  eq?: Maybe<DepartmentType>;
  ne?: Maybe<DepartmentType>;
};

export type ModelDiscountCodeConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  memo?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDiscountCodeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDiscountCodeConditionInput>>>;
  not?: Maybe<ModelDiscountCodeConditionInput>;
};

export type ModelDiscountCodeConnection = {
  __typename?: 'ModelDiscountCodeConnection';
  items?: Maybe<Array<Maybe<DiscountCode>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDiscountCodeFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  memo?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDiscountCodeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDiscountCodeFilterInput>>>;
  not?: Maybe<ModelDiscountCodeFilterInput>;
};

export type ModelDoctorConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinic?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDoctorConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDoctorConditionInput>>>;
  not?: Maybe<ModelDoctorConditionInput>;
};

export type ModelDoctorConnection = {
  __typename?: 'ModelDoctorConnection';
  items?: Maybe<Array<Maybe<Doctor>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDoctorFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinic?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDoctorFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDoctorFilterInput>>>;
  not?: Maybe<ModelDoctorFilterInput>;
};

export type ModelDosageConditionInput = {
  /** pharmacy#date#time#userId */
  id?: Maybe<ModelStringInput>;
  pharmacy?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  status?: Maybe<ModelDosageStatusInput>;
  and?: Maybe<Array<Maybe<ModelDosageConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDosageConditionInput>>>;
  not?: Maybe<ModelDosageConditionInput>;
};

export type ModelDosageConnection = {
  __typename?: 'ModelDosageConnection';
  items?: Maybe<Array<Maybe<Dosage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDosageFilterInput = {
  /** pharmacy#date#time#userId */
  id?: Maybe<ModelStringInput>;
  pharmacy?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  status?: Maybe<ModelDosageStatusInput>;
  and?: Maybe<Array<Maybe<ModelDosageFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDosageFilterInput>>>;
  not?: Maybe<ModelDosageFilterInput>;
};

export type ModelDosagePrescriptionConditionInput = {
  id?: Maybe<ModelIdInput>;
  key?: Maybe<ModelStringInput>;
  fileName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDosagePrescriptionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDosagePrescriptionConditionInput>>>;
  not?: Maybe<ModelDosagePrescriptionConditionInput>;
};

export type ModelDosagePrescriptionConnection = {
  __typename?: 'ModelDosagePrescriptionConnection';
  items?: Maybe<Array<Maybe<DosagePrescription>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDosagePrescriptionFilterInput = {
  id?: Maybe<ModelIdInput>;
  key?: Maybe<ModelStringInput>;
  fileName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDosagePrescriptionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDosagePrescriptionFilterInput>>>;
  not?: Maybe<ModelDosagePrescriptionFilterInput>;
};

export type ModelDosageStatusInput = {
  eq?: Maybe<DosageStatus>;
  ne?: Maybe<DosageStatus>;
};

export type ModelEmployeeConditionInput = {
  id?: Maybe<ModelIdInput>;
  chatTool?: Maybe<ModelChatToolInput>;
  chatId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelEmployeeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelEmployeeConditionInput>>>;
  not?: Maybe<ModelEmployeeConditionInput>;
};

export type ModelEmployeeConnection = {
  __typename?: 'ModelEmployeeConnection';
  items?: Maybe<Array<Maybe<Employee>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelEmployeeFilterInput = {
  id?: Maybe<ModelIdInput>;
  chatTool?: Maybe<ModelChatToolInput>;
  chatId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelEmployeeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelEmployeeFilterInput>>>;
  not?: Maybe<ModelEmployeeFilterInput>;
};

export type ModelEventInstructionConditionInput = {
  id?: Maybe<ModelIdInput>;
  inChargeStaffId?: Maybe<ModelIdInput>;
  instructorStaffId?: Maybe<ModelIdInput>;
  content?: Maybe<ModelStringInput>;
  isDone?: Maybe<ModelBooleanInput>;
  order?: Maybe<ModelIntInput>;
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelEventInstructionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelEventInstructionConditionInput>>>;
  not?: Maybe<ModelEventInstructionConditionInput>;
};

export type ModelEventInstructionConnection = {
  __typename?: 'ModelEventInstructionConnection';
  items?: Maybe<Array<Maybe<EventInstruction>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelEventInstructionFilterInput = {
  id?: Maybe<ModelIdInput>;
  inChargeStaffId?: Maybe<ModelIdInput>;
  instructorStaffId?: Maybe<ModelIdInput>;
  content?: Maybe<ModelStringInput>;
  isDone?: Maybe<ModelBooleanInput>;
  order?: Maybe<ModelIntInput>;
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelEventInstructionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelEventInstructionFilterInput>>>;
  not?: Maybe<ModelEventInstructionFilterInput>;
};

export type ModelExtendedDictionaryArgTypeInput = {
  eq?: Maybe<ExtendedDictionaryArgType>;
  ne?: Maybe<ExtendedDictionaryArgType>;
};

export type ModelExtendedDictionaryConditionInput = {
  key?: Maybe<ModelIdInput>;
  template?: Maybe<ModelStringInput>;
  type?: Maybe<ModelExtendedDictionaryArgTypeInput>;
  and?: Maybe<Array<Maybe<ModelExtendedDictionaryConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelExtendedDictionaryConditionInput>>>;
  not?: Maybe<ModelExtendedDictionaryConditionInput>;
};

export type ModelExtendedDictionaryConnection = {
  __typename?: 'ModelExtendedDictionaryConnection';
  items?: Maybe<Array<Maybe<ExtendedDictionary>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelExtendedDictionaryFilterInput = {
  key?: Maybe<ModelIdInput>;
  template?: Maybe<ModelStringInput>;
  type?: Maybe<ModelExtendedDictionaryArgTypeInput>;
  and?: Maybe<Array<Maybe<ModelExtendedDictionaryFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelExtendedDictionaryFilterInput>>>;
  not?: Maybe<ModelExtendedDictionaryFilterInput>;
};

export type ModelFeedbackConditionInput = {
  id?: Maybe<ModelIdInput>;
  feedback?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelFeedbackConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelFeedbackConditionInput>>>;
  not?: Maybe<ModelFeedbackConditionInput>;
};

export type ModelFeedbackConnection = {
  __typename?: 'ModelFeedbackConnection';
  items?: Maybe<Array<Maybe<Feedback>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFeedbackFilterInput = {
  id?: Maybe<ModelIdInput>;
  feedback?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelFeedbackFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelFeedbackFilterInput>>>;
  not?: Maybe<ModelFeedbackFilterInput>;
};

export type ModelFloatInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelFollowupConditionInput = {
  id?: Maybe<ModelIdInput>;
  type?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  body?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelFollowupConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelFollowupConditionInput>>>;
  not?: Maybe<ModelFollowupConditionInput>;
};

export type ModelFollowupConnection = {
  __typename?: 'ModelFollowupConnection';
  items?: Maybe<Array<Maybe<Followup>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFollowupFilterInput = {
  id?: Maybe<ModelIdInput>;
  type?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  body?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelFollowupFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelFollowupFilterInput>>>;
  not?: Maybe<ModelFollowupFilterInput>;
};

export type ModelIdInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  size?: Maybe<ModelSizeInput>;
};

export type ModelImportJobConditionInput = {
  schema?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  startedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelImportJobConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelImportJobConditionInput>>>;
  not?: Maybe<ModelImportJobConditionInput>;
};

export type ModelImportJobConnection = {
  __typename?: 'ModelImportJobConnection';
  items?: Maybe<Array<Maybe<ImportJob>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelImportJobFilterInput = {
  schema?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  startedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelImportJobFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelImportJobFilterInput>>>;
  not?: Maybe<ModelImportJobFilterInput>;
};

export type ModelInstructionConditionInput = {
  id?: Maybe<ModelIdInput>;
  /** 処置内容 */
  content?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelInstructionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelInstructionConditionInput>>>;
  not?: Maybe<ModelInstructionConditionInput>;
};

export type ModelInstructionConnection = {
  __typename?: 'ModelInstructionConnection';
  items?: Maybe<Array<Maybe<Instruction>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelInstructionFilterInput = {
  id?: Maybe<ModelIdInput>;
  /** 処置内容 */
  content?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelInstructionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelInstructionFilterInput>>>;
  not?: Maybe<ModelInstructionFilterInput>;
};

export type ModelInterviewTimeTypeInput = {
  eq?: Maybe<InterviewTimeType>;
};

export type ModelIntInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelLineBotMessageConditionInput = {
  key?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  example?: Maybe<ModelStringInput>;
  selectClinic?: Maybe<ModelBooleanInput>;
  childrenKeys: Array<Maybe<Scalars['String']>>;
  and?: Maybe<Array<Maybe<ModelLineBotMessageConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLineBotMessageConditionInput>>>;
  not?: Maybe<ModelLineBotMessageConditionInput>;
};

export type ModelLineBotMessageConnection = {
  __typename?: 'ModelLineBotMessageConnection';
  items?: Maybe<Array<Maybe<LineBotMessage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLineBotMessageFilterInput = {
  key?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  example?: Maybe<ModelStringInput>;
  selectClinic?: Maybe<ModelBooleanInput>;
  childrenKeys: Array<Maybe<Scalars['String']>>;
  and?: Maybe<Array<Maybe<ModelLineBotMessageFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLineBotMessageFilterInput>>>;
  not?: Maybe<ModelLineBotMessageFilterInput>;
};

export type ModelLineMessageConditionInput = {
  id?: Maybe<ModelIdInput>;
  channelId?: Maybe<ModelStringInput>;
  messageText?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  messageType?: Maybe<ModelLineMessageSendTypeInput>;
  accountType?: Maybe<ModelStringInput>;
  /** jsonだけどぶっこむ */
  event?: Maybe<ModelStringInput>;
  openedByUserAt?: Maybe<ModelStringInput>;
  /** どこ経由か。必ずオール小文字。現在は zendesk と search。 */
  via?: Maybe<ModelStringInput>;
  searchGroupId?: Maybe<ModelStringInput>;
  origin?: Maybe<ModelLineMessageOriginInput>;
  and?: Maybe<Array<Maybe<ModelLineMessageConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLineMessageConditionInput>>>;
  not?: Maybe<ModelLineMessageConditionInput>;
};

export type ModelLineMessageConnection = {
  __typename?: 'ModelLineMessageConnection';
  items?: Maybe<Array<Maybe<LineMessage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLineMessageFilterInput = {
  id?: Maybe<ModelIdInput>;
  channelId?: Maybe<ModelStringInput>;
  messageText?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  messageType?: Maybe<ModelLineMessageSendTypeInput>;
  accountType?: Maybe<ModelStringInput>;
  /** jsonだけどぶっこむ */
  event?: Maybe<ModelStringInput>;
  openedByUserAt?: Maybe<ModelStringInput>;
  /** どこ経由か。必ずオール小文字。現在は zendesk と search。 */
  via?: Maybe<ModelStringInput>;
  searchGroupId?: Maybe<ModelStringInput>;
  origin?: Maybe<ModelLineMessageOriginInput>;
  and?: Maybe<Array<Maybe<ModelLineMessageFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLineMessageFilterInput>>>;
  not?: Maybe<ModelLineMessageFilterInput>;
};

export type ModelLineMessageidAndDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelLineMessageidAndDateCompositeKeyInput>>>;
  eq?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
  ge?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
  gt?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
  le?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
  lt?: Maybe<ModelLineMessageidAndDateCompositeKeyInput>;
};

export type ModelLineMessageidAndDateCompositeKeyInput = {
  createdAt?: Maybe<Scalars['String']>;
};

export type ModelLineMessageOriginInput = {
  eq?: Maybe<LineMessageOrigin>;
  ne?: Maybe<LineMessageOrigin>;
};

export type ModelLineMessageSendTypeInput = {
  eq?: Maybe<LineMessageSendType>;
  ne?: Maybe<LineMessageSendType>;
};

export type ModelLineMessageTemplateConditionInput = {
  key?: Maybe<ModelLineTemplateKeyInput>;
  clinic?: Maybe<ModelStringInput>;
  department?: Maybe<ModelStringInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelLineMessageTemplateConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLineMessageTemplateConditionInput>>>;
  not?: Maybe<ModelLineMessageTemplateConditionInput>;
};

export type ModelLineMessageTemplateConnection = {
  __typename?: 'ModelLineMessageTemplateConnection';
  items?: Maybe<Array<Maybe<LineMessageTemplate>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLineMessageTemplateFilterInput = {
  key?: Maybe<ModelLineTemplateKeyInput>;
  clinic?: Maybe<ModelStringInput>;
  department?: Maybe<ModelStringInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelLineMessageTemplateFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLineMessageTemplateFilterInput>>>;
  not?: Maybe<ModelLineMessageTemplateFilterInput>;
};

export type ModelLineReadStatuschannelIdAndDateCompositeKeyConditionInput = {
  eq?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
  le?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
  lt?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
  ge?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
  gt?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelLineReadStatuschannelIdAndDateCompositeKeyInput>;
};

export type ModelLineReadStatuschannelIdAndDateCompositeKeyInput = {
  latestUpdatedAt?: Maybe<Scalars['String']>;
};

export type ModelLineReadStatusConditionInput = {
  and?: Maybe<Array<Maybe<ModelLineReadStatusConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLineReadStatusConditionInput>>>;
  not?: Maybe<ModelLineReadStatusConditionInput>;
};

export type ModelLineReadStatusConnection = {
  __typename?: 'ModelLineReadStatusConnection';
  items?: Maybe<Array<Maybe<LineReadStatus>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLineReadStatusFilterInput = {
  and?: Maybe<Array<Maybe<ModelLineReadStatusFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLineReadStatusFilterInput>>>;
  not?: Maybe<ModelLineReadStatusFilterInput>;
};

export type ModelLineReadStatusIdCompositeKeyInput = {
  lineUserId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ModelLineTemplateKeyInput = {
  eq?: Maybe<LineTemplateKey>;
  ne?: Maybe<LineTemplateKey>;
};

export type ModelLineUserConditionInput = {
  id?: Maybe<ModelIdInput>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelLineUserConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLineUserConditionInput>>>;
  not?: Maybe<ModelLineUserConditionInput>;
};

export type ModelLineUserConnection = {
  __typename?: 'ModelLineUserConnection';
  items?: Maybe<Array<Maybe<LineUser>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLineUserFilterInput = {
  id?: Maybe<ModelIdInput>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelLineUserFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLineUserFilterInput>>>;
  not?: Maybe<ModelLineUserFilterInput>;
};

export type ModelListBlockCompositeKeyConditionInput = {
  eq?: Maybe<ModelListBlockCompositeKeyInput>;
  le?: Maybe<ModelListBlockCompositeKeyInput>;
  lt?: Maybe<ModelListBlockCompositeKeyInput>;
  ge?: Maybe<ModelListBlockCompositeKeyInput>;
  gt?: Maybe<ModelListBlockCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelListBlockCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelListBlockCompositeKeyInput>;
};

export type ModelListBlockCompositeKeyInput = {
  lane?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type ModelListDosageCompositeKeyConditionInput = {
  eq?: Maybe<ModelListDosageCompositeKeyInput>;
  le?: Maybe<ModelListDosageCompositeKeyInput>;
  lt?: Maybe<ModelListDosageCompositeKeyInput>;
  ge?: Maybe<ModelListDosageCompositeKeyInput>;
  gt?: Maybe<ModelListDosageCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelListDosageCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelListDosageCompositeKeyInput>;
};

export type ModelListDosageCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ModelListTreatmentCompositeKeyConditionInput = {
  eq?: Maybe<ModelListTreatmentCompositeKeyInput>;
  le?: Maybe<ModelListTreatmentCompositeKeyInput>;
  lt?: Maybe<ModelListTreatmentCompositeKeyInput>;
  ge?: Maybe<ModelListTreatmentCompositeKeyInput>;
  gt?: Maybe<ModelListTreatmentCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelListTreatmentCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelListTreatmentCompositeKeyInput>;
};

export type ModelListTreatmentCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  temporally?: Maybe<Scalars['String']>;
};

export type ModelListUserReserveConditionCompositeKeyConditionInput = {
  eq?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
  le?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
  lt?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
  ge?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
  gt?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelListUserReserveConditionCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelListUserReserveConditionCompositeKeyInput>;
};

export type ModelListUserReserveConditionCompositeKeyInput = {
  start?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
};

export type ModelLocationConditionInput = {
  id?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  groupId?: Maybe<ModelIdInput>;
  /** ロケーション名 */
  name?: Maybe<ModelStringInput>;
  /** クリニックID */
  clinicId?: Maybe<ModelStringInput>;
  /** 部屋のタイプ waiting: 待合室, examination: 処置室 */
  roomType?: Maybe<ModelStringInput>;
  /** 上から何番目かの表示位置 */
  top?: Maybe<ModelIntInput>;
  /** 左から何番目かの表示位置 */
  left?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelLocationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLocationConditionInput>>>;
  not?: Maybe<ModelLocationConditionInput>;
};

export type ModelLocationConnection = {
  __typename?: 'ModelLocationConnection';
  items?: Maybe<Array<Maybe<Location>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLocationFilterInput = {
  id?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  groupId?: Maybe<ModelIdInput>;
  /** ロケーション名 */
  name?: Maybe<ModelStringInput>;
  /** クリニックID */
  clinicId?: Maybe<ModelStringInput>;
  /** 部屋のタイプ waiting: 待合室, examination: 処置室 */
  roomType?: Maybe<ModelStringInput>;
  /** 上から何番目かの表示位置 */
  top?: Maybe<ModelIntInput>;
  /** 左から何番目かの表示位置 */
  left?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelLocationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLocationFilterInput>>>;
  not?: Maybe<ModelLocationFilterInput>;
};

export type ModelLocationGroupConditionInput = {
  id?: Maybe<ModelIdInput>;
  /** ロケーショングループ名 */
  name?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  /** クリニックID */
  clinicId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelLocationGroupConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelLocationGroupConditionInput>>>;
  not?: Maybe<ModelLocationGroupConditionInput>;
};

export type ModelLocationGroupConnection = {
  __typename?: 'ModelLocationGroupConnection';
  items?: Maybe<Array<Maybe<LocationGroup>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLocationGroupFilterInput = {
  id?: Maybe<ModelIdInput>;
  /** ロケーショングループ名 */
  name?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  /** クリニックID */
  clinicId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelLocationGroupFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelLocationGroupFilterInput>>>;
  not?: Maybe<ModelLocationGroupFilterInput>;
};

export type ModelMedicalQuestionnaireInterviewAnswersConditionInput = {
  id?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>;
};

export type ModelMedicalQuestionnaireInterviewAnswersConnection = {
  __typename?: 'ModelMedicalQuestionnaireInterviewAnswersConnection';
  items?: Maybe<Array<Maybe<MedicalQuestionnaireInterviewAnswers>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMedicalQuestionnaireInterviewAnswersFilterInput = {
  id?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewAnswersFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewAnswersFilterInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireInterviewAnswersFilterInput>;
};

export type ModelMedicalQuestionnaireInterviewConditionInput = {
  interviewTime?: Maybe<ModelInterviewTimeTypeInput>;
  order?: Maybe<ModelStringInput>;
  question?: Maybe<ModelIntInput>;
  description?: Maybe<ModelStringInput>;
  disable?: Maybe<ModelBooleanInput>;
  required?: Maybe<ModelBooleanInput>;
  medicalRecordTitle?: Maybe<ModelStringInput>;
  medicalRecordText?: Maybe<ModelStringInput>;
  choiceType?: Maybe<ModelChoiceTypeInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewConditionInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireInterviewConditionInput>;
};

export type ModelMedicalQuestionnaireInterviewConnection = {
  __typename?: 'ModelMedicalQuestionnaireInterviewConnection';
  items?: Maybe<Array<Maybe<MedicalQuestionnaireInterview>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMedicalQuestionnaireInterviewFilterInput = {
  interviewTime?: Maybe<ModelInterviewTimeTypeInput>;
  order?: Maybe<ModelIntInput>;
  question?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  disable?: Maybe<ModelBooleanInput>;
  required?: Maybe<ModelBooleanInput>;
  medicalRecordTitle?: Maybe<ModelStringInput>;
  medicalRecordText?: Maybe<ModelStringInput>;
  choiceType?: Maybe<ModelChoiceTypeInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireInterviewFilterInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireInterviewFilterInput>;
};

export type ModelMedicalQuestionnaireSurveyAnswersConditionInput = {
  id?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>;
};

export type ModelMedicalQuestionnaireSurveyAnswersConnection = {
  __typename?: 'ModelMedicalQuestionnaireSurveyAnswersConnection';
  items?: Maybe<Array<Maybe<MedicalQuestionnaireSurveyAnswers>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMedicalQuestionnaireSurveyAnswersFilterInput = {
  id?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyAnswersFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyAnswersFilterInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireSurveyAnswersFilterInput>;
};

export type ModelMedicalQuestionnaireSurveyConditionInput = {
  surveyTime?: Maybe<ModelSurveyTimeTypeInput>;
  order?: Maybe<ModelStringInput>;
  question?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  disable?: Maybe<ModelBooleanInput>;
  required?: Maybe<ModelBooleanInput>;
  medicalRecordTitle?: Maybe<ModelStringInput>;
  medicalRecordText?: Maybe<ModelStringInput>;
  choiceType?: Maybe<ModelChoiceTypeInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyConditionInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireSurveyConditionInput>;
};

export type ModelMedicalQuestionnaireSurveyConnection = {
  __typename?: 'ModelMedicalQuestionnaireSurveyConnection';
  items?: Maybe<Array<Maybe<MedicalQuestionnaireSurvey>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMedicalQuestionnaireSurveyFilterInput = {
  surveyTime?: Maybe<ModelSurveyTimeTypeInput>;
  order?: Maybe<ModelStringInput>;
  question?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  disable?: Maybe<ModelBooleanInput>;
  required?: Maybe<ModelBooleanInput>;
  medicalRecordTitle?: Maybe<ModelStringInput>;
  medicalRecordText?: Maybe<ModelStringInput>;
  choiceType?: Maybe<ModelChoiceTypeInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelMedicalQuestionnaireSurveyFilterInput>>>;
  not?: Maybe<ModelMedicalQuestionnaireSurveyFilterInput>;
};

export type ModelNonceConditionInput = {
  nonce?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  issueAt?: Maybe<ModelStringInput>;
  expireAt?: Maybe<ModelStringInput>;
  disabled?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelNonceConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelNonceConditionInput>>>;
  not?: Maybe<ModelNonceConditionInput>;
};

export type ModelNonceConnection = {
  __typename?: 'ModelNonceConnection';
  items?: Maybe<Array<Maybe<Nonce>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelNonceFilterInput = {
  nonce?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  issueAt?: Maybe<ModelStringInput>;
  expireAt?: Maybe<ModelStringInput>;
  disabled?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelNonceFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelNonceFilterInput>>>;
  not?: Maybe<ModelNonceFilterInput>;
};

export type ModelOpenMonthConditionInput = {
  yearMonth?: Maybe<ModelStringInput>;
  open?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelOpenMonthConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelOpenMonthConditionInput>>>;
  not?: Maybe<ModelOpenMonthConditionInput>;
};

export type ModelOpenMonthConnection = {
  __typename?: 'ModelOpenMonthConnection';
  items?: Maybe<Array<Maybe<OpenMonth>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOpenMonthFilterInput = {
  yearMonth?: Maybe<ModelStringInput>;
  open?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelOpenMonthFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelOpenMonthFilterInput>>>;
  not?: Maybe<ModelOpenMonthFilterInput>;
};

export type ModelOrcaPatientConditionInput = {
  orcaID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelOrcaPatientConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelOrcaPatientConditionInput>>>;
  not?: Maybe<ModelOrcaPatientConditionInput>;
};

export type ModelOrcaPatientConnection = {
  __typename?: 'ModelOrcaPatientConnection';
  items?: Maybe<Array<Maybe<OrcaPatient>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOrcaPatientFilterInput = {
  orcaID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelOrcaPatientFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelOrcaPatientFilterInput>>>;
  not?: Maybe<ModelOrcaPatientFilterInput>;
};

export type ModelOrganizationConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  chatTool?: Maybe<ModelChatToolInput>;
  chatId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelOrganizationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelOrganizationConditionInput>>>;
  not?: Maybe<ModelOrganizationConditionInput>;
};

export type ModelOrganizationConnection = {
  __typename?: 'ModelOrganizationConnection';
  items?: Maybe<Array<Maybe<Organization>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOrganizationFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  chatTool?: Maybe<ModelChatToolInput>;
  chatId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelOrganizationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelOrganizationFilterInput>>>;
  not?: Maybe<ModelOrganizationFilterInput>;
};

export type ModelPatientCommentConditionInput = {
  id?: Maybe<ModelIdInput>;
  comment?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientCommentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientCommentConditionInput>>>;
  not?: Maybe<ModelPatientCommentConditionInput>;
};

export type ModelPatientCommentConnection = {
  __typename?: 'ModelPatientCommentConnection';
  items?: Maybe<Array<Maybe<PatientComment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPatientCommentFilterInput = {
  id?: Maybe<ModelIdInput>;
  comment?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientCommentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientCommentFilterInput>>>;
  not?: Maybe<ModelPatientCommentFilterInput>;
};

export type ModelPatientConditionInput = {
  id?: Maybe<ModelIdInput>;
  patientNo?: Maybe<ModelStringInput>;
  orcaID?: Maybe<ModelStringInput>;
  birthDate?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  seikana?: Maybe<ModelStringInput>;
  meikana?: Maybe<ModelStringInput>;
  sei?: Maybe<ModelStringInput>;
  mei?: Maybe<ModelStringInput>;
  comment?: Maybe<ModelStringInput>;
  staffId?: Maybe<ModelStringInput>;
  anyoneStaff?: Maybe<ModelBooleanInput>;
  /** 薬科の患者番号 */
  pharmacyPatientNo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientConditionInput>>>;
  not?: Maybe<ModelPatientConditionInput>;
};

export type ModelPatientConnection = {
  __typename?: 'ModelPatientConnection';
  items?: Maybe<Array<Maybe<Patient>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPatientFilterInput = {
  id?: Maybe<ModelIdInput>;
  patientNo?: Maybe<ModelStringInput>;
  orcaID?: Maybe<ModelStringInput>;
  birthDate?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  seikana?: Maybe<ModelStringInput>;
  meikana?: Maybe<ModelStringInput>;
  sei?: Maybe<ModelStringInput>;
  mei?: Maybe<ModelStringInput>;
  comment?: Maybe<ModelStringInput>;
  staffId?: Maybe<ModelStringInput>;
  anyoneStaff?: Maybe<ModelBooleanInput>;
  /** 薬科の患者番号 */
  pharmacyPatientNo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientFilterInput>>>;
  not?: Maybe<ModelPatientFilterInput>;
};

export type ModelPatientSearchSettingInput = {
  empty?: Maybe<Scalars['Boolean']>;
};

export type ModelPatientTagAssociationConditionInput = {
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientTagAssociationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientTagAssociationConditionInput>>>;
  not?: Maybe<ModelPatientTagAssociationConditionInput>;
};

export type ModelPatientTagAssociationConnection = {
  __typename?: 'ModelPatientTagAssociationConnection';
  items?: Maybe<Array<Maybe<PatientTagAssociation>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPatientTagAssociationFilterInput = {
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPatientTagAssociationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientTagAssociationFilterInput>>>;
  not?: Maybe<ModelPatientTagAssociationFilterInput>;
};

export type ModelPatientTagConditionInput = {
  tag?: Maybe<ModelIdInput>;
  displayName?: Maybe<ModelStringInput>;
  color?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  deleted?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelPatientTagConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientTagConditionInput>>>;
  not?: Maybe<ModelPatientTagConditionInput>;
};

export type ModelPatientTagConnection = {
  __typename?: 'ModelPatientTagConnection';
  items?: Maybe<Array<Maybe<PatientTag>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPatientTagFilterInput = {
  tag?: Maybe<ModelIdInput>;
  displayName?: Maybe<ModelStringInput>;
  color?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  deleted?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelPatientTagFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPatientTagFilterInput>>>;
  not?: Maybe<ModelPatientTagFilterInput>;
};

export type ModelPaymentConditionInput = {
  date?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentConditionInput>>>;
  not?: Maybe<ModelPaymentConditionInput>;
};

export type ModelPaymentConnection = {
  __typename?: 'ModelPaymentConnection';
  items?: Maybe<Array<Maybe<Payment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPaymentFilterInput = {
  date?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentFilterInput>>>;
  not?: Maybe<ModelPaymentFilterInput>;
};

export type ModelPaymentJobTypeInput = {
  eq?: Maybe<PaymentJobType>;
  ne?: Maybe<PaymentJobType>;
};

export type ModelPaymentStateConditionInput = {
  executionId?: Maybe<ModelStringInput>;
  type?: Maybe<ModelPaymentJobTypeInput>;
  state?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  reason?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentStateConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentStateConditionInput>>>;
  not?: Maybe<ModelPaymentStateConditionInput>;
};

export type ModelPaymentStateConnection = {
  __typename?: 'ModelPaymentStateConnection';
  items?: Maybe<Array<Maybe<PaymentState>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPaymentStateFilterInput = {
  executionId?: Maybe<ModelStringInput>;
  type?: Maybe<ModelPaymentJobTypeInput>;
  state?: Maybe<ModelStringInput>;
  payjpID?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  reason?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentStateFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentStateFilterInput>>>;
  not?: Maybe<ModelPaymentStateFilterInput>;
};

export type ModelPaymentStateMachineConditionInput = {
  date?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  department?: Maybe<ModelStringInput>;
  executionId?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  amount?: Maybe<ModelIntInput>;
  invoiceNumber?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentStateMachineConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentStateMachineConditionInput>>>;
  not?: Maybe<ModelPaymentStateMachineConditionInput>;
};

export type ModelPaymentStateMachineConnection = {
  __typename?: 'ModelPaymentStateMachineConnection';
  items?: Maybe<Array<Maybe<PaymentStateMachine>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPaymentStateMachineFilterInput = {
  date?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  department?: Maybe<ModelStringInput>;
  executionId?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  amount?: Maybe<ModelIntInput>;
  invoiceNumber?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPaymentStateMachineFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPaymentStateMachineFilterInput>>>;
  not?: Maybe<ModelPaymentStateMachineFilterInput>;
};

export type ModelPaymentStateMachinePaymentStateMachinesCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>>>;
  eq?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
  ge?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
  gt?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
  le?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
  lt?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput>;
};

export type ModelPaymentStateMachinePaymentStateMachinesCompositeKeyInput = {
  department?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ModelPharmacistConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPharmacistConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPharmacistConditionInput>>>;
  not?: Maybe<ModelPharmacistConditionInput>;
};

export type ModelPharmacistConnection = {
  __typename?: 'ModelPharmacistConnection';
  items?: Maybe<Array<Maybe<Pharmacist>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPharmacistFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPharmacistFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPharmacistFilterInput>>>;
  not?: Maybe<ModelPharmacistFilterInput>;
};

export type ModelPharmacyConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPharmacyConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelPharmacyConditionInput>>>;
  not?: Maybe<ModelPharmacyConditionInput>;
};

export type ModelPharmacyConnection = {
  __typename?: 'ModelPharmacyConnection';
  items?: Maybe<Array<Maybe<Pharmacy>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPharmacyFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelPharmacyFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelPharmacyFilterInput>>>;
  not?: Maybe<ModelPharmacyFilterInput>;
};

export type ModelRemindActionConditionInput = {
  id?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  remindActionType?: Maybe<ModelRemindActionTypeInput>;
  remindActionStatus?: Maybe<ModelRemindActionStatusInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionConditionInput>>>;
  not?: Maybe<ModelRemindActionConditionInput>;
};

export type ModelRemindActionConnection = {
  __typename?: 'ModelRemindActionConnection';
  items?: Maybe<Array<Maybe<RemindAction>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRemindActionDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelRemindActionDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelRemindActionDateCompositeKeyInput>>>;
  eq?: Maybe<ModelRemindActionDateCompositeKeyInput>;
  ge?: Maybe<ModelRemindActionDateCompositeKeyInput>;
  gt?: Maybe<ModelRemindActionDateCompositeKeyInput>;
  le?: Maybe<ModelRemindActionDateCompositeKeyInput>;
  lt?: Maybe<ModelRemindActionDateCompositeKeyInput>;
};

export type ModelRemindActionDateCompositeKeyInput = {
  time?: Maybe<Scalars['String']>;
};

export type ModelRemindActionFilterInput = {
  id?: Maybe<ModelIdInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  remindActionType?: Maybe<ModelRemindActionTypeInput>;
  remindActionStatus?: Maybe<ModelRemindActionStatusInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionFilterInput>>>;
  not?: Maybe<ModelRemindActionFilterInput>;
};

export type ModelRemindActionLogConditionInput = {
  id?: Maybe<ModelIdInput>;
  to?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionLogConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionLogConditionInput>>>;
  not?: Maybe<ModelRemindActionLogConditionInput>;
};

export type ModelRemindActionLogConnection = {
  __typename?: 'ModelRemindActionLogConnection';
  items?: Maybe<Array<Maybe<RemindActionLog>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRemindActionLogFilterInput = {
  id?: Maybe<ModelIdInput>;
  to?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionLogFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionLogFilterInput>>>;
  not?: Maybe<ModelRemindActionLogFilterInput>;
};

export type ModelRemindActionPlanConditionInput = {
  date?: Maybe<ModelIdInput>;
  type?: Maybe<ModelRemindActionTypeInput>;
  status?: Maybe<ModelRemindActionStatusInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionPlanConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionPlanConditionInput>>>;
  not?: Maybe<ModelRemindActionPlanConditionInput>;
};

export type ModelRemindActionPlanConnection = {
  __typename?: 'ModelRemindActionPlanConnection';
  items?: Maybe<Array<Maybe<RemindActionPlan>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRemindActionPlanFilterInput = {
  date?: Maybe<ModelIdInput>;
  type?: Maybe<ModelRemindActionTypeInput>;
  status?: Maybe<ModelRemindActionStatusInput>;
  message?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRemindActionPlanFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelRemindActionPlanFilterInput>>>;
  not?: Maybe<ModelRemindActionPlanFilterInput>;
};

export type ModelRemindActionStatusInput = {
  eq?: Maybe<RemindActionStatus>;
  ne?: Maybe<RemindActionStatus>;
};

export type ModelRemindActionTypeInput = {
  eq?: Maybe<RemindActionType>;
  ne?: Maybe<RemindActionType>;
};

export type ModelReviewConditionInput = {
  schema?: Maybe<ModelReviewSchemaInput>;
  createdAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelReviewConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelReviewConditionInput>>>;
  not?: Maybe<ModelReviewConditionInput>;
};

export type ModelReviewConnection = {
  __typename?: 'ModelReviewConnection';
  items?: Maybe<Array<Maybe<Review>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelReviewFilterInput = {
  schema?: Maybe<ModelReviewSchemaInput>;
  createdAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelReviewFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelReviewFilterInput>>>;
  not?: Maybe<ModelReviewFilterInput>;
};

export type ModelReviewSchemaInput = {
  eq?: Maybe<ReviewSchema>;
  ne?: Maybe<ReviewSchema>;
};

export type ModelRoomConditionInput = {
  roomType?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRoomConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelRoomConditionInput>>>;
  not?: Maybe<ModelRoomConditionInput>;
};

export type ModelRoomConnection = {
  __typename?: 'ModelRoomConnection';
  items?: Maybe<Array<Maybe<Room>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRoomFilterInput = {
  roomType?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRoomFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelRoomFilterInput>>>;
  not?: Maybe<ModelRoomFilterInput>;
};

export type ModelRoomVacancyConditionInput = {
  roomType?: Maybe<ModelStringInput>;
  roomNo?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  vacancyStatus?: Maybe<ModelVacancyStatusInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRoomVacancyConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelRoomVacancyConditionInput>>>;
  not?: Maybe<ModelRoomVacancyConditionInput>;
};

export type ModelRoomVacancyConnection = {
  __typename?: 'ModelRoomVacancyConnection';
  items?: Maybe<Array<Maybe<RoomVacancy>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRoomVacancyFilterInput = {
  roomType?: Maybe<ModelStringInput>;
  roomNo?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  vacancyStatus?: Maybe<ModelVacancyStatusInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelRoomVacancyFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelRoomVacancyFilterInput>>>;
  not?: Maybe<ModelRoomVacancyFilterInput>;
};

export type ModelSearchConditionConditionInput = {
  type?: Maybe<ModelIdInput>;
  createdAt?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  treatment?: Maybe<ModelSearchTreatmentSettingInput>;
  patient?: Maybe<ModelPatientSearchSettingInput>;
  and?: Maybe<Array<Maybe<ModelSearchConditionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelSearchConditionConditionInput>>>;
  not?: Maybe<ModelSearchConditionConditionInput>;
};

export type ModelSearchConditionConnection = {
  __typename?: 'ModelSearchConditionConnection';
  items?: Maybe<Array<Maybe<SearchCondition>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSearchConditionFilterInput = {
  type?: Maybe<ModelIdInput>;
  createdAt?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  treatment?: Maybe<ModelSearchTreatmentSettingInput>;
  patient?: Maybe<ModelPatientSearchSettingInput>;
  and?: Maybe<Array<Maybe<ModelSearchConditionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelSearchConditionFilterInput>>>;
  not?: Maybe<ModelSearchConditionFilterInput>;
};

export type ModelSearchPatientResult = {
  __typename?: 'ModelSearchPatientResult';
  count: Scalars['Int'];
  items: Array<Patient>;
};

export type ModelSearchTreatmentResult = {
  __typename?: 'ModelSearchTreatmentResult';
  count: Scalars['Int'];
  items: Array<Treatment>;
};

export type ModelSearchTreatmentSettingInput = {
  empty?: Maybe<Scalars['Boolean']>;
};

export type ModelSentMessageGroupBySearchConnection = {
  __typename?: 'ModelSentMessageGroupBySearchConnection';
  items?: Maybe<Array<Maybe<SentMessageGroupBySearch>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSentMessageGroupBySearchFilterInput = {
  id?: Maybe<ModelIdInput>;
  createdAt?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  createdUserName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelSentMessageGroupBySearchFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelSentMessageGroupBySearchFilterInput>>>;
  not?: Maybe<ModelSentMessageGroupBySearchFilterInput>;
};

export type ModelSexInput = {
  eq?: Maybe<Sex>;
  ne?: Maybe<Sex>;
};

export type ModelShiftCompositeKeyConditionInput = {
  eq?: Maybe<ModelShiftCompositeKeyInput>;
  le?: Maybe<ModelShiftCompositeKeyInput>;
  lt?: Maybe<ModelShiftCompositeKeyInput>;
  ge?: Maybe<ModelShiftCompositeKeyInput>;
  gt?: Maybe<ModelShiftCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelShiftCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelShiftCompositeKeyInput>;
};

export type ModelShiftCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
};

export type ModelShiftConditionInput = {
  clinic?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  laneId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelShiftConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelShiftConditionInput>>>;
  not?: Maybe<ModelShiftConditionInput>;
};

export type ModelShiftConnection = {
  __typename?: 'ModelShiftConnection';
  items?: Maybe<Array<Maybe<Shift>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelShiftFilterInput = {
  clinic?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  laneId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelShiftFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelShiftFilterInput>>>;
  not?: Maybe<ModelShiftFilterInput>;
};

export type ModelSizeInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ModelSlackNonceConditionInput = {
  id?: Maybe<ModelIdInput>;
  slackId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelSlackNonceConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelSlackNonceConditionInput>>>;
  not?: Maybe<ModelSlackNonceConditionInput>;
};

export type ModelSlackNonceConnection = {
  __typename?: 'ModelSlackNonceConnection';
  items?: Maybe<Array<Maybe<SlackNonce>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSlackNonceFilterInput = {
  id?: Maybe<ModelIdInput>;
  slackId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelSlackNonceFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelSlackNonceFilterInput>>>;
  not?: Maybe<ModelSlackNonceFilterInput>;
};

export type ModelSoapInfoConditionInput = {
  id?: Maybe<ModelIdInput>;
  subjectComment?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  feedback?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelSoapInfoConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelSoapInfoConditionInput>>>;
  not?: Maybe<ModelSoapInfoConditionInput>;
};

export type ModelSoapInfoConnection = {
  __typename?: 'ModelSOAPInfoConnection';
  items?: Maybe<Array<Maybe<SoapInfo>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSoapInfoFilterInput = {
  id?: Maybe<ModelIdInput>;
  subjectComment?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  feedback?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelSoapInfoFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelSoapInfoFilterInput>>>;
  not?: Maybe<ModelSoapInfoFilterInput>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStaffConditionInput = {
  role?: Maybe<ModelStaffRoleTypeInput>;
  staffId?: Maybe<ModelStringInput>;
  enable?: Maybe<ModelBooleanInput>;
  name?: Maybe<ModelStringInput>;
  gender?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  shortName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffConditionInput>>>;
  not?: Maybe<ModelStaffConditionInput>;
};

export type ModelStaffConnection = {
  __typename?: 'ModelStaffConnection';
  items?: Maybe<Array<Maybe<Staff>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelStaffEmailIndexCompositeKeyConditionInput = {
  eq?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
  le?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
  lt?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
  ge?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
  gt?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelStaffEmailIndexCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelStaffEmailIndexCompositeKeyInput>;
};

export type ModelStaffEmailIndexCompositeKeyInput = {
  createdAt?: Maybe<Scalars['String']>;
};

export type ModelStaffFilterInput = {
  role?: Maybe<ModelStaffRoleTypeInput>;
  staffId?: Maybe<ModelStringInput>;
  enable?: Maybe<ModelBooleanInput>;
  name?: Maybe<ModelStringInput>;
  gender?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  shortName?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffFilterInput>>>;
  not?: Maybe<ModelStaffFilterInput>;
};

export type ModelStaffRoleTypeInput = {
  eq?: Maybe<StaffRoleType>;
  ne?: Maybe<StaffRoleType>;
};

export type ModelStaffShiftConditionInput = {
  staff?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  failure?: Maybe<ModelBooleanInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffShiftConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffShiftConditionInput>>>;
  not?: Maybe<ModelStaffShiftConditionInput>;
};

export type ModelStaffShiftConnection = {
  __typename?: 'ModelStaffShiftConnection';
  items?: Maybe<Array<Maybe<StaffShift>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelStaffShiftFilterInput = {
  staff?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  failure?: Maybe<ModelBooleanInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffShiftFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffShiftFilterInput>>>;
  not?: Maybe<ModelStaffShiftFilterInput>;
};

export type ModelStaffVacancyConditionInput = {
  staff?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  vacancyStatus?: Maybe<ModelVacancyStatusInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffVacancyConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffVacancyConditionInput>>>;
  not?: Maybe<ModelStaffVacancyConditionInput>;
};

export type ModelStaffVacancyConnection = {
  __typename?: 'ModelStaffVacancyConnection';
  items?: Maybe<Array<Maybe<StaffVacancy>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelStaffVacancyFilterInput = {
  staff?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  vacancyStatus?: Maybe<ModelVacancyStatusInput>;
  createdAt?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelStaffVacancyFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelStaffVacancyFilterInput>>>;
  not?: Maybe<ModelStaffVacancyFilterInput>;
};

export type ModelStringConditionInput = {
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
};

export type ModelStringInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  size?: Maybe<ModelSizeInput>;
};

export type ModelSurveyTimeTypeInput = {
  eq?: Maybe<SurveyTimeType>;
};

export type ModelTemplateKindInput = {
  eq?: Maybe<TemplateKind>;
  ne?: Maybe<TemplateKind>;
};

export type ModelTreatmentConditionInput = {
  id?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  estimatedEnd?: Maybe<ModelStringInput>;
  physician?: Maybe<ModelStringInput>;
  status?: Maybe<ModelTreatmentStatusInput>;
  holdedAt?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  orcaReservationId?: Maybe<ModelStringInput>;
  orcaAcceptanceId?: Maybe<ModelStringInput>;
  temporally?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentConditionInput>>>;
  not?: Maybe<ModelTreatmentConditionInput>;
};

export type ModelTreatmentConnection = {
  __typename?: 'ModelTreatmentConnection';
  items?: Maybe<Array<Maybe<Treatment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentDateAndUserCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelTreatmentDateAndUserCompositeKeyInput>>>;
  eq?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
  ge?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
  gt?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
  le?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
  lt?: Maybe<ModelTreatmentDateAndUserCompositeKeyInput>;
};

export type ModelTreatmentDateAndUserCompositeKeyInput = {
  userId?: Maybe<Scalars['String']>;
};

export type ModelTreatmentFilterInput = {
  id?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  time?: Maybe<ModelStringInput>;
  estimatedEnd?: Maybe<ModelStringInput>;
  physician?: Maybe<ModelStringInput>;
  status?: Maybe<ModelTreatmentStatusInput>;
  holdedAt?: Maybe<ModelStringInput>;
  yearMonth?: Maybe<ModelStringInput>;
  orcaReservationId?: Maybe<ModelStringInput>;
  orcaAcceptanceId?: Maybe<ModelStringInput>;
  temporally?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentFilterInput>>>;
  not?: Maybe<ModelTreatmentFilterInput>;
};

export type ModelTreatmentKindConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  shortName?: Maybe<ModelStringInput>;
  course?: Maybe<ModelBooleanInput>;
  color?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  icon?: Maybe<ModelStringInput>;
  /** ワクチン制御の対象かどうか */
  vaccine?: Maybe<ModelBooleanInput>;
  /** リコール自動送信用のメッセージテンプレート */
  remindTemplate?: Maybe<ModelStringInput>;
  /** 診療科の重複の許可 */
  permitDuplicateDepartment?: Maybe<ModelBooleanInput>;
  noInterview?: Maybe<ModelBooleanInput>;
  subTitle?: Maybe<ModelStringInput>;
  interviewName?: Maybe<ModelStringInput>;
  estimatedDuration?: Maybe<ModelFloatInput>;
  revisit?: Maybe<ModelBooleanInput>;
  order?: Maybe<ModelIntInput>;
  /** Department.announcement をオーバーライドできる。 */
  announcement?: Maybe<ModelStringInput>;
  /** Department.requirement をオーバーライドできる。 */
  requirement?: Maybe<ModelStringInput>;
  online?: Maybe<ModelBooleanInput>;
  /** ユーザーに表示する長さ */
  displayDuration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentKindConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentKindConditionInput>>>;
  not?: Maybe<ModelTreatmentKindConditionInput>;
  requiredReservationFee?: Maybe<ModelBooleanInput>;
};

export type ModelTreatmentKindConnection = {
  __typename?: 'ModelTreatmentKindConnection';
  items?: Maybe<Array<Maybe<TreatmentKind>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentKindFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  shortName?: Maybe<ModelStringInput>;
  course?: Maybe<ModelBooleanInput>;
  color?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  icon?: Maybe<ModelStringInput>;
  /** ワクチン制御の対象かどうか */
  vaccine?: Maybe<ModelBooleanInput>;
  /** リコール自動送信用のメッセージテンプレート */
  remindTemplate?: Maybe<ModelStringInput>;
  /** 診療科の重複の許可 */
  permitDuplicateDepartment?: Maybe<ModelBooleanInput>;
  noInterview?: Maybe<ModelBooleanInput>;
  subTitle?: Maybe<ModelStringInput>;
  interviewName?: Maybe<ModelStringInput>;
  estimatedDuration?: Maybe<ModelFloatInput>;
  revisit?: Maybe<ModelBooleanInput>;
  order?: Maybe<ModelIntInput>;
  /** Department.announcement をオーバーライドできる。 */
  announcement?: Maybe<ModelStringInput>;
  /** Department.requirement をオーバーライドできる。 */
  requirement?: Maybe<ModelStringInput>;
  online?: Maybe<ModelBooleanInput>;
  /** ユーザーに表示する長さ */
  displayDuration?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentKindFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentKindFilterInput>>>;
  not?: Maybe<ModelTreatmentKindFilterInput>;
  requiredReservationFee?: Maybe<ModelBooleanInput>;
};

export type ModelTreatmentProgressConditionInput = {
  /** ID */
  treatmentProgressId?: Maybe<ModelIdInput>;
  /** 作成日時 */
  createdAt?: Maybe<ModelStringInput>;
  /** 更新日時 */
  updatedAt?: Maybe<ModelStringInput>;
  /** 処置ID */
  treatmentId?: Maybe<ModelIdInput>;
  /** クリニック情報 */
  clinicId?: Maybe<ModelIdInput>;
  /** ロケーション情報 */
  locationId?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<ModelIdInput>;
  /** 来院日時 */
  visitAt?: Maybe<ModelStringInput>;
  /** 入室日時 */
  entryAt?: Maybe<ModelStringInput>;
  /** 患者情報 */
  patientId?: Maybe<ModelIdInput>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<ModelStringInput>;
  /** 待ち状態 */
  isWaiting?: Maybe<ModelBooleanInput>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<ModelStringInput>;
  /** メモ */
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressConditionInput>>>;
  not?: Maybe<ModelTreatmentProgressConditionInput>;
};

export type ModelTreatmentProgressConnection = {
  __typename?: 'ModelTreatmentProgressConnection';
  items?: Maybe<Array<Maybe<TreatmentProgress>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentProgressFilterInput = {
  /** ID */
  treatmentProgressId?: Maybe<ModelIdInput>;
  /** 作成日時 */
  createdAt?: Maybe<ModelStringInput>;
  /** 更新日時 */
  updatedAt?: Maybe<ModelStringInput>;
  /** 処置ID */
  treatmentId?: Maybe<ModelIdInput>;
  /** クリニック情報 */
  clinicId?: Maybe<ModelIdInput>;
  /** ロケーション情報 */
  locationId?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<ModelIdInput>;
  /** 来院日時 */
  visitAt?: Maybe<ModelStringInput>;
  /** 入室日時 */
  entryAt?: Maybe<ModelStringInput>;
  /** 患者情報 */
  patientId?: Maybe<ModelIdInput>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<ModelStringInput>;
  /** 待ち状態 */
  isWaiting?: Maybe<ModelBooleanInput>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<ModelStringInput>;
  /** メモ */
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressFilterInput>>>;
  not?: Maybe<ModelTreatmentProgressFilterInput>;
};

export type ModelTreatmentProgressHistoryConditionInput = {
  /** ID */
  historyId?: Maybe<ModelIdInput>;
  /** treatmentProgressテーブルとの紐付けをするためのID */
  treatmentProgressId?: Maybe<ModelIdInput>;
  /** 作成日時 */
  createdAt?: Maybe<ModelStringInput>;
  /** 更新日時 */
  updatedAt?: Maybe<ModelStringInput>;
  /** 処置ID */
  treatmentId?: Maybe<ModelIdInput>;
  /** クリニック情報 */
  clinicId?: Maybe<ModelIdInput>;
  /** ロケーション情報 */
  locationId?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<ModelIdInput>;
  /** 来院日時 */
  visitAt?: Maybe<ModelStringInput>;
  /** 入室日時 */
  entryAt?: Maybe<ModelStringInput>;
  /** 退室日時 */
  exitAt?: Maybe<ModelStringInput>;
  /** 患者情報 */
  patientId?: Maybe<ModelIdInput>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<ModelStringInput>;
  /** 待ち状態 */
  isWaiting?: Maybe<ModelBooleanInput>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<ModelStringInput>;
  /** メモ */
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressHistoryConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressHistoryConditionInput>>>;
  not?: Maybe<ModelTreatmentProgressHistoryConditionInput>;
};

export type ModelTreatmentProgressHistoryConnection = {
  __typename?: 'ModelTreatmentProgressHistoryConnection';
  items?: Maybe<Array<Maybe<TreatmentProgressHistory>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentProgressHistoryFilterInput = {
  /** ID */
  historyId?: Maybe<ModelIdInput>;
  /** treatmentProgressテーブルとの紐付けをするためのID */
  treatmentProgressId?: Maybe<ModelIdInput>;
  /** 作成日時 */
  createdAt?: Maybe<ModelStringInput>;
  /** 更新日時 */
  updatedAt?: Maybe<ModelStringInput>;
  /** 処置ID */
  treatmentId?: Maybe<ModelIdInput>;
  /** クリニック情報 */
  clinicId?: Maybe<ModelIdInput>;
  /** ロケーション情報 */
  locationId?: Maybe<ModelIdInput>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<ModelIdInput>;
  /** 来院日時 */
  visitAt?: Maybe<ModelStringInput>;
  /** 入室日時 */
  entryAt?: Maybe<ModelStringInput>;
  /** 退室日時 */
  exitAt?: Maybe<ModelStringInput>;
  /** 患者情報 */
  patientId?: Maybe<ModelIdInput>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<ModelStringInput>;
  /** 待ち状態 */
  isWaiting?: Maybe<ModelBooleanInput>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<ModelStringInput>;
  /** メモ */
  memo?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressHistoryFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressHistoryFilterInput>>>;
  not?: Maybe<ModelTreatmentProgressHistoryFilterInput>;
};

export type ModelTreatmentProgressNextStatusAssociationConditionInput = {
  statusId?: Maybe<ModelStringInput>;
  nextStatusId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>>>;
  not?: Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>;
};

export type ModelTreatmentProgressNextStatusAssociationConnection = {
  __typename?: 'ModelTreatmentProgressNextStatusAssociationConnection';
  items?: Maybe<Array<Maybe<TreatmentProgressNextStatusAssociation>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentProgressNextStatusAssociationFilterInput = {
  statusId?: Maybe<ModelStringInput>;
  nextStatusId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressNextStatusAssociationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressNextStatusAssociationFilterInput>>>;
  not?: Maybe<ModelTreatmentProgressNextStatusAssociationFilterInput>;
};

export type ModelTreatmentProgressStatusConditionInput = {
  id?: Maybe<ModelIdInput>;
  /** 診察ステータス名 */
  name?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  /** 診察ステータスを上位表示する役職。役職によって表示順が変わるため。 */
  preferredRoles: Array<Maybe<Scalars['String']>>;
  /** 進行中、待機中を判別するためのステータス */
  status?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressStatusConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressStatusConditionInput>>>;
  not?: Maybe<ModelTreatmentProgressStatusConditionInput>;
};

export type ModelTreatmentProgressStatusConnection = {
  __typename?: 'ModelTreatmentProgressStatusConnection';
  items?: Maybe<Array<Maybe<TreatmentProgressStatus>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTreatmentProgressStatusFilterInput = {
  id?: Maybe<ModelIdInput>;
  /** 診察ステータス名 */
  name?: Maybe<ModelStringInput>;
  /** 表示順 */
  order?: Maybe<ModelIntInput>;
  /** 診察ステータスを上位表示する役職。役職によって表示順が変わるため。 */
  preferredRoles: Array<Maybe<Scalars['String']>>;
  /** 進行中、待機中を判別するためのステータス */
  status?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTreatmentProgressStatusFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTreatmentProgressStatusFilterInput>>>;
  not?: Maybe<ModelTreatmentProgressStatusFilterInput>;
};

export type ModelTreatmentSortByDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelTreatmentSortByDateCompositeKeyInput>>>;
  eq?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
  ge?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
  gt?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
  le?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
  lt?: Maybe<ModelTreatmentSortByDateCompositeKeyInput>;
};

export type ModelTreatmentSortByDateCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type ModelTreatmentStatusInput = {
  eq?: Maybe<TreatmentStatus>;
  ne?: Maybe<TreatmentStatus>;
};

export type ModelTreatmentUserAndDateCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelTreatmentUserAndDateCompositeKeyInput>>>;
  eq?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
  ge?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
  gt?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
  le?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
  lt?: Maybe<ModelTreatmentUserAndDateCompositeKeyInput>;
};

export type ModelTreatmentUserAndDateCompositeKeyInput = {
  date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ModelUserConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  sei?: Maybe<ModelStringInput>;
  mei?: Maybe<ModelStringInput>;
  age?: Maybe<ModelIntInput>;
  sex?: Maybe<ModelSexInput>;
  mail?: Maybe<ModelStringInput>;
  phone?: Maybe<ModelStringInput>;
  zipCode?: Maybe<ModelStringInput>;
  pref?: Maybe<ModelStringInput>;
  addressLine1?: Maybe<ModelStringInput>;
  addressLine2?: Maybe<ModelStringInput>;
  confirmed?: Maybe<Array<Scalars['String']>>;
  profession?: Maybe<ModelStringInput>;
  insuranceCardCheckedAt?: Maybe<ModelStringInput>;
  penaltyCount?: Maybe<ModelIntInput>;
  accountRestricted?: Maybe<ModelBooleanInput>;
  introducer?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelUserConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserConditionInput>>>;
  not?: Maybe<ModelUserConditionInput>;
};

export type ModelUserConnection = {
  __typename?: 'ModelUserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  sei?: Maybe<ModelStringInput>;
  mei?: Maybe<ModelStringInput>;
  age?: Maybe<ModelIntInput>;
  sex?: Maybe<ModelSexInput>;
  mail?: Maybe<ModelStringInput>;
  phone?: Maybe<ModelStringInput>;
  zipCode?: Maybe<ModelStringInput>;
  pref?: Maybe<ModelStringInput>;
  addressLine1?: Maybe<ModelStringInput>;
  addressLine2?: Maybe<ModelStringInput>;
  confirmed?: Maybe<Array<Scalars['String']>>;
  profession?: Maybe<ModelStringInput>;
  insuranceCardCheckedAt?: Maybe<ModelStringInput>;
  penaltyCount?: Maybe<ModelIntInput>;
  accountRestricted?: Maybe<ModelBooleanInput>;
  introducer?: Maybe<ModelStringInput>;
  notes?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  not?: Maybe<ModelUserFilterInput>;
};

export type ModelUserReserveConditionConditionInput = {
  userId?: Maybe<ModelIdInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  disabled?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelUserReserveConditionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserReserveConditionConditionInput>>>;
  not?: Maybe<ModelUserReserveConditionConditionInput>;
};

export type ModelUserReserveConditionConnection = {
  __typename?: 'ModelUserReserveConditionConnection';
  items?: Maybe<Array<Maybe<UserReserveCondition>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserReserveConditionFilterInput = {
  userId?: Maybe<ModelIdInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  disabled?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelUserReserveConditionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserReserveConditionFilterInput>>>;
  not?: Maybe<ModelUserReserveConditionFilterInput>;
};

export type ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyConditionInput = {
  eq?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
  le?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
  lt?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
  ge?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
  gt?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput>;
};

export type ModelUserReserveConditionUserReserveConditionRemindOnCompositeKeyInput = {
  treatmentKind?: Maybe<Scalars['String']>;
};

export type ModelUserThreadConditionInput = {
  /** lineMessageIdまたはメールのID */
  userId?: Maybe<ModelIdInput>;
  accountType?: Maybe<ModelStringInput>;
  /** LINE, MAIL */
  messageType?: Maybe<ModelStringInput>;
  startMessageCreatedAt?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  userThreadClinicId?: Maybe<ModelStringInput>;
  correspondingType?: Maybe<ModelUserThreadCorrespondingTypeInput>;
  status?: Maybe<ModelUserThreadStatusInput>;
  and?: Maybe<Array<Maybe<ModelUserThreadConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserThreadConditionInput>>>;
  not?: Maybe<ModelUserThreadConditionInput>;
};

export type ModelUserThreadConnection = {
  __typename?: 'ModelUserThreadConnection';
  items: Array<UserThread>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserThreadCorrespondingTypeInput = {
  eq?: Maybe<UserThreadCorrespondingType>;
  ne?: Maybe<UserThreadCorrespondingType>;
};

export type ModelUserThreadFilterInput = {
  /** lineMessageIdまたはメールのID */
  userId?: Maybe<ModelIdInput>;
  accountType?: Maybe<ModelStringInput>;
  /** LINE, MAIL */
  messageType?: Maybe<ModelStringInput>;
  startMessageCreatedAt?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  userThreadClinicId?: Maybe<ModelStringInput>;
  correspondingType: UserThreadCorrespondingType;
  status?: Maybe<ModelUserThreadStatusInput>;
  and?: Maybe<Array<Maybe<ModelUserThreadFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserThreadFilterInput>>>;
  not?: Maybe<ModelUserThreadFilterInput>;
};

export type ModelUserThreadStatusInput = {
  eq?: Maybe<UserThreadStatus>;
  ne?: Maybe<UserThreadStatus>;
};

export type ModelUserTreatmentCompositeKeyConditionInput = {
  eq?: Maybe<ModelUserTreatmentCompositeKeyInput>;
  le?: Maybe<ModelUserTreatmentCompositeKeyInput>;
  lt?: Maybe<ModelUserTreatmentCompositeKeyInput>;
  ge?: Maybe<ModelUserTreatmentCompositeKeyInput>;
  gt?: Maybe<ModelUserTreatmentCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelUserTreatmentCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelUserTreatmentCompositeKeyInput>;
};

export type ModelUserTreatmentCompositeKeyInput = {
  status?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ModelVacanciesConditionInput = {
  date?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacanciesConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacanciesConditionInput>>>;
  not?: Maybe<ModelVacanciesConditionInput>;
};

export type ModelVacanciesConnection = {
  __typename?: 'ModelVacanciesConnection';
  items?: Maybe<Array<Maybe<Vacancies>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacanciesFilterInput = {
  date?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacanciesFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacanciesFilterInput>>>;
  not?: Maybe<ModelVacanciesFilterInput>;
};

export type ModelVacancyConfigSetConditionInput = {
  id?: Maybe<ModelIdInput>;
  clinicId?: Maybe<ModelStringInput>;
  label?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacancyConfigSetConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyConfigSetConditionInput>>>;
  not?: Maybe<ModelVacancyConfigSetConditionInput>;
};

export type ModelVacancyConfigSetConnection = {
  __typename?: 'ModelVacancyConfigSetConnection';
  items?: Maybe<Array<Maybe<VacancyConfigSet>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancyConfigSetFilterInput = {
  id?: Maybe<ModelIdInput>;
  clinicId?: Maybe<ModelStringInput>;
  label?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacancyConfigSetFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyConfigSetFilterInput>>>;
  not?: Maybe<ModelVacancyConfigSetFilterInput>;
};

export type ModelVacancyLaneConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  unit?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultTotal?: Maybe<ModelIntInput>;
  duration?: Maybe<ModelIntInput>;
  startTime?: Maybe<ModelStringInput>;
  endTime?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacancyLaneConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyLaneConditionInput>>>;
  not?: Maybe<ModelVacancyLaneConditionInput>;
};

export type ModelVacancyLaneConnection = {
  __typename?: 'ModelVacancyLaneConnection';
  items?: Maybe<Array<Maybe<VacancyLane>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancyLaneFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  unit?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultTotal?: Maybe<ModelIntInput>;
  duration?: Maybe<ModelIntInput>;
  startTime?: Maybe<ModelStringInput>;
  endTime?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVacancyLaneFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyLaneFilterInput>>>;
  not?: Maybe<ModelVacancyLaneFilterInput>;
};

export type ModelVacancySpacerConditionInput = {
  clinic?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  laneId?: Maybe<ModelStringInput>;
  spacer: Array<Maybe<Scalars['String']>>;
  and?: Maybe<Array<Maybe<ModelVacancySpacerConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancySpacerConditionInput>>>;
  not?: Maybe<ModelVacancySpacerConditionInput>;
};

export type ModelVacancySpacerConnection = {
  __typename?: 'ModelVacancySpacerConnection';
  items?: Maybe<Array<Maybe<VacancySpacer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancySpacerFilterInput = {
  clinic?: Maybe<ModelStringInput>;
  date?: Maybe<ModelStringInput>;
  laneId?: Maybe<ModelStringInput>;
  spacer: Array<Maybe<Scalars['String']>>;
  and?: Maybe<Array<Maybe<ModelVacancySpacerFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancySpacerFilterInput>>>;
  not?: Maybe<ModelVacancySpacerFilterInput>;
};

export type ModelVacancySpacerTemplateConditionInput = {
  clinic?: Maybe<ModelStringInput>;
  week?: Maybe<ModelIntInput>;
  laneId?: Maybe<ModelStringInput>;
  spacer?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelVacancySpacerTemplateConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancySpacerTemplateConditionInput>>>;
  not?: Maybe<ModelVacancySpacerTemplateConditionInput>;
};

export type ModelVacancySpacerTemplateConnection = {
  __typename?: 'ModelVacancySpacerTemplateConnection';
  items?: Maybe<Array<Maybe<VacancySpacerTemplate>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancySpacerTemplateFilterInput = {
  clinic?: Maybe<ModelStringInput>;
  week?: Maybe<ModelIntInput>;
  laneId?: Maybe<ModelStringInput>;
  spacer?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelVacancySpacerTemplateFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancySpacerTemplateFilterInput>>>;
  not?: Maybe<ModelVacancySpacerTemplateFilterInput>;
};

export type ModelVacancyStatusInput = {
  eq?: Maybe<VacancyStatus>;
  ne?: Maybe<VacancyStatus>;
};

export type ModelVacancyTemplateConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelVacancyTemplateConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyTemplateConditionInput>>>;
  not?: Maybe<ModelVacancyTemplateConditionInput>;
};

export type ModelVacancyTemplateConnection = {
  __typename?: 'ModelVacancyTemplateConnection';
  items?: Maybe<Array<Maybe<VacancyTemplate>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancyTemplateFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  order?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelVacancyTemplateFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyTemplateFilterInput>>>;
  not?: Maybe<ModelVacancyTemplateFilterInput>;
};

export type ModelVacancyUnitConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  /** 空き枠編集の並び順。100単位で分かれる */
  order?: Maybe<ModelIntInput>;
  departmentId?: Maybe<ModelStringInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDuration?: Maybe<ModelFloatInput>;
  online?: Maybe<ModelBooleanInput>;
  lanePreference?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelVacancyUnitConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyUnitConditionInput>>>;
  not?: Maybe<ModelVacancyUnitConditionInput>;
};

export type ModelVacancyUnitConnection = {
  __typename?: 'ModelVacancyUnitConnection';
  items?: Maybe<Array<Maybe<VacancyUnit>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVacancyUnitFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  clinicId?: Maybe<ModelStringInput>;
  /** 空き枠編集の並び順。100単位で分かれる */
  order?: Maybe<ModelIntInput>;
  departmentId?: Maybe<ModelStringInput>;
  treatmentKind?: Maybe<ModelStringInput>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDuration?: Maybe<ModelFloatInput>;
  online?: Maybe<ModelBooleanInput>;
  lanePreference?: Maybe<Array<Maybe<Scalars['String']>>>;
  and?: Maybe<Array<Maybe<ModelVacancyUnitFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVacancyUnitFilterInput>>>;
  not?: Maybe<ModelVacancyUnitFilterInput>;
};

export type ModelVaccineEventConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  /** 開始日付YYYY-MM-DD */
  start?: Maybe<ModelStringInput>;
  /** 終了日付YYYY-MM-DD */
  end?: Maybe<ModelStringInput>;
  /** 招待テンプレート */
  invitationTemplate?: Maybe<ModelStringInput>;
  /** 予約可能期間開始日YYYY-MM-DD */
  reserveTermStart?: Maybe<ModelStringInput>;
  /** 予約可能期間終了日YYYY-MM-DD */
  reserveTermEnd?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVaccineEventConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVaccineEventConditionInput>>>;
  not?: Maybe<ModelVaccineEventConditionInput>;
};

export type ModelVaccineEventConnection = {
  __typename?: 'ModelVaccineEventConnection';
  items?: Maybe<Array<Maybe<VaccineEvent>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVaccineEventFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  /** 開始日付YYYY-MM-DD */
  start?: Maybe<ModelStringInput>;
  /** 終了日付YYYY-MM-DD */
  end?: Maybe<ModelStringInput>;
  /** 招待テンプレート */
  invitationTemplate?: Maybe<ModelStringInput>;
  /** 予約可能期間開始日YYYY-MM-DD */
  reserveTermStart?: Maybe<ModelStringInput>;
  /** 予約可能期間終了日YYYY-MM-DD */
  reserveTermEnd?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVaccineEventFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVaccineEventFilterInput>>>;
  not?: Maybe<ModelVaccineEventFilterInput>;
};

export type ModelVaccinePatientConditionInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  email?: Maybe<ModelStringInput>;
  tel?: Maybe<ModelStringInput>;
  belongs?: Maybe<ModelStringInput>;
  /** ワクチン接種シークエンスを管理する一貫したID。 */
  sequenceId?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  invitationSentAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVaccinePatientConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelVaccinePatientConditionInput>>>;
  not?: Maybe<ModelVaccinePatientConditionInput>;
};

export type ModelVaccinePatientConnection = {
  __typename?: 'ModelVaccinePatientConnection';
  items?: Maybe<Array<Maybe<VaccinePatient>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelVaccinePatientFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  email?: Maybe<ModelStringInput>;
  tel?: Maybe<ModelStringInput>;
  belongs?: Maybe<ModelStringInput>;
  /** ワクチン接種シークエンスを管理する一貫したID。 */
  sequenceId?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  invitationSentAt?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelVaccinePatientFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVaccinePatientFilterInput>>>;
  not?: Maybe<ModelVaccinePatientFilterInput>;
};

export type ModelVaccinePatientVaccinePatientSequenceCompositeKeyConditionInput = {
  beginsWith?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>>>;
  eq?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
  ge?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
  gt?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
  le?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
  lt?: Maybe<ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput>;
};

export type ModelVaccinePatientVaccinePatientSequenceCompositeKeyInput = {
  createdAt?: Maybe<Scalars['String']>;
};

export type ModelWaitingListItemConditionInput = {
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelWaitingListItemConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelWaitingListItemConditionInput>>>;
  not?: Maybe<ModelWaitingListItemConditionInput>;
};

export type ModelWaitingListItemConnection = {
  __typename?: 'ModelWaitingListItemConnection';
  items?: Maybe<Array<Maybe<WaitingListItem>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelWaitingListItemFilterInput = {
  start?: Maybe<ModelStringInput>;
  end?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelWaitingListItemFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelWaitingListItemFilterInput>>>;
  not?: Maybe<ModelWaitingListItemFilterInput>;
};

export type ModelWaitingListItemPrimaryKeyCompositeKeyConditionInput = {
  eq?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
  le?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
  lt?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
  ge?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
  gt?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
  between?: Maybe<Array<Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>>>;
  beginsWith?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyInput>;
};

export type ModelWaitingListItemPrimaryKeyCompositeKeyInput = {
  start?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ModelZendeskMessageConditionInput = {
  type?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  messageId?: Maybe<ModelStringInput>;
  from?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelZendeskMessageConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelZendeskMessageConditionInput>>>;
  not?: Maybe<ModelZendeskMessageConditionInput>;
};

export type ModelZendeskMessageConnection = {
  __typename?: 'ModelZendeskMessageConnection';
  items?: Maybe<Array<Maybe<ZendeskMessage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelZendeskMessageFilterInput = {
  type?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  message?: Maybe<ModelStringInput>;
  messageId?: Maybe<ModelStringInput>;
  from?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelZendeskMessageFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelZendeskMessageFilterInput>>>;
  not?: Maybe<ModelZendeskMessageFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateScanner?: Maybe<ActivateScanner>;
  addConfirm?: Maybe<Scalars['Boolean']>;
  answerInterview?: Maybe<Interview>;
  cancelCheckupAppointment?: Maybe<EmptyResult>;
  cancelLumeccaTreatment?: Maybe<ProcessResult>;
  cancelPayment?: Maybe<PaymentStateMachine>;
  cancelTreatment?: Maybe<ProcessResult>;
  coopGoogleCalendar?: Maybe<EmptyResult>;
  createAttachment?: Maybe<Attachment>;
  createAuditLogQueryJob?: Maybe<AsyncProcessResult>;
  createAutoNumber?: Maybe<AutoNumber>;
  createBlock?: Maybe<Block>;
  createBlockWithValidation?: Maybe<Block>;
  createCard?: Maybe<Card>;
  createCheckupAdminUser?: Maybe<EmptyResult>;
  createCheckupAppointment?: Maybe<CheckupAppointment>;
  createCheckupAttachmentZip?: Maybe<AttachmentUrl>;
  createCheckupInterviewQuestion?: Maybe<CheckupInterviewQuestion>;
  createCheckupOption?: Maybe<CheckupOption>;
  createCheckupOptionIndividual?: Maybe<CheckupOptionIndividual>;
  createCheckupOrganization?: Maybe<CheckupOrganization>;
  createCheckupOrganizationChatWorkspace?: Maybe<CheckupOrganizationChatWorkspace>;
  createCheckupOrganizationEvent?: Maybe<CheckupOrganizationEvent>;
  createCheckupOrganizationEventByCopyingLatestEvent: CreateCheckupOrganizationEventByCopyingLatestEventResult;
  createCheckupOrganizationEventOption?: Maybe<CheckupOrganizationEventOption>;
  createCheckupOrganizationEventTemplate?: Maybe<CheckupOrganizationEventTemplate>;
  createCheckupOrganizationEventTemplateV2?: Maybe<CheckupOrganizationEventTemplateV2>;
  createCheckupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  createCheckupPlan?: Maybe<CheckupPlan>;
  createCheckupPlanIndividual?: Maybe<CheckupPlanIndividual>;
  createCheckupResult?: Maybe<CheckupResult>;
  createCheckupResultItem?: Maybe<CheckupResultItem>;
  createCheckupResultItemGroup?: Maybe<CheckupResultItemGroup>;
  createCheckupResultItemSubGroup?: Maybe<CheckupResultItemSubGroup>;
  createCheckupResultSet?: Maybe<CheckupResultSet>;
  createCheckupUnion?: Maybe<CheckupUnion>;
  createClinic?: Maybe<Clinic>;
  createClinicMemo?: Maybe<ClinicMemo>;
  createCustomer?: Maybe<Customer>;
  createDentalEvaluation?: Maybe<DentalEvaluation>;
  createDentalEvaluationItems?: Maybe<DentalEvaluationItems>;
  createDepartment?: Maybe<Department>;
  createDiscountCode?: Maybe<DiscountCode>;
  createDoctor?: Maybe<Doctor>;
  createDosage?: Maybe<Dosage>;
  createDosageFeedback?: Maybe<EmptyResult>;
  createDosagePrescription?: Maybe<DosagePrescription>;
  createDosageWithPrescription?: Maybe<CreateDosageWithPrescriptionResult>;
  createEmployee?: Maybe<Employee>;
  createEventInstruction?: Maybe<EventInstruction>;
  createExtendedDictionary?: Maybe<ExtendedDictionary>;
  createFeedback?: Maybe<Feedback>;
  createFollowup?: Maybe<Followup>;
  createImportJob?: Maybe<ImportJob>;
  createInstruction?: Maybe<Instruction>;
  createInterview?: Maybe<Interview>;
  createLineBotMessage?: Maybe<LineBotMessage>;
  createLineMessage?: Maybe<LineMessage>;
  createLineMessageTemplate?: Maybe<LineMessageTemplate>;
  createLineReadStatus?: Maybe<LineReadStatus>;
  createLineUser?: Maybe<LineUser>;
  createLocation?: Maybe<Location>;
  createLocationGroup?: Maybe<LocationGroup>;
  createMedicalQuestionnaireInterview?: Maybe<MedicalQuestionnaireInterview>;
  createMedicalQuestionnaireInterviewAnswers?: Maybe<MedicalQuestionnaireInterviewAnswers>;
  createMedicalQuestionnaireSurvey?: Maybe<MedicalQuestionnaireSurvey>;
  createMedicalQuestionnaireSurveyAnswers?: Maybe<MedicalQuestionnaireSurveyAnswers>;
  createNonce?: Maybe<Nonce>;
  createOpenMonth?: Maybe<OpenMonth>;
  createOrcaPatient?: Maybe<OrcaPatient>;
  createOrganization?: Maybe<Organization>;
  createPatient?: Maybe<Patient>;
  createPatientComment?: Maybe<PatientComment>;
  createPatientTag?: Maybe<PatientTag>;
  createPatientTagAssociation?: Maybe<PatientTagAssociation>;
  createPayment?: Maybe<Payment>;
  createPaymentState?: Maybe<PaymentState>;
  createPaymentStateMachine?: Maybe<PaymentStateMachine>;
  createPharmacist?: Maybe<Pharmacist>;
  createPharmacy?: Maybe<Pharmacy>;
  createRemindAction?: Maybe<RemindAction>;
  createRemindActionLog?: Maybe<RemindActionLog>;
  createRemindActionPlan?: Maybe<RemindActionPlan>;
  createReview?: Maybe<Review>;
  createRoom?: Maybe<Room>;
  createRoomVacancy?: Maybe<RoomVacancy>;
  createSOAPInfo?: Maybe<SoapInfo>;
  createSearchCondition?: Maybe<SearchCondition>;
  createSentMessageGroupBySearch?: Maybe<SentMessageGroupBySearch>;
  createShift?: Maybe<Shift>;
  createSlackBotToken?: Maybe<EmptyResult>;
  createSlackNonce?: Maybe<SlackNonce>;
  createStaff?: Maybe<Staff>;
  createStaffShift?: Maybe<StaffShift>;
  createStaffVacancy?: Maybe<StaffVacancy>;
  createTemporallyTreatment?: Maybe<Treatment>;
  createTemporallyTreatmentAdmin?: Maybe<Treatment>;
  createTreatment?: Maybe<Treatment>;
  createTreatmentAdmin?: Maybe<Treatment>;
  createTreatmentKind?: Maybe<TreatmentKind>;
  createTreatmentProgress?: Maybe<TreatmentProgress>;
  createTreatmentProgressHistory?: Maybe<TreatmentProgressHistory>;
  createTreatmentProgressNextStatusAssociation?: Maybe<TreatmentProgressNextStatusAssociation>;
  createTreatmentProgressStatus?: Maybe<TreatmentProgressStatus>;
  createTreatmentWithValidation?: Maybe<Treatment>;
  createUser?: Maybe<User>;
  createUserReserveCondition?: Maybe<UserReserveCondition>;
  createUserThread?: Maybe<UserThread>;
  createVacancies?: Maybe<Vacancies>;
  createVacancyConfigSet?: Maybe<VacancyConfigSet>;
  createVacancyLane?: Maybe<VacancyLane>;
  createVacancySpacer?: Maybe<VacancySpacer>;
  createVacancySpacerTemplate?: Maybe<VacancySpacerTemplate>;
  createVacancyTemplate?: Maybe<VacancyTemplate>;
  createVacancyUnit?: Maybe<VacancyUnit>;
  createVaccineEvent?: Maybe<VaccineEvent>;
  createVaccinePatient?: Maybe<VaccinePatient>;
  createWaitingListItem?: Maybe<WaitingListItem>;
  createZendeskMessage?: Maybe<ZendeskMessage>;
  deleteAttachment?: Maybe<Attachment>;
  deleteAutoNumber?: Maybe<AutoNumber>;
  deleteBlock?: Maybe<Block>;
  deleteBlockWithValidation?: Maybe<Block>;
  deleteCard?: Maybe<Card>;
  deleteCheckupAppointment?: Maybe<CheckupAppointment>;
  deleteCheckupAppointmentOrganizationTemporally?: Maybe<ProcessResult>;
  deleteCheckupInterviewQuestion?: Maybe<CheckupInterviewQuestion>;
  deleteCheckupOption?: Maybe<CheckupOption>;
  deleteCheckupOptionIndividual?: Maybe<CheckupOptionIndividual>;
  deleteCheckupOrganization?: Maybe<CheckupOrganization>;
  deleteCheckupOrganizationChatWorkspace?: Maybe<CheckupOrganizationChatWorkspace>;
  deleteCheckupOrganizationEvent?: Maybe<CheckupOrganizationEvent>;
  deleteCheckupOrganizationEventOption?: Maybe<CheckupOrganizationEventOption>;
  deleteCheckupOrganizationEventTemplate?: Maybe<CheckupOrganizationEventTemplate>;
  deleteCheckupOrganizationEventTemplateV2?: Maybe<CheckupOrganizationEventTemplateV2>;
  deleteCheckupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  deleteCheckupPlan?: Maybe<CheckupPlan>;
  deleteCheckupPlanIndividual?: Maybe<CheckupPlanIndividual>;
  deleteCheckupResult?: Maybe<CheckupResult>;
  deleteCheckupResultItem?: Maybe<CheckupResultItem>;
  deleteCheckupResultItemGroup?: Maybe<CheckupResultItemGroup>;
  deleteCheckupResultItemSubGroup?: Maybe<CheckupResultItemSubGroup>;
  deleteCheckupResultSet?: Maybe<CheckupResultSet>;
  deleteCheckupUnion?: Maybe<CheckupUnion>;
  deleteClinic?: Maybe<Clinic>;
  deleteClinicMemo?: Maybe<ClinicMemo>;
  deleteCustomer?: Maybe<Customer>;
  deleteDentalEvaluation?: Maybe<DentalEvaluation>;
  deleteDentalEvaluationItems?: Maybe<DentalEvaluationItems>;
  deleteDepartment?: Maybe<Department>;
  deleteDiscountCode?: Maybe<DiscountCode>;
  deleteDoctor?: Maybe<Doctor>;
  deleteDosage?: Maybe<Dosage>;
  deleteDosagePrescription?: Maybe<DosagePrescription>;
  deleteEmployee?: Maybe<Employee>;
  deleteEventInstruction?: Maybe<EventInstruction>;
  deleteEventMembers?: Maybe<EmptyResult>;
  deleteExtendedDictionary?: Maybe<ExtendedDictionary>;
  deleteFeedback?: Maybe<Feedback>;
  deleteFollowup?: Maybe<Followup>;
  deleteForUserAsset?: Maybe<EmptyResult>;
  deleteImportJob?: Maybe<ImportJob>;
  deleteInstruction?: Maybe<Instruction>;
  deleteLineBotMessage?: Maybe<LineBotMessage>;
  deleteLineMessage?: Maybe<LineMessage>;
  deleteLineMessageTemplate?: Maybe<LineMessageTemplate>;
  deleteLineReadStatus?: Maybe<LineReadStatus>;
  deleteLineUser?: Maybe<LineUser>;
  deleteLocation?: Maybe<Location>;
  deleteLocationGroup?: Maybe<LocationGroup>;
  deleteMedicalQuestionnaireInterview?: Maybe<MedicalQuestionnaireInterview>;
  deleteMedicalQuestionnaireInterviewAnswers?: Maybe<MedicalQuestionnaireInterviewAnswers>;
  deleteMedicalQuestionnaireInterviewList: DeleteMedicalQuestionnaireInterviewListResult;
  deleteMedicalQuestionnaireSurvey?: Maybe<MedicalQuestionnaireSurvey>;
  deleteMedicalQuestionnaireSurveyAnswers?: Maybe<MedicalQuestionnaireSurveyAnswers>;
  deleteNonce?: Maybe<Nonce>;
  deleteOpenMonth?: Maybe<OpenMonth>;
  deleteOrcaPatient?: Maybe<OrcaPatient>;
  deleteOrganization?: Maybe<Organization>;
  deletePatient?: Maybe<Patient>;
  deletePatientComment?: Maybe<PatientComment>;
  deletePatientTag?: Maybe<PatientTag>;
  deletePatientTagAssociation?: Maybe<PatientTagAssociation>;
  deletePayment?: Maybe<Payment>;
  deletePaymentState?: Maybe<PaymentState>;
  deletePaymentStateMachine?: Maybe<PaymentStateMachine>;
  deletePharmacist?: Maybe<Pharmacist>;
  deletePharmacy?: Maybe<Pharmacy>;
  deleteRemindAction?: Maybe<RemindAction>;
  deleteRemindActionLog?: Maybe<RemindActionLog>;
  deleteRemindActionPlan?: Maybe<RemindActionPlan>;
  deleteReview?: Maybe<Review>;
  deleteRoom?: Maybe<Room>;
  deleteRoomVacancy?: Maybe<RoomVacancy>;
  deleteSOAPInfo?: Maybe<SoapInfo>;
  deleteSearchCondition?: Maybe<SearchCondition>;
  deleteSentMessageGroupBySearch?: Maybe<SentMessageGroupBySearch>;
  deleteShift?: Maybe<Shift>;
  deleteSlackNonce?: Maybe<SlackNonce>;
  deleteStaff?: Maybe<Staff>;
  deleteStaffShift?: Maybe<StaffShift>;
  deleteStaffVacancy?: Maybe<StaffVacancy>;
  deleteTemporallyTreatmentAdmin?: Maybe<Treatment>;
  deleteTreatment?: Maybe<Treatment>;
  deleteTreatmentKind?: Maybe<TreatmentKind>;
  deleteTreatmentProgress?: Maybe<TreatmentProgress>;
  deleteTreatmentProgressHistory?: Maybe<TreatmentProgressHistory>;
  deleteTreatmentProgressNextStatusAssociation?: Maybe<TreatmentProgressNextStatusAssociation>;
  deleteTreatmentProgressStatus?: Maybe<TreatmentProgressStatus>;
  deleteUser?: Maybe<User>;
  deleteUserReserveCondition?: Maybe<UserReserveCondition>;
  deleteUserThread?: Maybe<UserThread>;
  deleteVacancies?: Maybe<Vacancies>;
  deleteVacancyConfigSet?: Maybe<VacancyConfigSet>;
  deleteVacancyLane?: Maybe<VacancyLane>;
  deleteVacancySpacer?: Maybe<VacancySpacer>;
  deleteVacancySpacerTemplate?: Maybe<VacancySpacerTemplate>;
  deleteVacancyTemplate?: Maybe<VacancyTemplate>;
  deleteVacancyUnit?: Maybe<VacancyUnit>;
  deleteVaccineEvent?: Maybe<VaccineEvent>;
  deleteVaccinePatient?: Maybe<VaccinePatient>;
  deleteWaitingListItem?: Maybe<WaitingListItem>;
  deleteZendeskMessage?: Maybe<ZendeskMessage>;
  disableNonce?: Maybe<Nonce>;
  fixCheckupAppointmentOrganization?: Maybe<Treatment>;
  fixTreatment?: Maybe<Treatment>;
  fixTreatmentCheckupIndividual?: Maybe<Treatment>;
  issueUserNonce?: Maybe<AccountLinkResult>;
  kickCheckupResultMappingJob?: Maybe<AsyncProcessResult>;
  kickSlackUserConnection?: Maybe<AsyncProcessResult>;
  notifyAsyncJobStatus?: Maybe<AsyncJob>;
  notifyChatUser?: Maybe<NotifyChatUser>;
  notifyPrescriptionUpdate?: Maybe<EmptyResult>;
  notifySlackUserConnectionFinished?: Maybe<AsyncProcessResult>;
  notifyTreatmentUpdate?: Maybe<NotifyTreatmentUpdate>;
  reAnswerInterview?: Maybe<Interview>;
  reJudgeCheckupResult?: Maybe<CheckupResultSet>;
  registerUser?: Maybe<User>;
  registerUserAdmin?: Maybe<User>;
  reserveCheckup?: Maybe<Treatment>;
  reserveCheckupAppointmentIndividual?: Maybe<Treatment>;
  reserveCheckupAppointmentIndividualTemporarily?: Maybe<Treatment>;
  reserveCheckupAppointmentOrganization?: Maybe<Treatment>;
  reserveCheckupAppointmentOrganizationTemporarily?: Maybe<Treatment>;
  resetCheckupAdminPassword?: Maybe<EmptyResult>;
  sendCheckupInvitation?: Maybe<AsyncProcessResult>;
  sendFeedback?: Maybe<Feedback>;
  sendFreeChat?: Maybe<AsyncProcessResult>;
  sendMessageBySearch?: Maybe<SendMessageBySearchFuncResult>;
  sendPharmacyCall?: Maybe<ProcessResult>;
  sendSqsOfCheckupResultSummary?: Maybe<ProcessResult>;
  sendVaccineInvitations?: Maybe<AsyncProcessResult>;
  setDosageFeedback?: Maybe<DosageFeedback>;
  startInterview?: Maybe<Interview>;
  submitCheckupInterview?: Maybe<Interview>;
  updateAttachment?: Maybe<Attachment>;
  updateAutoNumber?: Maybe<AutoNumber>;
  updateBlock?: Maybe<Block>;
  updateBlockWithValidation?: Maybe<Block>;
  updateCard?: Maybe<Card>;
  updateCheckupAppointment?: Maybe<CheckupAppointment>;
  updateCheckupAppointmentIndividual?: Maybe<Treatment>;
  updateCheckupAppointmentKitDeliverStatus?: Maybe<CheckupAppointment>;
  updateCheckupAppointmentOrganization?: Maybe<Treatment>;
  updateCheckupInterviewQuestion?: Maybe<CheckupInterviewQuestion>;
  updateCheckupOption?: Maybe<CheckupOption>;
  updateCheckupOptionIndividual?: Maybe<CheckupOptionIndividual>;
  updateCheckupOrganization?: Maybe<CheckupOrganization>;
  updateCheckupOrganizationChatWorkspace?: Maybe<CheckupOrganizationChatWorkspace>;
  updateCheckupOrganizationEvent?: Maybe<CheckupOrganizationEvent>;
  updateCheckupOrganizationEventOption?: Maybe<CheckupOrganizationEventOption>;
  updateCheckupOrganizationEventTemplate?: Maybe<CheckupOrganizationEventTemplate>;
  updateCheckupOrganizationEventTemplateV2?: Maybe<CheckupOrganizationEventTemplateV2>;
  updateCheckupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  updateCheckupPlan?: Maybe<CheckupPlan>;
  updateCheckupPlanIndividual?: Maybe<CheckupPlanIndividual>;
  updateCheckupReservation?: Maybe<Treatment>;
  updateCheckupResult?: Maybe<CheckupResult>;
  updateCheckupResultItem?: Maybe<CheckupResultItem>;
  updateCheckupResultItemGroup?: Maybe<CheckupResultItemGroup>;
  updateCheckupResultItemSubGroup?: Maybe<CheckupResultItemSubGroup>;
  updateCheckupResultSet?: Maybe<CheckupResultSet>;
  updateCheckupUnion?: Maybe<CheckupUnion>;
  updateClinic?: Maybe<Clinic>;
  updateClinicMemo?: Maybe<ClinicMemo>;
  updateCustomer?: Maybe<Customer>;
  updateDentalEvaluation?: Maybe<DentalEvaluation>;
  updateDentalEvaluationItems?: Maybe<DentalEvaluationItems>;
  updateDepartment?: Maybe<Department>;
  updateDiscountCode?: Maybe<DiscountCode>;
  updateDoctor?: Maybe<Doctor>;
  updateDosage?: Maybe<Dosage>;
  updateDosagePrescription?: Maybe<DosagePrescription>;
  updateDosageStatus?: Maybe<Dosage>;
  updateDosageVisitingDatetime?: Maybe<Dosage>;
  updateEmployee?: Maybe<Employee>;
  updateEventInstruction?: Maybe<EventInstruction>;
  updateEventMembers?: Maybe<EmptyResult>;
  updateExtendedDictionary?: Maybe<ExtendedDictionary>;
  updateFeedback?: Maybe<Feedback>;
  updateFollowup?: Maybe<Followup>;
  updateImportJob?: Maybe<ImportJob>;
  updateInstruction?: Maybe<Instruction>;
  updateInterview?: Maybe<Interview>;
  updateLineBotMessage?: Maybe<LineBotMessage>;
  updateLineMessage?: Maybe<LineMessage>;
  updateLineMessageOpenedByUserAt?: Maybe<LineMessage>;
  updateLineMessageTemplate?: Maybe<LineMessageTemplate>;
  updateLineReadStatus?: Maybe<LineReadStatus>;
  updateLineReadStatusForAlreadyRead?: Maybe<LineReadStatus>;
  updateLineReadStatusForReceive?: Maybe<LineReadStatus>;
  updateLineUser?: Maybe<LineUser>;
  updateLocation?: Maybe<Location>;
  updateLocationGroup?: Maybe<LocationGroup>;
  updateMedicalQuestionnaireInterview?: Maybe<MedicalQuestionnaireInterview>;
  updateMedicalQuestionnaireInterviewAnswers?: Maybe<MedicalQuestionnaireInterviewAnswers>;
  updateMedicalQuestionnaireInterviewList: UpdateMedicalQuestionnaireInterviewListResult;
  updateMedicalQuestionnaireSurvey?: Maybe<MedicalQuestionnaireSurvey>;
  updateMedicalQuestionnaireSurveyAnswers?: Maybe<MedicalQuestionnaireSurveyAnswers>;
  updateNonce?: Maybe<Nonce>;
  updateOpenMonth?: Maybe<OpenMonth>;
  updateOrcaPatient?: Maybe<OrcaPatient>;
  updateOrganization?: Maybe<Organization>;
  updatePatient?: Maybe<Patient>;
  updatePatientComment?: Maybe<PatientComment>;
  updatePatientTag?: Maybe<PatientTag>;
  updatePatientTagAssociation?: Maybe<PatientTagAssociation>;
  updatePayment?: Maybe<Payment>;
  updatePaymentState?: Maybe<PaymentState>;
  updatePaymentStateMachine?: Maybe<PaymentStateMachine>;
  updatePharmacist?: Maybe<Pharmacist>;
  updatePharmacy?: Maybe<Pharmacy>;
  updateProfile?: Maybe<User>;
  updateRemindAction?: Maybe<RemindAction>;
  updateRemindActionLog?: Maybe<RemindActionLog>;
  updateRemindActionPlan?: Maybe<RemindActionPlan>;
  updateRemindActionStatus?: Maybe<RemindAction>;
  updateReview?: Maybe<Review>;
  updateRoom?: Maybe<Room>;
  updateRoomVacancy?: Maybe<RoomVacancy>;
  updateSOAPInfo?: Maybe<SoapInfo>;
  updateSearchCondition?: Maybe<SearchCondition>;
  updateSentMessageGroupBySearch?: Maybe<SentMessageGroupBySearch>;
  updateShift?: Maybe<Shift>;
  updateShiftManual?: Maybe<ProcessResult>;
  updateSlackNonce?: Maybe<SlackNonce>;
  updateStaff?: Maybe<Staff>;
  updateStaffShift?: Maybe<StaffShift>;
  updateStaffVacancy?: Maybe<StaffVacancy>;
  /** dummy */
  updateTreatment?: Maybe<Treatment>;
  updateTreatmentAdmin?: Maybe<Treatment>;
  updateTreatmentChecklist?: Maybe<Treatment>;
  updateTreatmentComment?: Maybe<Treatment>;
  updateTreatmentEditCondition?: Maybe<Treatment>;
  updateTreatmentFields?: Maybe<Treatment>;
  updateTreatmentKind?: Maybe<TreatmentKind>;
  updateTreatmentLane?: Maybe<Treatment>;
  updateTreatmentLateContact?: Maybe<Treatment>;
  updateTreatmentNoCharge?: Maybe<Treatment>;
  updateTreatmentPharmacy?: Maybe<Treatment>;
  updateTreatmentProgress?: Maybe<TreatmentProgress>;
  updateTreatmentProgressHistory?: Maybe<TreatmentProgressHistory>;
  updateTreatmentProgressNextStatusAssociation?: Maybe<TreatmentProgressNextStatusAssociation>;
  updateTreatmentProgressStatus?: Maybe<TreatmentProgressStatus>;
  updateTreatmentSendRemind?: Maybe<Treatment>;
  updateTreatmentStatus?: Maybe<Treatment>;
  updateTreatmentWithValidation?: Maybe<Treatment>;
  updateUser?: Maybe<User>;
  updateUserLineUserId?: Maybe<User>;
  updateUserReserveCondition?: Maybe<UserReserveCondition>;
  updateUserThread?: Maybe<UserThread>;
  updateUserThreadMessageIds?: Maybe<UserThread>;
  updateUserThreadStatusAndName?: Maybe<UserThread>;
  updateVacancies?: Maybe<Vacancies>;
  updateVacancy?: Maybe<Vacancies>;
  updateVacancyConfigSet?: Maybe<VacancyConfigSet>;
  updateVacancyLane?: Maybe<VacancyLane>;
  updateVacancySpacer?: Maybe<VacancySpacer>;
  updateVacancySpacerTemplate?: Maybe<VacancySpacerTemplate>;
  updateVacancyTemplate?: Maybe<VacancyTemplate>;
  updateVacancyUnit?: Maybe<VacancyUnit>;
  updateVaccineEvent?: Maybe<VaccineEvent>;
  updateVaccinePatient?: Maybe<VaccinePatient>;
  updateWaitingListItem?: Maybe<WaitingListItem>;
  updateZendeskMessage?: Maybe<ZendeskMessage>;
  validateCard?: Maybe<CardConfirmation>;
};


export type MutationActivateScannerArgs = {
  input?: Maybe<ActivateScannerInput>;
};


export type MutationAddConfirmArgs = {
  input: ConfirmQueryInput;
};


export type MutationAnswerInterviewArgs = {
  input?: Maybe<AnswerInterviewInput>;
};


export type MutationCancelCheckupAppointmentArgs = {
  input: CancelCheckupInput;
};


export type MutationCancelLumeccaTreatmentArgs = {
  input: CancelTreatmentInput;
};


export type MutationCancelPaymentArgs = {
  input?: Maybe<CancelPaymentInput>;
};


export type MutationCancelTreatmentArgs = {
  input: CancelTreatmentInput;
};


export type MutationCoopGoogleCalendarArgs = {
  input: GoogleCalendarCoopInput;
};


export type MutationCreateAttachmentArgs = {
  input: CreateAttachmentInput;
  condition?: Maybe<ModelAttachmentConditionInput>;
};


export type MutationCreateAuditLogQueryJobArgs = {
  input: CreateAuditLogQueryJobInput;
};


export type MutationCreateAutoNumberArgs = {
  input: CreateAutoNumberInput;
  condition?: Maybe<ModelAutoNumberConditionInput>;
};


export type MutationCreateBlockArgs = {
  input: CreateBlockInput;
  condition?: Maybe<ModelBlockConditionInput>;
};


export type MutationCreateBlockWithValidationArgs = {
  input?: Maybe<CreateBlockInput>;
};


export type MutationCreateCardArgs = {
  input: CreateCardInput;
  condition?: Maybe<ModelCardConditionInput>;
};


export type MutationCreateCheckupAdminUserArgs = {
  input: CreateCheckupAdminUserInput;
};


export type MutationCreateCheckupAppointmentArgs = {
  input: CreateCheckupAppointmentInput;
  condition?: Maybe<ModelCheckupAppointmentConditionInput>;
};


export type MutationCreateCheckupAttachmentZipArgs = {
  input: CreateCheckupAttachmentZipInput;
};


export type MutationCreateCheckupInterviewQuestionArgs = {
  input: CreateCheckupInterviewQuestionInput;
  condition?: Maybe<ModelCheckupInterviewQuestionConditionInput>;
};


export type MutationCreateCheckupOptionArgs = {
  input: CreateCheckupOptionInput;
  condition?: Maybe<ModelCheckupOptionConditionInput>;
};


export type MutationCreateCheckupOptionIndividualArgs = {
  input: CreateCheckupOptionIndividualInput;
  condition?: Maybe<ModelCheckupOptionIndividualConditionInput>;
};


export type MutationCreateCheckupOrganizationArgs = {
  input: CreateCheckupOrganizationInput;
  condition?: Maybe<ModelCheckupOrganizationConditionInput>;
};


export type MutationCreateCheckupOrganizationChatWorkspaceArgs = {
  input: CreateCheckupOrganizationChatWorkspaceInput;
  condition?: Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>;
};


export type MutationCreateCheckupOrganizationEventArgs = {
  input: CreateCheckupOrganizationEventInput;
  condition?: Maybe<ModelCheckupOrganizationEventConditionInput>;
};


export type MutationCreateCheckupOrganizationEventByCopyingLatestEventArgs = {
  input: CreateCheckupOrganizationEventByCopyingLatestEventInput;
};


export type MutationCreateCheckupOrganizationEventOptionArgs = {
  input: CreateCheckupOrganizationEventOptionInput;
  condition?: Maybe<ModelCheckupOrganizationEventOptionConditionInput>;
};


export type MutationCreateCheckupOrganizationEventTemplateArgs = {
  input: CreateCheckupOrganizationEventTemplateInput;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateConditionInput>;
};


export type MutationCreateCheckupOrganizationEventTemplateV2Args = {
  input: CreateCheckupOrganizationEventTemplateV2Input;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>;
};


export type MutationCreateCheckupOrganizationMemberEventArgs = {
  input: CreateCheckupOrganizationMemberEventInput;
  condition?: Maybe<ModelCheckupOrganizationMemberEventConditionInput>;
};


export type MutationCreateCheckupPlanArgs = {
  input: CreateCheckupPlanInput;
  condition?: Maybe<ModelCheckupPlanConditionInput>;
};


export type MutationCreateCheckupPlanIndividualArgs = {
  input: CreateCheckupPlanIndividualInput;
  condition?: Maybe<ModelCheckupPlanIndividualConditionInput>;
};


export type MutationCreateCheckupResultArgs = {
  input: CreateCheckupResultInput;
  condition?: Maybe<ModelCheckupResultConditionInput>;
};


export type MutationCreateCheckupResultItemArgs = {
  input: CreateCheckupResultItemInput;
  condition?: Maybe<ModelCheckupResultItemConditionInput>;
};


export type MutationCreateCheckupResultItemGroupArgs = {
  input: CreateCheckupResultItemGroupInput;
  condition?: Maybe<ModelCheckupResultItemGroupConditionInput>;
};


export type MutationCreateCheckupResultItemSubGroupArgs = {
  input: CreateCheckupResultItemSubGroupInput;
  condition?: Maybe<ModelCheckupResultItemSubGroupConditionInput>;
};


export type MutationCreateCheckupResultSetArgs = {
  input: CreateCheckupResultSetInput;
  condition?: Maybe<ModelCheckupResultSetConditionInput>;
};


export type MutationCreateCheckupUnionArgs = {
  input: CreateCheckupUnionInput;
  condition?: Maybe<ModelCheckupUnionConditionInput>;
};


export type MutationCreateClinicArgs = {
  input: CreateClinicInput;
  condition?: Maybe<ModelClinicConditionInput>;
};


export type MutationCreateClinicMemoArgs = {
  input: CreateClinicMemoInput;
  condition?: Maybe<ModelClinicMemoConditionInput>;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
  condition?: Maybe<ModelCustomerConditionInput>;
};


export type MutationCreateDentalEvaluationArgs = {
  input: CreateDentalEvaluationInput;
  condition?: Maybe<ModelDentalEvaluationConditionInput>;
};


export type MutationCreateDentalEvaluationItemsArgs = {
  input: CreateDentalEvaluationItemsInput;
  condition?: Maybe<ModelDentalEvaluationItemsConditionInput>;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
  condition?: Maybe<ModelDepartmentConditionInput>;
};


export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
  condition?: Maybe<ModelDiscountCodeConditionInput>;
};


export type MutationCreateDoctorArgs = {
  input: CreateDoctorInput;
  condition?: Maybe<ModelDoctorConditionInput>;
};


export type MutationCreateDosageArgs = {
  input: CreateDosageInput;
  condition?: Maybe<ModelDosageConditionInput>;
};


export type MutationCreateDosageFeedbackArgs = {
  input: CreateDosageFeedbackInput;
};


export type MutationCreateDosagePrescriptionArgs = {
  input: CreateDosagePrescriptionInput;
  condition?: Maybe<ModelDosagePrescriptionConditionInput>;
};


export type MutationCreateDosageWithPrescriptionArgs = {
  input: CreateDosageWithPrescriptionInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
  condition?: Maybe<ModelEmployeeConditionInput>;
};


export type MutationCreateEventInstructionArgs = {
  input: CreateEventInstructionInput;
  condition?: Maybe<ModelEventInstructionConditionInput>;
};


export type MutationCreateExtendedDictionaryArgs = {
  input: CreateExtendedDictionaryInput;
  condition?: Maybe<ModelExtendedDictionaryConditionInput>;
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
  condition?: Maybe<ModelFeedbackConditionInput>;
};


export type MutationCreateFollowupArgs = {
  input: CreateFollowupInput;
  condition?: Maybe<ModelFollowupConditionInput>;
};


export type MutationCreateImportJobArgs = {
  input: CreateImportJobInput;
  condition?: Maybe<ModelImportJobConditionInput>;
};


export type MutationCreateInstructionArgs = {
  input: CreateInstructionInput;
  condition?: Maybe<ModelInstructionConditionInput>;
};


export type MutationCreateInterviewArgs = {
  input: CreateInterviewInput;
};


export type MutationCreateLineBotMessageArgs = {
  input: CreateLineBotMessageInput;
  condition?: Maybe<ModelLineBotMessageConditionInput>;
};


export type MutationCreateLineMessageArgs = {
  input: CreateLineMessageInput;
  condition?: Maybe<ModelLineMessageConditionInput>;
};


export type MutationCreateLineMessageTemplateArgs = {
  input: CreateLineMessageTemplateInput;
  condition?: Maybe<ModelLineMessageTemplateConditionInput>;
};


export type MutationCreateLineReadStatusArgs = {
  input: CreateLineReadStatusInput;
  condition?: Maybe<ModelLineReadStatusConditionInput>;
};


export type MutationCreateLineUserArgs = {
  input: CreateLineUserInput;
  condition?: Maybe<ModelLineUserConditionInput>;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
  condition?: Maybe<ModelLocationConditionInput>;
};


export type MutationCreateLocationGroupArgs = {
  input: CreateLocationGroupInput;
  condition?: Maybe<ModelLocationGroupConditionInput>;
};


export type MutationCreateMedicalQuestionnaireInterviewArgs = {
  input: CreateMedicalQuestionnaireInterviewInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewConditionInput>;
};


export type MutationCreateMedicalQuestionnaireInterviewAnswersArgs = {
  input: CreateMedicalQuestionnaireInterviewAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>;
};


export type MutationCreateMedicalQuestionnaireSurveyArgs = {
  input: CreateMedicalQuestionnaireSurveyInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyConditionInput>;
};


export type MutationCreateMedicalQuestionnaireSurveyAnswersArgs = {
  input: CreateMedicalQuestionnaireSurveyAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>;
};


export type MutationCreateNonceArgs = {
  input: CreateNonceInput;
  condition?: Maybe<ModelNonceConditionInput>;
};


export type MutationCreateOpenMonthArgs = {
  input: CreateOpenMonthInput;
  condition?: Maybe<ModelOpenMonthConditionInput>;
};


export type MutationCreateOrcaPatientArgs = {
  input: CreateOrcaPatientInput;
  condition?: Maybe<ModelOrcaPatientConditionInput>;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
  condition?: Maybe<ModelOrganizationConditionInput>;
};


export type MutationCreatePatientArgs = {
  input: CreatePatientInput;
  condition?: Maybe<ModelPatientConditionInput>;
};


export type MutationCreatePatientCommentArgs = {
  input: CreatePatientCommentInput;
  condition?: Maybe<ModelPatientCommentConditionInput>;
};


export type MutationCreatePatientTagArgs = {
  input: CreatePatientTagInput;
  condition?: Maybe<ModelPatientTagConditionInput>;
};


export type MutationCreatePatientTagAssociationArgs = {
  input: CreatePatientTagAssociationInput;
  condition?: Maybe<ModelPatientTagAssociationConditionInput>;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
  condition?: Maybe<ModelPaymentConditionInput>;
};


export type MutationCreatePaymentStateArgs = {
  input: CreatePaymentStateInput;
  condition?: Maybe<ModelPaymentStateConditionInput>;
};


export type MutationCreatePaymentStateMachineArgs = {
  input: CreatePaymentStateMachineInput;
  condition?: Maybe<ModelPaymentStateMachineConditionInput>;
};


export type MutationCreatePharmacistArgs = {
  input: CreatePharmacistInput;
  condition?: Maybe<ModelPharmacistConditionInput>;
};


export type MutationCreatePharmacyArgs = {
  input: CreatePharmacyInput;
  condition?: Maybe<ModelPharmacyConditionInput>;
};


export type MutationCreateRemindActionArgs = {
  input: CreateRemindActionInput;
  condition?: Maybe<ModelRemindActionConditionInput>;
};


export type MutationCreateRemindActionLogArgs = {
  input: CreateRemindActionLogInput;
  condition?: Maybe<ModelRemindActionLogConditionInput>;
};


export type MutationCreateRemindActionPlanArgs = {
  input: CreateRemindActionPlanInput;
  condition?: Maybe<ModelRemindActionPlanConditionInput>;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
  condition?: Maybe<ModelReviewConditionInput>;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
  condition?: Maybe<ModelRoomConditionInput>;
};


export type MutationCreateRoomVacancyArgs = {
  input: CreateRoomVacancyInput;
  condition?: Maybe<ModelRoomVacancyConditionInput>;
};


export type MutationCreateSoapInfoArgs = {
  input: CreateSoapInfoInput;
  condition?: Maybe<ModelSoapInfoConditionInput>;
};


export type MutationCreateSearchConditionArgs = {
  input: CreateSearchConditionInput;
  condition?: Maybe<ModelSearchConditionConditionInput>;
};


export type MutationCreateSentMessageGroupBySearchArgs = {
  input: CreateSentMessageGroupBySearchInput;
};


export type MutationCreateShiftArgs = {
  input: CreateShiftInput;
  condition?: Maybe<ModelShiftConditionInput>;
};


export type MutationCreateSlackBotTokenArgs = {
  input: CreateSlackBotTokenInput;
};


export type MutationCreateSlackNonceArgs = {
  input: CreateSlackNonceInput;
  condition?: Maybe<ModelSlackNonceConditionInput>;
};


export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
  condition?: Maybe<ModelStaffConditionInput>;
};


export type MutationCreateStaffShiftArgs = {
  input: CreateStaffShiftInput;
  condition?: Maybe<ModelStaffShiftConditionInput>;
};


export type MutationCreateStaffVacancyArgs = {
  input: CreateStaffVacancyInput;
  condition?: Maybe<ModelStaffVacancyConditionInput>;
};


export type MutationCreateTemporallyTreatmentArgs = {
  input: CreateTreatmentInput;
};


export type MutationCreateTemporallyTreatmentAdminArgs = {
  input: CreateTreatmentInput;
};


export type MutationCreateTreatmentArgs = {
  input: CreateTreatmentInput;
  condition?: Maybe<ModelTreatmentConditionInput>;
};


export type MutationCreateTreatmentAdminArgs = {
  input: CreateTreatmentAdminInput;
};


export type MutationCreateTreatmentKindArgs = {
  input: CreateTreatmentKindInput;
  condition?: Maybe<ModelTreatmentKindConditionInput>;
};


export type MutationCreateTreatmentProgressArgs = {
  input: CreateTreatmentProgressInput;
  condition?: Maybe<ModelTreatmentProgressConditionInput>;
};


export type MutationCreateTreatmentProgressHistoryArgs = {
  input: CreateTreatmentProgressHistoryInput;
  condition?: Maybe<ModelTreatmentProgressHistoryConditionInput>;
};


export type MutationCreateTreatmentProgressNextStatusAssociationArgs = {
  input: CreateTreatmentProgressNextStatusAssociationInput;
  condition?: Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>;
};


export type MutationCreateTreatmentProgressStatusArgs = {
  input: CreateTreatmentProgressStatusInput;
  condition?: Maybe<ModelTreatmentProgressStatusConditionInput>;
};


export type MutationCreateTreatmentWithValidationArgs = {
  input?: Maybe<CreateTreatmentInput>;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
  condition?: Maybe<ModelUserConditionInput>;
};


export type MutationCreateUserReserveConditionArgs = {
  input: CreateUserReserveConditionInput;
  condition?: Maybe<ModelUserReserveConditionConditionInput>;
};


export type MutationCreateUserThreadArgs = {
  input: CreateUserThreadInput;
  condition?: Maybe<ModelUserThreadConditionInput>;
};


export type MutationCreateVacanciesArgs = {
  input: CreateVacanciesInput;
  condition?: Maybe<ModelVacanciesConditionInput>;
};


export type MutationCreateVacancyConfigSetArgs = {
  input: CreateVacancyConfigSetInput;
  condition?: Maybe<ModelVacancyConfigSetConditionInput>;
};


export type MutationCreateVacancyLaneArgs = {
  input: CreateVacancyLaneInput;
  condition?: Maybe<ModelVacancyLaneConditionInput>;
};


export type MutationCreateVacancySpacerArgs = {
  input: CreateVacancySpacerInput;
  condition?: Maybe<ModelVacancySpacerConditionInput>;
};


export type MutationCreateVacancySpacerTemplateArgs = {
  input: CreateVacancySpacerTemplateInput;
  condition?: Maybe<ModelVacancySpacerTemplateConditionInput>;
};


export type MutationCreateVacancyTemplateArgs = {
  input: CreateVacancyTemplateInput;
  condition?: Maybe<ModelVacancyTemplateConditionInput>;
};


export type MutationCreateVacancyUnitArgs = {
  input: CreateVacancyUnitInput;
  condition?: Maybe<ModelVacancyUnitConditionInput>;
};


export type MutationCreateVaccineEventArgs = {
  input: CreateVaccineEventInput;
  condition?: Maybe<ModelVaccineEventConditionInput>;
};


export type MutationCreateVaccinePatientArgs = {
  input: CreateVaccinePatientInput;
  condition?: Maybe<ModelVaccinePatientConditionInput>;
};


export type MutationCreateWaitingListItemArgs = {
  input: CreateWaitingListItemInput;
  condition?: Maybe<ModelWaitingListItemConditionInput>;
};


export type MutationCreateZendeskMessageArgs = {
  input: CreateZendeskMessageInput;
  condition?: Maybe<ModelZendeskMessageConditionInput>;
};


export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
  condition?: Maybe<ModelAttachmentConditionInput>;
};


export type MutationDeleteAutoNumberArgs = {
  input: DeleteAutoNumberInput;
  condition?: Maybe<ModelAutoNumberConditionInput>;
};


export type MutationDeleteBlockArgs = {
  input: DeleteBlockInput;
  condition?: Maybe<ModelBlockConditionInput>;
};


export type MutationDeleteBlockWithValidationArgs = {
  input?: Maybe<DeleteBlockInput>;
};


export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
  condition?: Maybe<ModelCardConditionInput>;
};


export type MutationDeleteCheckupAppointmentArgs = {
  input: DeleteCheckupAppointmentInput;
  condition?: Maybe<ModelCheckupAppointmentConditionInput>;
};


export type MutationDeleteCheckupAppointmentOrganizationTemporallyArgs = {
  input: DeleteCheckupAppointmentOrganizationInput;
};


export type MutationDeleteCheckupInterviewQuestionArgs = {
  input: DeleteCheckupInterviewQuestionInput;
  condition?: Maybe<ModelCheckupInterviewQuestionConditionInput>;
};


export type MutationDeleteCheckupOptionArgs = {
  input: DeleteCheckupOptionInput;
  condition?: Maybe<ModelCheckupOptionConditionInput>;
};


export type MutationDeleteCheckupOptionIndividualArgs = {
  input: DeleteCheckupOptionIndividualInput;
  condition?: Maybe<ModelCheckupOptionIndividualConditionInput>;
};


export type MutationDeleteCheckupOrganizationArgs = {
  input: DeleteCheckupOrganizationInput;
  condition?: Maybe<ModelCheckupOrganizationConditionInput>;
};


export type MutationDeleteCheckupOrganizationChatWorkspaceArgs = {
  input: DeleteCheckupOrganizationChatWorkspaceInput;
  condition?: Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>;
};


export type MutationDeleteCheckupOrganizationEventArgs = {
  input: DeleteCheckupOrganizationEventInput;
  condition?: Maybe<ModelCheckupOrganizationEventConditionInput>;
};


export type MutationDeleteCheckupOrganizationEventOptionArgs = {
  input: DeleteCheckupOrganizationEventOptionInput;
  condition?: Maybe<ModelCheckupOrganizationEventOptionConditionInput>;
};


export type MutationDeleteCheckupOrganizationEventTemplateArgs = {
  input: DeleteCheckupOrganizationEventTemplateInput;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateConditionInput>;
};


export type MutationDeleteCheckupOrganizationEventTemplateV2Args = {
  input: DeleteCheckupOrganizationEventTemplateV2Input;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>;
};


export type MutationDeleteCheckupOrganizationMemberEventArgs = {
  input: DeleteCheckupOrganizationMemberEventInput;
  condition?: Maybe<ModelCheckupOrganizationMemberEventConditionInput>;
};


export type MutationDeleteCheckupPlanArgs = {
  input: DeleteCheckupPlanInput;
  condition?: Maybe<ModelCheckupPlanConditionInput>;
};


export type MutationDeleteCheckupPlanIndividualArgs = {
  input: DeleteCheckupPlanIndividualInput;
  condition?: Maybe<ModelCheckupPlanIndividualConditionInput>;
};


export type MutationDeleteCheckupResultArgs = {
  input: DeleteCheckupResultInput;
  condition?: Maybe<ModelCheckupResultConditionInput>;
};


export type MutationDeleteCheckupResultItemArgs = {
  input: DeleteCheckupResultItemInput;
  condition?: Maybe<ModelCheckupResultItemConditionInput>;
};


export type MutationDeleteCheckupResultItemGroupArgs = {
  input: DeleteCheckupResultItemGroupInput;
  condition?: Maybe<ModelCheckupResultItemGroupConditionInput>;
};


export type MutationDeleteCheckupResultItemSubGroupArgs = {
  input: DeleteCheckupResultItemSubGroupInput;
  condition?: Maybe<ModelCheckupResultItemSubGroupConditionInput>;
};


export type MutationDeleteCheckupResultSetArgs = {
  input: DeleteCheckupResultSetInput;
  condition?: Maybe<ModelCheckupResultSetConditionInput>;
};


export type MutationDeleteCheckupUnionArgs = {
  input: DeleteCheckupUnionInput;
  condition?: Maybe<ModelCheckupUnionConditionInput>;
};


export type MutationDeleteClinicArgs = {
  input: DeleteClinicInput;
  condition?: Maybe<ModelClinicConditionInput>;
};


export type MutationDeleteClinicMemoArgs = {
  input: DeleteClinicMemoInput;
  condition?: Maybe<ModelClinicMemoConditionInput>;
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
  condition?: Maybe<ModelCustomerConditionInput>;
};


export type MutationDeleteDentalEvaluationArgs = {
  input: DeleteDentalEvaluationInput;
  condition?: Maybe<ModelDentalEvaluationConditionInput>;
};


export type MutationDeleteDentalEvaluationItemsArgs = {
  input: DeleteDentalEvaluationItemsInput;
  condition?: Maybe<ModelDentalEvaluationItemsConditionInput>;
};


export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
  condition?: Maybe<ModelDepartmentConditionInput>;
};


export type MutationDeleteDiscountCodeArgs = {
  input: DeleteDiscountCodeInput;
  condition?: Maybe<ModelDiscountCodeConditionInput>;
};


export type MutationDeleteDoctorArgs = {
  input: DeleteDoctorInput;
  condition?: Maybe<ModelDoctorConditionInput>;
};


export type MutationDeleteDosageArgs = {
  input: DeleteDosageInput;
  condition?: Maybe<ModelDosageConditionInput>;
};


export type MutationDeleteDosagePrescriptionArgs = {
  input: DeleteDosagePrescriptionInput;
  condition?: Maybe<ModelDosagePrescriptionConditionInput>;
};


export type MutationDeleteEmployeeArgs = {
  input: DeleteEmployeeInput;
  condition?: Maybe<ModelEmployeeConditionInput>;
};


export type MutationDeleteEventInstructionArgs = {
  input: DeleteEventInstructionInput;
  condition?: Maybe<ModelEventInstructionConditionInput>;
};


export type MutationDeleteEventMembersArgs = {
  input: DeleteEventMembersInput;
};


export type MutationDeleteExtendedDictionaryArgs = {
  input: DeleteExtendedDictionaryInput;
  condition?: Maybe<ModelExtendedDictionaryConditionInput>;
};


export type MutationDeleteFeedbackArgs = {
  input: DeleteFeedbackInput;
  condition?: Maybe<ModelFeedbackConditionInput>;
};


export type MutationDeleteFollowupArgs = {
  input: DeleteFollowupInput;
  condition?: Maybe<ModelFollowupConditionInput>;
};


export type MutationDeleteForUserAssetArgs = {
  input: DeleteForUserAsset;
};


export type MutationDeleteImportJobArgs = {
  input: DeleteImportJobInput;
  condition?: Maybe<ModelImportJobConditionInput>;
};


export type MutationDeleteInstructionArgs = {
  input: DeleteInstructionInput;
  condition?: Maybe<ModelInstructionConditionInput>;
};


export type MutationDeleteLineBotMessageArgs = {
  input: DeleteLineBotMessageInput;
  condition?: Maybe<ModelLineBotMessageConditionInput>;
};


export type MutationDeleteLineMessageArgs = {
  input: DeleteLineMessageInput;
  condition?: Maybe<ModelLineMessageConditionInput>;
};


export type MutationDeleteLineMessageTemplateArgs = {
  input: DeleteLineMessageTemplateInput;
  condition?: Maybe<ModelLineMessageTemplateConditionInput>;
};


export type MutationDeleteLineUserArgs = {
  input: DeleteLineUserInput;
  condition?: Maybe<ModelLineUserConditionInput>;
};


export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
  condition?: Maybe<ModelLocationConditionInput>;
};


export type MutationDeleteLocationGroupArgs = {
  input: DeleteLocationGroupInput;
  condition?: Maybe<ModelLocationGroupConditionInput>;
};


export type MutationDeleteMedicalQuestionnaireInterviewArgs = {
  input: DeleteMedicalQuestionnaireInterviewInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewConditionInput>;
};


export type MutationDeleteMedicalQuestionnaireInterviewAnswersArgs = {
  input: DeleteMedicalQuestionnaireInterviewAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>;
};


export type MutationDeleteMedicalQuestionnaireInterviewListArgs = {
  input: DeleteMedicalQuestionnaireInterviewListInput;
};


export type MutationDeleteMedicalQuestionnaireSurveyArgs = {
  input: DeleteMedicalQuestionnaireSurveyInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyConditionInput>;
};


export type MutationDeleteMedicalQuestionnaireSurveyAnswersArgs = {
  input: DeleteMedicalQuestionnaireSurveyAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>;
};


export type MutationDeleteNonceArgs = {
  input: DeleteNonceInput;
  condition?: Maybe<ModelNonceConditionInput>;
};


export type MutationDeleteOpenMonthArgs = {
  input: DeleteOpenMonthInput;
  condition?: Maybe<ModelOpenMonthConditionInput>;
};


export type MutationDeleteOrcaPatientArgs = {
  input: DeleteOrcaPatientInput;
  condition?: Maybe<ModelOrcaPatientConditionInput>;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
  condition?: Maybe<ModelOrganizationConditionInput>;
};


export type MutationDeletePatientArgs = {
  input: DeletePatientInput;
  condition?: Maybe<ModelPatientConditionInput>;
};


export type MutationDeletePatientCommentArgs = {
  input: DeletePatientCommentInput;
  condition?: Maybe<ModelPatientCommentConditionInput>;
};


export type MutationDeletePatientTagArgs = {
  input: DeletePatientTagInput;
  condition?: Maybe<ModelPatientTagConditionInput>;
};


export type MutationDeletePatientTagAssociationArgs = {
  input: DeletePatientTagAssociationInput;
  condition?: Maybe<ModelPatientTagAssociationConditionInput>;
};


export type MutationDeletePaymentArgs = {
  input: DeletePaymentInput;
  condition?: Maybe<ModelPaymentConditionInput>;
};


export type MutationDeletePaymentStateArgs = {
  input: DeletePaymentStateInput;
  condition?: Maybe<ModelPaymentStateConditionInput>;
};


export type MutationDeletePaymentStateMachineArgs = {
  input: DeletePaymentStateMachineInput;
  condition?: Maybe<ModelPaymentStateMachineConditionInput>;
};


export type MutationDeletePharmacistArgs = {
  input: DeletePharmacistInput;
  condition?: Maybe<ModelPharmacistConditionInput>;
};


export type MutationDeletePharmacyArgs = {
  input: DeletePharmacyInput;
  condition?: Maybe<ModelPharmacyConditionInput>;
};


export type MutationDeleteRemindActionArgs = {
  input: DeleteRemindActionInput;
  condition?: Maybe<ModelRemindActionConditionInput>;
};


export type MutationDeleteRemindActionLogArgs = {
  input: DeleteRemindActionLogInput;
  condition?: Maybe<ModelRemindActionLogConditionInput>;
};


export type MutationDeleteRemindActionPlanArgs = {
  input: DeleteRemindActionPlanInput;
  condition?: Maybe<ModelRemindActionPlanConditionInput>;
};


export type MutationDeleteReviewArgs = {
  input: DeleteReviewInput;
  condition?: Maybe<ModelReviewConditionInput>;
};


export type MutationDeleteRoomArgs = {
  input: DeleteRoomInput;
  condition?: Maybe<ModelRoomConditionInput>;
};


export type MutationDeleteRoomVacancyArgs = {
  input: DeleteRoomVacancyInput;
  condition?: Maybe<ModelRoomVacancyConditionInput>;
};


export type MutationDeleteSoapInfoArgs = {
  input: DeleteSoapInfoInput;
  condition?: Maybe<ModelSoapInfoConditionInput>;
};


export type MutationDeleteSearchConditionArgs = {
  input: DeleteSearchConditionInput;
  condition?: Maybe<ModelSearchConditionConditionInput>;
};


export type MutationDeleteSentMessageGroupBySearchArgs = {
  input: DeleteSentMessageGroupBySearchInput;
};


export type MutationDeleteShiftArgs = {
  input: DeleteShiftInput;
  condition?: Maybe<ModelShiftConditionInput>;
};


export type MutationDeleteSlackNonceArgs = {
  input: DeleteSlackNonceInput;
  condition?: Maybe<ModelSlackNonceConditionInput>;
};


export type MutationDeleteStaffArgs = {
  input: DeleteStaffInput;
  condition?: Maybe<ModelStaffConditionInput>;
};


export type MutationDeleteStaffShiftArgs = {
  input: DeleteStaffShiftInput;
  condition?: Maybe<ModelStaffShiftConditionInput>;
};


export type MutationDeleteStaffVacancyArgs = {
  input: DeleteStaffVacancyInput;
  condition?: Maybe<ModelStaffVacancyConditionInput>;
};


export type MutationDeleteTemporallyTreatmentAdminArgs = {
  input: DeleteTemporallyTreatmentInput;
};


export type MutationDeleteTreatmentArgs = {
  input: DeleteTreatmentInput;
  condition?: Maybe<ModelTreatmentConditionInput>;
};


export type MutationDeleteTreatmentKindArgs = {
  input: DeleteTreatmentKindInput;
  condition?: Maybe<ModelTreatmentKindConditionInput>;
};


export type MutationDeleteTreatmentProgressArgs = {
  input: DeleteTreatmentProgressInput;
  condition?: Maybe<ModelTreatmentProgressConditionInput>;
};


export type MutationDeleteTreatmentProgressHistoryArgs = {
  input: DeleteTreatmentProgressHistoryInput;
  condition?: Maybe<ModelTreatmentProgressHistoryConditionInput>;
};


export type MutationDeleteTreatmentProgressNextStatusAssociationArgs = {
  input: DeleteTreatmentProgressNextStatusAssociationInput;
  condition?: Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>;
};


export type MutationDeleteTreatmentProgressStatusArgs = {
  input: DeleteTreatmentProgressStatusInput;
  condition?: Maybe<ModelTreatmentProgressStatusConditionInput>;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
  condition?: Maybe<ModelUserConditionInput>;
};


export type MutationDeleteUserReserveConditionArgs = {
  input: DeleteUserReserveConditionInput;
  condition?: Maybe<ModelUserReserveConditionConditionInput>;
};


export type MutationDeleteUserThreadArgs = {
  input: DeleteUserThreadInput;
  condition?: Maybe<ModelUserThreadConditionInput>;
};


export type MutationDeleteVacanciesArgs = {
  input: DeleteVacanciesInput;
  condition?: Maybe<ModelVacanciesConditionInput>;
};


export type MutationDeleteVacancyConfigSetArgs = {
  input: DeleteVacancyConfigSetInput;
  condition?: Maybe<ModelVacancyConfigSetConditionInput>;
};


export type MutationDeleteVacancyLaneArgs = {
  input: DeleteVacancyLaneInput;
  condition?: Maybe<ModelVacancyLaneConditionInput>;
};


export type MutationDeleteVacancySpacerArgs = {
  input: DeleteVacancySpacerInput;
  condition?: Maybe<ModelVacancySpacerConditionInput>;
};


export type MutationDeleteVacancySpacerTemplateArgs = {
  input: DeleteVacancySpacerTemplateInput;
  condition?: Maybe<ModelVacancySpacerTemplateConditionInput>;
};


export type MutationDeleteVacancyTemplateArgs = {
  input: DeleteVacancyTemplateInput;
  condition?: Maybe<ModelVacancyTemplateConditionInput>;
};


export type MutationDeleteVacancyUnitArgs = {
  input: DeleteVacancyUnitInput;
  condition?: Maybe<ModelVacancyUnitConditionInput>;
};


export type MutationDeleteVaccineEventArgs = {
  input: DeleteVaccineEventInput;
  condition?: Maybe<ModelVaccineEventConditionInput>;
};


export type MutationDeleteVaccinePatientArgs = {
  input: DeleteVaccinePatientInput;
  condition?: Maybe<ModelVaccinePatientConditionInput>;
};


export type MutationDeleteWaitingListItemArgs = {
  input: DeleteWaitingListItemInput;
  condition?: Maybe<ModelWaitingListItemConditionInput>;
};


export type MutationDeleteZendeskMessageArgs = {
  input: DeleteZendeskMessageInput;
  condition?: Maybe<ModelZendeskMessageConditionInput>;
};


export type MutationDisableNonceArgs = {
  input: DisableNonceInput;
};


export type MutationFixCheckupAppointmentOrganizationArgs = {
  input: CheckupAppointmentOrganizationFixInput;
};


export type MutationFixTreatmentArgs = {
  input?: Maybe<FixTreatmentInput>;
};


export type MutationFixTreatmentCheckupIndividualArgs = {
  input: FixTreatmentInput;
};


export type MutationIssueUserNonceArgs = {
  input: IssueUserNonceInput;
};


export type MutationKickCheckupResultMappingJobArgs = {
  input: CheckupResultMappingJobInput;
};


export type MutationKickSlackUserConnectionArgs = {
  input: KickSlackUserConnectionInput;
};


export type MutationNotifyAsyncJobStatusArgs = {
  input: NotifyAsyncJobStatusInput;
};


export type MutationNotifyChatUserArgs = {
  input: NotifyChatUserInput;
};


export type MutationNotifyPrescriptionUpdateArgs = {
  input?: Maybe<EmptyInput>;
};


export type MutationNotifySlackUserConnectionFinishedArgs = {
  input: AsyncProcessResultInput;
};


export type MutationNotifyTreatmentUpdateArgs = {
  input: NotifyTreatmentUpdateInput;
};


export type MutationReAnswerInterviewArgs = {
  input?: Maybe<ReAnswerInterviewInput>;
};


export type MutationReJudgeCheckupResultArgs = {
  input: UpdateCheckupResultSetInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRegisterUserAdminArgs = {
  input: RegisterUserInput;
};


export type MutationReserveCheckupArgs = {
  input: ReserveCheckupInput;
};


export type MutationReserveCheckupAppointmentIndividualArgs = {
  input: ReserveCheckupAppointmentInput;
};


export type MutationReserveCheckupAppointmentIndividualTemporarilyArgs = {
  input: ReserveCheckupAppointmentInput;
};


export type MutationReserveCheckupAppointmentOrganizationArgs = {
  input: ReserveCheckupAppointmentInput;
};


export type MutationReserveCheckupAppointmentOrganizationTemporarilyArgs = {
  input: CheckupAppointmentOrganizationTemporallyInput;
};


export type MutationResetCheckupAdminPasswordArgs = {
  input: ResetCheckupAdminPasswordInput;
};


export type MutationSendCheckupInvitationArgs = {
  input: CheckupInvitationInput;
};


export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};


export type MutationSendFreeChatArgs = {
  input: AbstractChatInput;
};


export type MutationSendMessageBySearchArgs = {
  input: SendMessageBySearchInput;
};


export type MutationSendPharmacyCallArgs = {
  input: SendPharmacyCallInput;
};


export type MutationSendSqsOfCheckupResultSummaryArgs = {
  input: SendSqsOfCheckupResultSummaryInput;
};


export type MutationSendVaccineInvitationsArgs = {
  input: SendVaccineInvitationInput;
};


export type MutationSetDosageFeedbackArgs = {
  input: CreateDosageFeedbackInput;
};


export type MutationStartInterviewArgs = {
  input?: Maybe<StartInterviewInput>;
};


export type MutationSubmitCheckupInterviewArgs = {
  input: SubmitCheckupInterviewInput;
};


export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
  condition?: Maybe<ModelAttachmentConditionInput>;
};


export type MutationUpdateAutoNumberArgs = {
  input: UpdateAutoNumberInput;
  condition?: Maybe<ModelAutoNumberConditionInput>;
};


export type MutationUpdateBlockArgs = {
  input: UpdateBlockInput;
  condition?: Maybe<ModelBlockConditionInput>;
};


export type MutationUpdateBlockWithValidationArgs = {
  input?: Maybe<UpdateBlockInput>;
};


export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
  condition?: Maybe<ModelCardConditionInput>;
};


export type MutationUpdateCheckupAppointmentArgs = {
  input: UpdateCheckupAppointmentInput;
  condition?: Maybe<ModelCheckupAppointmentConditionInput>;
};


export type MutationUpdateCheckupAppointmentIndividualArgs = {
  input: UpdateCheckupInput;
};


export type MutationUpdateCheckupAppointmentKitDeliverStatusArgs = {
  input: UpdateCheckupAppointmentKitDeliverStatusInput;
};


export type MutationUpdateCheckupAppointmentOrganizationArgs = {
  input: UpdateCheckupInput;
};


export type MutationUpdateCheckupInterviewQuestionArgs = {
  input: UpdateCheckupInterviewQuestionInput;
  condition?: Maybe<ModelCheckupInterviewQuestionConditionInput>;
};


export type MutationUpdateCheckupOptionArgs = {
  input: UpdateCheckupOptionInput;
  condition?: Maybe<ModelCheckupOptionConditionInput>;
};


export type MutationUpdateCheckupOptionIndividualArgs = {
  input: UpdateCheckupOptionIndividualInput;
  condition?: Maybe<ModelCheckupOptionIndividualConditionInput>;
};


export type MutationUpdateCheckupOrganizationArgs = {
  input: UpdateCheckupOrganizationInput;
  condition?: Maybe<ModelCheckupOrganizationConditionInput>;
};


export type MutationUpdateCheckupOrganizationChatWorkspaceArgs = {
  input: UpdateCheckupOrganizationChatWorkspaceInput;
  condition?: Maybe<ModelCheckupOrganizationChatWorkspaceConditionInput>;
};


export type MutationUpdateCheckupOrganizationEventArgs = {
  input: UpdateCheckupOrganizationEventInput;
  condition?: Maybe<ModelCheckupOrganizationEventConditionInput>;
};


export type MutationUpdateCheckupOrganizationEventOptionArgs = {
  input: UpdateCheckupOrganizationEventOptionInput;
  condition?: Maybe<ModelCheckupOrganizationEventOptionConditionInput>;
};


export type MutationUpdateCheckupOrganizationEventTemplateArgs = {
  input: UpdateCheckupOrganizationEventTemplateInput;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateConditionInput>;
};


export type MutationUpdateCheckupOrganizationEventTemplateV2Args = {
  input: UpdateCheckupOrganizationEventTemplateV2Input;
  condition?: Maybe<ModelCheckupOrganizationEventTemplateV2ConditionInput>;
};


export type MutationUpdateCheckupOrganizationMemberEventArgs = {
  input: UpdateCheckupOrganizationMemberEventInput;
  condition?: Maybe<ModelCheckupOrganizationMemberEventConditionInput>;
};


export type MutationUpdateCheckupPlanArgs = {
  input: UpdateCheckupPlanInput;
  condition?: Maybe<ModelCheckupPlanConditionInput>;
};


export type MutationUpdateCheckupPlanIndividualArgs = {
  input: UpdateCheckupPlanIndividualInput;
  condition?: Maybe<ModelCheckupPlanIndividualConditionInput>;
};


export type MutationUpdateCheckupReservationArgs = {
  input: UpdateCheckupReservationInput;
};


export type MutationUpdateCheckupResultArgs = {
  input: UpdateCheckupResultInput;
  condition?: Maybe<ModelCheckupResultConditionInput>;
};


export type MutationUpdateCheckupResultItemArgs = {
  input: UpdateCheckupResultItemInput;
  condition?: Maybe<ModelCheckupResultItemConditionInput>;
};


export type MutationUpdateCheckupResultItemGroupArgs = {
  input: UpdateCheckupResultItemGroupInput;
  condition?: Maybe<ModelCheckupResultItemGroupConditionInput>;
};


export type MutationUpdateCheckupResultItemSubGroupArgs = {
  input: UpdateCheckupResultItemSubGroupInput;
  condition?: Maybe<ModelCheckupResultItemSubGroupConditionInput>;
};


export type MutationUpdateCheckupResultSetArgs = {
  input: UpdateCheckupResultSetInput;
  condition?: Maybe<ModelCheckupResultSetConditionInput>;
};


export type MutationUpdateCheckupUnionArgs = {
  input: UpdateCheckupUnionInput;
  condition?: Maybe<ModelCheckupUnionConditionInput>;
};


export type MutationUpdateClinicArgs = {
  input: UpdateClinicInput;
  condition?: Maybe<ModelClinicConditionInput>;
};


export type MutationUpdateClinicMemoArgs = {
  input: UpdateClinicMemoInput;
  condition?: Maybe<ModelClinicMemoConditionInput>;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
  condition?: Maybe<ModelCustomerConditionInput>;
};


export type MutationUpdateDentalEvaluationArgs = {
  input: UpdateDentalEvaluationInput;
  condition?: Maybe<ModelDentalEvaluationConditionInput>;
};


export type MutationUpdateDentalEvaluationItemsArgs = {
  input: UpdateDentalEvaluationItemsInput;
  condition?: Maybe<ModelDentalEvaluationItemsConditionInput>;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
  condition?: Maybe<ModelDepartmentConditionInput>;
};


export type MutationUpdateDiscountCodeArgs = {
  input: UpdateDiscountCodeInput;
  condition?: Maybe<ModelDiscountCodeConditionInput>;
};


export type MutationUpdateDoctorArgs = {
  input: UpdateDoctorInput;
  condition?: Maybe<ModelDoctorConditionInput>;
};


export type MutationUpdateDosageArgs = {
  input: UpdateDosageInput;
  condition?: Maybe<ModelDosageConditionInput>;
};


export type MutationUpdateDosagePrescriptionArgs = {
  input: UpdateDosagePrescriptionInput;
  condition?: Maybe<ModelDosagePrescriptionConditionInput>;
};


export type MutationUpdateDosageStatusArgs = {
  input: UpdateDosageStatusInput;
};


export type MutationUpdateDosageVisitingDatetimeArgs = {
  input: UpdateDosageVisitingDatetimeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
  condition?: Maybe<ModelEmployeeConditionInput>;
};


export type MutationUpdateEventInstructionArgs = {
  input: UpdateEventInstructionInput;
  condition?: Maybe<ModelEventInstructionConditionInput>;
};


export type MutationUpdateEventMembersArgs = {
  input: UpdateEventMembersInput;
};


export type MutationUpdateExtendedDictionaryArgs = {
  input: UpdateExtendedDictionaryInput;
  condition?: Maybe<ModelExtendedDictionaryConditionInput>;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
  condition?: Maybe<ModelFeedbackConditionInput>;
};


export type MutationUpdateFollowupArgs = {
  input: UpdateFollowupInput;
  condition?: Maybe<ModelFollowupConditionInput>;
};


export type MutationUpdateImportJobArgs = {
  input: UpdateImportJobInput;
  condition?: Maybe<ModelImportJobConditionInput>;
};


export type MutationUpdateInstructionArgs = {
  input: UpdateInstructionInput;
  condition?: Maybe<ModelInstructionConditionInput>;
};


export type MutationUpdateInterviewArgs = {
  input: UpdateInterviewInput;
};


export type MutationUpdateLineBotMessageArgs = {
  input: UpdateLineBotMessageInput;
  condition?: Maybe<ModelLineBotMessageConditionInput>;
};


export type MutationUpdateLineMessageArgs = {
  input: UpdateLineMessageInput;
  condition?: Maybe<ModelLineMessageConditionInput>;
};


export type MutationUpdateLineMessageOpenedByUserAtArgs = {
  input: UpdateLineMessageOpenedByUserAtInput;
};


export type MutationUpdateLineMessageTemplateArgs = {
  input: UpdateLineMessageTemplateInput;
  condition?: Maybe<ModelLineMessageTemplateConditionInput>;
};


export type MutationUpdateLineReadStatusForAlreadyReadArgs = {
  input: UpdateLineReadStatusForAlreadyReadInput;
};


export type MutationUpdateLineReadStatusForReceiveArgs = {
  input: UpdateLineReadStatusForReceiveInput;
};


export type MutationUpdateLineUserArgs = {
  input: UpdateLineUserInput;
  condition?: Maybe<ModelLineUserConditionInput>;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
  condition?: Maybe<ModelLocationConditionInput>;
};


export type MutationUpdateLocationGroupArgs = {
  input: UpdateLocationGroupInput;
  condition?: Maybe<ModelLocationGroupConditionInput>;
};


export type MutationUpdateMedicalQuestionnaireInterviewArgs = {
  input: UpdateMedicalQuestionnaireInterviewInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewConditionInput>;
};


export type MutationUpdateMedicalQuestionnaireInterviewAnswersArgs = {
  input: UpdateMedicalQuestionnaireInterviewAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireInterviewAnswersConditionInput>;
};


export type MutationUpdateMedicalQuestionnaireInterviewListArgs = {
  input: UpdateMedicalQuestionnaireInterviewListInput;
};


export type MutationUpdateMedicalQuestionnaireSurveyArgs = {
  input: UpdateMedicalQuestionnaireSurveyInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyConditionInput>;
};


export type MutationUpdateMedicalQuestionnaireSurveyAnswersArgs = {
  input: UpdateMedicalQuestionnaireSurveyAnswersInput;
  condition?: Maybe<ModelMedicalQuestionnaireSurveyAnswersConditionInput>;
};


export type MutationUpdateNonceArgs = {
  input: UpdateNonceInput;
  condition?: Maybe<ModelNonceConditionInput>;
};


export type MutationUpdateOpenMonthArgs = {
  input: UpdateOpenMonthInput;
  condition?: Maybe<ModelOpenMonthConditionInput>;
};


export type MutationUpdateOrcaPatientArgs = {
  input: UpdateOrcaPatientInput;
  condition?: Maybe<ModelOrcaPatientConditionInput>;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
  condition?: Maybe<ModelOrganizationConditionInput>;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
  condition?: Maybe<ModelPatientConditionInput>;
};


export type MutationUpdatePatientCommentArgs = {
  input: UpdatePatientCommentInput;
  condition?: Maybe<ModelPatientCommentConditionInput>;
};


export type MutationUpdatePatientTagArgs = {
  input: UpdatePatientTagInput;
  condition?: Maybe<ModelPatientTagConditionInput>;
};


export type MutationUpdatePatientTagAssociationArgs = {
  input: UpdatePatientTagAssociationInput;
  condition?: Maybe<ModelPatientTagAssociationConditionInput>;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
  condition?: Maybe<ModelPaymentConditionInput>;
};


export type MutationUpdatePaymentStateArgs = {
  input: UpdatePaymentStateInput;
  condition?: Maybe<ModelPaymentStateConditionInput>;
};


export type MutationUpdatePaymentStateMachineArgs = {
  input: UpdatePaymentStateMachineInput;
  condition?: Maybe<ModelPaymentStateMachineConditionInput>;
};


export type MutationUpdatePharmacistArgs = {
  input: UpdatePharmacistInput;
  condition?: Maybe<ModelPharmacistConditionInput>;
};


export type MutationUpdatePharmacyArgs = {
  input: UpdatePharmacyInput;
  condition?: Maybe<ModelPharmacyConditionInput>;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateRemindActionArgs = {
  input: UpdateRemindActionInput;
  condition?: Maybe<ModelRemindActionConditionInput>;
};


export type MutationUpdateRemindActionLogArgs = {
  input: UpdateRemindActionLogInput;
  condition?: Maybe<ModelRemindActionLogConditionInput>;
};


export type MutationUpdateRemindActionPlanArgs = {
  input: UpdateRemindActionPlanInput;
  condition?: Maybe<ModelRemindActionPlanConditionInput>;
};


export type MutationUpdateRemindActionStatusArgs = {
  input: UpdateRemindActionStatusInput;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
  condition?: Maybe<ModelReviewConditionInput>;
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
  condition?: Maybe<ModelRoomConditionInput>;
};


export type MutationUpdateRoomVacancyArgs = {
  input: UpdateRoomVacancyInput;
  condition?: Maybe<ModelRoomVacancyConditionInput>;
};


export type MutationUpdateSoapInfoArgs = {
  input: UpdateSoapInfoInput;
  condition?: Maybe<ModelSoapInfoConditionInput>;
};


export type MutationUpdateSearchConditionArgs = {
  input: UpdateSearchConditionInput;
  condition?: Maybe<ModelSearchConditionConditionInput>;
};


export type MutationUpdateSentMessageGroupBySearchArgs = {
  input: UpdateSentMessageGroupBySearchInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
  condition?: Maybe<ModelShiftConditionInput>;
};


export type MutationUpdateShiftManualArgs = {
  input: UpdateShiftManualInput;
};


export type MutationUpdateSlackNonceArgs = {
  input: UpdateSlackNonceInput;
  condition?: Maybe<ModelSlackNonceConditionInput>;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
  condition?: Maybe<ModelStaffConditionInput>;
};


export type MutationUpdateStaffShiftArgs = {
  input: UpdateStaffShiftInput;
  condition?: Maybe<ModelStaffShiftConditionInput>;
};


export type MutationUpdateStaffVacancyArgs = {
  input: UpdateStaffVacancyInput;
  condition?: Maybe<ModelStaffVacancyConditionInput>;
};


export type MutationUpdateTreatmentArgs = {
  input: CreateTreatmentInput;
  condition?: Maybe<ModelTreatmentConditionInput>;
};


export type MutationUpdateTreatmentAdminArgs = {
  input: CreateTreatmentInput;
  id: Scalars['String'];
};


export type MutationUpdateTreatmentChecklistArgs = {
  input: UpdateTreatmentChecklistInput;
};


export type MutationUpdateTreatmentCommentArgs = {
  input: UpdateTreatmentCommentInput;
};


export type MutationUpdateTreatmentEditConditionArgs = {
  input: UpdateTreatmentEditConditionInput;
};


export type MutationUpdateTreatmentFieldsArgs = {
  input: UpdateTreatmentFieldsInput;
};


export type MutationUpdateTreatmentKindArgs = {
  input: UpdateTreatmentKindInput;
  condition?: Maybe<ModelTreatmentKindConditionInput>;
};


export type MutationUpdateTreatmentLaneArgs = {
  input: UpdateTreatmentLaneInput;
};


export type MutationUpdateTreatmentLateContactArgs = {
  input: UpdateTreatmentLateContactFlagInput;
};


export type MutationUpdateTreatmentNoChargeArgs = {
  input: UpdateTreatmentNoChargeInput;
};


export type MutationUpdateTreatmentPharmacyArgs = {
  input: UpdateTreatmentPharmacyInput;
};


export type MutationUpdateTreatmentProgressArgs = {
  input: UpdateTreatmentProgressInput;
  condition?: Maybe<ModelTreatmentProgressConditionInput>;
};


export type MutationUpdateTreatmentProgressHistoryArgs = {
  input: UpdateTreatmentProgressHistoryInput;
  condition?: Maybe<ModelTreatmentProgressHistoryConditionInput>;
};


export type MutationUpdateTreatmentProgressNextStatusAssociationArgs = {
  input: UpdateTreatmentProgressNextStatusAssociationInput;
  condition?: Maybe<ModelTreatmentProgressNextStatusAssociationConditionInput>;
};


export type MutationUpdateTreatmentProgressStatusArgs = {
  input: UpdateTreatmentProgressStatusInput;
  condition?: Maybe<ModelTreatmentProgressStatusConditionInput>;
};


export type MutationUpdateTreatmentSendRemindArgs = {
  input: UpdateTreatmentSendRemindInput;
};


export type MutationUpdateTreatmentStatusArgs = {
  input: UpdateTreatmentStatusInput;
};


export type MutationUpdateTreatmentWithValidationArgs = {
  input: CreateTreatmentInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  condition?: Maybe<ModelUserConditionInput>;
};


export type MutationUpdateUserLineUserIdArgs = {
  input: UpdateUserLineUserIdInput;
};


export type MutationUpdateUserReserveConditionArgs = {
  input: UpdateUserReserveConditionInput;
  condition?: Maybe<ModelUserReserveConditionConditionInput>;
};


export type MutationUpdateUserThreadArgs = {
  input: UpdateUserThreadInput;
  condition?: Maybe<ModelUserThreadConditionInput>;
};


export type MutationUpdateUserThreadMessageIdsArgs = {
  input: UpdateUserThreadMessageIdsInput;
};


export type MutationUpdateUserThreadStatusAndNameArgs = {
  input: UpdateUserThreadStatusAndNameInput;
};


export type MutationUpdateVacanciesArgs = {
  input: UpdateVacanciesInput;
  condition?: Maybe<ModelVacanciesConditionInput>;
};


export type MutationUpdateVacancyArgs = {
  input: CreateVacanciesInput;
};


export type MutationUpdateVacancyConfigSetArgs = {
  input: UpdateVacancyConfigSetInput;
  condition?: Maybe<ModelVacancyConfigSetConditionInput>;
};


export type MutationUpdateVacancyLaneArgs = {
  input: UpdateVacancyLaneInput;
  condition?: Maybe<ModelVacancyLaneConditionInput>;
};


export type MutationUpdateVacancySpacerArgs = {
  input: UpdateVacancySpacerInput;
  condition?: Maybe<ModelVacancySpacerConditionInput>;
};


export type MutationUpdateVacancySpacerTemplateArgs = {
  input: UpdateVacancySpacerTemplateInput;
  condition?: Maybe<ModelVacancySpacerTemplateConditionInput>;
};


export type MutationUpdateVacancyTemplateArgs = {
  input: UpdateVacancyTemplateInput;
  condition?: Maybe<ModelVacancyTemplateConditionInput>;
};


export type MutationUpdateVacancyUnitArgs = {
  input: UpdateVacancyUnitInput;
  condition?: Maybe<ModelVacancyUnitConditionInput>;
};


export type MutationUpdateVaccineEventArgs = {
  input: UpdateVaccineEventInput;
  condition?: Maybe<ModelVaccineEventConditionInput>;
};


export type MutationUpdateVaccinePatientArgs = {
  input: UpdateVaccinePatientInput;
  condition?: Maybe<ModelVaccinePatientConditionInput>;
};


export type MutationUpdateWaitingListItemArgs = {
  input: UpdateWaitingListItemInput;
  condition?: Maybe<ModelWaitingListItemConditionInput>;
};


export type MutationUpdateZendeskMessageArgs = {
  input: UpdateZendeskMessageInput;
  condition?: Maybe<ModelZendeskMessageConditionInput>;
};


export type MutationValidateCardArgs = {
  input: CardConfirmInfoInput;
};

export type NameItem = {
  __typename?: 'NameItem';
  name?: Maybe<Scalars['String']>;
};

export type NegaPosi = {
  __typename?: 'NegaPosi';
  positive?: Maybe<Array<Maybe<Scalars['String']>>>;
  negative?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NegaPosiInput = {
  positive?: Maybe<Array<Maybe<Scalars['String']>>>;
  negative?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * clinic-frontへ受け渡すための問診システムの型
 * exitがある場合（ =defaultExitがない場合）は条件分岐が発生します。
 * exitもdefaultExitもない場合は最後の設問になることを表します。
 * 
 * clinic-frontが旧システムのままのため必要な方です。clinic-front修正後は不要になります。
 */
export type NewInterviewSchema = {
  __typename?: 'NewInterviewSchema';
  domainIdentifier?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['Int']>;
  department?: Maybe<NameItem>;
  treatmentKind?: Maybe<NameItem>;
  choiceType?: Maybe<ChoiceType>;
  description?: Maybe<Scalars['String']>;
  interviewTime?: Maybe<Scalars['String']>;
  disable?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  medicalRecordTitle?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  order: Scalars['Int'];
  required: Scalars['Boolean'];
  answerChoices?: Maybe<Array<Maybe<NewInterviewSchemaAnswerChoice>>>;
  defaultExit?: Maybe<OrderItem>;
  isConditional?: Maybe<Scalars['Boolean']>;
};

export type NewInterviewSchemaAnswerChoice = {
  __typename?: 'NewInterviewSchemaAnswerChoice';
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  placeholder?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<AnswerType>;
  exit?: Maybe<OrderItem>;
};

export enum NoChargeReason {
  Free = 'FREE',
  ChargedOutsideSystem = 'CHARGED_OUTSIDE_SYSTEM',
  Other = 'OTHER'
}

export type Nonce = {
  __typename?: 'Nonce';
  nonce: Scalars['String'];
  userId: Scalars['String'];
  issueAt: Scalars['String'];
  expireAt: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type NonceInput = {
  nonce: Scalars['String'];
  organizationId: Scalars['String'];
};

export type NotifyAsyncJobStatusInput = {
  status: AsyncJobStatus;
  reason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  finished?: Maybe<Scalars['Boolean']>;
};

export type NotifyChatUser = {
  __typename?: 'NotifyChatUser';
  userId: Scalars['String'];
  messageType?: Maybe<Scalars['String']>;
};

export type NotifyChatUserInput = {
  userId: Scalars['String'];
  messageType: LineMessageSendType;
};

export type NotifyTreatmentUpdate = {
  __typename?: 'NotifyTreatmentUpdate';
  date?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type NotifyTreatmentUpdateInput = {
  date: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type OpenMonth = {
  __typename?: 'OpenMonth';
  yearMonth: Scalars['String'];
  open?: Maybe<Scalars['Boolean']>;
  clinic: Clinic;
};

/** 新空き枠用のoperation情報 */
export type Operation = {
  __typename?: 'Operation';
  role: Scalars['String'];
  staffDuration?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  roomType: Scalars['String'];
  enableStaff: Array<Staff>;
};

export type OperationInput = {
  role: Scalars['String'];
  staffDuration?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  roomType: Scalars['String'];
  enableStaff: Array<Scalars['String']>;
};

export type OrcaPatient = {
  __typename?: 'OrcaPatient';
  orcaID: Scalars['ID'];
  patient?: Maybe<Patient>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  order?: Maybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  employee: ModelEmployeeConnection;
  name: Scalars['String'];
  chatTool?: Maybe<ChatTool>;
  chatId?: Maybe<Scalars['String']>;
};


export type OrganizationEmployeeArgs = {
  filter?: Maybe<ModelEmployeeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['ID'];
  user?: Maybe<User>;
  patientNo: Scalars['String'];
  orcaID?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  seikana?: Maybe<Scalars['String']>;
  meikana?: Maybe<Scalars['String']>;
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  comments: ModelPatientCommentConnection;
  tags: ModelPatientTagAssociationConnection;
  /** 薬科の患者番号 */
  pharmacyPatientNo?: Maybe<Scalars['String']>;
  /** 申し送り事項 */
  comment?: Maybe<Scalars['String']>;
  /** 担当スタッフ */
  staffId?: Maybe<Scalars['String']>;
  staff?: Maybe<Staff>;
  /** 担当変更時の挙動 */
  anyoneStaff?: Maybe<Scalars['Boolean']>;
};


export type PatientCommentsArgs = {
  filter?: Maybe<ModelPatientCommentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type PatientTagsArgs = {
  filter?: Maybe<ModelPatientTagAssociationFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PatientComment = {
  __typename?: 'PatientComment';
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  comment: Scalars['String'];
  createdAt: Scalars['String'];
};

/** 担当スタッフ情報 */
export type PatientInCharge = {
  __typename?: 'PatientInCharge';
  staff: Staff;
  anyone: Scalars['Boolean'];
};

export type PatientInChargeInput = {
  staff: Scalars['String'];
  anyone: Scalars['Boolean'];
};

export type PatientSearchSetting = {
  __typename?: 'PatientSearchSetting';
  condition?: Maybe<SearchPatientCondition>;
  orders?: Maybe<Array<SearchPatientOrder>>;
  displayColumns: Array<Scalars['String']>;
};

export type PatientSearchSettingInput = {
  condition?: Maybe<SearchPatientConditionInput>;
  orders?: Maybe<Array<Maybe<SearchPatientOrderInput>>>;
  displayColumns: Array<Scalars['String']>;
};

export type PatientTag = {
  __typename?: 'PatientTag';
  tag: Scalars['ID'];
  displayName: Scalars['String'];
  color: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<Clinic>;
};

export type PatientTagAssociation = {
  __typename?: 'PatientTagAssociation';
  patient?: Maybe<Patient>;
  tag?: Maybe<PatientTag>;
  createdAt: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  date: Scalars['String'];
  time: Scalars['String'];
  department?: Maybe<Department>;
  user?: Maybe<User>;
  treatment?: Maybe<Treatment>;
  job: ModelPaymentStateMachineConnection;
};


export type PaymentJobArgs = {
  filter?: Maybe<ModelPaymentStateMachineFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaymentAttachmentInput = {
  name: Scalars['String'];
  treatmentId: Scalars['String'];
};

export enum PaymentJobType {
  Fixed = 'FIXED',
  Canceled = 'CANCELED'
}

export type PaymentState = {
  __typename?: 'PaymentState';
  executionId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentJobType>;
  state?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
  stateMachine?: Maybe<PaymentStateMachine>;
  createdAt?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type PaymentStateMachine = {
  __typename?: 'PaymentStateMachine';
  date: Scalars['String'];
  userId: Scalars['String'];
  department: Scalars['String'];
  executionId: Scalars['String'];
  time: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  state: ModelPaymentStateConnection;
};


export type PaymentStateMachineStateArgs = {
  filter?: Maybe<ModelPaymentStateFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

/** 薬剤師マスタ */
export type Pharmacist = {
  __typename?: 'Pharmacist';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Pharmacy = {
  __typename?: 'Pharmacy';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PrescriptionDownloadableLinkInput = {
  key: Scalars['String'];
};

export type ProcessResult = {
  __typename?: 'ProcessResult';
  success: Scalars['Boolean'];
};

export type PublicKey = {
  __typename?: 'PublicKey';
  publicKey: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  displayStaffShiftForDay: Array<Maybe<ShiftAdminForDay>>;
  displayStaffShiftForMonth: Array<Maybe<ShiftAdminForMonth>>;
  downloadAllReceipts?: Maybe<AttachmentUrl>;
  getAttachment?: Maybe<Attachment>;
  getAuditLogQueryResult?: Maybe<AuditLogQueryResults>;
  getAutoNumber?: Maybe<AutoNumber>;
  getBlock?: Maybe<Block>;
  getBulkReceiptDownload?: Maybe<ReceiptDownloadResult>;
  getCard?: Maybe<Card>;
  getChatPresignedPost?: Maybe<SignedPost>;
  getCheckupAppointment?: Maybe<CheckupAppointment>;
  getCheckupDownloadableLink?: Maybe<AttachmentUrl>;
  getCheckupInterview?: Maybe<Interview>;
  getCheckupInterviewQuestion?: Maybe<CheckupInterviewQuestion>;
  getCheckupOption?: Maybe<CheckupOption>;
  getCheckupOptionIndividual?: Maybe<CheckupOptionIndividual>;
  getCheckupOrganization?: Maybe<CheckupOrganization>;
  getCheckupOrganizationChatWorkspace?: Maybe<CheckupOrganizationChatWorkspace>;
  getCheckupOrganizationEvent?: Maybe<CheckupOrganizationEvent>;
  getCheckupOrganizationEventOption?: Maybe<CheckupOrganizationEventOption>;
  getCheckupOrganizationEventTemplate?: Maybe<CheckupOrganizationEventTemplate>;
  getCheckupOrganizationEventTemplateV2?: Maybe<CheckupOrganizationEventTemplateV2>;
  getCheckupOrganizationForLaborManager?: Maybe<CheckupOrganization>;
  getCheckupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  getCheckupPlan?: Maybe<CheckupPlan>;
  getCheckupPlanIndividual?: Maybe<CheckupPlanIndividual>;
  getCheckupPresignedPost?: Maybe<SignedPost>;
  getCheckupResult?: Maybe<CheckupResult>;
  getCheckupResultItem?: Maybe<CheckupResultItem>;
  getCheckupResultItemGroup?: Maybe<CheckupResultItemGroup>;
  getCheckupResultItemSubGroup?: Maybe<CheckupResultItemSubGroup>;
  getCheckupResultSet?: Maybe<CheckupResultSet>;
  getCheckupTemplateAttachmentSignedPost?: Maybe<SignedPost>;
  getCheckupTemplateBody?: Maybe<CheckupTemplateBody>;
  getCheckupUnion?: Maybe<CheckupUnion>;
  getClinic?: Maybe<Clinic>;
  getClinicMemo?: Maybe<ClinicMemo>;
  getCustomer?: Maybe<Customer>;
  getDentalEvaluation?: Maybe<DentalEvaluation>;
  getDentalEvaluationItems?: Maybe<DentalEvaluationItems>;
  getDepartment?: Maybe<Department>;
  getDiscountCheckupIndividualMasters?: Maybe<DiscountIndividualCheckupMasters>;
  getDiscountCode?: Maybe<DiscountCode>;
  getDoctor?: Maybe<Doctor>;
  getDosage?: Maybe<Dosage>;
  getDosagePrescription?: Maybe<DosagePrescription>;
  getDownloadableLink?: Maybe<AttachmentUrl>;
  getDownloadableLinkForCheckupTemplateAttachment?: Maybe<SignedPost>;
  getEmployee?: Maybe<Employee>;
  getEventInstruction?: Maybe<EventInstruction>;
  getExtendedDictionary?: Maybe<ExtendedDictionary>;
  getFeedback?: Maybe<Feedback>;
  getFeedbackByPatient?: Maybe<GetFeedbackResult>;
  getFollowup?: Maybe<Followup>;
  getImportJob?: Maybe<ImportJob>;
  getImportSignedPost?: Maybe<SignedPostForImport>;
  getInstruction?: Maybe<Instruction>;
  getInsuranceCardSignedPost?: Maybe<SignedPost>;
  getInterview?: Maybe<Interview>;
  getInterviewDownloadableUrl?: Maybe<SignedPost>;
  getInterviewPresignedPost?: Maybe<SignedPost>;
  getLineBotMessage?: Maybe<LineBotMessage>;
  getLineMessage?: Maybe<LineMessage>;
  getLineMessageTemplate?: Maybe<LineMessageTemplate>;
  getLineReadStatus?: Maybe<LineReadStatus>;
  getLineUser?: Maybe<LineUser>;
  getLocation?: Maybe<Location>;
  getLocationGroup?: Maybe<LocationGroup>;
  getMedicalInfoPresignedPost?: Maybe<SignedPost>;
  getMedicalQuestionnaireInterview?: Maybe<MedicalQuestionnaireInterview>;
  getMedicalQuestionnaireInterviewAnswers?: Maybe<MedicalQuestionnaireInterviewAnswers>;
  getMedicalQuestionnaireSurvey?: Maybe<MedicalQuestionnaireSurvey>;
  getMedicalQuestionnaireSurveyAnswers?: Maybe<MedicalQuestionnaireSurveyAnswers>;
  getMonshinResults?: Maybe<SoapSubject>;
  getNextTreatmentPlan?: Maybe<Array<TreatmentKind>>;
  getNonce?: Maybe<Nonce>;
  getOpenMonth?: Maybe<OpenMonth>;
  getOrcaPatient?: Maybe<OrcaPatient>;
  getOrganization?: Maybe<Organization>;
  getPatient?: Maybe<Patient>;
  getPatientComment?: Maybe<PatientComment>;
  getPatientTag?: Maybe<PatientTag>;
  getPatientTagAssociation?: Maybe<PatientTagAssociation>;
  getPayjpPublicKey?: Maybe<PublicKey>;
  getPayment?: Maybe<Payment>;
  getPaymentState?: Maybe<PaymentState>;
  getPaymentStateMachine?: Maybe<PaymentStateMachine>;
  getPharmacist?: Maybe<Pharmacist>;
  getPharmacy?: Maybe<Pharmacy>;
  getPrescriptionDownloadableLink?: Maybe<SignedPost>;
  getPrescriptionPresignedPost?: Maybe<SignedPost>;
  getRemindAction?: Maybe<RemindAction>;
  getRemindActionLog?: Maybe<RemindActionLog>;
  getRemindActionPlan?: Maybe<RemindActionPlan>;
  getResultSignedUrl?: Maybe<SignedPost>;
  getReview?: Maybe<Review>;
  getRoom?: Maybe<Room>;
  getRoomVacancy?: Maybe<RoomVacancy>;
  getSOAPInfo?: Maybe<SoapInfo>;
  getSearchCondition?: Maybe<SearchCondition>;
  getSentMessageGroupBySearch?: Maybe<SentMessageGroupBySearch>;
  getShift?: Maybe<Shift>;
  getSignedPost?: Maybe<SignedPost>;
  getSlackNonce?: Maybe<SlackNonce>;
  getStaff?: Maybe<Staff>;
  getStaffShift?: Maybe<StaffShift>;
  getStaffVacancy?: Maybe<StaffVacancy>;
  getSubjectText?: Maybe<SubjectText>;
  getTemporallyTreatment?: Maybe<Treatment>;
  getTreatment?: Maybe<Treatment>;
  getTreatmentKind?: Maybe<TreatmentKind>;
  getTreatmentKindOperations?: Maybe<TreatmentKind>;
  getTreatmentProgress?: Maybe<TreatmentProgress>;
  getTreatmentProgressHistory?: Maybe<TreatmentProgressHistory>;
  getTreatmentProgressNextStatusAssociation?: Maybe<TreatmentProgressNextStatusAssociation>;
  getTreatmentProgressStatus?: Maybe<TreatmentProgressStatus>;
  getUser?: Maybe<User>;
  getUserReserveCondition?: Maybe<UserReserveCondition>;
  getUserThread?: Maybe<UserThread>;
  getVacancies?: Maybe<Vacancies>;
  getVacancyConfigSet?: Maybe<VacancyConfigSet>;
  getVacancyLane?: Maybe<VacancyLane>;
  getVacancySpacer?: Maybe<VacancySpacer>;
  getVacancySpacerTemplate?: Maybe<VacancySpacerTemplate>;
  getVacancyTemplate?: Maybe<VacancyTemplate>;
  getVacancyUnit?: Maybe<VacancyUnit>;
  getVaccineEvent?: Maybe<VaccineEvent>;
  getVaccinePatient?: Maybe<VaccinePatient>;
  getWaitingListItem?: Maybe<WaitingListItem>;
  getZendeskMessage?: Maybe<ZendeskMessage>;
  listAttachments?: Maybe<ModelAttachmentConnection>;
  listAutoNumbers?: Maybe<ModelAutoNumberConnection>;
  listBlocks?: Maybe<ModelBlockConnection>;
  listCards?: Maybe<ModelCardConnection>;
  listCheckupAppointments?: Maybe<ModelCheckupAppointmentConnection>;
  listCheckupAppointmentsByKitDeliverStatus?: Maybe<ModelCheckupAppointmentConnection>;
  listCheckupInterviewQuestions?: Maybe<ModelCheckupInterviewQuestionConnection>;
  listCheckupOptionIndividuals?: Maybe<ModelCheckupOptionIndividualConnection>;
  listCheckupOptionSuggestion?: Maybe<Array<Maybe<CheckupOptionSuggestion>>>;
  listCheckupOptions?: Maybe<ModelCheckupOptionConnection>;
  listCheckupOrganizationChatWorkspaces?: Maybe<ModelCheckupOrganizationChatWorkspaceConnection>;
  listCheckupOrganizationEventOptions?: Maybe<ModelCheckupOrganizationEventOptionConnection>;
  listCheckupOrganizationEventTemplateV2s?: Maybe<ModelCheckupOrganizationEventTemplateV2Connection>;
  listCheckupOrganizationEventTemplates?: Maybe<ModelCheckupOrganizationEventTemplateConnection>;
  listCheckupOrganizationEvents?: Maybe<ModelCheckupOrganizationEventConnection>;
  listCheckupOrganizationMemberEventByRemindDate?: Maybe<ModelCheckupOrganizationMemberEventConnection>;
  listCheckupOrganizationMemberEvents?: Maybe<ModelCheckupOrganizationMemberEventConnection>;
  listCheckupOrganizations?: Maybe<ModelCheckupOrganizationConnection>;
  listCheckupPlanIndividuals?: Maybe<ModelCheckupPlanIndividualConnection>;
  listCheckupPlans?: Maybe<ModelCheckupPlanConnection>;
  listCheckupResultByStatus?: Maybe<ModelCheckupResultConnection>;
  listCheckupResultItemGroups?: Maybe<ModelCheckupResultItemGroupConnection>;
  listCheckupResultItemSubGroups?: Maybe<ModelCheckupResultItemSubGroupConnection>;
  listCheckupResultItems?: Maybe<ModelCheckupResultItemConnection>;
  listCheckupResultSetByStatusDate?: Maybe<ModelCheckupResultSetConnection>;
  listCheckupResultSets?: Maybe<ModelCheckupResultSetConnection>;
  listCheckupResults?: Maybe<ModelCheckupResultConnection>;
  listCheckupUnions?: Maybe<ModelCheckupUnionConnection>;
  listCheckupVacancies: Array<CheckupVacancy>;
  listClinicMemos?: Maybe<ModelClinicMemoConnection>;
  listClinics?: Maybe<ModelClinicConnection>;
  listCustomers?: Maybe<ModelCustomerConnection>;
  listDentalEvaluationItemss?: Maybe<ModelDentalEvaluationItemsConnection>;
  listDentalEvaluations?: Maybe<ModelDentalEvaluationConnection>;
  listDepartments?: Maybe<ModelDepartmentConnection>;
  listDiscountCodes?: Maybe<ModelDiscountCodeConnection>;
  listDoctors?: Maybe<ModelDoctorConnection>;
  listDosagePrescriptions?: Maybe<ModelDosagePrescriptionConnection>;
  listDosages?: Maybe<ModelDosageConnection>;
  listEmployees?: Maybe<ModelEmployeeConnection>;
  listEventInstructions?: Maybe<ModelEventInstructionConnection>;
  listExtendedDictionarys?: Maybe<ModelExtendedDictionaryConnection>;
  listFeedbacks?: Maybe<ModelFeedbackConnection>;
  listFollowups?: Maybe<ModelFollowupConnection>;
  listImportJobs?: Maybe<ModelImportJobConnection>;
  listInstructions?: Maybe<ModelInstructionConnection>;
  listLineBotMessages?: Maybe<ModelLineBotMessageConnection>;
  listLineMessageIdAndDate?: Maybe<ModelLineMessageConnection>;
  listLineMessageTemplates?: Maybe<ModelLineMessageTemplateConnection>;
  listLineMessageTemplatesByClinic?: Maybe<ModelLineMessageTemplateConnection>;
  listLineMessages?: Maybe<ModelLineMessageConnection>;
  listLineReadStatusByChannel?: Maybe<ModelLineReadStatusConnection>;
  listLineReadStatuss?: Maybe<ModelLineReadStatusConnection>;
  listLineUsers?: Maybe<ModelLineUserConnection>;
  listLocationGroups?: Maybe<ModelLocationGroupConnection>;
  listLocations?: Maybe<ModelLocationConnection>;
  listLumeccaConsecutiveVacancies: Array<ComputedVacancy>;
  listMedicalQuestionnaireInterviewAnswerss?: Maybe<ModelMedicalQuestionnaireInterviewAnswersConnection>;
  listMedicalQuestionnaireInterviewByListType: Array<Maybe<MedicalQuestionnaireInterview>>;
  listMedicalQuestionnaireInterviews?: Maybe<ModelMedicalQuestionnaireInterviewConnection>;
  listMedicalQuestionnaireInterviewsByInterviewTime?: Maybe<ModelMedicalQuestionnaireInterviewConnection>;
  listMedicalQuestionnaireSurveyAnswerss?: Maybe<ModelMedicalQuestionnaireSurveyAnswersConnection>;
  listMedicalQuestionnaireSurveys?: Maybe<ModelMedicalQuestionnaireSurveyConnection>;
  listMedicalQuestionnaireSurveysBySurveyTime?: Maybe<ModelMedicalQuestionnaireSurveyConnection>;
  listNonces?: Maybe<ModelNonceConnection>;
  listOpenMonths?: Maybe<ModelOpenMonthConnection>;
  listOrcaPatients?: Maybe<ModelOrcaPatientConnection>;
  listOrganizations?: Maybe<ModelOrganizationConnection>;
  listPatientComments?: Maybe<ModelPatientCommentConnection>;
  listPatientTagAssociations?: Maybe<ModelPatientTagAssociationConnection>;
  listPatientTags?: Maybe<ModelPatientTagConnection>;
  listPatients?: Maybe<ModelPatientConnection>;
  listPaymentStateMachineSorByUser?: Maybe<ModelPaymentStateMachineConnection>;
  listPaymentStateMachines?: Maybe<ModelPaymentStateMachineConnection>;
  listPaymentStates?: Maybe<ModelPaymentStateConnection>;
  listPayments?: Maybe<ModelPaymentConnection>;
  listPharmacists?: Maybe<ModelPharmacistConnection>;
  listPharmacys?: Maybe<ModelPharmacyConnection>;
  listRemindActionLogs?: Maybe<ModelRemindActionLogConnection>;
  listRemindActionPlans?: Maybe<ModelRemindActionPlanConnection>;
  listRemindActionSortedByDate?: Maybe<ModelRemindActionConnection>;
  listRemindActions?: Maybe<ModelRemindActionConnection>;
  listReviews?: Maybe<ModelReviewConnection>;
  listRoomVacancies?: Maybe<ModelRoomVacancyConnection>;
  /** @deprecated フレームワークが勝手にリゾルバを生成してしまうためスタブで用意している。 */
  listRoomVacancys?: Maybe<ModelRoomVacancyConnection>;
  listRooms?: Maybe<ModelRoomConnection>;
  listSOAPInfos?: Maybe<ModelSoapInfoConnection>;
  listSearchConditions?: Maybe<ModelSearchConditionConnection>;
  listSentMessageGroupBySearchs?: Maybe<ModelSentMessageGroupBySearchConnection>;
  listShifts?: Maybe<ModelShiftConnection>;
  listSlackNonces?: Maybe<ModelSlackNonceConnection>;
  listStaffShifts?: Maybe<ModelStaffShiftConnection>;
  listStaffShiftsForMonth?: Maybe<ModelStaffShiftConnection>;
  listStaffVacancies?: Maybe<ModelStaffVacancyConnection>;
  /** @deprecated フレームワークが勝手にリゾルバを生成してしまうためスタブで用意している。 */
  listStaffVacancys?: Maybe<ModelStaffVacancyConnection>;
  listStaffs?: Maybe<ModelStaffConnection>;
  listStaffsByEmail?: Maybe<ModelStaffConnection>;
  listTreatmentKinds?: Maybe<ModelTreatmentKindConnection>;
  listTreatmentProgressHistorys?: Maybe<ModelTreatmentProgressHistoryConnection>;
  listTreatmentProgressNextStatusAssociations?: Maybe<ModelTreatmentProgressNextStatusAssociationConnection>;
  listTreatmentProgressStatuss?: Maybe<ModelTreatmentProgressStatusConnection>;
  listTreatmentProgresss?: Maybe<ModelTreatmentProgressConnection>;
  listTreatmentSortedByDate?: Maybe<ModelTreatmentConnection>;
  listTreatmentSortedByStatus?: Maybe<ModelTreatmentConnection>;
  listTreatmentSortedByUser?: Maybe<ModelTreatmentConnection>;
  listTreatments?: Maybe<ModelTreatmentConnection>;
  listUserReserveConditionByRemindOn?: Maybe<ModelUserReserveConditionConnection>;
  listUserReserveConditions?: Maybe<ModelUserReserveConditionConnection>;
  listUserThreads?: Maybe<ModelUserThreadConnection>;
  listUserThreadsByDate: ModelUserThreadConnection;
  listUsers?: Maybe<ModelUserConnection>;
  listVacancies: Array<ComputedVacancy>;
  listVacanciess?: Maybe<ModelVacanciesConnection>;
  listVacancyConfigSets?: Maybe<ModelVacancyConfigSetConnection>;
  listVacancyLanes?: Maybe<ModelVacancyLaneConnection>;
  listVacancySpacerTemplates?: Maybe<ModelVacancySpacerTemplateConnection>;
  listVacancySpacers?: Maybe<ModelVacancySpacerConnection>;
  listVacancySpacersWeeks?: Maybe<ModelVacancySpacerConnection>;
  listVacancyTemplates?: Maybe<ModelVacancyTemplateConnection>;
  listVacancyUnits?: Maybe<ModelVacancyUnitConnection>;
  listVaccineEvents?: Maybe<ModelVaccineEventConnection>;
  listVaccinePatients?: Maybe<ModelVaccinePatientConnection>;
  listVaccinePatientsBySequenceId?: Maybe<ModelVaccinePatientConnection>;
  listWaitingListItems?: Maybe<ModelWaitingListItemConnection>;
  listZendeskMessages?: Maybe<ModelZendeskMessageConnection>;
  renderPDF?: Maybe<RenderPdfResult>;
  searchPatients?: Maybe<ModelSearchPatientResult>;
  searchTreatments?: Maybe<ModelSearchTreatmentResult>;
  shouldConfirm?: Maybe<ConfirmQueryResult>;
  simulateCheckupResultJudgement?: Maybe<CheckupResultSet>;
  validateVaccinePassword?: Maybe<ValidateResult>;
};


export type QueryDisplayStaffShiftForDayArgs = {
  input: StaffShiftForDayInput;
};


export type QueryDisplayStaffShiftForMonthArgs = {
  input: StaffShiftForMonthInput;
};


export type QueryGetAttachmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetAuditLogQueryResultArgs = {
  input: AuditLogQueryIdInput;
};


export type QueryGetAutoNumberArgs = {
  clinic: Scalars['ID'];
  schema: Scalars['ID'];
};


export type QueryGetBlockArgs = {
  date: Scalars['ID'];
  laneStart: Scalars['ID'];
};


export type QueryGetBulkReceiptDownloadArgs = {
  input: BulkReceiptDownloadInput;
};


export type QueryGetCardArgs = {
  id: Scalars['ID'];
};


export type QueryGetChatPresignedPostArgs = {
  input: ChatPresignedPostQueryInput;
};


export type QueryGetCheckupAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupDownloadableLinkArgs = {
  input: CheckupDownloadableLinkQueryInput;
};


export type QueryGetCheckupInterviewArgs = {
  input: CheckupInterviewInput;
};


export type QueryGetCheckupInterviewQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOptionArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOptionIndividualArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOrganizationChatWorkspaceArgs = {
  checkupOrganizationId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type QueryGetCheckupOrganizationEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOrganizationEventOptionArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOrganizationEventTemplateArgs = {
  checkupOrganizationEventId: Scalars['ID'];
  templateKind: Scalars['ID'];
};


export type QueryGetCheckupOrganizationEventTemplateV2Args = {
  id: Scalars['ID'];
};


export type QueryGetCheckupOrganizationForLaborManagerArgs = {
  organizationId?: Maybe<Scalars['String']>;
};


export type QueryGetCheckupOrganizationMemberEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupPlanArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupPlanIndividualArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupPresignedPostArgs = {
  input: CheckupPresignedPostQueryInput;
};


export type QueryGetCheckupResultArgs = {
  patientNo: Scalars['ID'];
  dateLabel: Scalars['ID'];
};


export type QueryGetCheckupResultItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupResultItemGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupResultItemSubGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupResultSetArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckupTemplateAttachmentSignedPostArgs = {
  input: GetCheckupTemplateAttachmentSignedPostInput;
};


export type QueryGetCheckupUnionArgs = {
  id: Scalars['ID'];
};


export type QueryGetClinicArgs = {
  id: Scalars['ID'];
};


export type QueryGetClinicMemoArgs = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryGetDentalEvaluationArgs = {
  treatmentId: Scalars['ID'];
};


export type QueryGetDentalEvaluationItemsArgs = {
  id: Scalars['ID'];
};


export type QueryGetDepartmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountCheckupIndividualMastersArgs = {
  input: DiscountCodeInput;
};


export type QueryGetDiscountCodeArgs = {
  id: Scalars['ID'];
};


export type QueryGetDoctorArgs = {
  id: Scalars['ID'];
};


export type QueryGetDosageArgs = {
  id?: Maybe<Scalars['String']>;
  pharmacy?: Maybe<Scalars['ID']>;
  dateTimeUserId?: Maybe<Scalars['ID']>;
};


export type QueryGetDosagePrescriptionArgs = {
  id: Scalars['ID'];
};


export type QueryGetDownloadableLinkArgs = {
  input: DownloadableLinkInput;
};


export type QueryGetDownloadableLinkForCheckupTemplateAttachmentArgs = {
  input: CheckupTemplateAttachmentDownloadableLinkInput;
};


export type QueryGetEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventInstructionArgs = {
  id: Scalars['ID'];
};


export type QueryGetExtendedDictionaryArgs = {
  key: Scalars['ID'];
};


export type QueryGetFeedbackArgs = {
  treatmentId: Scalars['ID'];
};


export type QueryGetFeedbackByPatientArgs = {
  input: FeedbackQueryInput;
};


export type QueryGetFollowupArgs = {
  id: Scalars['ID'];
};


export type QueryGetImportJobArgs = {
  schema: Scalars['ID'];
  startedAt: Scalars['ID'];
};


export type QueryGetImportSignedPostArgs = {
  input: ImportInput;
};


export type QueryGetInstructionArgs = {
  id: Scalars['ID'];
};


export type QueryGetInsuranceCardSignedPostArgs = {
  input: InsuranceCardSignedPostQueryInput;
};


export type QueryGetInterviewArgs = {
  id: Scalars['ID'];
};


export type QueryGetInterviewDownloadableUrlArgs = {
  input: InterviewDownloadableUrlQueryInput;
};


export type QueryGetInterviewPresignedPostArgs = {
  input: InterviewPresignedPostQueryInput;
};


export type QueryGetLineBotMessageArgs = {
  key: Scalars['ID'];
};


export type QueryGetLineMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGetLineMessageTemplateArgs = {
  key: Scalars['ID'];
  clinicDepartmentTreatmentKind: Scalars['ID'];
};


export type QueryGetLineReadStatusArgs = {
  key: ModelLineReadStatusIdCompositeKeyInput;
};


export type QueryGetLineUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetMedicalInfoPresignedPostArgs = {
  input: MedicalInfoPresignedPostQueryInput;
};


export type QueryGetMedicalQuestionnaireInterviewArgs = {
  domainIdentifier: Scalars['String'];
  interviewTimeId: Scalars['ID'];
};


export type QueryGetMedicalQuestionnaireInterviewAnswersArgs = {
  id: Scalars['ID'];
};


export type QueryGetMedicalQuestionnaireSurveyArgs = {
  surveyTime: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetMedicalQuestionnaireSurveyAnswersArgs = {
  id: Scalars['ID'];
};


export type QueryGetMonshinResultsArgs = {
  treatmentId: Scalars['String'];
};


export type QueryGetNextTreatmentPlanArgs = {
  input: GetNextTreatmentPlanQueryInput;
};


export type QueryGetNonceArgs = {
  nonce: Scalars['ID'];
};


export type QueryGetOpenMonthArgs = {
  yearMonth: Scalars['ID'];
  clinic: Scalars['ID'];
};


export type QueryGetOrcaPatientArgs = {
  orcaID: Scalars['ID'];
};


export type QueryGetOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientCommentArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientTagArgs = {
  tag: Scalars['ID'];
};


export type QueryGetPatientTagAssociationArgs = {
  patient: Scalars['ID'];
  tag: Scalars['ID'];
};


export type QueryGetPayjpPublicKeyArgs = {
  input: EmptyInput;
};


export type QueryGetPaymentArgs = {
  date: Scalars['ID'];
  userIdDepartmentTime: Scalars['ID'];
};


export type QueryGetPaymentStateArgs = {
  executionId: Scalars['ID'];
  createdAt: Scalars['ID'];
};


export type QueryGetPaymentStateMachineArgs = {
  executionId: Scalars['ID'];
};


export type QueryGetPharmacistArgs = {
  id: Scalars['ID'];
};


export type QueryGetPharmacyArgs = {
  id: Scalars['ID'];
};


export type QueryGetPrescriptionDownloadableLinkArgs = {
  input: PrescriptionDownloadableLinkInput;
};


export type QueryGetPrescriptionPresignedPostArgs = {
  input: MedicalInfoPresignedPostQueryInput;
};


export type QueryGetRemindActionArgs = {
  id: Scalars['ID'];
};


export type QueryGetRemindActionLogArgs = {
  id: Scalars['ID'];
};


export type QueryGetRemindActionPlanArgs = {
  date: Scalars['ID'];
  dosageId: Scalars['ID'];
};


export type QueryGetResultSignedUrlArgs = {
  input: ImportResultInput;
};


export type QueryGetReviewArgs = {
  schema: Scalars['ID'];
  createdAt: Scalars['ID'];
};


export type QueryGetRoomArgs = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};


export type QueryGetRoomVacancyArgs = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};


export type QueryGetSoapInfoArgs = {
  treatmentId: Scalars['ID'];
};


export type QueryGetSearchConditionArgs = {
  type: Scalars['ID'];
  createdAt: Scalars['ID'];
};


export type QueryGetSentMessageGroupBySearchArgs = {
  id: Scalars['ID'];
};


export type QueryGetShiftArgs = {
  clinic: Scalars['ID'];
  dateStartDoctor: Scalars['ID'];
};


export type QueryGetSignedPostArgs = {
  input: PaymentAttachmentInput;
};


export type QueryGetSlackNonceArgs = {
  id: Scalars['ID'];
};


export type QueryGetStaffArgs = {
  id: Scalars['ID'];
};


export type QueryGetStaffShiftArgs = {
  clinic: Scalars['ID'];
  staffId: Scalars['ID'];
};


export type QueryGetStaffVacancyArgs = {
  clinic: Scalars['ID'];
  dateStartDoctor: Scalars['ID'];
};


export type QueryGetSubjectTextArgs = {
  input: SubjectTextInput;
};


export type QueryGetTemporallyTreatmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetTreatmentArgs = {
  clinic?: Maybe<Scalars['String']>;
  dateTimeDepartmentUserIdTemporally?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetTreatmentKindArgs = {
  id: Scalars['ID'];
};


export type QueryGetTreatmentKindOperationsArgs = {
  id: Scalars['ID'];
  filter?: Maybe<ModelTreatmentKindFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetTreatmentProgressArgs = {
  treatmentProgressId: Scalars['ID'];
};


export type QueryGetTreatmentProgressHistoryArgs = {
  historyId: Scalars['ID'];
};


export type QueryGetTreatmentProgressNextStatusAssociationArgs = {
  statusId: Scalars['ID'];
  nextStatusId: Scalars['ID'];
};


export type QueryGetTreatmentProgressStatusArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserReserveConditionArgs = {
  userId: Scalars['ID'];
  treatmentKindStart: Scalars['ID'];
};


export type QueryGetUserThreadArgs = {
  input?: Maybe<GetUserThreadInput>;
};


export type QueryGetVacanciesArgs = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};


export type QueryGetVacancyConfigSetArgs = {
  id: Scalars['ID'];
};


export type QueryGetVacancyLaneArgs = {
  id: Scalars['ID'];
};


export type QueryGetVacancySpacerArgs = {
  clinic: Scalars['ID'];
  date: Scalars['ID'];
};


export type QueryGetVacancySpacerTemplateArgs = {
  clinic: Scalars['ID'];
  weekLaneId: Scalars['ID'];
};


export type QueryGetVacancyTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryGetVacancyUnitArgs = {
  id: Scalars['ID'];
};


export type QueryGetVaccineEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetVaccinePatientArgs = {
  id: Scalars['ID'];
};


export type QueryGetWaitingListItemArgs = {
  clinic: Scalars['ID'];
  startDepartmentTreatmentKindUserId: Scalars['ID'];
};


export type QueryGetZendeskMessageArgs = {
  type: Scalars['ID'];
  createdAt: Scalars['ID'];
};


export type QueryListAttachmentsArgs = {
  filter?: Maybe<ModelAttachmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListAutoNumbersArgs = {
  filter?: Maybe<ModelAutoNumberFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListBlocksArgs = {
  date: Scalars['ID'];
  filter?: Maybe<ModelBlockFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCardsArgs = {
  filter?: Maybe<ModelCardFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupAppointmentsArgs = {
  filter?: Maybe<ModelCheckupAppointmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupAppointmentsByKitDeliverStatusArgs = {
  createdAt?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelCheckupAppointmentFilterInput>;
  kitDeliverStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListCheckupInterviewQuestionsArgs = {
  filter?: Maybe<ModelCheckupInterviewQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOptionIndividualsArgs = {
  filter?: Maybe<ModelCheckupOptionIndividualFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOptionSuggestionArgs = {
  input: Array<CheckupOptionSuggestionInput>;
};


export type QueryListCheckupOptionsArgs = {
  filter?: Maybe<ModelCheckupOptionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationChatWorkspacesArgs = {
  filter?: Maybe<ModelCheckupOrganizationChatWorkspaceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationEventOptionsArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventOptionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationEventTemplateV2sArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventTemplateV2FilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationEventTemplatesArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventTemplateFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationEventsArgs = {
  filter?: Maybe<ModelCheckupOrganizationEventFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationMemberEventByRemindDateArgs = {
  checkupOrganizationEventId?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelCheckupOrganizationMemberEventFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextRemindOn?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListCheckupOrganizationMemberEventsArgs = {
  filter?: Maybe<ModelCheckupOrganizationMemberEventFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupOrganizationsArgs = {
  filter?: Maybe<ModelCheckupOrganizationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupPlanIndividualsArgs = {
  filter?: Maybe<ModelCheckupPlanIndividualFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupPlansArgs = {
  filter?: Maybe<ModelCheckupPlanFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultByStatusArgs = {
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelCheckupResultFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  status?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultItemGroupsArgs = {
  filter?: Maybe<ModelCheckupResultItemGroupFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultItemSubGroupsArgs = {
  filter?: Maybe<ModelCheckupResultItemSubGroupFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultItemsArgs = {
  filter?: Maybe<ModelCheckupResultItemFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultSetByStatusDateArgs = {
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelCheckupResultSetFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  status?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultSetsArgs = {
  filter?: Maybe<ModelCheckupResultSetFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupResultsArgs = {
  patientNo: Scalars['ID'];
  dateLabel?: Maybe<ModelCheckupResultPrimaryKeyCompositeKeyConditionInput>;
  filter?: Maybe<ModelCheckupResultFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupUnionsArgs = {
  filter?: Maybe<ModelCheckupUnionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCheckupVacanciesArgs = {
  input?: Maybe<VacancyUnitCalculation>;
};


export type QueryListClinicMemosArgs = {
  filter?: Maybe<ModelClinicMemoFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListClinicsArgs = {
  filter?: Maybe<ModelClinicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListCustomersArgs = {
  filter?: Maybe<ModelCustomerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDentalEvaluationItemssArgs = {
  filter?: Maybe<ModelDentalEvaluationItemsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDentalEvaluationsArgs = {
  filter?: Maybe<ModelDentalEvaluationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDepartmentsArgs = {
  filter?: Maybe<ModelDepartmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDiscountCodesArgs = {
  filter?: Maybe<ModelDiscountCodeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDoctorsArgs = {
  filter?: Maybe<ModelDoctorFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDosagePrescriptionsArgs = {
  filter?: Maybe<ModelDosagePrescriptionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDosagesArgs = {
  pharmacy: Scalars['ID'];
  dateTimeUserId: ModelListDosageCompositeKeyConditionInput;
  filter?: Maybe<ModelDosageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListEmployeesArgs = {
  filter?: Maybe<ModelEmployeeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListEventInstructionsArgs = {
  filter?: Maybe<ModelEventInstructionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListExtendedDictionarysArgs = {
  filter?: Maybe<ModelExtendedDictionaryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListFeedbacksArgs = {
  filter?: Maybe<ModelFeedbackFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListFollowupsArgs = {
  filter?: Maybe<ModelFollowupFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListImportJobsArgs = {
  schema: Scalars['ID'];
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelImportJobFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListInstructionsArgs = {
  filter?: Maybe<ModelInstructionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLineBotMessagesArgs = {
  filter?: Maybe<ModelLineBotMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLineMessageIdAndDateArgs = {
  createdAt?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelLineMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  lineMessageLineUserId?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListLineMessageTemplatesArgs = {
  filter?: Maybe<ModelLineMessageTemplateFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLineMessageTemplatesByClinicArgs = {
  key: LineTemplateKey;
  clinic: Scalars['String'];
};


export type QueryListLineMessagesArgs = {
  filter?: Maybe<ModelLineMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLineReadStatusByChannelArgs = {
  channelId?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelLineReadStatusFilterInput>;
  latestUpdatedAt?: Maybe<ModelStringConditionInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListLineUsersArgs = {
  filter?: Maybe<ModelLineUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLocationGroupsArgs = {
  filter?: Maybe<ModelLocationGroupFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLocationsArgs = {
  filter?: Maybe<ModelLocationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListLumeccaConsecutiveVacanciesArgs = {
  input?: Maybe<VacancyUnitCalculation>;
};


export type QueryListMedicalQuestionnaireInterviewAnswerssArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireInterviewAnswersFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListMedicalQuestionnaireInterviewByListTypeArgs = {
  input: ListMedicalQuestionnaireInterviewByListTypeInput;
};


export type QueryListMedicalQuestionnaireInterviewsArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireInterviewFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListMedicalQuestionnaireInterviewsByInterviewTimeArgs = {
  domainIdentifier: Scalars['String'];
  interviewTime: Scalars['String'];
};


export type QueryListMedicalQuestionnaireSurveyAnswerssArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireSurveyAnswersFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListMedicalQuestionnaireSurveysArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireSurveyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListMedicalQuestionnaireSurveysBySurveyTimeArgs = {
  surveyTime: Scalars['String'];
};


export type QueryListNoncesArgs = {
  filter?: Maybe<ModelNonceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOpenMonthsArgs = {
  filter?: Maybe<ModelOpenMonthFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOrcaPatientsArgs = {
  filter?: Maybe<ModelOrcaPatientFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOrganizationsArgs = {
  filter?: Maybe<ModelOrganizationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPatientCommentsArgs = {
  filter?: Maybe<ModelPatientCommentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPatientTagAssociationsArgs = {
  filter?: Maybe<ModelPatientTagAssociationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPatientTagsArgs = {
  filter?: Maybe<ModelPatientTagFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPatientsArgs = {
  filter?: Maybe<ModelPatientFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPaymentStateMachineSorByUserArgs = {
  date?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelPaymentStateMachineFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userIdDepartmentTime?: Maybe<ModelPaymentStateMachinePaymentStateMachinesCompositeKeyConditionInput>;
};


export type QueryListPaymentStateMachinesArgs = {
  filter?: Maybe<ModelPaymentStateMachineFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPaymentStatesArgs = {
  filter?: Maybe<ModelPaymentStateFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPaymentsArgs = {
  date?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelPaymentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPharmacistsArgs = {
  filter?: Maybe<ModelPharmacistFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListPharmacysArgs = {
  filter?: Maybe<ModelPharmacyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRemindActionLogsArgs = {
  filter?: Maybe<ModelRemindActionLogFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRemindActionPlansArgs = {
  date: Scalars['ID'];
  filter?: Maybe<ModelRemindActionPlanFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRemindActionSortedByDateArgs = {
  date?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelRemindActionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  time?: Maybe<ModelStringConditionInput>;
};


export type QueryListRemindActionsArgs = {
  filter?: Maybe<ModelRemindActionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListReviewsArgs = {
  filter?: Maybe<ModelReviewFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRoomVacanciesArgs = {
  roomType: Scalars['String'];
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelRoomVacancyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRoomVacancysArgs = {
  roomType: Scalars['String'];
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelRoomVacancyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRoomsArgs = {
  filter?: Maybe<ModelRoomFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListSoapInfosArgs = {
  filter?: Maybe<ModelSoapInfoFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListSearchConditionsArgs = {
  filter?: Maybe<ModelSearchConditionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListSentMessageGroupBySearchsArgs = {
  filter?: Maybe<ModelSentMessageGroupBySearchFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListShiftsArgs = {
  clinic: Scalars['ID'];
  dateStartDoctor?: Maybe<ModelShiftCompositeKeyConditionInput>;
  filter?: Maybe<ModelShiftFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListSlackNoncesArgs = {
  filter?: Maybe<ModelSlackNonceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffShiftsArgs = {
  staff?: Maybe<Scalars['String']>;
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelStaffVacancyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffShiftsForMonthArgs = {
  clinic: Scalars['ID'];
  yearMonth?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelStaffShiftFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffVacanciesArgs = {
  staff: Scalars['String'];
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelStaffVacancyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffVacancysArgs = {
  staff: Scalars['String'];
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelStaffVacancyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffsArgs = {
  filter?: Maybe<ModelStaffFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListStaffsByEmailArgs = {
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelStaffFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentKindsArgs = {
  filter?: Maybe<ModelTreatmentKindFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentProgressHistorysArgs = {
  filter?: Maybe<ModelTreatmentProgressHistoryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentProgressNextStatusAssociationsArgs = {
  filter?: Maybe<ModelTreatmentProgressNextStatusAssociationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentProgressStatussArgs = {
  filter?: Maybe<ModelTreatmentProgressStatusFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentProgresssArgs = {
  filter?: Maybe<ModelTreatmentProgressFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentSortedByDateArgs = {
  dateTime?: Maybe<ModelTreatmentSortByDateCompositeKeyConditionInput>;
  filter?: Maybe<ModelTreatmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  yearMonth?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentSortedByStatusArgs = {
  filter?: Maybe<ModelTreatmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  statusDate?: Maybe<ModelTreatmentUserAndDateCompositeKeyConditionInput>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryListTreatmentSortedByUserArgs = {
  date?: Maybe<Scalars['String']>;
  filter?: Maybe<ModelTreatmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<ModelStringConditionInput>;
};


export type QueryListTreatmentsArgs = {
  clinic: Scalars['String'];
  dateTimeDepartmentUserIdTemporally: ModelListTreatmentCompositeKeyConditionInput;
  filter?: Maybe<ModelTreatmentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListUserReserveConditionByRemindOnArgs = {
  filter?: Maybe<ModelUserReserveConditionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  remindOn: Scalars['ID'];
  sortDirection?: Maybe<ModelSortDirection>;
  treatmentKind?: Maybe<ModelStringConditionInput>;
};


export type QueryListUserReserveConditionsArgs = {
  userId: Scalars['ID'];
  treatmentKindStart?: Maybe<ModelListUserReserveConditionCompositeKeyConditionInput>;
  filter?: Maybe<ModelUserReserveConditionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListUserThreadsArgs = {
  filter?: Maybe<ModelUserThreadFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListUserThreadsByDateArgs = {
  input: UserThreadByDateInput;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListUsersArgs = {
  filter?: Maybe<ModelUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacanciesArgs = {
  input: VacancyUnitCalculation;
};


export type QueryListVacanciessArgs = {
  clinic: Scalars['ID'];
  date?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelVacanciesFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancyConfigSetsArgs = {
  filter?: Maybe<ModelVacancyConfigSetFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancyLanesArgs = {
  filter?: Maybe<ModelVacancyLaneFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancySpacerTemplatesArgs = {
  filter?: Maybe<ModelVacancySpacerTemplateFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancySpacersArgs = {
  filter?: Maybe<ModelVacancySpacerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancySpacersWeeksArgs = {
  clinic: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  laneId?: Maybe<Scalars['String']>;
};


export type QueryListVacancyTemplatesArgs = {
  filter?: Maybe<ModelVacancyTemplateFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVacancyUnitsArgs = {
  filter?: Maybe<ModelVacancyUnitFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVaccineEventsArgs = {
  filter?: Maybe<ModelVaccineEventFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVaccinePatientsArgs = {
  filter?: Maybe<ModelVaccinePatientFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListVaccinePatientsBySequenceIdArgs = {
  createdAt?: Maybe<ModelStringConditionInput>;
  filter?: Maybe<ModelVaccinePatientFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sequenceId?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
};


export type QueryListWaitingListItemsArgs = {
  clinic?: Maybe<Scalars['String']>;
  startDepartmentTreatmentKindUserId?: Maybe<ModelWaitingListItemPrimaryKeyCompositeKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelWaitingListItemFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListZendeskMessagesArgs = {
  type: Scalars['String'];
  createdAt?: Maybe<ModelStringInput>;
  filter?: Maybe<ModelZendeskMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryRenderPdfArgs = {
  input: RenderPdfInput;
};


export type QuerySearchPatientsArgs = {
  condition?: Maybe<SearchPatientConditionInput>;
  limit?: Maybe<Scalars['Int']>;
  orders?: Maybe<Array<SearchPatientOrderInput>>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySearchTreatmentsArgs = {
  condition?: Maybe<SearchTreatmentConditionInput>;
  limit?: Maybe<Scalars['Int']>;
  orders?: Maybe<Array<SearchTreatmentOrderInput>>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryShouldConfirmArgs = {
  input: ConfirmQueryInput;
};


export type QuerySimulateCheckupResultJudgementArgs = {
  input: UpdateCheckupResultSetInput;
};


export type QueryValidateVaccinePasswordArgs = {
  input: ValidateVaccinePasswordInput;
};

export type Question = {
  __typename?: 'Question';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  answer?: Maybe<Array<Maybe<AnswerResult>>>;
  options?: Maybe<Array<Maybe<QuestionOption>>>;
  category?: Maybe<Scalars['String']>;
  confirm_text?: Maybe<Scalars['String']>;
  medical_record_title?: Maybe<Scalars['String']>;
  medical_record_text?: Maybe<Scalars['String']>;
  negative_or_positive?: Maybe<NegaPosi>;
  additional_column_name?: Maybe<Scalars['String']>;
  additional_type?: Maybe<Scalars['String']>;
  isConditional?: Maybe<Scalars['Boolean']>;
  isNarrowDown?: Maybe<Scalars['Boolean']>;
};

export type QuestionInput = {
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  answer: Array<FullAnswerInput>;
  options: Array<QuestionOptionInput>;
  negative_or_positive?: Maybe<NegaPosiInput>;
  category?: Maybe<Scalars['String']>;
  confirm_text?: Maybe<Scalars['String']>;
  medical_record_text?: Maybe<Scalars['String']>;
  medical_record_title?: Maybe<Scalars['String']>;
  additional_column_name?: Maybe<Scalars['String']>;
  additional_type?: Maybe<Scalars['String']>;
  isConditional?: Maybe<Scalars['Boolean']>;
  isNarrowDown?: Maybe<Scalars['Boolean']>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type QuestionOptionInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type ReAnswerInterviewInput = {
  treatmentId: Scalars['String'];
  answers: Array<AnswerInput>;
};

export type ReceiptDownloadResult = {
  __typename?: 'ReceiptDownloadResult';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  user: CreateUserInput;
  card?: Maybe<CreateCardInput>;
};

export type RejectCheckupInput = {
  id: Scalars['ID'];
};

export type RemindAction = {
  __typename?: 'RemindAction';
  id: Scalars['ID'];
  user: User;
  treatment?: Maybe<Treatment>;
  dosage?: Maybe<Dosage>;
  date: Scalars['String'];
  time: Scalars['String'];
  remindActionType?: Maybe<RemindActionType>;
  remindActionStatus?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type RemindActionLog = {
  __typename?: 'RemindActionLog';
  id: Scalars['ID'];
  user?: Maybe<User>;
  treatment?: Maybe<Treatment>;
  dosage?: Maybe<Dosage>;
  to?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  createdAt: Scalars['String'];
};

export type RemindActionPlan = {
  __typename?: 'RemindActionPlan';
  date: Scalars['ID'];
  dosage?: Maybe<Dosage>;
  type?: Maybe<RemindActionType>;
  status?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export enum RemindActionStatus {
  Created = 'CREATED',
  SendStart = 'SEND_START',
  Send = 'SEND',
  Canceled = 'CANCELED',
  Failed = 'FAILED'
}

export enum RemindActionType {
  CompleteReservation = 'COMPLETE_RESERVATION',
  ChangeReservation = 'CHANGE_RESERVATION',
  CancelReservation = 'CANCEL_RESERVATION',
  RemindMonshin = 'REMIND_MONSHIN',
  RemindMonshin_2 = 'REMIND_MONSHIN_2',
  RemindReservation = 'REMIND_RESERVATION',
  DeterminationMonshin = 'DETERMINATION_MONSHIN',
  DeterminationClaim = 'DETERMINATION_CLAIM',
  Feedback = 'FEEDBACK',
  AfterFirst = 'AFTER_FIRST',
  AfterSecond = 'AFTER_SECOND',
  ArriveLate = 'ARRIVE_LATE',
  MedicinePrepared = 'MEDICINE_PREPARED',
  PharmacistFeedback = 'PHARMACIST_FEEDBACK',
  PharmacistRemind = 'PHARMACIST_REMIND',
  Recall = 'RECALL',
  RemindLate = 'REMIND_LATE',
  AttachmentReceipt = 'ATTACHMENT_RECEIPT',
  AttachmentCheckupResult = 'ATTACHMENT_CHECKUP_RESULT',
  RemindOnline = 'REMIND_ONLINE',
  PenaltyCountNotification = 'PENALTY_COUNT_NOTIFICATION',
  CheckupResultSummary = 'CHECKUP_RESULT_SUMMARY'
}

export type RenderPdfInput = {
  html: Scalars['String'];
};

export type RenderPdfResult = {
  __typename?: 'RenderPDFResult';
  url: Scalars['String'];
};

export type ReserveCheckupAppointmentInput = {
  checkupTreatment: CreateTreatmentInput;
  dentalTreatment?: Maybe<CreateTreatmentInput>;
  interview: CreateInterviewInput;
  appointment: CheckupAppointmentEntityInput;
};

export type ReserveCheckupInput = {
  checkupTreatment: CreateTreatmentInput;
  dentalTreatment: CreateTreatmentInput;
  interview: CreateInterviewInput;
  member: CheckupOrganizationMemberEventInput;
  options: Array<Maybe<Scalars['String']>>;
};

export type ResetCheckupAdminPasswordInput = {
  id: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  schema: ReviewSchema;
  createdAt: Scalars['String'];
  answers: Array<KeyValue>;
  userId: Scalars['String'];
};

export enum ReviewSchema {
  CompleteReservation = 'COMPLETE_RESERVATION'
}

export type Room = {
  __typename?: 'Room';
  clinic?: Maybe<Clinic>;
  roomType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RoomVacancy = {
  __typename?: 'RoomVacancy';
  roomType: Scalars['String'];
  roomNo: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatment?: Maybe<Treatment>;
  block?: Maybe<Block>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RoomVacancyInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
  roomNo?: Maybe<Scalars['String']>;
};

export type SearchCondition = {
  __typename?: 'SearchCondition';
  type: Scalars['ID'];
  clinic: Scalars['String'];
  createdAt: Scalars['String'];
  title: Scalars['String'];
  treatment?: Maybe<SearchTreatmentSetting>;
  patient?: Maybe<PatientSearchSetting>;
};

export type SearchConditionBetween = {
  __typename?: 'SearchConditionBetween';
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type SearchConditionBetweenInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type SearchConditionBetweenNumber = {
  __typename?: 'SearchConditionBetweenNumber';
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type SearchConditionBetweenNumberInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export enum SearchConditionChargeStatusInput {
  Charged = 'CHARGED',
  ChargeFailed = 'CHARGE_FAILED',
  NotCharged = 'NOT_CHARGED',
  ChargeSkipped = 'CHARGE_SKIPPED'
}

export enum SearchConditionInsuranceCardStatusInput {
  Invalid = 'INVALID',
  Valid = 'VALID',
  NotRegistered = 'NOT_REGISTERED'
}

export enum SearchConditionInterviewStatusInput {
  Completed = 'COMPLETED',
  Started = 'STARTED',
  NotStarted = 'NOT_STARTED'
}

export enum SearchConditionPaymethodStatusInput {
  Invalid = 'INVALID',
  Valid = 'VALID',
  NotRegistered = 'NOT_REGISTERED',
  Expired = 'EXPIRED'
}

export enum SearchOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SearchPatientCondition = {
  __typename?: 'SearchPatientCondition';
  freeText?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['String']>;
  penaltyCount?: Maybe<SearchConditionBetweenNumber>;
  accountRestricted?: Maybe<Scalars['Boolean']>;
  patientTags?: Maybe<Array<Scalars['String']>>;
  treatmentCondition?: Maybe<Array<SearchPatientConditionTreatmentCondition>>;
};

export type SearchPatientConditionCheckupOptions = {
  __typename?: 'SearchPatientConditionCheckupOptions';
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionCheckupOptionsInput = {
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionCheckupPlan = {
  __typename?: 'SearchPatientConditionCheckupPlan';
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionCheckupPlanInput = {
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionClinic = {
  __typename?: 'SearchPatientConditionClinic';
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionClinicInput = {
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionDepartment = {
  __typename?: 'SearchPatientConditionDepartment';
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionDepartmentInput = {
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionInput = {
  freeText?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['String']>;
  penaltyCount?: Maybe<SearchConditionBetweenNumberInput>;
  accountRestricted?: Maybe<Scalars['Boolean']>;
  patientTags?: Maybe<Array<Scalars['String']>>;
  treatmentCondition?: Maybe<Array<SearchPatientConditionTreatmentConditionInput>>;
};

export enum SearchPatientConditionOperatorInput {
  And = 'AND',
  Or = 'OR'
}

export type SearchPatientConditionTreatmentCondition = {
  __typename?: 'SearchPatientConditionTreatmentCondition';
  status?: Maybe<SearchPatintConditionTreatmentStatus>;
  clinic?: Maybe<SearchPatientConditionClinic>;
  department?: Maybe<SearchPatientConditionDepartment>;
  treatmentKind?: Maybe<SearchPatientConditionTreatmentKind>;
  checkupPlan?: Maybe<SearchPatientConditionCheckupPlan>;
  checkupOptions?: Maybe<SearchPatientConditionCheckupOptions>;
};

export type SearchPatientConditionTreatmentConditionInput = {
  status?: Maybe<SearchPatintConditionTreatmentStatus>;
  clinic?: Maybe<SearchPatientConditionClinicInput>;
  department?: Maybe<SearchPatientConditionDepartmentInput>;
  treatmentKind?: Maybe<SearchPatientConditionTreatmentKindInput>;
  checkupPlan?: Maybe<SearchPatientConditionCheckupPlanInput>;
  checkupOptions?: Maybe<SearchPatientConditionCheckupOptionsInput>;
};

export type SearchPatientConditionTreatmentKind = {
  __typename?: 'SearchPatientConditionTreatmentKind';
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientConditionTreatmentKindInput = {
  operator: SearchPatientConditionOperatorInput;
  id?: Maybe<Array<Scalars['String']>>;
};

export type SearchPatientOrder = {
  __typename?: 'SearchPatientOrder';
  column: SearchPatientSortableColumn;
  direction: ModelSortDirection;
};

export type SearchPatientOrderInput = {
  column: SearchPatientSortableColumn;
  direction: ModelSortDirection;
};

export enum SearchPatientSortableColumn {
  Name = 'NAME',
  PatientNo = 'PATIENT_NO'
}

export enum SearchPatintConditionTreatmentStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  Fixed = 'FIXED',
  NotFixed = 'NOT_FIXED'
}

export type SearchTreatmentCondition = {
  __typename?: 'SearchTreatmentCondition';
  date?: Maybe<SearchConditionBetween>;
  freeText?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  treatmentKindId?: Maybe<Scalars['String']>;
  checkupPlanId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  interviewStatus?: Maybe<SearchConditionInterviewStatusInput>;
  paymethodStatus?: Maybe<SearchConditionPaymethodStatusInput>;
  insuranceCardStatus?: Maybe<SearchConditionInsuranceCardStatusInput>;
  treatmentStatus?: Maybe<Scalars['String']>;
  chargeStatus?: Maybe<SearchConditionChargeStatusInput>;
  chargeAmount?: Maybe<SearchConditionBetweenNumber>;
  updatedAt?: Maybe<SearchConditionBetween>;
  patientTags?: Maybe<Array<Scalars['String']>>;
};

export type SearchTreatmentConditionInput = {
  date?: Maybe<SearchConditionBetweenInput>;
  freeText?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  treatmentKindId?: Maybe<Scalars['String']>;
  checkupPlanId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  interviewStatus?: Maybe<SearchConditionInterviewStatusInput>;
  paymethodStatus?: Maybe<SearchConditionPaymethodStatusInput>;
  insuranceCardStatus?: Maybe<SearchConditionInsuranceCardStatusInput>;
  treatmentStatus?: Maybe<Scalars['String']>;
  chargeStatus?: Maybe<SearchConditionChargeStatusInput>;
  chargeAmount?: Maybe<SearchConditionBetweenNumberInput>;
  updatedAt?: Maybe<SearchConditionBetweenInput>;
  patientTags?: Maybe<Array<Scalars['String']>>;
};

export type SearchTreatmentOrder = {
  __typename?: 'SearchTreatmentOrder';
  column: SearchTreatmentSortableColumn;
  direction: ModelSortDirection;
};

export type SearchTreatmentOrderInput = {
  column: SearchTreatmentSortableColumn;
  direction: ModelSortDirection;
};

export type SearchTreatmentSetting = {
  __typename?: 'SearchTreatmentSetting';
  searchCondition: SearchTreatmentCondition;
  orders: Array<SearchTreatmentOrder>;
  displayColumns: Array<Scalars['String']>;
};

export type SearchTreatmentSettingInput = {
  searchCondition: SearchTreatmentConditionInput;
  orders: Array<SearchTreatmentOrderInput>;
  displayColumns: Array<Scalars['String']>;
};

export enum SearchTreatmentSortableColumn {
  UserName = 'USER_NAME',
  PatientNo = 'PATIENT_NO',
  InterviewStatus = 'INTERVIEW_STATUS',
  TreatmentStatus = 'TREATMENT_STATUS',
  StaffName = 'STAFF_NAME',
  Date = 'DATE'
}

export type SendFeedbackInput = {
  treatmentId?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
};

export type SendMessageBySearchFuncResult = {
  __typename?: 'SendMessageBySearchFuncResult';
  id?: Maybe<Scalars['String']>;
};

export type SendMessageBySearchInput = {
  title: Scalars['String'];
  mailSubject: Scalars['String'];
  messageText: Scalars['String'];
  searchCondition: SentMessageSearchConditionInput;
  targetUsers?: Maybe<Array<TargetUsers>>;
  shouldSendAll?: Maybe<Scalars['Boolean']>;
};

export type SendPharmacyCallInput = {
  dosageId: Scalars['String'];
};

export type SendSqsOfCheckupResultSummaryInput = {
  treatmentId: Scalars['String'];
  userId: Scalars['String'];
};

export type SendVaccineInvitationInput = {
  vaccinePatientIds: Array<Scalars['String']>;
};

export type SentMessageGroupBySearch = {
  __typename?: 'SentMessageGroupBySearch';
  id: Scalars['ID'];
  title: Scalars['String'];
  mailSubject: Scalars['String'];
  templateMessage: Scalars['String'];
  searchCondition: SentMessageSearchCondition;
  targetUserIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  createdUserName: Scalars['String'];
};

export type SentMessageGroupBySearchConnection = {
  __typename?: 'SentMessageGroupBySearchConnection';
  items?: Maybe<Array<Maybe<SentMessageGroupBySearch>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SentMessageGroupBySearchCreatedAtFilterInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
};

export type SentMessageGroupBySearchFilterInput = {
  id?: Maybe<SentMessageGroupBySearchIdFilterInput>;
  createdAt?: Maybe<SentMessageGroupBySearchCreatedAtFilterInput>;
};

export type SentMessageGroupBySearchIdFilterInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
};

export type SentMessageSearchCondition = {
  __typename?: 'SentMessageSearchCondition';
  type: Scalars['ID'];
  treatment?: Maybe<SearchTreatmentCondition>;
  patient?: Maybe<SearchPatientCondition>;
};

export type SentMessageSearchConditionInput = {
  patient?: Maybe<SearchPatientConditionInput>;
  treatment?: Maybe<SearchTreatmentConditionInput>;
  type: Scalars['ID'];
};

export enum Sex {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type Shift = {
  __typename?: 'Shift';
  clinic: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  date: Scalars['String'];
  doctor?: Maybe<Doctor>;
  lane?: Maybe<VacancyLane>;
};

export type ShiftAdminForDay = {
  __typename?: 'ShiftAdminForDay';
  date: Scalars['String'];
  staffName?: Maybe<Scalars['String']>;
  staffRole?: Maybe<StaffRoleType>;
  status: Array<Maybe<ShiftAdminForDayStatus>>;
};

export type ShiftAdminForDayStatus = {
  __typename?: 'ShiftAdminForDayStatus';
  time?: Maybe<Scalars['String']>;
  vacancyStatus?: Maybe<VacancyStatus>;
  failure?: Maybe<Scalars['Boolean']>;
};

export type ShiftAdminForMonth = {
  __typename?: 'ShiftAdminForMonth';
  date: Scalars['String'];
  staffName?: Maybe<Scalars['String']>;
  staffRole?: Maybe<StaffRoleType>;
  failure?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type SignedPost = {
  __typename?: 'SignedPost';
  url: Scalars['String'];
  fields: Array<SignedPostField>;
};

export type SignedPostField = {
  __typename?: 'SignedPostField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SignedPostForImport = {
  __typename?: 'SignedPostForImport';
  post: SignedPost;
  name: Scalars['String'];
};

export type SlackInvitationInput = {
  checkupOrganizationMemberEventIds: Array<Scalars['String']>;
};

export type SlackNonce = {
  __typename?: 'SlackNonce';
  id: Scalars['ID'];
  slackId: Scalars['String'];
};

export type SoapInfo = {
  __typename?: 'SOAPInfo';
  id: Scalars['ID'];
  treatment: Treatment;
  subjectComment?: Maybe<Scalars['String']>;
  doctor?: Maybe<Doctor>;
  userId?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type SoapSubject = {
  __typename?: 'SOAPSubject';
  firstVisit?: Maybe<Scalars['Boolean']>;
  negative_or_positive?: Maybe<NegaPosi>;
  subjects?: Maybe<Array<Maybe<Subject>>>;
};

export type Staff = {
  __typename?: 'Staff';
  role: StaffRoleType;
  staffId: Scalars['String'];
  enable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  enableTreatmentKinds: ModelTreatmentKindConnection;
  enableOptions: ModelCheckupOptionConnection;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  iconSrc?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type StaffEnableTreatmentKindsArgs = {
  filter?: Maybe<ModelTreatmentKindFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type StaffEnableOptionsArgs = {
  filter?: Maybe<ModelCheckupOptionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type StaffCVacancyInput = {
  yearMonth?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  vacancyStatus?: Maybe<ModelVacancyStatusInput>;
};

export enum StaffRoleType {
  MDr = 'MDr',
  Ns = 'Ns',
  DDr = 'DDr',
  Dh = 'DH',
  Mt = 'MT'
}

export type StaffShift = {
  __typename?: 'StaffShift';
  clinic?: Maybe<Clinic>;
  staff: Staff;
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  failure?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type StaffShiftForDayInput = {
  date?: Maybe<Scalars['String']>;
};

export type StaffShiftForMonthInput = {
  clinic?: Maybe<Scalars['String']>;
  yearMonth?: Maybe<Scalars['String']>;
};

export type StaffVacancy = {
  __typename?: 'StaffVacancy';
  clinic?: Maybe<Clinic>;
  staff: Staff;
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatment?: Maybe<Treatment>;
  block?: Maybe<Block>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type StartInterviewInput = {
  treatmentId: Scalars['String'];
};

export type Subject = {
  __typename?: 'Subject';
  kind?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<Maybe<Question>>>;
};

export type SubjectText = {
  __typename?: 'SubjectText';
  treatmentId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
};

export enum SubjectTextIdType {
  Patient = 'PATIENT',
  Treatment = 'TREATMENT'
}

export type SubjectTextInput = {
  treatmentId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  idType: SubjectTextIdType;
};

export type SubmitCheckupInterviewInput = {
  treatmentId: Scalars['String'];
  answers: Array<AnswerInput>;
  timing: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onActivateScanner?: Maybe<ActivateScanner>;
  onPrescriptionUpdate?: Maybe<EmptyResult>;
  onSlackUserConnectionFinished?: Maybe<AsyncProcessResult>;
  onNotifyChatUser?: Maybe<NotifyChatUser>;
  onNotifyAsyncJobStatus?: Maybe<AsyncJob>;
  onNotifyTreatmentUpdate?: Maybe<NotifyTreatmentUpdate>;
  onTreatmentProgress?: Maybe<TreatmentProgress>;
};


export type SubscriptionOnNotifyAsyncJobStatusArgs = {
  id: Scalars['String'];
};


export type SubscriptionOnNotifyTreatmentUpdateArgs = {
  date: Scalars['String'];
};


export type SubscriptionOnTreatmentProgressArgs = {
  clinicId: Scalars['String'];
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  medicalQuestionnaireSurvey: MedicalQuestionnaireSurvey;
  answer?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SurveyAnswerChoice = {
  __typename?: 'SurveyAnswerChoice';
  id: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  label: Scalars['String'];
  value: Scalars['String'];
  type?: Maybe<AnswerType>;
  placeholder?: Maybe<Scalars['String']>;
  exit?: Maybe<MedicalQuestionnaireSurvey>;
};

export type SurveyAnswerChoiceInput = {
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  exit?: Maybe<Scalars['String']>;
};

export type SurveyAnswerInput = {
  medicalQuestionnaireSurvey: Scalars['String'];
  answer: Array<Maybe<Scalars['String']>>;
};

export type SurveyAnswerStatus = {
  __typename?: 'SurveyAnswerStatus';
  surveyTime: SurveyTimeType;
  finished?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Maybe<SurveyAnswer>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SurveyAnswerStatusInput = {
  finished?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Maybe<SurveyAnswerInput>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum SurveyTimeType {
  FirstVisit = 'FIRST_VISIT',
  FirstVisitDental = 'FIRST_VISIT_DENTAL'
}

export type TargetUsers = {
  id: Scalars['String'];
  lineId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export enum TemplateKind {
  Invitation = 'INVITATION',
  Remind = 'REMIND'
}

export type TestResult = {
  __typename?: 'TestResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type Treatment = {
  __typename?: 'Treatment';
  id: Scalars['String'];
  user?: Maybe<User>;
  date: Scalars['String'];
  time: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  estimatedEnd?: Maybe<Scalars['String']>;
  department?: Maybe<Department>;
  physician?: Maybe<Scalars['String']>;
  status?: Maybe<TreatmentStatus>;
  holdedAt?: Maybe<Scalars['String']>;
  yearMonth?: Maybe<Scalars['String']>;
  orcaReservationId?: Maybe<Scalars['String']>;
  orcaAcceptanceId?: Maybe<Scalars['String']>;
  followup: ModelFollowupConnection;
  payment?: Maybe<Payment>;
  soap?: Maybe<SoapInfo>;
  interview?: Maybe<Interview>;
  preInterview?: Maybe<Interview>;
  attachments: ModelAttachmentConnection;
  clinic?: Maybe<Clinic>;
  temporally?: Maybe<Scalars['String']>;
  remind: ModelRemindActionConnection;
  treatmentKind?: Maybe<TreatmentKind>;
  /**
   * 担当できる医師の情報。担当可能な医師が複数いる場合があるため、配列になっているが現状では1人のみの入力になっている
   * そのため、editConditionのdoctor = physician(DoctorテーブルのID)
   */
  editCondition?: Maybe<Array<TreatmentEditCondition>>;
  pharmacy?: Maybe<Pharmacy>;
  feedback?: Maybe<Feedback>;
  dentalEvaluation?: Maybe<DentalEvaluation>;
  checkupOrganizationMemberEvent?: Maybe<CheckupOrganizationMemberEvent>;
  checkupAppointment?: Maybe<CheckupAppointment>;
  options?: Maybe<Array<Scalars['String']>>;
  interviewId?: Maybe<Scalars['String']>;
  lateContactFlag?: Maybe<Scalars['Boolean']>;
  sendRemind?: Maybe<Scalars['Boolean']>;
  doctor?: Maybe<Doctor>;
  /** 予約管理上のコメント */
  reservationMemo?: Maybe<Scalars['String']>;
  /** ユーザーから隠す */
  hidden?: Maybe<Scalars['Boolean']>;
  /** ユーザー表示用の所要時間 */
  displayDuration?: Maybe<Scalars['Float']>;
  /** ディスカウントコード */
  discount?: Maybe<DiscountCode>;
  discountCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  /** ワクチン接種ID */
  vaccinePatient?: Maybe<VaccinePatient>;
  /** 問診が不要であればtrue */
  noInterview?: Maybe<Scalars['Boolean']>;
  /** オンライン診療フラグ */
  online?: Maybe<Scalars['Boolean']>;
  /** レーンID */
  laneId?: Maybe<Scalars['String']>;
  /** 予約可能期間開始日 */
  reservableFrom?: Maybe<Scalars['String']>;
  /** Lumeccaの診察と照射の紐付けID */
  lumeccaConsecutiveId?: Maybe<Scalars['String']>;
  /** 診察室ウィンドウの状態を保持する項目 */
  detailedStatus?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  checklist?: Maybe<TreatmentChecklist>;
  /** 管理者による予約であればtrue */
  reservedByAdmin?: Maybe<Scalars['Boolean']>;
  /** 新空き枠で追加した対応するスタッフ情報 */
  assignedStaff?: Maybe<Array<Maybe<AssignedStaff>>>;
  /** 課金不要事由 */
  noChargeReason?: Maybe<NoChargeReason>;
  noChargeReasonDetail?: Maybe<Scalars['String']>;
  /** 新空き枠で追加した対応する部屋情報 */
  assignedRoom?: Maybe<AssignedRoom>;
  /** 同意したキャンセルポリシーのバージョンの日付 */
  cancelPolicyRevision?: Maybe<Scalars['String']>;
  /** 診療開始時間(DateTime) */
  startedAt?: Maybe<Scalars['String']>;
  /** 診療終了時間(DateTime) */
  endedAt?: Maybe<Scalars['String']>;
  /** 課金サマリー */
  lastPaymentAmount?: Maybe<Scalars['Int']>;
  /** 課金ステータスサマリー */
  lastPaymentStatus?: Maybe<Scalars['String']>;
  /** 課金ステータスサマリー */
  lastPaymentStatusUpdatedAt?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
};


export type TreatmentFollowupArgs = {
  filter?: Maybe<ModelFollowupFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type TreatmentAttachmentsArgs = {
  filter?: Maybe<ModelAttachmentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type TreatmentRemindArgs = {
  filter?: Maybe<ModelRemindActionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum TreatmentCancelReason {
  ClinicConvenience = 'CLINIC_CONVENIENCE',
  PatientConvenience = 'PATIENT_CONVENIENCE',
  PatientConvenienceNoContact = 'PATIENT_CONVENIENCE_NO_CONTACT'
}

export type TreatmentChecklist = {
  __typename?: 'TreatmentChecklist';
  insuranceCard?: Maybe<Scalars['Boolean']>;
  creditCard?: Maybe<Scalars['Boolean']>;
  interview?: Maybe<Scalars['Boolean']>;
  carte?: Maybe<Scalars['Boolean']>;
  urinalysis?: Maybe<Scalars['Boolean']>;
};

export type TreatmentChecklistInput = {
  insuranceCard?: Maybe<Scalars['Boolean']>;
  creditCard?: Maybe<Scalars['Boolean']>;
  interview?: Maybe<Scalars['Boolean']>;
  carte?: Maybe<Scalars['Boolean']>;
  urinalysis?: Maybe<Scalars['Boolean']>;
};

/** 診察変更条件 */
export type TreatmentEditCondition = {
  __typename?: 'TreatmentEditCondition';
  staff?: Maybe<Staff>;
  role?: Maybe<Scalars['String']>;
  doctor?: Maybe<Doctor>;
  duration: Scalars['Float'];
  anyone?: Maybe<Scalars['Boolean']>;
};

export type TreatmentEditConditionInput = {
  staff?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  anyone?: Maybe<Scalars['Boolean']>;
};

export type TreatmentKind = {
  __typename?: 'TreatmentKind';
  id: Scalars['ID'];
  name: Scalars['String'];
  department?: Maybe<Department>;
  shortName: Scalars['String'];
  course?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  /** ワクチン制御の対象かどうか */
  vaccine?: Maybe<Scalars['Boolean']>;
  /** 複数回受診するワクチンの場合、シークエンスの設定 */
  vaccineSequence?: Maybe<VaccineSequence>;
  /** リコール自動送信用のメッセージテンプレート */
  remindTemplate?: Maybe<Scalars['String']>;
  /** 診療科の重複の許可 */
  permitDuplicateDepartment?: Maybe<Scalars['Boolean']>;
  noInterview?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['String']>;
  interviewName?: Maybe<Scalars['String']>;
  estimatedDuration?: Maybe<Scalars['Float']>;
  revisit?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  /** Department.announcement をオーバーライドできる。 */
  announcement?: Maybe<Scalars['String']>;
  /** subAnnouncementはTreatmentKindのみ */
  subAnnouncement?: Maybe<Scalars['String']>;
  /** Department.requirement をオーバーライドできる。 */
  requirement?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  /** ユーザーに表示する長さ */
  displayDuration?: Maybe<Scalars['Int']>;
  /** 新空き枠用 */
  operations?: Maybe<Array<Maybe<Operation>>>;
  cancelPolicies?: Maybe<Array<Maybe<CancelPolicy>>>;
  /**
   * 問診リスト
   * 値のサンプル：12#AFTER_RESERVE（domainIdentifier#interviewTime でidは不要）
   */
  listMedicalQuestionnaireInterview: ModelMedicalQuestionnaireInterviewConnection;
  /** 予約診療費の徴収対象かどうか */
  requiredReservationFee?: Maybe<Scalars['Boolean']>;
  /** 連鎖予約の場合、対象の処置種別IDが入る */
  consecutiveTreatmentKindId?: Maybe<Scalars['String']>;
};


export type TreatmentKindListMedicalQuestionnaireInterviewArgs = {
  filter?: Maybe<ModelMedicalQuestionnaireInterviewFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

/**
 * 診察管理向けの処置イベント
 * フロー情報を管理するテーブル。
 * 
 * Updateをかける前提に立ち、流動性の高いテーブルのため、分析や履歴などはTreatmentProgressHistoryテーブルで管理する。
 */
export type TreatmentProgress = {
  __typename?: 'TreatmentProgress';
  /** ID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId: Scalars['ID'];
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  clinic?: Maybe<Clinic>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  locationGroup?: Maybe<LocationGroup>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  patient?: Maybe<Patient>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstruction>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

/**
 * 診察管理向けの処置イベント履歴
 * Updateは行わず、Insertのみの運用とする前提。分析や履歴などはこのTreatmentProgressHistoryテーブルで管理。
 * TreatmentProgressが更新されたらInsertされる。
 */
export type TreatmentProgressHistory = {
  __typename?: 'TreatmentProgressHistory';
  /** ID */
  historyId: Scalars['ID'];
  /** treatmentProgressテーブルとの紐付けをするためのID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId: Scalars['ID'];
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  clinic?: Maybe<Clinic>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  locationGroup?: Maybe<LocationGroup>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 退室日時 */
  exitAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  patient?: Maybe<Patient>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstruction>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

export type TreatmentProgressNextStatusAssociation = {
  __typename?: 'TreatmentProgressNextStatusAssociation';
  statusId: Scalars['String'];
  status?: Maybe<TreatmentProgressStatus>;
  nextStatusId: Scalars['String'];
  nextStatus?: Maybe<TreatmentProgressStatus>;
};

/** 診察ステータス。看護待ち、医師診療中、会計待ち、など */
export type TreatmentProgressStatus = {
  __typename?: 'TreatmentProgressStatus';
  id: Scalars['ID'];
  /** 診察ステータス名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** 診察ステータスを上位表示する役職。役職によって表示順が変わるため。 */
  preferredRoles: Array<Maybe<Scalars['String']>>;
  /** 進行中、待機中を判別するためのステータス */
  status: Scalars['String'];
  /** 次の行動の候補を定義 */
  nextStatus: ModelTreatmentProgressNextStatusAssociationConnection;
};


/** 診察ステータス。看護待ち、医師診療中、会計待ち、など */
export type TreatmentProgressStatusNextStatusArgs = {
  filter?: Maybe<ModelTreatmentProgressNextStatusAssociationFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type TreatmentSearchSetting = {
  __typename?: 'TreatmentSearchSetting';
  condition?: Maybe<SearchTreatmentCondition>;
  orders?: Maybe<Array<SearchTreatmentOrder>>;
  displayColumns: Array<Scalars['String']>;
};

export type TreatmentSearchSettingInput = {
  condition?: Maybe<SearchTreatmentConditionInput>;
  orders?: Maybe<Array<Maybe<SearchTreatmentOrderInput>>>;
  displayColumns: Array<Scalars['String']>;
};

export enum TreatmentStatus {
  Temporally = 'TEMPORALLY',
  Fixed = 'FIXED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  NoShow = 'NO_SHOW'
}

export type UpdateAttachmentInput = {
  id: Scalars['ID'];
  attachmentTreatmentId?: Maybe<Scalars['String']>;
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<AttachmentType>;
};

export type UpdateAutoNumberInput = {
  clinic: Scalars['String'];
  schema: Scalars['String'];
  current: Scalars['Int'];
  digits: Scalars['Int'];
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type UpdateBlockInput = {
  update: CreateBlockInput;
  remove: DeleteBlockInput;
};

export type UpdateCardInput = {
  id: Scalars['ID'];
  cardCustomerId: Scalars['String'];
  fourDigit: Scalars['String'];
  brand: Scalars['String'];
  payjpID: Scalars['String'];
  expAt: Scalars['String'];
};

export type UpdateCheckupAppointmentInput = {
  id: Scalars['ID'];
  userId: Scalars['String'];
  birthDate: Scalars['String'];
  address: Scalars['String'];
  planId: Scalars['String'];
  kitDeliverStatus: CheckupKitDeliverStatus;
  kitDeliverDate?: Maybe<Scalars['String']>;
  treatmentStatus: TreatmentStatus;
  planPrice: Scalars['Int'];
  planDiscountAmount?: Maybe<DiscountAmountInput>;
  planBillType?: Maybe<Scalars['String']>;
  optionPrices: Array<CheckupOptionPriceInput>;
  checkupOrganizationMemberEventId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type UpdateCheckupAppointmentKitDeliverStatusInput = {
  id: Scalars['String'];
  status: CheckupKitDeliverStatus;
  deliverDate?: Maybe<Scalars['String']>;
};

export type UpdateCheckupInput = {
  checkupTreatment: CreateTreatmentInput;
  dentalTreatment?: Maybe<CreateTreatmentInput>;
  interview?: Maybe<CreateInterviewInput>;
  appointment: CheckupAppointmentEntityInput;
};

export type UpdateCheckupInterviewQuestionInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<QuestionOptionInput>>>;
  confirm_text?: Maybe<Scalars['String']>;
  additional_column_name?: Maybe<Scalars['String']>;
  additional_type?: Maybe<Scalars['String']>;
  /**
   * BeforeReserving: '1_BEFORE_RESERVING',
   * AfterReserving: '2_AFTER_RESERVING',
   */
  timing?: Maybe<Scalars['String']>;
  condition?: Maybe<Array<Maybe<CheckupInterviewConditionInput>>>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type UpdateCheckupOptionIndividualInput = {
  id: Scalars['ID'];
  optionId: Scalars['String'];
  terms: Array<CheckupOptionIndividualTermInput>;
};

export type UpdateCheckupOptionInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  requiredMin?: Maybe<Scalars['Int']>;
  clinicType?: Maybe<ClinicType>;
  regularPrice?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  aboutDisplay?: Maybe<Scalars['Boolean']>;
  annotation?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  recommendLogic?: Maybe<Array<CheckupOptionRecommendLogicInput>>;
};

export type UpdateCheckupOrganizationChatWorkspaceInput = {
  checkupOrganizationId: Scalars['String'];
  workspaceId: Scalars['String'];
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  type?: Maybe<ChatWorkspaceType>;
  accessToken?: Maybe<Scalars['String']>;
};

export type UpdateCheckupOrganizationEventInput = {
  /** UUID */
  id: Scalars['ID'];
  /** 名称（「2022年度」等） */
  name: Scalars['String'];
  /**
   * 年度（2022等）
   * 年度の生成時にシーケンスとして利用する
   */
  year: Scalars['Int'];
  /** 年度 + 期首で算出した開始日 */
  start: Scalars['String'];
  /** 年度 + 期首で算出した終了日 */
  end: Scalars['String'];
  /** 企業とのリレーション */
  checkupOrganizationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** プランの「デフォルト」 */
  planId?: Maybe<Scalars['String']>;
  /** プラン負担者設定の「デフォルト」 */
  planBillType: Scalars['String'];
  /** 会社負担オプション設定 */
  expenseOptions: Array<Maybe<Scalars['String']>>;
  /** キット送付タイプの「デフォルト」 */
  kitDeliverType: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先の住所 */
  address: Scalars['String'];
  /** 希望開始月（年月） */
  desiredStartTerm: Scalars['String'];
  /** 希望終了月（年月） */
  desiredEndTerm: Scalars['String'];
  /** メモ */
  notedByOrganization?: Maybe<Scalars['String']>;
  /** 社内メモ */
  notedByAdmin?: Maybe<Scalars['String']>;
  /** 適用済みディスカウント */
  discountCode?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  startMonth?: Maybe<Scalars['Int']>;
  /** 組合 */
  union?: Maybe<Scalars['String']>;
};

export type UpdateCheckupOrganizationEventOptionInput = {
  id: Scalars['ID'];
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['String']>;
  billType: Scalars['String'];
  price: Scalars['Int'];
};

export type UpdateCheckupOrganizationEventTemplateInput = {
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachmentInput>;
};

export type UpdateCheckupOrganizationEventTemplateV2Input = {
  id: Scalars['ID'];
  /** 「案内」「リマインド」等。 */
  templateKind: TemplateKind;
  /** テンプレート本文 */
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  /** 年度とのリレーション */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  /** そのTemplateKind内でのデフォルトであるかどうか。 */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** リマインド実行スケジュールのデフォルト */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** タイトル（メールの場合） */
  title?: Maybe<Scalars['String']>;
  /** 識別名 */
  name?: Maybe<Scalars['String']>;
  attachments: Array<CheckupOrganizationEventTemplateAttachmentInput>;
};

export type UpdateCheckupOrganizationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  staffName?: Maybe<Scalars['String']>;
  staffTel?: Maybe<Scalars['String']>;
  staffEmail?: Maybe<Scalars['String']>;
  userCreatedAt?: Maybe<Scalars['String']>;
};

export type UpdateCheckupOrganizationMemberEventInput = {
  /** UID 「受診者番号」 */
  id: Scalars['ID'];
  /** 別システムでの一意キー */
  employeeNo?: Maybe<Scalars['String']>;
  /** 所属する年度 */
  checkupOrganizationEventId?: Maybe<Scalars['String']>;
  checkupOrganizationId?: Maybe<Scalars['String']>;
  /** 氏名 */
  name: Scalars['String'];
  /** メールアドレス + slackと照合する用のキー */
  email: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
  /** キット送付先住所 */
  address: Scalars['String'];
  /** 初回案内送付日時（= 確定日時） */
  invitedAt?: Maybe<Scalars['String']>;
  /** チャットツール上のハンドルネーム */
  chatName?: Maybe<Scalars['String']>;
  /** チャットツール上のID */
  chatId?: Maybe<Scalars['String']>;
  /** 実際に受診するプラン */
  plan?: Maybe<Scalars['String']>;
  /** プラン負担者 */
  planBillType?: Maybe<Scalars['String']>;
  /** 会社負担オプション設定 */
  expenseOptions: Array<Maybe<Scalars['String']>>;
  /** キット送付タイプ */
  kitDeliverType?: Maybe<Scalars['String']>;
  nextRemindOn?: Maybe<Scalars['String']>;
  /** 利用するテンプレート */
  template?: Maybe<Scalars['String']>;
  /** スケジュール設定（個別） */
  schedule?: Maybe<CheckupRemindScheduleInput>;
  /** 前回リマインド日 */
  recentRemindedAt?: Maybe<Scalars['String']>;
  /** slackでリマインドする */
  slackEnabled?: Maybe<Scalars['Boolean']>;
  /** メールでリマインドする */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  /** 受診予定期間 */
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  /** 事業所 */
  office?: Maybe<Scalars['String']>;
  /** 部門 */
  department?: Maybe<Scalars['String']>;
  /** 雇入年月日 */
  hiringDate?: Maybe<Scalars['String']>;
};

export type UpdateCheckupPlanIndividualInput = {
  id: Scalars['ID'];
  planId: Scalars['String'];
  terms: Array<CheckupPlanIndividualTermInput>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCheckupPlanInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  options: Array<Maybe<Scalars['String']>>;
  treatmentKindId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hideForOrganization?: Maybe<Scalars['Boolean']>;
  hideForIndividual?: Maybe<Scalars['Boolean']>;
  urinalysisKit?: Maybe<Scalars['Boolean']>;
  stoolKit?: Maybe<Scalars['Boolean']>;
};

export type UpdateCheckupReservationInput = {
  id: Scalars['String'];
  checkupTreatment: CreateTreatmentInput;
  dentalTreatment: CreateTreatmentInput;
  member: CheckupOrganizationMemberEventInput;
};

export type UpdateCheckupResultInput = {
  patientNo: Scalars['String'];
  patientName?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  label: Scalars['String'];
  valueStr?: Maybe<Scalars['String']>;
  valueNum?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  status: CheckupResultStatus;
  dataSource: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type UpdateCheckupResultItemGroupInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  friendlyName: Scalars['String'];
  technicalName: Scalars['String'];
};

export type UpdateCheckupResultItemInput = {
  id: Scalars['ID'];
  subTitle?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  mappingName?: Maybe<Scalars['String']>;
  mappingStrategy: CheckupResultItemMappingStrategy;
  unit?: Maybe<Scalars['String']>;
  criteria: Array<CheckupCriteriaInput>;
  order: Scalars['Int'];
  subGroup?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  ignore?: Maybe<Scalars['Boolean']>;
  displayStrategy?: Maybe<CheckupResultDisplayStrategy>;
  criteriaChart: Array<Maybe<CheckupResultCriteriaChartDefInput>>;
  pairName?: Maybe<Scalars['String']>;
  halfName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nurse?: Maybe<Scalars['Boolean']>;
  doctor?: Maybe<Scalars['Boolean']>;
  formQuestion?: Maybe<QuestionInput>;
};

export type UpdateCheckupResultItemSubGroupInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  group?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  strategy: CheckupResultSubGroupJudgeStrategy;
  conditions: Array<Maybe<CheckupResultSubGroupConditionInput>>;
  comments: Array<Maybe<CheckupResultSubGroupCommentSetInput>>;
};

export type UpdateCheckupResultSetInput = {
  /** CheckupAppointmentのID */
  id: Scalars['ID'];
  /** 取り込んだローデータ */
  raw: Array<CheckupResultRawDataInput>;
  /** 手入力データ */
  override: Array<CheckupResultRawDataInput>;
  /** 判定済みのデータ */
  judged?: Maybe<Array<CheckupResultJudgedSubGroupInput>>;
  /** 総合判定 */
  summary?: Maybe<CheckupResultSummaryInput>;
  /** 担当医の名前 */
  doctor?: Maybe<CheckupResultDoctorInput>;
  /** 検査日 */
  date: Scalars['String'];
  status: CheckupResultSetStatus;
};

export type UpdateCheckupUnionInput = {
  id: Scalars['ID'];
  unions: Array<Scalars['String']>;
};

export type UpdateClinicInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  bankInfo: BankInfoInput;
  mapUrl?: Maybe<Scalars['String']>;
  termsUrl?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
};

export type UpdateClinicMemoInput = {
  clinic: Scalars['ID'];
  date: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  id: Scalars['ID'];
  customerUserId: Scalars['String'];
  defaultCard?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
};

export type UpdateDentalEvaluationInput = {
  doctorId?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  evaluations: Array<Maybe<DentalEvaluationValueInput>>;
};

export type UpdateDentalEvaluationItemsInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  children: Array<Maybe<DentalEvaluationItemInput>>;
  createdAt: Scalars['String'];
};

export type UpdateDepartmentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<DepartmentType>;
  baseDuration?: Maybe<Scalars['Float']>;
  departmentClinicId?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  reserveInterval?: Maybe<Scalars['Int']>;
  editInterval?: Maybe<Scalars['Int']>;
  alertThreshold?: Maybe<Scalars['Int']>;
  orcaID: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  announcement?: Maybe<Scalars['String']>;
  requirement?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
};

export type UpdateDiscountCodeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  strategy: DiscountStrategyInput;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type UpdateDoctorInput = {
  id: Scalars['ID'];
  doctorDentalEvaluationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  clinic?: Maybe<Scalars['String']>;
};

export type UpdateDosageInput = {
  /** pharmacy#date#time#userId */
  id?: Maybe<Scalars['String']>;
  pharmacy: Scalars['ID'];
  date: Scalars['String'];
  userId: Scalars['String'];
  treatmentId?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  interview?: Maybe<InterviewInput>;
  status: DosageStatus;
  pharmacist?: Maybe<Scalars['String']>;
  feedback?: Maybe<DosageFeedbackInput>;
  visitingDatetime?: Maybe<Scalars['String']>;
};

export type UpdateDosagePrescriptionInput = {
  id: Scalars['ID'];
  key: Scalars['String'];
  dosageId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type UpdateDosageStatusInput = {
  id: Scalars['ID'];
  status: DosageStatus;
  pharmacist?: Maybe<Scalars['String']>;
};

export type UpdateDosageVisitingDatetimeInput = {
  id: Scalars['ID'];
  visitingDatetime: Scalars['String'];
};

export type UpdateEmployeeInput = {
  id: Scalars['ID'];
  employeeOrganizationId?: Maybe<Scalars['String']>;
  employeeUserId?: Maybe<Scalars['String']>;
  chatTool?: Maybe<ChatTool>;
  chatId?: Maybe<Scalars['String']>;
};

export type UpdateEventInstructionInput = {
  id?: Maybe<Scalars['ID']>;
  inChargeStaffId?: Maybe<Scalars['ID']>;
  instructorStaffId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  isDone?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
};

export type UpdateEventMembersInput = {
  eventId: Scalars['String'];
  members: Array<EventMemberInput>;
};

export type UpdateExtendedDictionaryInput = {
  key: Scalars['ID'];
  template: Scalars['String'];
  type: ExtendedDictionaryArgType;
  override: Array<ExtendedDictionaryOverrideInput>;
};

export type UpdateFeedbackInput = {
  id: Scalars['ID'];
  treatmentId: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  doctor?: Maybe<Scalars['String']>;
};

export type UpdateFollowupInput = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body: Scalars['String'];
  followupTreatmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
};

export type UpdateImportJobInput = {
  schema: Scalars['String'];
  name: Scalars['String'];
  startedAt: Scalars['String'];
  status: ImportJobStatus;
  hasError?: Maybe<Scalars['Boolean']>;
};

export type UpdateInstructionInput = {
  id: Scalars['ID'];
  /** 処置内容 */
  content: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
};

export type UpdateInterviewInput = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  firstVisit?: Maybe<Scalars['Boolean']>;
  questions: Array<Maybe<QuestionInput>>;
  submitted?: Maybe<Scalars['Boolean']>;
  pharmacy?: Maybe<Scalars['String']>;
  negative_or_positive?: Maybe<NegaPosiInput>;
  isRenewal?: Maybe<Scalars['Boolean']>;
};

export type UpdateLineBotMessageInput = {
  key: Scalars['String'];
  message: Scalars['String'];
  example?: Maybe<Scalars['String']>;
  selectClinic?: Maybe<Scalars['Boolean']>;
  childrenKeys: Array<Maybe<Scalars['String']>>;
};

export type UpdateLineMessageInput = {
  id: Scalars['ID'];
  lineMessageLineUserId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  messageText: Scalars['String'];
  createdAt: Scalars['String'];
  messageType: LineMessageSendType;
  accountType: Scalars['String'];
  alreadyReadLineMessageId?: Maybe<Scalars['String']>;
  latestLineMessageId?: Maybe<Scalars['String']>;
  lineMessageUserThreadId?: Maybe<Scalars['String']>;
  /** jsonだけどぶっこむ */
  event?: Maybe<Scalars['String']>;
  attachmentId?: Maybe<Scalars['String']>;
  openedByUserAt?: Maybe<Scalars['String']>;
  /** どこ経由か。必ずオール小文字。現在は zendesk と search。 */
  via?: Maybe<Scalars['String']>;
  searchGroupId?: Maybe<Scalars['String']>;
  origin?: Maybe<LineMessageOrigin>;
};

export type UpdateLineMessageOpenedByUserAtInput = {
  id: Scalars['String'];
  openedByUserAt: Scalars['String'];
};

export type UpdateLineMessageTemplateInput = {
  key: LineTemplateKey;
  clinic: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type UpdateLineReadStatusForAlreadyReadInput = {
  key: ModelLineReadStatusIdCompositeKeyInput;
  alreadyReadLineMessageId?: Maybe<Scalars['String']>;
};

export type UpdateLineReadStatusForReceiveInput = {
  key: ModelLineReadStatusIdCompositeKeyInput;
  latestLineMessageId?: Maybe<Scalars['String']>;
};

export type UpdateLineUserInput = {
  id: Scalars['ID'];
  lineUserUserId?: Maybe<Scalars['String']>;
  accounts: Array<Maybe<Scalars['String']>>;
};

export type UpdateLocationGroupInput = {
  id: Scalars['ID'];
  /** ロケーショングループ名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** クリニックID */
  clinicId: Scalars['String'];
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  /** ロケーショングループ情報 */
  groupId: Scalars['ID'];
  /** ロケーション名 */
  name: Scalars['String'];
  /** クリニックID */
  clinicId: Scalars['String'];
  /** 部屋のタイプ waiting: 待合室, examination: 処置室 */
  roomType: Scalars['String'];
  /** 上から何番目かの表示位置 */
  top: Scalars['Int'];
  /** 左から何番目かの表示位置 */
  left: Scalars['Int'];
};

export type UpdateMedicalQuestionnaireInterviewAnswersInput = {
  id: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  beforeReserve?: Maybe<InterviewAnswerStatusInput>;
  afterReserve?: Maybe<InterviewAnswerStatusInput>;
};

export type UpdateMedicalQuestionnaireInterviewInput = {
  domainIdentifier: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  interviewTime: InterviewTimeType;
  order?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  answerChoices: Array<InterviewAnswerChoiceInput>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  previousInterviews?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultExit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateMedicalQuestionnaireInterviewListInput = {
  updateActionType: MedicalQuestionnaireInterviewUpdateActionType;
  medicalQuestionnaireInterviewList: Array<Maybe<UpdateMedicalQuestionnaireInterviewInput>>;
};

export type UpdateMedicalQuestionnaireInterviewListResult = {
  __typename?: 'updateMedicalQuestionnaireInterviewListResult';
  success: Scalars['Boolean'];
  medicalQuestionnaireInterviewList?: Maybe<Array<Maybe<MedicalQuestionnaireInterviewResult>>>;
};

export type UpdateMedicalQuestionnaireSurveyAnswersInput = {
  id: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  answers?: Maybe<SurveyAnswerStatusInput>;
};

export type UpdateMedicalQuestionnaireSurveyInput = {
  id?: Maybe<Scalars['String']>;
  surveyTime: SurveyTimeType;
  order: Scalars['Int'];
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  answerChoices: Array<Maybe<SurveyAnswerChoiceInput>>;
  disable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  medicalRecordTitle?: Maybe<Scalars['String']>;
  medicalRecordText?: Maybe<Scalars['String']>;
  choiceType: ChoiceType;
  medicalQuestionnaireSurvey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateNonceInput = {
  nonce: Scalars['String'];
  userId: Scalars['String'];
  issueAt: Scalars['String'];
  expireAt: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type UpdateOpenMonthInput = {
  yearMonth: Scalars['String'];
  open?: Maybe<Scalars['Boolean']>;
  clinic: Scalars['String'];
};

export type UpdateOrcaPatientInput = {
  orcaID: Scalars['ID'];
  orcaPatientPatientId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  chatTool?: Maybe<ChatTool>;
  chatId?: Maybe<Scalars['String']>;
};

export type UpdatePatientCommentInput = {
  id: Scalars['ID'];
  patient?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  createdAt: Scalars['String'];
};

export type UpdatePatientInput = {
  id: Scalars['ID'];
  patientUserId?: Maybe<Scalars['String']>;
  patientNo?: Maybe<Scalars['String']>;
  orcaID?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  seikana?: Maybe<Scalars['String']>;
  meikana?: Maybe<Scalars['String']>;
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  anyoneStaff?: Maybe<Scalars['Boolean']>;
  staffId?: Maybe<Scalars['String']>;
  /** 薬科の患者番号 */
  pharmacyPatientNo?: Maybe<Scalars['String']>;
};

export type UpdatePatientTagAssociationInput = {
  patient?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
};

export type UpdatePatientTagInput = {
  tag: Scalars['ID'];
  displayName: Scalars['String'];
  color: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type UpdatePaymentInput = {
  date: Scalars['String'];
  time: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  paymentTreatmentId?: Maybe<Scalars['String']>;
};

export type UpdatePaymentStateInput = {
  executionId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentJobType>;
  state?: Maybe<Scalars['String']>;
  payjpID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type UpdatePaymentStateMachineInput = {
  date: Scalars['String'];
  userId: Scalars['String'];
  department: Scalars['String'];
  executionId: Scalars['String'];
  time: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type UpdatePharmacistInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdatePharmacyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateProfileInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  mail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  pref?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Array<Scalars['String']>>;
  insuranceCards?: Maybe<Array<InsuranceCardInput>>;
};

export type UpdateRemindActionInput = {
  id: Scalars['ID'];
  remindActionUserId: Scalars['String'];
  remindActionTreatmentId?: Maybe<Scalars['String']>;
  dosageId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  remindActionType?: Maybe<RemindActionType>;
  remindActionStatus?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type UpdateRemindActionLogInput = {
  id: Scalars['ID'];
  remindActionLogUserId?: Maybe<Scalars['String']>;
  remindActionLogTreatmentId?: Maybe<Scalars['String']>;
  dosageId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  createdAt: Scalars['String'];
};

export type UpdateRemindActionPlanInput = {
  date: Scalars['ID'];
  dosageId?: Maybe<Scalars['String']>;
  type?: Maybe<RemindActionType>;
  status?: Maybe<RemindActionStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type UpdateRemindActionStatusInput = {
  id: Scalars['ID'];
  remindActionStatus?: Maybe<RemindActionStatus>;
};

export type UpdateReviewInput = {
  schema: ReviewSchema;
  createdAt: Scalars['String'];
  answers: Array<KeyValueInput>;
  userId: Scalars['String'];
};

export type UpdateRoomInput = {
  clinic?: Maybe<Scalars['String']>;
  roomType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateRoomVacancyInput = {
  roomType: Scalars['String'];
  roomNo: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatmentId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateSearchConditionInput = {
  type: Scalars['ID'];
  clinic: Scalars['String'];
  createdAt: Scalars['String'];
  title: Scalars['String'];
  treatment?: Maybe<SearchTreatmentSettingInput>;
  patient?: Maybe<PatientSearchSettingInput>;
};

export type UpdateSentMessageGroupBySearchInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  mailSubject: Scalars['String'];
  templateMessage: Scalars['String'];
  searchCondition: SentMessageSearchConditionInput;
  targetUserIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  createdUserName: Scalars['String'];
};

export type UpdateShiftInput = {
  clinic: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  date: Scalars['String'];
  doctor?: Maybe<Scalars['String']>;
  laneId?: Maybe<Scalars['String']>;
};

export type UpdateShiftManualInput = {
  clinic: Scalars['String'];
  laneId: Scalars['String'];
  date: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  doctors: Array<Scalars['String']>;
};

export type UpdateSlackNonceInput = {
  id: Scalars['ID'];
  slackId: Scalars['String'];
};

export type UpdateSoapInfoInput = {
  id: Scalars['ID'];
  treatmentId: Scalars['String'];
  subjectComment?: Maybe<Scalars['String']>;
  sOAPInfoDoctorId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateStaffInput = {
  role: StaffRoleType;
  staffId: Scalars['String'];
  enable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  enableTreatmentKindIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  iconSrc?: Maybe<Scalars['String']>;
};

export type UpdateStaffShiftInput = {
  clinic?: Maybe<Scalars['String']>;
  staff?: Maybe<Scalars['String']>;
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  failure?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateStaffVacancyInput = {
  clinic?: Maybe<Scalars['String']>;
  staff: Scalars['String'];
  yearMonth: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  vacancyStatus: VacancyStatus;
  treatmentId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentChecklistInput = {
  id: Scalars['String'];
  checklist: TreatmentChecklistInput;
};

export type UpdateTreatmentCommentInput = {
  id: Scalars['String'];
  reservationMemo: Scalars['String'];
};

export type UpdateTreatmentDetailStatus = {
  id: Scalars['String'];
  detailedStatus: Scalars['String'];
};

export type UpdateTreatmentEditConditionInput = {
  id: Scalars['String'];
  editCondition?: Maybe<Array<TreatmentEditConditionInput>>;
  reservableFrom?: Maybe<Scalars['String']>;
  physician?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentFieldsInput = {
  id: Scalars['String'];
  estimatedEnd?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  physician?: Maybe<Scalars['String']>;
  holdedAt?: Maybe<Scalars['String']>;
  yearMonth?: Maybe<Scalars['String']>;
  orcaReservationId?: Maybe<Scalars['String']>;
  orcaAcceptanceId?: Maybe<Scalars['String']>;
  treatmentSOAPInfoId?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  editCondition?: Maybe<Array<TreatmentEditConditionInput>>;
  checkupOrganizationMemberEventId?: Maybe<Scalars['String']>;
  lumeccaConsecutiveId?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  reservationMemo?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  lateContactFlag?: Maybe<Scalars['Boolean']>;
  /** ユーザーから隠す */
  hidden?: Maybe<Scalars['Boolean']>;
  /** ユーザー表示用の所要時間 */
  displayDuration?: Maybe<Scalars['Float']>;
  /** ディスカウントコード */
  discountCode?: Maybe<Scalars['String']>;
  vaccineId?: Maybe<Scalars['String']>;
  noInterview?: Maybe<Scalars['Boolean']>;
  /** オンライン診療フラグ */
  online?: Maybe<Scalars['Boolean']>;
  /** レーンID */
  laneId?: Maybe<Scalars['String']>;
  reservableFrom?: Maybe<Scalars['String']>;
  preInterview?: Maybe<CreateInterviewInput>;
  interview?: Maybe<CreateInterviewInput>;
  detailedStatus?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  checklist?: Maybe<TreatmentChecklistInput>;
  /** 課金不要事由 */
  noChargeReason?: Maybe<NoChargeReason>;
  noChargeReasonDetail?: Maybe<Scalars['String']>;
  cancelPolicyRevision?: Maybe<Scalars['String']>;
  /** 診療開始時間(DateTime) */
  startedAt?: Maybe<Scalars['String']>;
  /** 診療終了時間(DateTime) */
  endedAt?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentKindInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  course?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  /** ワクチン制御の対象かどうか */
  vaccine?: Maybe<Scalars['Boolean']>;
  /** 複数回受診するワクチンの場合、シークエンスの設定 */
  vaccineSequence?: Maybe<VaccineSequenceInput>;
  /** リコール自動送信用のメッセージテンプレート */
  remindTemplate?: Maybe<Scalars['String']>;
  /** 診療科の重複の許可 */
  permitDuplicateDepartment?: Maybe<Scalars['Boolean']>;
  noInterview?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['String']>;
  interviewName?: Maybe<Scalars['String']>;
  estimatedDuration?: Maybe<Scalars['Float']>;
  revisit?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  /** Department.announcement をオーバーライドできる。 */
  announcement?: Maybe<Scalars['String']>;
  /** Department.requirement をオーバーライドできる。 */
  requirement?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  /** ユーザーに表示する長さ */
  displayDuration?: Maybe<Scalars['Int']>;
  /** 新空き枠用 */
  operation: Array<Maybe<OperationInput>>;
  requiredReservationFee?: Maybe<Scalars['Boolean']>;
  cancelPolicies?: Maybe<Array<Maybe<CancelPolicyInput>>>;
};

export type UpdateTreatmentLaneInput = {
  id: Scalars['String'];
  laneId: Scalars['String'];
};

export type UpdateTreatmentLateContactFlagInput = {
  id: Scalars['String'];
  lateContactFlag: Scalars['Boolean'];
};

export type UpdateTreatmentNoChargeInput = {
  id: Scalars['String'];
  noChargeReason?: Maybe<NoChargeReason>;
  noChargeReasonDetail?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentPharmacyInput = {
  id: Scalars['String'];
  pharmacy: Scalars['String'];
};

export type UpdateTreatmentProgressHistoryInput = {
  /** ID */
  historyId: Scalars['ID'];
  /** treatmentProgressテーブルとの紐付けをするためのID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId?: Maybe<Scalars['ID']>;
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 退室日時 */
  exitAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstructionInput>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentProgressInput = {
  /** ID */
  treatmentProgressId: Scalars['ID'];
  /** 作成日時 */
  createdAt?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 処置ID */
  treatmentId?: Maybe<Scalars['ID']>;
  /** クリニック情報 */
  clinicId?: Maybe<Scalars['ID']>;
  /** ロケーション情報 */
  locationId?: Maybe<Scalars['ID']>;
  /** ロケーショングループ情報 */
  locationGroupId?: Maybe<Scalars['ID']>;
  /** 来院日時 */
  visitAt?: Maybe<Scalars['String']>;
  /** 入室日時 */
  entryAt?: Maybe<Scalars['String']>;
  /** 患者情報 */
  patientId?: Maybe<Scalars['ID']>;
  /** 診察ステータス */
  treatmentProgressStatus?: Maybe<Scalars['String']>;
  /** 待ち状態 */
  isWaiting?: Maybe<Scalars['Boolean']>;
  /** 状態の開始時間 */
  treatmentProgressStatusStartedAt?: Maybe<Scalars['String']>;
  /** オーダー(対応内容 / 指示) 技師対応、看護対応、内診、エコーなど */
  instructions?: Maybe<Array<Maybe<EventInstructionInput>>>;
  /** メモ */
  memo?: Maybe<Scalars['String']>;
};

export type UpdateTreatmentProgressNextStatusAssociationInput = {
  statusId: Scalars['String'];
  nextStatusId: Scalars['String'];
};

export type UpdateTreatmentProgressStatusInput = {
  id: Scalars['ID'];
  /** 診察ステータス名 */
  name: Scalars['String'];
  /** 表示順 */
  order: Scalars['Int'];
  /** 診察ステータスを上位表示する役職。役職によって表示順が変わるため。 */
  preferredRoles: Array<Maybe<Scalars['String']>>;
  /** 進行中、待機中を判別するためのステータス */
  status: Scalars['String'];
};

export type UpdateTreatmentRoom = {
  id: Scalars['String'];
  detailedStatus: Scalars['String'];
  room: Scalars['String'];
};

export type UpdateTreatmentSendRemindInput = {
  id: Scalars['String'];
  sendRemind: Scalars['Boolean'];
};

export type UpdateTreatmentStatusInput = {
  id: Scalars['String'];
  status: TreatmentStatus;
};

/**
 * 患者詳細画面で住所のみを更新したいため、
 * updateの必須項目を最低限のidのみにする
 */
export type UpdateUserInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Sex>;
  mail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  pref?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  userPatientId?: Maybe<Scalars['String']>;
  userCustomerId?: Maybe<Scalars['String']>;
  userLineUserId?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Array<Scalars['String']>>;
  /**
   * 保険証（複数）
   * フィールドリゾルバーとして、ダウンロード可能なURLを返すlambdaをアタッチする。
   * 永続層としてはkeyを持つ。
   */
  insuranceCards?: Maybe<Array<Maybe<InsuranceCardInput>>>;
  profession?: Maybe<Scalars['String']>;
  insuranceCardCheckedAt?: Maybe<Scalars['String']>;
  penaltyCount?: Maybe<Scalars['Int']>;
  accountRestricted?: Maybe<Scalars['Boolean']>;
  introducer?: Maybe<Scalars['String']>;
};

export type UpdateUserLineUserIdInput = {
  id: Scalars['ID'];
  userLineUserId: Scalars['String'];
};

export type UpdateUserReserveConditionInput = {
  userId: Scalars['ID'];
  treatmentKind: Scalars['String'];
  start: Scalars['String'];
  conditions?: Maybe<Array<Maybe<TreatmentEditConditionInput>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  remindOn?: Maybe<Scalars['String']>;
  remindedAt?: Maybe<Scalars['String']>;
  reservationMemo?: Maybe<Scalars['String']>;
  reservableFrom?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  remindTemplate?: Maybe<Scalars['String']>;
};

export type UpdateUserThreadInput = {
  /** lineMessageIdまたはメールのID */
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  /** LINE, MAIL */
  messageType: Scalars['String'];
  startMessageCreatedAt: Scalars['String'];
  name: Scalars['String'];
  userThreadClinicId?: Maybe<Scalars['String']>;
  correspondingType: UserThreadCorrespondingType;
  status: UserThreadStatus;
  userThreadLineMessageIds: Array<Maybe<Scalars['String']>>;
  userThreadLineReadStatusId?: Maybe<Scalars['String']>;
};

export type UpdateUserThreadMessageIdsInput = {
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  messageType: Scalars['String'];
  startMessageCreatedAt: Scalars['String'];
  userThreadLineMessageIds: Array<Maybe<Scalars['String']>>;
};

export type UpdateUserThreadStatusAndNameInput = {
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  messageType: Scalars['String'];
  startMessageCreatedAt: Scalars['String'];
  name: Scalars['String'];
  status: UserThreadStatus;
  userThreadClinicId?: Maybe<Scalars['String']>;
  correspondingType?: Maybe<UserThreadCorrespondingType>;
};

export type UpdateVacanciesInput = {
  clinic?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  vacancies: Array<Maybe<VacancyInput>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UpdateVacancyConfigSetInput = {
  id: Scalars['ID'];
  clinicId: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  set?: Maybe<VacancySetInput>;
};

export type UpdateVacancyLaneInput = {
  id: Scalars['ID'];
  clinic?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  unit: Array<Maybe<Scalars['String']>>;
  defaultTotal: Scalars['Int'];
  duration: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type UpdateVacancyNew = {
  __typename?: 'UpdateVacancyNew';
  date?: Maybe<Scalars['String']>;
};

export type UpdateVacancyNewInput = {
  treatmentId: Scalars['String'];
  treatmentKindId?: Maybe<Scalars['String']>;
  reserveDate?: Maybe<Scalars['String']>;
  reserveTime?: Maybe<Scalars['String']>;
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  assignedRoom?: Maybe<Array<Maybe<RoomVacancyInput>>>;
  assignedStaff?: Maybe<Array<Maybe<AssignedStaffToVacancyInput>>>;
};

export type UpdateVacancySpacerInput = {
  clinic: Scalars['String'];
  date: Scalars['String'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export type UpdateVacancySpacerTemplateInput = {
  clinic: Scalars['String'];
  week: Scalars['Int'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export type UpdateVacancyTemplateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  vacancies: Array<Maybe<VacancyInput>>;
  conditions: Array<Maybe<VacancyTemplateConditionInput>>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateVacancyUnitInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  /** 空き枠編集の並び順。100単位で分かれる */
  order?: Maybe<Scalars['Int']>;
  departmentId?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDuration?: Maybe<Scalars['Float']>;
  online?: Maybe<Scalars['Boolean']>;
  lanePreference?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateVaccineEventInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 開始日付YYYY-MM-DD */
  start: Scalars['String'];
  /** 終了日付YYYY-MM-DD */
  end: Scalars['String'];
  treatmentKind?: Maybe<Scalars['String']>;
  /** 招待テンプレート */
  invitationTemplate?: Maybe<Scalars['String']>;
  /** 予約可能期間開始日YYYY-MM-DD */
  reserveTermStart?: Maybe<Scalars['String']>;
  /** 予約可能期間終了日YYYY-MM-DD */
  reserveTermEnd?: Maybe<Scalars['String']>;
};

export type UpdateVaccinePatientInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  belongs?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  /** ワクチン接種シークエンスを管理する一貫したID。 */
  sequenceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  invitationSentAt?: Maybe<Scalars['String']>;
};

export type UpdateWaitingListItemInput = {
  clinic?: Maybe<Scalars['String']>;
  start: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  treatmentKind?: Maybe<Scalars['String']>;
  end: Scalars['String'];
  userId: Scalars['String'];
  vacancyUnit?: Maybe<Scalars['String']>;
};

export type UpdateZendeskMessageInput = {
  type: Scalars['String'];
  createdAt: Scalars['String'];
  message: Scalars['String'];
  messageId: Scalars['String'];
  from: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  sei?: Maybe<Scalars['String']>;
  mei?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  mail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  pref?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  customer?: Maybe<Customer>;
  treatment: ModelTreatmentConnection;
  employee: ModelEmployeeConnection;
  payment: ModelPaymentConnection;
  line?: Maybe<LineUser>;
  confirmed?: Maybe<Array<Scalars['String']>>;
  /** 予約診療費の同意をクリニック単位で保持 */
  confirmedReservationFee?: Maybe<Array<ConfirmedReservationFeeItem>>;
  /**
   * 保険証（複数）
   * フィールドリゾルバーとして、ダウンロード可能なURLを返すlambdaをアタッチする。
   * 永続層としてはkeyを持つ。
   */
  insuranceCards?: Maybe<Array<Maybe<InsuranceCard>>>;
  profession?: Maybe<Scalars['String']>;
  waitingList: ModelWaitingListItemConnection;
  insuranceCardCheckedAt?: Maybe<Scalars['String']>;
  penaltyCount?: Maybe<Scalars['Int']>;
  accountRestricted?: Maybe<Scalars['Boolean']>;
  introducer?: Maybe<Scalars['String']>;
};


export type UserTreatmentArgs = {
  filter?: Maybe<ModelTreatmentFilterInput>;
  statusDate?: Maybe<ModelUserTreatmentCompositeKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type UserEmployeeArgs = {
  filter?: Maybe<ModelEmployeeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type UserPaymentArgs = {
  filter?: Maybe<ModelPaymentFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type UserWaitingListArgs = {
  filter?: Maybe<ModelWaitingListItemFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

/** 再診予約設定 / 治療計画 */
export type UserReserveCondition = {
  __typename?: 'UserReserveCondition';
  userId: Scalars['ID'];
  treatmentKind: Scalars['String'];
  start: Scalars['String'];
  conditions: Array<TreatmentEditCondition>;
  disabled?: Maybe<Scalars['Boolean']>;
  remindOn?: Maybe<Scalars['String']>;
  remindedAt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  treatmentKindMst?: Maybe<TreatmentKind>;
  reservationMemo?: Maybe<Scalars['String']>;
  remindTemplate?: Maybe<Scalars['String']>;
  /** 予約開始可能日 */
  reservableFrom?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UserThread = {
  __typename?: 'UserThread';
  /** lineUserId */
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  messageType: InteractionMessageType;
  startMessageCreatedAt: Scalars['String'];
  name: Scalars['String'];
  /** 医科か歯科かを判定する。事務の場合はnull */
  clinic?: Maybe<Clinic>;
  correspondingType: UserThreadCorrespondingType;
  status: UserThreadStatus;
  lineMessages: ModelLineMessageConnection;
  lineReadStatus?: Maybe<LineReadStatus>;
};


export type UserThreadLineMessagesArgs = {
  filter?: Maybe<ModelLineMessageFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserThreadByDateInput = {
  userId: Scalars['ID'];
  accountType: Scalars['String'];
  messageType: Scalars['String'];
  startMessageCreatedAt?: Maybe<ModelStringConditionInput>;
  limit?: Maybe<Scalars['Int']>;
};

/** 医師対応か、医療事務対応か */
export enum UserThreadCorrespondingType {
  Doctor = 'DOCTOR',
  Assistant = 'ASSISTANT'
}

export enum UserThreadStatus {
  Init = 'INIT',
  Started = 'STARTED',
  Resolved = 'RESOLVED'
}

/**
 * 空き枠のモデル。
 * 強い整合性のため、一つのエンティティで当該クリニックのその日の全体の空き枠を表現する。
 */
export type Vacancies = {
  __typename?: 'Vacancies';
  clinic?: Maybe<Clinic>;
  date: Scalars['String'];
  vacancies?: Maybe<Array<Maybe<Vacancy>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

/**
 * 空き枠のコアなモデル。
 * total/availables が、それぞれ空き枠のキャップと残りの枠数を管理する。
 * duration/ remainDuration が、それぞれ空き枠の長さと残りの長さを管理する。
 * availables または remainDurationがゼロになったら、その枠は入れない。
 */
export type Vacancy = {
  __typename?: 'Vacancy';
  id: Scalars['ID'];
  date: Scalars['String'];
  time: Scalars['String'];
  unit: Array<Scalars['String']>;
  total: Scalars['Int'];
  availables: Scalars['Int'];
  clinic?: Maybe<Scalars['String']>;
  lane: Scalars['String'];
  duration: Scalars['Float'];
  remainDuration: Scalars['Float'];
  treatment: ModelTreatmentConnection;
};

/**
 * 空き枠設定のデフォルトを格納する。
 * 便利機能にすぎない
 */
export type VacancyConfigSet = {
  __typename?: 'VacancyConfigSet';
  id: Scalars['ID'];
  clinicId: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  set?: Maybe<VacancySet>;
};

export type VacancyInput = {
  id: Scalars['ID'];
  date: Scalars['String'];
  time: Scalars['String'];
  unit: Array<Scalars['String']>;
  total: Scalars['Int'];
  availables: Scalars['Int'];
  clinic?: Maybe<Scalars['String']>;
  lane: Scalars['String'];
  duration: Scalars['Float'];
  remainDuration: Scalars['Float'];
  treatment?: Maybe<Array<Scalars['String']>>;
};

export type VacancyLane = {
  __typename?: 'VacancyLane';
  id: Scalars['ID'];
  clinic?: Maybe<Clinic>;
  name: Scalars['String'];
  start: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  unit?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultTotal: Scalars['Int'];
  duration: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type VacancySet = {
  __typename?: 'VacancySet';
  units: Array<Scalars['String']>;
};

export type VacancySetInput = {
  units: Array<Scalars['String']>;
};

export type VacancySpacer = {
  __typename?: 'VacancySpacer';
  clinic: Scalars['String'];
  date: Scalars['String'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export type VacancySpacerTemplate = {
  __typename?: 'VacancySpacerTemplate';
  clinic: Scalars['String'];
  week: Scalars['Int'];
  laneId: Scalars['String'];
  spacer: Array<Maybe<Scalars['String']>>;
};

export enum VacancyStatus {
  Vacant = 'VACANT',
  Wait = 'WAIT',
  Full = 'FULL'
}

export type VacancyTemplate = {
  __typename?: 'VacancyTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  vacancies?: Maybe<Array<Maybe<Vacancy>>>;
  conditions?: Maybe<Array<Maybe<VacancyTemplateCondition>>>;
  order?: Maybe<Scalars['Int']>;
};

export type VacancyTemplateCondition = {
  __typename?: 'VacancyTemplateCondition';
  strategy?: Maybe<VacancyTemplateConditionStrategy>;
  day?: Maybe<Scalars['Int']>;
};

export type VacancyTemplateConditionInput = {
  strategy?: Maybe<VacancyTemplateConditionStrategy>;
  day?: Maybe<Scalars['Int']>;
};

export enum VacancyTemplateConditionStrategy {
  Day = 'DAY'
}

/**
 * 空き枠認識単位マスタ
 * defaultDurationで、消費予定Durationポイントを指定する。
 */
export type VacancyUnit = {
  __typename?: 'VacancyUnit';
  id: Scalars['ID'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  /** 空き枠編集の並び順。100単位で分かれる */
  order?: Maybe<Scalars['Int']>;
  departmentId: Scalars['String'];
  treatmentKind: Scalars['String'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDuration: Scalars['Float'];
  online?: Maybe<Scalars['Boolean']>;
  lanePreference?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VacancyUnitCalculation = {
  clinic: Scalars['String'];
  departmentId: Scalars['String'];
  treatmentKind: Scalars['String'];
  options: Array<Scalars['String']>;
  fromDate: Scalars['String'];
  fromTime: Scalars['String'];
  toDate: Scalars['String'];
  toTime: Scalars['String'];
  editCondition?: Maybe<Array<TreatmentEditConditionInput>>;
  ignoreLimit?: Maybe<Scalars['Boolean']>;
  vaccinePatientId?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
};

/** ワクチン集団接種イベント */
export type VaccineEvent = {
  __typename?: 'VaccineEvent';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 開始日付YYYY-MM-DD */
  start: Scalars['String'];
  /** 終了日付YYYY-MM-DD */
  end: Scalars['String'];
  treatmentKind?: Maybe<TreatmentKind>;
  patients: ModelVaccinePatientConnection;
  /** 招待テンプレート */
  invitationTemplate?: Maybe<Scalars['String']>;
  /** 予約可能期間開始日YYYY-MM-DD */
  reserveTermStart?: Maybe<Scalars['String']>;
  /** 予約可能期間終了日YYYY-MM-DD */
  reserveTermEnd?: Maybe<Scalars['String']>;
};


/** ワクチン集団接種イベント */
export type VaccineEventPatientsArgs = {
  filter?: Maybe<ModelVaccinePatientFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

/** ワクチン接種患者 */
export type VaccinePatient = {
  __typename?: 'VaccinePatient';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
  event?: Maybe<VaccineEvent>;
  belongs?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  /** ワクチン接種シークエンスを管理する一貫したID。 */
  sequenceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  invitationSentAt?: Maybe<Scalars['String']>;
};

/** ワクチンのシークエンスを管理する設定 */
export type VaccineSequence = {
  __typename?: 'VaccineSequence';
  /** その処置で終わりかどうか */
  end?: Maybe<Scalars['Boolean']>;
  /** 前回の処置に依存するのであれば、どう依存するか */
  previous?: Maybe<VaccineSequenceCondition>;
};

/** ワクチンのシークエンスの具体的な設定 */
export type VaccineSequenceCondition = {
  __typename?: 'VaccineSequenceCondition';
  /** TreatmentKindのID */
  dependsOn?: Maybe<Scalars['String']>;
  /** 前回のdependsOnの間隔（日数） */
  intervalInDays?: Maybe<Scalars['Int']>;
};

export type VaccineSequenceConditionInput = {
  /** TreatmentKindのID */
  dependsOn?: Maybe<Scalars['String']>;
  /** 前回のdependsOnの間隔（日数） */
  intervalInDays?: Maybe<Scalars['Int']>;
};

export type VaccineSequenceInput = {
  /** その処置で終わりかどうか */
  end?: Maybe<Scalars['Boolean']>;
  /** 前回の処置に依存するのであれば、どう依存するか */
  previous?: Maybe<VaccineSequenceConditionInput>;
};

export type ValidateResult = {
  __typename?: 'ValidateResult';
  valid: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type ValidateVaccinePasswordInput = {
  vaccinePatientId: Scalars['String'];
  treatmentKind: Scalars['String'];
};

export type WaitingListItem = {
  __typename?: 'WaitingListItem';
  clinic?: Maybe<Clinic>;
  start: Scalars['String'];
  department?: Maybe<Department>;
  treatmentKind?: Maybe<TreatmentKind>;
  end: Scalars['String'];
  userId: Scalars['String'];
  vacancyUnit?: Maybe<VacancyUnit>;
  user?: Maybe<User>;
};

export type ZendeskMessage = {
  __typename?: 'ZendeskMessage';
  type: Scalars['String'];
  createdAt: Scalars['String'];
  message: Scalars['String'];
  messageId: Scalars['String'];
  from: Scalars['String'];
  ttl: Scalars['Int'];
  lineUser?: Maybe<LineUser>;
};

export type CancelPoliciesTypeFragment = (
  { __typename?: 'TreatmentKind' }
  & { cancelPolicies?: Maybe<Array<Maybe<(
    { __typename?: 'CancelPolicy' }
    & Pick<CancelPolicy, 'greetingText' | 'revisedAt'>
    & { cancelPenaltyPolicy: Array<(
      { __typename?: 'CancelPenaltyCondition' }
      & Pick<CancelPenaltyCondition, 'difference' | 'feeAmount' | 'timeToBan' | 'unit'>
    )>, cancelableUntil: (
      { __typename?: 'CancelableCondition' }
      & Pick<CancelableCondition, 'difference' | 'unit'>
    ) }
  )>>> }
);

export type CheckupMemberEventTypeFragment = (
  { __typename?: 'CheckupOrganizationMemberEvent' }
  & Pick<CheckupOrganizationMemberEvent, 'id' | 'name' | 'email' | 'address' | 'hiringDate' | 'invitedAt' | 'planBillType' | 'kitDeliverType' | 'expenseOptions'>
  & { appointments: (
    { __typename?: 'ModelCheckupAppointmentConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupAppointment' }
      & Pick<CheckupAppointment, 'id' | 'treatmentStatus'>
      & { treatment: (
        { __typename?: 'ModelTreatmentConnection' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Treatment' }
          & Pick<Treatment, 'id' | 'status'>
          & { department?: Maybe<(
            { __typename?: 'Department' }
            & Pick<Department, 'id'>
          )> }
        )>>> }
      ) }
    )>>> }
  ), plan?: Maybe<(
    { __typename?: 'CheckupPlan' }
    & Pick<CheckupPlan, 'id' | 'label' | 'price' | 'treatmentKindId' | 'options'>
    & { treatmentKind?: Maybe<(
      { __typename?: 'TreatmentKind' }
      & CancelPoliciesTypeFragment
    )> }
  )>, event?: Maybe<(
    { __typename?: 'CheckupOrganizationEvent' }
    & Pick<CheckupOrganizationEvent, 'expenseOptions' | 'discountCode' | 'planBillType' | 'kitDeliverType' | 'id' | 'address' | 'union'>
    & { plan?: Maybe<(
      { __typename?: 'CheckupPlan' }
      & Pick<CheckupPlan, 'id' | 'label' | 'price' | 'treatmentKindId' | 'options'>
      & { treatmentKind?: Maybe<(
        { __typename?: 'TreatmentKind' }
        & CancelPoliciesTypeFragment
      )> }
    )>, organization?: Maybe<(
      { __typename?: 'CheckupOrganization' }
      & Pick<CheckupOrganization, 'id' | 'name'>
    )>, options: (
      { __typename?: 'ModelCheckupOrganizationEventOptionConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'CheckupOrganizationEventOption' }
        & Pick<CheckupOrganizationEventOption, 'price' | 'billType'>
        & { option?: Maybe<(
          { __typename?: 'CheckupOption' }
          & Pick<CheckupOption, 'id' | 'label' | 'regularPrice' | 'description' | 'gender' | 'aboutDisplay' | 'annotation' | 'clinicType' | 'requiredMin' | 'order'>
          & { treatmentKind?: Maybe<(
            { __typename?: 'TreatmentKind' }
            & Pick<TreatmentKind, 'id' | 'name'>
          )> }
        )> }
      )>>> }
    ) }
  )> }
);

export type CheckupResultItemGroupTypeFragment = (
  { __typename?: 'CheckupResultItemGroup' }
  & Pick<CheckupResultItemGroup, 'id' | 'technicalName' | 'friendlyName' | 'order'>
  & { subGroups: (
    { __typename?: 'ModelCheckupResultItemSubGroupConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupResultItemSubGroup' }
      & CheckupResultItemSubGroupTypeFragment
    )>>> }
  ) }
);

export type CheckupResultItemSubGroupTypeFragment = (
  { __typename?: 'CheckupResultItemSubGroup' }
  & Pick<CheckupResultItemSubGroup, 'id' | 'name' | 'description' | 'order'>
  & { group?: Maybe<(
    { __typename?: 'CheckupResultItemGroup' }
    & Pick<CheckupResultItemGroup, 'id' | 'friendlyName'>
  )> }
);

export type CheckupResultJudgedSubGroupTypeFragment = (
  { __typename?: 'CheckupResultJudgedSubGroup' }
  & Pick<CheckupResultJudgedSubGroup, 'result' | 'comment' | 'subGroupId' | 'departmentId'>
  & { subGroup?: Maybe<(
    { __typename?: 'CheckupResultItemSubGroup' }
    & CheckupResultItemSubGroupTypeFragment
  )>, items?: Maybe<Array<Maybe<(
    { __typename?: 'CheckupResultJudged' }
    & Pick<CheckupResultJudged, 'result' | 'itemId' | 'valueStr'>
    & { item?: Maybe<(
      { __typename?: 'CheckupResultItem' }
      & Pick<CheckupResultItem, 'unit' | 'displayName' | 'displayStrategy' | 'halfName' | 'pairName' | 'description'>
      & { criteriaChart?: Maybe<Array<Maybe<(
        { __typename?: 'CheckupResultCriteriaChartDef' }
        & Pick<CheckupResultCriteriaChartDef, 'min' | 'max' | 'result'>
      )>>> }
    )> }
  )>>> }
);

export type ExtendedDictionaryConditionTypeFragment = (
  { __typename?: 'ExtendedDictionaryCondition' }
  & Pick<ExtendedDictionaryCondition, 'left' | 'right' | 'comparator'>
);

export type ExtendedDictionaryOverrideTypeFragment = (
  { __typename?: 'ExtendedDictionaryOverride' }
  & Pick<ExtendedDictionaryOverride, 'template'>
  & { conditions: Array<(
    { __typename?: 'ExtendedDictionaryCondition' }
    & ExtendedDictionaryConditionTypeFragment
  )> }
);

export type ExtendedDictionaryTypeFragment = (
  { __typename?: 'ExtendedDictionary' }
  & Pick<ExtendedDictionary, 'key' | 'template'>
  & { override: Array<(
    { __typename?: 'ExtendedDictionaryOverride' }
    & ExtendedDictionaryOverrideTypeFragment
  )> }
);

export type QuestionTypeFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'label' | 'required' | 'additional_type' | 'additional_column_name' | 'category' | 'confirm_text' | 'isConditional' | 'isNarrowDown'>
  & { answer?: Maybe<Array<Maybe<(
    { __typename?: 'AnswerResult' }
    & Pick<AnswerResult, 'answer'>
    & { option?: Maybe<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'id' | 'label' | 'value'>
    )> }
  )>>>, options?: Maybe<Array<Maybe<(
    { __typename?: 'QuestionOption' }
    & Pick<QuestionOption, 'id' | 'label' | 'value' | 'type' | 'placeholder'>
  )>>> }
);

export type InterviewTypeFragment = (
  { __typename?: 'Interview' }
  & Pick<Interview, 'id' | 'finished'>
  & { questions?: Maybe<Array<Maybe<(
    { __typename?: 'Question' }
    & QuestionTypeFragment
  )>>> }
);

export type ReservationFragment = (
  { __typename?: 'Treatment' }
  & Pick<Treatment, 'id' | 'status' | 'date' | 'time' | 'interviewId' | 'duration' | 'physician' | 'hidden' | 'displayDuration' | 'noInterview' | 'laneId'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'baseDuration' | 'icon' | 'type' | 'editInterval' | 'announcement' | 'requirement'>
    & { clinic?: Maybe<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name' | 'address' | 'lat' | 'lng' | 'mapUrl' | 'termsUrl'>
    )> }
  )>, treatmentKind?: Maybe<(
    { __typename?: 'TreatmentKind' }
    & Pick<TreatmentKind, 'id' | 'name' | 'course' | 'vaccine' | 'announcement' | 'requirement' | 'online'>
    & { vaccineSequence?: Maybe<(
      { __typename?: 'VaccineSequence' }
      & Pick<VaccineSequence, 'end'>
    )> }
    & CancelPoliciesTypeFragment
  )>, editCondition?: Maybe<Array<(
    { __typename?: 'TreatmentEditCondition' }
    & Pick<TreatmentEditCondition, 'duration' | 'anyone'>
    & { doctor?: Maybe<(
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id'>
    )> }
  )>>, interview?: Maybe<(
    { __typename?: 'Interview' }
    & Pick<Interview, 'id' | 'submitted'>
  )>, checkupAppointment?: Maybe<(
    { __typename?: 'CheckupAppointment' }
    & Pick<CheckupAppointment, 'id' | 'planPrice'>
    & { checkupOrganizationMemberEvent?: Maybe<(
      { __typename?: 'CheckupOrganizationMemberEvent' }
      & { event?: Maybe<(
        { __typename?: 'CheckupOrganizationEvent' }
        & Pick<CheckupOrganizationEvent, 'planBillType'>
      )> }
    )>, plan: (
      { __typename?: 'CheckupPlan' }
      & Pick<CheckupPlan, 'label' | 'price'>
    ), optionPrices: Array<(
      { __typename?: 'CheckupOptionPrice' }
      & Pick<CheckupOptionPrice, 'optionId' | 'price' | 'billType'>
      & { option?: Maybe<(
        { __typename?: 'CheckupOption' }
        & Pick<CheckupOption, 'label' | 'regularPrice'>
      )>, discountAmount?: Maybe<(
        { __typename?: 'DiscountAmount' }
        & Pick<DiscountAmount, 'discountRate' | 'discountPrice'>
      )> }
    )> }
  )> }
);

export type SignedPostTypeFragment = (
  { __typename?: 'SignedPost' }
  & Pick<SignedPost, 'url'>
  & { fields: Array<(
    { __typename?: 'SignedPostField' }
    & Pick<SignedPostField, 'key' | 'value'>
  )> }
);

export type TreatmentHistoryFragment = (
  { __typename?: 'Treatment' }
  & Pick<Treatment, 'id' | 'status' | 'date' | 'time' | 'duration' | 'noChargeReason'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'name'>
    & { clinic?: Maybe<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name'>
    )> }
  )>, feedback?: Maybe<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'feedback'>
    & { doctor?: Maybe<(
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    )> }
  )>, payment?: Maybe<(
    { __typename?: 'Payment' }
    & { job: (
      { __typename?: 'ModelPaymentStateMachineConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentStateMachine' }
        & Pick<PaymentStateMachine, 'amount' | 'createdAt'>
      )>>> }
    ) }
  )>, attachments: (
    { __typename?: 'ModelAttachmentConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'key' | 'name' | 'type'>
    )>>> }
  ), treatmentKind?: Maybe<(
    { __typename?: 'TreatmentKind' }
    & Pick<TreatmentKind, 'online'>
  )>, checkupAppointment?: Maybe<(
    { __typename?: 'CheckupAppointment' }
    & { plan: (
      { __typename?: 'CheckupPlan' }
      & Pick<CheckupPlan, 'label'>
    ), checkupOrganizationMemberEvent?: Maybe<(
      { __typename?: 'CheckupOrganizationMemberEvent' }
      & { event?: Maybe<(
        { __typename?: 'CheckupOrganizationEvent' }
        & { organization?: Maybe<(
          { __typename?: 'CheckupOrganization' }
          & Pick<CheckupOrganization, 'name'>
        )> }
      )> }
    )>, result?: Maybe<(
      { __typename?: 'CheckupResultSet' }
      & Pick<CheckupResultSet, 'id' | 'status' | 'userId' | 'date'>
      & { summary?: Maybe<(
        { __typename?: 'CheckupResultSummary' }
        & Pick<CheckupResultSummary, 'result' | 'comment' | 'departmentId' | 'overallComment'>
      )>, judged: Array<(
        { __typename?: 'CheckupResultJudgedSubGroup' }
        & CheckupResultJudgedSubGroupTypeFragment
      )> }
    )> }
  )> }
);

export type TreatmentKindTypeFragment = (
  { __typename?: 'TreatmentKind' }
  & Pick<TreatmentKind, 'id' | 'name' | 'course' | 'icon' | 'vaccine' | 'noInterview' | 'subTitle' | 'estimatedDuration' | 'revisit' | 'order' | 'subAnnouncement' | 'announcement' | 'requirement' | 'displayDuration' | 'requiredReservationFee'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id'>
  )> }
  & CancelPoliciesTypeFragment
);

export type ProfileTypeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'sei' | 'mei' | 'phone' | 'mail' | 'addressLine1' | 'addressLine2' | 'pref' | 'zipCode' | 'sex' | 'age' | 'birthday' | 'accountRestricted'>
  & { insuranceCards?: Maybe<Array<Maybe<(
    { __typename?: 'InsuranceCard' }
    & Pick<InsuranceCard, 'key' | 'url'>
  )>>>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'defaultCard'>
    & { cards: (
      { __typename?: 'ModelCardConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'fourDigit' | 'brand' | 'expAt'>
      )>>> }
    ) }
  )>, patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'patientNo'>
  )> }
);

export type VacancyTypeFragment = (
  { __typename?: 'Vacancies' }
  & Pick<Vacancies, 'date' | 'updatedAt'>
  & { vacancies?: Maybe<Array<Maybe<(
    { __typename?: 'Vacancy' }
    & Pick<Vacancy, 'id' | 'date' | 'time' | 'duration' | 'remainDuration' | 'availables' | 'total' | 'unit' | 'lane'>
  )>>> }
);

export type WaitingListItemTypeFragment = (
  { __typename?: 'WaitingListItem' }
  & Pick<WaitingListItem, 'start' | 'end' | 'userId'>
  & { clinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  )>, department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'icon'>
  )>, treatmentKind?: Maybe<(
    { __typename?: 'TreatmentKind' }
    & Pick<TreatmentKind, 'id' | 'name'>
  )> }
);

export type AnswerInterviewMutationVariables = Exact<{
  input?: Maybe<AnswerInterviewInput>;
}>;


export type AnswerInterviewMutation = (
  { __typename?: 'Mutation' }
  & { answerInterview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewTypeFragment
  )> }
);

export type CancelCheckupAppointmentMutationVariables = Exact<{
  input: CancelCheckupInput;
}>;


export type CancelCheckupAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelCheckupAppointment?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'empty'>
  )> }
);

export type CancelLumeccaTreatmentMutationVariables = Exact<{
  input: CancelTreatmentInput;
}>;


export type CancelLumeccaTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelLumeccaTreatment?: Maybe<(
    { __typename?: 'ProcessResult' }
    & Pick<ProcessResult, 'success'>
  )> }
);

export type CancelTreatmentMutationVariables = Exact<{
  input: CancelTreatmentInput;
}>;


export type CancelTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelTreatment?: Maybe<(
    { __typename?: 'ProcessResult' }
    & Pick<ProcessResult, 'success'>
  )> }
);

export type CoopGoogleCalendarMutationVariables = Exact<{
  input: GoogleCalendarCoopInput;
}>;


export type CoopGoogleCalendarMutation = (
  { __typename?: 'Mutation' }
  & { coopGoogleCalendar?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'empty'>
  )> }
);

export type CreateCardMutationVariables = Exact<{
  input: CreateCardInput;
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  )> }
);

export type ReserveCheckupMutationVariables = Exact<{
  input: ReserveCheckupInput;
}>;


export type ReserveCheckupMutation = (
  { __typename?: 'Mutation' }
  & { reserveCheckup?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateCheckupReservationForIndividualMutationVariables = Exact<{
  input: ReserveCheckupAppointmentInput;
}>;


export type CreateCheckupReservationForIndividualMutation = (
  { __typename?: 'Mutation' }
  & { reserveCheckupAppointmentIndividual?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateCheckupReservationForOrganizationMutationVariables = Exact<{
  input: ReserveCheckupAppointmentInput;
}>;


export type CreateCheckupReservationForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { reserveCheckupAppointmentOrganization?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateDosageWithPrescriptionMutationVariables = Exact<{
  input: CreateDosageWithPrescriptionInput;
}>;


export type CreateDosageWithPrescriptionMutation = (
  { __typename?: 'Mutation' }
  & { createDosageWithPrescription?: Maybe<(
    { __typename?: 'CreateDosageWithPrescriptionResult' }
    & { urls: Array<(
      { __typename?: 'SignedPost' }
      & Pick<SignedPost, 'url'>
      & { fields: Array<(
        { __typename?: 'SignedPostField' }
        & Pick<SignedPostField, 'key' | 'value'>
      )> }
    )> }
  )> }
);

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInput;
}>;


export type CreateReviewMutation = (
  { __typename?: 'Mutation' }
  & { createReview?: Maybe<(
    { __typename?: 'Review' }
    & Pick<Review, 'createdAt'>
  )> }
);

export type CreateTemporallyCheckupReservationIndividualMutationVariables = Exact<{
  input: ReserveCheckupAppointmentInput;
}>;


export type CreateTemporallyCheckupReservationIndividualMutation = (
  { __typename?: 'Mutation' }
  & { reserveCheckupAppointmentIndividualTemporarily?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateTemporallyTreatmentMutationVariables = Exact<{
  input: CreateTreatmentInput;
}>;


export type CreateTemporallyTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { createTemporallyTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateTreatmentMutationVariables = Exact<{
  input: CreateTreatmentInput;
}>;


export type CreateTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { createTreatmentWithValidation?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type CreateWaitingListItemMutationVariables = Exact<{
  input: CreateWaitingListItemInput;
}>;


export type CreateWaitingListItemMutation = (
  { __typename?: 'Mutation' }
  & { createWaitingListItem?: Maybe<(
    { __typename?: 'WaitingListItem' }
    & Pick<WaitingListItem, 'start'>
  )> }
);

export type DeleteWaitingListItemMutationVariables = Exact<{
  input: DeleteWaitingListItemInput;
}>;


export type DeleteWaitingListItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteWaitingListItem?: Maybe<(
    { __typename?: 'WaitingListItem' }
    & Pick<WaitingListItem, 'start'>
  )> }
);

export type DownloadAllReceiptsQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadAllReceiptsQuery = (
  { __typename?: 'Query' }
  & { downloadAllReceipts?: Maybe<(
    { __typename?: 'AttachmentUrl' }
    & Pick<AttachmentUrl, 'url'>
  )> }
);

export type FixCheckupAppointmentOrganizationMutationVariables = Exact<{
  input: CheckupAppointmentOrganizationFixInput;
}>;


export type FixCheckupAppointmentOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { fixCheckupAppointmentOrganization?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type FixTemporallyCheckupReservationForIndividualMutationVariables = Exact<{
  input: FixTreatmentInput;
}>;


export type FixTemporallyCheckupReservationForIndividualMutation = (
  { __typename?: 'Mutation' }
  & { fixTreatmentCheckupIndividual?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type FixTreatmentMutationVariables = Exact<{
  input?: Maybe<FixTreatmentInput>;
}>;


export type FixTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { fixTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type GetAttachmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAttachmentQuery = (
  { __typename?: 'Query' }
  & { getAttachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'key' | 'name'>
  )> }
);

export type GetBulkReceiptsDownloadLinkQueryVariables = Exact<{
  input: BulkReceiptDownloadInput;
}>;


export type GetBulkReceiptsDownloadLinkQuery = (
  { __typename?: 'Query' }
  & { getBulkReceiptDownload?: Maybe<(
    { __typename?: 'ReceiptDownloadResult' }
    & Pick<ReceiptDownloadResult, 'success' | 'url'>
  )> }
);

export type GetCheckupAppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCheckupAppointmentQuery = (
  { __typename?: 'Query' }
  & { getCheckupAppointment?: Maybe<(
    { __typename?: 'CheckupAppointment' }
    & Pick<CheckupAppointment, 'id'>
    & { checkupOrganizationMemberEvent?: Maybe<(
      { __typename?: 'CheckupOrganizationMemberEvent' }
      & Pick<CheckupOrganizationMemberEvent, 'id'>
      & { event?: Maybe<(
        { __typename?: 'CheckupOrganizationEvent' }
        & Pick<CheckupOrganizationEvent, 'id' | 'discountCode'>
      )> }
    )>, treatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & Pick<Treatment, 'id' | 'status'>
        & { department?: Maybe<(
          { __typename?: 'Department' }
          & Pick<Department, 'id'>
        )> }
      )>>> }
    ) }
  )> }
);

export type GetCheckupAppointmentByTreatmentIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetCheckupAppointmentByTreatmentIdQuery = (
  { __typename?: 'Query' }
  & { getTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'options' | 'discountCode'>
    & { preInterview?: Maybe<(
      { __typename?: 'Interview' }
      & InterviewTypeFragment
    )>, checkupAppointment?: Maybe<(
      { __typename?: 'CheckupAppointment' }
      & Pick<CheckupAppointment, 'id' | 'address'>
      & { plan: (
        { __typename?: 'CheckupPlan' }
        & Pick<CheckupPlan, 'id' | 'label' | 'price'>
      ), optionPrices: Array<(
        { __typename?: 'CheckupOptionPrice' }
        & Pick<CheckupOptionPrice, 'optionId' | 'price' | 'billType'>
        & { discountAmount?: Maybe<(
          { __typename?: 'DiscountAmount' }
          & Pick<DiscountAmount, 'discountRate' | 'discountPrice'>
        )>, option?: Maybe<(
          { __typename?: 'CheckupOption' }
          & Pick<CheckupOption, 'label' | 'regularPrice'>
        )> }
      )>, treatment: (
        { __typename?: 'ModelTreatmentConnection' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Treatment' }
          & ReservationFragment
        )>>> }
      ) }
    )> }
    & ReservationFragment
  )> }
);

export type GetCheckupInterviewQueryVariables = Exact<{
  input: CheckupInterviewInput;
}>;


export type GetCheckupInterviewQuery = (
  { __typename?: 'Query' }
  & { getCheckupInterview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewTypeFragment
  )> }
);

export type GetCheckupOrganizationMemberEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCheckupOrganizationMemberEventQuery = (
  { __typename?: 'Query' }
  & { getCheckupOrganizationMemberEvent?: Maybe<(
    { __typename?: 'CheckupOrganizationMemberEvent' }
    & CheckupMemberEventTypeFragment
  )>, listCheckupPlans?: Maybe<(
    { __typename?: 'ModelCheckupPlanConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupPlan' }
      & Pick<CheckupPlan, 'id' | 'label' | 'price' | 'treatmentKindId' | 'options'>
      & { treatmentKind?: Maybe<(
        { __typename?: 'TreatmentKind' }
        & CancelPoliciesTypeFragment
      )> }
    )>>> }
  )> }
);

export type GetCheckupOrganizationMemberEventByTreatmentIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetCheckupOrganizationMemberEventByTreatmentIdQuery = (
  { __typename?: 'Query' }
  & { getTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id' | 'options'>
    & { preInterview?: Maybe<(
      { __typename?: 'Interview' }
      & InterviewTypeFragment
    )>, checkupAppointment?: Maybe<(
      { __typename?: 'CheckupAppointment' }
      & Pick<CheckupAppointment, 'id'>
      & { checkupOrganizationMemberEvent?: Maybe<(
        { __typename?: 'CheckupOrganizationMemberEvent' }
        & CheckupMemberEventTypeFragment
      )> }
    )> }
  )> }
);

export type GetCheckupSummaryPageQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetCheckupSummaryPageQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { fixedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & ReservationFragment
      )>>> }
    ), completedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & TreatmentHistoryFragment
      )>>> }
    ), waitingList: (
      { __typename?: 'ModelWaitingListItemConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'WaitingListItem' }
        & WaitingListItemTypeFragment
      )>>> }
    ) }
  )> }
);

export type GetCheckupTreatmentTemporallyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCheckupTreatmentTemporallyQuery = (
  { __typename?: 'Query' }
  & { getTemporallyTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'date' | 'time'>
    & { checkupAppointment?: Maybe<(
      { __typename?: 'CheckupAppointment' }
      & { checkupOrganizationMemberEvent?: Maybe<(
        { __typename?: 'CheckupOrganizationMemberEvent' }
        & Pick<CheckupOrganizationMemberEvent, 'id'>
      )> }
    )> }
  )> }
);

export type GetCheckupTreatmentWithInterviewQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetCheckupTreatmentWithInterviewQuery = (
  { __typename?: 'Query' }
  & { getTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'options'>
    & { interview?: Maybe<(
      { __typename?: 'Interview' }
      & InterviewTypeFragment
    )>, preInterview?: Maybe<(
      { __typename?: 'Interview' }
      & InterviewTypeFragment
    )>, checkupAppointment?: Maybe<(
      { __typename?: 'CheckupAppointment' }
      & { plan: (
        { __typename?: 'CheckupPlan' }
        & Pick<CheckupPlan, 'id'>
      ) }
    )> }
  )> }
);

export type GetDownloadableLinkQueryVariables = Exact<{
  input: DownloadableLinkInput;
}>;


export type GetDownloadableLinkQuery = (
  { __typename?: 'Query' }
  & { getDownloadableLink?: Maybe<(
    { __typename?: 'AttachmentUrl' }
    & Pick<AttachmentUrl, 'url'>
  )> }
);

export type GetInsuranceCardSignedPostQueryVariables = Exact<{
  input: InsuranceCardSignedPostQueryInput;
}>;


export type GetInsuranceCardSignedPostQuery = (
  { __typename?: 'Query' }
  & { getInsuranceCardSignedPost?: Maybe<(
    { __typename?: 'SignedPost' }
    & SignedPostTypeFragment
  )> }
);

export type GetInterviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInterviewQuery = (
  { __typename?: 'Query' }
  & { getInterview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewTypeFragment
  )> }
);

export type GetInterviewDownloadableUrlQueryVariables = Exact<{
  input: InterviewDownloadableUrlQueryInput;
}>;


export type GetInterviewDownloadableUrlQuery = (
  { __typename?: 'Query' }
  & { getInterviewDownloadableUrl?: Maybe<(
    { __typename?: 'SignedPost' }
    & SignedPostTypeFragment
  )> }
);

export type GetInterviewPresignedPostQueryVariables = Exact<{
  input: InterviewPresignedPostQueryInput;
}>;


export type GetInterviewPresignedPostQuery = (
  { __typename?: 'Query' }
  & { getInterviewPresignedPost?: Maybe<(
    { __typename?: 'SignedPost' }
    & SignedPostTypeFragment
  )> }
);

export type GetMyPageQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetMyPageQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { fixedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & ReservationFragment
      )>>> }
    ), completedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & TreatmentHistoryFragment
      )>>> }
    ), waitingList: (
      { __typename?: 'ModelWaitingListItemConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'WaitingListItem' }
        & WaitingListItemTypeFragment
      )>>> }
    ) }
  )> }
);

export type GetNextTreatmentPlanQueryVariables = Exact<{
  input: GetNextTreatmentPlanQueryInput;
}>;


export type GetNextTreatmentPlanQuery = (
  { __typename?: 'Query' }
  & { getNextTreatmentPlan?: Maybe<Array<(
    { __typename?: 'TreatmentKind' }
    & TreatmentKindTypeFragment
  )>> }
);

export type GetPayjpPublicKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayjpPublicKeyQuery = (
  { __typename?: 'Query' }
  & { getPayjpPublicKey?: Maybe<(
    { __typename?: 'PublicKey' }
    & Pick<PublicKey, 'publicKey'>
  )> }
);

export type GetReservationQueryVariables = Exact<{
  treatmentId?: Maybe<Scalars['String']>;
}>;


export type GetReservationQuery = (
  { __typename?: 'Query' }
  & { getTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & { checkupAppointment?: Maybe<(
      { __typename?: 'CheckupAppointment' }
      & Pick<CheckupAppointment, 'id'>
      & { checkupOrganizationMemberEvent?: Maybe<(
        { __typename?: 'CheckupOrganizationMemberEvent' }
        & Pick<CheckupOrganizationMemberEvent, 'id'>
      )>, treatment: (
        { __typename?: 'ModelTreatmentConnection' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Treatment' }
          & Pick<Treatment, 'status'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'penaltyCount'>
    )> }
    & ReservationFragment
  )> }
);

export type GetTemporallyTreatmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTemporallyTreatmentQuery = (
  { __typename?: 'Query' }
  & { getTemporallyTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & { clinic?: Maybe<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name' | 'termsUrl'>
    )>, department?: Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id'>
    )>, treatmentKind?: Maybe<(
      { __typename?: 'TreatmentKind' }
      & Pick<TreatmentKind, 'id'>
    )> }
  )> }
);

export type GetTreatmentHistoryQueryVariables = Exact<{
  treatmentId?: Maybe<Scalars['String']>;
}>;


export type GetTreatmentHistoryQuery = (
  { __typename?: 'Query' }
  & { getTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & TreatmentHistoryFragment
  )> }
);

export type GetTreatmentKindQueryVariables = Exact<{
  treatmentKindId: Scalars['ID'];
}>;


export type GetTreatmentKindQuery = (
  { __typename?: 'Query' }
  & { getTreatmentKind?: Maybe<(
    { __typename?: 'TreatmentKind' }
    & TreatmentKindTypeFragment
  )> }
);

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & ProfileTypeFragment
  )> }
);

export type GetWaitingListItemQueryVariables = Exact<{
  clinic: Scalars['ID'];
  sortKey: Scalars['ID'];
}>;


export type GetWaitingListItemQuery = (
  { __typename?: 'Query' }
  & { getWaitingListItem?: Maybe<(
    { __typename?: 'WaitingListItem' }
    & WaitingListItemTypeFragment
  )> }
);

export type IssueUserNonceMutationVariables = Exact<{
  input: IssueUserNonceInput;
}>;


export type IssueUserNonceMutation = (
  { __typename?: 'Mutation' }
  & { issueUserNonce?: Maybe<(
    { __typename?: 'AccountLinkResult' }
    & Pick<AccountLinkResult, 'url'>
  )> }
);

export type ListCheckupResultItemGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCheckupResultItemGroupsQuery = (
  { __typename?: 'Query' }
  & { listCheckupResultItemGroups?: Maybe<(
    { __typename?: 'ModelCheckupResultItemGroupConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupResultItemGroup' }
      & CheckupResultItemGroupTypeFragment
    )>>> }
  )> }
);

export type ListCheckupResultItemSubGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCheckupResultItemSubGroupsQuery = (
  { __typename?: 'Query' }
  & { listCheckupResultItemSubGroups?: Maybe<(
    { __typename?: 'ModelCheckupResultItemSubGroupConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupResultItemSubGroup' }
      & Pick<CheckupResultItemSubGroup, 'description' | 'id' | 'order' | 'name'>
      & { group?: Maybe<(
        { __typename?: 'CheckupResultItemGroup' }
        & CheckupResultItemGroupTypeFragment
      )> }
    )>>> }
  )> }
);

export type ListCheckupOptionSuggestionQueryVariables = Exact<{
  input: Array<CheckupOptionSuggestionInput>;
}>;


export type ListCheckupOptionSuggestionQuery = (
  { __typename?: 'Query' }
  & { listCheckupOptionSuggestion?: Maybe<Array<Maybe<(
    { __typename?: 'CheckupOptionSuggestion' }
    & Pick<CheckupOptionSuggestion, 'optionId' | 'text' | 'severity'>
  )>>> }
);

export type ListCheckupVacanciesQueryVariables = Exact<{
  input: VacancyUnitCalculation;
}>;


export type ListCheckupVacanciesQuery = (
  { __typename?: 'Query' }
  & { listCheckupVacancies: Array<(
    { __typename?: 'CheckupVacancy' }
    & { checkup: (
      { __typename?: 'ComputedVacancy' }
      & Pick<ComputedVacancy, 'clinic' | 'date' | 'time' | 'available' | 'estimatedDuration' | 'doctor' | 'laneId'>
    ), dental?: Maybe<(
      { __typename?: 'ComputedVacancy' }
      & Pick<ComputedVacancy, 'clinic' | 'date' | 'time' | 'available' | 'estimatedDuration' | 'doctor' | 'laneId'>
    )> }
  )> }
);

export type ListDepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListDepartmentsQuery = (
  { __typename?: 'Query' }
  & { listDepartments?: Maybe<(
    { __typename?: 'ModelDepartmentConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name' | 'type' | 'baseDuration' | 'icon' | 'alertThreshold' | 'orcaID' | 'online' | 'order' | 'displayName' | 'hidden' | 'description' | 'announcement' | 'requirement'>
      & { clinic?: Maybe<(
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'id' | 'name' | 'termsUrl'>
      )> }
    )>>> }
  )> }
);

export type ListExtendedDictionariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListExtendedDictionariesQuery = (
  { __typename?: 'Query' }
  & { listExtendedDictionarys?: Maybe<(
    { __typename?: 'ModelExtendedDictionaryConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ExtendedDictionary' }
      & ExtendedDictionaryTypeFragment
    )>>> }
  )> }
);

export type ListIndividualCheckupMastersQueryVariables = Exact<{
  discountCode?: Maybe<Scalars['String']>;
  union?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
}>;


export type ListIndividualCheckupMastersQuery = (
  { __typename?: 'Query' }
  & { getDiscountCheckupIndividualMasters?: Maybe<(
    { __typename?: 'DiscountIndividualCheckupMasters' }
    & { plans: Array<(
      { __typename?: 'CheckupPlanDiscountPrice' }
      & Pick<CheckupPlanDiscountPrice, 'planId' | 'regularPrice' | 'price'>
    )>, options: Array<(
      { __typename?: 'CheckupOptionDiscountPrice' }
      & Pick<CheckupOptionDiscountPrice, 'optionId' | 'price' | 'regularPrice'>
    )> }
  )>, listCheckupPlanIndividuals?: Maybe<(
    { __typename?: 'ModelCheckupPlanIndividualConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupPlanIndividual' }
      & Pick<CheckupPlanIndividual, 'order'>
      & { plan: (
        { __typename?: 'CheckupPlan' }
        & Pick<CheckupPlan, 'id' | 'label' | 'description' | 'options' | 'treatmentKindId' | 'hideForIndividual'>
        & { treatmentKind?: Maybe<(
          { __typename?: 'TreatmentKind' }
          & CancelPoliciesTypeFragment
        )> }
      ) }
    )>>> }
  )>, listCheckupOptionIndividuals?: Maybe<(
    { __typename?: 'ModelCheckupOptionIndividualConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CheckupOptionIndividual' }
      & { option: (
        { __typename?: 'CheckupOption' }
        & Pick<CheckupOption, 'regularPrice' | 'id' | 'label' | 'description' | 'clinicType' | 'requiredMin' | 'order' | 'subTitle' | 'warning'>
        & { treatmentKind?: Maybe<(
          { __typename?: 'TreatmentKind' }
          & Pick<TreatmentKind, 'id' | 'name'>
        )> }
      ) }
    )>>> }
  )> }
);

export type ListLumeccaVacanciesQueryVariables = Exact<{
  input: VacancyUnitCalculation;
}>;


export type ListLumeccaVacanciesQuery = (
  { __typename?: 'Query' }
  & { listLumeccaConsecutiveVacancies: Array<(
    { __typename?: 'ComputedVacancy' }
    & Pick<ComputedVacancy, 'date' | 'time' | 'available' | 'estimatedDuration' | 'doctor' | 'laneId' | 'waitAvailable'>
  )> }
);

export type ListTreatmentKindsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListTreatmentKindsQuery = (
  { __typename?: 'Query' }
  & { listTreatmentKinds?: Maybe<(
    { __typename?: 'ModelTreatmentKindConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'TreatmentKind' }
      & TreatmentKindTypeFragment
    )>>> }
  )> }
);

export type ListTreatmentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListTreatmentsQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { completedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & TreatmentHistoryFragment
      )>>> }
    ) }
  )> }
);

export type ListVacanciesForWeekQueryVariables = Exact<{
  input: VacancyUnitCalculation;
}>;


export type ListVacanciesForWeekQuery = (
  { __typename?: 'Query' }
  & { listVacancies: Array<(
    { __typename?: 'ComputedVacancy' }
    & Pick<ComputedVacancy, 'date' | 'time' | 'available' | 'estimatedDuration' | 'doctor' | 'laneId' | 'waitAvailable'>
  )> }
);

export type ListVacancyUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListVacancyUnitsQuery = (
  { __typename?: 'Query' }
  & { listVacancyUnits?: Maybe<(
    { __typename?: 'ModelVacancyUnitConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'VacancyUnit' }
      & Pick<VacancyUnit, 'id' | 'name' | 'clinicId' | 'departmentId' | 'treatmentKind' | 'options' | 'defaultDuration'>
    )>>> }
  )> }
);

export type ListYearNoShowTreatmentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  year?: Maybe<Scalars['String']>;
}>;


export type ListYearNoShowTreatmentsQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { noShowTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & TreatmentHistoryFragment
      )>>> }
    ) }
  )> }
);

export type ListYearTreatmentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  year?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListYearTreatmentsQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { completedTreatment: (
      { __typename?: 'ModelTreatmentConnection' }
      & Pick<ModelTreatmentConnection, 'nextToken'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Treatment' }
        & TreatmentHistoryFragment
      )>>> }
    ) }
  )> }
);

export type ListVacanciessQueryVariables = Exact<{
  clinic: Scalars['ID'];
  date?: Maybe<ModelStringConditionInput>;
}>;


export type ListVacanciessQuery = (
  { __typename?: 'Query' }
  & { listVacanciess?: Maybe<(
    { __typename?: 'ModelVacanciesConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Vacancies' }
      & Pick<Vacancies, 'date'>
      & { vacancies?: Maybe<Array<Maybe<(
        { __typename?: 'Vacancy' }
        & Pick<Vacancy, 'date' | 'time' | 'unit' | 'availables'>
      )>>> }
    )>>> }
  )> }
);

export type ReAnswerInterviewMutationVariables = Exact<{
  input?: Maybe<ReAnswerInterviewInput>;
}>;


export type ReAnswerInterviewMutation = (
  { __typename?: 'Mutation' }
  & { reAnswerInterview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewTypeFragment
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type ShouldConfirmQueryVariables = Exact<{
  input: ConfirmQueryInput;
}>;


export type ShouldConfirmQuery = (
  { __typename?: 'Query' }
  & { shouldConfirm?: Maybe<(
    { __typename?: 'ConfirmQueryResult' }
    & Pick<ConfirmQueryResult, 'confirmRequired' | 'confirmReservationFeeRequired'>
  )> }
);

export type StartInterviewMutationVariables = Exact<{
  input?: Maybe<StartInterviewInput>;
}>;


export type StartInterviewMutation = (
  { __typename?: 'Mutation' }
  & { startInterview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewTypeFragment
  )> }
);

export type SubmitCheckupInterviewMutationVariables = Exact<{
  input: SubmitCheckupInterviewInput;
}>;


export type SubmitCheckupInterviewMutation = (
  { __typename?: 'Mutation' }
  & { submitCheckupInterview?: Maybe<(
    { __typename?: 'Interview' }
    & Pick<Interview, 'id'>
  )> }
);

export type UpdateCheckupAppointmentOrganizationMutationVariables = Exact<{
  input: UpdateCheckupInput;
}>;


export type UpdateCheckupAppointmentOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckupAppointmentOrganization?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type UpdateCheckupReservationMutationVariables = Exact<{
  input: UpdateCheckupReservationInput;
}>;


export type UpdateCheckupReservationMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckupReservation?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type UpdateCheckupAppointmentForIndividualMutationVariables = Exact<{
  input: UpdateCheckupInput;
}>;


export type UpdateCheckupAppointmentForIndividualMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckupAppointmentIndividual?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type UpdateTreatmentMutationVariables = Exact<{
  input: CreateTreatmentInput;
  id: Scalars['String'];
}>;


export type UpdateTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { updateTreatmentWithValidation?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'User' }
    & ProfileTypeFragment
  )> }
);

export type ValidateCardMutationVariables = Exact<{
  input: CardConfirmInfoInput;
}>;


export type ValidateCardMutation = (
  { __typename?: 'Mutation' }
  & { validateCard?: Maybe<(
    { __typename?: 'CardConfirmation' }
    & Pick<CardConfirmation, 'success'>
    & { message?: Maybe<(
      { __typename?: 'CardError' }
      & Pick<CardError, 'code' | 'message' | 'status' | 'type'>
    )> }
  )> }
);

export type ValidateVaccinePasswordQueryVariables = Exact<{
  input: ValidateVaccinePasswordInput;
}>;


export type ValidateVaccinePasswordQuery = (
  { __typename?: 'Query' }
  & { validateVaccinePassword?: Maybe<(
    { __typename?: 'ValidateResult' }
    & Pick<ValidateResult, 'valid' | 'error'>
  )> }
);
