import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorDialogContext } from '../../../../components/ErrorDialog';
import { Routes } from '../../../../constants/Routes';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { SessionStore } from '../../../../stores/SessionStore';
import { VacanciesStore } from '../../stores/VacanciesStore';
import { ReservationStore } from '../stores/ReservationStore';

export const useTreatmentRegisterFlow = () => {
  const store = useMemo(() => new ReservationStore(), []);
  const session = useContext(SessionStore.Context);
  const { encode } = useReservationId();
  const { push } = useHistory();
  const { setError } = useContext(ErrorDialogContext);
  const transaction = useTransaction(
    async (session: SessionStore, type?: string) => {
      await postTreatment({ store, encode, push, session, type });
    },
    e => {
      const gqlError: { errors?: { message: string }[] } = e as any;
      setError({
        header: '予約に失敗しました',
        message: `${
          gqlError.errors ? gqlError.errors[0].message : ''
        }お手数ですが、再度はじめから予約をお願い致します。`,
        onOk: () => {
          setError();
          window.location.reload();
        },
      });
    },
  );

  const vacancyStore = useMemo(() => new VacanciesStore(), []);

  return { store, transaction, vacancyStore, session };
};

type Props = {
  store: ReservationStore;
  push: ReturnType<typeof useHistory>['push'];
  session: SessionStore;
  type?: string;
  encode: ReturnType<typeof useReservationId>['encode'];
};

const postTreatment = async ({ type, store, session, push, encode }: Props) => {
  await session.fetchSession();
  if (!session.authenticated) {
    const id = await store.postTemporallyReservation();
    push({
      pathname: Routes.Paths.AccountNew,
      search: new URLSearchParams({ type, reservationId: encode(id || ''), action: 'fixTreatment' } as Record<
        'type' | 'reservationId' | 'action',
        string
      >).toString(),
    });
    return;
  }

  const id = await store.postReservation(session.user?.userId || '');
  push(Routes.Paths.ReservationSuccess.replace(':uuid', encode(id || '')));
};
