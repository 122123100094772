import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import { css } from 'emotion';
import React from 'react';

import { Maybe } from '../../../../../../gql/gql-types';
import { Caption } from '../../../../../components/Caption';
import { Gutter } from '../../../../../components/Gutter';
import { Defaults } from '../../../../../constants/Defaults';
import { Color, FontSize } from '../../../../../constants/Style';
import { approximate } from '../../../../../utils/NumberUtil';
import { CheckupAmountDisplay } from '../../../../Checkup/Individual/Reservations/IndividualCheckupReservationConfirm/IndividualCheckupOptionSelect/components/CheckupAmountDisplay';
import { ReservationImage } from '../ReservationImage';

type Props = {
  date?: Date;
  duration?: number;
  clinic?: {
    id: string;
    name: string;
    address: string;
    lat: number;
    lng: number;
  };
  department?: {
    id: string;
    name: string;
    editInterval: number;
    requirement?: Maybe<string>;
  };
  treatmentKind?: {
    id?: string;
    name?: string;
    course?: boolean;
    vaccine?: boolean;
    requirement?: Maybe<string>;
  };
  isCorporateCheckup?: boolean;
  options?: {
    name: string;
    regularAmount: number;
    discountAmount: number;
    billingTarget: '1_organization' | '2_own';
  }[];
  plan?: { name: string; regularAmount: number; discountAmount: number; billingTarget: '1_organization' | '2_own' };
};

type HeaderProps = {
  clinicName: string;
  date: Date;
  departmentName: string;
  planName?: string;
  treatmentKind?: string;
  duration: number;
};

export const ReservationDetailInfoHeader = ({
  clinicName,
  date,
  departmentName,
  planName,
  treatmentKind,
  duration,
}: HeaderProps) => (
  <>
    <ReservationImage clinic={{ id: '01' }} />
    <div className={dateStyle}>
      {format(date, `yyyy年MM月dd日(${WeekLabels[date.getDay()]}) HH:mm`)}
      <span className={smallTimeTildeStyle}> ～ </span>
      <span className={smallTimeStyle}>[所要{approximate(duration, Defaults.DURATION)}分]</span>
    </div>
    <Gutter vertical={3}>
      <div className={clinicCaptionStyle}>
        {clinicName}
        <br />
        {departmentName} {planName && `/ ${planName}`}
      </div>
    </Gutter>
    {treatmentKind ? <div className={treatmentKindStyle}>ご希望の処置： {treatmentKind} </div> : null}
  </>
);

export const ReservationDetailInfo = ({
  date = new Date(),
  duration = 15,
  clinic = { id: '', name: '', address: '', lng: 0, lat: 0 },
  department = { id: '', name: '', editInterval: 0 },
  treatmentKind,
  isCorporateCheckup = false,
  options,
  plan,
}: Props) => (
  <>
    <ReservationDetailInfoHeader
      clinicName={clinic.name}
      date={date}
      departmentName={department.name}
      duration={duration}
      planName={plan?.name}
      treatmentKind={treatmentKind?.course ? treatmentKind.name : undefined}
    />
    {/** FIXME COVID only */}
    {!(treatmentKind?.vaccine || department.requirement || treatmentKind?.requirement) && (
      <p className={hintStyle}>
        ※患者様一人ひとりを時間枠を作ってお待ちしているので、当日または無断キャンセルはご遠慮ください。当日または無断キャンセルが続く場合、ご予約をお断りすることがございます
      </p>
    )}
    {isCorporateCheckup && differenceInMinutes(date, new Date()) <= department.editInterval && (
      <Caption type="danger">受診24時間以内の予約変更・取消はキャンセル料が発生する場合がございます。</Caption>
    )}
    {options && plan && (
      <Gutter vertical={4}>
        <CheckupAmountDisplay options={options} plan={plan} foldable />
      </Gutter>
    )}
    {(treatmentKind?.requirement || department.requirement) && (
      <Caption type="danger" break>
        {treatmentKind?.requirement || department.requirement}
      </Caption>
    )}
  </>
);

const clinicCaptionStyle = css`
  font-size: ${FontSize.Medium};
  line-height: 150%;
`;

const dateStyle = css`
  font-size: ${FontSize.Large};
  font-weight: bold;
  color: ${Color.GrayscaleDarker};
`;

const smallTimeStyle = css`
  font-size: ${FontSize.SmallRegular};
`;
const smallTimeTildeStyle = css`
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const hintStyle = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;

const treatmentKindStyle = css`
  font-size: ${FontSize.Regular};
  color: ${Color.GrayscaleDarker};
`;

const WeekLabels = ['日', '月', '火', '水', '木', '金', '土'] as const;
