import { css } from 'emotion';
import React from 'react';

import { Icon } from '../../../../components/Icon';
import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';

type Props = {
  groupName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const Accordion = ({ groupName, open, setOpen }: Props) => (
  <div className={groupNameStyle}>
    <p>{groupName}</p>
    <div onClick={() => setOpen(!open)}>
      <Icon name={open ? 'angle-down' : 'angle-up'} />
    </div>
  </div>
);

const groupNameStyle = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${gutter(3)};
  margin-bottom: ${gutter(5)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
  border-bottom: 1px solid ${Color.GrayscaleLight};

  > div > svg {
    cursor: pointer;
    ${square(20)}

    fill: ${Color.GrayscaleNeutral}
  }
`;
