import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../../constants/Routes';
import { useContext } from '../../../../hooks/useContext';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import { SessionStore } from '../../../../stores/SessionStore';
import { WaitingListStore } from '../stores/WaitingListStore';

export const useWaitingListRegisterFlow = () => {
  const { push } = useReservationId();
  const { push: nativePush } = useHistory();
  const waitingListStore = useMemo(() => new WaitingListStore(), []);
  const sessionStore = useContext(SessionStore.Context);
  const [doRegisterWaitingList, registerWaitingListStatus] = useTransaction(async () => {
    if (!sessionStore.authenticated) {
      nativePush({
        pathname: Routes.Paths.AccountNew,
        search: new URLSearchParams({
          type: 'LINE',
          action: 'fixWaitingListItem',
          start: waitingListStore.start?.toISOString(),
          end: waitingListStore.end?.toISOString(),
          department: waitingListStore.department?.id,
          clinic: waitingListStore.department?.clinic,
          treatmentKind: waitingListStore.treatmentKind?.id || waitingListStore.department?.id,
        } as Record<string, string>).toString(),
      });
      return;
    }
    await waitingListStore.register();
    push(Routes.Paths.WaitingSuccess, waitingListStore.id);
  });

  useEffect(() => {
    if (!sessionStore.user?.userId) {
      return;
    }
    waitingListStore.setUserId(sessionStore.user.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  return {
    waitingListStore,
    doRegisterWaitingList,
    registerWaitingListStatus,
  };
};
