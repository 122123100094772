import { css } from 'emotion';
import React, { useState } from 'react';

import { Icon } from '../../../../../../components/Icon';
import { Environment } from '../../../../../../environments';

type Props = {
  id: string;
};

const style = css`
  > img,
  svg {
    width: 27px;
    border-radius: 27px;
  }
`;

export const DoctorIcon = ({ id }: Props) => {
  const [hasError, setHasError] = useState(false);
  return (
    <div className={style}>
      {hasError ? (
        <Icon name="user" />
      ) : (
        <img src={`${Environment.publicAssetUrl}/doctors/${id}.png`} alt="doctor" onError={() => setHasError(true)} />
      )}
    </div>
  );
};
