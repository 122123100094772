import { AnswerInput, Maybe, Question } from '../../../../../gql/gql-types';

export class FeverDomain {
  public static feverWarning = (questions: Maybe<Question>[], answers: AnswerInput[], department: string) => {
    if (department !== '01') {
      return false;
    }
    const feverQuestionIds = questions.flatMap(
      q => q?.options?.flatMap(o => (o?.type === 'FEVER' ? String(o.id) : [])) ?? [],
    );

    return answers.some(a => a.answer.some(b => feverQuestionIds.includes(String(b.id))));
  };

  public static filterQuestions = (questions: Maybe<Question>[], answers: AnswerInput[], department: string) => {
    if (!FeverDomain.feverWarning(questions, answers, department)) {
      return questions;
    }

    const index = questions.findIndex(q => q?.options?.some(a => a?.type === 'FEVER'));
    if (index < 0) {
      return [];
    }

    return questions.slice(0, index + 1);
  };
}
