/*
 * NOTE: This class is auto generated by srac-technologies/clinic-monorepo/packages/clinic-icon
 * Do not edit the class manually.
 */
import React from 'react';

export type IconName =
  | 'account-circle-grey'
  | 'account-circle'
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'angle-up'
  | 'arrow-left-circle'
  | 'arrow-right-circle'
  | 'arrow-right'
  | 'arrow-up'
  | 'attach-file'
  | 'babybottle'
  | 'beauty'
  | 'bell-off'
  | 'bell'
  | 'black-filter'
  | 'block'
  | 'building'
  | 'calendar-plus'
  | 'calendar-search'
  | 'calendar'
  | 'camera'
  | 'capsule'
  | 'check-circle'
  | 'check-done'
  | 'check'
  | 'checkupreport'
  | 'circle-plus'
  | 'circle'
  | 'clock-three'
  | 'comment-edit'
  | 'comment'
  | 'consultation-status'
  | 'copy'
  | 'dashboard'
  | 'dental-checkup'
  | 'dental-decay'
  | 'dental-halitosis'
  | 'dental-implant'
  | 'dental-orthodontic'
  | 'dental-teeth-whitening'
  | 'dental-toothbrush'
  | 'dermatology'
  | 'edit_fill'
  | 'edit'
  | 'examiner'
  | 'exclamation-triangle'
  | 'exit'
  | 'eye-black'
  | 'eye-close'
  | 'eye'
  | 'facebook'
  | 'fever'
  | 'file-download-alt'
  | 'file-medical'
  | 'filter-fill'
  | 'filter'
  | 'gnav'
  | 'hand'
  | 'hide-sidebar'
  | 'instagram'
  | 'ladies'
  | 'left-black-arrow-circle'
  | 'left-white-arrow-circle'
  | 'line-green'
  | 'line'
  | 'list-ul'
  | 'logo-system-header-dental'
  | 'logo-system-header'
  | 'logout'
  | 'mail'
  | 'map-marker'
  | 'medical-checkup'
  | 'men'
  | 'menshealth'
  | 'message-template'
  | 'muscle'
  | 'no-smoking'
  | 'note'
  | 'order'
  | 'oyashirazu_15min'
  | 'oyashirazu_60min'
  | 'patient-search'
  | 'pc'
  | 'prescription-bottle'
  | 'question-circle'
  | 'refresh'
  | 'register'
  | 'remind-watch'
  | 'result-download'
  | 'right-arrow-circle'
  | 'right-white-arrow-circle'
  | 'save_template'
  | 'search'
  | 'settings'
  | 'show-sidebar'
  | 'sign-in-alt'
  | 'slack'
  | 'sleep_02'
  | 'sleep'
  | 'sns-share'
  | 'stethoscope-alt'
  | 'syringe'
  | 'tablets'
  | 'three-dot-line'
  | 'three-dot-row'
  | 'times-circle'
  | 'times'
  | 'todo-round'
  | 'tooth'
  | 'trash-empty'
  | 'trash'
  | 'triangle'
  | 'twitter'
  | 'upload'
  | 'urology'
  | 'user';

export const IconElements: Readonly<{ [name in IconName]: JSX.Element }> = {
  'account-circle-grey': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
      <path d="M12 2C6.49 2 2 6.49 2 12c0 3.26 1.58 6.15 4 7.98.25.18.5.36.76.52 1.53.95 3.32 1.5 5.24 1.5 1.92 0 3.71-.55 5.24-1.5.26-.16.51-.33.76-.52 2.42-1.83 4-4.72 4-7.98 0-5.51-4.49-10-10-10zm0 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm6 13.01c-.22.22-.45.42-.69.61-.26.21-.53.4-.81.58v-1.7c0-.83-.67-1.5-1.5-1.5H9c-.83 0-1.5.67-1.5 1.5v1.7A8.57 8.57 0 016 18.02v-.51c0-1.65 1.35-3 3-3h6c1.65 0 3 1.35 3 3v.51-.01z" />
    </svg>
  ),

  'account-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 13c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6.5a2.5 2.5 0 010 5 2.5 2.5 0 010-5z" />
      <path d="M12 2C6.49 2 2 6.49 2 12c0 3.26 1.58 6.15 4 7.98.25.18.5.36.76.52 1.53.95 3.32 1.5 5.24 1.5 1.92 0 3.71-.55 5.24-1.5.26-.16.51-.33.76-.52 2.42-1.83 4-4.72 4-7.98 0-5.51-4.49-10-10-10zm0 18.5c-1.65 0-3.19-.48-4.5-1.3v-1.7c0-.83.67-1.5 1.5-1.5h6c.83 0 1.5.67 1.5 1.5v1.7c-1.31.82-2.85 1.3-4.5 1.3zm6-2.49v-.51c0-1.65-1.35-3-3-3H9c-1.65 0-3 1.35-3 3v.51A8.49 8.49 0 013.5 12c0-4.69 3.81-8.5 8.5-8.5 4.69 0 8.5 3.81 8.5 8.5 0 2.35-.96 4.47-2.5 6.01z" />
    </svg>
  ),

  'angle-down': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.47 9.76L12 15.29l5.53-5.53-1.06-1.06L12 13.17 7.53 8.7 6.47 9.76z" />
    </svg>
  ),

  'angle-left': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.24 17.53L8.71 12l5.53-5.53 1.06 1.06L10.83 12l4.47 4.47-1.06 1.06z" />
    </svg>
  ),

  'angle-right': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.76 17.53L15.29 12 9.76 6.47 8.7 7.53 13.17 12 8.7 16.47l1.06 1.06z" />
    </svg>
  ),

  'angle-up': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.53 14.23L12 8.7l-5.53 5.53 1.06 1.06L12 10.82l4.47 4.47 1.06-1.06z" />
    </svg>
  ),

  'arrow-left-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 12c0-4.69-3.81-8.5-8.5-8.5-4.69 0-8.5 3.81-8.5 8.5 0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5zM2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10S2 17.51 2 12zm9.5-4.7l1.06 1.06-2.9 2.89h7.55v1.5H9.66l2.9 2.9-1.06 1.06L6.79 12l4.71-4.7z" />
    </svg>
  ),

  'arrow-right-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 12c0-4.69-3.81-8.5-8.5-8.5-4.69 0-8.5 3.81-8.5 8.5 0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5zM2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10S2 17.51 2 12zm10.5-4.7l-1.06 1.06 2.9 2.89H6.8v1.5h7.54l-2.9 2.9 1.06 1.06L17.21 12 12.5 7.3z" />
    </svg>
  ),

  'arrow-right': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.18 12.75h14.78l-6.43 6.44 1.06 1.06L20.83 12l-8.24-8.24-1.06 1.06 6.43 6.43H3.18v1.5z" />
    </svg>
  ),

  'arrow-up': (
    <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 20.83V6.05l6.43 6.43 1.06-1.06L12 3.17l-8.24 8.25 1.06 1.06 6.43-6.43v14.78h1.5z" />
    </svg>
  ),

  'attach-file': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 6.75v10c0 2.07-1.68 3.75-3.75 3.75-2.07 0-3.75-1.68-3.75-3.75v-11c0-1.24 1.01-2.25 2.25-2.25s2.25 1.01 2.25 2.25v9.5c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-8.5h-1.5v8.5c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25v-9.5C14.25 3.68 12.57 2 10.5 2 8.43 2 6.75 3.68 6.75 5.75v11a5.25 5.25 0 1010.5 0v-10h-1.5z" />
    </svg>
  ),

  babybottle: (
    <svg width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 9h-1l-3.5-3.5v-1a2.5 2.5 0 00-5 0v1L6 9H5v5h1v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-6h1V9zm-1.5 3.5h-11v-2h11v2zm-1 7.5c0 .27-.23.5-.5.5H8c-.27 0-.5-.23-.5-.5v-.5H10V18H7.5v-1.5H10V15H7.5v-1h9v6z" />
    </svg>
  ),

  beauty: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.63 19.9C21.28 18.86 20.1 18 19 18h-4v-1.89a5.442 5.442 0 002.36-3.42c.02 0 .04.02.07.03l.69.21.24-.68a6.7 6.7 0 00.39-2.25c0-2.35-1.21-4.42-3.04-5.63A3.734 3.734 0 0012 1c-1.94 0-3.52 1.48-3.71 3.37A6.744 6.744 0 005.25 10c0 .96.2 1.89.6 2.77l.23.52.56-.08.13-.03A5.5 5.5 0 009 16.11V18H5c-1.1 0-2.29.85-2.63 1.9L1.34 23h1.58l.88-2.63c.14-.43.75-.87 1.21-.87h5.5v-2.71c.48.14.98.21 1.5.21s1.02-.08 1.5-.21v2.71h5.5c.46 0 1.06.44 1.21.87L21.1 23h1.58l-1.03-3.1h-.02zM12 2.5c.79 0 1.48.41 1.88 1.02-.6-.17-1.23-.27-1.88-.27-.65 0-1.28.1-1.88.27.4-.61 1.09-1.02 1.88-1.02zm-4.99 9.12c-.17-.52-.26-1.06-.26-1.62 0-.07.02-.13.02-.2.06-1.47.73-2.78 1.75-3.7.93-.83 2.14-1.35 3.48-1.35 1.34 0 2.57.52 3.49 1.36 1.02.92 1.68 2.23 1.74 3.69 0 .07.02.13.02.2 0 .34-.04.69-.11 1.03-.49-.19-.91-.4-1.31-.61-.65-.35-1.2-.73-1.63-1.08a8.4 8.4 0 00.89-1.19l-1.31-.73s-.09.15-.26.39c-.21.29-.56.71-1.07 1.18-.07.06-.13.12-.21.19-.06.06-.13.11-.2.17-.08.07-.17.13-.25.2-.06.05-.13.1-.19.15-.13.09-.26.18-.4.28-.04.02-.07.05-.11.07-.24.15-.5.31-.78.45-.02.01-.05.03-.07.04-.64.33-1.37.63-2.22.86-.32.09-.65.17-1 .24l-.01-.02zM12 15.5c-1.06 0-2.02-.42-2.73-1.1a4.05 4.05 0 01-1.01-1.55c.3-.08.61-.17.89-.26.18-.05.35-.12.52-.18h.02c1.46-.57 2.59-1.31 3.42-2.01.68.57 1.62 1.21 2.81 1.75-.15.88-.57 1.67-1.2 2.26-.72.67-1.67 1.1-2.73 1.1l.01-.01zM3.25 6h1.5V4.75H6v-1.5H4.75V2h-1.5v1.25H2v1.5h1.25V6z" />
      <path d="M18 14.25v1.5h1.25V17h1.5v-1.25H22v-1.5h-1.25V13h-1.5v1.25H18z" />
    </svg>
  ),

  'bell-off': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 8.18l-1.5 1.5v4.94l2.88 2.88H9.68L8.18 19H22v-2l-3-3V8.18zM12 21.5c-.93 0-1.71-.64-1.93-1.5H8.55c.25 1.69 1.69 3 3.45 3 1.76 0 3.21-1.31 3.45-3h-1.52c-.22.86-1 1.5-1.93 1.5zM21.37 1.57L18.2 4.74A6.983 6.983 0 0012.01.99c-3.87 0-7 3.13-7 7v6l-3 3v2h1.94l-2.37 2.37 1.06 1.06L22.43 2.63l-1.06-1.06zM5.44 17.5H3.62l2.88-2.88V8c0-3.03 2.47-5.5 5.5-5.5 2.28 0 4.24 1.39 5.07 3.37L5.44 17.5z" />
    </svg>
  ),

  bell: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 8v6l3 3v2H2v-2l3-3V8c0-3.87 3.13-7 7-7s7 3.13 7 7zM6.5 14.62L3.62 17.5h16.76l-2.88-2.88V8c0-3.03-2.47-5.5-5.5-5.5A5.51 5.51 0 006.5 8v6.62zM12 21.5c.93 0 1.71-.64 1.93-1.5h1.52c-.24 1.69-1.69 3-3.45 3-1.76 0-3.21-1.31-3.45-3h1.52c.22.86 1 1.5 1.93 1.5z" />
    </svg>
  ),

  'black-filter': (
    <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_784_6201)">
        <path d="M13.217 2H3.965c-.727 0-1.322.6-1.322 1.333v9.334c0 .733.595 1.333 1.322 1.333h9.252c.727 0 1.322-.6 1.322-1.333V3.333C14.539 2.6 13.944 2 13.217 2z" />
        <path d="M7.857 11.333h1.468V10H7.857v1.333zm-2.57-6.667V6h6.608V4.667H5.287zm1.101 4h4.406V7.333H6.388v1.333z" />
      </g>
    </svg>
  ),

  block: (
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.454 11.064a5.333 5.333 0 01-7.518-7.518l7.518 7.518zm.9-.985L3.922 2.645a5.333 5.333 0 017.434 7.434zM13.667 7A6.667 6.667 0 11.333 7a6.667 6.667 0 0113.333 0z" />
    </svg>
  ),

  building: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 1v22h18V1H3zm11.5 20.5h-5v-3h5v3zm5 0H16V17H8v4.5H4.5v-19h15v19zM11.25 5.25h1.5v1.5h-1.5v-1.5zm4 4h1.5v1.5h-1.5v-1.5zm1.5 4h-1.5v1.5h1.5v-1.5zm-1.5-8h1.5v1.5h-1.5v-1.5zm-8 8h1.5v1.5h-1.5v-1.5zm1.5-4h-1.5v1.5h1.5v-1.5zm2.5 0h1.5v1.5h-1.5v-1.5zm1.5 4h-1.5v1.5h1.5v-1.5zm-5.5-8h1.5v1.5h-1.5v-1.5z" />
    </svg>
  ),

  'calendar-plus': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.75 1h-1.5v2H2v20h20V3h-4.25V1h-1.5v2h-8.5V1zm8.5 3.5h-8.5V7h-1.5V4.5H3.5v4.25h17V4.5h-2.75V7h-1.5V4.5zM3.5 21.5V10.25h17V21.5h-17zm7.75-8.5h1.5v2.25H15v1.5h-2.25V19h-1.5v-2.25H9v-1.5h2.25V13z" />
    </svg>
  ),

  'calendar-search': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.75 13.25h-1.5v1.5h1.5v-1.5zM12.75 13.25h-1.5v1.5h1.5v-1.5zM7.75 17.25h-1.5v1.5h1.5v-1.5zM12.75 17.25h-1.5v1.5h1.5v-1.5z" />
      <path d="M13.9 21.5H3.5V10.25h17v3.13c.55.21 1.05.51 1.5.88V3h-4.25V1h-1.5v2h-8.5V1h-1.5v2H2v20h13.35a5.45 5.45 0 01-1.45-1.5zM3.5 4.5h2.75V7h1.5V4.5h8.5V7h1.5V4.5h2.75v4.25h-17V4.5z" />
      <path d="M21.81 20.75s.03-.06.05-.09c.11-.16.2-.34.28-.52a4.42 4.42 0 00.24-.7c.02-.07.04-.14.05-.22.04-.24.07-.48.07-.73 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.25 0 .49-.03.73-.07.07-.01.14-.03.22-.05.16-.04.32-.09.48-.15.07-.03.14-.05.21-.09.18-.08.35-.17.52-.28.03-.02.06-.03.09-.05l2.19 2.19L24 22.93l-2.19-2.19v.01zM18.5 21a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" />
    </svg>
  ),

  calendar: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 1h1.5v2h8.5V1h1.5v2H22v20H2V3h4.25V1zm1.5 3.5h8.5V7h1.5V4.5h2.75v4.25h-17V4.5h2.75V7h1.5V4.5zM3.5 10.25V21.5h17V10.25h-17zm2.75 3h1.5v1.5h-1.5v-1.5zm5 0h1.5v1.5h-1.5v-1.5zm6.5 0h-1.5v1.5h1.5v-1.5zm-11.5 4h1.5v1.5h-1.5v-1.5zm6.5 0h-1.5v1.5h1.5v-1.5zm3.5 0h1.5v1.5h-1.5v-1.5z" />
    </svg>
  ),

  camera: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.28 3.5h9.44l1.09 3H22v14H2v-14h4.19l1.09-3zM3.5 8v11h17V8h-3.74l-1.09-3H8.33L7.24 8H3.5zm11 4.5a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0zm-6.5 0c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z" />
    </svg>
  ),

  capsule: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 3c-1.34 0-2.59.52-3.54 1.46l-8 8C3.52 13.4 3 14.66 3 16c0 1.34.52 2.59 1.46 3.54C5.4 20.49 6.66 21 8 21c1.34 0 2.59-.52 3.54-1.46l8-8C20.48 10.6 21 9.34 21 8c0-1.34-.52-2.59-1.46-3.54C18.6 3.51 17.34 3 16 3zm-5.52 15.47c-.66.66-1.54 1.02-2.47 1.02-.94 0-1.81-.36-2.47-1.02a3.478 3.478 0 01-1.02-2.48c0-.93.36-1.81 1.02-2.47l3.47-3.47L13.96 15l-3.47 3.47h-.01zM19.5 8c0 .93-.36 1.81-1.02 2.48l-3.47 3.47L10.06 9l3.47-3.47A3.43 3.43 0 0116 4.51c.94 0 1.81.36 2.47 1.02A3.43 3.43 0 0119.49 8h.01z" />
    </svg>
  ),

  'check-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.461 11.09l-.001-.01v.01h.001zm.049.91c0-.306-.02-.613-.049-.91h1.499c.03.3.05.61.05.92 0 6.08-4.92 11-11 11s-11-4.93-11-11 4.92-11 11-11c1.59 0 3.1.34 4.47.95l-.61 1.37a9.413 9.413 0 00-3.86-.83c-5.24 0-9.5 4.26-9.5 9.5s4.26 9.5 9.5 9.5 9.5-4.26 9.5-9.5zm-13.04-.47L12 15.06 22.53 4.53l-1.06-1.06L12 12.94l-2.47-2.47-1.06 1.06z" />
    </svg>
  ),

  'check-done': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" />
      <path d="M7 12.5l3 3L17.5 8" stroke="#fff" stroke-linejoin="round" />
    </svg>
  ),

  check: (
    <svg width="9" height="7" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.78 7.58l-1.06-1.06-8.84 8.84-4.6-4.6-1.06 1.06 4.6 4.6 1.06 1.06 1.06-1.06 8.84-8.84z" />
    </svg>
  ),

  checkupreport: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3v18h18V3H3zm16.5 16.5h-15v-15h15v15zM11.25 7h1.5v10h-1.5V7zm4 6h1.5v4h-1.5v-4zm-8-3h1.5v7h-1.5v-7z" />
    </svg>
  ),

  'circle-plus': (
    <svg width="18" height="19" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 11.25h-4.75V6.5h-1.5v4.75H6.5v1.5h4.75v4.75h1.5v-4.75h4.75v-1.5z" />
      <path d="M12 3.5c4.69 0 8.5 3.81 8.5 8.5 0 4.69-3.81 8.5-8.5 8.5-4.69 0-8.5-3.81-8.5-8.5 0-4.69 3.81-8.5 8.5-8.5zM12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2z" />
    </svg>
  ),

  circle: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18.5c-4.69 0-8.5-3.81-8.5-8.5 0-4.69 3.81-8.5 8.5-8.5 4.69 0 8.5 3.81 8.5 8.5 0 4.69-3.81 8.5-8.5 8.5z" />
    </svg>
  ),

  'clock-three': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10S2 17.51 2 12zm1.5 0c0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5 0-4.69-3.81-8.5-8.5-8.5-4.69 0-8.5 3.81-8.5 8.5zm7.75.75H16v-1.5h-3.25V6h-1.5v6.75z" />
    </svg>
  ),

  'comment-edit': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.88 5.53l-1.41-1.41h-.01l-5.22 5.22v1.41h1.42l5.22-5.22zm-8.14 3.19L18.46 2 22 5.54l-6.72 6.72h-3.54V8.72zM2.6 21.75H11c2.34 0 4.54-.91 6.19-2.56A8.698 8.698 0 0019.75 13h-1.5a7.2 7.2 0 01-2.12 5.13A7.2 7.2 0 0111 20.25H6.22l.89-.89-1.24-1.24a7.2 7.2 0 01-2.12-5.13 7.2 7.2 0 012.12-5.13A7.2 7.2 0 0111 5.74v-1.5c-2.34 0-4.54.91-6.19 2.56a8.698 8.698 0 00-2.56 6.19c0 2.34.91 4.54 2.56 6.19l.18.18-2.39 2.39z" />
    </svg>
  ),

  comment: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 12c0-4.69-3.81-8.5-8.5-8.5-2.27 0-4.41.88-6.01 2.49A8.416 8.416 0 003.5 12c0 2.27.88 4.41 2.49 6.01l.09.09 1.06 1.06-1.06 1.06-.28.28H12c2.27 0 4.4-.88 6.01-2.49A8.416 8.416 0 0020.5 12zM4.93 4.93A9.932 9.932 0 0112 2c5.51 0 10 4.49 10 10 0 2.67-1.04 5.18-2.93 7.07A9.932 9.932 0 0112 22H2.18l2.84-2.84-.09-.09A9.932 9.932 0 012 12c0-2.67 1.04-5.18 2.93-7.07zm2.32 6.32h1.5v1.5h-1.5v-1.5zm8 0h1.5v1.5h-1.5v-1.5zm-2.5 0h-1.5v1.5h1.5v-1.5z" />
    </svg>
  ),

  'consultation-status': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 14h-1.5v3h1.5v-3zM12.75 11h-1.5v1.5h1.5V11zM16.75 7h-1.5v10h1.5V7z" />
      <path d="M3 3v18h18V3H3zm16.5 16.5h-15v-15h15v15z" />
      <path d="M8.75 13h-1.5v4h1.5v-4z" />
    </svg>
  ),

  copy: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 16.5v-13h9v1H16V2H4v16h2.5v-1.5h-1z" />
      <path d="M8 6v16h12V6H8zm10.5 14.5h-9v-13h9v13z" />
    </svg>
  ),

  dashboard: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.19 14.25a8.557 8.557 0 01-5.93 5.93v-4.3c.68-.39 1.24-.96 1.64-1.64h4.3m1.78-1.5h-7.07a2.975 2.975 0 01-2.14 2.14v7.07c4.92-.37 8.84-4.3 9.21-9.21zM14.25 3.81c2.86.79 5.14 3.07 5.93 5.93h-4.3a4.47 4.47 0 00-1.63-1.63v-4.3zm-1.5-1.78V9.1c1.05.27 1.87 1.09 2.14 2.14h7.07c-.37-4.92-4.3-8.84-9.21-9.21zM9.75 3.81v4.3A4.48 4.48 0 007.5 12c0 1.64.88 3.1 2.25 3.89v4.3c-3.38-.94-5.95-3.95-6.22-7.58-.01-.2-.03-.4-.03-.61 0-.21.02-.41.03-.64.27-3.6 2.83-6.61 6.22-7.55zm1.5-1.78c-4.92.37-8.84 4.3-9.21 9.21-.02.25-.04.5-.04.75s.02.5.04.75c.37 4.92 4.3 8.84 9.21 9.21v-7.07A2.992 2.992 0 019 11.99c0-1.39.96-2.56 2.25-2.89V2.03z" />
    </svg>
  ),

  'dental-checkup': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 5c0-1.66-1.34-3-3-3s-3 1.34-3 3c0 1.4.96 2.56 2.25 2.89V10H9V7.89c1.29-.33 2.25-1.5 2.25-2.89zm-3 1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM9.5 11H7v11h2.5V11zM17.05 2L15 4.05V8h1.5V4.67l1.61-1.61L17.05 2zM17 9h-2.5v13H17V9z" />
    </svg>
  ),

  'dental-decay': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.27 2H4.73L2 8.36 5.64 22h3.64l1.82-6.36h1.82L14.74 22h3.64l3.64-13.64L19.29 2h-.02zm-2.06 18.5h-1.35l-1.51-5.28-.31-1.09H9.96l-.31 1.09-1.51 5.28H6.79L3.58 8.48 5.71 3.5h4.69L8.77 7.95h2.51l-1.13 5.2 5.81-7.85h-3.27l1.02-1.8h4.57l2.13 4.98L17.2 20.5h.01z" />
    </svg>
  ),

  'dental-halitosis': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.96 10.18h-1.42l-1.04 1.04-1.04-1.04h-1.42L3.5 16.72l5.27 5.27h7.45l5.27-5.27-6.54-6.54h.01zm.65 10.32H9.4l-3.77-3.77 5.04-5.04h.18l1.66 1.66 1.66-1.66h.18l5.04 5.04-3.77 3.77h-.01z" />
      <path d="M14.25 15.13h-3.5l-1.59 1.6L10.43 18h4.14l1.27-1.27-1.59-1.6zM8.5 5v3H10V3.5H7V2H5.5v3h3z" />
      <path d="M5.5 8v2.5h3V9H7V6.5H4V4H2.5v4h3z" />
    </svg>
  ),

  'dental-implant': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.14 19.72v1.52c1.62.56 3.53.76 4.86.76 1.33 0 3.25-.2 4.86-.76v-1.52c-1.18.44-2.8.78-4.86.78-2.06 0-3.69-.34-4.86-.78zM21.13 11.63L22 8.36 19.27 2H4.72L1.99 8.36l.87 3.27c-.5-.11-.96-.23-1.37-.35v2.55c.65.18 1.35.33 2.08.47.53.1 1.07.18 1.62.26.64.09 1.29.15 1.93.21v1.32c1.62.56 3.53.76 4.86.76 1.33 0 3.25-.2 4.86-.76v-1.32c.64-.06 1.29-.12 1.94-.21.55-.07 1.09-.16 1.62-.25.73-.13 1.43-.29 2.08-.47v-2.55c-.41.12-.87.24-1.37.35l.02-.01zm-1.64.32c-1.97.32-4.46.55-7.49.55-3.03 0-5.52-.23-7.49-.55l-.93-3.47L5.71 3.5h12.57l2.13 4.98-.93 3.47h.01zM7.14 17.14v1.52c1.62.56 3.53.76 4.86.76 1.33 0 3.25-.2 4.86-.76v-1.52c-1.18.44-2.8.78-4.86.78-2.06 0-3.69-.34-4.86-.78z" />
    </svg>
  ),

  'dental-orthodontic': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.72 3.5h12.57l1.44 3.36c.58-.1 1.07-.21 1.5-.32L19.28 2H4.73L2.79 6.52c.42.11.92.21 1.5.32L5.72 3.5zM17.22 20.5h-1.35l-1.51-5.28-.31-1.09H9.97l-.31 1.09-1.51 5.28H6.8l-2.43-9.11c-.56-.09-1.1-.18-1.63-.29L5.65 22h3.64l1.82-6.36h1.82L14.75 22h3.64l2.9-10.87c-.53.11-1.07.2-1.63.28l-2.42 9.09h-.02z" />
      <path d="M15.06 8.87V7.71h-6v1.16c-3.38-.18-5.91-.65-7.56-1.15v1.56c2.22.62 5.05.95 7.56 1.09v1.34h6v-1.34c2.47-.14 5.24-.46 7.44-1.06V7.76c-1.66.49-4.14.94-7.44 1.12v-.01z" />
    </svg>
  ),

  'dental-teeth-whitening': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.21 20.5h-1.35l-1.51-5.27-.31-1.09H9.96l-.31 1.09-1.51 5.27H6.79L3.58 8.48 5.72 3.5H13V2H4.73L2 8.37 5.64 22h3.63l1.82-6.36h1.82L14.73 22h3.63l2.94-11h-1.56l-2.53 9.5z" />
      <path d="M17.75 3.25V1.5h-1.5v1.75H14.5v1.5h1.75V6.5h1.5V4.75h1.75v-1.5h-1.75zM21.25 6.75V5.5h-1.5v1.25H18.5v1.5h1.25V9.5h1.5V8.25h1.25v-1.5h-1.25z" />
    </svg>
  ),

  'dental-toothbrush': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.74 4.75V3h-1.5v1.75h-1.75v1.5h1.75V8h1.5V6.25h1.75v-1.5h-1.75zM18.24 8.25V7h-1.5v1.25h-1.25v1.5h1.25V11h1.5V9.75h1.25v-1.5h-1.25zM12.11 11.32H9.28l-.62-.62 1.76-1.77-.7-.71-1.77 1.77-.71-.71 1.77-1.77-.71-.7-1.76 1.77-.71-.71L7.6 6.1l-.71-.71-1.77 1.77-.71-.71L3 7.87l5.3 5.3h2.83l7.43 7.43 1.41-1.42-7.86-7.86z" />
    </svg>
  ),

  dermatology: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.25 2v2h3v13.25a3.759 3.759 0 01-3 3.67V24H6.75v-3.08a3.759 3.759 0 01-3-3.67V8.5h.75c.85 0 1.62.29 2.25.77V2h3V0h4.5v2h3zm1.36 16c.09-.24.14-.49.14-.75V5.5h-1.5V11h-1.5V3.5h-1.5V11h-1.5V1.5h-1.5V11h-1.5V3.5h-1.5V13h-1.5v-.75c0-.26-.05-.51-.13-.75l-.008-.014c-.007-.012-.012-.021-.012-.036-.05-.12-.11-.23-.17-.34a.88.88 0 01-.03-.05l-.03-.05c-.08-.12-.17-.24-.27-.34a.098.098 0 01-.025-.02.098.098 0 00-.025-.02c-.09-.09-.19-.17-.3-.24a.36.36 0 00-.1-.07c-.13-.07-.26-.14-.4-.19v7.12c0 .26.06.51.14.75.23.63.72 1.13 1.36 1.36.24.09.49.14.75.14h.75v3h7.5v-3h.75c.26 0 .51-.06.75-.14A2.26 2.26 0 0018.61 18zm-8.86-4h1.5v1.5h-1.5V14zm4 0h1.5v1.5h-1.5V14zm-.5 3h-1.5v1.5h1.5V17z" />
    </svg>
  ),

  edit_fill: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.63 17.83v3.54h3.54L18.19 9.35l-3.54-3.54L2.63 17.83zM17.834 2.633l-2.122 2.12 3.536 3.536 2.121-2.12-3.535-3.536z" />
    </svg>
  ),

  edit: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.07 9.35L5.55 19.87H4.14v-1.41L14.66 7.94l1.41 1.41zm2.12 0l-3.54-3.54L2.63 17.83v3.54h3.54L18.19 9.35zM17.838 2.628l-2.12 2.121 3.535 3.536 2.121-2.122-3.536-3.535z" />
    </svg>
  ),

  examiner: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2v20h20V2H2zm5.5 18.5V19c0-.83.67-1.5 1.5-1.5h6c.83 0 1.5.67 1.5 1.5v1.5h-9zm13 0H18V19c0-1.65-1.35-3-3-3H9c-1.65 0-3 1.35-3 3v1.5H3.5v-17h17v17z" />
      <path d="M12.01 14.5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6.5a2.5 2.5 0 010 5 2.5 2.5 0 010-5z" />
    </svg>
  ),

  'exclamation-triangle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5.12l8.46 15.39H3.53l8.46-15.39H12zm-.01-3.11l-11 20h22l-11-20zm.76 7.99h-1.5v5h1.5v-5zm-1.5 6.25h1.5v1.5h-1.5v-1.5z" />
    </svg>
  ),

  exit: (
    <svg width="32" height="33" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.91 11.25H4.87l2.19-2.19L6 8l-4 4 4 4 1.06-1.06-2.19-2.19h8.04v-1.5z" />
      <path d="M9 3v6.75h1.5V4.5h9v15h-9v-5.25H9V21h12V3H9z" />
    </svg>
  ),

  'eye-black': (
    <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 14.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
      <path d="M12 6C7.69 6 3.94 8.42 2 12c1.94 3.58 5.69 6 10 6s8.06-2.42 10-6c-1.94-3.58-5.69-6-10-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
    </svg>
  ),

  'eye-close': (
    <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.41 9.68l-1.15-.97c-1.83 2.79-4.92 4.5-8.25 4.5s-6.42-1.71-8.25-4.5l-1.15.97c.84 1.24 1.92 2.3 3.17 3.13L4.5 15.02l1.3.75 1.28-2.21c1.28.63 2.69 1.01 4.19 1.11v2.55h1.5v-2.55c1.49-.1 2.91-.49 4.19-1.11l1.28 2.21.65-.38.65-.38-1.28-2.21c1.24-.83 2.33-1.88 3.17-3.13l-.02.01z" />
    </svg>
  ),

  eye: (
    <svg width="24" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7.5c3.33 0 6.42 1.71 8.25 4.5-1.83 2.79-4.92 4.5-8.25 4.5S5.58 14.79 3.75 12C5.58 9.21 8.67 7.5 12 7.5zM12 6C7.69 6 3.94 8.42 2 12c1.94 3.58 5.69 6 10 6s8.06-2.42 10-6c-1.94-3.58-5.69-6-10-6z" />
      <path d="M12 9.5a2.5 2.5 0 010 5 2.5 2.5 0 010-5zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
    </svg>
  ),

  facebook: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9 2H3.1A1.1 1.1 0 002 3.1v17.8A1.1 1.1 0 003.1 22h9.58v-7.75h-2.6v-3h2.6V9a3.64 3.64 0 013.88-4 20.26 20.26 0 012.33.12v2.7H17.3c-1.26 0-1.5.6-1.5 1.47v1.93h3l-.39 3H15.8V22h5.1a1.102 1.102 0 001.1-1.1V3.1A1.101 1.101 0 0020.9 2z" />
    </svg>
  ),

  fever: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.19 8h1.5V4.25h-2V2h-1.5v3.75h2V8zm-2.5 4h-1.5V9.75h-2v-2h-2V4h1.5v2.25h2v2h2V12zm14.08.8l.04-.29c0-2.9-2.26-5.28-5.11-5.48-.065 0-.13-.005-.195-.01a2.546 2.546 0 00-.195-.01c-.71 0-1.38.14-2 .38-2.05.8-3.5 2.79-3.5 5.12v.72l-.17.28-2.7 2.5 1.87 5 3-1v2h1.5v-2.5l4.26-1.42-.3.98 1.79 2.94h1.76l-1.91-3.14 1.87-6.06-.01-.01zm-7.46 5.13l-3.6 1.2-.42-1.12-.59-1.58 1.53-1.42h3.08v2.92zm-2-4.42v-1c0-1.65 1-3.11 2.55-3.72.47-.19.96-.28 1.45-.28.08 0 .15 0 .22.01h.06c2.05.15 3.66 1.84 3.72 3.89v.06l-.33 1.04h-7.67zm3.5 3.39v-1.89h3.71l-.23.73-3.48 1.16z" />
    </svg>
  ),

  'file-download-alt': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 3.5h-7v17H15V22H4V2h9.41L20 8.59V13h-1.5V9.5h-6v-6zM17 8l-3-3v3h3zm-6 .25H8v1.5h3v-1.5zm5 4H8v1.5h8v-1.5zm-8 5.5v-1.5h6v1.5H8zM22.25 19l-1.06-1.06L20 19.13V15h-1.5v4.13l-1.19-1.19L16.25 19l3 3 3-3z" />
    </svg>
  ),

  'file-medical': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.01 2h9.41l6.59 6.59V22h-16V2zm13 6l-3-3v3h3zM5.51 20.5h13v-11h-6v-6h-7v17zM9 14.25h2.25V12h1.5v2.25H15v1.5h-2.25V18h-1.5v-2.25H9v-1.5z" />
    </svg>
  ),

  'filter-fill': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3l6.75 8.25V21l4.5-3v-6.75L21 3H3z" />
    </svg>
  ),

  filter: (
    <svg width="10" height="10" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.84 4.5l-4.75 5.8-.34.42v6.48l-1.5 1v-7.48l-.34-.42-4.75-5.8h11.67M21 3H3l6.75 8.25V21l4.5-3v-6.75L21 3z" />
    </svg>
  ),

  gnav: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 6H2v1.5h20V6zm0 10.5H2V18h20v-1.5zM2 11.25h20v1.5H2v-1.5z" />
    </svg>
  ),

  hand: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.25 8V6h-6V0h-4.5v9.27c-.63-.48-1.4-.77-2.25-.77h-.75v8.75a3.759 3.759 0 003 3.67V24h10.5v-3.08a3.759 3.759 0 003-3.67V8h-3zm1.5 9.25c0 .26-.05.51-.14.75a2.26 2.26 0 01-1.36 1.36c-.24.08-.49.14-.75.14h-.75v3h-7.5v-3H7.5c-.26 0-.51-.05-.75-.14A2.26 2.26 0 015.39 18c-.08-.24-.14-.49-.14-.75v-7.12c.14.05.27.12.4.19.04.02.07.04.1.07.11.07.21.15.3.24.02.01.03.03.05.04.1.1.19.22.27.34.02.03.04.07.06.1.06.11.12.22.17.34 0 .02.01.03.02.05.08.24.13.49.13.75V13h1.5V1.5h1.5V11h1.5V7.5h1.5V11h1.5V7.5h1.5V11h1.5V9.5h1.5v7.75z" />
    </svg>
  ),

  'hide-sidebar': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2v20h20V2H2zm1.5 18.5v-17h3v17h-3zm17 0H8v-17h12.5v17z" />
      <path d="M16.55 14.47L14.08 12l2.47-2.47-1.06-1.07L11.96 12l3.53 3.54 1.06-1.07z" />
    </svg>
  ),

  instagram: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.34 5.46a1.2 1.2 0 100 2.4 1.2 1.2 0 000-2.4zm4.6 2.42a7.588 7.588 0 00-.46-2.43 4.94 4.94 0 00-1.16-1.77 4.7 4.7 0 00-1.77-1.15 7.3 7.3 0 00-2.43-.47C15.06 2 14.72 2 12 2s-3.06 0-4.12.06a7.3 7.3 0 00-2.43.47 4.78 4.78 0 00-1.77 1.15 4.7 4.7 0 00-1.15 1.77 7.3 7.3 0 00-.47 2.43C2 8.94 2 9.28 2 12s0 3.06.06 4.12a7.3 7.3 0 00.47 2.43 4.7 4.7 0 001.15 1.77 4.78 4.78 0 001.77 1.15 7.3 7.3 0 002.43.47C8.94 22 9.28 22 12 22s3.06 0 4.12-.06a7.3 7.3 0 002.43-.47 4.7 4.7 0 001.77-1.15 4.85 4.85 0 001.16-1.77c.285-.78.44-1.6.46-2.43 0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12zM20.14 16a5.61 5.61 0 01-.34 1.86 3.06 3.06 0 01-.75 1.15c-.324.33-.717.586-1.15.75a5.61 5.61 0 01-1.86.34c-1 .05-1.37.06-4 .06s-3 0-4-.06a5.73 5.73 0 01-1.94-.3 3.27 3.27 0 01-1.1-.75 3 3 0 01-.74-1.15 5.54 5.54 0 01-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4a5.54 5.54 0 01.35-1.9A3 3 0 015 5a3.14 3.14 0 011.1-.8A5.73 5.73 0 018 3.86c1 0 1.37-.06 4-.06s3 0 4 .06a5.61 5.61 0 011.86.34 3.06 3.06 0 011.19.8c.328.307.584.683.75 1.1.222.609.337 1.252.34 1.9.05 1 .06 1.37.06 4s-.01 3-.06 4zM12 6.87A5.13 5.13 0 1017.14 12 5.12 5.12 0 0012 6.87zm0 8.46a3.33 3.33 0 110-6.66 3.33 3.33 0 010 6.66z" />
    </svg>
  ),

  ladies: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 12.92V10.5a9 9 0 00-18 0v2.42c-1.76.77-3 2.53-3 4.58 0 2.76 2.24 5 5 5 1.4 0 2.66-.58 3.56-1.5.96.78 2.15 1.25 3.44 1.25s2.48-.47 3.44-1.25c.91.92 2.17 1.5 3.56 1.5 2.76 0 5-2.24 5-5 0-2.05-1.24-3.8-3-4.58zm-6.43 6.84c-.72.61-1.6.99-2.57.99-.97 0-1.85-.38-2.57-.99-.43-.37-.79-.82-1.07-1.34-.38-.71-.61-1.53-.61-2.42v-4.5h3.75c1.08 0 2.05-.47 2.73-1.2.45.66 1.18 1.09 2.02 1.17V16c0 .89-.23 1.71-.61 2.42-.28.52-.64.98-1.07 1.34zM19 21c-.99 0-1.89-.42-2.52-1.09A6.577 6.577 0 0017.75 16v-4.5h.75V10h-2c-.09 0-.17-.03-.25-.05-.57-.12-1-.6-1-1.2v-2h-1.5v1c0 .17-.02.34-.06.5-.23 1-1.12 1.75-2.19 1.75h-6v1.5h.75V16c0 1.48.48 2.84 1.27 3.91C6.88 20.58 5.99 21 5 21c-1.93 0-3.5-1.57-3.5-3.5 0-1.28.7-2.39 1.73-3 .38-.23.81-.38 1.27-.45V10.5C4.5 6.36 7.86 3 12 3c4.14 0 7.5 3.36 7.5 7.5v3.55c.46.07.89.22 1.27.45 1.03.61 1.73 1.72 1.73 3 0 1.93-1.57 3.5-3.5 3.5z" />
    </svg>
  ),

  'left-black-arrow-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" stroke="#000" />
      <path d="M13.5 7.5l-4 4.5 4 4.5" stroke="#2D1F20" />
    </svg>
  ),

  'left-white-arrow-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm2.38 13.65l-1.06 1.06L8.61 12l4.71-4.71 1.06 1.06L10.73 12l3.65 3.65z" />
    </svg>
  ),

  'line-green': (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.373 12.613a.508.508 0 00-.506.507v2.227L16 12.867a.534.534 0 00-.44-.254.508.508 0 00-.507.507v3.787a.506.506 0 101.014 0v-2.24l1.853 2.546a.36.36 0 00.2.147.426.426 0 00.187 0 .439.439 0 00.36-.027l.133-.093a.52.52 0 00.147-.36v-3.76a.508.508 0 00-.574-.507zm-6.106 3.747h-1.414v-3.24a.507.507 0 00-1.013 0v3.787a.507.507 0 00.507.506h1.92a.52.52 0 00.52-.506.52.52 0 00-.52-.547zm1.48-3.747a.52.52 0 00-.52.507v3.787a.52.52 0 00.52.506.507.507 0 00.506-.506V13.12a.507.507 0 00-.506-.507zM23.88 2.667H8.12A5.467 5.467 0 002.667 8.12v15.76a5.466 5.466 0 005.453 5.453h15.76a5.466 5.466 0 005.453-5.453V8.12a5.467 5.467 0 00-5.453-5.453zm.413 16.373c-.052.08-.11.155-.173.227a7.337 7.337 0 01-1.067 1.066c-2.666 2.494-7.146 5.48-7.746 5.014-.6-.467.853-2.347-.707-2.667-.11.014-.223.014-.333 0-4.587-.64-8-3.854-8-7.707 0-4.333 4.386-7.84 9.786-7.84 5.4 0 9.787 3.507 9.787 7.84a6.666 6.666 0 01-1.547 4.067zm-2.28-6.413H20a.507.507 0 00-.507.506v3.787a.506.506 0 00.507.506h1.973a.506.506 0 100-1.013H20.6v-.8h1.413a.52.52 0 00.507-.52.507.507 0 00-.507-.507H20.6v-.813h1.413a.507.507 0 00.507-.506.507.507 0 00-.507-.64z" />
    </svg>
  ),

  line: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.78 9.46a.38.38 0 00-.38.38v1.67L12 9.65a.4.4 0 00-.33-.19.38.38 0 00-.38.38v2.84a.38.38 0 00.38.38.38.38 0 00.38-.38V11l1.39 1.91a.27.27 0 00.15.11c.046.01.094.01.14 0A.332.332 0 0014 13l.1-.07a.39.39 0 00.11-.27V9.84a.379.379 0 00-.43-.38zM9.2 12.27H8.14V9.84a.38.38 0 00-.38-.38.38.38 0 00-.38.38v2.84a.38.38 0 00.38.38H9.2a.39.39 0 00.39-.38.39.39 0 00-.39-.41zm1.11-2.81a.39.39 0 00-.39.38v2.84a.39.39 0 00.39.38.38.38 0 00.38-.38V9.84a.38.38 0 00-.38-.38zM17.91 2H6.09A4.1 4.1 0 002 6.09v11.82A4.1 4.1 0 006.09 22h11.82A4.1 4.1 0 0022 17.91V6.09A4.1 4.1 0 0017.91 2zm.31 12.28a5.5 5.5 0 01-.93.97c-2 1.87-5.36 4.11-5.81 3.76-.45-.35.64-1.76-.53-2a.996.996 0 01-.25 0c-3.44-.48-6-2.89-6-5.78 0-3.25 3.29-5.88 7.34-5.88s7.34 2.63 7.34 5.88a5 5 0 01-1.16 3.05zm-1.71-4.81H15a.38.38 0 00-.38.38v2.84a.38.38 0 00.38.38h1.48a.38.38 0 00.38-.38.38.38 0 00-.38-.38h-1.03v-.6h1.06a.39.39 0 00.38-.39.38.38 0 00-.38-.38h-1.06v-.61h1.06a.38.38 0 00.38-.38.38.38 0 00-.38-.48z" />
    </svg>
  ),

  'list-ul': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 6H5v1.5h17V6zM22 16.5H5V18h17v-1.5zM22 11.25H5v1.5h17v-1.5zM3.5 6H2v1.5h1.5V6zM3.5 16.5H2V18h1.5v-1.5zM3.5 11.25H2v1.5h1.5v-1.5z" />
    </svg>
  ),

  'logo-system-header-dental': (
    <svg viewBox="0 0 119 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.747 0H45.19v2.19h18.557V0zM55.56 6.328h-2.182V24h2.182V6.328zM116.818 0v20.084l-.148-.202L102.516 0h-2.077v24h2.183V3.916l.144.202L116.923 24H119V0h-2.182zM91.373 0H72.815v2.19h18.558V0zM91.373 21.81H72.815V24h18.558v-2.19zM82.097 9.679c-.884 0-1.652.498-2.039 1.227h-7.239v2.191h7.239a2.312 2.312 0 002.038 1.227c.884 0 1.649-.498 2.039-1.227h7.239v-2.19h-7.239a2.31 2.31 0 00-2.038-1.228zM0 16.872c0-1.855 1.347-3.122 3.036-3.122 1.232 0 1.963.633 2.403 1.357l-.88.463a1.753 1.753 0 00-1.523-.877c-1.117 0-1.98.915-1.98 2.182s.863 2.182 1.98 2.182c.677 0 1.242-.372 1.523-.877l.88.463C4.989 19.367 4.268 20 3.036 20 1.347 19.993 0 18.726 0 16.872zM7.587 13.847h1.029v5.106h2.586v.932H7.587v-6.038zM14.336 13.847h-1.029v6.035h1.03v-6.035zM17.968 15.486v4.4h-1.032v-6.039h1.056l3.002 4.28v-4.28h1.029v6.038h-.995l-3.06-4.399zM25.668 13.847h-1.03v6.035h1.03v-6.035zM27.973 16.872c0-1.855 1.347-3.122 3.036-3.122 1.231 0 1.963.633 2.402 1.357l-.88.463a1.753 1.753 0 00-1.522-.877c-1.117 0-1.98.915-1.98 2.182s.863 2.182 1.98 2.182c.677 0 1.242-.372 1.523-.877l.88.463c-.45.724-1.172 1.357-2.403 1.357-1.69-.007-3.036-1.274-3.036-3.128zM4.13 7c0 1.166-.835 2.126-1.996 2.126H1.1V4.883h1.033c1.161 0 1.996.951 1.996 2.117zm1.127 0c0-1.646-1.308-2.991-3.123-2.991H0V10h2.134c1.815 0 3.123-1.354 3.123-3zM10.917 10v-.874H8.103V7.497h2.452v-.874H8.103v-1.74h2.814V4H7.002v6h3.915zM16.924 10h.826V4h-1.101v4.02L13.672 4h-.835v6h1.102V5.954L16.924 10zM19.433 4v.883h1.841V10h1.101V4.883h1.859V4h-4.8zM25.72 10l.439-1.174h2.65L29.265 10h1.127l-2.34-6h-1.145l-2.323 6h1.136zm2.77-2.066H26.47l1.015-2.82 1.006 2.82zM33.126 9.126V4h-1.1v6h3.836v-.874h-2.736z" />
    </svg>
  ),

  'logo-system-header': (
    <svg viewBox="0 0 120 24" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_5839)">
        <path d="M0 12.005c0-1.712 1.279-2.882 2.882-2.882 1.169 0 1.863.585 2.28 1.253l-.835.427a1.672 1.672 0 00-1.445-.81c-1.06 0-1.88.846-1.88 2.015 0 1.17.82 2.014 1.88 2.014.642 0 1.179-.344 1.445-.81l.835.428c-.427.668-1.111 1.253-2.28 1.253C1.279 14.886 0 13.717 0 12.005zM7.202 9.213h.977v4.713h2.454v.86h-3.43V9.214zM13.607 9.213h-.976v5.57h.976v-5.57zM17.055 10.726v4.06h-.98V9.214h1.003l2.849 3.951v-3.95h.977v5.573h-.945l-2.904-4.06zM24.363 9.213h-.976v5.57h.976v-5.57zM26.55 12.005c0-1.712 1.28-2.882 2.882-2.882 1.17 0 1.864.585 2.281 1.253l-.835.427a1.672 1.672 0 00-1.446-.81c-1.06 0-1.879.846-1.879 2.015 0 1.17.82 2.014 1.88 2.014.642 0 1.178-.344 1.445-.81l.835.428c-.427.668-1.111 1.253-2.28 1.253-1.604-.007-2.882-1.176-2.882-2.888zM63.991 0H45.362v2.19h18.63V0zM55.774 6.328h-2.191V24h2.19V6.328zM117.266 0v20.084l-.147-.202L102.91 0h-2.085v24h2.191V3.916l.145.202L117.372 24h2.085V0h-2.191zM91.725 0h-18.63v2.19h18.63V0zM91.725 21.81h-18.63V24h18.63v-2.19zM82.411 9.679c-.886 0-1.657.498-2.046 1.227H73.1v2.191h7.266a2.322 2.322 0 004.093 0h7.266v-2.19h-7.266a2.319 2.319 0 00-2.047-1.228z" />
      </g>
    </svg>
  ),

  logout: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.37 19.5v-15h9V3H4.87v18h10.5v-1.5h-9z" />
      <path d="M9.22 12.75h9.04l-2.89 2.9 1.06 1.06 4.7-4.71-4.7-4.71-1.06 1.06 2.89 2.9H9.22v1.5z" />
    </svg>
  ),

  mail: (
    <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6v12h18V6H3zm14.4 1.5L12 11.1 6.6 7.5H17.4zm2.1 9h-15V7.9l7.5 5 7.5-5v8.6z" />
    </svg>
  ),

  'map-marker': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 10c0-3.58-2.92-6.5-6.5-6.5v.01c-3.58 0-6.5 2.92-6.5 6.5 0 1.56.57 3.07 1.6 4.25l.38.44L12 19.75l4.65-5.2.25-.29c1.03-1.19 1.6-2.7 1.6-4.26zM4 10.01c0-4.42 3.58-8 8-8a7.998 7.998 0 016.04 13.24L12 22.01l-6.04-6.76A7.943 7.943 0 014 10.01zM14.5 10a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0zM8 10c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z" />
    </svg>
  ),

  'medical-checkup': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 7H7V1h10v6zM8.5 5.5h7v-3h-7v3z" />
      <path d="M21 23H3V3h5v1.5H4.5v17h15v-17H16V3h5v20z" />
      <path d="M21 23H3V3h5v1.5H4.5v17h15v-17H16V3h5v20z" />
      <path d="M16 11H8v1.5h8V11zM13 15.5H8V17h5v-1.5z" />
    </svg>
  ),

  men: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.97 9.75L21 9.5C21 5.36 17.64 2 13.5 2 9.36 2 6 5.36 6 9.5v1.41l-3 5h3V20h2v2h1.5v-2h2v-1.5h-4v-4.09H5.65l1.64-2.73.21-.36V9.49c0-3.31 2.69-6 6-6s5.95 2.64 6 5.91v.06l-1.87 7.14-.12.47.17.45 1.14 2.98.58 1.5h1.61l-1.92-5.02 1.89-7.23h-.01z" />
    </svg>
  ),

  menshealth: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 11.02v-.32l.32-.05.08-.54c.06-.41.09-.77.09-1.12 0-4-3.25-7.25-7.25-7.25S4.74 4.99 4.74 8.99c0 .34.03.71.09 1.12l.14.91.26-.1v.1c-1.54.13-2.75 1.4-2.75 2.97 0 1.57 1.3 2.95 2.92 2.99.68 3.01 3.37 5.26 6.58 5.26s5.9-2.25 6.58-5.26a2.99 2.99 0 002.92-2.99c0-1.63-1.21-2.85-2.75-2.97h.02zm-13.5 4.45C4.54 15.35 4 14.74 4 14s.54-1.35 1.25-1.47v2.95-.01zm1.43-8.64c.31-.77.79-1.46 1.38-2.02 0 0 .12-.12.43-.37a5.71 5.71 0 013.5-1.19c2.41 0 4.47 1.49 5.32 3.59.27.67.43 1.4.43 2.16 0 .11 0 .23-.01.35l-.49.06c-3.56.42-5.58-.28-6.71-1.07-.35-.24-.61-.49-.81-.72l-.69-.78-.57-.64-.44.64-.56.82c-.16.25-.36.48-.59.68-.04.04-.09.07-.13.11-.17.14-.33.26-.5.37.02-.7.18-1.36.43-1.97l.01-.02zm10.57 8.66c0 .4-.05.78-.13 1.15-.53 2.34-2.62 4.1-5.12 4.1s-4.59-1.76-5.12-4.1c-.08-.37-.13-.75-.13-1.15v-5.24c.59-.34 1.34-.88 1.88-1.64.95 1.09 2.87 2.42 6.61 2.42.62 0 1.3-.05 2.02-.13v4.59h-.01zm1.5-.03v-2.95c.71.12 1.25.73 1.25 1.47s-.54 1.35-1.25 1.47v.01z" />
    </svg>
  ),

  'message-template': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 14.25l-1.06 1.06 1.19 1.19H15.5V18h3.63l-1.19 1.19L19 20.25l3-3-3-3z" />
      <path d="M4.5 16.5V7.9l7.5 5 7.5-5v5.2H21V6H3v12h11v-1.5H4.5zm12.9-9L12 11.1 6.6 7.5H17.4z" />
    </svg>
  ),

  muscle: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 2.86L8.71 2 7.1 5.21l1.72 4.02h3.64l.84 2.91c-.11 0-.21-.03-.31-.03-1.05 0-1.99.43-2.67 1.13a4.89 4.89 0 00-3.33-1.31c-2.76 0-5 2.26-5 5.04 0 2.47 1.8 4.62 4.13 5.04h15.87v-5.29l-6-13.85.01-.01zm4.5 17.64H6.28c-1.59-.35-2.78-1.85-2.78-3.54 0-1.95 1.57-3.54 3.5-3.54.95 0 1.83.4 2.5 1.11-.16.41-.25.85-.25 1.32h1.5c0-.14.02-.28.04-.42.2-1.04 1.11-1.83 2.21-1.83.82 0 1.53.44 1.92 1.09.2.34.33.73.33 1.16h1.5c0-1.31-.68-2.47-1.7-3.14L13.6 7.72H9.82L8.76 5.25l.82-1.64 5.38.64 5.53 12.78v3.47h.01z" />
    </svg>
  ),

  'no-smoking': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.75 11.25h-1.5v1.5h1.5v-1.5z" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3.5 12c0-4.69 3.81-8.5 8.5-8.5 2.07 0 3.97.75 5.45 1.99l-5.76 5.76H6v1.5h4.19l-4.7 4.7A8.462 8.462 0 013.5 12zm8.5 8.5c-2.07 0-3.97-.75-5.45-1.99l5.76-5.76h3.19v-1.5h-1.69l4.7-4.7A8.462 8.462 0 0120.5 12c0 4.69-3.81 8.5-8.5 8.5z" />
    </svg>
  ),

  note: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.504 8.484V7.02c0-.266.013-.355.055-.492.11-.376.48-.657.919-.657.439 0 .81.288.919.657.041.137.055.226.055.492v2.257c0 .137 0 .274-.027.397-.076.342-.385.65-.728.725-.123.028-.26.028-.398.028H8.036c-.268 0-.357-.014-.494-.055a.954.954 0 01-.659-.917c0-.437.288-.807.659-.916.137-.041.226-.055.494-.055h1.468zm8.067 10.232H5.43V8.053c0-.137.048-.253.144-.349l3.149-3.14a.477.477 0 01.35-.143h8.5v14.296zm1.38-16.702A2.576 2.576 0 0018.647 2h-9.9c-.109 0-.218.007-.294.014a1.915 1.915 0 00-1.18.574L3.59 6.261a1.905 1.905 0 00-.576 1.177A3.347 3.347 0 003 7.732V19.79c0 .158.007.24.014.301.055.513.521.978 1.036 1.033.061.007.144.014.301.014h14.297c.158 0 .24-.007.302-.014.515-.055.981-.52 1.036-1.033.007-.061.014-.143.014-.3V3.346c0-.157-.007-.239-.014-.3-.055-.514-.521-.979-1.036-1.033z" />
    </svg>
  ),

  order: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 10.5V5.46l2.19 2.19L16 6.59l-4-4-4 4 1.06 1.06 2.19-2.19v5.04h1.5zM11.25 13.5v5.04l-2.19-2.19L8 17.41l4 4 4-4-1.06-1.06-2.19 2.19V13.5h-1.5z" />
    </svg>
  ),

  oyashirazu_15min: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.22 14.83v-7.1l-3.06-3.88L5.14 6.53l-.7 4.89 1.84 3.2c-2.35.92-4.12 2.37-4.87 4.1l1.52.27c.69-1.27 2.18-2.36 4.11-3.06l2.42 4.21 2.5-.67.07-4.38v-.34l1.26-.34.47.78 1.96 3.27 2.5-.67v-1.32c1.31.67 2.31 1.54 2.84 2.51l1.52-.27c-.7-1.6-2.27-2.97-4.37-3.89l.01.01zm-1.5 1.82l-.3.08-.7-1.17-1.05-1.75-.09-.16-.58-.97-1.09.29-1.25.34-1.09.29v.06l-.02 1.07v.43l-.06 3.15-.3.08-1.67-2.9-.78-1.35L6 11.12l.49-3.39 8.11-2.17 2.12 2.69v8.4z" />
    </svg>
  ),

  oyashirazu_60min: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 6.39l-4.47 1.2s-.03-.05-.05-.07c-.02-.02-.05-.03-.07-.05L17.61 3l-1.17-.31-2.8 1.62-.73 2.73c-.24.13-.47.29-.67.49-.36.36-.59.79-.73 1.25H8.88L2.7 14.96l1.41 1.41 5.59-5.59h1.88c.15.36.37.7.66 1 .29.3.63.51 1 .66v1.88l-5.59 5.59 1.41 1.41 6.18-6.18v-2.63c.46-.14.89-.38 1.25-.73.2-.2.36-.43.49-.67l2.73-.73 1.62-2.8-.31-1.17-.02-.02zm-6.08-1.1l.61-.35-.48 1.8c-.16-.04-.33-.06-.5-.07l.37-1.38zm.49 5.42a1.499 1.499 0 11-2.12-2.12 1.499 1.499 0 112.12 2.12zm3.29-1.63l-1.37.37c-.01-.17-.03-.33-.07-.5l1.8-.48-.35.61h-.01z" />
    </svg>
  ),

  'patient-search': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 5.75a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0-1.5c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
      <path d="M19.31 16.5s.03-.06.05-.09c.11-.16.2-.34.28-.52a4.42 4.42 0 00.24-.7c.02-.07.04-.14.05-.22.04-.24.07-.48.07-.73 0-2.21-1.79-4-4-4-2.06 0-3.73 1.56-3.96 3.56-.18-.03-.36-.06-.54-.06h-6c-1.65 0-3 1.35-3 3v3H4v-3c0-.83.67-1.5 1.5-1.5h6c.25 0 .49.07.7.18.17.54.44 1.03.8 1.44v2.88h1.5v-1.8c.46.19.97.3 1.5.3.25 0 .49-.03.73-.07.07-.01.14-.03.22-.05.16-.04.32-.09.48-.15.07-.03.14-.05.21-.09.18-.08.35-.17.52-.28.03-.02.06-.03.09-.05l2.19 2.19 1.06-1.06-2.19-2.19v.01zm-3.31.25c-.59 0-1.13-.21-1.56-.56-.49-.4-.83-.97-.91-1.63-.01-.1-.03-.2-.03-.31a2.5 2.5 0 015 0 2.5 2.5 0 01-2.5 2.5z" />
    </svg>
  ),

  pc: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 2v14.75h1V22H1v-5.25h1V2h20zm-1.5 1.5h-17v13.25h17V3.5zm-18 17h19v-2.25h-19v2.25zM7.25 9.75h1.5v1.5h-1.5v-1.5zm4 0h1.5v1.5h-1.5v-1.5zm5.5 0h-1.5v1.5h1.5v-1.5z" />
    </svg>
  ),

  'prescription-bottle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 2H3v6h2v14h14V8h2V2zm-3.5 14.75h-7v-5h7v5zm0-6.5H9v8h8.5v2.25h-11V8h11v2.25zm2-3.75h-15v-3h15v3z" />
    </svg>
  ),

  'question-circle': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 12c0-4.69-3.81-8.5-8.5-8.5-4.69 0-8.5 3.81-8.5 8.5 0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5zM2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10S2 17.51 2 12zm6-1h1.5V8.5h5v3h-3.25V15h1.5v-2H16V7H8v4zm4.75 6.75v-1.5h-1.5v1.5h1.5z" />
    </svg>
  ),

  refresh: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 9.75h5v-5h-1.5v2.29A9.018 9.018 0 0012 3c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9h-1.5c0 4.14-3.36 7.5-7.5 7.5-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 2.69 0 5.15 1.46 6.48 3.75H16v1.5z" />
    </svg>
  ),

  register: (
    <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.75 15h1.5v-2.25H8.5v-1.5H6.25V9h-1.5v2.25H2.5v1.5h2.25V15zM15.5 5.75a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0-1.5c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM21.5 16.75c0-1.65-1.35-3-3-3h-6c-1.65 0-3 1.35-3 3v3H11v-3c0-.83.67-1.5 1.5-1.5h6c.83 0 1.5.67 1.5 1.5v3h1.5v-3z" />
    </svg>
  ),

  'remind-watch': (
    <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5.5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5zM12 4c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9zM5.99 2.75L2.45 6.28l1.06 1.06 3.54-3.53-1.06-1.06zM18.01 2.75l-1.06 1.06 3.54 3.53 1.06-1.06-3.54-3.53z" />
      <path d="M14.83 16.89l-3.58-3.58V8h1.5v4.69l3.14 3.14-1.06 1.06z" />
    </svg>
  ),

  'result-download': (
    <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 19.13H4v1.5h16v-1.5zM15.5 9.88v-6.5h-7v6.5H4.76l1.5 1.5L12 17.12l5.74-5.74 1.5-1.5H15.5zM12 15l-3.62-3.62H10v-6.5h4v6.5h1.62L12 15z" />
    </svg>
  ),

  'right-arrow-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22c5.51 0 10-4.49 10-10S17.51 2 12 2 2 6.49 2 12s4.49 10 10 10zM9.62 8.35l1.06-1.06L15.39 12l-4.71 4.71-1.06-1.06L13.27 12 9.62 8.35z" />
    </svg>
  ),

  'right-white-arrow-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22c5.51 0 10-4.49 10-10S17.51 2 12 2 2 6.49 2 12s4.49 10 10 10zM9.62 8.35l1.06-1.06L15.39 12l-4.71 4.71-1.06-1.06L13.27 12 9.62 8.35z" />
    </svg>
  ),

  save_template: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 2.5v10.79L6.82 8.85 5.76 9.91 12 16.16l6.24-6.25-1.06-1.06-4.43 4.44V2.5h-1.5z" />
      <path d="M22 21.5H2v-10h1.5V20h17v-8.5H22v10z" />
    </svg>
  ),

  search: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1 15.33l-.35.35-1.44-1.44A7.44 7.44 0 0017 9.49c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5a7.503 7.503 0 0012.25 5.81l1.44 1.44-.35.35 5.79 5.79 1.77-1.77-5.79-5.79-.01.01zM3.5 9.5c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z" />
    </svg>
  ),

  settings: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22c-.62 0-1.25-.06-1.89-.18l-.61-.12v-2.64c-.87-.31-1.67-.77-2.37-1.37L4.86 19l-.41-.47a10.05 10.05 0 01-1.9-3.27l-.2-.59 2.28-1.31c-.09-.46-.13-.92-.13-1.37 0-.45.04-.91.13-1.37L2.35 9.31l.2-.59a9.846 9.846 0 011.9-3.27l.41-.47 2.27 1.31c.7-.59 1.5-1.06 2.37-1.37V2.29l.61-.12c1.27-.24 2.51-.24 3.78 0l.61.12v2.63c.87.31 1.67.77 2.37 1.36l2.27-1.31.41.47c.84.97 1.48 2.07 1.9 3.27l.2.59-2.28 1.31c.09.46.13.92.13 1.37 0 .45-.04.91-.13 1.37l2.28 1.31-.2.59a9.846 9.846 0 01-1.9 3.27l-.41.47-2.27-1.31c-.7.59-1.5 1.06-2.37 1.37v2.64l-.61.12c-.64.12-1.28.18-1.89.18V22zm-1-1.56c.68.08 1.32.08 2 0v-2.5l.55-.15c1.02-.27 1.95-.81 2.68-1.54l.41-.41 2.16 1.25c.4-.54.74-1.12 1-1.73l-2.16-1.25.15-.56c.14-.52.21-1.04.21-1.55s-.07-1.03-.21-1.55l-.15-.56 2.16-1.25c-.26-.62-.6-1.19-1-1.73l-2.16 1.25-.41-.41a5.924 5.924 0 00-2.68-1.54L13 6.06v-2.5c-.67-.08-1.33-.08-2 0v2.5l-.55.15c-1.02.27-1.95.81-2.68 1.54l-.41.41L5.2 6.91c-.4.54-.74 1.12-1 1.73l2.16 1.25-.15.56A5.96 5.96 0 006 12c0 .51.07 1.03.21 1.55l.15.56-2.16 1.25c.26.62.6 1.19 1 1.73l2.16-1.24.41.41c.74.74 1.67 1.27 2.68 1.54l.55.15v2.5-.01zm1-4.94c-.4 0-.81-.08-1.21-.23a3.39 3.39 0 01-1.02-.59 3.53 3.53 0 01-1.21-2.09c-.03-.19-.06-.39-.06-.59 0-.2.03-.39.06-.58.14-.82.57-1.56 1.21-2.09.31-.26.65-.46 1.02-.59.81-.3 1.62-.3 2.42 0 .36.14.71.33 1.02.59.64.54 1.08 1.28 1.21 2.09.03.19.06.39.06.59 0 .2-.02.39-.06.58a3.5 3.5 0 01-1.21 2.09c-.31.26-.65.46-1.02.59-.41.15-.81.23-1.21.23zm0-5.5c-.22 0-.45.04-.69.13-.2.08-.4.19-.58.34-.37.31-.61.73-.69 1.19-.02.11-.04.22-.04.33 0 .11.02.23.04.33.08.46.33.88.69 1.19.18.15.38.26.58.34.48.18.9.18 1.38 0 .2-.08.4-.19.58-.34.37-.31.62-.73.7-1.19.02-.11.04-.22.04-.33 0-.11-.02-.23-.04-.33a2.01 2.01 0 00-.7-1.19A2.008 2.008 0 0012 10z" />
    </svg>
  ),

  'show-sidebar': (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2v20h20V2H2zm1.5 18.5v-17h3v17h-3zm17 0H8v-17h12.5v17z" />
      <path d="M11.95 9.53L14.43 12l-2.48 2.47 1.06 1.07L16.55 12l-3.54-3.54-1.06 1.07z" />
    </svg>
  ),

  'sign-in-alt': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 12c0 4.69 3.81 8.5 8.5 8.5 3.63 0 6.72-2.29 7.94-5.5h1.6c-1.28 4.05-5.07 7-9.54 7-5.51 0-10-4.49-10-10S6.49 2 12 2c4.47 0 8.26 2.95 9.54 7h-1.6C18.73 5.79 15.63 3.5 12 3.5c-4.69 0-8.5 3.81-8.5 8.5zm7.96-.75H22v1.5H11.46l2.89 2.9-1.06 1.06L8.59 12l4.7-4.7 1.06 1.06-2.89 2.89z" />
    </svg>
  ),

  slack: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14.67a2 2 0 004 0v-2H4a2 2 0 00-2 2zm12.64-3.34a2 2 0 002-2V4a2 2 0 10-4 0v5.33a2.001 2.001 0 002.02 2h-.02zm7.32-2a2 2 0 10-4 0v2h2a2.001 2.001 0 002.04-2h-.04zM9.34 12.67a2 2 0 00-2 2V20a2 2 0 004 0v-5.33a2 2 0 00-2-2zM14.66 18h-2v2a2 2 0 102-2zM20 12.67h-5.34a2 2 0 000 4H20a2 2 0 000-4zM9.34 7.33H4a2 2 0 100 4h5.34a2 2 0 100-4zm0-5.33a2 2 0 100 4h2V4a2 2 0 00-2-2z" />
    </svg>
  ),

  sleep_02: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 13c0-1.62-1.3-2.94-2.91-2.99.25-.31.46-.64.61-1.01H18V1H6v8h2.3c.15.37.36.7.61 1.01A3.002 3.002 0 006 13H4v10h16V13h-2zM8 7.5h-.5v-5h9v5H16c0-1.52-.86-2.82-2.1-3.5-.57-.31-1.21-.5-1.9-.5s-1.33.19-1.9.5C8.85 4.68 8 5.98 8 7.5zm6.5 0c0 .26-.05.51-.13.75-.09.27-.22.53-.39.75-.46.6-1.17 1-1.99 1A2.5 2.5 0 0110 9c-.17-.22-.3-.48-.39-.75-.08-.24-.13-.49-.13-.75a2.5 2.5 0 015 0h.02zm-5.5 4h6c.83 0 1.5.67 1.5 1.5h-9c0-.83.67-1.5 1.5-1.5zm9.5 10h-13v-7h13v7z" />
    </svg>
  ),

  sleep: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2.5V1H6v1.5H4V23h16V2.5h-2zm-10.5 0h9v5H16c0-1.52-.86-2.82-2.1-3.5-.57-.31-1.21-.5-1.9-.5s-1.33.19-1.9.5C8.85 4.68 8 5.98 8 7.5h-.5v-5zm0 10.5c0-.83.67-1.5 1.5-1.5h6c.83 0 1.5.67 1.5 1.5h-9zm2-5.5a2.5 2.5 0 015 0c0 .26-.05.51-.13.75-.09.27-.22.53-.39.75-.46.6-1.17 1-1.99 1A2.5 2.5 0 0110 9c-.17-.22-.3-.48-.39-.75-.08-.24-.13-.49-.13-.75h.02zM5.5 4H6v5h2.3c.15.37.36.7.61 1.01A3.002 3.002 0 006 13h-.5V4zm13 17.5h-13v-7h13v7zm0-8.5H18c0-1.62-1.3-2.94-2.91-2.99.25-.31.46-.64.61-1.01H18V4h.5v9z" />
    </svg>
  ),

  'sns-share': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 16c-.71 0-1.34.29-1.79.76l-8.75-4.37c.02-.13.04-.26.04-.39s-.02-.26-.04-.39l8.75-4.37c.45.47 1.09.76 1.79.76a2.5 2.5 0 000-5A2.5 2.5 0 0016 5.5c0 .13.02.26.04.39l-8.75 4.37C6.84 9.79 6.2 9.5 5.5 9.5a2.5 2.5 0 000 5c.71 0 1.34-.29 1.79-.76l8.75 4.37c-.02.13-.04.26-.04.39a2.5 2.5 0 005 0 2.5 2.5 0 00-2.5-2.5zm0-11.5c.55 0 1 .45 1 1s-.45 1-1 1c-.08 0-.15-.03-.23-.05-.44-.11-.77-.48-.77-.95 0-.13.03-.26.08-.38.15-.36.51-.62.92-.62zM5.5 13c-.42 0-.77-.26-.92-.62a.957.957 0 010-.76c.15-.36.51-.62.92-.62.08 0 .15.03.23.05.44.11.77.48.77.95s-.33.85-.77.95c-.08.02-.15.05-.23.05zm13 6.5c-.42 0-.77-.26-.92-.62a.957.957 0 01-.08-.38c0-.47.33-.85.77-.95.08-.02.15-.05.23-.05.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  ),

  'stethoscope-alt': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 9.5c-1.66 0-3 1.34-3 3 0 1.4.96 2.56 2.25 2.89v.11c0 2.76-2.24 5-5 5s-5-2.24-5-5v-.83l5.25-5.13V2.01h-4v1.5h2.5v5.4l-4.5 4.4-4.5-4.4v-5.4h2.5v-1.5h-4v7.53L7 14.67v.83a6.5 6.5 0 1013 0v-.11a2.992 2.992 0 00-.75-5.89zm0 4.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </svg>
  ),

  syringe: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.39 12.09l-3.71-3.71 1.77-1.77 1.59 1.59 1.06-1.06-4.24-4.24-1.06 1.06 1.59 1.59-1.77 1.77-3.71-3.71-1.06 1.06 1.77 1.77-8.84 8.84 1.94 1.94-2.83 2.83 1.06 1.06 2.83-2.83 1.94 1.94 8.84-8.84 1.77 1.77 1.06-1.06zm-5.13-.53l-1.41-1.41-1.06 1.06 1.41 1.41-1.06 1.06-1.41-1.41-1.06 1.06 1.41 1.41-1.06 1.06-1.41-1.41-1.06 1.06 1.41 1.41-1.24 1.24-2.83-2.83 7.78-7.78 2.83 2.83-1.24 1.24z" />
    </svg>
  ),

  tablets: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.46 3.46a5.003 5.003 0 017.07 0 5.004 5.004 0 010 7.07 5.003 5.003 0 01-7.07 0 5.004 5.004 0 010-7.07zm5.42.59a3.503 3.503 0 00-4.36.47 3.503 3.503 0 00-.47 4.36l4.83-4.83zm1.06 1.06l-4.83 4.83c1.35.87 3.18.71 4.36-.47a3.503 3.503 0 00.47-4.36zM3.75 20.25c-2.34-2.35-2.34-6.15 0-8.49 2.35-2.34 6.15-2.35 8.49 0s2.35 6.15 0 8.49-6.15 2.35-8.49 0zm.58-6.85c-1.24 1.76-1.09 4.21.48 5.78 1.57 1.58 4.02 1.73 5.78.48L4.33 13.4zm1.06-1.06l6.26 6.26c1.24-1.76 1.09-4.21-.48-5.78-1.57-1.58-4.02-1.73-5.78-.48z" />
    </svg>
  ),

  'three-dot-line': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 4.5h-3v3h3v-3zM13.5 10.5h-3v3h3v-3zM13.5 16.5h-3v3h3v-3z" />
    </svg>
  ),

  'three-dot-row': (
    <svg width="24" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 10.5h-3v3h3v-3zM13.5 10.5h-3v3h3v-3zM19.5 10.5h-3v3h3v-3z" />
    </svg>
  ),

  'times-circle': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 12c0-4.69-3.81-8.5-8.5-8.5-4.69 0-8.5 3.81-8.5 8.5 0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5zM2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10S2 17.51 2 12zm6.652 4.416l3.35-3.35 3.353 3.352 1.061-1.06-3.353-3.353 3.367-3.367-1.06-1.06-3.367 3.366L8.637 7.58l-1.06 1.06 3.364 3.365-3.35 3.35 1.06 1.061z" />
    </svg>
  ),

  times: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.818 6.88l1.06-1.061 5.127 5.126L17.13 5.82l1.061 1.06-5.127 5.127 5.127 5.126-1.06 1.061-5.127-5.127-5.127 5.127-1.06-1.06 5.126-5.127-5.126-5.127z" />
    </svg>
  ),

  'todo-round': (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8" stroke="#2D1F20" />
    </svg>
  ),

  tooth: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.28 3.5l2.13 4.98L17.2 20.5h-1.35l-1.51-5.28-.31-1.09H9.95l-.31 1.09-1.51 5.28H6.78L3.57 8.48 5.7 3.5h12.57m.99-1.5H4.73L2 8.36 5.64 22h3.64l1.82-6.36h1.82L14.74 22h3.64l3.64-13.64L19.29 2h-.03z" />
    </svg>
  ),

  'trash-empty': (
    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 20.5h-11V9H5v13h14V9h-1.5v11.5zM21 6h-5V2H8v4H3v1.5h18V6zm-6.5 0h-5V3.5h5V6z" />
    </svg>
  ),

  trash: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 2v4h5v1.5h-2V22H5V7.5H3V6h5V2h8zm-1.5 1.5h-5V6h5V3.5zm-8 17h11v-13h-11v13zM10.75 10h-1.5v8h1.5v-8zm4 0h-1.5v8h1.5v-8z" />
    </svg>
  ),

  triangle: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5.12l8.46 15.39H3.53l8.46-15.39m0-3.11l-11 20h22l-11-20z" />
    </svg>
  ),

  twitter: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 5.8a8.49 8.49 0 01-2.36.64 4.13 4.13 0 001.81-2.27 8.21 8.21 0 01-2.61 1 4.1 4.1 0 00-7 3.74 11.64 11.64 0 01-8.45-4.29 4.16 4.16 0 00-.55 2.07 4.09 4.09 0 001.82 3.41 4.05 4.05 0 01-1.86-.51v.05a4.1 4.1 0 003.3 4 3.933 3.933 0 01-1.1.17 4.907 4.907 0 01-.77-.07 4.11 4.11 0 003.83 2.84A8.22 8.22 0 012 18.28a11.57 11.57 0 006.29 1.85A11.589 11.589 0 0020 8.45v-.53a8.43 8.43 0 002-2.12z" />
    </svg>
  ),

  upload: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 5.38v10.78h-1.5V5.38L6.82 9.81 5.76 8.75 12 2.5l6.24 6.25-1.06 1.06-4.43-4.43zM2 21.5h20v-10h-1.5V20h-17v-8.5H2v10z" />
    </svg>
  ),

  urology: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.81 7.45l.88-1.76c.19-.36.32-.76.32-1.19a2.5 2.5 0 00-3-2.45c-2.24.25-4 2.14-4 4.45v2A2.5 2.5 0 007.8 9.51l.36-.72c.8.42 1.36 1.25 1.36 2.21v2h1.5v-2c0-1.55-.9-2.89-2.19-3.55h-.02zM6.43 8.87v.03c-.07.12-.14.22-.22.31A1.003 1.003 0 014.5 8.5v-2c0-1.52 1.15-2.79 2.66-2.96h.07l.06-.02c.07-.01.14-.02.21-.02.55 0 1 .45 1 1 0 .15-.04.31-.13.47V5l-.03.03-1 2.01-.34.68-.56 1.12-.02.03h.01zM12 14c-1.93 0-3.5 1.57-3.5 3.5 0 1.68 1.18 3.07 2.75 3.42V22h1.5v-1.08a3.498 3.498 0 002.75-3.42c0-1.93-1.57-3.5-3.5-3.5zm0 5.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM16.51 2c.17 0 .34.02.5.05h.02c2.25.25 4 2.14 4 4.45v2a2.5 2.5 0 01-4.79 1.01l-.36-.72c-.8.42-1.36 1.25-1.36 2.21v2h-1.5v-2c0-1.56.89-2.89 2.19-3.55l-.88-1.76c-.2-.35-.32-.76-.32-1.19a2.5 2.5 0 012.5-2.5zm2 7.5c.55 0 1-.45 1-1h-.01v-2c0-1.52-1.14-2.79-2.66-2.96l-.07-.02h-.06c-.07-.02-.14-.02-.21-.02-.55 0-1 .45-1 1 0 .15.04.31.13.47l.03.03v.03l1 2.01.34.68.56 1.12.03.03v.03c.06.12.13.23.21.31.19.19.44.29.71.29z" />
    </svg>
  ),

  user: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 7c0-1.93-1.57-3.5-3.5-3.5S8.5 5.07 8.5 7s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5zM7 7c0-2.76 2.24-5 5-5s5 2.24 5 5-2.24 5-5 5-5-2.24-5-5zM3 22v-8h18v8h-1.5v-6.5h-15V22H3z" />
    </svg>
  ),
} as const;
