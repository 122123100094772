import { urlSafeEncode } from '@aws-amplify/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { useContext } from '../../../../../hooks/useContext';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { CheckupReservationStore } from '../stores/CheckupReservationStore';

type Props = {
  onNext?: () => void;
};
export const CheckupReservationCodeInputPage = observer(({ onNext }: Props) => {
  const store = useContext(CheckupReservationStore.Context);
  const [code, setCode] = useState('');
  const { push } = useHistory();
  const [load] = useTransaction(async () => {
    await store.fetchCheckupInfo(code);
    push({ search: `?checkupOrganizationMemberEventId=${urlSafeEncode(code)}` });
  });
  return (
    <Wrapper>
      <DocumentTitle title="従業員コードを入力してください。" />
      <FormLayout>
        <FormItem>
          <FormLabel>従業員コードを入力してください。</FormLabel>
          <TextField color="white" value={code} onChange={v => setCode(v)} onFocusout={() => load()} />
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={16}>
        <Button onClick={onNext} disabled={!store.checkupOrganizationMember}>
          次へ
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
