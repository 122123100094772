import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * routerで遷移した時に、自動的に一番上までスクロールする
 */
export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};
