export const GtmClasses = {
  gtm: {
    conversion: {
      withNewAccount: 'gtm_conversion_signUp', // アカウント登録と同時に予約確定
      withSignIn: 'gtm_conversion_signIn', // 仮予約を、登録済みアカウントで確定
      authenticated: 'gtm_conversion_authenticated', // 認証済みの予約
      withConcent: 'gtm_conversion_withConcent', // アカウント登録済みかつ新規の医院への予約確定
      checkup: 'gtm_conversion_checkup', // 健診の予約確定
    },
    temporallyReservation: {
      def: 'gtm_temporallyReservation_default', // 仮予約作成
    },
    startInterview: {
      onCompleted: 'gtm_startInterview_onCompletedReservation', // 予約完了画面で問診画面に遷移
      fromMyPage: 'gtm_startInterview_fromMyPage', // マイページから問診画面に遷移
    },
    registration: {
      patientInformation: {
        startCardRegistration: 'gtm_registration_patientInformation_startCardRegistration',
        // 患者情報の入力フォーム
        editForm: {
          card: {
            number: 'gtm_registration_patientInformation_editForm_cardNumber',
            expiry: 'gtm_registration_patientInformation_editForm_cardExpiry',
            cvc: 'gtm_registration_patientInformation_editForm_cardCvc',
          },
          phoneNumber: 'gtm_registration_patientInformation_editForm_phoneNumber',
          postCode: 'gtm_registration_patientInformation_editForm_postCode',
          prefecture: 'gtm_registration_patientInformation_editForm_prefecture',
          municipalities: 'gtm_registration_patientInformation_editForm_municipalities',
          building: 'gtm_registration_patientInformation_editForm_building',
          insuranceCard: {
            open: 'gtm_registration_patientInformation_editForm_openInsuranceCard',
            upload: 'gtm_registration_patientInformation_editForm_uploadInsuranceCard', // 保険証のアップロード
          },
        },
        finish: 'gtm_registration_patientInformation_finish', // 患者プロフィール登録ページ【最終確認】への遷移
        finishFromMyPage: 'gtm_registration_patientInformation_finish_fromMyPage', // マイページから患者プロフィール編集
      },
      preInterview: {
        fromAccount: 'gtm_registration_preInterview_fromAccount', // 患者プロフィール登録後から事前問診への遷移
        interviewForm: 'gtm_registration_preInterview_interviewForm', // 事前問診の回答フォームにつける
        finish: 'gtm_registration_preInterview_finish', // 事前問診の完了
      },
    },
    cancel: {
      onCancel: 'gtm_cancel_onCancel', // 予約を取り消す
      addWaitingList: 'gtm_cancel_addWaitingList', // キャンセル通知の登録
    },
    addCalendar: {
      apple: 'gtm_addCalendar_apple',
      google: 'gtm_addCalendar_google',
    },
  },
} as const;
