import { css } from 'emotion';
import React from 'react';

import { Color, FontSize, IconSize } from '../../constants/Style';
import { gutter, square } from '../../helpers/Style';
import { Icon } from '../Icon';

export const WaitingListLegend = () => (
  <div className={baseStyle}>
    <div className={itemStyle}>
      <div className={circleStyle}>
        <Icon name="circle" />
      </div>
      <div className={legendStyle}>：空きあり</div>
    </div>
    <div className={itemStyle}>
      <div className={bellStyle}>
        <Icon name="bell" />
      </div>
      <div className={legendStyle}>：キャンセル通知</div>
    </div>
    <div className={itemStyle}>
      <div className={timesStyle}>
        <Icon name="times" />
      </div>
      <div className={legendStyle}>：受付不可</div>
    </div>
  </div>
);

const itemStyle = css`
  display: flex;
  align-items: center;
`;

const legendStyle = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleDark};
`;

const baseStyle = css`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  column-gap: ${gutter(4)};
`;

const circleStyle = css`
  ${square(IconSize.Regular)}
  > svg {
    fill: ${Color.FunctionalRedNeutral};
  }
`;

const bellStyle = css`
  ${square(IconSize.Regular)}
  > svg {
    fill: ${Color.FunctionalYellowNeutral};
  }
`;

const timesStyle = css`
  ${square(IconSize.Regular)}
  > svg {
    fill: ${Color.GrayscaleLight};
  }
`;
