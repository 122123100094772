import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../components/Caption';
import { FormItem, FormLabel, FormLayout } from '../../../components/Form';
import { TextField } from '../../../components/TextField';
import { useToast } from '../../../components/Toast';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { useReservationId } from '../../../hooks/useReservationId';
import { useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { GoogleCalendarCoopStore } from './stores/GoogleCalendarCoopStore';

export const GoogleCalendarCoopPage = observer(() => {
  const { reservationId, push } = useReservationId();
  const { addToast } = useToast();
  const session = useContext(SessionStore.Context);
  const store = useMemo(() => {
    const res = new GoogleCalendarCoopStore();
    res.setId(reservationId);
    return res;
  }, [reservationId]);

  useEffect(() => {
    if (!session.user?.userEntity?.mail) {
      return;
    }
    store.setEmail(session.user.userEntity.mail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.user]);

  const [doCoop, coopStatus] = useTransaction(async () => {
    await store.kickCoop();
    addToast({ message: '連携を実行しました' });
    push(Routes.Paths.ReservationShow, reservationId);
  });

  return (
    <Wrapper>
      <CaptionGroup>
        <Caption type="header" align="center" underline>
          Google Calendar連携設定
        </Caption>
      </CaptionGroup>
      <FormLayout>
        <FormItem>
          <FormLabel>招待メールアドレス</FormLabel>
          <TextField type="email" color="white" value={store.email} onChange={v => store.setEmail(v)} />
        </FormItem>
      </FormLayout>
      <ButtonGroup gutter={5}>
        <Button block onClick={doCoop} disabled={coopStatus.running}>
          登録
        </Button>
        <Button ghost block onClick={() => push(Routes.Paths.ReservationShow, reservationId)}>
          キャンセル
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
});
