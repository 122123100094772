import { IconName } from '@clinic-monorepo/clinic-icon';
import add from 'date-fns/add';
import { isSameDay } from 'date-fns/esm';
import format from 'date-fns/format';
import { css, cx } from 'emotion';
import React, { ComponentProps, ReactNode, useMemo } from 'react';

import { TreatmentStatus } from '../../../../../../gql/gql-types';
import { Card } from '../../../../../components/Card';
import { Icon } from '../../../../../components/Icon';
import { Defaults } from '../../../../../constants/Defaults';
import { Routes } from '../../../../../constants/Routes';
import { BorderRadius, Color, FontSize, IconSize } from '../../../../../constants/Style';
import { gutter, square } from '../../../../../helpers/Style';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { InterviewStatus } from '../../../../../interfaces/Reservation';
import { approximate } from '../../../../../utils/NumberUtil';

type BaseProp = {
  children: ReactNode;
};

type Props = {
  id: string;
  date: Date;
  duration: number;
  department: {
    id: string;
    name: string;
    icon?: IconName;
  };
  treatmentKind: {
    id: string;
    name: string;
  };
  clinic: {
    id: string;
    name: string;
  };
  status: TreatmentStatus;
  interviewStatus?: InterviewStatus;
  width?: number;
  hidden?: boolean;
  displayDuration?: number;
  variant?: 'reservation' | 'waitingList';
};

export const ReservationCard = ({
  id,
  date,
  duration,
  department,
  treatmentKind,
  clinic,
  status,
  width,
  interviewStatus,
  displayDuration,
  variant = 'reservation',
}: Props) => {
  const { push } = useReservationId();
  return (
    <Card
      shadowType="none"
      color={variant === 'waitingList' ? 'disabled' : clinic.id === '01' ? 'clinic' : 'dental'}
      width={width}
    >
      <div
        onClick={() => {
          if (variant === 'reservation') {
            push(Routes.Paths.ReservationShow, id);
          }
          if (variant === 'waitingList') {
            push(Routes.Paths.WaitingListItemShow, id);
          }
        }}
        className={contentActionContainerStyle}
      >
        <ReservationCardContent>
          <ReservationCardHeader status={status} interviewStatus={interviewStatus} variant={variant} />
          <ReservationCardBody
            date={date}
            duration={displayDuration || duration}
            department={department}
            treatmentKind={treatmentKind}
            clinic={clinic}
            width={width}
            variant={variant}
          />
        </ReservationCardContent>
        <div className={iconStyle}>
          <Icon name={department.icon || 'men'} />
        </div>
        <ReservationCardAction />
      </div>
    </Card>
  );
};

const iconStyle = css`
  position: absolute;
  right: 30px;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  opacity: 0.1;

  > svg {
    fill: ${Color.GrayscaleWhite};
  }
`;

const actionStyle = css`
  display: flex;
  align-items: center;

  > svg {
    fill: ${Color.GrayscaleWhite};
    ${square(IconSize.Regular)}
  }
`;

const ReservationCardContent = ({ children }: BaseProp) => <div className={contentStyle}>{children}</div>;

const contentStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 136px;
`;

const ReservationCardAction = () => (
  <div className={actionStyle}>
    <Icon name="angle-right" />
  </div>
);

const contentActionContainerStyle = css`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const StatusName = {
  [TreatmentStatus.Fixed]: '予約済',
  [TreatmentStatus.Completed]: '完了',
  [TreatmentStatus.Canceled]: '取消済',
  [TreatmentStatus.Temporally]: '仮予約',
  [TreatmentStatus.Started]: '開始済',
  [TreatmentStatus.NoShow]: '取消済',
} as const;

type HeaderProps = Pick<ComponentProps<typeof ReservationCard>, 'status' | 'interviewStatus' | 'variant'>;

const ReservationCardHeader = ({ status, interviewStatus, variant = 'reservation' }: HeaderProps) => {
  const isAlert = ['notStarted', 'started'].some(stat => stat === interviewStatus);
  return (
    <header>
      <ReservationCardStatusIndicator isAlert={isAlert}>
        {(() => {
          if (variant === 'waitingList') {
            return 'キャンセル待ち';
          }

          switch (interviewStatus) {
            case 'notStarted':
              return '問診未回答';
            case 'started':
              return '問診未完了';
            case 'finished':
            case 'notNeeded':
            default:
              return StatusName[status];
          }
        })()}
      </ReservationCardStatusIndicator>
    </header>
  );
};
const statusIndicatorStyle = css`
  width: fit-content;
  padding: ${gutter(1)} ${gutter(3)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleWhite};
  border: 1px solid ${Color.GrayscaleWhite};
  border-radius: ${BorderRadius.Regular};
`;

const statusIndicatorAlertStyle = css`
  width: fit-content;
  padding: ${gutter(1)} ${gutter(3)};
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleWhite};
  background-color: ${Color.FunctionalRedNeutral};
  border-radius: ${BorderRadius.Regular};
`;
const ReservationCardStatusIndicator = ({ children, isAlert }: BaseProp & { isAlert: boolean }) => (
  <div className={isAlert ? statusIndicatorAlertStyle : statusIndicatorStyle}>{children}</div>
);

type BodyProps = Pick<
  ComponentProps<typeof ReservationCard>,
  'date' | 'duration' | 'clinic' | 'department' | 'treatmentKind' | 'width' | 'variant'
>;
const ReservationCardBody = ({ date, duration, clinic, department, treatmentKind, width, variant }: BodyProps) => {
  const widthStyle = useMemo(
    () =>
      cx(
        smallStyle,
        width
          ? css`
              width: ${width - 50}px;
            `
          : '',
      ),
    [width],
  );

  const approximateDuration = useMemo(() => approximate(duration, Defaults.DURATION), [duration]);
  const endDate = useMemo(() => add(date, { minutes: approximate(duration, Defaults.DURATION) }), [date, duration]);

  return (
    <div>
      <div className={dateStyle}>{format(date, `yyyy年MM月dd日(${weekdays[date.getDay()]})`)}</div>
      <div className={timeStyle}>
        {variant === 'waitingList' ? (
          <>
            {format(date, 'HH:mm')} - {!isSameDay(date, endDate) && '翌'}
            {format(endDate, 'HH:mm')}
          </>
        ) : (
          <>
            {format(date, 'HH:mm')}
            <span className={smallTimeTildeStyle}> ～ </span>
            <span className={smallTimeStyle}>[所要{approximateDuration}分]</span>
          </>
        )}
      </div>
      <small className={widthStyle}>
        {clinic.name} / {department.name} {treatmentKind.name}
      </small>
    </div>
  );
};

const weekdays = ['日', '月', '火', '水', '木', '金', '土'] as const;

const dateStyle = css`
  margin-bottom: 10px;
  font-size: ${FontSize.Medium};
  font-weight: bold;
  line-height: 1;
  color: ${Color.GrayscaleWhite};
`;

const timeStyle = css`
  margin-bottom: 10px;
  font-size: ${FontSize.ExtraLarge};
  font-weight: bold;
  line-height: 1;
  color: ${Color.GrayscaleWhite};
`;

const smallTimeStyle = css`
  font-size: ${FontSize.SmallRegular};
`;

const smallTimeTildeStyle = css`
  font-size: ${FontSize.SmallRegular};
  vertical-align: middle;
`;

const smallStyle = css`
  display: block;
  overflow: hidden;
  font-size: ${FontSize.Regular};
  line-height: 1;
  color: ${Color.GrayscaleWhite};
  text-overflow: ellipsis;
  white-space: nowrap;

  > svg {
    fill: ${Color.GrayscaleWhite};
    ${square(14)}
  }
`;
