import { css } from 'emotion';
import React from 'react';

import { Maybe } from '../../../../../../../../gql/gql-types';
import { Caption } from '../../../../../../../components/Caption';
import { Card } from '../../../../../../../components/Card';
import { Breakpoint, Dimension } from '../../../../../../../constants/Style';
import { Department } from '../../../../../../../domains/reservation/Department';
import { gutter } from '../../../../../../../helpers/Style';
import { useMedia } from '../../../../../../../hooks/useMedia';
import { DepartmentCardFooter } from './DepartmentCardFooter';
import { DepartmentCardHeader } from './DepartmentCardHeader';

export type Props = {
  department: Department;
  nearest?: Maybe<Date>;
  onClick?: (department: Department) => void;
  selected: boolean;
  disabled: boolean;
};

/**
 * 診療科を選択するカードコンポーネント
 */
export const DepartmentCard = ({ department, nearest, onClick, selected, disabled }: Props) => {
  const sp = useMedia([{ query: `(max-width: ${Dimension.ContentWidthMax}px)`, value: true }], false);
  return (
    <Card shadowType="floating" outlined={selected} disabled={disabled} smallPadding={sp}>
      <div className={layout} onClick={() => !disabled && onClick?.(department)}>
        <DepartmentCardHeader
          icon={department.icon}
          name={department.displayName || department.name}
          nearest={nearest}
        />
        <Caption type="danger">{department.description}</Caption>
        {!sp && <DepartmentCardFooter nearest={nearest} />}
      </div>
    </Card>
  );
};

const Constants = {
  cardHeight: '150px',
  cardSpHeight: '56px',
  cardWidth: {
    sm: 160,
    md: 280,
  },
} as const;

const layout = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${Constants.cardHeight};
  padding: ${gutter(1)};

  @media (max-width: ${Breakpoint.ScreenXs}px) {
    display: flex;
    justify-content: center;
    height: ${Constants.cardSpHeight};
    padding: 0 ${gutter(2)};
  }
`;
