import React, { useEffect, useMemo } from 'react';

import { Caption } from '../../../../components/Caption';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Wrapper } from '../../../../components/Wrapper';
import { Routes } from '../../../../constants/Routes';
import { useReservationId } from '../../../../hooks/useReservationId';
import { useTransaction } from '../../../../hooks/useTransaction';
import {
  ConfirmationFormArea,
  ConfirmationFormGroup,
  ConfirmationFormItem,
  ConfirmationFormItemValue,
  ConfirmationFormLabel,
  ConfirmationGroupTitle,
} from '../../../Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { transformer } from '../../../Interviews/InterviewsNew/InterviewsInput/transformer';
import { CheckupPreInterviewStore } from './stores/CheckupPreInterviewStore';

export const CheckupPreInterviewShowPage = () => {
  const { reservationId, push } = useReservationId();
  const store = useMemo(() => new CheckupPreInterviewStore(), []);
  const [load] = useTransaction(() => store.fetchInterviews(reservationId));

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return (
    <Wrapper>
      <DocumentTitle title="事前問診の確認・編集" />
      <Caption type="header" align="center" underline>
        事前問診の確認・編集
      </Caption>
      <ConfirmationFormArea>
        <ConfirmationFormGroup>
          <ConfirmationGroupTitle onClickEdit={() => push(Routes.Paths.ReservationPreInterview, reservationId)}>
            予約前問診
          </ConfirmationGroupTitle>
          {store.preInterview?.questions
            ?.map(question => question && { ...question, ...transformer([], question) })
            .map(q => (
              <>
                {q && (
                  <ConfirmationFormItem key={q.id}>
                    <ConfirmationFormLabel>{q.confirm_text}</ConfirmationFormLabel>
                    <ConfirmationFormItemValue question={q} />
                  </ConfirmationFormItem>
                )}
              </>
            ))}
        </ConfirmationFormGroup>
        <ConfirmationFormGroup>
          <ConfirmationGroupTitle
            editable
            onClickEdit={() => push(Routes.Paths.CheckupPreInterviewPostReserveEdit, reservationId)}
          >
            事前問診
          </ConfirmationGroupTitle>
          {store.interview?.questions
            ?.map(question => question && { ...question, ...transformer([], question) })
            .map(q => (
              <>
                {q && (
                  <ConfirmationFormItem key={q.id}>
                    <ConfirmationFormLabel>{q.confirm_text}</ConfirmationFormLabel>
                    <ConfirmationFormItemValue question={q} />
                  </ConfirmationFormItem>
                )}
              </>
            ))}
        </ConfirmationFormGroup>
      </ConfirmationFormArea>
    </Wrapper>
  );
};
