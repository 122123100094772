import gql from 'graphql-tag';

import { TreatmentType } from '../fragments/treatment';

export default gql`
  query getTreatmentHistory($treatmentId: String) {
    getTreatment(id: $treatmentId) {
      ...TreatmentHistory
    }
  }
  ${TreatmentType}
`;
