import { format } from 'date-fns/esm';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../../gql/client';
import {
  CreateDosageWithPrescriptionMutation,
  CreateDosageWithPrescriptionMutationVariables,
  DosageStatus,
  SignedPost,
} from '../../../../../gql/gql-types';
import { createDosageWithPrescription } from '../../../../../gql/operations/createDosageWithPrescription';

export class PrescriptionUploadStore {
  constructor() {
    makeAutoObservable(this, {
      files: observable,
      setFiles: action,
      setDate: action,
      date: observable,
      valid: computed,
    });
  }

  public files?: FileList = undefined;

  public setFiles(file?: FileList) {
    this.files = file;
  }

  public date: Date = new Date();

  public setDate(date: Date) {
    this.date = date;
  }

  public get valid() {
    return !!this.files;
  }

  public async post(userId: string) {
    if (!this.files) {
      return false;
    }

    const res = await GQL.query<CreateDosageWithPrescriptionMutationVariables, CreateDosageWithPrescriptionMutation>(
      createDosageWithPrescription,
      {
        input: {
          dosage: {
            date: format(this.date, 'yyyy-MM-dd'),
            time: format(this.date, 'HH:mm'),
            pharmacy: '01', // FIXME
            status: DosageStatus.BPrescriptionNotConfirmed,
            userId,
          },
          prescriptions: filterSafe(
            new Array(this.files.length).fill(undefined).map((_, i) => this.files?.item(i)?.name),
          ),
        },
      },
    );

    if (!res.createDosageWithPrescription) {
      return false;
    }

    await Promise.all(res.createDosageWithPrescription.urls.map((u, i) => this.doUpload(this.files?.item(i), u)));
    return true;
  }

  public async doUpload(file: File | undefined | null, signedPost: SignedPost) {
    if (!file) {
      return;
    }
    const d = new FormData();
    signedPost.fields.forEach(f => d.append(f.key, f.value));
    d.append('file', file, file.name);

    await fetch(signedPost.url, {
      headers: {
        accept: 'multipart/form-data',
      },
      mode: 'no-cors',
      body: d,
      method: 'POST',
    });
  }
}

function filterSafe(arr: (string | undefined)[]): string[] {
  return arr.filter(x => !!x) as string[];
}
