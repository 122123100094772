import { css, cx } from 'emotion';
import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breakpoint, Color, Dimension, Duration, Easing, ZIndex } from '../../constants/Style';
import { gutter, square, toRGBA } from '../../helpers/Style';
import { GlobalHeader } from '../GlobalHeader';
import { GlobalNavigation } from '../GlobalNavigation';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  const [shownNavigation, setShownNavigation] = useState(false);

  const handleShowNavigation = () => setShownNavigation(true);

  const handleCloseNavigation = () => setShownNavigation(false);

  const history = useHistory();
  history.listen(() => handleCloseNavigation());

  return (
    <>
      <div className={baseStyle}>
        <GlobalHeader onClickNavigationButton={handleShowNavigation} />
        <main className={mainStyle}>{children}</main>
      </div>

      <div className={cx(overlayStyle, shownNavigation && overlayShownStyle)} onClick={handleCloseNavigation} />
      <div className={cx(navigationContainerStyle, shownNavigation && navigationContainerShownStyle)}>
        <GlobalNavigation onClose={handleCloseNavigation} />
      </div>
    </>
  );
};

const baseStyle = css`
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    margin-left: ${Dimension.GlobalNavigationWidth}px;
  }
`;

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.GlobalNavigation - 1};
  display: none;
  background: ${toRGBA(Color.GrayscaleDarker, 0.5)};
  ${square('100%')}
`;

const overlayShownStyle = css`
  display: block;
`;

const navigationContainerStyle = css`
  position: fixed;
  top: 0;
  left: ${Dimension.GlobalNavigationWidth * -1}px;
  z-index: ${ZIndex.GlobalNavigation};
  width: ${Dimension.GlobalNavigationWidth}px;
  height: 100%;
  overflow-y: auto;
  background: ${Color.GrayscaleWhite};
  box-shadow: 1px 0 0 rgb(0 0 0 / 5%);
  transition: left ${Duration.Enter} ${Easing.Enter};
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: 0;
  }
`;

const navigationContainerShownStyle = css`
  left: 0;
`;

const mainStyle = css`
  padding: ${gutter(4)} 0;
`;
