import { addDays } from 'date-fns/esm';
import { css } from 'emotion';
import React, { memo, useMemo } from 'react';

import { Dimension } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { EmptyCards } from '../../pages/Root/components/EmptyCards';
import { Navigation } from './Navigation';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { WaitingListLegend } from './WaitingListLegend';

export type CalendarCells =
  | Time[][]
  | {
      empty: true;
      week: Date;
    };

export type Time = {
  date: Date;
  /**
   * 予約可能な枠数
   */
  available: number;

  estimatedDuration: number;

  doctor?: string;

  laneId: string;

  waitingListAvailable?: boolean;
};

export type CalendarOnSelectParam = {
  date: Date;
  estimatedDuration: number;
  laneId: string;
  doctor?: string;
  waitingList?: boolean;
};

type Props = {
  /**
   * 表示したい週のデータ。
   *
   * 週の始まりは任意の曜日でよい。
   * 一日あたりの時間枠の配列と、それを7日分ならんだ配列（= 二次元配列）。
   * 必ず7日分のデータを渡すこと。
   */
  items: CalendarCells;
  /**
   * 残り枠数を表示するしきい値。
   *
   * この値以下になると「のこりn枠」と表示する。
   *
   * @default 0
   */
  alertThreshold?: number;
  onSelect: (data: CalendarOnSelectParam) => void;
  onClickPrevWeek: (dateOfPrevWeek: Date) => void;
  onClickNextWeek: (dateOfNextWeek: Date) => void;

  /**
   * 現在日付
   */
  now: Date;

  /**
   * キャンセル待ち機能が使えるか？
   */
  waitingListEnabled?: boolean;
};

export const Calendar = memo(
  ({ items, alertThreshold = 0, onSelect, onClickPrevWeek, onClickNextWeek, now, waitingListEnabled }: Props) => {
    const headerItems = useMemo(() => {
      if ('empty' in items) {
        return [...new Array(7).keys()].map(d => addDays(items.week, d));
      }
      return items.map(item => item[0].date);
    }, [items]);
    if ('empty' in items) {
      return (
        <div className={baseStyle}>
          <header className={headerStyle}>
            <Navigation
              start={items.week}
              end={addDays(items.week, 6)}
              onClickPrevWeek={onClickPrevWeek}
              onClickNextWeek={onClickNextWeek}
            />
            <TableHeader items={headerItems} now={now} />
          </header>
          <EmptyCards>
            <div>予約が解禁されていません。</div>
          </EmptyCards>
        </div>
      );
    }

    return (
      <div className={baseStyle}>
        {waitingListEnabled && (
          <div className={legendStyle}>
            <WaitingListLegend />{' '}
          </div>
        )}
        <header className={headerStyle}>
          <Navigation
            start={items[0][0].date}
            end={items.slice(-1)[0][0].date}
            onClickPrevWeek={onClickPrevWeek}
            onClickNextWeek={onClickNextWeek}
          />
          <TableHeader items={headerItems} now={now} />
        </header>
        <TableBody
          now={now}
          items={items}
          alertThreshold={alertThreshold}
          onSelect={onSelect}
          waitingListEnabled={!!waitingListEnabled}
        />
      </div>
    );
  },
);

const legendStyle = css`
  margin-bottom: ${gutter(4)};
`;

const baseStyle = css`
  max-width: ${Dimension.ContentWidthMax}px;
`;

const headerStyle = css`
  position: sticky;
  top: 0;
`;
