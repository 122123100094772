import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';

import { AnswerInput, AnswerValueInput, Question } from '../../../../../../../gql/gql-types';
import { Button } from '../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Caption } from '../../../../../../components/Caption';
import { FormItem, FormLayout } from '../../../../../../components/Form';
import { InterviewReactiveFormItem } from '../../../../../../components/InterviewForm';
import { Wrapper } from '../../../../../../components/Wrapper';
import { Breakpoint, Dimension } from '../../../../../../constants/Style';
import { useContext } from '../../../../../../hooks/useContext';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { InterviewProgress } from '../../../../../Interviews/InterviewsNew/InterviewsInput/components/InterviewProgress';
import { transformer } from '../../../../../Interviews/InterviewsNew/InterviewsInput/transformer';
import { Interviews } from '../../../../../Interviews/InterviewsNew/stores/Interviews';
import { CheckupPreInterviewPostReserveStore } from '../stores/CheckupPreInterviewPostReserveStore';

type AnswerAction = {
  type: 'ANSWER';
  answer: AnswerInput;
};
type action =
  | AnswerAction
  | {
      type: 'OVERWRITE';
      initialState: AnswerInput[];
    };

const reducer = (previewState: AnswerInput[], action: action) => {
  switch (action.type) {
    case 'ANSWER':
    default:
      return [
        ...previewState.slice(
          0,
          previewState.findIndex(s => s.id === action.answer.id),
        ),
        action.answer,
        ...previewState.slice(previewState.findIndex(s => s.id === action.answer.id) + 1),
      ];
    case 'OVERWRITE':
      return [...action.initialState];
  }
};

const getDefaultValue = ({ answer }: Pick<Question, 'answer'>): AnswerValueInput[] => {
  if (answer && answer.length > 0) {
    return answer.map(a => ({
      id: String(a?.option?.id || 0),
      label: a?.option?.label || '',
      value: a?.answer || '',
    }));
  }
  return [];
};

type Prop = {
  onNext?: () => void;
  disabled?: boolean;
};

const validate = (answers: AnswerInput[], questions: NonNullable<Interviews['interview']>['questions']) =>
  answers.length > 0 && !answers.some(a => questions?.some(q => q?.id === a.id && q.required) && a.answer.length === 0);

export const CheckupPreInterviewPostReserveInputStep = observer(({ onNext, disabled }: Prop) => {
  const interviews = useContext(CheckupPreInterviewPostReserveStore.Context);
  const [doAnswerInterview, answerInterviewStatus] = useTransaction((answers: AnswerInput[]) =>
    interviews.answerInterview({
      treatmentId: interviews.treatmentId,
      answers,
    }),
  );

  const [answers, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({
      type: 'OVERWRITE',
      initialState: (interviews.interview?.questions || []).map(q => ({
        id: String(q?.id || 0),
        answer: q ? getDefaultValue(q) : [],
      })),
    });
  }, [interviews.interview]);

  useEffect(() => {
    validate(answers, interviews.interview?.questions || []) &&
      !answerInterviewStatus.running &&
      !interviews.interview?.finished &&
      doAnswerInterview(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const onChange = async (answer: AnswerInput) => {
    const action: AnswerAction = { type: 'ANSWER', answer };
    dispatch(action);
  };

  return (
    <>
      <div className={interviewProgressStyle}>
        <InterviewProgress
          total={interviews.estimatedQuestions}
          answered={answers.filter(a => a.answer.length > 0).length}
        />
      </div>
      <Wrapper>
        <Caption type="header" underline align="center">
          事前問診
        </Caption>

        <FormLayout>
          {(interviews.interview?.questions || []).map(
            (question, inx) =>
              question && (
                <InterviewReactiveFormItem
                  def={transformer(answers, question)}
                  onChange={value => onChange({ id: String(question.id || 0), answer: value })}
                  value={answers[inx] ? answers[inx].answer : []}
                  key={inx}
                />
              ),
          )}
          <FormItem>
            {validate(answers, interviews.interview?.questions) && (
              <ButtonGroup gutter={10}>
                <Button
                  block
                  onClick={() => {
                    onNext?.();
                  }}
                  disabled={disabled}
                >
                  確認画面に進む
                </Button>
              </ButtonGroup>
            )}
          </FormItem>
        </FormLayout>
      </Wrapper>
    </>
  );
});

const interviewProgressStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media (min-width: ${Breakpoint.ScreenMd}px) {
    left: ${Dimension.GlobalNavigationWidth}px;
  }
`;
