import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '../../../../constants/Routes';
import { useContext } from '../../../../hooks/useContext';
import { ReservationEditPage } from '../../../../pages/Reservations/ReservationDetail/ReservationEdit';
import { ReservationEditStore } from '../../../../pages/Reservations/ReservationDetail/ReservationEdit/stores/ReservationEditStore';
import { ReservationDetailStore } from '../../../../pages/Reservations/ReservationDetail/stores/ReservationDetailStore';

/**
 * 変更のアプリケーション。
 * ReservationDetailStoreをContextとして利用し、そこでロードされた情報をReservationEditStoreにつめなおす。
 */
export const ReservationEditApp = observer(() => {
  const editStore = useMemo(() => new ReservationEditStore(), []);
  const detailStore = useContext(ReservationDetailStore.Context);

  useEffect(() => {
    detailStore.reservation && editStore.fetchReservation(detailStore.reservation.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailStore.reservation]);

  return (
    <Switch>
      <ReservationEditStore.Context.Provider value={editStore}>
        <Route path={Routes.Paths.ReservationEdit} component={ReservationEditPage} />
      </ReservationEditStore.Context.Provider>
    </Switch>
  );
});
