import React, { ComponentProps } from 'react';

import { Card } from '../Card';
import { CheckboxGroup, ErrorLabel, FormLabel } from '../Form';
import { Radio } from '../Radio';
import { errorStyle, InterviewReactiveFormItem, toNonNull } from '.';
import { OptionProp } from './types';

type Prop = ComponentProps<typeof InterviewReactiveFormItem>;

export const InterviewPainRadioGroup = ({ def, value, onChange, errors, analyticsClassName }: Prop) => (
  <>
    <FormLabel required={!!def.required}>{def.label}</FormLabel>
    <Card className={errors && errorStyle}>
      <CheckboxGroup>
        {(def.options || []).map((option, index) => (
          <InterviewPainRadio
            def={def}
            option={toNonNull(option)}
            value={value}
            onChange={onChange}
            key={option?.id}
            analyticsClassName={`${analyticsClassName}_${index + 1}`}
          />
        ))}
      </CheckboxGroup>
    </Card>
    <ErrorLabel errors={errors} />
  </>
);

const InterviewPainRadio = ({ def, option, value, onChange, analyticsClassName }: OptionProp) => {
  const answerOpt = { label: option.label, value: option.value, id: String(option.id) };
  return (
    <>
      <Radio
        key={option.value}
        value={String(option.id)}
        name={String(def.id)}
        label={option.label}
        checked={value.some(v => v.id === option.id)}
        onChange={i => i.target.checked && onChange([answerOpt])}
        analyticsClassName={analyticsClassName}
      >
        <PainScaleIcon scale={option.value} />
      </Radio>
    </>
  );
};

type IconProp = {
  scale: string;
};
const PainScaleIcon = ({ scale }: IconProp) => {
  switch (scale) {
    case '1':
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H30V30H0V0Z" fill="white" />
          <path
            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
            fill="#C4425C"
          />
          <path
            d="M11.4 12.5998C11.4 13.5939 10.5941 14.3998 9.59999 14.3998C8.60588 14.3998 7.79999 13.5939 7.79999 12.5998C7.79999 11.6057 8.60588 10.7998 9.59999 10.7998C10.5941 10.7998 11.4 11.6057 11.4 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6485 9.16947C19.2311 9.27357 19.0578 9.39049 19.0243 9.42407C18.7899 9.65838 18.4101 9.65838 18.1757 9.42407C17.9414 9.18975 17.9414 8.80985 18.1757 8.57554C18.4422 8.30912 18.8733 8.12604 19.3581 8.00514C19.8664 7.87837 20.5166 7.7998 21.3 7.7998C22.0834 7.7998 22.7336 7.87837 23.2419 8.00514C23.7267 8.12604 24.1578 8.30912 24.4243 8.57554C24.6586 8.80985 24.6586 9.18975 24.4243 9.42407C24.19 9.65838 23.8101 9.65838 23.5757 9.42407C23.5422 9.39049 23.3689 9.27357 22.9515 9.16947C22.5576 9.07124 22.0078 8.9998 21.3 8.9998C20.5922 8.9998 20.0424 9.07124 19.6485 9.16947Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.04852 9.16947C6.63111 9.27357 6.45787 9.39049 6.42429 9.42407C6.18997 9.65838 5.81007 9.65838 5.57576 9.42407C5.34145 9.18975 5.34145 8.80985 5.57576 8.57554C5.84218 8.30912 6.27335 8.12604 6.75815 8.00514C7.26645 7.87837 7.91663 7.7998 8.70002 7.7998C9.48342 7.7998 10.1336 7.87837 10.6419 8.00514C11.1267 8.12604 11.5579 8.30912 11.8243 8.57554C12.0586 8.80985 12.0586 9.18975 11.8243 9.42407C11.59 9.65838 11.2101 9.65838 10.9758 9.42407C10.9422 9.39049 10.7689 9.27357 10.3515 9.16947C9.95761 9.07124 9.4078 8.9998 8.70002 8.9998C7.99225 8.9998 7.44243 9.07124 7.04852 9.16947Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3 20.4001C16.5349 20.4001 17.2929 19.6586 17.5757 19.3758C17.81 19.1415 18.1899 19.1415 18.4242 19.3758C18.6586 19.6101 18.6586 19.99 18.4242 20.2244C18.107 20.5416 17.0474 21.6001 15.3 21.6001C13.5526 21.6001 12.4929 20.5416 12.1757 20.2244C11.9414 19.99 11.9414 19.6101 12.1757 19.3758C12.41 19.1415 12.7899 19.1415 13.0242 19.3758C13.307 19.6586 14.0651 20.4001 15.3 20.4001Z"
            fill="white"
          />
          <path
            d="M22.2 12.5998C22.2 13.5939 21.3941 14.3998 20.4 14.3998C19.4059 14.3998 18.6 13.5939 18.6 12.5998C18.6 11.6057 19.4059 10.7998 20.4 10.7998C21.3941 10.7998 22.2 11.6057 22.2 12.5998Z"
            fill="white"
          />
        </svg>
      );
    case '2':
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H30V30H0V0Z" fill="white" />
          <path
            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
            fill="#A2426E"
          />
          <path
            d="M11.4 12.5998C11.4 13.5939 10.5941 14.3998 9.59999 14.3998C8.60588 14.3998 7.79999 13.5939 7.79999 12.5998C7.79999 11.6057 8.60588 10.7998 9.59999 10.7998C10.5941 10.7998 11.4 11.6057 11.4 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2605 8.43595C18.8923 8.6585 18.7612 8.82131 18.739 8.86329C18.5842 9.15628 18.2212 9.26831 17.9282 9.1135C17.6353 8.9587 17.5232 8.59569 17.678 8.3027C17.8541 7.96957 18.2121 7.66749 18.6397 7.40901C19.088 7.13799 19.6861 6.8712 20.4346 6.64019C21.1832 6.40919 21.8276 6.29254 22.3507 6.26379C22.8496 6.23636 23.3156 6.28416 23.6487 6.46017C23.9417 6.61497 24.0537 6.97798 23.8989 7.27097C23.7441 7.56396 23.3811 7.67598 23.0881 7.52118C23.0462 7.49899 22.8461 7.43836 22.4166 7.46198C22.0112 7.48426 21.4648 7.57813 20.7885 7.78684C20.1122 7.99554 19.6079 8.22593 19.2605 8.43595Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.04852 9.16947C6.63111 9.27357 6.45787 9.39049 6.42429 9.42407C6.18997 9.65838 5.81007 9.65838 5.57576 9.42407C5.34145 9.18975 5.34145 8.80985 5.57576 8.57554C5.84218 8.30912 6.27335 8.12604 6.75815 8.00514C7.26645 7.87837 7.91663 7.7998 8.70002 7.7998C9.48342 7.7998 10.1336 7.87837 10.6419 8.00514C11.1267 8.12604 11.5579 8.30912 11.8243 8.57554C12.0586 8.80985 12.0586 9.18975 11.8243 9.42407C11.59 9.65838 11.2101 9.65838 10.9758 9.42407C10.9422 9.39049 10.7689 9.27357 10.3515 9.16947C9.95761 9.07124 9.4078 8.9998 8.70002 8.9998C7.99225 8.9998 7.44243 9.07124 7.04852 9.16947Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 20.4001C18.3313 20.4001 18.6 20.6687 18.6 21.0001C18.6 21.3315 18.3314 21.6001 18 21.6001L12 21.6001C11.6686 21.6001 11.4 21.3315 11.4 21.0001C11.4 20.6687 11.6686 20.4001 12 20.4001L18 20.4001Z"
            fill="white"
          />
          <path
            d="M22.2 12.5998C22.2 13.5939 21.3941 14.3998 20.4 14.3998C19.4059 14.3998 18.6 13.5939 18.6 12.5998C18.6 11.6057 19.4059 10.7998 20.4 10.7998C21.3941 10.7998 22.2 11.6057 22.2 12.5998Z"
            fill="white"
          />
        </svg>
      );
    case '3':
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H30V30H0V0Z" fill="white" />
          <path
            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
            fill="#764485"
          />
          <path
            d="M11.4 12.5998C11.4 13.5939 10.5941 14.3998 9.59999 14.3998C8.60588 14.3998 7.79999 13.5939 7.79999 12.5998C7.79999 11.6057 8.60588 10.7998 9.59999 10.7998C10.5941 10.7998 11.4 11.6057 11.4 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8496 8.41853C19.4788 8.63675 19.3459 8.79801 19.3232 8.83974C19.165 9.1309 18.8007 9.23866 18.5095 9.08044C18.2184 8.92222 18.1106 8.55793 18.2688 8.26677C18.4487 7.93572 18.8102 7.63785 19.2408 7.38439C19.6923 7.11864 20.2935 6.85886 21.0447 6.63664C21.7959 6.41441 22.4417 6.30531 22.965 6.28268C23.4642 6.26109 23.9296 6.31434 24.2607 6.49424C24.5518 6.65246 24.6596 7.01675 24.5014 7.30791C24.3431 7.59907 23.9789 7.70684 23.6877 7.54862C23.646 7.52594 23.4467 7.46297 23.0169 7.48156C22.6113 7.4991 22.0638 7.58657 21.3851 7.78734C20.7064 7.98812 20.1994 8.21258 19.8496 8.41853Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.98553 7.40651C6.55698 7.36902 6.3551 7.42315 6.31242 7.44397C6.01458 7.58922 5.65538 7.46552 5.51013 7.16767C5.36489 6.86983 5.48859 6.51064 5.78643 6.36539C6.12508 6.20024 6.59237 6.16753 7.09011 6.21108C7.61199 6.25673 8.25232 6.39416 8.99302 6.64925C9.73372 6.90433 10.3229 7.19033 10.7622 7.4757C11.1812 7.74787 11.5293 8.06137 11.6944 8.40002C11.8397 8.69786 11.716 9.05706 11.4181 9.20231C11.1203 9.34755 10.7611 9.22385 10.6158 8.92601C10.595 8.88333 10.4693 8.71637 10.1085 8.48203C9.76807 8.26088 9.27148 8.01431 8.60228 7.78385C7.93308 7.55338 7.38996 7.44189 6.98553 7.40651Z"
            fill="white"
          />
          <path
            d="M22.2 12.5998C22.2 13.5939 21.3941 14.3998 20.4 14.3998C19.4059 14.3998 18.6 13.5939 18.6 12.5998C18.6 11.6057 19.4059 10.7998 20.4 10.7998C21.3941 10.7998 22.2 11.6057 22.2 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3 20.4002C14.0651 20.4002 13.307 21.1417 13.0243 21.4245C12.7899 21.6588 12.4101 21.6588 12.1757 21.4245C11.9414 21.1901 11.9414 20.8102 12.1757 20.5759C12.493 20.2587 13.5526 19.2002 15.3 19.2002C17.0474 19.2002 18.107 20.2587 18.4243 20.5759C18.6586 20.8102 18.6586 21.1901 18.4243 21.4245C18.1899 21.6588 17.8101 21.6588 17.5757 21.4245C17.293 21.1417 16.5349 20.4002 15.3 20.4002Z"
            fill="white"
          />
        </svg>
      );
    case '4':
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H30V30H0V0Z" fill="white" />
          <path
            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
            fill="#514499"
          />
          <path
            d="M28.2 11.25C28.2 10.305 27.3858 9.99 26.9143 9C26.4429 10.035 25.8 10.305 25.8 11.25C25.8 11.52 25.8 12.6 26.9143 12.6C28.0286 12.6 28.2 11.925 28.2 11.25Z"
            fill="white"
          />
          <path
            d="M11.4 12.5998C11.4 13.5939 10.5941 14.3998 9.59999 14.3998C8.60588 14.3998 7.79999 13.5939 7.79999 12.5998C7.79999 11.6057 8.60588 10.7998 9.59999 10.7998C10.5941 10.7998 11.4 11.6057 11.4 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9652 8.51888C23.3787 8.63743 23.5872 8.6228 23.6331 8.61051C23.9532 8.52475 24.2822 8.7147 24.3679 9.03478C24.4537 9.35485 24.2638 9.68386 23.9437 9.76962C23.5797 9.86714 23.1148 9.81011 22.6345 9.67241C22.1309 9.52804 21.5286 9.27099 20.8501 8.8793C20.1717 8.4876 19.6479 8.09447 19.2711 7.73054C18.9117 7.38343 18.6298 7.00929 18.5323 6.64536C18.4465 6.32528 18.6365 5.99628 18.9566 5.91051C19.2766 5.82475 19.6056 6.0147 19.6914 6.33478C19.7037 6.38065 19.7953 6.56852 20.1047 6.86738C20.3967 7.14941 20.8372 7.48618 21.4501 7.84007C22.0631 8.19395 22.5749 8.407 22.9652 8.51888Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.89546 6.86757C10.2049 6.56872 10.2965 6.38084 10.3088 6.33497C10.3945 6.01489 10.7235 5.82494 11.0436 5.91071C11.3637 5.99647 11.5536 6.32547 11.4679 6.64555C11.3704 7.00949 11.0885 7.38363 10.7291 7.73073C10.3523 8.09466 9.82849 8.48779 9.15005 8.87949C8.47161 9.27119 7.86925 9.52824 7.36567 9.67261C6.88537 9.8103 6.42043 9.86733 6.05649 9.76982C5.73641 9.68405 5.54646 9.35505 5.63223 9.03497C5.71799 8.71489 6.04699 8.52494 6.36707 8.61071C6.41295 8.623 6.62144 8.63763 7.03497 8.51908C7.42523 8.4072 7.9371 8.19415 8.55005 7.84026C9.163 7.48638 9.60344 7.1496 9.89546 6.86757Z"
            fill="white"
          />
          <path
            d="M22.2 12.5998C22.2 13.5939 21.3941 14.3998 20.4 14.3998C19.4059 14.3998 18.6 13.5939 18.6 12.5998C18.6 11.6057 19.4059 10.7998 20.4 10.7998C21.3941 10.7998 22.2 11.6057 22.2 12.5998Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1306 22.0413C12.5384 22.6377 12.1346 23.3278 11.9195 23.7C11.7538 23.9869 11.3868 24.0851 11.0999 23.9193C10.8129 23.7535 10.7147 23.3866 10.8805 23.0996C11.0988 22.7218 11.568 21.9119 12.279 21.1958C12.9893 20.4805 14.0001 19.7998 15.3 19.7998C16.6 19.7998 17.6108 20.4805 18.321 21.1958C19.032 21.9119 19.5013 22.7218 19.7195 23.0996C19.8853 23.3866 19.7871 23.7535 19.5002 23.9193C19.2133 24.0851 18.8463 23.9869 18.6805 23.7C18.4655 23.3278 18.0616 22.6377 17.4695 22.0413C16.8765 21.4441 16.154 20.9998 15.3 20.9998C14.446 20.9998 13.7235 21.4441 13.1306 22.0413Z"
            fill="white"
          />
        </svg>
      );
    case '5':
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H30V30H0V0Z" fill="white" />
          <path
            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
            fill="#3045AA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.88175 11.0976C8.04872 10.8113 8.41611 10.7147 8.70234 10.8816L12.3023 12.9816C12.4991 13.0964 12.6139 13.3126 12.5987 13.5399C12.5835 13.7671 12.441 13.9662 12.2308 14.0537L8.63079 15.5537C8.32491 15.6812 7.97363 15.5366 7.84618 15.2307C7.71873 14.9248 7.86337 14.5735 8.16925 14.4461L10.6554 13.4102L8.0977 11.9182C7.81147 11.7512 7.71479 11.3838 7.88175 11.0976Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4915 11.0558C21.3015 10.7843 20.9274 10.7183 20.6559 10.9083L17.6559 13.0083C17.4827 13.1296 17.3861 13.3331 17.4016 13.5439C17.4171 13.7548 17.5425 13.9419 17.7316 14.0365L20.7316 15.5365C21.028 15.6847 21.3884 15.5646 21.5366 15.2682C21.6848 14.9718 21.5647 14.6114 21.2683 14.4632L19.1693 13.4137L21.344 11.8914C21.6155 11.7014 21.6815 11.3272 21.4915 11.0558Z"
            fill="white"
          />
          <path
            d="M28.2 11.25C28.2 10.305 27.3858 9.99 26.9143 9C26.4429 10.035 25.8 10.305 25.8 11.25C25.8 11.52 25.8 12.6 26.9143 12.6C28.0286 12.6 28.2 11.925 28.2 11.25Z"
            fill="white"
          />
          <path
            d="M28.2 15.4502C28.2 14.5052 27.3858 14.1902 26.9143 13.2002C26.4429 14.2352 25.8 14.5052 25.8 15.4502C25.8 15.7202 25.8 16.8002 26.9143 16.8002C28.0286 16.8002 28.2 16.1252 28.2 15.4502Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1306 22.0413C12.5384 22.6377 12.1346 23.3278 11.9195 23.7C11.7538 23.9869 11.3868 24.0851 11.0999 23.9193C10.8129 23.7535 10.7147 23.3866 10.8805 23.0996C11.0988 22.7218 11.568 21.9119 12.279 21.1958C12.9893 20.4805 14.0001 19.7998 15.3 19.7998C16.6 19.7998 17.6108 20.4805 18.321 21.1958C19.032 21.9119 19.5013 22.7218 19.7195 23.0996C19.8853 23.3866 19.7871 23.7535 19.5002 23.9193C19.2133 24.0851 18.8463 23.9869 18.6805 23.7C18.4655 23.3278 18.0616 22.6377 17.4695 22.0413C16.8765 21.4441 16.154 20.9998 15.3 20.9998C14.446 20.9998 13.7235 21.4441 13.1306 22.0413Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9652 8.51888C23.3787 8.63743 23.5872 8.6228 23.6331 8.61051C23.9532 8.52475 24.2822 8.7147 24.3679 9.03478C24.4537 9.35485 24.2638 9.68386 23.9437 9.76962C23.5797 9.86714 23.1148 9.81011 22.6345 9.67241C22.1309 9.52804 21.5286 9.27099 20.8501 8.8793C20.1717 8.4876 19.6479 8.09447 19.2711 7.73054C18.9117 7.38343 18.6298 7.00929 18.5323 6.64536C18.4465 6.32528 18.6365 5.99628 18.9566 5.91051C19.2766 5.82475 19.6056 6.0147 19.6914 6.33478C19.7037 6.38065 19.7953 6.56852 20.1047 6.86738C20.3967 7.14941 20.8372 7.48618 21.4501 7.84007C22.0631 8.19395 22.5749 8.407 22.9652 8.51888Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.89546 6.86757C10.2049 6.56872 10.2965 6.38084 10.3088 6.33497C10.3945 6.01489 10.7235 5.82494 11.0436 5.91071C11.3637 5.99647 11.5536 6.32547 11.4679 6.64555C11.3704 7.00949 11.0885 7.38363 10.7291 7.73073C10.3523 8.09466 9.82849 8.48779 9.15005 8.87949C8.47161 9.27119 7.86925 9.52824 7.36567 9.67261C6.88537 9.8103 6.42043 9.86733 6.05649 9.76982C5.73641 9.68405 5.54646 9.35505 5.63223 9.03497C5.71799 8.71489 6.04699 8.52494 6.36707 8.61071C6.41295 8.623 6.62144 8.63763 7.03497 8.51908C7.42523 8.4072 7.9371 8.19415 8.55005 7.84026C9.163 7.48638 9.60344 7.1496 9.89546 6.86757Z"
            fill="white"
          />
        </svg>
      );

    default:
      return <></>;
  }
};
