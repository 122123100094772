import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Caption } from '../../../components/Caption';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { FormItem, FormLayout } from '../../../components/Form';
import { Icon } from '../../../components/Icon';
import { TextField } from '../../../components/TextField';
import { useToast } from '../../../components/Toast';
import { Wrapper } from '../../../components/Wrapper';
import { Routes } from '../../../constants/Routes';
import { URLSearchParamsMonad } from '../../../domains/base/SearchParams';
import { useContext } from '../../../hooks/useContext';
import { useTransaction } from '../../../hooks/useTransaction';
import { SessionStore } from '../../../stores/SessionStore';
import { SignInLegacyStore } from './stores/SignInLegacyStore';

type type = 'LINE' | 'LEGACY';
type Props = {
  onChangeMethod?: (method: type) => void;
};

export const SignInLegacyPage = observer(({ onChangeMethod }: Props) => {
  const store = useMemo(() => new SignInLegacyStore(), []);
  const [error, setError] = useState<any[]>([]);
  const { push } = useHistory();
  const session = useContext(SessionStore.Context);
  const { addToast } = useToast();
  const [signIn, status] = useTransaction(async () => {
    if (!store.email || !store.password) {
      return;
    }
    try {
      if (await store.signIn()) {
        await session.legacySignIn(store.email, store.password);
        addToast({ message: 'ユーザーを認証しました。', icon: 'check-circle' });
        push({
          pathname: Routes.Paths.AccountName,
          search: window.location.search,
        });
      }
    } catch (e) {
      setError([e]);
    }
  });

  return (
    <Wrapper>
      <DocumentTitle title="メールアドレスでログイン" />
      <Caption type="header" align="center">
        メールアドレスでログイン
      </Caption>
      <Caption type="body">（過去にメールアドレスで登録済みの方のみ。新規登録はLINEのみとなります）</Caption>
      <FormLayout>
        <FormItem>
          <TextField
            placeholder="メールアドレス"
            color="white"
            type="email"
            value={store.email}
            onChange={v => store.setEmail(v)}
          />
        </FormItem>
        <FormItem>
          <TextField
            placeholder="パスワード"
            color="white"
            type="password"
            value={store.password}
            onChange={v => store.setPassword(v)}
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <Button onClick={() => signIn()} disabled={!!status.running}>
              ログイン
            </Button>
            {error.map(e => e?.message || 'エラーがあります。')}
          </ButtonGroup>
        </FormItem>

        <FormItem>
          <ButtonGroup>
            <Button ghost block noBorder onClick={() => push(Routes.Paths.ForgotPassword)}>
              <Icon name="question-circle" />
              パスワードをお忘れの方
            </Button>
            {/* <Button
              ghost
              block
              noBorder
              onClick={() =>
                push({
                  pathname: Routes.Paths.AccountNew,
                  search: new URLSearchParamsMonad(window.location.search).accept('type', 'LEGACY').toString(),
                })
              }
            >
              <Icon name="user" />
              新規会員登録
            </Button> */}
            <Button
              theme="line"
              block
              onClick={() => {
                push({
                  pathname: Routes.Paths.AccountNew,
                  search: new URLSearchParamsMonad(window.location.search).accept('type', 'LINE').toString(),
                });
                onChangeMethod?.('LINE');
              }}
            >
              <Icon name="line" />
              LINEアカウントでログイン
            </Button>
          </ButtonGroup>
        </FormItem>
      </FormLayout>
    </Wrapper>
  );
});
