import React, { useEffect, useMemo, useState } from 'react';

import { Card } from '../../../../../components/Card';
import { Modal } from '../../../../../components/Modal';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { CheckupVacancyStore } from '../../../Reservations/CheckupReservationsNew/CheckupReservationVacancySelect/stores/CheckupVacancyStore';
import { IndividualCheckupReservationStore } from '../IndividualCheckupReservationsNew/stores/IndividualCheckupReservationStore';
import { IndividualCheckupReservationEditOptionSelectPage } from './IndividualCheckupReservationEditOptionSelect';
import { IndividualCheckupReservationEditVacancySelectPage } from './IndividualCheckupReservationEditVacancySelect';

type STEPS = 'optionSelect' | 'vacancySelect';
export const IndividualCheckupReservationEditPage = () => {
  const [step, setStep] = useState<STEPS>('optionSelect');
  const store = useMemo(() => new IndividualCheckupReservationStore(), []);
  const vacancyStore = useMemo(() => new CheckupVacancyStore(), []);
  const { reservationId } = useReservationId();
  const [load, loadStatus] = useTransaction(() => store.fetchCheckupTreatmentInfo(reservationId));

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const main = () => {
    switch (step) {
      case 'optionSelect':
      default:
        return <IndividualCheckupReservationEditOptionSelectPage onNext={() => setStep('vacancySelect')} />;
      case 'vacancySelect':
        return <IndividualCheckupReservationEditVacancySelectPage />;
    }
  };

  return (
    <IndividualCheckupReservationStore.Context.Provider value={store}>
      <CheckupVacancyStore.Context.Provider value={vacancyStore}>
        {loadStatus.running ? (
          <Modal visible>
            <Card>診察情報を取得中</Card>
          </Modal>
        ) : (
          main()
        )}
      </CheckupVacancyStore.Context.Provider>
    </IndividualCheckupReservationStore.Context.Provider>
  );
};
