import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ErrorDialogProvider } from '../components/ErrorDialog';
import { Layout } from '../components/Layout';
import { ToastProvider } from '../components/Toast';
import { useAuthNotification } from '../hooks/useAuthNotification';
import { useAuthRedirect } from '../hooks/useAuthRedirect';
import { useRouterLog } from '../hooks/useRouterLog';
import { PayjpStore } from '../pages/Accounts/AccountDetail/stores/PayjpStore';
import { ExtendedDictionaryStore } from '../stores/ExtendedDictionaryStore';
import { SessionStore } from '../stores/SessionStore';
import { Guest } from './Guest';
import { Member } from './Member';

export const App = observer(() => {
  const sessionStore = useMemo(() => new SessionStore(), []);
  const dictionaryStore = useMemo(() => new ExtendedDictionaryStore(), []);
  const payjpStore = useMemo(() => new PayjpStore(), []);

  useEffect(() => {
    sessionStore.fetchSession();
  }, [sessionStore]);

  useEffect(() => {
    dictionaryStore.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExtendedDictionaryStore.Context.Provider value={dictionaryStore}>
      <SessionStore.Context.Provider value={sessionStore}>
        <ToastProvider>
          <ErrorDialogProvider>
            <BrowserRouter>
              <PayjpStore.Context.Provider value={payjpStore}>
                <BootLoader sessionStore={sessionStore} />
              </PayjpStore.Context.Provider>
            </BrowserRouter>
          </ErrorDialogProvider>
        </ToastProvider>
      </SessionStore.Context.Provider>
    </ExtendedDictionaryStore.Context.Provider>
  );
});

type BootLoaderProps = {
  sessionStore: SessionStore;
};

/**
 * SessionStoreの状態によって読み込むアプリをスイッチする。
 * authenticated === trueの場合はMemberアプリを、 falseの場合はGuestアプリを読み込む。
 * authStateKnown === falseの場合（まだセッションの状態が未確定の場合）には、空のアプリを読み込む。
 */
const BootLoader = observer(({ sessionStore }: BootLoaderProps) => {
  useAuthRedirect();
  useAuthNotification();
  useRouterLog();
  if (!sessionStore.authStateKnown) {
    return (
      <Layout>
        <></>
      </Layout>
    );
  }
  return sessionStore.authenticated ? <Member /> : <Guest />;
});
