import gql from 'graphql-tag';

export const getAttachment = gql`
  query getAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      key
      name
    }
  }
`;
