import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Card } from '../../../../components/Card';
import { Icon } from '../../../../components/Icon';
import { Color } from '../../../../constants/Style';
import { square } from '../../../../helpers/Style';
import { CancelPolicyStore } from '../../../../stores/CancelPolicyStore';

/**
 * キャンセルポリシーを表示するコンテンツ
 * componentごとに現状作成されているので今後はこちらを利用する
 */

type Props = {
  date?: Date;
  actionType: 'reserve' | 'edit' | 'cancel';
  store: CancelPolicyStore;
};

export const CancelPolicyContent = observer(({ date, store, actionType }: Props) => {
  // 予約時は時間に関わらずキャンセルポリシーを全部表示する
  if (!date || (actionType !== 'reserve' && !store.isJustBeforeAction(date))) {
    return null;
  }

  if (actionType !== 'reserve' && !store.hasPenalty(date)) {
    return (
      <div className={cancelPolicyCardStyle}>
        <Card color="warning">ご予約のお時間まで24時間を切っています。今後は余裕を持った変更をお願いします。</Card>
      </div>
    );
  }

  if (!store.cancelPolicyInfo) {
    return null;
  }

  const { greetingText, cancelFee, timeToBan, cancelableTimeLimitText } = store.cancelPolicyInfo;

  return (
    <div className={cancelPolicyCardStyle}>
      <Card color="redNeutral">
        <span className={actionType === 'reserve' ? '' : cancelPolicyRedStyle}>
          {actionType === 'reserve' ? (
            <>
              <div className={cancelPolicyIconStyle}>
                <Icon name="exclamation-triangle" />
              </div>
              {greetingText}
              <span className={cancelPolicyRedStyle}>{`キャンセル・予約変更は${cancelableTimeLimitText}まで`}</span>
              にお願いします。
            </>
          ) : (
            <>
              直前の予約変更です。
              <br />
            </>
          )}

          {cancelFee.map(({ differenceText, feeAmount }) => {
            if (!differenceText || !feeAmount) return <></>;
            return (
              <>
                {`自費診療は予約時間${differenceText}からキャンセル料${feeAmount}円が発生します。`}
                <br />
              </>
            );
          })}
          {actionType === 'reserve'
            ? `${timeToBan.maxBanCount}回以上の直前/当日予約変更はアカウント停止となりますのでご注意ください。`
            : `${timeToBan.maxBanCount}回以上直前キャンセル/予約変更を行うとアカウント停止となります。`}
        </span>
      </Card>
    </div>
  );
});

const cancelPolicyCardStyle = css`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 4px;
`;

const cancelPolicyIconStyle = css`
  text-align: center;

  > svg {
    ${square(32)}

    fill: ${Color.FunctionalRedNeutral};
  }
`;

const cancelPolicyRedStyle = css`
  font-weight: 600;
  color: ${Color.FunctionalRedNeutral};
`;
