import { action, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  AttachmentType,
  GetDownloadableLinkQuery,
  GetDownloadableLinkQueryVariables,
  GetTreatmentHistoryQuery,
  GetTreatmentHistoryQueryVariables,
} from '../../../../../gql/gql-types';
import getDownloadableLink from '../../../../../gql/operations/getDownloadableLink';
import getTreatment from '../../../../../gql/operations/getTreatment';
import { Treatment } from '../../../../interfaces/Treatment';
import { TreatmentDate } from '../../TreatmentList/stores/TreatmentListStore';
import { latestPaymentState } from '../../utils/latestPaymentState';

export class TreatmentDetailStore {
  public static Context = createContext<TreatmentDetailStore | null>(null);

  public treatment?: Treatment | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {
      fetchTreatment: action,
      setTreatment: action,
      treatment: observable,
    });
  }

  public setTreatment(t: Treatment) {
    this.treatment = { ...t };
  }

  public async fetchDownloadableLink(id: string) {
    const res = await GQL.query<GetDownloadableLinkQueryVariables, GetDownloadableLinkQuery>(getDownloadableLink, {
      input: { id },
    });
    return res.getDownloadableLink?.url;
  }

  public async fetchTreatment(id: string) {
    const res = await GQL.query<GetTreatmentHistoryQueryVariables, GetTreatmentHistoryQuery>(getTreatment, {
      treatmentId: id,
    });
    res.getTreatment &&
      this.setTreatment({
        id,
        clinic: { id: '', name: '', ...res.getTreatment.department?.clinic },
        date: res.getTreatment.date
          ? new TreatmentDate(res.getTreatment.date, res.getTreatment.time).toDate()
          : new Date(),
        attachments:
          res.getTreatment.attachments.items?.map(item => ({
            key: item?.key || '',
            id: item?.id || '',
            name: item?.name || '',
            type: item?.type || AttachmentType.Free,
          })) || [],
        department: { id: '', name: '', ...res.getTreatment.department },
        doctor: { id: '', name: '', ...res.getTreatment.feedback?.doctor },
        payment: {
          amount: latestPaymentState(res.getTreatment.payment as any)?.amount,
        },
        isNoCharge: !!res.getTreatment.noChargeReason,
        duration: res.getTreatment.duration || 0,
        soap: {
          feedback: (() => {
            if (res.getTreatment.status === 'NO_SHOW') {
              return '利用規約に基づき、キャンセル料を請求させていただきます。';
            }
            return res.getTreatment.feedback?.feedback || '受診お疲れ様でした。お大事にしてください。';
          })(),
          doctor: { id: '', name: '', ...res.getTreatment.feedback?.doctor },
        },
        treatmentKind: {
          online: res.getTreatment.treatmentKind?.online,
        },
        checkupAppointment: {
          organizationName: '',
          result: res.getTreatment.checkupAppointment?.result,
          plan: {
            label: res.getTreatment.checkupAppointment?.plan.label || '',
          },
        },
      });
  }
}
