import { action, makeAutoObservable, observable } from 'mobx';

import { GQL } from '../../../../gql/client';
import {
  GetAttachmentQuery,
  GetAttachmentQueryVariables,
  GetDownloadableLinkQuery,
  GetDownloadableLinkQueryVariables,
} from '../../../../gql/gql-types';
import { getAttachment } from '../../../../gql/operations/getAttachment';
import getDownloadableLink from '../../../../gql/operations/getDownloadableLink';

export class AttachmentStore {
  constructor() {
    makeAutoObservable(this, {
      fetchDownloadableLink: action,
      fetch: action,
      name: observable,
      id: observable,
    });
  }

  public name?: string = undefined;
  public id?: string = undefined;

  public async fetch(id: string) {
    this.id = id;
    const res = await GQL.query<GetAttachmentQueryVariables, GetAttachmentQuery>(getAttachment, { id });
    this.name = res.getAttachment?.name;
  }

  public async fetchDownloadableLink() {
    if (!this.id) {
      return false;
    }
    const res = await GQL.query<GetDownloadableLinkQueryVariables, GetDownloadableLinkQuery>(getDownloadableLink, {
      input: { id: this.id },
    });
    return res.getDownloadableLink?.url;
  }
}
