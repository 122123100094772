import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '../../../constants/Routes';
import { useContext } from '../../../hooks/useContext';
import { AccountDetailPage } from '../../../pages/Accounts/AccountDetail';
import { AccountEditPage } from '../../../pages/Accounts/AccountDetail/AccountEdit';
import { FirstReserverAccountEditPage } from '../../../pages/Accounts/AccountDetail/FirstReserve';
import { SettlementEditPage } from '../../../pages/Accounts/AccountDetail/SettlementEdit';
import { AccountDetailStore } from '../../../pages/Accounts/AccountDetail/stores/AccountDetailStore';
import { SessionStore } from '../../../stores/SessionStore';

export const AccountDetailApp = () => {
  const detailStore = useMemo(() => new AccountDetailStore(), []);
  const sessionStore = useContext(SessionStore.Context);

  useEffect(() => {
    sessionStore.user && detailStore.fetchProfile(sessionStore.user.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.user]);

  return (
    <Switch>
      <AccountDetailStore.Context.Provider value={detailStore}>
        <Route path={Routes.Paths.Account} exact>
          <AccountDetailPage />
        </Route>
        <Route path={Routes.Paths.AccountProfileEdit} exact>
          <AccountEditPage />
        </Route>
        <Route path={Routes.Paths.AccountSettlementEdit} exact>
          <SettlementEditPage />
        </Route>
        <Route path={Routes.Paths.ReservationAccountPreEdit} exact>
          <FirstReserverAccountEditPage />
        </Route>
      </AccountDetailStore.Context.Provider>
    </Switch>
  );
};
