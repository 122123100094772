import { css } from 'emotion';
import React, { ComponentProps, useState } from 'react';

import { CheckupOptionRecommendSeverity } from '../../../../../../../../gql/gql-types';
import { Button } from '../../../../../../../components/Button';
import { ButtonGroup } from '../../../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../../../components/Caption';
import { Card } from '../../../../../../../components/Card';
import { Checkbox } from '../../../../../../../components/Checkbox';
import { Icon } from '../../../../../../../components/Icon';
import { Modal } from '../../../../../../../components/Modal';
import { Tip } from '../../../../../../../components/Tip';
import { Color, FontSize } from '../../../../../../../constants/Style';
import { square } from '../../../../../../../helpers/Style';

type OptionBillType =
  | '1_organization' // 団体持ち
  | '2_own'; // 個人持ち

type CheckboxOnChangeHandler = (event: { target: { checked: boolean } }) => void;

type Props = Pick<ComponentProps<typeof Checkbox>, 'checked' | 'disabled' | 'label' | 'value'> & {
  suggestText?: string;
  price: number;
  discountPrice?: number;
  billingType?: OptionBillType;
  description?: string;
  onChange?: CheckboxOnChangeHandler;
  suggestSeverity?: string;
};

const flexStyle = css`
  display: flex;
`;

const suffixStyle = css`
  flex-grow: 1;
  margin-left: 8px;
`;

export const CheckupOptionCheckbox = ({
  checked,
  disabled,
  label,
  onChange,
  value,
  suggestText,
  price,
  discountPrice,
  billingType,
  description,
  suggestSeverity,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={flexStyle}>
      <Modal visible={dialogOpen}>
        <Card>
          <CaptionGroup>
            <Caption type="header" align="center">
              {label}
            </Caption>
            <Caption type="div" align="center">
              {description}
            </Caption>
            <div className={dialogMoneyStyle}>
              ¥{(discountPrice === undefined ? price : discountPrice).toLocaleString()}
            </div>
          </CaptionGroup>
          <ButtonGroup>
            <Button
              block
              onClick={() => {
                onChange?.({ target: { checked: true } });
                setDialogOpen(false);
              }}
            >
              選択する
            </Button>
            <Button block ghost onClick={() => setDialogOpen(false)}>
              閉じる
            </Button>
          </ButtonGroup>
        </Card>
      </Modal>
      <Checkbox checked={checked} disabled={disabled} onChange={onChange} label="" value={value} />
      <>
        <div className={suffixStyle}>
          <div className={flexStyle}>
            <div>
              <span
                onClick={() => {
                  onChange?.({ target: { checked: !checked } });
                }}
              >
                {label}
              </span>
              <span
                onClick={ev => {
                  setDialogOpen(true);
                  ev.preventDefault();
                  ev.stopPropagation();
                  return false;
                }}
                className={questionStyle}
              >
                {description && <Icon name="question-circle" />}
              </span>
            </div>
            <div className={numberStyle}>
              <div>{billingType === '1_organization' ? <Tip inverted>会社負担</Tip> : <div />}</div>
              <div className={numberGridStyle}>
                {discountPrice !== undefined || billingType === '1_organization' ? (
                  <div>
                    <s className={mutedStyle}>¥ {price.toLocaleString()} - </s>
                  </div>
                ) : (
                  <div />
                )}
                <div className={priceStyle}>
                  ¥{' '}
                  {billingType === '1_organization'
                    ? '0'
                    : (discountPrice === undefined ? price : discountPrice).toLocaleString()}{' '}
                  -
                </div>
              </div>
            </div>
          </div>
          <div>{suggestText ? <span className={suggestStyle(suggestSeverity)}>{suggestText}</span> : null}</div>
        </div>
      </>
    </div>
  );
};

const dialogMoneyStyle = css`
  margin: 16px 0;
  font-weight: bold;
  text-align: center;
`;

const suggestStyle = (severity = 'success') => suggestStyles[isSuggestSeverity(severity) ? severity : 'success'];
const isSuggestSeverity = (key: string): key is keyof typeof suggestStyles => key in suggestStyles;

const suggestStyles = {
  success: css`
    font-size: ${FontSize.Small};
    color: ${Color.FunctionalGreenNeutral};
  `,
  danger: css`
    font-size: ${FontSize.Small};
    color: ${Color.FunctionalRedNeutral};
  `,
  [CheckupOptionRecommendSeverity.Info]: css`
    font-size: ${FontSize.Small};
    color: ${Color.FunctionalGreenNeutral};
  `,
  [CheckupOptionRecommendSeverity.Danger]: css`
    font-size: ${FontSize.Small};
    color: ${Color.FunctionalRedNeutral};
  `,
};

const numberStyle = css`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const numberGridStyle = css`
  display: grid;
  grid-template-columns: 72px auto;
`;

const mutedStyle = css`
  font-weight: normal;
  color: ${Color.GrayscaleLight};
`;

const priceStyle = css`
  text-align: right;
`;

const questionStyle = css`
  > svg {
    ${square(FontSize.Medium)}

    cursor: pointer;
    fill: ${Color.GrayscaleNeutral};
  }
`;
