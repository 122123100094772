import gql from 'graphql-tag';

import { InterviewType } from '../fragments/interview';

export default gql`
  mutation reAnswerInterview($input: ReAnswerInterviewInput) {
    reAnswerInterview(input: $input) {
      ...InterviewType
    }
  }
  ${InterviewType}
`;
