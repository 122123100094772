import gql from 'graphql-tag';

import { CheckupResultJudgedSubGroupType } from './checkupResultJudgedSubGroup';

export const TreatmentType = gql`
  fragment TreatmentHistory on Treatment {
    id
    status
    date
    time
    department {
      name
      clinic {
        name
      }
    }
    duration
    feedback {
      feedback
      doctor {
        id
        name
      }
    }
    noChargeReason
    payment {
      job {
        items {
          amount
          createdAt
        }
      }
    }
    attachments {
      items {
        id
        key
        name
        type
      }
    }
    treatmentKind {
      online
    }
    checkupAppointment {
      plan {
        label
      }
      checkupOrganizationMemberEvent {
        event {
          organization {
            name
          }
        }
      }
      result {
        id
        status
        userId
        date
        summary {
          result
          comment
          departmentId
          overallComment
        }
        judged {
          ...CheckupResultJudgedSubGroupType
        }
      }
    }
  }
  ${CheckupResultJudgedSubGroupType}
`;
