import React from 'react';

export const Safari = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1 7.5C14.1 11.1451 11.1451 14.1 7.5 14.1C3.85492 14.1 0.9 11.1451 0.9 7.5C0.9 3.85492 3.85492 0.9 7.5 0.9C11.1451 0.9 14.1 3.85492 14.1 7.5ZM15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM9.06076 8.28673C9.04981 8.33623 8.99393 8.36084 8.95002 8.33549L8.95003 8.33549C8.99393 8.36084 9.00056 8.42155 8.96316 8.45577L4.79727 12.2684C4.74322 12.3179 4.65759 12.2684 4.6734 12.1969L5.89227 6.6828C5.90322 6.63329 5.9591 6.60868 6.00301 6.63403L6.003 6.63403C5.9591 6.60868 5.95247 6.54798 5.98987 6.51375L10.1558 2.70114C10.2098 2.65167 10.2954 2.70111 10.2796 2.77266L9.06076 8.28673Z"
      fill="#2CA9E1"
    />
  </svg>
);
