import { Hub } from '@aws-amplify/core';
import { useEffect } from 'react';

import { useToast } from '../components/Toast';

/**
 * Cognito経由でリダイレクトした際に、toastを表示するhook。
 * TODO providerを判定する。
 */
export const useAuthNotification = () => {
  const { addToast } = useToast();
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'customOAuthState') {
        addToast({ message: 'LINEアカウントを認証しました', icon: 'check-circle', theme: 'success' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
