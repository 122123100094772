import { IconName } from '@clinic-monorepo/clinic-icon';
import { css } from 'emotion';
import React from 'react';

import { Color, Duration, IconSize } from '../../constants/Style';
import { Environment } from '../../environments';
import { gutter, square } from '../../helpers/Style';
import { Icon } from '../Icon';

export const SNSMenu = () => (
  <ul className={baseStyle}>
    {data.map(({ name, to, disabled }) => (
      <li key={name} className={itemStyle}>
        <Link name={name} to={to} disabled={disabled} />
      </li>
    ))}
  </ul>
);

const Link = ({ name, to, disabled }: LinkProps) => {
  if (disabled) {
    return (
      <span className={disabledStyle}>
        <Icon name={name} />
      </span>
    );
  }
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      <Icon name={name} />
    </a>
  );
};

const data = [
  {
    name: 'facebook',
    to: 'https://www.facebook.com/ClinicTENShibuya/',
    disabled: false,
  },
  {
    name: 'line',
    to: Environment.friendRequestUrl,
    disabled: false,
  },
  {
    name: 'instagram',
    to: 'https://www.instagram.com/clinic_ten_shibuya',
    disabled: false,
  },
  {
    name: 'note',
    to: 'https://note.com',
    disabled: true,
  },
] as const;

type LinkProps = {
  name: IconName;
  to: string;
  disabled: boolean;
};

const baseStyle = css`
  display: flex;
  justify-content: center;
  margin-top: ${gutter(12)};
`;

const itemStyle = css`
  margin: 0 ${gutter(3)};
  ${square(IconSize.Large)}

  > a,span {
    display: block;

    > svg {
      fill: #999;
      transition: ${Duration.Fade};
    }

    &:hover {
      > svg {
        fill: ${Color.GrayscaleDarker};
      }
    }
  }
`;

const disabledStyle = css`
  cursor: not-allowed;
`;
