import { css } from 'emotion';
import React from 'react';

import { Button } from '../../../../../../components/Button';
import { Routes } from '../../../../../../constants/Routes';
import { gutter } from '../../../../../../helpers/Style';
import { useTreatmentId } from '../../../../../../hooks/useTreatmentId';
import { Result } from '../../../../../../interfaces/Result';
import { CheckupResultSubGroupStore } from '../../../../Base/stores/CheckupResultSubGroupStore';
import { StrategicResultItemCards } from '../../../../components/StrategicResultItemCard';
import { ItemInfo } from '../../OtherItems/ItemInfo';

type Props = {
  judged: CheckupResultSubGroupStore;
};
export const RecommendItem = ({ judged }: Props) => {
  const { push, treatmentId } = useTreatmentId();

  return (
    <>
      <div>
        <div key={judged.subgroupResult.subGroupId}>
          <ItemInfo name={judged.subGroup.name} result={judged.result as Result} />
          <StrategicResultItemCards store={judged} />
        </div>
      </div>
      <div className={buttonContainerStyle}>
        <Button
          ghost
          onClick={() =>
            push(Routes.Paths.CheckupResultBase.replace(':subGroupId', judged.subgroupResult.subGroupId), treatmentId)
          }
          block
        >
          詳細を見る
        </Button>
      </div>
    </>
  );
};

const buttonContainerStyle = css`
  margin: 0 auto ${gutter(10)};
`;
