import gql from 'graphql-tag';

export const listDepartments = gql`
  query ListDepartments {
    listDepartments {
      items {
        id
        name
        type
        baseDuration
        icon
        alertThreshold
        orcaID
        clinic {
          id
          name
          termsUrl
        }
        online
        order
        displayName
        hidden
        description
        announcement
        requirement
      }
    }
  }
`;
