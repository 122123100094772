import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { ErrorMessage } from '../../../../../components/ErrorMessage';
import { FormItem, FormLabel, FormLayout, FormSubLabel } from '../../../../../components/Form';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { AccountDetailStore } from '../../stores/AccountDetailStore';
import { STEP } from '..';
import { AddressInput } from '../components/AddressInput';
import { InsuranceCardInput } from '../components/InsuranceCardInput';

export type FormDirtyItem = {
  insuranceCard: boolean;
  phone: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  pref: boolean;
  zipCode: boolean;
};

type Props = {
  store: AccountDetailStore;
  setStep: (step: string) => void;
};
export const RegisterPage = observer(({ store, setStep }: Props) => {
  const [dirty, setDirty] = useState<FormDirtyItem>({
    insuranceCard: false,
    phone: false,
    addressLine1: false,
    addressLine2: false,
    pref: false,
    zipCode: false,
  });

  const onFocusOut = (target: string) => {
    setDirty({
      ...dirty,
      [target]: true,
    });
  };

  const [onConfirmClick, confirmStatus] = useTransaction(async () => {
    setDirty({
      insuranceCard: true,
      phone: true,
      addressLine1: true,
      addressLine2: true,
      pref: true,
      zipCode: true,
    });
    store.edit?.firstReserveValidation();

    store.edit?.setCard(undefined);
    store.edit?.errors.length === 0 && setStep(STEP.CONFIRM);
    store.edit?.setPreEditValid(true);
  });

  return (
    <Wrapper>
      <DocumentTitle title="患者プロフィール登録" />
      <Caption type="header" underline align="center">
        患者プロフィール登録
      </Caption>
      <CaptionGroup>
        <Caption type="body">
          感染症対策及び待ち時間短縮のため、初めてご来院の方全員にご登録をお願いしています。
        </Caption>
      </CaptionGroup>
      <FormLayout>
        <FormItem gutter={4}>
          <FormLabel required>保険証</FormLabel>
          <FormSubLabel>表面</FormSubLabel>
          <Card
            smallPadding
            color={store.edit?.insuranceCards.urls.length === 0 ? 'normal' : 'inset'}
            shadowType="none"
            error={
              store.edit?.insuranceCards.urls.length === 0 && store.edit.errors.some(e => e.field === 'insuranceCard')
            }
          >
            {store.edit && <InsuranceCardInput store={store.edit.insuranceCards} />}
          </Card>
          {dirty.insuranceCard &&
            store.edit?.insuranceCards.urls.length === 0 &&
            store.edit.errors
              .filter(e => e.field === 'insuranceCard')
              .map(e => <ErrorMessage key={e.error}>{e.error}</ErrorMessage>)}
        </FormItem>
        <FormItem>
          <FormLabel required>電話番号</FormLabel>
          <TextField
            color="white"
            type="tel"
            value={store.edit?.phone}
            onFocusout={() => onFocusOut('phone')}
            onChange={v => store.edit?.setPhone(v)}
            error={store.edit?.errors.some(e => e.field === 'phone')}
          />
          {dirty.phone &&
            store.edit?.errors
              .filter(e => e.field === 'phone')
              .map(e => <ErrorMessage key={e.error}>{e.error}</ErrorMessage>)}
        </FormItem>
        <FormItem>
          <FormLabel required>住所</FormLabel>
          <AddressInput
            value={
              store.edit?.address || {
                addressLine1: '',
                addressLine2: '',
                pref: '',
                zipCode: '',
              }
            }
            onChange={v => store.edit?.setAddress(v)}
            onFocusout={onFocusOut}
            store={store}
            dirty={dirty}
          />
        </FormItem>
      </FormLayout>
      <div className={buttonGroupStyle}>
        <ButtonGroup gutter={10}>
          <Button
            block
            disabled={!!confirmStatus.running || !store.edit?.preEditValid}
            onClick={() => onConfirmClick()}
          >
            確認画面へ
          </Button>
        </ButtonGroup>
      </div>
    </Wrapper>
  );
});

const buttonGroupStyle = css`
  margin-top: 16px;
`;
