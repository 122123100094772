import gql from 'graphql-tag';

export const InterviewType = gql`
  fragment QuestionType on Question {
    id
    label
    required
    additional_type
    additional_column_name
    answer {
      answer
      option {
        id
        label
        value
      }
    }
    options {
      id
      label
      value
      type
      placeholder
    }
    category
    confirm_text
    isConditional
    isNarrowDown
  }
  fragment InterviewType on Interview {
    id
    finished
    questions {
      ...QuestionType
    }
  }
`;
