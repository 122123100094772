import format from 'date-fns/format';
import { css } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Icon } from '../../../../../components/Icon';
import { Color, IconSize } from '../../../../../constants/Style';
import { square } from '../../../../../helpers/Style';

type Props = {
  id: string;
  date: Date;
  title: string;
};
export const NewsArticle = ({ date, title }: Props) => (
  <div className={newsArticleStyle}>
    <div>
      <Caption type="small">{format(date, 'yyyy年MM月dd日')}</Caption>
      <p>{title}</p>
    </div>
    <NewsArticleAction />
  </div>
);

const newsArticleStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid ${Color.GrayscaleLight};
`;

const NewsArticleAction = () => (
  <div className={newsArticleActionStyle}>
    <Icon name="angle-right" />
  </div>
);

const newsArticleActionStyle = css`
  ${square(IconSize.Regular)}
  > svg {
    fill: ${Color.GrayscaleNeutral};
  }
`;
