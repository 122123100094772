import { css } from 'emotion';
import React, { useReducer } from 'react';

import { ErrorMessage } from '../../../../../components/ErrorMessage';
import { FormItem, FormLabel } from '../../../../../components/Form';
import { TextField } from '../../../../../components/TextField';
import { ErrorInfo } from '../../stores/ProfileStore';

type Name = {
  sei: string;
  mei: string;
};
type Props = {
  value: Name;
  onChange: (v: Name) => void;
  errors: ErrorInfo[];
};

type Form = {
  sei: boolean;
  mei: boolean;
};

export const NameInput = ({ value, onChange, errors }: Props) => {
  const [form, setDirty] = useReducer((prev: Form, act: Partial<Form>) => ({ ...prev, ...act }), {
    sei: false,
    mei: false,
  });
  return (
    <div>
      <div className={containerStyle}>
        <FormItem>
          <FormLabel required>姓（カナ）</FormLabel>
          <TextField
            type="text"
            color="white"
            value={value.sei}
            onChange={v => onChange({ sei: v, mei: value.mei })}
            onFocusout={() => setDirty({ sei: true })}
            error={form.sei && errors.some(e => e.field === 'sei')}
          />
          {form.sei && (
            <div>
              {errors
                .filter(e => e.field === 'sei')
                .map(e => (
                  <ErrorMessage key={e.error}>{e.error}</ErrorMessage>
                ))}
            </div>
          )}
        </FormItem>
        <FormItem>
          <FormLabel required>名（カナ）</FormLabel>
          <TextField
            type="text"
            color="white"
            value={value.mei}
            onChange={v => onChange({ sei: value.sei, mei: v })}
            onFocusout={() => setDirty({ mei: true })}
            error={form.mei && errors.some(e => e.field === 'mei')}
          />
          {form.mei && (
            <div>
              {errors
                .filter(e => e.field === 'mei')
                .map(e => (
                  <ErrorMessage key={e.error}>{e.error}</ErrorMessage>
                ))}
            </div>
          )}
        </FormItem>
      </div>
    </div>
  );
};

const containerStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  width: 100%;
`;
