import gql from 'graphql-tag';

export const listCheckupSuggestions = gql`
  query listCheckupOptionSuggestion($input: [CheckupOptionSuggestionInput!]!) {
    listCheckupOptionSuggestion(input: $input) {
      optionId
      text
      severity
    }
  }
`;
