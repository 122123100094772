import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { Icon } from '../../../../components/Icon';
import { SUMMARY } from '../../../../constants/CheckupResult';
import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';
import { Result } from '../../../../interfaces/Result';
import { GroupInfoModal } from '../../Base/components/GroupInfoModal';
import { UseToOtherSubGroup } from '../../Summary/hooks/useToOtherSubGroup';
import { resultWordStyle } from '../../utils/resultColor';
import { BottomBorder } from '../BottomBorder';
import { ResultRound } from '../ResultRound';

type Props = {
  width?: number;
  height?: string;
  item: {
    displayName: string;
    description: string;
  };
  previous: string;
  thisTime: string;
  result: Result;
  previousResult?: Result;
  subGroupId: string;
};

export const ResultAndValueCard = ({
  width,
  height,
  item,
  previous,
  result,
  thisTime,
  previousResult,
  subGroupId,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const params = useParams<{ treatmentId: string }>();
  const location = useLocation();

  const toOtherSubGroup = (subGroupId: string) => {
    UseToOtherSubGroup(subGroupId, history, params, location);
  };
  return (
    <>
      <div className={cardWrapperStyle} onClick={() => toOtherSubGroup(subGroupId)}>
        <Card shadowType="floating" width={width} height={height}>
          <div className={headerStyle}>
            {item.displayName}
            {item.description && (
              <div onClick={e => e.stopPropagation()}>
                <div className={questionIconContainerStyle} onClick={() => setModalOpen(true)}>
                  <Icon name="question-circle" />
                </div>
              </div>
            )}
          </div>
          <div className={valueOnlyStyle}>
            <Caption type="small">今回</Caption>
            <p className={resultAndValueTextStyle}>
              <div className={roundWordContainerStyle}>
                <ResultRound small result={result} />
                <p className={cx(resultStyle, resultWordStyle[result])}>{SUMMARY[result]}</p>
              </div>
              <p className={resultWordStyle[result]}>{thisTime}</p>
            </p>
          </div>
          <BottomBorder />
          <div className={valueOnlyStyle}>
            <Caption type="small">前回</Caption>
            <p className={resultAndValueTextStyle}>
              {previousResult && (
                <>
                  <div className={roundWordContainerStyle}>
                    <ResultRound small result={previousResult} />
                    <p className={cx(resultStyle, resultWordStyle[previousResult])}>{SUMMARY[previousResult]}</p>
                  </div>
                  <p className={resultWordStyle[previousResult]}>{previous || '-'}</p>
                </>
              )}
            </p>
          </div>
        </Card>
      </div>
      <GroupInfoModal
        name={item.displayName}
        description={item.description}
        modalOpen={modalOpen}
        onClick={setModalOpen}
      />
    </>
  );
};

const cardWrapperStyle = css`
  margin-bottom: ${gutter(5)};
  cursor: pointer;
`;

const headerStyle = css`
  display: flex;
  align-items: center;
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const questionIconContainerStyle = css`
  display: flex;

  > svg {
    margin-left: ${gutter(1.7)};
    ${square(24)}

    fill: ${Color.GrayscaleLight}
  }
`;

const valueOnlyStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const resultAndValueTextStyle = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  min-width: 100px;
  font-size: ${FontSize.Large};
`;

const roundWordContainerStyle = css`
  display: flex;
  font-weight: bold;
`;

const resultStyle = css`
  margin-left: ${gutter(2)};
  font-size: ${FontSize.SmallRegular};
`;
