import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../constants/Routes';
import { IconSize } from '../../../constants/Style';
import { square } from '../../../helpers/Style';
import { useContext } from '../../../hooks/useContext';
import { SessionStore } from '../../../stores/SessionStore';
import { Icon } from '../../Icon';

export const LoginState = observer(() => {
  const session = useContext(SessionStore.Context);
  const { push } = useHistory();

  if (!session.authStateKnown) {
    return <div />;
  }

  if (!session.authenticated) {
    return (
      <div className={signInAltStyle} onClick={() => push(Routes.Paths.AccountNew)}>
        <Icon name="sign-in-alt" />
        <div className={captionStyle}>ログイン</div>
      </div>
    );
  }
  return (
    <div className={iconContainerStyle}>
      <Icon name="user" />
      <div className={captionStyle}>{session.user?.userEntity?.sei || session.user?.userEntity?.name || ''}様</div>
    </div>
  );
});

const iconContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    ${square(IconSize.Large)}
  }
`;

const signInAltStyle = cx(
  iconContainerStyle,
  css`
    > svg {
      transform: scale(-1, 1);
    }

    cursor: pointer;
  `,
);

const captionStyle = css`
  margin-top: 4px;
  font-size: 9px;
  text-align: center;
`;
