import { useEffect, useState } from 'react';

/**
 * 引数として渡されたミリ秒だけ待って、完了したらtrueになる状態を返すhook
 */
export const useDebounce = (milliSecond: number) => {
  const [done, setDone] = useState(false);
  useEffect(() => {
    setTimeout(() => setDone(true), milliSecond);
  }, [milliSecond]);
  return done;
};
