import { action, computed, makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  GetNextTreatmentPlanQuery,
  GetNextTreatmentPlanQueryVariables,
  ListTreatmentKindsQuery,
  ListTreatmentKindsQueryVariables,
  Maybe,
  TreatmentKindTypeFragment,
} from '../../../../../gql/gql-types';
import { getNextTreatmentPlan } from '../../../../../gql/operations/getNextTreatmentPlan';
import { listTreatmentKind } from '../../../../../gql/operations/listTreatmentKind';
import { ElementOf, orderBy, OrderByBuilder, safeFilter } from '../../../../utils/CollectionUtil';
import { ReservationStore, Revisit } from './ReservationStore';

type Course = NonNullable<ReservationStore['treatmentKind']> & {
  department: string;
  course: boolean;
  subTitle?: string;
  estimatedDuration?: Maybe<number>;
  revisit?: Maybe<boolean>;
  order?: Maybe<number>;
};

type SubTitle = {
  subTitle: string;
  courses: Course[];
};

type Department = NonNullable<ReservationStore['department']>;

type TreatmentKindIds = {
  [departmentId: string]: string[];
};
export class CourseStore {
  public static Context = createContext<CourseStore | null>(null);
  public static treatmentKindIds: TreatmentKindIds = {};

  constructor() {
    makeAutoObservable(this, {
      courses: computed,
      nextCourses: observable,
      department: observable,
      fetchCourses: action,
      setDepartment: action,
      subTitles: computed,
    });
  }

  private treatmentKinds: Course[] = [];
  public nextCourses: Course[] = [];
  public department?: Department = undefined;
  public revisit?: Revisit = undefined;
  public setRevisit(r: Revisit) {
    this.revisit = r;
  }

  public setDepartment(d: Department) {
    this.department = d;
  }

  public get courses() {
    return this.treatmentKinds.filter(x => !this.department || (x.department === this.department.id && x.course));
  }

  public get subTitles() {
    return new OrderByBuilder<ElementOf<CourseStore['treatmentKinds']>>()
      .asc(t => t.order)
      .asc(t => t.id)
      .sort(
        this.treatmentKinds
          .filter(x => !this.department || (x.department === this.department.id && x.course))
          .filter(x => this.revisit !== 'NEW' || !x.revisit),
      )
      .reduce((prev, elem) => {
        const found = prev.findIndex(p => p.subTitle === elem.subTitle);
        if (found > -1) {
          return [
            ...prev.slice(0, found),
            {
              ...prev[found],
              courses: [...prev[found].courses, elem],
            },
            ...prev.slice(found + 1),
          ];
        }
        return [
          ...prev,
          {
            subTitle: elem.subTitle || '',
            courses: [elem],
          },
        ];
      }, new Array<SubTitle>());
  }

  public async fetchCourses(loggedIn?: boolean) {
    if (!loggedIn || !this.department) {
      const treatmentKinds = await this.fetchTreatmentKinds();
      this.treatmentKinds = treatmentKinds;
      return;
    }

    const [nextPlan, treatmentKinds] = await Promise.all([
      GQL.query<GetNextTreatmentPlanQueryVariables, GetNextTreatmentPlanQuery>(getNextTreatmentPlan, {
        input: {
          clinic: this.department.clinic,
          department: this.department.id,
        },
      }),
      this.fetchTreatmentKinds(),
    ]);

    this.treatmentKinds = treatmentKinds;
    this.nextCourses = (nextPlan.getNextTreatmentPlan || []).map(v => factory(v, true));
  }

  private async fetchTreatmentKinds() {
    const { listTreatmentKinds } = await GQL.queryAsGuest<ListTreatmentKindsQueryVariables, ListTreatmentKindsQuery>(
      listTreatmentKind,
      {},
    );

    const departmentSet = new Set(safeFilter(listTreatmentKinds?.items || []).map(e => e.department?.id));
    departmentSet.forEach((setDeptId: string | undefined) => {
      const ids: string[] = [];
      safeFilter(listTreatmentKinds?.items || [])
        .filter(i => i.department?.id === setDeptId)
        .forEach(d => ids.push(d.id));
      if (setDeptId !== undefined) {
        CourseStore.treatmentKindIds[setDeptId] = ids;
      }
    });

    return orderBy(
      safeFilter(listTreatmentKinds?.items || []).map(x => factory(x)),
      i => i.id,
    );
  }
}

const factory = (item: TreatmentKindTypeFragment, returning?: boolean) => ({
  baseDuration: 0,
  id: item.id,
  name: item.name,
  department: item.department?.id || '',
  course: !!item.course,
  icon: item.icon,
  vaccine: !!item.vaccine,
  noInterview: !!item.noInterview,
  subTitle: returning ? '再診' : item.subTitle || '',
  estimatedDuration: item.displayDuration || item.estimatedDuration,
  revisit: item.revisit,
  order: returning ? -1 : item.order,
  requirement: item.requirement,
  displayDuration: item.displayDuration,
  cancelPolicies: item.cancelPolicies,
  announcement: item.announcement,
  subAnnouncement: item.subAnnouncement,
  requiredReservationFee: item.requiredReservationFee || false,
});
