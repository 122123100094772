import { css } from 'emotion';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { Icon } from '../../../../components/Icon';
import { Color, FontSize } from '../../../../constants/Style';
import { gutter, square } from '../../../../helpers/Style';
import { GroupInfoModal } from '../../Base/components/GroupInfoModal';
import { UseToOtherSubGroup } from '../../Summary/hooks/useToOtherSubGroup';

type Props = {
  cardWidth?: number;
  cardHeight?: string;
  halfName: string;
  nextHalfName: string;
  valueStr: string;
  nextValueStr: string;
  previousValueStr: string;
  previousNextValueStr: string;
  subGroupId: string;
  item: {
    displayName: string;
    description: string;
  };
};

export const PairCard = ({
  cardWidth,
  cardHeight,
  halfName,
  nextHalfName,
  valueStr,
  nextValueStr,
  previousValueStr,
  previousNextValueStr,
  subGroupId,
  item,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const params = useParams<{ treatmentId: string }>();
  const location = useLocation();

  const toOtherSubGroup = (subGroupId: string) => {
    UseToOtherSubGroup(subGroupId, history, params, location);
  };
  return (
    <>
      <div className={cardWrapperStyle} onClick={() => toOtherSubGroup(subGroupId)}>
        <Card shadowType="floating" width={cardWidth} height={cardHeight}>
          <div>
            <div className={headerStyle}>
              {item.displayName}
              {item.description && (
                <div onClick={e => e.stopPropagation()}>
                  <div className={questionIconContainerStyle} onClick={() => setModalOpen(true)}>
                    <Icon name="question-circle" />
                  </div>
                </div>
              )}
            </div>
            <div className={pairStyle}>
              <div>
                <Caption type="small">今回</Caption>
              </div>
              <div className={contentsStyle}>
                <Caption type="small">{halfName}</Caption>
                <p className={pairTextStyle}>{valueStr}</p>
                <Caption type="small">{nextHalfName}</Caption>
                <p className={pairTextStyle}>{nextValueStr}</p>
              </div>
            </div>
            {previousValueStr && previousNextValueStr && (
              <div className={pairStyle}>
                <div>
                  <Caption type="small">前回</Caption>
                </div>
                <div className={contentsStyle}>
                  <Caption type="small">{halfName}</Caption>
                  <p className={pairTextStyle}>{previousValueStr}</p>
                  <Caption type="small">{nextHalfName}</Caption>
                  <p className={pairTextStyle}>{previousNextValueStr}</p>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
      <GroupInfoModal
        name={item.displayName}
        description={item.description}
        modalOpen={modalOpen}
        onClick={setModalOpen}
      />
    </>
  );
};

const cardWrapperStyle = css`
  margin-bottom: ${gutter(5)};
  cursor: pointer;
`;

const headerStyle = css`
  display: flex;
  align-items: center;
  font-size: ${FontSize.Large};
  font-weight: bold;
`;

const questionIconContainerStyle = css`
  display: flex;

  > svg {
    margin-left: ${gutter(1.7)};
    ${square(24)}

    fill: ${Color.GrayscaleLight}
  }
`;

const pairStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const contentsStyle = css`
  display: flex;
  align-items: center;
  justify-content: inherit;
  width: 80%;
`;

const pairTextStyle = css`
  font-size: ${FontSize.SmallMedium};
  font-weight: bold;
`;
