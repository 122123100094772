import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';
import { Department } from 'src/domains/reservation/Department';

import { GQL } from '../../../../../gql/client';
import {
  DepartmentType,
  GetDownloadableLinkQuery,
  GetDownloadableLinkQueryVariables,
  GetMyPageQuery,
  GetMyPageQueryVariables,
  ListCheckupResultItemGroupsQuery,
  ListCheckupResultItemGroupsQueryVariables,
  ListCheckupResultItemSubGroupsQuery,
  ListCheckupResultItemSubGroupsQueryVariables,
  ListDepartmentsQuery,
  ListDepartmentsQueryVariables,
  TreatmentHistoryFragment,
} from '../../../../../gql/gql-types';
import getDownloadableLink from '../../../../../gql/operations/getDownloadableLink';
import getMyPage from '../../../../../gql/operations/getMyPage';
import { listCheckupResultItemGroups } from '../../../../../gql/operations/listCheckupResultItemGroup';
import { listCheckupResultItemSubGroups } from '../../../../../gql/operations/listCheckupResultItemSubGroup';
import { listDepartments } from '../../../../../gql/operations/listDepartment';
import { JUDGED_RESULT } from '../../../../constants/CheckupResult';
import { WaitingListItemStore } from '../../../../pages/WaitingListItems/stores/WaitingListItemStore';
import { safeFilter } from '../../../../utils/CollectionUtil';
import { CheckupResultGroupStore } from '../../Base/stores/CheckupResultGroupStore';

type Profile = Pick<Required<NonNullable<GetMyPageQuery['getUser']>>, 'id'>;

const toNonNullArray = <X>(nullable: Array<X | null | undefined> | null | undefined): X[] =>
  nullable ? nullable.filter(n => n !== undefined && n != null).map(n => n as X) : [];

export class SummaryStore {
  public static Context = createContext<null | SummaryStore>(null);
  public static toNonNullArray = toNonNullArray;

  constructor() {
    makeAutoObservable(this, {
      treatmentId: observable,
      profile: observable,
      treatments: observable,
      waitingListItems: observable,
      fetchInitial: action,
      setProfile: action,
      targetTreatment: computed,
      groups: observable,
    });
  }

  public treatmentId = '';

  public profile?: Profile = undefined;

  public setProfile(p: Profile) {
    this.profile = p;
  }

  public treatments: TreatmentHistoryFragment[] = [];

  public waitingListItems: WaitingListItemStore[] = [];

  public departments?: Department[] = [];

  public groups: CheckupResultGroupStore[] = [];

  public async fetchInitial(treatmentId: string) {
    if (!this.profile?.id) {
      return;
    }
    const [res, resCheckupResultItemSubGroups, resDepartments, resGroups] = await Promise.all([
      GQL.query<GetMyPageQueryVariables, GetMyPageQuery>(getMyPage, { userId: this.profile.id }),
      GQL.query<ListCheckupResultItemSubGroupsQueryVariables, ListCheckupResultItemSubGroupsQuery>(
        listCheckupResultItemSubGroups,
        {},
      ),
      GQL.query<ListDepartmentsQueryVariables, ListDepartmentsQuery>(listDepartments, {}),
      GQL.query<ListCheckupResultItemGroupsQueryVariables, ListCheckupResultItemGroupsQuery>(
        listCheckupResultItemGroups,
        {},
      ),
    ]);

    runInAction(() => {
      this.treatmentId = treatmentId;
      this.profile = res.getUser ? { id: res.getUser.id } : undefined;
      this.treatments = safeFilter(res.getUser?.completedTreatment.items);
      this.waitingListItems = safeFilter(res.getUser?.waitingList.items).map(x => WaitingListItemStore.fromFragment(x));
      this.departments = resDepartments.listDepartments?.items?.map(item => ({
        id: item?.id || '',
        name: item?.name || '',
        type: item?.type as DepartmentType,
        baseDuration: 15,
        clinic: item?.clinic?.name || '',
        clinicName: item?.clinic?.name,
        clinicId: item?.clinic?.id,
        icon: 'men',
        termsUrl: item?.clinic?.termsUrl || '',
      }));
      this.groups = CheckupResultGroupStore.fromFragments(
        treatmentId,
        safeFilter(
          safeFilter(res.getUser?.completedTreatment.items).find(t => t.id === treatmentId)?.checkupAppointment?.result
            ?.judged,
        ),
        safeFilter(resCheckupResultItemSubGroups.listCheckupResultItemSubGroups?.items),
        safeFilter(resGroups.listCheckupResultItemGroups?.items),
        safeFilter(res.getUser?.completedTreatment.items),
      );
    });
  }

  public async fetchDownloadableLink(id: string) {
    const res = await GQL.query<GetDownloadableLinkQueryVariables, GetDownloadableLinkQuery>(getDownloadableLink, {
      input: { id },
    });
    return res.getDownloadableLink?.url;
  }

  public get targetTreatment() {
    return this.treatments.find(treatment => treatment.id === this.treatmentId);
  }

  public get targetTreatmentCheckupResultSummary() {
    if (this.treatments.length === 0) {
      return undefined;
    }
    return this.treatments.find(treatment => treatment.id === this.treatmentId)?.checkupAppointment?.result?.summary;
  }

  public get subGroups() {
    return this.groups.flatMap(g => g.subGroups);
  }

  // Aだけの時は受診をおすすめしますにアコーディオンごと表示させない
  public get filterGroups() {
    return this.groups.filter(g =>
      g.subGroups.some(
        s =>
          s.subgroupResult.result === JUDGED_RESULT['B'] ||
          s.subgroupResult.result === JUDGED_RESULT['C'] ||
          s.subgroupResult.result === JUDGED_RESULT['D'],
      ),
    );
  }

  public get resultDepartmentName() {
    if (this.treatments.length === 0) {
      return '';
    }
    return this.treatments.find(treatment => treatment.id === this.treatmentId)?.checkupAppointment?.result;
  }

  public get departmentName() {
    return this.departments?.find(d => d.id === this.targetTreatmentCheckupResultSummary?.departmentId)?.name;
  }

  public get departmentClinic() {
    return this.departments?.find(d => d.id === this.targetTreatmentCheckupResultSummary?.departmentId)?.clinic || '';
  }

  public get departmentClinicId() {
    return this.departments?.find(d => d.id === this.targetTreatmentCheckupResultSummary?.departmentId)?.clinicId || '';
  }

  public get departmentId() {
    return this.departments?.find(d => d.id === this.targetTreatmentCheckupResultSummary?.departmentId)?.id || '';
  }

  public get targetDate() {
    if (!this.targetTreatment) {
      return '';
    }
    const date = new Date(this.targetTreatment.date);
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
  }

  public get checkupResultAttachmentId() {
    return this.targetTreatment?.attachments.items?.find(i => i?.name.match(/健診結果表/))?.id || '';
  }
}
