import { css, cx } from 'emotion';
import React from 'react';

import { Caption } from '../../../../../components/Caption';
import { Wrapper } from '../../../../../components/Wrapper';
import { SUMMARY } from '../../../../../constants/CheckupResult';
import { Dictionary } from '../../../../../constants/Dictionaries';
import { Color, FontSize } from '../../../../../constants/Style';
import { gutter, square } from '../../../../../helpers/Style';
import { Result } from '../../../../../interfaces/Result';
import { Sex } from '../../../../../interfaces/Sex';
import { resultWordStyle } from '../../../utils/resultColor';
import { ResultDoctorImage } from '../ResultDoctorImage';

type Props = {
  name: string;
  organizationName: string;
  planName: string;
  sex?: string;
  date: string;
  result: Result;
};
export const ResultDoctorWrapper = ({ name, organizationName, planName, sex, date, result }: Props) => (
  <div className={cx(containerStyle, backgroundStyle[result])}>
    <Wrapper>
      <div className={relativeStyle}>
        <div className={headStyle}>
          <p className={regularHeaderStyle}>{name.replace(/\s+/g, '')}さんの</p>
          <Caption type="header">健康診断結果</Caption>
        </div>

        <div className={whiteCircleStyle}>
          <div className={cx(baseCircleBackgroundStyle, circleBackgroundStyle[result])}>
            <div className={resultWordSimpleStyle}>{result}</div>
          </div>
        </div>

        <div className={cx(resultWordStyle[result], resultBoldWordStyle)}>{SUMMARY[result]}</div>

        <div className={subInfoContainerStyle}>
          <div className={marginBottomStyle}>
            <p className={subInfoTextStyle}>
              {organizationName === '' ? '個人健診' : organizationName}/{date}
            </p>
          </div>
          <p className={subInfoTextStyle}>
            CLINIC TEN SHIBUYA / {planName}
            {sex === '' ? '' : `/${Dictionary.UserSex[sex as Sex]}`}
          </p>
        </div>
        <ResultDoctorImage result={result} />
      </div>
    </Wrapper>
  </div>
);

const height = 448;

const containerStyle = css`
  height: ${height}px;
`;

const headStyle = css`
  margin: ${gutter(23.5)} ${gutter(10)} ${gutter(6.75)};
  text-align: center;
`;

const relativeStyle = css`
  position: relative;
  height: ${height}px;
`;

const regularHeaderStyle = css`
  margin-bottom: ${gutter(2.75)};
  font-weight: bold;
`;

const backgroundStyle: Record<Result, string> = {
  A: backgroundColor(Color.NotingResultBackground),
  B: backgroundColor(Color.AttentionResultBackground),
  C: backgroundColor(Color.ConsultationResultBackground),
  D: backgroundColor(Color.TreatmentResultBackground),
};

function backgroundColor(color: Color) {
  return css`
    background: linear-gradient(#f4f4f4, ${color});
  `;
}

const baseCircleBackgroundStyle = css`
  ${square(80)}

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

const circleBackgroundStyle: Record<Result, string> = {
  A: css`
    background: ${Color.NotingResult};
    box-shadow: 0 0 12px 0 rgb(67 170 39 / 30%);
  `,
  B: css`
    background: ${Color.AttentionResult};
    box-shadow: 0 0 12px 0 rgb(247 180 0 / 30%);
  `,
  C: css`
    background: ${Color.ConsultationResult};
    box-shadow: 0 0 12px 0 rgb(235 87 87 / 20%);
  `,
  D: css`
    background: ${Color.TreatmentResult};
    box-shadow: 0 0 12px 0 rgb(73 66 79 / 25%);
  `,
};

const whiteCircleStyle = css`
  ${square(108)}

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: ${gutter(2.5)};
  background: ${Color.GrayscaleWhite};
  border-radius: 100%;
`;

const resultWordSimpleStyle = css`
  font-size: ${FontSize.MaximLarge};
  font-weight: bold;
  color: ${Color.GrayscaleWhite};
`;

const resultBoldWordStyle = css`
  margin-bottom: ${gutter(12.5)};
  font-size: ${FontSize.ExtraLarge};
  font-weight: bold;
  text-align: center;
`;

const marginBottomStyle = css`
  margin-bottom: ${gutter(1.5)};
`;

const subInfoContainerStyle = css`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const subInfoTextStyle = css`
  font-size: ${FontSize.Small};
  font-weight: 300;
  color: ${Color.GrayscaleDarker};
`;
