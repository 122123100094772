const BasePath = '/';

// @TODO
// 不足してるパス定義を fix させる。
// 事前問診や診療日時の編集画面のパスをどうするか要検討
const Paths = {
  /** トップページ（マイページ） */
  Root: BasePath,

  /** 予約確認・変更 */
  Reservations: `${BasePath}reservations`,
  /** 予約詳細 */
  ReservationShow: `${BasePath}reservations/:uuid`,

  /** 予約詳細(アクション確認) */
  ReservationActions: `${BasePath}reservations/:uuid/actions`,
  /** 予約変更 */
  ReservationEdit: `${BasePath}reservations/:uuid/edit`,

  /**
   * 予約（新規）
   *
   * 下記の画面遷移を内包する。
   * 1. 診療科一覧選択
   * 2. 診療枠選択
   */
  ReservationNew: `${BasePath}reservations/new`,
  /** 予約完了 */
  ReservationSuccess: `${BasePath}reservations/:uuid/success`,
  /** 初めての予約完了 */
  ReservationFirstSuccess: `${BasePath}reservations/:uuid/first/success`,
  /** 事前プロフィール登録 */
  ReservationAccountPreEdit: `${BasePath}account/:uuid/preEdit`,
  /** 事前問診 */
  ReservationPreInterview: `${BasePath}reservations/:uuid/preInterview`,
  /** 事前問診完了 */
  ReservationPreInterviewSuccess: `${BasePath}reservations/:uuid/preInterview/success`,
  /** 事前問診確認 */
  ReservationPreInterviewShow: `${BasePath}preInterviews/:uuid`,
  /** オンライン診療予約完了 */
  ReservationNewOnline: `${BasePath}reservations/online/new`,
  /** キャンセル待ち登録完了 */
  WaitingSuccess: `${BasePath}waitingListItems/:uuid/success`,

  /** 診察・検診履歴一覧 */
  MedicalHistories: `${BasePath}medicalHistories`,
  /** 診察・検診履歴詳細 */
  MedicalHistoryShow: `${BasePath}medicalHistories/:uuid`,

  /** 健康診断結果一覧 */
  CheckupResultHistories: `${BasePath}checkupResultHistories`,
  /** 健康診断結果サマリ画面 */
  CheckupResultSummary: `${BasePath}checkupResultSummary/:treatmentId`,
  /** 健康診断結果ベース画面 */
  CheckupResultBase: `${BasePath}checkupResultBase/:subGroupId/:treatmentId`,

  /** キャンセル待ち登録完了 */
  WaitingListItemShow: `${BasePath}waitingListItems/:uuid`,
  /** キャンセル待ち削除完了 */
  WaitingListItemDeleteComplete: `${BasePath}waitingListItems/removed`,

  /** 領収書一覧 */
  Receipts: `${BasePath}receipts`,
  /** お知らせ一覧 */
  Notifications: `${BasePath}notifications`,
  /** お知らせ詳細 */
  NotificationShow: `${BasePath}notifications/:uuid`,

  /** アカウント情報 */
  Account: `${BasePath}account`,
  /** プロフィール編集 */
  AccountProfileEdit: `${BasePath}account/profile/edit`,
  /** 決済情報編集 */
  AccountSettlementEdit: `${BasePath}account/settlement/edit`,

  /** アカウント登録 */
  AccountNew: `${BasePath}account/new`,
  AccountConfirm: `${BasePath}account/confirm`,
  AccountName: `${BasePath}account/profile/name/edit`,
  SignIn: `${BasePath}account/signIn`,
  ForgotPassword: `${BasePath}account/forgotPassword`,
  ResetPassword: `${BasePath}account/:id/password`,

  AccountNewPharmacy: `${BasePath}pharmacy/account/new`,
  AccountNamePharmacyComplete: `${BasePath}account/profile/name/complete`,

  AccountLineConnect: `${BasePath}account/connect`,

  IdpResponse: `${BasePath}auth/idpresponse`,

  /** bot強制登録 */
  AggressiveBotLink: `${BasePath}auth/redirects/line`,
  StartAuth: `${BasePath}auth/start`,

  /** 健診予約フロー */
  CheckupReservationNew: `${BasePath}checkup/reservations/new`,
  CheckupPreInterviewShow: `${BasePath}checkup/reservations/:uuid/preInterview`,
  CheckupPreInterviewPostReserveEdit: `${BasePath}checkup/reservations/:uuid/postReserveInterview/edit`,
  CheckupReservationEdit: `${BasePath}checkup/reservations/:uuid/edit`,
  CheckupReservationFix: `${BasePath}checkup/reservations/:uuid/fix`,

  /** 個人用健診予約フロー */
  CheckupReservationNewIndividual: `${BasePath}individualCheckup/reservations/new`,
  CheckupReservationCompleteIndividual: `${BasePath}individualCheckup/reservations/:uuid/success`,
  CheckupReservationFixIndividual: `${BasePath}individualCheckup/reservations/:uuid/fix`,
  CheckupReservationInterviewCompleteIndividual: `${BasePath}individualCheckup/reservations/:uuid/interview/success`,
  CheckupReservationEditIndividual: `${BasePath}individualCheckup/reservations/:uuid/edit`,

  /**
   * 規約
   */
  UsagePolicy: `${BasePath}terms`,
  PrivacyPolicy: `${BasePath}terms/privacy`,

  FaqList: `${BasePath}questions`,
  FaqShow: `${BasePath}questions/:questionId`,

  /**
   * 添付ファイル
   */
  AuthorizedAttachment: `${BasePath}attachments/:attachmentId`,

  /**
   * 処方箋提出
   */
  PrescriptionUpload: `${BasePath}prescriptions/new`,
  PrescriptionUploadComplete: `${BasePath}prescriptions/complete`,

  /**
   * google calendar url scheme
   */
  GoogleCalendarURLSchemeRedirect: `${BasePath}googleCalendar/redirect`,
  GoogleCalendarCoopSetting: `${BasePath}reservations/:uuid/googleCalendarSetting`,

  /**
   * 未回答問診ジャンプ
   */
  UnAnsweredInterview: `${BasePath}interviews/unAnswered`,

  /*
   * 外部埋め込み用
   */
  EmbeddedVacancies: `${BasePath}embedded/vacancies`,

  /*
   * (仮)iPhoneカレンダー登録用
   */
  addToIPhoneCalendar: `${BasePath}addToIPhoneCalendar`,
};

const AuthRoutes = [
  Paths.AccountProfileEdit,
  Paths.AccountSettlementEdit,
  Paths.CheckupPreInterviewPostReserveEdit,
  Paths.CheckupPreInterviewShow,
  Paths.CheckupReservationEdit,
  Paths.CheckupReservationNew,
  Paths.MedicalHistories,
  Paths.MedicalHistoryShow,
  Paths.Receipts,
  Paths.ReservationActions,
  Paths.ReservationEdit,
  Paths.ReservationNew,
  Paths.ReservationPreInterview,
  Paths.ReservationPreInterviewShow,
  Paths.ReservationPreInterviewSuccess,
  Paths.ReservationShow,
  Paths.ReservationSuccess,
  Paths.Reservations,
  Paths.AuthorizedAttachment,
  Paths.CheckupReservationFix,
  Paths.UnAnsweredInterview,
  Paths.CheckupReservationFixIndividual,
  Paths.CheckupResultHistories,
  Paths.CheckupResultSummary,
  Paths.CheckupResultBase,
  Paths.WaitingListItemShow,
  Paths.GoogleCalendarCoopSetting,
] as const;

// eslint-disable-next-line no-useless-escape
const toRegExp = (input: string) => new RegExp(`^${input.replace(/:[^\/]+/, '[^/]*')}\/*$`);

const matches = (path: string) => {
  const { pathname } = window.location;
  return toRegExp(path).test(pathname);
};

const Externals = {
  FAQ: {
    Root: `https://support.clinicten.jp/hc/ja`,
    CANNOT_LOGIN: `https://support.clinicten.jp/hc/ja/articles/4412089144089-LINE%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%8C%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84`,
  },
};

export const Routes = {
  BasePath,
  Paths,
  AuthRoutes,
  matches,
  Externals,
} as const;
