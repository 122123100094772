import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption } from '../../../../components/Caption';
import { Color } from '../../../../constants/Style';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CancelPolicyStore } from '../../../../stores/CancelPolicyStore';

type Props = {
  type?: 'reserve' | 'edit' | 'cancel';
  store: CancelPolicyStore;
  penaltyCount?: number;
  disabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmAnalyticsClassName?: string;
  cancelAnalyticsClassName?: string;
};

export const LastWarningContent = observer(
  ({
    store,
    type = 'reserve',
    penaltyCount,
    disabled = false,
    onConfirm,
    onCancel,
    confirmAnalyticsClassName,
    cancelAnalyticsClassName,
  }: Props) => {
    const { headerText, confirmText, cancelText } = store.makeLastWarningTextList(type);

    const timerEnabled = useDebounce(1000);

    const nextPenaltyCount = penaltyCount ? penaltyCount + 1 : 1;

    const handleCancelClick = () => {
      onCancel();
      store.setVisibleLastCaution(false);
    };

    return (
      <div className={lastWarningContentSectionStyle}>
        <div className={lastWarningContentHeaderStyle}>
          <Caption type="header">{headerText}</Caption>
        </div>
        <div className={imageStyle}>
          {type === 'reserve' ? (
            <img src="/images/doctors_bowing.png" alt="医師がお辞儀をしている画像" />
          ) : (
            <img src="/images/doctors_caution.png" alt="医師が警告をしている画像" />
          )}
        </div>
        <div className={lastWarningContentBodyStyle}>
          {type === 'reserve' && (
            <>
              ご予約のお時間まで24時間を切っているため、キャンセルポリシーに基づきキャンセルできません。
              <br />
              了承の上予約しますか？
            </>
          )}
          {type !== 'reserve' && (
            <>
              患者様の直前の変更・キャンセル回数は
              <span className={redTextStyle}>{nextPenaltyCount}回</span>になります。
            </>
          )}
        </div>
        <ButtonGroup>
          <Button
            disabled={disabled || !timerEnabled}
            type="button"
            onClick={() => onConfirm()}
            block
            className={confirmAnalyticsClassName}
          >
            {confirmText}
          </Button>
          <Button
            block
            theme="neutral"
            ghost
            type="button"
            onClick={handleCancelClick}
            className={cancelAnalyticsClassName}
          >
            {cancelText}
          </Button>
        </ButtonGroup>
      </div>
    );
  },
);

const lastWarningContentSectionStyle = css`
  padding-top: 16px;
  padding-bottom: 8px;
`;

const lastWarningContentHeaderStyle = css`
  text-align: center;
`;

const lastWarningContentBodyStyle = css`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
`;

const imageStyle = css`
  display: flex;
  justify-content: center;
`;

const redTextStyle = css`
  font-weight: 600;
  color: ${Color.FunctionalRedNeutral};
`;
